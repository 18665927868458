import React, {FC} from "react"

interface ICustomButton {
    className?: string
    value: string
    clickeHandler: () => void
    disabled?: boolean
}

export const CustomButton: FC<ICustomButton> = ({className = "", value, clickeHandler, disabled = false}) => {
    return (
        <button
            disabled={disabled}
            className={`${className} flex h-12 xxs:h-10 w-full items-center justify-center rounded-lg ${disabled ? 'bg-gray-400' : 'bg-primary-6000 hover:bg-primary-700'} text-neutral-50 font-semibold mt-2 focus:outline-none md:h-12 md:w-16 xxs:text-md`}
            onClick={clickeHandler}
        >{value}</button>
    )
}
