import React, {FC, useEffect, useRef, useState} from 'react';
import moment from "moment/moment";
import {BestPricesModel} from "../../../api/types";
import { Skeleton } from 'primereact/skeleton';

interface ICustomCalendar {
    isLoadingBestPrices?: boolean
    bestPrices?: BestPricesModel[]
    range?: boolean
    selectedDate: [moment.Moment|null, moment.Moment|null]
    setSelectedDate: (value: [moment.Moment|null, moment.Moment|null]) => void
}

export const CustomCalendar: FC<ICustomCalendar> = (
    {
        isLoadingBestPrices,
        bestPrices,
        range = true,
        selectedDate,
        setSelectedDate
    }) => {
    const dateTailRef = useRef<HTMLDivElement|null>(null);

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ startDate, setStartDate ] = useState<moment.Moment|null>(null)

    const currentDate: moment.Moment = moment().startOf('day');
    const currentYear: number = currentDate.year();
    const currentMonth: number = currentDate.month();
    const numberOfYearsToShow: number = 2;
    const startYear: number = currentYear;
    const endYear: number = currentYear + numberOfYearsToShow;
    const monthsToShow: Array<number> = Array.from(
        { length: (endYear - startYear + 1) * 7 },
        (_, index) => index
    );

    useEffect(() => {
        scrollToFirstSelectedDay()
    }, [])
   
   
   
useEffect(() => {
    if(isLoadingBestPrices)
    setIsLoading(isLoadingBestPrices)
    else
setIsLoading(false)
}, [isLoadingBestPrices])


    
    const scrollToFirstSelectedDay = () => {
        dateTailRef.current?.scrollIntoView({behavior: 'auto', block: 'center'})
    }

    const getMonthData = (date: moment.Moment): string => {
        if(bestPrices) {
        const bestPrice: BestPricesModel|undefined = bestPrices.find(value => moment(value.departureDate).isSame(date))
        return !bestPrice ? '' : bestPrice.bestPrice ? bestPrice.bestPrice!.toFixed(0) + ' €' : '' }
        else
        return ''
    };

    const selectDateHandler = (date: moment.Moment) => {
        const _selectedDate: [moment.Moment|null, moment.Moment|null] = [...selectedDate]

        if (!startDate) {
            if (range)
                setStartDate(date)

            _selectedDate[0] = date
            _selectedDate[1] = null
        } else if (range) {
            _selectedDate[1] = date
            setStartDate(null)
        }

        setSelectedDate(_selectedDate)
    }

    const daysInWeek: Array<string> = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return (
        <div className={`w-full`}>
            <div className={`sticky top-0 bg-white text-center z-10 px-2`} style={{borderBottom: '1px solid #e5e7eb'}}>
                <div className="grid grid-cols-7 gap-x-4">
                    {daysInWeek.map((dayName, index) => (
                        <div key={dayName} className={`col-start-${index + 1} col-span-1 day-name`}>
                            {dayName}
                        </div>
                    ))}
                </div>
            </div>
            {monthsToShow.map((monthIndex) => {
                const year = Math.floor(monthIndex / 12) + startYear;
                const month = monthIndex % 12;
                if (year < currentYear || (year === currentYear && month < currentMonth)) {
                    return null; // Überspringe vergangene Monate
                }
                const firstDay = new Date(year, month, 1);
                firstDay.setDate(1); // Setzen Sie den Starttag auf den 1. des aktuellen Monats
                const daysInMonth = new Date(year, month + 1, 0).getDate();

                return (
                    <div key={monthIndex} className={`my-4 px-2`}>
                        <h2 className={`font-semibold pb-2`}>{firstDay.toLocaleString('default', { month: 'long' })} {year}</h2>
                        <div className="calendar">
                            <div className="grid grid-cols-7 gap-x-2 gap-y-2">
                                {Array.from({ length: firstDay.getDay() }, (_, dayIndex) => (
                                    <div key={`empty${dayIndex}`} className="col-span-1 empty-day" />
                                ))}
                                {Array.from({ length: daysInMonth }, (_, dayIndex) => {
                                    const day = dayIndex + 1;
                                    const date = moment({year: year, month: month, day: day}).startOf('day');

                                    const isPastDay: boolean = date.isBefore(startDate ? startDate : currentDate)
                                    const isBeforeStartDay: boolean = date.isBefore(startDate)
                                    const isCurrentDay: boolean = date.isSame(currentDate)
                                    const isSelectedDay: boolean = date.isSame(selectedDate[0]) || date.isSame(selectedDate[1])
                                    const isBetweenDay: boolean = date.isAfter(selectedDate[0]) && date.isBefore(selectedDate[1]) || date.isAfter(selectedDate[1]) && date.isBefore(selectedDate[0])

                                    return (
                                        <div
                                            ref={isSelectedDay ? dateTailRef : null}
                                            key={date.format('YYYY-MM-DD')}
                                            className={`col-span-1 day flex justify-center items-center px-1 py-2 cursor-pointer relative 
                                                ${isCurrentDay && 'text-red-500'} 
                                                ${isPastDay ? 'cursor-not-allowed' : isSelectedDay ? 'bg-blue-700 hover:bg-blue-700 text-white rounded selected' : isBetweenDay ? 'bg-blue-500 hover:bg-blue-500 text-white rounded' : 'bg-customGray hover:bg-blue-300 rounded'}`
                                            }
                                            onClick={() => !isPastDay && selectDateHandler(date)}
                                        >
                                            <div>
                                                <div className={`day-number text-center m-1 ${!isPastDay && 'font-semibold'}`}>{day}</div>
                                                <div className="day-info text-center" style={{ fontSize: '0.6rem' }}>
                                                    {
                                                        !isLoading ?
                                                            <div className={'absolute bottom-0.5 left-0 right-0'}>
                                                                {!isPastDay || isBeforeStartDay ? getMonthData(date) : ''}
                                                            </div>
                                                            :
                                                            !isPastDay && (<Skeleton className={'absolute bottom-0.5 left-0 right-0'} height="0.3rem"></Skeleton>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
