import React from 'react';
import {LoopCircleLoading} from "react-loadingg";
import {makeStyles} from "@material-ui/core/styles";

export function LoadingOverlayCustom( props )
{
    const useStyles = makeStyles((theme) => ({
        overlay: {
            display: 'block',
            backgroundColor: 'black',
            height: '100%',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 50,
            opacity: 0.6
        },
        container: {
            padding: props.hasOwnProperty( "isMobile" ) && props.isMobile ? "23%" : "20vw 38vw",
            position: "fixed",
            top: props.hasOwnProperty( "isMobile" ) && props.isMobile ? "25%" : "0",
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: "60",
            opacity: 1
        },
        box: {
            height: "200px",
            width: "auto",
            zIndex: 60,
            display: "grid",
            gridTemplateRows: "25% 75%",
            backgroundColor: "#fff"
        },
        label: {
            fontSize: "13px",
            fontWeight: "500",
            textAlign: "center",
            display: "table-cell",
            verticalAlign: "middle"
        }
    }));

    const classes = useStyles();

    return(
        <div>
            <div className = { classes.overlay } />
            <div className = { classes.container }>
                <div className = { classes.box }>
                    <div style = {{ position: "relative", height: "100%", display: "table" }}>
                        <div className = { classes.label }>
                            { props.msg }
                        </div>
                    </div>
                    <div style = {{position: "relative"}}>
                        <LoopCircleLoading size = { 20 } color = { '#1976d0' } />
                    </div>
                </div>
            </div>
        </div>
    )
}