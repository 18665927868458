import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getAgentInvoicePdfUrl,
  getAgentTransactionURL,
} from "../../api/helpers/api-constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IDateRange } from "../../api/types";
import PostFetcher from "../../api/helpers/postFetcher";
import { LinearProgress, TextField, Tooltip, Button } from "@mui/material";
import { useReduxReducer } from "../../redux/store";
import  { fetcherWithoutJSON } from "api/helpers/fetcher";
import { usePagination } from "react-use-pagination";
import Pagination from "../../components/ui/Pagination/Pagination";
import currencyFormat from "utils/currencyFormat";
import { Tabs, Tab } from "@mui/material"; 
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import SyncIcon from '@mui/icons-material/Sync';
import { format } from 'date-fns';

interface ITransactionTable {
  dateRange: IDateRange;
}
interface IBalanceDetails {
  BalanceByTermDate: number;
  Balance: number;
  PartnerCode: string;
  SepaLimit: number;
}
interface ITransaction {
  Type: number;
  Amount: number;
  Explain: string;
  PaymentID: number;
  Paid: number;
  Payments: IPayment[];
  ExplainDetailed: string;
  InvoiceNo: string;
  ID: number;
  PartnerCode: string;
  Date: string;
  LimitAfterTran: IBalanceDetails | null;
  RefType?: number;
  RefPNR?: string;
}
interface IAgencyLimit {
  BalanceByTermDate: number;
  Balance: number;
  PartnerCode: string;
  SepaLimit: number;
}
interface IPayment {
  Amount: number;
  Explain: string;
  PaymentID: number;
  ID: number;
  Date: string;
}

interface IPaymentMethod {
  ID: number;
  Name: string;
}

interface IAgencyTransactionModel {
  AgentLimit: IAgencyLimit;
  PaymentMethods: IPaymentMethod[];
  Transactions: ITransaction[];
  agencyName: string;
}



export const TransactionTable: FC<ITransactionTable> = ({ dateRange }) => {
  const PAGE_SIZE: number = 10;

  const [reverseData, setreverseData] = useState<ITransaction[]>([]); // reversed to sort newer data first
  const [partnerCode, setPartnerCode] = useState<string>("");
  const [selectedInvoiceNo, setSelectedInvoiceNo] = useState<string>("");
  const { t } = useTranslation();
  const { isAdmin, userData} = useReduxReducer((state) => state.general);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const [agencyName, setagencyName] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState<number>(5); // State for selected tab
  const [paymentMethods, setpaymentMethods] = useState<IPaymentMethod>();
  const [agencyLimit, setAgencyLimit] = useState<IAgencyLimit | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    startIndex,
    endIndex,
    currentPage,
    setPage,
    setPreviousPage,
    setNextPage,
    previousEnabled,
    nextEnabled
  } = usePagination({
    totalItems: rowCount,
    initialPageSize: PAGE_SIZE
  });
  function parseDate(dateStr: string): Date {
    if (!dateStr) {
        throw new Error('Invalid date string');
    }

    let parts = dateStr.split(' ');
    if (parts.length !== 2) {
        throw new Error('Invalid date string format');
    }

    let [datePart, timePart] = parts;

    let dateParts = datePart.split('.');
    if (dateParts.length !== 3) {
        throw new Error('Invalid date string format');
    }

    let timeParts = timePart.split(':');
    if (timeParts.length < 2 || timeParts.length > 3) {
        throw new Error('Invalid date string format');
    }

    let [day, month, year] = dateParts;
    let [hour, minute, secondPart] = timeParts;

    let second = 0;
    let millisecond = 0;

    if (secondPart) {
        let secondParts = secondPart.split('.');
        second = Number(secondParts[0]);
        millisecond = secondParts.length > 1 ? Number(secondParts[1]) : 0;
    }

    // Month is 0-based in Date constructor, so we subtract 1 from month
    return new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), second, millisecond);
}

function sortTransactionsByDate(transactions: ITransaction[]): ITransaction[] {
  let sorted = transactions.slice(0); // Make a copy to avoid mutating the original array
  
  // Sort the array by Date and ID
  sorted.sort((a, b) => {
      // Convert date strings to Date objects
      let dateA = parseDate(a.Date);
      let dateB = parseDate(b.Date);
      
      // Compare dates first
      let dateComparison = dateB.getTime() - dateA.getTime();
      
      if (dateComparison !== 0) {
          return dateComparison; // If dates are different, use the date comparison result
      } else {
          // If dates are the same, compare by ID (
          return b.ID - a.ID;
      }
  });
  
  return sorted;
}
  useEffect(() => {
    if (partnerCode.length === 0 || partnerCode.length > 4) fetchData();
  }, [partnerCode, tabValue]); // Add tabValue as a dependency

  const fetchData = async () => {
    setIsLoading(true)
    const agentInvoiceListRequest = {
      partnerCode: partnerCode,
      invoiceDateBegin: dateRange.startDate
        ?.endOf("day")
        .format("DD.MM.YYYY HH:mm:ss"),
      invoiceDateEnd: dateRange.endDate?.endOf("day").format("DD.MM.YYYY HH:mm:ss"),
    };

    const response = await PostFetcher(
      getAgentTransactionURL(),
      agentInvoiceListRequest
    );
    let filteredData = 
    sortTransactionsByDate(response.Transactions)
    if (tabValue > 0) {
      filteredData = filteredData.filter(
        (transaction: ITransaction) => transaction.Type === tabValue
      );
    }
    if(response){
      setpaymentMethods(response.PaymentMethods);
       setAgencyLimit(response.AgentLimit) 
    
      setreverseData([...filteredData]);
      setRowCount(filteredData.length);
      setagencyName(partnerCode.length === 0 ? null : response.agencyName);
      setIsLoading(false)
    }
  };
  
  function limitRefText(refType: number): JSX.Element {
    let text: string;

    switch (refType) {
        case 1:
            text = "Invoice";
            break;
        case 2:
            text = "Invoice Payment";
            break;
        case 3:
            text = "Credit limit insert";
            break;
        case 4:
            text = "Bank Payment";
            break;
        default:
            text = "";
    }

    return (
        <>
            {text}
        </>
    );
}

  const clickHandler = (invoiceNumber: string) => {
    if (!invoiceNumber) {
      return ;
    }
  
    setSelectedInvoiceNo(invoiceNumber);
    setShowErrorAlert(false);
  
    fetcherWithoutJSON(getAgentInvoicePdfUrl(invoiceNumber))
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        window.open(link.href, "_blank")?.focus();
      })
      .catch((error) => {
        console.log(error);
        setShowErrorAlert(true);
      })
      .finally(() => {
        setSelectedInvoiceNo("");
      });
  };
  

  const amountTemplate = (transaction: ITransaction) => {
    return (
      <span className={transaction.Amount < 0 ? "text-red-600" : "text-green-600"}>
        {transaction.Amount.toFixed(2).replace(".", ",")} €
      </span>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <>
      <div className={`mt-16 mb-3 grid grid-cols-2 align-bottom`}>
        <div className={`col-span-1`}>
          <h1 className="text-lg font-semibold">{t("general.transaction")}</h1>
        </div>

        {(isAdmin || userData?.company.includes("10999")) && (
          <div className={`col-span-2 text-right`}>
            {agencyName && (
              <span className="py-2 flex justify-end items-center font-semibold text-red-500">
                {" "}
                {t("manage-agencies.agency")}: {agencyName}
              </span>
            
            )}
            <TextField
              className="pr-2"
              label={t("manage-users.agency-number")}
              size="small"
              defaultValue={""}
              onChange={(e) => {
                setPartnerCode(e.target.value);
              }}
            />
          </div>
        )}

      </div>
      <div className="flex justify-between items-center"> 
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="transaction type tabs"
      >
        <Tab label={t("invoice.general.type3")} value={5} />
        <Tab label={t("invoice.general.type1")} value={1} />
        <Tab label={t("invoice.general.type2")} value={2} />
        <Tab label={t("invoice.general.type4")} value={4} />
      </Tabs>
      <ButtonPrimary onClick={fetchData}>
      <SyncIcon className="w-5 h-5"/>
        {t("general.refresh")}
      </ButtonPrimary>    </div>
   
      
        <div className="relative overflow-hidden rounded-xl border border-neutral-100 bg-white transition-shadow dark:border-neutral-800 dark:bg-neutral-900 }">
        {agencyLimit && (
          <div className="p-2 flex justify-end space-x-4">
            {agencyLimit.Balance !== agencyLimit.BalanceByTermDate && <span className={agencyLimit!.Balance < 0 ? "text-red-600 flex justify-end space-x-4 font-semibold" : "text-green-600 font-semibold flex justify-end space-x-4"}>
            İleri Tarihli Bakiye: {currencyFormat(agencyLimit.Balance)}
            </span>}
            {
            <span className={agencyLimit!.BalanceByTermDate < 0 ? "text-red-600 flex justify-end space-x-4 font-semibold" : "text-green-600 font-semibold flex justify-end space-x-4"}>
            {t("wallet.balance")}: {currencyFormat(agencyLimit.BalanceByTermDate)}
            </span>}
            {agencyLimit.SepaLimit !== 0 && 
            <span className={agencyLimit!.SepaLimit < 0 ? "text-red-600 flex justify-end space-x-4 font-semibold" : "text-green-600 font-semibold flex justify-end space-x-4"}>
              Sepa: {currencyFormat(agencyLimit.SepaLimit)}
            </span>}
           
          </div>
        )}
        {isLoading 
        ? <LinearProgress/>
        : null }
      { tabValue === 5 
      
      ? <DataTable
      value={reverseData
        .filter((transaction) => 
          transaction.RefType !== 2 && 
          !(transaction.RefType === 3 && transaction.Explain.includes("paid")) 
          // bank payment and invoice payment have same refType so, only way to seperate them look into explain string that includes "paid" => IT S NOT IDEAL TODO, fix
        )
        .slice(startIndex, endIndex + 1)}
      selectionMode="single"
      onSelectionChange={(e) => clickHandler(e.value.InvoiceNo)}
      loading={selectedInvoiceNo.length > 0}
      className={"text-sm"}
    >
       <Column
    className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
    header={t("react.general.date")}
    field="Date"
    style={{ width: "15%" }}
    body={(transaction: ITransaction) => 
        format(parseDate(transaction.Date), 'dd:MM:yyyy HH:mm:ss')
    }
></Column>
       
       
        <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("invoice.general.summary")}
          field="Explain"
          style={{width: "20%"}}
          body={(transacion: ITransaction) => (
            <div>
            {transacion.Explain.length > 40 ? (
              <Tooltip title={transacion.Explain}>
                <span>{transacion.Explain.substring(0, 40)}...
                <InfoOutlinedIcon fontSize="small" className="ml-1 cursor-pointer text-blue-500" /></span>
              </Tooltip>
            ) : (
              <>
                <span>{transacion.Explain}</span>
                <span>/ PNR: {transacion.RefPNR}</span>
                </>
            
            )}
          </div>
          )}
        ></Column>
        <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("invoice.general.amount")}
          field="Amount"
          body={amountTemplate}
          style={{ width: "10%" }}
        ></Column>
           <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("wallet.balance")}
          field=""
          style={{ width: "10%" }}
          body={(transaction) => (
            <span>
              {transaction.LimitAfterTran && currencyFormat(transaction.LimitAfterTran.Balance) }
            </span>
          )}
        ></Column>
         <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("generals.details")}
          field=""
          style={{ width: "10%" }}
          body={(transaction) => (
            <span>
              {limitRefText(transaction.RefType)}
            </span>
          )}
        ></Column>
          
          
               
            
               
          
      </DataTable> 
      : 
      <DataTable
        value={reverseData.slice(startIndex, endIndex + 1)}
        selectionMode="single"
        onSelectionChange={(e) => clickHandler(e.value.InvoiceNo)}
        loading={selectedInvoiceNo.length > 0}
        className={"text-sm"}
      >
       <Column
    className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
    header={t("react.general.date")}
    field="Date"
    style={{ width: "15%" }}
    body={(transaction: ITransaction) => 
        format(parseDate(transaction.Date), 'dd:MM:yyyy HH:mm:ss')
    }
></Column>
        {tabValue !== 4 && <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("general.invoice.number")}
          field="InvoiceNo"
          style={{ width: "10%" }}
        ></Column>}
       
        <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("invoice.general.summary")}
          field="Explain"
          style={{width: "20%"}}
          body={(transacion: ITransaction) => (
            <div>
            {transacion.Explain.length > 40 ? (
              <Tooltip title={transacion.Explain}>
                <span>{transacion.Explain.substring(0, 40)}...
                <InfoOutlinedIcon fontSize="small" className="ml-1 cursor-pointer text-blue-500" /></span>
              </Tooltip>
            ) : (
                <span>{transacion.Explain}</span>            
            )}
          </div>
          )}
        ></Column>
        <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("invoice.general.amount")}
          field="Amount"
          body={amountTemplate}
          style={{ width: "10%" }}
        ></Column>
          {tabValue === 5 && <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("wallet.balance")}
          field=""
          style={{ width: "10%" }}
          body={(transaction) => (
            <span>
              {transaction.LimitAfterTran && currencyFormat(transaction.LimitAfterTran.Balance) }
            </span>
          )}
        ></Column>}
         {tabValue === 5 && <Column
          className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
          header={t("generals.details")}
          field=""
          style={{ width: "10%" }}
          body={(transaction) => (
            <span>
              {limitRefText(transaction.RefType)}
            </span>
          )}
        ></Column>}
          
            {tabValue == 1 && <Column
                className="border-b border-slate-100 text-green-600 dark:border-slate-700 dark:text-slate-400 font-bold"
                header={t("invoice.general.paid")}
                body={(transaction) => (
                  <span>
                    {currencyFormat(transaction.Paid) }
                  </span>
                )}
                style={{ width: "10%" }}
              ></Column> }
                 {tabValue == 1 && <Column
                className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
                header={t("invoice.general.remain")}
                body={(transaction) => (
                  <span className={transaction.Amount + transaction.Paid < 0 ? "text-red-600" : "text-green-600"}>
                    {currencyFormat(transaction.Amount +  transaction.Paid) }
                  </span>
                )}
                style={{ width: "10%" }}
              ></Column> }
             {tabValue === 1 && <Column
                className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold"
                header={t("invoice.general.paymentdetails")}
                body={(transaction) => (
                 <>{transaction.Payments && transaction.Payments.map((p: any)=>
                  <ul><span>{p.Explain && p.Explain}</span><span>{p.Explain && p.Amount}</span></ul> )}</>
                )}
                style={{ width: "10%" }}
              ></Column>}
                {tabValue === 4 && <Column
                className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400 font-bold "
                header={t("invoice.general.transactiondetails")}
                body={(transaction) => (
                 <>{transaction.ExplainDetailed && transaction.ExplainDetailed}</>
                )}
                style={{ width: "40%" }}
              ></Column>}
          
      </DataTable>}
        {!showErrorAlert && (
          <div className="mt-5 py-4 flex items-center justify-center">
            <Pagination
              currentPage={currentPage}
              setPage={setPage}
              totalPages={Math.ceil(rowCount / PAGE_SIZE)}
              setPreviousPage={setPreviousPage}
              setNextPage={setNextPage}
              idToScrollOnPageChange="transactions"
              nextEnabled={nextEnabled}
              previousEnabled={previousEnabled}
            />
          </div>
        )}
      </div>
    </>
  );
};

