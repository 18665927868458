import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import FlightIcon from '@mui/icons-material/Flight';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { IPackageTourBanner } from 'api/types';
import PackageTourQueryParametersService from 'services/PackageTourQueryParameterService';
import { useTranslation } from 'react-i18next';

interface PackageTourBannerCardProps {
  banner: IPackageTourBanner;
}

export const PackageTourBannerCard: React.FC<PackageTourBannerCardProps> = ({ banner }) => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const handleNavigateSearch = () => {
    const dateRange = {
      startDate: moment().add(3, "months"),
      endDate: moment().add(4, "months")
    };

    const urlSearchParams = PackageTourQueryParametersService.encode(
      banner.packageLocation,
      banner.dateRange,
      banner.paxList,
      banner.airportList,
      banner.tripDurationAsNights,
      "",
    );

    console.log(urlSearchParams)
    if (urlSearchParams) {
      navigate(`/tourpackage?${urlSearchParams.toString()}`);
    }
  };

  return (
    <div
      onClick={handleNavigateSearch}
      className="bg-white rounded-lg shadow-md overflow-hidden transition duration-200 ease-in-out hover:shadow-2xl hover:cursor-pointer"
    >
      <figure className="w-full">
        <div className="relative h-48">
          <img
            src={banner.imageUrl}
            alt={banner.packageLocation!.title}
            className="object-cover w-full h-full"
          />
        </div>
      </figure>
      <div className="p-4">
        <div className="flex flex-col gap-3">
          <div className="space-y-2">
            <div className="flex items-center gap-2 text-base text-gray-600 whitespace-nowrap">
              <FlightIcon className='text-primary-6000' sx={{ fontSize: 16 }} />
              <span>{banner.airportList[0].text}</span>
            </div>
            <div className="flex items-center gap-2">
              <WbSunnyIcon sx={{ fontSize: 16, color: '#f59e0b' }} />
              <span className="text-base font-bold text-gray-900 whitespace-nowrap">
                {banner.packageLocation!.title}
              </span>
            </div>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <span>
              {moment(banner.dateRange.startDate).format('DD.MM.YYYY')} - {moment(banner.dateRange.endDate).format('DD.MM.YYYY')}
            </span>
            <span className="ml-auto">
              {banner.tripDurationAsNights} {t("hotel.nights")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};