import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {DataFilters} from "../../api/types";

export interface CounterState {
  data: DataFilters;
  activeFilters: {
    name?: string;
    priceRange?: {
      min: number;
      max: number;
    };
    stars?: number;
    accommodationType?: Array<string>;
    amenities?: Array<string>;
    boards?: Array<string>;
    city?:Array<string>;
  };
}

export type Filter = keyof CounterState["activeFilters"];

const initialState: CounterState = {
  data: {
    accommodationType: [],
    amenities: [],
    boards: [],
  },
  activeFilters: {},
};

export const filtersSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setDataFilters: (state, action: PayloadAction<DataFilters>) => {
      state.data = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<{
        filter: Filter;
        data: string | number | { min: number; max: number } | Array<string>;
      }>
    ) => {
      //@ts-ignore
      state.activeFilters[action.payload.filter] = action.payload.data;
    },
    clearFilter: (state, action: PayloadAction<{ filter: Filter }>) => {
      delete state.activeFilters[action.payload.filter];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDataFilters, setFilter, clearFilter } = filtersSlice.actions;

export default filtersSlice.reducer;
