import React, { useState } from 'react';
import Select from 'components/ui/Select/Select';
import Input from 'components/ui/Input/Input';
import postFetcher, { postParamRequest } from 'api/helpers/postFetcher';
import Swal from 'sweetalert2';
import { postUpdateWCHR } from 'api/helpers/api-constants';
import { useTranslation } from 'react-i18next';
import { fetcherWithoutJSON } from 'api/helpers/fetcher';

interface Option {
  value: string;
  label: string;
}

interface UpdateActionWCHRProps {
  ssrRecordID: string; // Prop for ssrRecordID
  manualStatus: string;
  manualRemarks?: string;
}

const UpdateActionWCHR: React.FC<UpdateActionWCHRProps> = ({ ssrRecordID , manualStatus, manualRemarks}) => {
  const {t} = useTranslation()  
  const [selectedOption, setSelectedOption] = useState<string>(manualStatus || '');
  const [remarks, setRemarks] = useState<string>(manualRemarks || '');
  const options: Option[] = [
    { value: 'CONFIRMED', label: t("b2c-app.generals.done") },
    { value: 'PENDING', label: t("b2c-app.profile.bookings.status.tf_pending_confirmation") },
    { value: 'FAILED', label: t("flight.ssr.wchr.failed") },
  ];
  const handleOptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOption(event.target.value as string);
  };

  const handleRemarksChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRemarks(event.target.value as string); 
  };
 
  const handleUpdate = async () => {
    try {
      const response = await fetcherWithoutJSON(
        postUpdateWCHR(ssrRecordID, selectedOption, remarks), {}
      );
      if (response.ok) 
      Swal.fire({
        title: "Success!",
        text: "İşlem başarılı lütfen sayfayı yenileyiniz",
        icon: "success",
        confirmButtonText: t("b2c-app.flights.inactivity-window-button"),
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
        else
    Swal.fire(t("app.general-error"), "", "error");
      }
    catch (error) {
      console.error("Error occurred:", error);
      Swal.fire(t("app.general-error"), "", "error");
    }
    // Reset form after update
    setSelectedOption('');
    setRemarks('');
  };

  return (
    <div className="grid grid-flow-col justify-center gap-2">
      <Select
        className="lg:w-40"
        name="manuelStatus"
        id="manuelStatus"
        onChange={handleOptionChange}
        value={selectedOption}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Input
        id="remarks"
        value={remarks}
        onChange={handleRemarksChange}
        className="h-11 mr-4"
        placeholder={t("booking.remarks")}
      />
      <button
        className="p-2 rounded bg-primary-6000 text-white hover:bg-primary-500"
        onClick={handleUpdate}
        disabled={!selectedOption}
      >
        {t("flights.submit")}
      </button>
    </div>
  );
};

export default UpdateActionWCHR;
