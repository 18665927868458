import React from 'react';
import { useTranslation } from 'react-i18next';

const InsuranceInfo: React.FC = () => {
  const {t} = useTranslation()
    return (
    <div className="container mx-auto p-4 mt-12 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4">{t("insurance.package_3")}</h1>
      <p className="mb-4">
      {t("react.insurance.text-1")}
      </p>
      <p className="mb-4">
      {t("react.insurance.text-2")} 
      </p>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">1) {t("react.insurance.title1")}</h2>
        <ul className="list-disc pl-5">
          <li>{t("react.insurance.text-3")}</li>
        </ul>
      </div>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">2) {t("react.insurance.title2")}</h2>
        <ul className="list-disc pl-5">
          <li>{t("react.insurance.list1")}</li>
          <li>{t("react.insurance.list2")}</li>
          <li>{t("react.insurance.list3")}</li>
          <li>{t("react.insurance.list4")}</li>
          <li>{t("react.insurance.list5")}</li>
        </ul>
      </div>
      <p className="mt-4">
      {t("react.insurance.text-4")} 
      </p>
    </div>
  );
};

export default InsuranceInfo;