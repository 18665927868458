import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SecurityIcon from "@material-ui/icons/Security";
import { t } from "i18next";
import Grid from "@material-ui/core/Grid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { InfoOutlined } from "@mui/icons-material";
import AtrSwitch from "../utils/UI/AtrSwitch";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 20%), 0px 1px 5px 0px rgb(0 0 0 / 20%)",
    fontSize: 15,
  },
}));

const marker = {
  1: false,
  2: false,
  3: false,
};

export function AddInsuranceToFlight(props) {
  const passengerCount = props.adtCount + props.chdCount + props.infCount;

  const [isWideSize, setIsWideSize] = useState(window.innerWidth > 1100);
  const [isMediumSize, setIsMediumSize] = useState(
    window.innerWidth <= 1100 && window.innerWidth > 900
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [selectedInsurances, setSelectedInsurances] = useState(
    props.adtCount + props.chdCount + props.infCount > 1
      ? [
          "package_fam_1",
          "package_fam_1_o_s",
          "package_fam_2",
          "package_fam_2_o_s",
          "package_fam_3",
          "package_fam_3_o_s",
          "insolvencyInsurance",
        ]
      : [
          "package_1",
          "package_1_o_s",
          "package_2",
          "package_2_o_s",
          "package_3",
          "package_3_o_s",
          "insolvencyInsurance",
        ]
  );
  const [isFamily, setIsFamily] = useState(
    props.infCount > 0 || props.chdCount > 0 || props.adtCount > 1
  );

  const updateSize = (e) => {
    if (e.currentTarget.window.innerWidth > 1100 && !isWideSize) {
      setIsWideSize(true);
      setIsMediumSize(false);
      setIsMobile(false);
    } else if (e.currentTarget.window.innerWidth <= 900 && !isMobile) {
      setIsWideSize(false);
      setIsMediumSize(false);
      setIsMobile(true);
    } else if (
      e.currentTarget.window.innerWidth > 900 &&
      e.currentTarget.window.innerWidth <= 1100 &&
      !isMediumSize
    ) {
      setIsWideSize(false);
      setIsMediumSize(true);
      setIsMobile(false);
    }
  };
  window.addEventListener("resize", updateSize);

  const useStyles = makeStyles((theme) => ({
    container: {
      position: "relative",
      marginTop: "25px",
      //padding: "20px 20px 0 20px",
      padding: "20px",
      height: "auto",
      border: "1px solid #d2d0d0",
      backgroundColor: "#fff",
    },
    header: {
      fontSize: !isMobile ? "22px" : "18px",
      fontWeight: "400",
      paddingBottom: passengerCount > 1 ? "20px" : "10px",
    },
    switchSection: {
      paddingBottom: "10px",
    },
    definition: {
      color: "red",
      paddingTop: "15px",
    },
    definitionheader: {
      fontSize: "16px",
    },
    definitionblock: {
      fontSize: "12px",
    },
  }));

  let insurancePrices = props.insurancePrices;

  const classes = useStyles();

  const initMarker = (value) => {
    if (marker[value]) {
      marker[1] = false;
      marker[2] = false;
      marker[3] = false;
      marker[4] = false;
      marker[5] = false;
      marker[6] = false;
    } else {
      marker[1] = false;
      marker[2] = false;
      marker[3] = false;
      marker[4] = false;
      marker[5] = false;
      marker[6] = false;

      marker[value] = true;
    }
  };

  useEffect(() => {
    setSelectedInsurances(
      isFamily
        ? [
            "package_fam_1",
            "package_fam_1_o_s",
            "package_fam_2",
            "package_fam_2_o_s",
            "package_fam_3",
            "package_fam_3_o_s",
            "insolvencyInsurance",
          ]
        : [
            "package_1",
            "package_1_o_s",
            "package_2",
            "package_2_o_s",
            "package_3",
            "package_3_o_s",
            "insolvencyInsurance",
          ]
    );
    props.selectedInsurance(null);
  }, [isFamily]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>{t("react.insurance.addincurance")}</div>
      {props.adtCount > 1 ? (
        <div className={classes.switchSection}>
          <AtrSwitch
            isFamily={isFamily}
            changeIsFamily={() => setIsFamily(!isFamily)}
          />
        </div>
      ) : (
        ""
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <InsuranceItem
            withDeductible={false}
            insurance={insurancePrices[selectedInsurances[2]]}
            marker={
              props.isInsuranceSelected && marker[2]
                ? { borderColor: "#89bdef", boxShadow: "0 0 5px #1976d0" }
                : {}
            }
            selectedInsurance={(insurance) => {
              initMarker(2);
              if (marker[2]) {
                props.selectedInsurance(insurance);
              } else {
                props.selectedInsurance(null);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InsuranceItem
            withDeductible={true}
            insurance={insurancePrices[selectedInsurances[3]]}
            marker={
              props.isInsuranceSelected && marker[4]
                ? { borderColor: "#89bdef", boxShadow: "0 0 5px #1976d0" }
                : {}
            }
            selectedInsurance={(insurance) => {
              initMarker(4);
              if (marker[4]) {
                props.selectedInsurance(insurance);
              } else {
                props.selectedInsurance(null);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InsuranceItem
            withDeductible={false}
            insurance={insurancePrices[selectedInsurances[4]]}
            marker={
              props.isInsuranceSelected && marker[3]
                ? { borderColor: "#89bdef", boxShadow: "0 0 5px #1976d0" }
                : {}
            }
            selectedInsurance={(insurance) => {
              initMarker(3);
              if (marker[3]) {
                props.selectedInsurance(insurance);
              } else {
                props.selectedInsurance(null);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InsuranceItem
            isFamily={isFamily}
            withDeductible={true}
            insurance={insurancePrices[selectedInsurances[5]]}
            marker={
              props.isInsuranceSelected && marker[5]
                ? { borderColor: "#89bdef", boxShadow: "0 0 5px #1976d0" }
                : {}
            }
            selectedInsurance={(insurance) => {
              initMarker(5);
              if (marker[5]) {
                props.selectedInsurance(insurance);
              } else {
                props.selectedInsurance(null);
              }
            }}
          />
        </Grid>
        {/*<Grid item xs={12} md={12}>
                    <InsuranceItem
                        withDeductible={false}
                        insurance={insurancePrices[selectedInsurances[6]]}
                        marker={props.isInsuranceSelected && marker[6] ? {
                            borderColor: '#89bdef',
                            boxShadow: '0 0 5px #1976d0'
                        } : {}}
                        selectedInsurance={(insurance) => {
                            initMarker(6)
                            if (marker[6]) {
                                props.selectedInsurance(insurance);
                            } else {
                                props.selectedInsurance(null);
                            }
                        }}
                    />
                </Grid>*/}
      </Grid>
      {isFamily ? (
        <div className={classes.definition}>
          <div className={classes.definitionheader}>
            {t("insurance.definition.header")}:
          </div>
          <div className={classes.definitionblock}>
            {t("insurance.definition.content")}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function InsuranceItem(props) {
  //Package 1: Reise-Rücktrittskosten-Versicherung -> soll nicht mehr angeboten werden
  //Package 2: Reise-Rücktrittskosten-Versicherung inkl. Reise-Abbruch-Versicherung
  //Package 3: Versicherungspaket
  let price = props.insurance.price;
  let tarifCode = props.insurance.tarifcode;
  let tarifName = "";
  let icon = "";
  let tarifDescription = "";

  if (
    tarifCode.startsWith("288E001") ||
    tarifCode.startsWith("296E005") ||
    tarifCode.startsWith("296G001") ||
    tarifCode.startsWith("296G005")
  ) {
    tarifName = t("insurance.package_1");

    tarifDescription = t("insurance.description.package_1");
    /*if ( props.withDeductible )
            tarifDescription = t( "insurance.description.package_1_with_Deductible" );*/

    icon = <SecurityIcon style={{ fontSize: "130px" }} />;
  } else if (
    tarifCode.startsWith("296Z067") ||
    tarifCode.startsWith("296Z069") ||
    tarifCode.startsWith("296W067") ||
    tarifCode.startsWith("296W069")
  ) {
    tarifName = t("insurance.package_2");

    tarifDescription = t("insurance.description.package_2");
    if (props.withDeductible)
      tarifDescription = t("insurance.description.package_2_with_deductible");

    icon = <SecurityIcon style={{ fontSize: "130px" }} />;
  } else if (
    tarifCode.startsWith("296P073") ||
    tarifCode.startsWith("296P071") ||
    tarifCode.startsWith("296R231") ||
    tarifCode.startsWith("296R232")
  ) {
    tarifName = t("insurance.package_3");

    tarifDescription = t("insurance.description.package_3");
    if (props.withDeductible)
      tarifDescription = t("insurance.description.package_3_with_deductible");

    icon = <SecurityIcon style={{ fontSize: "130px" }} />;
  } else if (tarifCode === "AT932") {
    tarifName = t("insurance.name.insolvency");

    tarifDescription = t("insurance.name.insolvency.description");

    icon = <SecurityIcon style={{ fontSize: "130px" }} />;
  }

  if (tarifCode !== "AT932")
    return (
      <div
        className="addinsurance-container"
        style={props.marker}
        onClick={() => props.selectedInsurance(props.insurance)}
      >
        <div style={{ zIndex: "10", position: "absolute" }}>
          <Grid container spacing={2} direction={"row"}>
            <Grid item>
              <div className="addrentalcartoflight-firstline">
                <a style={{ fontSize: "14px", fontWeight: "600" }}>
                  {tarifName}
                </a>
                &nbsp;
                <LightTooltip title={tarifDescription}>
                  <InfoOutlined style={{ fontSize: "15px" }} />
                </LightTooltip>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={3} md={3} />
              <Grid item xs={9} md={9}>
                <div className="addinsurance-thirdline">
                  <div style={{ fontSize: "14px", fontWeight: "700" }}>
                    {props.withDeductible
                      ? t("insurance.price-without-deductible")
                      : t("insurance.price-with-deductible")}
                  </div>
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: "21.5px",
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    {price.replace(".", ",")} €
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            zIndex: "0",
            position: "absolute",
            color: "#1976d021",
            transform: "rotate(-18deg)",
            opacity: "0.55",
          }}
        >
          {icon}
        </div>
      </div>
    );
  else
    return (
      <div
        className="addinsurance-insolvency-container"
        style={props.marker}
        onClick={() => props.selectedInsurance(props.insurance)}
      >
        <div>
          <Grid container spacing={2} direction={"row"}>
            <Grid item xs={9} md={9}>
              <div className="addinsurance-insolvency-firstline">
                {tarifName}
                &nbsp;
                <LightTooltip title={tarifDescription}>
                  <InfoOutlined style={{ fontSize: "15px" }} />
                </LightTooltip>
              </div>
            </Grid>
            <Grid item xs={3} md={3}>
              <div className="addinsurance-insolvency-thirdline">
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                >
                  {price.replace(".", ",")} €
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
}
