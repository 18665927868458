import React, {FC} from "react";
import {IToastAlert} from "../../../api/types";

export const ToastAlert: FC<IToastAlert> = ({msg, type}) => {
    let alertClass: string = "text-green-800 bg-green-100 dark:text-green-400";
    switch (type) {
        case "error": {
            alertClass = "text-red-800 bg-red-100 dark:text-red-400"
            break
        }
        case "warning": {
            alertClass = "text-yellow-800 bg-yellow-100 dark:text-yellow-400"
            break
        }
        case "info": {
            alertClass = "text-blue-800 bg-blue-100 dark:text-blue-400"
            break
        }
    }

    return (
        <div
            className={"absolute p-4 mb-4 text-sm rounded-lg dark:bg-gray-800 z-40" + alertClass}
            role="alert"
        >
            {msg}
        </div>
    )
}