import { Popover } from "@headlessui/react";
import {FC, useEffect, useState} from "react";
import NotificationModalScroll from "../../ui/Modal/NotificationModalScroll";
import fetcher from "../../../api/helpers/fetcher";
import {getNotificationUrl} from "../../../api/helpers/api-constants";
import {useReduxReducer} from "../../../redux/store";

interface INotification {}

export const Notification: FC<INotification> = () => {
  const [notifications, setNotifications] = useState([])
  const [open, setOpen] = useState(false)

    const { isApp, isMobile } = useReduxReducer(state => state.general)

  const modalHandler = () => {
      setOpen(!open)
  }

  useEffect(() => {
    const fetchData = async () => {
    const _notifications = await fetcher(getNotificationUrl());
   setNotifications(_notifications);
    }
      fetchData();
  },[open])

  return (
    <div className="">
      <Popover className="relative">
        <>
          <Popover.Button onClick={modalHandler}
              className={`text-opacity-90
                 group  relative inline-flex items-center rounded-full pt-2  md:text-[22px] font-medium hover:bg-gray-100 hover:text-opacity-100
                  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 dark:hover:bg-neutral-800`}
          >
            {
              notifications.length > 0 ?
                <span className="absolute top-3 right-3 h-2 w-2 rounded-full bg-red-500"/> :
                  ''
            }
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </Popover.Button>
        </>
      </Popover>
    <NotificationModalScroll
          closeModal={modalHandler}
          open={open}
          notifications={notifications}
      />
    </div>
  );
}
