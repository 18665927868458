import React, {FC, ReactNode, useRef} from 'react'
import {Messages} from "primereact/messages";
import {useMountEffect} from "primereact/hooks";
import {useTranslation} from "react-i18next";
import {useReduxReducer} from "../../../redux/store";

interface IGuestMessage {
    msg?: string
}

export const GuestMessage: FC<IGuestMessage> = ({ msg }) => {
    const { t } = useTranslation();
    const { isApp } = useReduxReducer((state) => state.general);

    const msgs = useRef<Messages | null>(null);

    useMountEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: '', detail: registrationMsg(), closable: false });
        }
    });

    const registrationMsg = (): ReactNode => {
        return (
            <>
                <div>{msg ? msg : t('general.register')}</div>
                {isApp && <div className={`text-md text-atrRed`}>{t('general.register.click-here')}</div>}
            </>
        )
    }

    const appRegistrationSite = () => {
        if (!window.location.hash.includes('&blocked=1'))
            window.location.hash = window.location.hash + '&blocked=1'
    }

    return (
        <Messages onClick={appRegistrationSite} className={`guest`} ref={msgs} />
    )
}
