import React, {FC, useState} from 'react'
import {HotelSearchAutocompleteResult, SearchPayload} from "../../../../api/types";
import {ISearchAutocompleteResult, MobileAutoComplete} from "../../SectionHeroArchivePage/MobileAutoComplete";
import {getAirportAutocompleteUrl, getHotelSearchAutocompleteUrl} from "../../../../api/helpers/api-constants";
import {useTranslation} from "react-i18next";
import {RoomInput} from "../GuestsInput";
import {DateRange, MissingInput} from "./index";
import {MobileHotelGuestsRoomSelection} from "./MobileHotelGuestsRoomSelection";
import {MobileHotelCountrySelection} from "./MobileHotelCountrySelection";
import {MobileCustomCalendar} from "../../CustomCalendar/MobileCustomCalendarDialog";

interface IHotelSearchFormMobile {
    queryParameters: SearchPayload|null
    hotel: HotelSearchAutocompleteResult|null
    setHotel: (value: HotelSearchAutocompleteResult) => void
    dateRange: DateRange
    setDateRange: (value: DateRange) => void
    nationality: string
    setNationality: (value: string) => void
    guests: RoomInput[]
    setGuests: (value: RoomInput[]) => void
    missingInputError: MissingInput
    handleSubmit: (value: React.FormEvent) => void
}

export const HotelSearchFormMobile: FC<IHotelSearchFormMobile> = (
    {
        queryParameters,
        hotel,
        setHotel,
        dateRange,
        setDateRange,
        nationality,
        setNationality,
        guests,
        setGuests,
        missingInputError,
        handleSubmit
    }) => {
    const { t } = useTranslation()

    const autocompleteHandler = (value: ISearchAutocompleteResult) => {
        setHotel({id: value.id, label: value.label!, type: value.type!})
    }

    return (
        <div className={`bg-white px-3 pt-2 pb-3`}>
            <div className={`flex w-full gap-x-2 py-2 justify-end`}>
                <MobileHotelCountrySelection
                    nationality={nationality}
                    setNationality={setNationality}
                />
                <MobileHotelGuestsRoomSelection
                    guests={guests}
                    setGuests={setGuests}
                />
            </div>
            <div className={`relative justify-center items-center`}>
                <div className={'w-full'}>
                    <MobileAutoComplete
                        className={`${missingInputError.location ? 'border-atrRed' : 'bg-gray-200'} rounded-lg `}
                        defaultValue={t('hotel.searching-for-best-hotel-offers')}
                        selectedDestination={{...hotel, text: hotel?.label} as ISearchAutocompleteResult}
                        setDestination={autocompleteHandler}
                        getUrl={getHotelSearchAutocompleteUrl}
                        placeholder={'Location'}
                    />
                    {missingInputError.location && <small className={`text-atrRed xxs:text-md`}>{t('react.validation.requiredfield')}</small>}
                </div>
                <div className={'w-full mt-2.5'}>
                    <MobileCustomCalendar
                        range={true}
                        selectedDate={[dateRange.startDate, dateRange.endDate]}
                        setSelectedDate={(value) => setDateRange({startDate: value[0], endDate: value[1]})}
                    />
                </div>
                <div className={`pt-3`}>
                    <button
                        className="flex h-12 w-full items-center justify-center rounded-lg bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none md:h-12 md:w-16 font-semibold xxs:text-md"
                        onClick={(e) => handleSubmit(e)}
                    >{'Hotel Suchen'}</button>
                </div>
            </div>
        </div>
    )
}
