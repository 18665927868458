import React from 'react';
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
  className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ className = '' }) => {
  const navigate = useNavigate();
  const handleClick = () => {
   navigate(-1);
  };
  const { t } = useTranslation();

  return (
    <button
      className={`p-1 flex items-center justify-center rounded !leading-none disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ml-4 mt-4 ${className}`}
      onClick={handleClick}
    >

      <ArrowLeftIcon className="w-5 h-5" />
        <span>{t("react.generel.back")}</span>
    </button>
  );
};

export default BackButton;
