export const getUserLanguage = () => localStorage.getItem('i18nextLng') || 'de';

const fetcher = (input: RequestInfo, init?: RequestInit | undefined) => {
  const headers = init?.headers as Headers || new Headers();
  headers.set('Accept-Language', getUserLanguage());

  const updatedInit = {
    ...init,
    headers,
  };

  return fetch(input, updatedInit).then(res => res.json());
};

export default fetcher;


// fetch response without parsing to Json 

export const fetcherWithoutJSON = (input: RequestInfo, init?: RequestInit | undefined) => {
  const headers = init?.headers as Headers || new Headers();
  headers.set('Accept-Language', getUserLanguage());

  const updatedInit = {
    ...init,
    headers,
  };

  return fetch(input, updatedInit);
};


// TODO: update fetcher headers to sent authentication token

// const fetcher = (input: RequestInfo, init?: RequestInit | undefined) => {
   
//     const token = 'token';
  
//     const newInit = init || {};
  
//     newInit.headers = {
//       ...(newInit.headers || {}),
//       Authorization: `Bearer ${token}`,
//     };
//       return fetch(input, newInit).then((res) => res.json());
//   };
  
//   export default fetcher;
  
// **LOCAL DEVELOPMENT FOR B2C** //
// const defaultHeaders = {
//   'Content-Type': 'application/json',
//   'X-Host': 'www.arasreisen.com',
// };

// const fetcher = (input: RequestInfo, init?: RequestInit | undefined) =>
//   fetch(input, {
//     ...init,
//     headers: {
//       ...defaultHeaders,
//       ...(init?.headers || {}),
//     },
//   }).then((res) => res.json());

// export default fetcher;

// export const fetcherWithoutJSON = (input: RequestInfo, init?: RequestInit | undefined) =>
//   fetch(input, {
//     ...init,
//     headers: {
//       ...defaultHeaders,
//       ...(init?.headers || {}),
//     },
//   });
