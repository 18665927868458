import { Popover, Transition } from "@headlessui/react";
import { FaUserAlt } from "react-icons/fa";
import { Fragment } from "react";
import {UserInformation} from "./UserInformation";

export interface ISolution {
  name: string;
  color: string;
  href: string;
  icon: any;
}



export default function AvatarDropdown() {
  return (
    <div className="AvatarDropdown">
      <Popover
        // onClick={updateHandler}
        className="relative"
      >
        <>
          <Popover.Button
            className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-gray-600 text-[16px]`}
          >
            <FaUserAlt />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute -right-10 z-10 mt-3 w-screen max-w-[370px] px-4 sm:right-0 sm:px-0">
              <UserInformation />
            </Popover.Panel>
          </Transition>
        </>
      </Popover>
    </div>
  );
}
