import useSWR from "swr";
import fetcher from "api/helpers/fetcher";

interface Props {
  url: string | null;
}

function useGetDetails<T>({ url }: Props) {
  const { data, error } = useSWR<T>(url, fetcher);
  let dataError : any;
  if (data)
  dataError = data 
  return { data, isLoading: !data && !error, error, dataError };
}

export default useGetDetails;
