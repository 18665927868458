import React, {FC} from "react"
import Select from "../../ui/Select/Select";
import {CabinClass} from "../../../api/types";
import {flightClassSelectOptions} from "../HeroSearchForm/FlightsSearchForm/FlightSearchForm";

interface IMobileFlightClassSelection {
    className?: string
    cabinClass: CabinClass
    setCabinClass: (value: CabinClass) => void
}

export const MobileFlightClassSelection: FC<IMobileFlightClassSelection> = (
    {
        className = '',
        cabinClass,
        setCabinClass
    }) => {

    const handleCabinClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCabinClass(e.target.value as CabinClass);
    }

    return (
        <Select
            className={`${className} lg:w-40`}
            name="flight-class"
            id="flight-class"
            sizeClass={'text-l'}
            onChange={handleCabinClassChange}
            value={cabinClass}
        >
            {flightClassSelectOptions.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    )
}
