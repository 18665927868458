import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { AtrTextfield } from "./AtrTextfield";
import { t } from "i18next";
import { AtrNationalityDropdown } from "./AtrNationalityDropdown";
import { AtrCheckbox } from "./AtrCheckbox";
import Typography from "@material-ui/core/Typography";
import { PhoneCountryCode } from "./PhoneCountryCode";

export function ContactInformation(props) {
  const [isWideSize, setIsWideSize] = useState(window.innerWidth > 1100);
  const [isMediumSize, setIsMediumSize] = useState(
    window.innerWidth <= 1100 && window.innerWidth > 900
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const updateSize = (e) => {
    if (e.currentTarget.window.innerWidth > 1100 && !isWideSize) {
      setIsWideSize(true);
      setIsMediumSize(false);
      setIsMobile(false);
    } else if (e.currentTarget.window.innerWidth <= 900 && !isMobile) {
      setIsWideSize(false);
      setIsMediumSize(false);
      setIsMobile(true);
    } else if (
      e.currentTarget.window.innerWidth > 900 &&
      e.currentTarget.window.innerWidth <= 1100 &&
      !isMediumSize
    ) {
      setIsWideSize(false);
      setIsMediumSize(true);
      setIsMobile(false);
    }
  };
  window.addEventListener("resize", updateSize);

  return (
    <div className="ContactInformation">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AtrTextfield
            isError={
              props.showValidation && props.contactInformation.email === ""
            }
            name="ContactInformation-email"
            helperText={
              props.showValidation && props.contactInformation.email === ""
                ? t("react.validation.requiredfield")
                : ""
            }
            label="E-Mail"
            style={{ width: "100%" }}
            updateValue={(value) => {
              props.updateContactInformation("email", value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AtrTextfield
            isError={
              (props.showValidation &&
                props.contactInformation.confirmEmail === "") ||
              (props.showValidation &&
                props.contactInformation.email !==
                  props.contactInformation.confirmEmail)
            }
            name="ContactInformation-email-confirmation"
            helperText={
              props.showValidation &&
              props.contactInformation.confirmEmail === ""
                ? t("react.validation.requiredfield")
                : props.showValidation &&
                  props.contactInformation.email !==
                    props.contactInformation.confirmEmail
                ? "E-Mail nicht identisch"
                : ""
            }
            label="E-Mail bestätigen"
            style={{ width: "100%" }}
            updateValue={(value) => {
              props.updateContactInformation("confirmEmail", value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <PhoneCountryCode
            customerObject={{
              value: props.contactInformation.phoneCountryCode,
            }}
            style={{ width: "100%" }}
            updateCustomerValue={(phoneCountryCode) => {
              props.updateContactInformation(
                "phoneCountryCode",
                phoneCountryCode
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AtrTextfield
            isError={
              props.showValidation && props.contactInformation.phone === ""
            }
            name="ContactInformation-phone"
            helperText={
              props.showValidation && props.contactInformation.phone === ""
                ? t("react.validation.requiredfield")
                : ""
            }
            label="Telefonnummer"
            style={{ width: "100%" }}
            updateValue={(value) => {
              props.updateContactInformation("phone", value);
            }}
          />
          {/*<AtrNationalityDropdown
                        isError = { props.showValidation && props.contactInformation.country === '' }
                        name = "ContactInformation-country"
                        helperText = { props.showValidation && props.contactInformation.country === '' ? t( 'react.validation.requiredfield' ) : '' }
                        label = "Land"
                        style = {{ width: "100%" }}
                        updateValue = { ( value ) =>
                        {
                            props.updateContactInformation( 'country', value );
                        }}
                    />*/}
        </Grid>

        {/*<Grid item xs={12} md={4}>
                    <AtrTextfield
                        isError = { props.showValidation && props.contactInformation.address === '' }
                        name = "ContactInformation-adress"
                        helperText = { props.showValidation && props.contactInformation.address === '' ? t( 'react.validation.requiredfield' ) : '' }
                        label = "Adresse"
                        style = {{ width: "100%" }}
                        updateValue = { ( value ) =>
                        {
                            props.updateContactInformation( 'address', value );
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <AtrTextfield
                        isError = { props.showValidation && props.contactInformation.postcode === '' }
                        name = "ContactInformation-postcode"
                        helperText = { props.showValidation && props.contactInformation.postcode === '' ? t( 'react.validation.requiredfield' ) : '' }
                        label = "PLZ"
                        style = {{ width: "40%", paddingRight: "16px" }}
                        updateValue = { ( value ) =>
                        {
                            props.updateContactInformation( 'postcode', value );
                        }}
                    />
                    <AtrTextfield
                        isError = { props.showValidation && props.contactInformation.city === '' }
                        name = "ContactInformation-city"
                        helperText = { props.showValidation && props.contactInformation.city === '' ? t( 'react.validation.requiredfield' ) : '' }
                        label = "Stadt"
                        style = {{ width: "60%" }}
                        updateValue = { ( value ) =>
                        {
                            props.updateContactInformation( 'city', value );
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4} />*/}

        {/*<Grid item xs={12} md={4}>
                    <AtrTextfield
                        isError = { props.showValidation && props.contactInformation.phone === '' }
                        name = "ContactInformation-phone"
                        helperText = { props.showValidation && props.contactInformation.phone === '' ? t( 'react.validation.requiredfield' ) : '' }
                        label = "Telefonnummer"
                        style = {{ width: "100%" }}
                        updateValue = { ( value ) =>
                        {
                            props.updateContactInformation( 'phone', value );
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8} />*/}
        {/*<Grid item xs={12} md={5}>
                    <AtrCheckbox
                        name = "checkbox-Contactinformation"
                        helperText = ""
                        label = "Buchungsbestätigung per SMS erhalten"
                        style = {{ width: "100%" }}
                        updateValue = { ( value ) =>
                        {
                            props.updateContactInformation( 'getSmsConfirmation', value );
                        }}
                    />
                </Grid>*/}
        <Grid item xs={12} md={3} />
      </Grid>
    </div>
  );
}
