import React, {FC} from "react"
import useQueryParameters from "../../../hooks/useQueryParameters";
import {formatDateShortMonth} from "../../../utils/formatDate";

export const HotelContent: FC = () => {
    const queryParameters = useQueryParameters()

    return (
        <>
            <div className={`font-semibold xxs:text-md`}>{queryParameters?.label}</div>
            <div className={`flex text-md xxs:text-xs whitespace-nowrap`}>
                <span>{formatDateShortMonth(queryParameters?.checkinDate!)} - {formatDateShortMonth(queryParameters?.checkoutDate!)}</span>&nbsp;&#x2022;&nbsp;
                <span>{queryParameters?.totalGuests} Gäste</span>&nbsp;&#x2022;&nbsp;
                <span>{queryParameters?.roomOccupancies?.length} Räume</span>
            </div>
        </>
    )
}
