import { SearchTab } from "api/types";
import create from "zustand"


const urlParams = new URLSearchParams(window.location.search);

interface SearchPageStore {
    activeTab: SearchTab
    setActiveTab: (tab: SearchTab) => void
}

const useSearchPageStore = create<SearchPageStore>((set) => ({
    activeTab: (urlParams.get("searchType") as SearchTab) || "Tour",
    setActiveTab: (tab: SearchTab) => set({ activeTab: tab }),
}))

export default useSearchPageStore