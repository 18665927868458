import React, { FC } from "react";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/common/HeroSearchForm";
import {isB2B} from "../../../api/helpers/checkUserRole";
import SearchEngine from "../SearchEngines/SearchEngine";
import {useReduxReducer} from "../../../redux/store";

export interface SectionHeroProps {
  className?: string;
}

const _isB2B = isB2B();

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const { isMobile, isApp } = useReduxReducer(state => state.general)

  return (
    <div
      className={`nc-SectionHero relative flex flex-col ${className}`}
      data-nc-id="SectionHero"
    >
      <div className={`${isMobile ? !isApp && 'mt-3' : 'mt-5'} z-10 w-full`}>
        <HeroSearchForm />
       { /*<SearchEngine />*/}
      </div>
    </div>
  );
};

export default SectionHero;
