import React, {FC, useEffect, useState} from "react"
import {Tag} from "primereact/tag";
import {PaxDetailPage} from "../../../api/types";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../../utils/formatDate";
import currencyFormat from "../../../utils/currencyFormat";

interface IPassagierPrices {[key: string]: {totalAmount: number, baseFare: number, tax: number}}

interface IPassagierInformation {
    paxList: PaxDetailPage[]
    totalAmount: number
    serviceFee: number
}

export const PassagierInformation: FC<IPassagierInformation> = ({paxList, totalAmount, serviceFee}) => {
    const { t } = useTranslation()

    const [ passagierPrices, setPassagierPrices ] = useState<IPassagierPrices|null>(null)

    useEffect(() => {
        if (!passagierPrices) {
            const _passagierPrices: IPassagierPrices = {}
            paxList.map(pax => _passagierPrices[pax.paxtype] = {totalAmount: pax.totalAmount + serviceFee, baseFare: pax.baseFare, tax: pax.totalAmount - pax.baseFare})
            setPassagierPrices(_passagierPrices)
        }
    }, [passagierPrices])

    return (
        <div className={`my-3 mx-2 mb-3 px-3 pb-3 pt-3 shadow-md bg-white rounded`}>
            <div className={`text-xl font-semibold mb-3`}>{t('flights.passengers')}</div>
            <div className={`grid grid-cols-2`}>
                <div className={`col-span-1 text-l font-semibold border text-center py-2`}>{t('general.name')}</div>
                <div className={`col-span-1 text-l font-semibold border-t border-b border-r text-center py-2`}>{t('flightbooking.ticket-number')}</div>
            </div>
            {
                paxList.map((pax, index) =>
                    <div key={`passagierInfo-${index}`} className={`grid grid-cols-2`}>
                        <div className={`col-span-1 text-l border-l border-r border-b`}>
                            <div className={`p-2`}>
                                <div>{`${pax.firstname} ${pax.surname}`}</div>
                                <div className={`flex w-full justify-between pt-1`}>
                                    <Tag>{pax.paxtype}</Tag>
                                    {pax.birthdate && <Tag>{formatDate(pax.birthdate.split('T')[0])}</Tag>}
                                </div>
                            </div>
                        </div>
                        <div className={`col-span-1 text-l border-b border-r py-2 flex justify-center items-center`}>
                            <div>
                                {pax.ticketnumber?.split('<br>').map((_ticketnumber, index) => <div key={`ticketnummer-${index}`}>{_ticketnumber}</div>)}
                            </div>
                        </div>
                    </div>
                )
            }
            {
                passagierPrices &&
                    <div>
                        <div className={`grid grid-cols-4 mt-3`}>
                            <div className={`col-span-1 text-md font-semibold border text-center py-2`}>{t('pdf.insurance.type')}</div>
                            <div className={`col-span-1 text-md font-semibold border-t border-b border-r text-center py-2`}>{t('flightbooking.fare')}</div>
                            <div className={`col-span-1 text-md font-semibold border-t border-b border-r text-center py-2`}>{t('flightbooking.tax')}</div>
                            <div className={`col-span-1 text-md font-semibold border-t border-b border-r text-center py-2`}>{t('rentalcar.total')}</div>
                        </div>
                        {
                            Object.keys(passagierPrices).map((key, index) => (
                                <div key={`passagierInfo-${index}`} className={`grid grid-cols-4`}>
                                    <div className={`col-span-1 text-md border-l border-r border-b flex justify-center items-center`}>{`${key}`}</div>
                                    <div className={`col-span-1 text-md border-b border-r py-2 flex justify-center items-center`}>{currencyFormat(passagierPrices[key].baseFare)}</div>
                                    <div className={`col-span-1 text-md border-b border-r py-2 flex justify-center items-center`}>{currencyFormat(passagierPrices[key].tax)}</div>
                                    <div className={`col-span-1 text-md border-b border-r py-2 flex justify-center items-center`}>{currencyFormat(passagierPrices[key].totalAmount)}</div>
                                </div>
                            ))
                        }
                        <div className={`grid grid-cols-4`}>
                            <div className={`col-span-3`} />
                            <div className={`col-span-1 text-md py-2 flex justify-center items-center font-semibold`}>{currencyFormat((totalAmount + (paxList.length * serviceFee)))}</div>
                        </div>
                    </div>
            }
        </div>
    )
}
