import React from 'react';

interface FiltersState {
  startDate: string;
  endDate: string;
  invoiceNumber: string;
  bookingReference: string;
  productType: string;
  outstandingBalance: string;
  totalSalesPrice: string;
}

interface InvoiceFiltersProps {
  filters: FiltersState;
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export function InvoiceFilters({ filters, onFilterChange, onFilterSubmit }: InvoiceFiltersProps) {
  return (
   
     <form onSubmit={onFilterSubmit} className="mb-6 space-y-4">
     <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
     <div>
        <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date</label>
        <input
          type="date"
          id="startDate"
          name="startDate"
          value={filters.startDate}
          onChange={onFilterChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      <div>
        <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date</label>
        <input
          type="date"
          id="endDate"
          name="endDate"
          value={filters.endDate}
          onChange={onFilterChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
       <div>
         <label htmlFor="invoiceNumber" className="block text-sm font-medium text-gray-700 mb-1">Invoice Number</label>
         <input
           type="text"
           id="invoiceNumber"
           name="invoiceNumber"
           value={filters.invoiceNumber}
           onChange={onFilterChange}
           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
         />
       </div>
       <div>
         <label htmlFor="bookingReference" className="block text-sm font-medium text-gray-700 mb-1">Booking Reference</label>
         <input
           type="text"
           id="bookingReference"
           name="bookingReference"
           value={filters.bookingReference}
           onChange={onFilterChange}
           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
         />
       </div>
     </div>
     <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
       <div>
         <label htmlFor="productType" className="block text-sm font-medium text-gray-700 mb-1">Product Type</label>
         <input
           type="text"
           id="productType"
           name="productType"
           value={filters.productType}
           onChange={onFilterChange}
           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
         />
       </div>
       <div>
         <label htmlFor="outstandingBalance" className="block text-sm font-medium text-gray-700 mb-1">Outstanding Balance</label>
         <input
           type="number"
           id="outstandingBalance"
           name="outstandingBalance"
           value={filters.outstandingBalance}
           onChange={onFilterChange}
           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
         />
       </div>
       <div>
         <label htmlFor="totalSalesPrice" className="block text-sm font-medium text-gray-700 mb-1">Total Sales Price</label>
         <input
           type="number"
           id="totalSalesPrice"
           name="totalSalesPrice"
           value={filters.totalSalesPrice}
           onChange={onFilterChange}
           className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
         />
       </div>
     </div>
     <div className="flex justify-end">
       <button
         type="submit"
         className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-6000 hover:bg-priamry-600 focus:outline-none "
       >
         Apply Filters
       </button>
     </div>
   </form>
  );
}

