import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "components/ui/NcImage/NcImage";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import SupportImage from "images/our-features.png"; // Müşteri destek resmi
import PhoneIcon from '@mui/icons-material/Phone';

const CustomerSupport: React.FC = () => (
  <div className="nc-CustomerSupport">
    <Helmet>
      <title>
        Customer Support || Book your Travel with ATR Touristik: Flights, Accommodations, Transfer, Rental Car and more
      </title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="mx-auto max-w-2xl space-y-2 text-center">
        <NcImage src={SupportImage} />
        <h1 className="text-2xl font-bold tracking-wider text-neutral-800 dark:text-neutral-200 sm:text-3xl">
          How can we help you?
        </h1>
        <span className="block text-sm font-medium tracking-wider text-neutral-800 dark:text-neutral-200 sm:text-base">
          Our customer support team is here to assist you with any inquiries or issues you may have.
        </span>
        <div className="mt-8 flex justify-center items-center space-x-2">
          <PhoneIcon className="text-blue-500" />
          <span className="text-lg font-medium text-neutral-800 dark:text-neutral-200">
            0049 69 2731668 00
          </span>
        </div>
      </header>

      {/* SUPPORT INFORMATION */}
 
    </div>
  </div>
);

export default CustomerSupport;
