import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import moment from 'moment';
import currencyFormat from 'utils/currencyFormat';

interface InvoiceDetailDialogProps {
  open: boolean;
  onClose: () => void;
  invoiceDetail: any;
}

const InvoiceDetailDialog: React.FC<InvoiceDetailDialogProps> = ({ open, onClose, invoiceDetail }) => {
  if (!invoiceDetail) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Invoice Detail</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Invoice Number: {invoiceDetail.invoiceNumber}</Typography>
            <Typography variant="subtitle1">Booking Reference: {invoiceDetail.bookingReference}</Typography>
            <Typography variant="subtitle1">Status: {invoiceDetail.invoiceStatus}</Typography>
            <Typography variant="subtitle1">Product Type: {invoiceDetail.productType}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Customer: {invoiceDetail.agencyCustomerName}</Typography>
            <Typography variant="subtitle1">Customer Number: {invoiceDetail.agencyCustomerNumber}</Typography>
            <Typography variant="subtitle1">Travel Date: {moment(invoiceDetail.travelDate).format('DD-MM-YYYY')}</Typography>
            <Typography variant="subtitle1">Travel Registrant: {invoiceDetail.travelRegistrant}</Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" style={{ marginTop: '20px' }}>Flight Bookings</Typography>
        {invoiceDetail.flightBookings.map((booking: any, index: number) => (
          <Paper key={index} style={{ padding: '10px', marginTop: '10px' }}>
            <Typography variant="subtitle1">Airline: {booking.airline}</Typography>
            <Typography variant="subtitle1">Supplier: {booking.supplier.supplierName}</Typography>
            
            <Typography variant="h6" style={{ marginTop: '10px' }}>Flights</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Flight Number</TableCell>
                    <TableCell>Departure</TableCell>
                    <TableCell>Arrival</TableCell>
                    <TableCell>Class</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {booking.flightList.map((flight: any) => (
                    <TableRow key={flight.id}>
                      <TableCell>{flight.flightNumber}</TableCell>
                      <TableCell>{`${flight.departurePort} (${moment(flight.departureDate).format('DD-MM-YYYY HH:mm')})`}</TableCell>
                      <TableCell>{`${flight.arrivalPort} (${moment(flight.arrivalDate).format('DD-MM-YYYY HH:mm')})`}</TableCell>
                      <TableCell>{flight.cabinClass}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h6" style={{ marginTop: '10px' }}>Passengers</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Ticket Number</TableCell>
                    <TableCell>Base Fare</TableCell>
                    <TableCell>Tax</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {booking.paxList.map((pax: any) => (
                    <TableRow key={pax.id}>
                      <TableCell>{`${pax.firstname} ${pax.surname}`}</TableCell>
                      <TableCell>{pax.paxtype}</TableCell>
                      <TableCell>{pax.ticketnumber}</TableCell>
                      <TableCell>{currencyFormat(pax.baseFare) }</TableCell>
                      <TableCell>{currencyFormat(pax.tax) }</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ))}

        <Typography variant="h6" style={{ marginTop: '20px' }}>Pricing</Typography>
        <Typography>Total Purchase Price: {currencyFormat(invoiceDetail.totalPurchasePrice) }</Typography>
        <Typography>Total Sales Price: { currencyFormat(invoiceDetail.totalSalesPrice)}</Typography>
        <Typography>Outstanding Balance: {currencyFormat(invoiceDetail.outstandingBalance)}</Typography>
        <Typography>Paid: {invoiceDetail.paid ? 'Yes' : 'No'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDetailDialog;

