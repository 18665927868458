import { useLocalStorageValue } from 'hooks/useLocalStorageValue';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import { useReduxReducer } from 'redux/store';

const CookieConsent: React.FC = () => {
  
  const { footer } = useReduxReducer((state) => state.config);
  const {t} = useTranslation()
  const [consent, setConsent] = useLocalStorageValue<string>({
    key: 'cookieConsent',
    defaultValue: '',
  });

  const handleAccept = () => {
    setConsent('true');
  };

  const handleDismiss = () => {
    setConsent('false');
  };

  useEffect(() => {
    if (consent === 'true' || consent === 'false') {
      return;
    }
  }, [consent]);

  if (consent === 'true' || consent === 'false') {
    return null;
  }

  return (
    <div className="fixed bottom-5 left-5 right-5 z-max max-w-md mx-auto bg-dark text-white p-6 rounded-lg shadow-lg flex flex-col space-y-4">
  <span className="text-md">
    {t("cookies.message")}
    <p>{t("cookies.more-information")}{': '}
    {footer.linksEnabled 
    ? <a href="https://www.atrtouristik.com/public/privacy-policy" target="_blank" rel="noopener noreferrer" className="underline text-blue-400">
        {t("homepage.privacy-policy")}
      </a>
    : <Link to="/privacypolicy" className="underline text-blue-400">
        {t("homepage.privacy-policy")}
      </Link>}
      </p>
  </span>
  <div className="flex justify-center space-x-3">
    <button
      onClick={handleDismiss}
      className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-6 rounded-full transition-colors duration-300"
    >
      Dismiss
    </button>
    <button
      onClick={handleAccept}
      className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-full transition-colors duration-300"
    >
      Accept
    </button>
  </div>
</div>

  );
};

export default CookieConsent;
