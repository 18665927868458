import Layout from "../layout";
import InvoiceTable from "./components/InvoiceTable";

const Invoices: React.FC = () => {
   
    return (
        <Layout>
        <h1 className="text-3xl font-bold mb-4">Invoices</h1>
        <InvoiceTable/>
      </Layout>    );
};

export default Invoices;
