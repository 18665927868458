import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

interface SelectFilterProps {
  className?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  selectOptions: Array<{ label: string; value: string }>;
}

const SelectFilter: React.FC<SelectFilterProps> = ({
  className,
  value,
  setValue,
  selectOptions,
}) => {
  const selectedLabel = selectOptions.find(
    (option) => value === option.value
  )?.label;

  return (
    <div>
      <label className="sr-only" htmlFor="sort-selection">
        {selectedLabel}
      </label>
      <Listbox value={value} onChange={setValue}>
        {({ open }) => (
          <>
            <Listbox.Button
              className={`flex w-full items-center justify-between rounded-full border border-neutral-300 px-4 py-2 text-sm focus:outline-none dark:border-neutral-700 ${
                open ? "!border-primary-500 " : ""
              } ${className}`}
            >
              <span>{selectedLabel}</span>
              <i className="las la-angle-down ml-2" />
            </Listbox.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Listbox.Options className="absolute z-10 mt-3 w-screen max-w-sm px-4 sm:px-0">
                <div className="overflow-hidden rounded-2xl border border-neutral-200 bg-white shadow-xl dark:border-neutral-700 dark:bg-neutral-900">
                  {selectOptions.map((option) => (
                    <Listbox.Option
                      className="cursor-pointer py-2.5 px-4 hover:bg-neutral-50"
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SelectFilter;
