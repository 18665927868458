import {
  AirportSearchAutocompleteResult,
  HotelSearchAutocompleteResult,
  SearchPayload,
} from "api/types";
import { RoomInput } from "components/common/HeroSearchForm/GuestsInput";
import { DateRange } from "components/common/HeroSearchForm/HotelSearchForm";
import RoomOccupanciesService from "./RoomOccupanciesService";
import ValidationService from "./ValidationService";

class HotelAndFlightQueryParametersService {
  static encode(
    nationality: string,
    airport: AirportSearchAutocompleteResult | null,
    hotelOrLocation: HotelSearchAutocompleteResult | null,
    dateRange: DateRange,
    guests: RoomInput[],
    onlyNeedAccomodation: boolean,
    locationDateRange: DateRange
  ): string | null {
    if (
      nationality &&
      airport &&
      hotelOrLocation &&
      dateRange.startDate &&
      dateRange.endDate &&
      (!onlyNeedAccomodation || locationDateRange.startDate) &&
      (!onlyNeedAccomodation || locationDateRange.endDate)
    ) {
      let roomOccupancies =
        RoomOccupanciesService.stringifyRoomOccupancies(guests);

      const departingDate = dateRange.startDate.format("YYYY-MM-DD");
      const returningDate = dateRange.endDate.format("YYYY-MM-DD");

      let checkinDate: string | null = null;
      let checkoutDate: string | null = null;

      if (locationDateRange.startDate && locationDateRange.endDate) {
        checkinDate = locationDateRange.startDate!.format("YYYY-MM-DD");
        checkoutDate = locationDateRange.endDate!.format("YYYY-MM-DD");
      } else {
        checkinDate = departingDate;
        checkoutDate = returningDate;
      }

      return new URLSearchParams({
        searchType: "Hotel & Flights",
        departingDate,
        returningDate,
        checkinDate,
        checkoutDate,
        destinationId: hotelOrLocation.id,
        destinationType: hotelOrLocation.type,
        label: hotelOrLocation.label,
        arrivalAirport: airport.id,
        arrivalText: airport.text,
        nationality: nationality,
        roomOccupancies,
      }).toString();
    }

    return null;
  }

  static decode(urlParams: URLSearchParams): SearchPayload | null {
    let checkinDate = urlParams.get("checkinDate") || null;
    let checkoutDate = urlParams.get("checkoutDate") || null;
    let destinationId = urlParams.get("destinationId") || null;
    let destinationType = urlParams.get("destinationType") || null;
    let label = urlParams.get("label") || null;
    let nationality = urlParams.get("nationality") || null;
    let roomOccupanciesParameter = urlParams.get("roomOccupancies") || null;

    let departingDate = urlParams.get("departingDate") || null;
    let returningDate = urlParams.get("returningDate") || null;
    let arrivalAirport = urlParams.get("arrivalAirport") || null;
    let arrivalText = urlParams.get("arrivalText") || null;

    checkinDate = ValidationService.date(checkinDate) ? checkinDate : null;
    checkoutDate = ValidationService.date(checkoutDate) ? checkoutDate : null;

    departingDate = ValidationService.date(departingDate)
      ? departingDate
      : null;
    returningDate = ValidationService.date(returningDate)
      ? returningDate
      : null;

    nationality = ValidationService.nationality(nationality)
      ? nationality
      : null;
    roomOccupanciesParameter = ValidationService.roomOccupancies(
      roomOccupanciesParameter
    )
      ? roomOccupanciesParameter
      : null;

    if (
      !destinationId ||
      !destinationType ||
      !arrivalAirport ||
      !arrivalText ||
      !nationality ||
      !departingDate ||
      !returningDate ||
      !roomOccupanciesParameter ||
      !checkinDate ||
      !checkoutDate
    ) {
      console.warn(
        checkinDate,
        checkoutDate,
        destinationId,
        destinationType,
        arrivalAirport,
        arrivalText,
        nationality,
        returningDate,
        departingDate,
        roomOccupanciesParameter,
        "Query parameters not valid!"
      );
      return null;
    }

    const { roomOccupancies, totalGuests } =
      RoomOccupanciesService.getRoomOccupanciesValues(
        roomOccupanciesParameter!
      );

    return {
      searchType: "Hotel & Flights",
      checkinDate: checkinDate!,
      checkoutDate: checkoutDate!,
      destinationId: destinationId!,
      destinationType: destinationType!,
      nationality: nationality!,
      roomOccupancies,
      label: label!,
      totalGuests,
      flightSearch: {
        originDestinationInformation: [
          {
            date: departingDate!,
            origin: arrivalAirport,
            originText: arrivalText,
          },
          { date: returningDate! },
        ],
      },
    };
  }
}

export default HotelAndFlightQueryParametersService;
