import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BookingApprovalTable from "./components/BookingApprovalTable";
import { bookingApproval } from "api/helpers/api-constants";
import fetcher from "api/helpers/fetcher";
import { IdentificationIcon } from "@heroicons/react/solid";
import { useReduxReducer } from "redux/store";
import BackButton from "components/ui/ButtonBack/ButtonBack";
import RemarksSection from "pages/BookingDetail/components/RemarksSection";
import Switch from "components/ui/Switch/Switch";

export interface BookingApprovalItem {
  id: number,
  bookingKey: string,
  productType: string,
  remarks: string,
  username: string,
  agency: string,
  approved: boolean

}

export interface BookingApproval{
bookingApprovalList:BookingApprovalItem []
}


const BookingApproval: React.FC = () => {
  const { t } = useTranslation();
  const [approvedSwich, setapprovedSwich] = useState<boolean>(false);
  const [bookingApprovalList, setBookingApprovalList] = useState<BookingApprovalItem[]>([]);
  const {auth} = useReduxReducer(state => state.config)
  const fetchData = async (approved: boolean) => {
    try {
      const result = await fetcher(bookingApproval(approved));
      setBookingApprovalList(result);
    } catch (error) {
      console.error("Error fetching booking approvals:", error);
    }
  };
  useEffect(() => {
   fetchData(approvedSwich);
  }, [approvedSwich]);

  const updateApprovalStatus = async (id: number, approved: boolean) => {
    try {
  
      const response = await fetch(bookingApproval(approved), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          approved,
        }),
      });
  
      if (response.ok) {
        console.log(`Successfully updated booking approval for ${id}`);

      }
    } catch (error) {
      console.error(`Error updating booking approval for ${id}:`, error);
    }
    finally{
      fetchData(approvedSwich)
    }
  };
  

  const handleApprove = (id: number) => {
    updateApprovalStatus(id, true);
  };

  const handleReject = (id: number) => {
    updateApprovalStatus(id, false);
  };
  
  return (
    <>
    {auth.userPermission.userNeedsApprovalForBooking 
    ? <>
    <BackButton/>
    <span className="flex justify-center min-h-screen">You are not permitted to view this page </span>
    </> 
    : <div className="container my-12 space-y-8 min-h-screen">
      <span className="flex justify-between">
      <h1 className="font-semibold">{t("generals.bookingapproval")}</h1>
      <span className={"text-md  flex items-center " + (approvedSwich ? 'text-primary-6000' : 'text-gray-800')}>
        <span>{approvedSwich 
        ? t("generals.approved") 
        : t("generals.notyetapproved")} 
        </span>
   <Switch
            id="approval-switch"
            checked={approvedSwich}
            onChange={() => setapprovedSwich((prev) => !prev)}
            labelClass=""
            className="pt-1"
            />  
            </span>

      </span>
     <BookingApprovalTable
      bookingApprovalList={bookingApprovalList}
      onApprove={handleApprove}
      onReject={handleReject}
      approvedSwitch={approvedSwich}
    />
  </div>}
     
    </>
   
  );
};

export default BookingApproval;
