import React, { useEffect, useState } from "react";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchPayload } from "api/types";
import { getPickupLocationAutocompleteUrl } from "api/helpers/api-constants";
import Checkbox from "components/ui/Checkbox/Checkbox";
import DateInput from "../DateInput";
import TravelersInput, { TravelersInputValue } from "../TravelersInput";
import TransferQueryParametersService from "services/TransferQueryParametersService";
import AutocompleteInputForTransfer from "../AutocompleteInputForTransfer";
import AutocompleteInputForTransfer2 from "../AutocompleteInputForTransfer2";
import useTransferPageStore from "store/transferPageStore";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch } from "react-redux";
import { SetTabSelections } from "redux/slices/tabsSlice";
import { useTranslation } from "react-i18next";
import HeroSelect from "../HeroSelect";
import timeSelectOptions from "utils/timeSelectOptions";
import { ClockIcon } from "@heroicons/react/outline";
import useRecentSearches from "hooks/useRecentSearches";
import TravelersInputWithoutModal from "../TravelersInputWithoutModal";

const TransferSearchForm = () => {
  const { search } = useLocation();
  const { setSearchPayload, searchPayload } = useTransferPageStore(
    (state) => state
  );
  const { appendRecentSearch } = useRecentSearches("recentSearches");

  const [travelers, setTravelers] = useState<TravelersInputValue>({
    adults: 1,
    children: 0,
    infants: 0,
  });
  const [departureDate, setDepartureDate] = useState<moment.Moment | null>(
    moment().add(7, "days")
  );
  const [roundTrip, setRoundTrip] = useState(false);
  const [landingDate, setLandingDate] = useState<moment.Moment | null>(
    moment().add(14, "days")
  );
  const [departureAirport, setDepartureAirport] = useState<any | null>(null);
  const [arrivalAirport, setArrivalAirport] = useState<any | null>(null);
  const [departTime, setDepartTime] = useState<string | null>("12:00");
  const [returnTime, setReturnTime] = useState<string | null>("12:00");
  const [departTimeFocus, setDepartTimeFocus] = useState(false);
  const [returnTimeFocus, setReturnTimeFocus] = useState(false);
  const [missingInputError, setMissinInputError] = useState({
    departureAirport: false,
    arrivalAirport: false,
    departingDate: false,
    returningDate: false,
  });

  const [locationFocus, setLocationFocus] = useState<boolean>(false);
  const [dateFocus, setDateFocus] = useState<boolean>(false);
  const [returnDateFocus, setReturnDateFocus] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    setSearchPayload(urlParams);
  }, []);

  useEffect(() => {
    if (searchPayload) {
      unstable_batchedUpdates(() => {
        setDepartureAirport({
          id: searchPayload.pickupLocationId
            ? searchPayload.pickupLocationId
            : "",
          name: searchPayload.pickupLocation
            ? searchPayload.pickupLocation
            : "",
            subLocationId:searchPayload.pickupSubLocationId ? searchPayload.pickupSubLocationId : "0"
        });

        setArrivalAirport({
          id: searchPayload.destinationLocationId,
          name: searchPayload.returnLocation,
          subLocationId:searchPayload.destinationSubLocationId ? searchPayload.destinationSubLocationId : "0"

        });

        setTravelers({
          adults: searchPayload.adtCount ? searchPayload.adtCount : 1,
          children: searchPayload.chdCount ? searchPayload.chdCount : 0,
          infants: searchPayload.infCount ? searchPayload.infCount : 0,
        });

        setDepartTime(
          searchPayload.pickupHour && searchPayload.pickupMinute
            ? searchPayload.pickupHour + ":" + searchPayload.pickupMinute
            : "12:00"
        );
        setReturnTime(
          searchPayload.returnHour && searchPayload.returnMinute
            ? searchPayload.returnHour + ":" + searchPayload.returnMinute
            : "12:00"
        );
        setRoundTrip(searchPayload.roundTrip === "true");
        if (searchPayload.roundTrip === "true") {
          setLandingDate(moment(searchPayload.returnDate, "DD.MM.YYYY"));
        } else {
          setLandingDate(null);
        }
        setDepartureDate(moment(searchPayload.pickupDate, "DD.MM.YYYY"));
      });
    }
  }, [searchPayload]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    dispatch(SetTabSelections({ currentTab: "Transfer" }));

    setMissinInputError({
      departureAirport: !departureAirport,
      arrivalAirport: !arrivalAirport,
      departingDate: !departureDate,
      returningDate: !landingDate,
    });

    const urlSearchParams = TransferQueryParametersService.encode({
      arrivalAirport,
      departureAirport,
      departureDate,
      travelers,
      roundTrip,
      landingDate,
      departTime,
      returnTime,
    });
    if (urlSearchParams) {
      const recentData = {
        type: "transfer",
        arrivalAirport: arrivalAirport.name,
        departureAirport: departureAirport.name,
        departTime: departTime,
        returnTime: returnTime,
        landingDate: landingDate,
        url: urlSearchParams.toString(),
      };

      setSearchPayload(urlSearchParams);
      navigate("/transfer/results?" + urlSearchParams?.toString());
      appendRecentSearch(recentData);
    }
  }

  // useEffect(() => {
  //   roundTrip && setLandingDate(moment(departureDate).add(7, "days"));
  // }, [departureDate, roundTrip]);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="relative mt-0 w-full rounded-b-lg bg-white shadow-sm dark:bg-neutral-900 dark:shadow-2xl"
      >
        <div className="flex w-full flex-col border-b border-neutral-100 pr-4 md:flex-row md:items-center ">
          <div className=" relative flex flex-grow flex-col md:flex-row ">
            <div className="mr-auto flex items-center">
              <TravelersInputWithoutModal
                labelClassName="!text-base !font-medium ml-auto"
                iconClassName="w-5 h-5 ml-3 mt-0.5"
                subtitle={false}
                travelers={travelers}
                setTravelers={setTravelers}
              />
            </div>
            <Checkbox
              labelClassName="!text-sm !text-neutral-400"
              inputClassName="!w-5 !h-5"
              className="my-auto"
              name="test"
              label={t("react.transfer.roundtrip")}
              checked={roundTrip}
              onChange={() => setRoundTrip((prev) => !prev)}
            />
          </div>
        </div>
        <div className="relative flex flex-grow flex-col items-center justify-between md:flex-row">
          {/* <DatesRangeInput
              wrapClassName="sm:!flex-row "
              defaultValue={roundTripRange}
              onChange={(data) => {
                setRoundTripRange(data);
              }}
              prRanges={false}
              checkinLabel={"Select Landing Date"}
              checkoutLabel={"Select Departure Date"}
              checkinDateError={missingInputError.departingDate}
              checkoutDateError={missingInputError.returningDate}
              startDateTime={departTime}
              setStartDateTime={setDepartTime}
              endDateTime={returnTime}
              value={roundTripRange}
              setEndDateTime={setReturnTime}
            /> */}

          <div className="flex lg:flex-row">
            <AutocompleteInputForTransfer2
              placeHolder={t("transfer.pickup-location")}
              desc={t("transfer.pickup-location")}
              getUrl={getPickupLocationAutocompleteUrl}
              autocompleteValue={departureAirport}
              setAutocompleteValue={setDepartureAirport}
              localStorageToken="airport-departure"
              error={missingInputError.departureAirport}
              setNextFocus={setLocationFocus}
            />
            <AutocompleteInputForTransfer
              placeHolder={t("transfer.destination")}
              pickupLocationId={departureAirport?.id || null}
              autocompleteValue={arrivalAirport}
              setAutocompleteValue={setArrivalAirport}
              localStorageToken="airport-arrival"
              error={missingInputError.arrivalAirport}
              focused={locationFocus}
              setFocused={setLocationFocus}
              setDateFocus={setDateFocus}
            />
            <DateInput
              date={departureDate}
              setDate={setDepartureDate}
              label={t("react.transfer.pickupdate")}
              error={missingInputError.departingDate}
              dateFocus={dateFocus}
              setDateFocus={setDateFocus}
              setTimeFocus={setDepartTimeFocus}
              numberOfMonths={1}
              minDate={moment()}
              maxDate={moment().add(10, "year")}
            />
            <HeroSelect
              className={"ml-md-1"}
              value={departTime}
              setValue={setDepartTime}
              selectOptions={timeSelectOptions}
              Icon={ClockIcon}
              focus={departTimeFocus}
              setFocus={setDepartTimeFocus}
              setNextFocus={setReturnDateFocus}
            />
          </div>

          {roundTrip && (
            <div className="flex ">
              <DateInput
                className={"border-left ml-1"}
                date={landingDate}
                setDate={setLandingDate}
                label={t("react.transfer.returndate")}
                error={missingInputError.returningDate}
                setTimeFocus={setReturnTimeFocus}
                numberOfMonths={1}
                minDate={
                  departureDate ? moment(departureDate).add(1, "day") : moment()
                }
                maxDate={moment().add(10, "year")}
                dateFocus={returnDateFocus}
                setDateFocus={setReturnDateFocus}
              />
              <HeroSelect
                value={returnTime}
                setValue={setReturnTime}
                selectOptions={timeSelectOptions}
                Icon={ClockIcon}
                focus={returnTimeFocus}
                setFocus={setReturnTimeFocus}
              />
            </div>
          )}

          <div className="justify center ml-auto flex w-full items-center px-4 py-4 lg:w-min lg:py-0">
            <SubmitButton />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TransferSearchForm;
