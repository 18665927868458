import React, { useState, useEffect } from 'react';

interface SwitchProps {
  id?: string;
  label?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  labelClass?: string;
  className?: string;
}

const Switch: React.FC<SwitchProps> = ({
  id = 'defaultSwitch',
  label,
  checked: propChecked = false,
  onChange,
  labelClass,
  className,
}) => {
  const [isChecked, setIsChecked] = useState(propChecked);

  useEffect(() => {
    setIsChecked(propChecked);
  }, [propChecked]);

  const handleSwitchChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);

    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div className={"flex items-center space-x-2 " + className}>
      <span className={"mr-2 text-md " + labelClass}>{label}</span>
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleSwitchChange}
        className="hidden"
      />
      <label
        htmlFor={id}
        className="relative flex items-center cursor-pointer select-none"
      >
        <div className={isChecked 
            ? "bg-primary-6000 w-10 h-6  rounded-full p-1 transition duration-300 ease-in-out" 
            : "bg-gray-300 w-10 h-6  rounded-full p-1 transition duration-300 ease-in-out"}>
          <div
            className={`${
              isChecked ? 'translate-x-full' : ''
            } w-4 h-4 rounded-full bg-white shadow-md transform transition-transform duration-300 ease-in-out`}
          ></div>
        </div>
      </label>
    </div>
  );
};

export default Switch;
