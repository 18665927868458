import React, { useState } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import useRecentSearches from "hooks/useRecentSearches";
import useSearchAutocomplete from "api/hooks/useGetAutocomplete";
import { ClockIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import useGetSuggested from "api/hooks/useGetSuggestet";
import {
  AirportSearchAutocompleteResult,
  MultiCitySearchModel,
  SearchTab,
} from "api/types";
import { Chip, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';

export interface AutocompleteForFlightProps<T> {
  category?: string;
  defaultValue?: any;
  queryType?: SearchTab;
  localStorageToken: string;
  autocompleteValue: MultiCitySearchModel[];
  getUrl: (term: string) => string;
  setAutocompleteValue: React.Dispatch<
    React.SetStateAction<MultiCitySearchModel[]>
  >;
  Icon?: React.ReactNode & React.FC<React.ComponentProps<"svg">>;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  onInputDone?: () => void;
  error?: boolean;
  setDateFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  focus?: boolean;
  setFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  onFocusChange?: Boolean;
  index?: number;
  flightDirection?: "departure" | "arrival";
}

function AutocompleteForMultiFlight<
  T extends { id: string; text?: string; label?: string }
>({
  category,
  defaultValue,
  autoFocus = false,
  getUrl,
  autocompleteValue,
  localStorageToken,
  setAutocompleteValue,
  Icon = LocationMarkerIcon,
  placeHolder = "Airport",
  desc = "Leaving from",
  className = "nc-flex-1.5",
  onInputDone = () => {},
  error = false,
  setDateFocus = () => {},
  focus = false,
  setFocus = () => {},
  onFocusChange,
  index = 0,
  flightDirection,
}: AutocompleteForFlightProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPopover, setShowPopover] = useState(autoFocus);
  const { query, setQuery, result } = useSearchAutocomplete<T[]>(
    (flightDirection === "departure"
      ? autocompleteValue[index]?.departureAirport?.text || ""
      : flightDirection === "arrival"
      ? autocompleteValue[index]?.arrivalAirport?.text || ""
      : "") || "",
    getUrl
  );
  interface MyState<T extends { id: string; text?: string; label?: string }> {
    data: (T | null)[];
  }

  const { suggested } = useGetSuggested<T[]>();
  const [inputValues, setInputValues] = useState<
    MyState<{ id: string; text?: string; label?: string }>
  >({
    data:
      flightDirection === "departure"
        ? autocompleteValue[index].departureAirport
          ? [autocompleteValue[index]?.departureAirport]
          : []
        : flightDirection === "arrival"
        ? autocompleteValue[index].arrivalAirport
          ? [autocompleteValue[index]?.arrivalAirport]
          : []
        : [],
  });

  // const { recentSearches } = useRecentSearches<T>(localStorageToken);
  const { t } = useTranslation();

  useEffect(() => {
    if (flightDirection === "departure") {
      if (autocompleteValue[index].departureAirport !== null) {
        setInputValues({
          data: [autocompleteValue[index].departureAirport],
        });
      } else {
        setInputValues({
          data: [],
        });
      }
    } else if (flightDirection === "arrival") {
      if (autocompleteValue[index].arrivalAirport !== null) {
        setInputValues({
          data: [autocompleteValue[index].arrivalAirport],
        });
      } else {
        setInputValues({
          data: [],
        });
      }
    } else {
      setInputValues({
        data: [],
      });
    }
  }, [autocompleteValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (onFocusChange) {
      inputRef.current?.focus();
      setShowPopover(true);

      let newValue = [...autocompleteValue];

      if (flightDirection === "departure") {
        newValue[index] = {
          ...newValue[index],
          departureAirport: null,
        };
      } else if (flightDirection === "arrival") {
        newValue[index] = {
          ...newValue[index],
          arrivalAirport: null,
        };
      }

      setAutocompleteValue(newValue);
    }
  }, [focus]);





  const handlePopover = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setQuery("");
      inputRef.current.focus();
      setShowPopover(true);
    }
  };

  useEffect(() => {
    if (!showPopover) {
      if (!autocompleteValue) {
        setQuery("");
      } else {
        if (flightDirection === "departure") {
          setQuery(
            autocompleteValue[index]?.departureAirport?.text ||
              autocompleteValue[index]?.departureAirport?.id ||
              ""
          );
        } else if (flightDirection === "arrival") {
          setQuery(
            autocompleteValue[index]?.arrivalAirport?.text ||
              autocompleteValue[index]?.arrivalAirport?.id ||
              ""
          );
        }
      }
    }
  }, [showPopover, autocompleteValue]);

  useEffect(() => {
    if (!showPopover && inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.value =
          flightDirection === "departure"
            ? autocompleteValue[index]?.departureAirport?.text ||
              autocompleteValue[index]?.departureAirport?.id ||
              ""
            : flightDirection === "arrival"
            ? autocompleteValue[index]?.arrivalAirport?.text ||
              autocompleteValue[index]?.arrivalAirport?.id ||
              ""
            : "";
      }
    }
  }, [showPopover, autocompleteValue]);

  const handleSelectLocation = (item: any) => {

    if (item.id.length !== 3) return;

    const newItem: { id: string; text: string } = {
      id: item.id,
      text: item.text.split(' ')[0],    };

    let newValue = [...autocompleteValue];

    if (flightDirection === "departure") {
      newValue[index] = {
        ...newValue[index],
        departureAirport: newItem,
      };
    } else if (flightDirection === "arrival") {
      newValue[index] = {
        ...newValue[index],
        arrivalAirport: newItem,
      };
    }

    setAutocompleteValue(newValue);

    setInputValues((prevInputValues: any) => {
      return {
        data: [...prevInputValues.data, newItem],
      };
    });
    //setQuery("");
    onInputDone && onInputDone();
    setShowPopover(false);
    setDateFocus(true);
    setFocus(false);
    setQuery("");
  };


  useEffect(() => {
    if (inputValues.data.length > 1) {
      const idValues = inputValues?.data.map((value) => value?.id);
      const textValues = inputValues?.data.map((value) => value?.text);

      let updatedAutocompleteValue =
        {} as AirportSearchAutocompleteResult | null;

      if (flightDirection === "departure") {
        if (autocompleteValue[index].departureAirport !== null) {
          updatedAutocompleteValue = {
            ...autocompleteValue[index].departureAirport,
            id: idValues.join(","),
            text: textValues.join(","),
          } as AirportSearchAutocompleteResult;
        } else {
          updatedAutocompleteValue = null;
        }
      } else if (flightDirection === "arrival") {
        if (autocompleteValue[index].arrivalAirport !== null) {
          updatedAutocompleteValue = {
            ...autocompleteValue[index].arrivalAirport,
            id: idValues.join(","),
            text: textValues.join(","),
          } as AirportSearchAutocompleteResult;
        } else {
          updatedAutocompleteValue = null;
        }
      }

      let newValue = [...autocompleteValue];

      if (flightDirection === "departure") {
        newValue[index] = {
          ...newValue[index],
          departureAirport: updatedAutocompleteValue,
        };
      } else if (flightDirection === "arrival") {
        newValue[index] = {
          ...newValue[index],
          arrivalAirport: updatedAutocompleteValue,
        };
      }

      setAutocompleteValue(newValue);

      // setAutocompleteValue((prevAutocomplete: MultiCitySearchModel[]) => {
      //     flightDirection === 'departure' ?
      //         prevAutocomplete[index].departureAirport = updatedAutocompleteValue
      //         :
      //         prevAutocomplete[index].arrivalAirport = updatedAutocompleteValue
      //     return prevAutocomplete
      // });
    }
  }, [inputValues, inputValues.data]);

  const renderSuggestedSearches = () => {
    return (
      <>
        <h3 className="text-base sm:text-lg mt-2 block px-4 font-semibold text-neutral-800 dark:text-neutral-100 sm:mt-0 sm:px-8">
          Suggestions
        </h3>
        <div className="mt-2">
          <div>
            {suggested?.length && suggested.length > 0 ? (
              suggested.map((item) => (
                <span
                  onClick={() => handleSelectLocation(item)}
                  key={item.id}
                  className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
                >
                  <span className="block text-neutral-400">
                    <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                  </span>
                  <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                    {item.text || item.label}
                  </span>
                </span>
              ))
            ) : (
              <span className="text-sm px-4 py-4 text-neutral-400 sm:px-8 sm:py-5">
                No results
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  // const renderRecentSearches = () => {
  //   return (
  {
    /*  <>
                <h3 className="mt-2 block px-4 text-base font-semibold text-neutral-800 dark:text-neutral-100 sm:mt-0 sm:px-8 sm:text-lg">
                    {t('app.recent-searches')}
                </h3>
                <div className="mt-2">
                    {recentSearches.length > 0 ? (
                        recentSearches.map((recentSearch) => (
                            <span
                                onClick={() => handleSelectLocation(recentSearch)}
                                key={recentSearch.id}
                                className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
                            >
                                <span className="block text-neutral-400">
                                    <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                                </span>
                                <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                                    {recentSearch.text || recentSearch.label}
                                </span>
                            </span>
                        ))
                    ) : (
                        <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
                            {category === "tour" ?
                                <>
                                    <span
                                        onClick={() => handleSelectLocation(defaultValue)}
                                        key={defaultValue.id}
                                        className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
                                    >
                                        <span className="block text-neutral-400">
                                            <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                                        </span>
                                        <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                                            {defaultValue.text || defaultValue.label}
                                        </span>
                                    </span>
                                    <span className="py-4 px-4">For more locations, enter name of the city, region or tour</span>
                                </>
                                :
                                <>
                                    No recent searches
                                </>
                            }
                        </span>
                    )}
                </div>
                        </> */
  }
  // );
  // };

  const renderSearchValue = () => {
    return (
      <div
        className="absolute left-0 top-full z-40 mt-0 max-h-96 w-full min-w-[250px] overflow-y-auto rounded border border-neutral-200 bg-white py-3 shadow-sm dark:bg-neutral-800 sm:min-w-[350px] sm:py-6"
      >
        {inputValues.data.length > 0 && 
        <div className="flex justify-center">
                  <span className="font-semibold text-sm"> {t("flight.search.selected-airports")}</span>

        </div>
        
        }
         <Stack direction={inputValues.data.length > 2 ? "column" : "row"} spacing={0.5} p={1} px={4} alignItems="flex-start">
       
            {autocompleteValue  &&
              inputValues.data.map((value: any, index: number) => (
                <Chip
                  key={index}
                  size="small"
                  label={value?.text +" " + value?.id}
                  onDelete={() => handleChipDelete(value.id)}
                  variant="filled"
                />

              ))}
              
          </Stack>
          {result?.length && result.length > 0 ? (
               <><div className="flex justify-center">
            <span className="font-semibold text-sm"> {t("flight.search.select-airport")}</span>

          </div><Stack direction="column" spacing={0.5} p={1} px={2} alignItems="flex-center">
              {result.map((item) => (
                <Chip
                  key={item.id}
                  label={<div className="grid grid-cols-8 gap-4">
                    <div className="text-neutral-400 text-xs flex items-center w-full col-span-7">
                      <span className="pr-0.5">
                        <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                      </span>
                      <span className="font-base block text-neutral-700 dark:text-neutral-200 truncate">
                        {item.text || item.label}
                      </span>
                    </div>

                    <div className="flex justify-end text-neutral-400 col-span-1">
                      <AddIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                    </div>
                  </div>}
                  clickable
                  onClick={() => handleSelectLocation({ ...item, text: item.text + '+' })}
                  className="m-1" />
              ))}
            </Stack></>
) : (
  <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
    {t("app.no-search-results")}
  </span>
)}
      </div>
    );
  };

  const errorClassName = error ? "text-red-400" : "text-neutral-400";

  const handleChipDelete = (chipIndex: any) => {
    setInputValues(
      (
        prevInputValues: MyState<{ id: string; text?: string; label?: string }>
      ) => {
        const updatedData = prevInputValues.data.filter(
          (item) => item?.id !== chipIndex
        );
        return {
          data: updatedData,
        };
      }
    );
    setQuery("");

    let newValue = [...autocompleteValue];

    if (flightDirection === "departure") {
      newValue[index] = {
        ...newValue[index],
        departureAirport: null,
      };
    } else {
      newValue[index] = {
        ...newValue[index],
        arrivalAirport: null,
      };
    }

    setAutocompleteValue(newValue);
  };

  // useEffect(() => {
  //     const idValues = inputValues?.data.map(value => value?.id);
  //     const textValues = inputValues?.data.map(value => value?.text);

  //     let updatedAutocompleteValue = {} as AirportSearchAutocompleteResult | null;

  //     if (flightDirection === 'departure') {
  //         if (autocompleteValue[index].departureAirport !== null) {
  //             updatedAutocompleteValue = {
  //                 ...autocompleteValue[index].departureAirport,
  //                 id: idValues.join(","),
  //                 text: textValues.join(",")
  //             } as AirportSearchAutocompleteResult;
  //         } else {
  //             updatedAutocompleteValue = null
  //         }
  //     } else if (flightDirection === 'arrival') {
  //         if (autocompleteValue[index].arrivalAirport !== null) {
  //             updatedAutocompleteValue = {
  //                 ...autocompleteValue[index].arrivalAirport,
  //                 id: idValues.join(","),
  //                 text: textValues.join(",")
  //             } as AirportSearchAutocompleteResult;
  //         } else {
  //             updatedAutocompleteValue = null
  //         }
  //     }

  //     let newValue = [...autocompleteValue]

  //     if (flightDirection === 'departure') {
  //         newValue[index] = {
  //             ...newValue[index],
  //             departureAirport: updatedAutocompleteValue
  //         }
  //     } else if (flightDirection === 'arrival') {
  //         newValue[index] = {
  //             ...newValue[index],
  //             arrivalAirport: updatedAutocompleteValue
  //         }
  //     }

  //     setAutocompleteValue(newValue);

  // }, [handleChipDelete])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
    setAutocompleteValue(
      (prevAutocomplete: MultiCitySearchModel[]) => prevAutocomplete
    );
  };
  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => handlePopover()}
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none  `}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <Icon className="nc-icon-field" />
          <span
              className={
                "text-sm mt-1.5 flex  justify-start text-center font-light text-neutral-400 " +
                errorClassName
              }
            >
              {!!inputValues || !!query ? desc : placeHolder}
            </span>
        </div>
        <div className="flex-grow">
          <span
            className={
              "text-sm mt-1.5 block text-center font-light text-neutral-400"
            }
          ></span>
         

          <Stack direction="row" spacing={1}>
          
            {autocompleteValue &&
              inputValues.data.map((value: any, index: number) => (
                <Chip
                  key={index}
                  label={value?.text +" " + value?.id}
                  onDelete={() => handleChipDelete(value.id)}
                  variant="filled"
                />
              ))}
               <input
            className={`xl:text-lg block w-full truncate border-none bg-transparent p-0  font-semibold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200 `}
            placeholder={inputValues.data.length > 0 ? '' : placeHolder} 
            value={showPopover ? query : ''}
            autoFocus={showPopover}
            onChange={handleInputChange}
            ref={inputRef}
          />
          </Stack>
          {query && showPopover && (
            <ClearDataButton
              onClick={() => {
                setQuery("");
                setAutocompleteValue((prevState: any) => {
                  flightDirection === "departure"
                    ? (prevState[index].departureAirport = null)
                    : (prevState[index].arrivalAirport = null);
                  return prevState;
                });
                setInputValues({ data: [] });
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="">
          {
             renderSearchValue()
           }
        </div>
      )}
    </div>
  );
}

export default AutocompleteForMultiFlight;

function useSWRImmutable<T>(arg0: string | null, fetcher: any): { data: any } {
  throw new Error("Function not implemented.");
}
