import React, { useEffect, useMemo, useState } from "react";
import NcInputNumber from "components/common/NcInputNumber";
import Modal from "components/ui/Modal";
import { useTranslation } from "react-i18next";
import Input from "components/ui/Input/Input";
import ButtonThird from "components/ui/Button/ButtonThird";
import ButtonSecondary from "components/ui/Button/ButtonSecondary";
import Label from "components/ui/Label";
import cn from "classnames";
import { UserAddIcon } from "@heroicons/react/outline";
import RoomOccupanciesService from "services/RoomOccupanciesService";
import { PackagePaxList } from "api/types";
import { DatePicker } from "components/ui/DatePicker/DatePicker";
import moment from "moment";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import Select from "components/ui/Select/Select";

export interface RoomInput {
  adults: number;
  children: number[];
}

export interface GuestsInputProps {
  guestValue: Array<RoomInput>;
  setGuestValue: React.Dispatch<React.SetStateAction<RoomInput[]>>;
  paxList?: PackagePaxList;
  setPaxList?: React.Dispatch<React.SetStateAction<PackagePaxList>>;
  wrapperClassName?: string;
  iconClassName?: string;
  labelClassName?: string;
  subtitleClassName?: string;
  subtitle?: boolean;
  roomAvailable?: boolean;
  maxAdultCount?: number;
  maxChildCount?: number;
}

const GuestsInput: React.FC<GuestsInputProps> = ({
  guestValue,
  setGuestValue,
  paxList,
  setPaxList,
  wrapperClassName,
  iconClassName = "",
  labelClassName = "",
  subtitleClassName = "",
  subtitle = true,
  roomAvailable = true,
  maxAdultCount = 12,
  maxChildCount = 6,
}) => {
  const { t } = useTranslation();

  const numOfGuests = useMemo(
    () => RoomOccupanciesService.getTotalGuests(guestValue),
    [JSON.stringify(guestValue)]
  );

  useEffect(() => {
    const updatedPaxList: PackagePaxList = [];

    for (let i = 0; i < guestValue[0].adults; i++) {
      updatedPaxList.push({ age: 33, type: "ADULT" });
    }

    for (let i = 0; i < guestValue[0].children.length; i++) {
      updatedPaxList.push({ age: guestValue[0].children[i], type: "CHILD" });
    }
    if (setPaxList) setPaxList(updatedPaxList);
  }, [guestValue]);
   return (
    <Modal
      contentExtraClass="max-w-screen-md"
      modalTitle={t("react.hotel.travellers")}
     
      renderTrigger={(openModal) => (
        <div
          onClick={openModal}
          className={`[ nc-hero-field-padding ] relative flex h-full flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 ${wrapperClassName}`}
        >
          <div>
            <UserAddIcon
              className={cn(
                "nc-icon-field text-neutral-300 dark:text-neutral-400",
                iconClassName
              )}
            />
          </div>
          <div className="flex-grow">
            <span className={cn("xl:text-base block font-semibold", labelClassName)}>
              {numOfGuests || ""} {t("react.guests")}
            </span>
            {subtitle && (
              <span
                className={cn(
                  "-mt-1 block text-sm font-medium text-neutral-400",
                  subtitleClassName
                )}
              >
                {numOfGuests ? t("react.guests") : t("react.guests") + " " + t("generals.add")}
              </span>
            )}
          </div>
        </div>
      )}
      renderContent={(closeModal) => (
        <>
          <GuestsInputModal
            guestValue={guestValue}
            setGuestValue={setGuestValue}
            roomAvailable={roomAvailable}
            maxAdultCount={maxAdultCount}
            maxChildCount={maxChildCount}
          />
          <span className="flex justify-end">
            <ButtonPrimary onClick={() => {  closeModal(); }}>
              {t("b2c-app.generals.done")}
            </ButtonPrimary>
          </span>
        </>
      )}
    />
  );
};

type GuestsInputModalProps = GuestsInputProps & {};

const GuestsInputModal: React.FC<GuestsInputModalProps> = ({
  guestValue,
  setGuestValue,
  roomAvailable,
  maxAdultCount = 12,
  maxChildCount = 6,
}) => {
  const { t } = useTranslation();
  const [datesOfBirth, setDatesOfBirth] = useState<Record<number, Record<number, moment.Moment | null>>>({});

  const handleAddRoom = () => {
    setGuestValue((prev) => [...prev, { adults: 2, children: [] }]);
    setDatesOfBirth((prev) => ({ ...prev, [guestValue.length]: {} }));
  };

  const handleRemoveRoom = (indexRoom: number) => {
    setGuestValue((prev) => {
      const roomsState = [...prev];
      roomsState.splice(indexRoom, 1);
      return roomsState;
    });
    setDatesOfBirth((prev) => {
      const datesState = { ...prev };
      delete datesState[indexRoom];
      return datesState;
    });
  };

  const handleAdultChange = (numAdults: number, indexRoom: number) => {
    setGuestValue((prev) => {
      const roomsState = [...prev];
      roomsState[indexRoom].adults = numAdults;
      return roomsState;
    });
  };

  const handleChildrenChange = (numChildren: number, indexRoom: number) => {
    setGuestValue((prev) => {
      const roomsState = [...prev];
      let children = roomsState[indexRoom].children;
      if (children.length > numChildren) {
        children.splice(numChildren);
      } else if (children.length < numChildren) {
        children = [
          ...children,
          ...Array(numChildren - children.length).fill(0),
        ];
      }
      roomsState[indexRoom].children = children;
      return roomsState;
    });

    setDatesOfBirth((prev) => {
      const datesState = { ...prev };
      if (!datesState[indexRoom]) {
        datesState[indexRoom] = {};
      }
      const currentChildrenCount = Object.keys(datesState[indexRoom]).length;
      if (currentChildrenCount > numChildren) {
        Object.keys(datesState[indexRoom]).forEach((key: any) => {
          if (Number(key) >= numChildren) {
            delete datesState[indexRoom][key];
          }
        });
      }
      return datesState;
    });
  };

  const handleChildChange = (
    numChild: string,
    childIndex: number,
    roomIndex: number
  ) => {
    const num = Number(numChild || "0");
    if (num < 0 || num > 14) {
      return;
    }
    setGuestValue((prev) => {
      const roomsState = [...prev];
      roomsState[roomIndex].children[childIndex] = num;
      return roomsState;
    });
  };

  return (
    <div>
      {guestValue.map((room, roomIndex) => (
        <div
          key={roomIndex}
          className="relative border-b border-neutral-100 py-4 px-6 dark:border-neutral-700 md:py-5"
        >
          {roomAvailable && (
            <div>
              <h2 className="mb-2 mt-2 text-xl font-semibold">
                {t("react.hotel.room")} {roomIndex + 1}
              </h2>
              <h3 className="mb-6 text-sm font-semibold uppercase text-neutral-400">
                {t("react.hotel.guests-in-room")}
              </h3>
            </div>
          )}
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-12">
            <NcInputNumber
              defaultValue={guestValue[roomIndex].adults}
              onChange={(numAdults: number) =>
                handleAdultChange(numAdults, roomIndex)
              }
              label={t("react.hotel.adult-count")}
              max={maxAdultCount}
            />

            <NcInputNumber
              defaultValue={guestValue[roomIndex].children.length}
              onChange={(numChildren: number) =>
                handleChildrenChange(numChildren, roomIndex)
              }
              label={t("react.hotel.children-count")}
              max={maxChildCount}
            />
          </div>
          {room.children.length > 0 && (
            <div>
              <h3 className="mb-6 mt-6 text-sm font-semibold uppercase text-neutral-400">
                {t("react.hotel.children-ages")}
              </h3>
              <div className="grid grid-cols-2 gap-x-6 gap-y-6 md:grid-cols-4 md:gap-x-12">
                {room.children.map((child, childIndex) => (
                  <div key={childIndex}>
                 
                 <label>
        {childIndex + 1}. {t("react.hotel.child")}
      </label>
      <Select
        value={child}
        onChange={(e) => handleChildChange(e.target.value, childIndex, roomIndex)}
      >
 {Array.from({ length: 14 }, (_, i) => (
          <option key={i + 1} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </Select>
                  </div>
                ))}
              </div>
            </div>
          )}

          {roomIndex !== 0 && (
            <div>
              <ButtonThird
                className="mt-5 !px-0 text-primary-700 dark:text-primary-500"
                onClick={() => handleRemoveRoom(roomIndex)}
              >
                {t("react.hotel.remove-room")}
              </ButtonThird>
            </div>
          )}
        </div>
      ))}
      {roomAvailable && (
        <div>
          <ButtonSecondary className="mt-5" onClick={handleAddRoom}>
            {t("hotel.add-room")}
          </ButtonSecondary>
        </div>
      )}
    </div>
  );
};

export default GuestsInput;
