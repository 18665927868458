import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { AtrDateMonthFormat, AtrDateFormat } from "../calendar";
import $ from "jquery";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { checkAge, validationDateValue } from "../../actions";
import { SetPassportData } from "./SetPassportData";
import { Grid } from "@material-ui/core";
import { t } from "i18next";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#1976d0",
  },
  title: {
    marginLeft: "50px",
    flex: 1,
    color: "white",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const gender = [
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
];

export function BuildPassengerSection(props) {
  const classes = useStyles();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [passenger, setPassenger] = useState(
    props.passengerObject[props.passengerType]
  );
  let passengerObject = JSON.parse(JSON.stringify(props.passengerObject));
  let keys = Object.keys(props.passengerObject);
  for (let i = 0; i < keys.length; i++) {
    passengerObject[keys[i]].passportInfo.passportExpiryDate.value =
      props.passengerObject[keys[i]].passportInfo.passportExpiryDate.value;
  }
  let passengerType = props.passengerType;

  let tmpPassenger = Object.assign({}, passenger);

  let i = props.index;
  let invalidMsg = props.invalidMsg;
  let invalidBirthdateMsg = props.invalidMsg;

  let passengerDataChanged = false;

  let isDateValueInvalid = !validationDateValue(
    props.passengerObject[passengerType].displayBirthdate
  );
  let isAgeInvalid = !checkAge(
    passengerType,
    new Date(props.passengerObject[passengerType]["birthdate"]),
    new Date(props.departureDate)
  );

  let showValidationFirstname =
    props.showValidation &&
    props.passengerObject[passengerType].firstname === "";
  let showValidationSurname =
    props.showValidation && props.passengerObject[passengerType].surname === "";
  let showValidationBirthdate =
    props.showValidation &&
    (props.passengerObject[passengerType].displayBirthdate === "" ||
      isDateValueInvalid ||
      isAgeInvalid);
  let showValidationHesCode =
    props.showValidation &&
    (!props.passengerObject[passengerType].hasOwnProperty("hesCode") ||
      props.passengerObject[passengerType]["hesCode"] === "" ||
      props.passengerObject[passengerType]["hesCode"].length < 6);

  if (showValidationBirthdate && isDateValueInvalid) {
    invalidBirthdateMsg =
      "Das Datum bitte in folgendem Format eingeben: dd.mm.yyyy";
  } else if (showValidationBirthdate && isAgeInvalid) {
    invalidBirthdateMsg = "Das Alter passt nicht zum Passagiertypen";
  }

  const closeDatePicker = () => {
    setOpenDatePicker(false);
  };

  useEffect(() => {
    if (props.isMobile) {
      if (openDatePicker) {
        initDatePicker();
        $(
          ".react-google-flight-datepicker > .dialog-date-picker .dialog-footer button"
        ).on("click", closeDatePicker);
      }

      if (passengerDataChanged) {
        passengerDataChanged = false;
        props.updatePassengerValue(passengerObject);
      }
    }
  });

  return (
    <div style={{ padding: "8px 0" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <TextField
            style={{ width: "100%" }}
            name={
              "flightcheckout-card-passenger-passengerObject-" +
              passengerType +
              "-" +
              (i + 1)
            }
            id={
              "flightcheckout-card-passenger-passengerObject-" +
              passengerType +
              "-" +
              (i + 1)
            }
            label={t("react.generel.gender")}
            select
            value={passengerObject[passengerType].gender}
            defaultValue={passengerObject[passengerType].gender}
            onChange={(e) => {
              passengerObject[passengerType].gender = e.target.value;
              props.updatePassengerValue(passengerObject);
            }}
            variant="outlined"
          >
            {gender.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            error={showValidationFirstname}
            helperText={showValidationFirstname ? invalidMsg : ""}
            style={{ width: "100%" }}
            name={
              "flightcheckout-card-passenger-firstname-" +
              passengerType +
              "-" +
              (i + 1)
            }
            label={t("react.generel.firstname")}
            variant="outlined"
            onBlur={(e) => {
              passengerObject[passengerType].firstname = e.target.value;
              props.updatePassengerValue(passengerObject);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            error={showValidationSurname}
            helperText={showValidationSurname ? invalidMsg : ""}
            style={{ width: "100%" }}
            name={
              "flightcheckout-card-passenger-surname-" +
              passengerType +
              "-" +
              (i + 1)
            }
            label={t("react.generel.name")}
            variant="outlined"
            onBlur={(e) => {
              passengerObject[passengerType].surname = e.target.value;
              props.updatePassengerValue(passengerObject);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            error={showValidationBirthdate}
            helperText={showValidationBirthdate ? invalidBirthdateMsg : ""}
            style={{ width: "100%" }}
            name={
              "flightcheckout-card-passenger-birthdate-" +
              passengerType +
              "-" +
              (i + 1)
            }
            id={
              "flightcheckout-card-passenger-birthdate-" +
              passengerType +
              "-" +
              (i + 1)
            }
            label={t("react.generel.birthdate")}
            variant="outlined"
            onChange={(e) => {
              const re = /^[0-9.]*$/;

              let inputValue = e.target.value;

              if (re.test(inputValue) && !inputValue.includes("..")) {
                if (inputValue.slice(-1) === ".") {
                  if (inputValue.length === 3 || inputValue === 6) {
                    $(e.target).val(inputValue);
                  }
                }
                if (re.test(inputValue)) {
                  if (inputValue.length < 11) {
                    if (inputValue.length === 2 || inputValue.length === 5) {
                      if (
                        inputValue.length === 2 &&
                        inputValue[inputValue.length - 1] === "."
                      ) {
                        inputValue = "0" + inputValue;
                      } else if (
                        inputValue.length === 5 &&
                        inputValue[inputValue.length - 1] === "."
                      ) {
                        let tmpInputValue = inputValue.split(".")[0] + ".";

                        if (inputValue.split(".")[1] === "0") {
                          tmpInputValue += "01.";
                        } else {
                          tmpInputValue += "0" + inputValue.split(".")[1] + ".";
                        }
                        inputValue = tmpInputValue;
                      }

                      $(e.target).val(inputValue);
                    } else if (inputValue.length > 2) {
                      if (
                        inputValue.length === 3 &&
                        inputValue.slice(-1) !== "."
                      ) {
                        inputValue = correctInputValue(inputValue, 2);
                      } else if (
                        inputValue.length === 6 &&
                        inputValue.slice(-1) !== "."
                      ) {
                        inputValue = correctInputValue(inputValue, 5);
                      }

                      let inputValueSplit = inputValue.split(".");

                      if (parseInt(inputValueSplit[0]) > 31) {
                        inputValueSplit[0] = "31";
                        $(e.target).val(inputValueSplit[0] + ".");
                      }

                      if (
                        inputValueSplit.length > 1 &&
                        parseInt(inputValueSplit[1]) > 12
                      ) {
                        inputValueSplit[1] = "12";
                        $(e.target).val(
                          inputValueSplit[0] + "." + inputValueSplit[1] + "."
                        );
                      } else {
                        let tmpValue = "";
                        for (let i = 0; i < inputValueSplit.length; i++) {
                          tmpValue += inputValueSplit[i];
                          if (i + 1 < inputValueSplit.length) {
                            tmpValue += ".";
                          }
                        }
                        $(e.target).val(tmpValue);
                      }
                    }
                  } else {
                    $(e.target).val(inputValue.substring(0, 10));
                  }

                  if (inputValue.length === 10) {
                    let inputValueSplit = inputValue.split(".");

                    passengerObject[passengerType].birthdate = new Date(
                      inputValueSplit[2] +
                        "-" +
                        inputValueSplit[1] +
                        "-" +
                        inputValueSplit[0]
                    );
                    passengerObject[passengerType].displayBirthdate =
                      inputValue;

                    props.updatePassengerValue(passengerObject);
                  }
                }
              } else {
                $(e.target).val(inputValue.substring(0, inputValue.length - 1));
              }
            }}
            onClick={() => {
              setOpenDatePicker(!openDatePicker);
            }}
            InputLabelProps={
              passengerObject[passengerType].displayBirthdate !== ""
                ? { shrink: true }
                : {}
            }
          />
          <div
            className="calendar-passenger"
            data-passengerValue={passengerType + "-" + (i + 1)}
          >
            {openDatePicker ? (
              <OutsideClickHandler
                onOutsideClick={(event) => {
                  if (
                    typeof $(event.target)
                      .closest('div[class^="calendar-"]')
                      .attr("data-passengervalue") === "undefined"
                  ) {
                    setOpenDatePicker(!openDatePicker);
                  }
                }}
              >
                <Calendar
                  minDate={
                    passengerObject[passengerType].hasOwnProperty("minDate")
                      ? new Date(passengerObject[passengerType].minDate)
                      : null
                  }
                  maxDate={new Date(passengerObject[passengerType].maxDate)}
                  value={
                    passengerObject[passengerType].birthdate !== ""
                      ? new Date(passengerObject[passengerType].birthdate)
                      : ""
                  }
                  onClickDay={(value, event) => {
                    passengerObject[passengerType].birthdate = value;
                    passengerObject[passengerType].displayBirthdate =
                      AtrDateFormat(value);

                    let inputElement =
                      "#flightcheckout-card-passenger-birthdate-" +
                      $(event.target)
                        .closest('div[ class^="calendar-passenger" ]')
                        .attr("data-passengerValue");
                    $(inputElement).val(AtrDateFormat(value));

                    setOpenDatePicker(!openDatePicker);
                    props.updatePassengerValue(passengerObject);
                  }}
                />
              </OutsideClickHandler>
            ) : (
              ""
            )}
          </div>
        </Grid>
        {props.isSelectBaggageReady || props.isHesCodeRequired ? (
          <React.Fragment>
            <Grid item xs={12} md={2} />
            {props.isHesCodeRequired &&
            !passengerType.toLowerCase().startsWith("inf") ? (
              <Grid item xs={12} md={3}>
                <TextField
                  error={showValidationHesCode}
                  helperText={
                    showValidationHesCode
                      ? props.passengerObject[passengerType].hasOwnProperty(
                          "hesCode"
                        ) &&
                        props.passengerObject[passengerType]["hesCode"].length <
                          6
                        ? t("react.flight.hescode.minlengtherror")
                        : invalidMsg
                      : ""
                  }
                  style={{ width: "100%" }}
                  name={
                    "flightcheckout-card-passenger-hescode-" +
                    passengerType +
                    "-" +
                    (i + 1)
                  }
                  label="HES Code"
                  variant="outlined"
                  onBlur={(e) => {
                    passengerObject[passengerType].hesCode = e.target.value;
                    props.updatePassengerValue(passengerObject);
                  }}
                />
              </Grid>
            ) : (
              ""
            )}
            {props.optionBooking.map((option, index) => (
              <Grid item xs={12} md={3}>
                <TextField
                  style={{ width: "100%" }}
                  name={index + "-i-flightcheckout-addbaggage-" + passengerType}
                  id={"flightcheckout-addbaggage-" + passengerType}
                  label={t("react.checkout.addbaggage")}
                  variant="outlined"
                  select
                  onChange={(event) => {
                    let baggageUpdate = JSON.parse(
                      JSON.stringify(props.selectedBaggage)
                    );
                    let passengerType =
                      event.target.name.split("addbaggage-")[1];
                    let index = event.target.name.split("-i")[0];

                    if (!baggageUpdate.hasOwnProperty(passengerType)) {
                      baggageUpdate[passengerType] = {};
                    }

                    if (event.target.value.key !== "-1") {
                      baggageUpdate[passengerType][index].price = parseFloat(
                        event.target.value.descr
                          .replace(/ /g, "")
                          .replace(/EUR/g, "")
                          .split("-")[2]
                      );
                      baggageUpdate[passengerType][index].desc =
                        event.target.value.descr;
                      baggageUpdate[passengerType][index].key =
                        event.target.value.key;
                    } else {
                      baggageUpdate[passengerType] = {};
                      baggageUpdate[passengerType][index].desc =
                        event.target.value.descr;
                      baggageUpdate[passengerType][index].key = "";
                      baggageUpdate[passengerType][index].price = 0.0;
                    }

                    props.updateBaggage(baggageUpdate);
                  }}
                >
                  {props.optionBooking[index].options.map((option) => (
                    <MenuItem key={option.key} value={option}>
                      {option.descr}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ))}
            {props.isHesCodeRequired &&
            !passengerType.toLowerCase().startsWith("inf") ? (
              <Grid item xs={12} md={4} />
            ) : (
              <Grid
                item
                xs={12}
                md={
                  props.optionBooking.length === 1
                    ? 7
                    : props.optionBooking === 2
                    ? 4
                    : 9
                }
              />
            )}
          </React.Fragment>
        ) : (
          ""
        )}
        {props.isPassportRequired ? (
          <SetPassportData
            passportinfo={
              props.passengerObject[props.passengerType].passportInfo
            }
            genderType={props.passengerType}
            updatePassportNationality={(nationality) => {
              passengerObject[
                passengerType
              ].passportInfo.passportNationality.value = nationality;
              props.updatePassengerValue(passengerObject);
            }}
            updatePassportNumber={(passportNumber) => {
              passengerObject[passengerType].passportInfo.passportNumber.value =
                passportNumber;
              props.updatePassengerValue(passengerObject);
            }}
            updatePassportExpiryDate={(expiryDate) => {
              passengerObject[
                passengerType
              ].passportInfo.passportExpiryDate.value = expiryDate;
              props.updatePassengerValue(passengerObject);
            }}
            updatePassportCountryOfIssue={(countryOfIssue) => {
              passengerObject[
                passengerType
              ].passportInfo.passportCountryOfIssue.value = countryOfIssue;
              props.updatePassengerValue(passengerObject);
            }}
          />
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}

function correctInputValue(value, index) {
  let newValue = "";

  for (let i = 0; i < value.length; i++) {
    if (i === index) {
      newValue += ".";
    }

    newValue += value[i];
  }

  return newValue;
}

function initDatePicker() {
  if (
    !$(".react-google-flight-datepicker > .dialog-date-picker").hasClass("open")
  ) {
    //$( '.mobile-roundtrip-datepicker' ).removeClass( 'deactive-datepicker' );

    $(".react-google-flight-datepicker > .dialog-date-picker")
      .removeClass("hide")
      .removeClass("close")
      .addClass("open");

    $("#start-date-input-button").click();
  }

  $(
    ".react-google-flight-datepicker > .dialog-date-picker .dialog-footer button"
  ).click(function () {
    if (
      $(".react-google-flight-datepicker > .dialog-date-picker").hasClass(
        "open"
      )
    ) {
      $(".react-google-flight-datepicker > .dialog-date-picker")
        .removeClass("open")
        .removeClass("hide")
        .addClass("close");
      //$( '.mobile-roundtrip-datepicker' ).addClass( 'deactive-datepicker' );
    }
  });

  $(".react-google-flight-datepicker > .dialog-date-picker button").click(
    function () {
      if (
        $(".react-google-flight-datepicker > .dialog-date-picker").hasClass(
          "open"
        )
      ) {
        $(".react-google-flight-datepicker > .dialog-date-picker")
          .removeClass("open")
          .removeClass("hide")
          .addClass("close");
        //$( '.mobile-roundtrip-datepicker' ).addClass( 'deactive-datepicker' );
      }
    }
  );
}
