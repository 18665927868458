import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel, FormGroup} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const CustomCheckbox = withStyles({
    root: {
        marginBottom: 0,
        '&$checked': {
            color: '#1976d0',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


export function AtrCheckbox( props )
{
    const [ checked, setChecked ] = useState( false );
    return(
        <FormGroup>
            <FormControlLabel
                control={
                    <CustomCheckbox
                        id = { props.name }
                        name = { props.name }
                        className = { props.name }
                        checked = { checked }
                        onChange = { () =>
                        {
                            setChecked( !checked );
                            props.updateValue( !checked );
                        }}
                    />
                }
                label = { <Typography>{ props.label }</Typography> }
            />
        </FormGroup>
    )
}