import { useEffect, useState } from "react";
import { useDebouncedValue } from "hooks/useDebouncedValue";
import useSWRImmutable from "swr/immutable";

import fetcher from "api/helpers/fetcher";

function useGetAutocomplete<T>(
  defaultValue: string,
  getUrl: (value: string) => string
) {
  const [query, setQuery] = useState(defaultValue);
  const [debounced] = useDebouncedValue(query, 250);

  const { data: result } = useSWRImmutable<T>(
    debounced ? getUrl(debounced) : null,
    fetcher
  );

  useEffect(() => {
    setQuery(defaultValue);
  }, [defaultValue]);

  return {
    query,
    setQuery,
    //@ts-ignore
    result: (result?.results ? result.results : result) as T,
  };
}

export default useGetAutocomplete;
