import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, ListSubheader, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { t } from "i18next";
import {
  phoneCountryCodesData,
  phoneCountryFavoriteCodesData,
} from "../PhoneCountryCodes";

const defaultVal = {
  name: "Germany",
  dial_code: "+49",
  code: "DE",
};

export function PhoneCountryCode(props) {
  const handleChange = (event) => {
    props.updateCustomerValue(event.target.value);
  };

  return (
    <FormControl variant="outlined" style={props.style}>
      <InputLabel id="countrycode-outlined-label">
        {t("react.phonecountrycode")}
      </InputLabel>
      <Select
        labelId="countrycode-label"
        id="phonecountrycode"
        defaultValue={defaultVal["dial_code"]}
        onChange={handleChange}
        label={t("react.phonecountrycode")}
      >
        <option aria-label="None" value="" />
        <ListSubheader>{t("general.farovites")}</ListSubheader>
        {phoneCountryFavoriteCodesData.map((country) => {
          return (
            <MenuItem key={country["dial_code"]} value={country["dial_code"]}>
              {country["name"]} {country["dial_code"]}
            </MenuItem>
          );
        })}
        <ListSubheader>{t("general.countries")}</ListSubheader>
        {phoneCountryCodesData.map((country) => {
          return (
            <MenuItem key={country["dial_code"]} value={country["dial_code"]}>
              {country["name"]} {country["dial_code"]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
