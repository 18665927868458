import StartRating from "components/common/StartRating";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonDelete from "components/ui/Button/ButtonDelete";
import ButtonClose from "components/ui/ButtonClose/ButtonClose";
import Select from "components/ui/Select/Select";
import Input from "components/ui/Input/Input";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { SpinnerDotted } from "spinners-react";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AccessibleIcon from "@mui/icons-material/Accessible";
import LuggageIcon from "@mui/icons-material/Luggage";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AddTaskIcon from "@mui/icons-material/AddTask";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import HistoryFlightCaseModal from "../../../components/ui/Modal/HistoryFlightCaseModal";
import {
 
  getBookingApiUrl,
} from "../../../api/helpers/api-constants";
import currencyFormat from "utils/currencyFormat";
import { Booking, BookingFlightReservation, PaymentInfo,TourPackageBookingDetailResponse } from "api/types";
import { SectionPayment } from ".././SectionPayment";
import LinearProgress from "@mui/material/LinearProgress";
import postFetcher, { postParamRequest } from "api/helpers/postFetcher";
import Timer from "components/common/Timer/Timer";
import fetcher, { fetcherWithoutJSON } from "api/helpers/fetcher";
import { getStaticUrl } from "utils/getStaticUrl";
import BackButton from "components/ui/ButtonBack/ButtonBack";
import { formatServerDate, formatServerDateTime } from "utils/formatserverdate";
import { useReduxReducer } from "redux/store";
import {  Menu, MenuItem,Accordion, AccordionDetails, AccordionSummary, Button, Typography, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { disableButton } from "utils/disableButton";
import CreateFlightCaseModal from "components/ui/Modal/CreateFlightCaseModal";
import Spinner from "components/ui/Spinner/Spinner";
import PaymentThreed from "../components/PaymentThreed";
import PaymentButton from "../PaymentButton";
import CreditCardForm from "../components/CreditCardForm";
import SectionPassanger from "../FlightDetail/components/SectionPassanger";
import FlightDetail from "../FlightDetail";
import { AgencyData, AgencySection } from "../components/AgencySection";
import "../BookingDetail.css";
import HotelDetail, { HotelBooking } from "../HotelDetail";
import FooterButtonsNew from "../components/FooterButtonsNew";
import RentACarDetail, { RentalCarData } from "../RentACarDetail";
import TransferDetail from "../TransferDetail";
import RemarksSection from "../components/RemarksSection";
import { PackageTourDetail } from "../components/PackageTourDetail";
import TourDetail, {  TourDetailResponse } from "../components/TourDetail";



declare global {
  interface Window {
    refundToken: string;
  }
}

interface Hotel {
  checkoutDate: string;
  checkinDate: string;
  cancellationPolicies: string;
  room: any;
  hotelName: string;
  rating: number;
  stars: number;
  hotel: any;
  rooms: any;
  totalAmount: number;
  nights: number;
  taxInfo:string;
  supplierBookingId?: string;
  
}
interface MealOption {
  ssrKey: string;
  name: string;
  currencyCode: string;
  description: string;
  price: number;
}

interface MealSelectionProps {
  onMealSelect: (meal: MealOption) => void;
}

 export interface pnrdata  {
  bookAirportCheckinAvailable: boolean;
  bookBaggageAvailable: JSX.Element;
  refundAvailable: boolean;
  airlineSystem: any;
  bookWheelChairAvailable: boolean;
  bookSeatAvailable: JSX.Element;
  reissueAvailable: JSX.Element;
  voidAvailable: JSX.Element;
  rescanAvailable: any;
  airline: string;
  id: number;
  filekey: string;
  flightNumber: string;
  departurePort: string;
  arrivalPort: string;
  arrivalTime: string;
  departureTime: string;
  airlineLogo: string;
  classOfService: string;
  arrivalDate: number;
  baggageAllowance: string;
  flightList: any[];
  optionDate: string;
  totalAmount: number;
  paxList: any[];
  bookMealAvailable: boolean;
  status: string;
};
type paxdata = {
  flightList: [];
  ancillaryServicesRequests: [];
  baseFare: number;
  birthdate: string;
  firstname: string;
  gender: string;
  id: number;
  ticketnumber: string;
  passportCountryOfIssue: string;
  passportNationality: string;
  passportNumber: string;
  paxtype: string;
  surname: string;
  ticketed: boolean;
  totalAmount: number;
};
type flightdata = {
  airline: string;
  id: number;
  flightNumber: string;
  departurePort: string;
  arrivalPort: string;
  arrivalTime: string;
  departureTime: string;
  airlineLogo: string;
  classOfService: string;
  arrivalDate: number;
  baggageAllowance: string;
};

const BookingDetailNew = () => {
  const { isApp } = useReduxReducer(state => state.general)
  const { audit,product }= useReduxReducer(state => state.config)
  const { isAdmin } = useReduxReducer(state => state.general)
  const { bookingKey } = useParams<{ bookingKey: string }>();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo[]>([]);
  const [bookingData, setbookingData] = useState<Booking>();
  const [flightReservation, setflightReservation] = useState<BookingFlightReservation>();
  const [hotelReservation, sethotelReservation] = useState<HotelBooking>();
  const [carrentData, setcarrentData] = useState<RentalCarData>();
  const [agencySectionData, setagencySectionData] = useState<AgencyData>();
  const [transferData, settransferData] = useState();
  const [packageTourData, setpackageTourData] = useState<TourPackageBookingDetailResponse>();
  const [tourDetail, settourDetail] = useState<TourDetailResponse | null>(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await postFetcher(getBookingApiUrl(bookingKey!), {
        });

        if (response) {
setbookingData(response)
setflightReservation(response.product.flightReservation)
sethotelReservation(response.product.hotelReservation)
setcarrentData(response.product.rentalCarReservation)
settransferData(response.product.transferReservation)
setpackageTourData(response.product.packageTourReservation)
setPaymentInfo(response.paymentInfo.payments)
settourDetail(response.product.tourReservation);
setagencySectionData({
  bookingKey: response.bookingKey,
  email: response.contact.email,
  phone: response.contact.phone,
  agencyNumber: response.agency.number,
  providers:response.product.providers,
  internalRemarks: response.internalRemarks,
  name: response.agency.name
  }
);
      
        } else {
          throw new Error('Something went wrong on the API server!');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (bookingKey !== undefined) getData();
  }, [bookingKey]);

 


  const baseUrl = getStaticUrl('b2b');





  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  
  return (
    <div>
      {loading &&
      <><LinearProgress color="inherit" />
      <div className={`absolute top-0 left-0 z-10 h-full w-full`}>
        <div
          className={`flex h-full items-center justify-center backdrop-blur-sm`}
        >
          <Spinner className="text-primary-6000" />
        </div>
      </div></>}
      {!loading && bookingData && (
        <div>
               <div className="flex justify-end px-4">
          { audit.marketingType === "B2B"  && <Timer initialTime={bookingData.remainingOptionTimeInSeconds} />}
        </div>
      
          <div className="container relative flex items-center justify-between space-x-4 py-4 xl:space-x-8">
            <div className="flex  bg-white w-full items-center justify-center pb-20 text-center sm:block sm:p-0">
              <div className="inline-block   h-full w-full transform transition-all">
             {  audit.marketingType === "B2B" ? (<div className="  ">
                   {!isApp && (<BackButton/>)}
                  {agencySectionData && <AgencySection data={agencySectionData }/>}
                  {<RemarksSection bookingKey={bookingData.bookingKey} initialRemarks={bookingData.remarks}/>}
                   {<SectionPassanger paxdata={bookingData.passengers} data={bookingData} audit={audit} status={bookingData.status } bookingKey={bookingData.bookingKey}/>}
                  {  flightReservation &&      <FlightDetail data={flightReservation} bookingKey={bookingData.bookingKey} />}
                  {hotelReservation && <HotelDetail data = {bookingData.product.hotelReservation} bookingKey={bookingData.bookingKey} paxdata={bookingData.passengers} status= {bookingData.status}/>}
                  {carrentData && <RentACarDetail data={bookingData} carrentData={carrentData}/>}
                  {transferData && <TransferDetail data={bookingData} transferData={transferData} status={bookingData.status}/>}
                 {packageTourData && <PackageTourDetail packageTour={packageTourData}/>} 
                 {tourDetail && <TourDetail tourDetail={tourDetail} />}
                  { isAdmin && <SectionPayment data={{ payments: paymentInfo }} />  }
                  <FooterButtonsNew data1={bookingData}/>
                </div>)
                :
                (<div className=" mt-2 ">
                  <BackButton/>

                 
              

                </div>)
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

            }
export default BookingDetailNew
