import React, {FC, ForwardedRef} from "react";
import {TextField} from "@mui/material";

interface ITextFieldRef {
    showError: boolean,
    ref: ForwardedRef<HTMLTextAreaElement>
    placeholder?: string,
    helperText?: string,
    size?: 'small' | 'medium',
    style: object,
    multiline?: boolean,
    minRows?: number,
    maxRows?: number,
    label?: string
}

const TextFieldRef: FC<ITextFieldRef> = React.forwardRef(
    (
            {
                showError,
                placeholder,
                helperText,
                size,
                style= {width: "100%"},
                multiline,
                minRows,
                maxRows,
                label= ""
            },
            ref: ForwardedRef<HTMLTextAreaElement>
    ) => {
    return (
        <TextField
            inputRef={ref}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            style={style}
            placeholder={placeholder}
            error={showError}
            helperText={showError ? helperText : ''}
            size={size}
            label={label}
        />
    )
});
export default TextFieldRef;