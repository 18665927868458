import React, {FC} from "react"
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import HotelIcon from '@mui/icons-material/Hotel';
import CarRentalIcon from '@mui/icons-material/CarRental';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TourIcon from '@mui/icons-material/Tour';
import {useReduxReducer} from "../../../redux/store";
import {SearchTab} from "../../../api/types";
import {useDispatch} from "react-redux";
import {SetTabSelections} from "../../../redux/slices/tabsSlice";

interface ISearchTypeTabSection {

}

export const SearchTypeTabSection: FC<ISearchTypeTabSection> = () => {
    const { currentTab } = useReduxReducer(state => state.tabs)

    const dispatch = useDispatch()

    const tabs: SearchTab[] = ["Flights", "Hotel", "Car Rental", "Transfer", "Tour"]

    const logo = (type: string) => {
        switch (type) {
            case 'Flights':
                return (
                    <LocalAirportIcon />
                )
            case 'Hotel':
                return (
                    <HotelIcon />
                )
            case 'Car Rental':
                return (
                    <CarRentalIcon />
                )
            case 'Transfer':
                return (
                    <DirectionsBusIcon />
                )
            case 'Tour':
                return (
                    <TourIcon />
                )
        }
    }

    return (
        <div className={`w-full flex gap-x-2 overflow-x-auto my-2 pb-3 border-b`}>
            {
                tabs.map(tab =>
                    <div
                        key={tab}
                        className={`${tab === currentTab && 'rounded bg-gray-200 font-semibold'} px-3 py-2 flex justify-center items-center whitespace-nowrap cursor-pointer hover:font-semibold`}
                        onClick={() =>  {dispatch(SetTabSelections({ currentTab: tab}))
                                         dispatch(SetTabSelections({ activeTab: tab}))
                                            } }
                    >
                        {logo(tab)}
                        &nbsp;
                        <div>{tab}</div>
                    </div>
                )
            }
        </div>
    )
}
