import StartRating from "components/common/StartRating";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonDelete from "components/ui/Button/ButtonDelete";
import ButtonClose from "components/ui/ButtonClose/ButtonClose";
import Select from "components/ui/Select/Select";
import Input from "components/ui/Input/Input";
import Swal from "sweetalert2";
import { Transition } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import "./BookingDetail.css";
import { SpinnerDotted } from "spinners-react";
import PaymentButton from "./PaymentButton";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AccessibleIcon from "@mui/icons-material/Accessible";
import LuggageIcon from "@mui/icons-material/Luggage";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AddTaskIcon from "@mui/icons-material/AddTask";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import CreateFlightCaseModal from "../../components/ui/Modal/CreateFlightCaseModal";
import HistoryFlightCaseModal from "../../components/ui/Modal/HistoryFlightCaseModal";
import {
  bookingRetrieveUrl,
  confirmMealSelection,
  confirmBaggageSelection,
  getBaggageSsr,
  getGetBookingUrl,
  getMealSsr,
  getSeatmapUrl,
  getUserDataUrl,
  confirmAirportCheckinSelection,
  getAirportCheckinSsr,
  confirmWheelChairSelection,
  getWheelChairSsr,
  confirmSeatSelection,
  getBookingLogs,
  getPaxConnectBooking,
  postSaveRemarksUrl,
  postsaveInternalRemarks,
  postRescanPnr,
  postRescanPnrtakeReservation,
  postValidateRefundUrl,
  postAgenyRefundUrl,
  getViewBookingUrl,
  getIssueBookingUrl,
  getIssueBookingWithStripeUrl,
  changePnrStatusURL,
  refreshPnrURL,
  postCancellationFeeHotel,
} from "../../api/helpers/api-constants";
import currencyFormat from "utils/currencyFormat";
import { INotificationModalScroll, PaymentInfo,TourPackageBookingDetailResponse } from "api/types";
import { SectionPayment } from "./SectionPayment";
import LinearProgress from "@mui/material/LinearProgress";
import postFetcher, { postParamRequest } from "api/helpers/postFetcher";
import Timer from "components/common/Timer/Timer";
import { PackageTourDetail } from "./components/PackageTourDetail";
import Spinner from "../../components/ui/Spinner/Spinner";
import fetcher, { fetcherWithoutJSON } from "api/helpers/fetcher";
import { getStaticUrl } from "utils/getStaticUrl";
import BackButton from "components/ui/ButtonBack/ButtonBack";
import { formatServerDate, formatServerDateTime } from "utils/formatserverdate";
import { useReduxReducer } from "redux/store";
import PaymentThreed from "./components/PaymentThreed";
import CreditCardForm from "./components/CreditCardForm";
import {  Menu, MenuItem,Accordion, AccordionDetails, AccordionSummary, Button, Typography, CircularProgress, Tooltip, IconButton } from '@mui/material';
import UpdateActionWCHR from "./components/UpdateActionWCHR";
import WheelChairOptions from "./components/WheelChairOptions";
import { disableButton } from "utils/disableButton";



declare global {
  interface Window {
    refundToken: string;
  }
}

interface Hotel {
  cancellationPolicies: string;
  room: any;
  hotelName: string;
  rating: number;
  stars: number;
  hotel: any;
  rooms: any;
  totalAmount: number;
  nights: number;
  taxInfo:string;
  supplierBookingId?: string;
}

interface MealOption {
  ssrKey: string;
  name: string;
  currencyCode: string;
  description: string;
  price: number;
}

interface MealSelectionProps {
  onMealSelect: (meal: MealOption) => void;
}

 export interface pnrdata  {
  bookAirportCheckinAvailable: boolean;
  bookBaggageAvailable: JSX.Element;
  refundAvailable: boolean;
  airlineSystem: any;
  bookWheelChairAvailable: boolean;
  bookSeatAvailable: JSX.Element;
  reissueAvailable: JSX.Element;
  voidAvailable: JSX.Element;
  rescanAvailable: boolean;
  airline: string;
  id: number;
  filekey: string;
  flightNumber: string;
  departurePort: string;
  arrivalPort: string;
  arrivalTime: string;
  departureTime: string;
  airlineLogo: string;
  classOfService: string;
  arrivalDate: number;
  baggageAllowance: string;
  flightList: any[];
  optionDate: string;
  totalAmount: number;
  paxList: any[];
  bookMealAvailable: boolean;
  status: string;

};
type paxdata = {
  flightList: [];
  ancillaryServicesRequests: [];
  baseFare: number;
  birthdate: string;
  firstname: string;
  gender: string;
  id: number;
  ticketnumber: string;
  passportCountryOfIssue: string;
  passportNationality: string;
  passportNumber: string;
  paxtype: string;
  surname: string;
  ticketed: boolean;
  totalAmount: number;
};
type flightdata = {
  airline: string;
  id: number;
  flightNumber: string;
  departurePort: string;
  arrivalPort: string;
  arrivalTime: string;
  departureTime: string;
  airlineLogo: string;
  classOfService: string;
  arrivalDate: number;
  baggageAllowance: string;
};

const BookingDetail = () => {
  const { isApp } = useReduxReducer(state => state.general)
  const { audit,product }= useReduxReducer(state => state.config)
  const { isAdmin } = useReduxReducer(state => state.general)
  const { bookingKey } = useParams<{ bookingKey: string }>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [flightdata, setflightData] = useState<flightdata[]>([]);
  const [pnrdata, setPnrData] = useState<pnrdata[]>([]);
  const [paxdata, setPaxData] = useState<paxdata[]>([]);
  const [openCreateFlightCaseModal, setOpenCreateFlightCaseModal] =
    useState<boolean>(false);
  const [openHistoryFlightCaseModal, setOpenHistoryFlightCaseModal] =
    useState<boolean>(false);
  const [
    openHistoryFlightLastCreatedCase,
    setOpenHistoryFlightLastCreatedCase,
  ] = useState<boolean>(false);
  const [userdata, setuserData] = useState<any>();
  const { t } = useTranslation();
  const key: any = bookingKey;
  //const filekey = pnrdata[0].filekey;
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo[]>([]);
  const caseAddBtnRef = useRef<HTMLButtonElement>(null);
  const caseHistoryBtnRef = useRef<HTMLButtonElement>(null);
  const [userRole, setUserRole] = useState("");
  const [agencyEmail, setAgencyEmail] = useState("");
  const [newData, setNewData] = useState<any>();
  const [packageTour, setpackageTour] = useState<TourPackageBookingDetailResponse>();
  const [hotel, setHotel] = useState<Hotel>();
  const [carrentData, setcarrentData] = useState<any>();
  const [transferData, setTransferData] = useState<any>();
const [isAirlinecc, setisAirlinecc] = useState<boolean>(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetcher(getGetBookingUrl(bookingKey!), {


        });

        if (response) {

          setData(response);
          setflightData(response.flightList);
          setPnrData(response.pnrList);
          setPaxData(response.extendedPaxList);
          setuserData(response.user.agency);
          setAgencyEmail(response.email);
        } else {
          throw new Error('Something went wrong on the API server!');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (bookingKey !== undefined) getData();
  }, [bookingKey]);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await fetcher(getUserDataUrl());

        if (response) {
          setUserRole(response.userRole);
          setisAirlinecc(response.airlineCreditCardAllowed)
        } else {
          throw new Error('Something went wrong on the API server!');
        }
      } catch (error) {
        console.error(error);
      }
    };

    getUserInfo();
  }, []);

  useEffect(() => {
    const getDatanew = async () => {
      try {
        const response = await fetcher(bookingRetrieveUrl(bookingKey!, agencyEmail!), {
          method: 'POST',

        });

        if (response) {

          setNewData(response);
          setHotel(response.hotelReservation);
          setTransferData(response.transferReservation);
          setcarrentData(response.restRentalcarReservation);
          setpackageTour(response.restPackageTourReservation);
          if (response.paymentInfo !== null) {
            setPaymentInfo(response.paymentInfo.payments);
          }
        } else {
          throw new Error('Something went wrong on the API server!');
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (bookingKey !== undefined && agencyEmail !== undefined) {
      getDatanew();
    }
  }, [bookingKey, agencyEmail]);

  useEffect(() => {
    if (isApp)
      window.location.href = '/#/app/booking/detail/' + bookingKey
  }, [isApp])

  const data1: any = data;

  function formatNumber(num: number): string {
    if (Number.isInteger(num)) {
      return num.toLocaleString("en-US") + ",00";
    } else {
      return num.toLocaleString("en-US");
    }
  }
  const [isOpen, setIsOpen] = useState(false); //controls meal modal
  const [isSeatOpen, setIsSeatOpen] = useState(false); //controls seat modal
  const [isBaggageOpen, setBaggageOpen] = useState(false); // controls baggage modal
  const [isWheelchairOpen, setIsWheelchairopen] = useState(false); // controls wheelchair modal
  const [isChekinOpen, setIsCheckinOpen] = useState(false); // controls airport checkin ssr modal
  const [internalRemarks, setInternalRemarks] = useState("");
  const [modal, setModal] = useState(false);
  const [showAtrOnlyArea, setShowAtrOnlyArea] = useState(false);
  const optionsGender = [
    { value: "Male", label: "Bay" },
    { value: "Female", label: "Bayan" },
  ];
  const optionsPaxType = [
    { value: "ADT", label: "ADT" },
    { value: "CHD", label: "CHD" },
    { value: "INF", label: "INF" },
    { value: "DIS", label: "DIS" },
  ];

  const handleHeaderClick = () => {
    setShowAtrOnlyArea((prev) => !prev);
  };
  const copyButtonRef = React.useRef<HTMLButtonElement>(null);

  function copyButtonClickHandler(str: string) {
    // event.preventDefault();
    navigator.clipboard.writeText(str);
  }

  //const date = moment(flightdata.arrivalDate);
  const baseUrl = getStaticUrl('b2b');

  const [selectedOptionPax, setSelectedOptionPax] = useState<string>(
    paxdata.map((pax) => pax.paxtype)[0]
  );
  const [selectedOptionGender, setSelectedOptionGender] = useState<string>(
    paxdata.map((pax) => pax.gender)[0]
  );
  const handleSelectChangeGender = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedOptionGender(event.target.value);
  };
  const handleSelectChangePax = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedOptionPax(event.target.value);
  };
  const [modalpaxIsopen, setModalpaxIsopen] = useState(false);
  const [modalFlightIsopen, setModalFlightIsopen] = useState(false);

  const HandleModalPax = () => {
    setModalpaxIsopen(!modalpaxIsopen);
  };
  //Meal selection

  const sectionCarrent = () => {
    if (!carrentData || carrentData.length === 0) return null;
    const HandleMarkAsRefunded = () => {

      const confirmation = window.confirm(t("flightbooking.options.are-you-sure"));

      if (confirmation) {
          let forms = document.forms;
          if (forms.length === 0) {
              let form = document.createElement("form");
              document.getElementsByTagName("body")[0].appendChild(form);
              forms = document.forms;
          }
          if (forms.length > 0) {
              const form = forms[0];
              form.action = baseUrl + `/intern/markRcrAsRefunded`;
              form.submit();
          } else {
              console.error("No forms found on the page.");
          }
      } else {
          console.log("Mark as refunded operation cancelled.");
      }
  };

    const HandleDeleteRent = () => {
      const forms = document.forms;
      if (forms.length > 0) {
        const form = forms[0];
        form.action = baseUrl + `/agency/deleteRentalCarReservation`;
        form.submit();
      } else {
        console.error("No forms found on the page.");
      }
    };

    return (
      <div className="my-4">
        <section className="shadow-sm">
          <div className="bg-white  py-3">
            <h3 className="border-bottom pb-4 font-bold">Rent A Car</h3>
          </div>
          <div className="justify-between bg-white p-6 xl:flex">
            <div className="sm:w-full xl:w-1/3">
              <p>{data1.rentalCarReservation.providerName}</p>
              <img
                className=" w-sm h-sm p-8 "
                src={data1.rentalCarReservation.providerLogoUrl}
              />
            </div>
            <div className="sm:w-full xl:w-1/3">
              <img
                className=" w-sm h-sm object-contain p-8 "
                src={data1.rentalCarReservation.rentalCarImageUrl}
              />
            </div>
            <div className="px-4 pt-4 sm:w-full xl:w-1/3">
              {data1.rentalCarReservation.rentalCarBrand +
                " " +
                data1.rentalCarReservation.rentalCarType}
              <p>or</p>
              <p>{data1.rentalCarReservation.rentalCarGroup}</p>
            </div>
          </div>
          <div
            className={`flex h-4 items-center justify-around rounded p-3 
                            ${
                              data1.status === "CONFIRMED" ? "bg-green-400" : ""
                            }
                            ${
                              data1.status === "RESERVATION"
                                ? "bg-blue-400"
                                : ""
                            }
                            ${data1.status === "CANCELED" ? "bg-red-400" : ""}
                            ${data1.status === "VOIDED" ? "bg-red-400" : ""}
                            ${data1.status === "REFUNDED" ? "bg-gray-400" : ""}
                            ${data1.status === "EXPIRED" ? "bg-red-400" : ""}`}
          >
            {data1.status === "RESERVATION" ? (
              <>
                {t("flightbooking.ticketing_deadline")}:{" "}
                {formatServerDate(data1.rentalCarReservation.optionDate)}
              </>
            ) : null}
            {data1.status === "CONFIRMED" ? (
              <>{t("flightbooking.ticketed")}</>
            ) : null}
            {data1.status === "CANCELED" ? (
              <>{t("flightbooking.canceled")}</>
            ) : null}
            {data1.status === "VOIDED" ? <>Voided Kayit</> : null}
            {data1.status === "REFUNDED" ? (
              <>{t("flightbooking.refunded")}</>
            ) : null}
            {data1.status === "EXPIRED" ? (
              <>{t("flightbooking.expired")}</>
            ) : null}
            <div className="flex h-4 items-center justify-center rounded p-3">
              {t("rentalcar.deposit")}: {currencyFormat(carrentData.deposit)}
            </div>
            <div className="flex h-4 items-center justify-center rounded p-3">
              {t("rentalcar.total")}: {currencyFormat(carrentData.totalAmount)}
            </div>
          </div>
          <div className="mb-4 grid grid-cols-1 gap-4 bg-white p-4 md:grid-cols-5 md:p-6 lg:p-8">
            <div>
              <p>
                <b>{t("rentalcar.pickup-location")}</b>
              </p>
              <p>{carrentData.pickupLocation}</p>
            </div>
            <div className="">
              <p>
                <b>{t("rentalcar.pickup-date")}</b>
              </p>
              {formatServerDate(carrentData.pickupDate)}
            </div>

            <div className="">
              <p>
                <b>{t("rentalcar.days")}</b>
              </p>
              {carrentData.totalDays}
            </div>

            <div className="">
              <p>
                <b>{t("rentalcar.return-date")}</b>
              </p>
              {formatServerDate(carrentData.returnDate)}
            </div>

            <div>
              <p>
                <b>{t("rentalcar.return-location")}</b>
              </p>
              <p>{carrentData.returnLocation}</p>
            </div>
          </div>
        </section>

        <div className={"my-4"}>
          <section className="shadow-sm">
            <div className="border-bottom-0 rounded border bg-white py-4">
              <h3 className="border-bottom pb-4 font-bold">
                {t("rentalcar.driver")}
              </h3>
            </div>
            <div className="border-top-0 mb-4 rounded border bg-white p-4 md:p-6 lg:p-8">
              {data1.extendedPaxList.map((row: any) => (
                <div className={"grid grid-cols-1 gap-4 md:grid-cols-5"}>
                  <div>
                    <p>
                      <b>{t("rentalcar.firstname")}</b>
                    </p>
                    <p>{row.firstname}</p>
                  </div>
                  <div className="">
                    <p>
                      <b>{t("rentalcar.surname")}</b>
                    </p>
                    {row.surname}
                  </div>
                  <div className="">
                    <p>
                      <b>{t("rentalcar.phone")}</b>
                    </p>
                    {data1.phone}
                  </div>
                  <div className="">
                    <p>
                      <b>{t("rentalcar.birthdate")}</b>
                    </p>
                    {formatServerDate(row.birthdate)}
                  </div>
                  <div>
                    <p>
                      <b>{t("rentalcar.email")}</b>
                    </p>
                    <p>{data1.email}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="shadow-sm">
            <div className="flex justify-center rounded border bg-white">
              <div className="inline-flex justify-center p-2 " role="group">
                {data1.status === "CONFIRMED" &&
                (userRole === "ADMIN" || userRole === "SYSTEM_EMPLOYEE") ? (
                  <div>
                    <form>
                    <input
                    type="hidden"
                    name="bookingKey"
                    value={data1.bookingKey}
                  ></input>
                    </form>
                    <button
                    id="markAsRefunded"
                    type="button"
                    className="text-sm flex  justify-center rounded  border border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                    onClick={() =>
                      {
                        const button = document.getElementById("markAsRefunded") as HTMLButtonElement | null;
                      if (button) {
                        disableButton([button]);
                     }
                      HandleMarkAsRefunded()}}
                  >
                {t("flightcases.refund-request")}
                  </button>
                  </div>

                ) : null}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };


  const sectionHotel = () => {
    if (
      !data1.hotelReservation ||
      data1.hotelReservation.length === 0 ||
      !hotel
    )
      return null;
const hotelOfferTax = hotel.taxInfo && JSON.parse(hotel.taxInfo) 
const cancelHotel = async (formElement: HTMLFormElement, bookingKey: string): Promise<void> => {
  try {
    setLoading(true);

    // Perform like ajax form request with fetch API
    const response = await fetch(postCancellationFeeHotel(), {
      method: 'POST',
      headers: {
        'Accept': 'text/plain, */*; q=0.01',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: new URLSearchParams({ bookingKey }).toString(),
    });

    const responseText = await response.text();

    const data = JSON.parse(responseText.replace(/\n/g, ''));
    let msg = "";

    if (data.hasOwnProperty('fee')) {
      const cancellationFee = data.fee.amount;
      msg = `
        Rezervasyon iptal edilecektir.<br>
        Bu iptalden dolayı oluşacak olan masraf: <b style='color:red'>${cancellationFee} EUR</b><br><br>
        <span style='color:red; font-weight:bold;'>İşlemi gerçekleştirmek istediğinize emin misiniz?</span>
      `;
    } else {
      msg = data.freeText;
    }

    const confirmation = await Swal.fire({
      title: t("generals.are-you-sure-you-want-to-proceed"),
      html: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t("generals.confirm"),
      cancelButtonText: t("b2c-app.generals.cancel"),
      reverseButtons: true,
    });

    if (confirmation.isConfirmed) {
      const formData = new FormData(formElement);

      const submitResponse = await fetcher("/agency/hotels/cancelReservation", {
        method: 'POST',
        body: formData,
      });

      if (submitResponse.ok) {
        await Swal.fire({
          title: t("generals.success"),
          text: t("b2c-app.generals.submission_success"),
          icon: 'success',
          confirmButtonText: t("b2c-app.flights.inactivity-window-button"),
          showCancelButton: true,
          cancelButtonText: t("generals.close")
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(); 
          }
        });
      } else {
        await Swal.fire({
          title: t("generals.error"),
          text: t("b2c-app.generals.submission_failed"),
          icon: 'error',
          confirmButtonText: t("generals.ok")
        });
      }
    } else {
      setLoading(false);
    }
  } catch (error) {
    console.error("An error occurred:", error);
    setLoading(false);
  }
};

    return (
      <div className="my-4">
        <section className="my-4 rounded border bg-white shadow-sm">
          <div className="mb-1 py-3">
            <h3 className="text-lg font-bold"> Hotel</h3>
          
          </div>
          
          <div className="mb-4 grid grid-cols-1 grid-rows-1 gap-4">
            <div className=" flex-wrap">
              <h3 className="mb-2 flex items-center  justify-center font-bold">
                {hotel?.hotelName}
              </h3>

              <div className="mb-2 flex items-center justify-center">
                <StartRating point={hotel.hotel?.stars} />
              </div>
              <div className="flex items-center justify-center ">
                <img className="h-80" src={hotel.hotel.photos[0]} />
              </div>
            </div>
          </div>
          <div
            className={`flex h-4 items-center justify-center rounded p-3
                            ${
                              data1.status === "CONFIRMED" ? "bg-green-400" : ""
                            }
                            ${
                              data1.status === "RESERVATION"
                                ? "bg-blue-400"
                                : ""
                            }
                            ${data1.status === "CANCELED" ? "bg-red-400" : ""}
                            ${data1.status === "VOIDED" ? "bg-red-400" : ""}
                            ${data1.status === "REFUNDED" ? "bg-gray-400" : ""}
                            ${data1.status === "EXPIRED" ? "bg-red-400" : ""}

`}
          >
            {data1.status === "RESERVATION" ? (
              <>{t("flightbooking.ticketing_deadline")}</>
            ) : null}
            {data1.status === "CONFIRMED" ? (
              <>{t("flightbooking.ticketed")}</>
            ) : null}
            {data1.status === "CANCELED" ? (
              <>{t("flightbooking.canceled")}</>
            ) : null}
            {data1.status === "VOIDED" ? <>Voided Kayit</> : null}
            {data1.status === "REFUNDED" ? (
              <>{t("flightbooking.refunded")}</>
            ) : null}
            {data1.status === "EXPIRED" ? (
              <>{t("flightbooking.expired")}</>
            ) : null}
          </div>
          { hotel.supplierBookingId && <div className="flex justfiy-center items-center text-lg px-4 text-primary-6000 font-semibold">ReservationID: {hotel.supplierBookingId}</div>}

        </section>

        <section className="my-4 rounded  bg-white ">
          {hotel.rooms?.map((room: any, i: number) => (
            <div className="p-6">
              <div
                key={i}
                className="text-md mb-2 flex justify-center rounded border-2 p-2"
              >
                {" "}
                {t("hotel.room")} {(i + 1)}
              </div>
              <div className=" grid grid-cols-5 grid-rows-1 gap-4">
                <div>
                  <p>
                    <b>{t("react.hotel.check-in")}</b>
                  </p>
                  <p>
                    {formatServerDate(
                      data1.hotelReservation.checkinDate
                    )}
                  </p>
                </div>
                <div className="text-md">
                  <p>
                    <b>{t("react.hotel.room")}</b>
                  </p>
                  {room.roomType}
                </div>

                <div className="">
                  <p>
                    <b>{t("react.hotel.board")}</b>
                  </p>
                  {room.board}
                </div>

                <div className="">
                  <p>
                    <b>{t("hotel.room")}</b>
                  </p>
                  {currencyFormat(hotel.totalAmount / hotel.nights)}
                </div>

                <div>
                  <p>
                    <b>{t("react.hotel.check-out")}</b>
                  </p>
                  <p>
                    {formatServerDate(
                      data1.hotelReservation.checkoutDate
                    )}
                  </p>
                </div>
              </div>
             
            
            </div>
          ))}
        </section>

        <section className="my-4 rounded  bg-white ">
          <div className="p-6">
            <table className="table-bordered table-hover w-full table-auto">
              <thead>
                <tr>
                  <th className="px-4 py-2">{t("react.hotel.firstname")}</th>
                  <th className="px-4 py-2">{t("react.hotel.lastname")}</th>
                  <th className="px-4 py-2">{t("charter.passenger-type")}</th>
                  <th className="px-4 py-2">{t("generals.gender")}</th>
                </tr>
              </thead>

              <tbody>
                {paxdata.map((row) => (
                  <tr key={row.id}>
                    <td className=" px-4 py-2">{row.firstname}</td>
                    <td className=" px-4 py-2">{row.surname} </td>
                    <td className=" px-4 py-2">{row.paxtype}</td>
                    <td className=" px-4 py-2">{row.gender}</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </section>
        <div className="flex flex-col space-y-4 px-4  ">
        <h3 className="text-xl text-left font-semibold">
          {t("react.hotel.price-overview")}
        </h3>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>{t("react.general.price")}</span>
          <span>{ currencyFormat(hotel.totalAmount) }</span>
        </div>
      
         {hotelOfferTax && 
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Taxes and Fees
          <Tooltip title="This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.">
      <IconButton  size="small">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
             </span>
             
          <span>{currencyFormat(hotelOfferTax.taxesAndFees)}</span>
          
          </div>}
     
        
        
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between text-xl font-semibold">
          <span>{t("react.generel.totalprice")}</span>
      {   <span>{currencyFormat( hotel.totalAmount + (hotelOfferTax && hotelOfferTax.feePaymentInProperty ? hotelOfferTax.feePaymentInProperty : 0) )} </span>}
        </div>
      </div>
   {hotelOfferTax && <div className="mt-0 px-4 text-left pt-3 pb-0 ">
    
    <h2 className="text-[16px] font-semibold mt-2">
       {t("react.hotel.check-in")} 
     </h2>
    <span
    className="text-sm"
      dangerouslySetInnerHTML={{ __html:hotel.hotel!.checkinTime  }}
    />
     <h2 className="text-[16px] font-semibold mt-2">
       {t("react.hotel.check-out")}
     </h2>
     <span
     className="text-sm"
      dangerouslySetInnerHTML={{ __html: hotel.hotel!.checkoutTime  }}
    />
    <h2 className="text-[16px] font-semibold mt-2">
       Fees & Policies
     </h2>
     <span
     className="text-sm"
      dangerouslySetInnerHTML={{ __html: hotel.hotel!.fees  }}
    />
     <span
     className="text-sm"
      dangerouslySetInnerHTML={{ __html: hotel.hotel!.policies  }}
    />
   </div>}
   {newData.status === "CONFIRMED" && <div>
              <form id={`form_${newData.bookingKey}`}>
      <input
        type="hidden"
        name="bookingKey"
        value={newData.bookingKey}
      ></input>
  
    </form>
                <button
                id="cancelHotel"
                  type="button"
                  onClick={() =>
                   { const button = document.getElementById("cancelHotel") as HTMLButtonElement | null;
                    if (button) {
                      disableButton([button]);
                   }
                    cancelHotel(document.getElementById(`form_${newData.bookingKey}`) as HTMLFormElement,newData.bookingKey)}}
                  className="text-sm rounded border bg-red-500 px-4 py-2 font-medium   hover:border-red-600 text-white "
                >
                  <i className="fa fa-close" aria-hidden="true"></i>{" "}
                  {t("booking.cancel")}
                </button>
              </div>}
        <section className="my-4 rounded border bg-white shadow-sm">
          <div className="p-6">
            <p>Passenger Contact: 004969273166800, HOTEL@ATRTOURISTIK.COM</p>
          </div>
        </section>
      </div>
    );
  };

  const sectionAgency = () => {
    const getListItemHtml = (item: any) => {
      return `
        <li class="">
          <div class="ml-1 gap-2 grid grid-cols-5 text-sm">
          <span >${item.date}</span>
          <span >${item.username} </span>
          <span >${item.ip} </span>
          <span >${item.logType} </span>
          <span>${item.details} </span>
          </div>
        </li>
      `;
    };
    const showList = async () => {
      const payload = {bookingKey: bookingKey}
      const response = await postParamRequest(getBookingLogs(), payload);
      if(response){
        const list: any[] = response;

      const listHtml = `  
        <div class=" font-semibold ml-1 grid grid-cols-5 ">
                <span>Tarih</span>
                <span>Kullanici</span>
                <span>IP</span>
                <span>Islem</span>
                <span>Detay</span>
              </div>  

        <ul class="list divide-y divide-gray-200 overflow-y-scroll">
          ${list.map(getListItemHtml).join("")}
        </ul>
      `;

      await Swal.fire({
        title: "History",
        html: listHtml,
        width: 1200,
        showCloseButton: true,
      });
      }

    };


   const saveInternalRemarks = async (
      bookingKey: string,
      internalRemarks: string
    ) => {

      const payload = {
        bookingKey: bookingKey,
        remarks: internalRemarks,
      }
      try {
        const response = await postFetcher(
          postsaveInternalRemarks(),
          payload
        );
        Swal.fire(t("generals.success"), "", "success");
      } catch (error) {
        Swal.fire(t("app.general-error"), "", "error");
      }
    };
    return (
      <div>
      {(userRole === "ADMIN" || userRole === "SYSTEM_EMPLOYEE") && <div className="border-gray mt-2 mb-4 w-full rounded bg-white p-2 shadow-sm">
          <h4
            className="cursor-pointer font-semibold"
            onClick={handleHeaderClick}
          >
            <span className="flex justify-start">
              ATR Only
              {showAtrOnlyArea ? (
                <ChevronUpIcon className="ml-auto h-6 w-6 " />
              ) : (
                <ChevronDownIcon className="ml-auto h-6 w-6" />
              )}
            </span>
          </h4>
          <Transition
            show={showAtrOnlyArea}
            enter="transition ease-out duration-500"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-500"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="bg-gray-200">
              <div className="w-full p-6">
                <div className="flex h-40 min-w-full">
                  <textarea
                    id="internalRemarks"
                    className="form-control flex-grow p-2"
                    placeholder="Gizli Notlar"
                    onChange={(e) => setInternalRemarks(e.target.value)}
                  />
                  <div className=" flex justify-end">
                    <button
                      id="saveInternalRemarksBtn"
                      className="  border-1 ml-3 h-10 rounded border-black bg-gray-200 px-4 py-2 text-black hover:bg-gray-400"
                      type="button"
                      onClick={() =>
                        saveInternalRemarks(data1.bookingKey, internalRemarks)
                      }
                    >
                      Kaydet
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex  justify-start py-6">
                <button
                  className=" border-1 ml-3 rounded border-black bg-gray-200 px-4 py-2 text-black hover:bg-gray-400"
                  id="getBookingLogsBtn"
                  onClick={showList}
                >
                  History Sorgulama
                </button>
              </div>
            </div>
          </Transition>
        </div>}

        <div className="mb-4 items-center grid grid-rows-2 rounded bg-white  mx-4 ">
          <div className="flex justify-between">
            <h2 className="text-lg  font-bold">
              {/*{t("bookings.agency")} :{data1?.user.agency?.name}*/}
            </h2>
            <button
            title="Copy"
            className=" rounded border bg-gray-100 px-3 shadow-sm h-8"
            onClick={() => copyButtonClickHandler(data1.bookingKey)}
          >
            <i className="fa fa-copy" aria-hidden="true"></i>
            <span className="text-base ml-2 font-semibold">
              {data1.bookingKey}
            </span>
          </button>
          </div>

          <div className="text-left grid grid-rows-2 items-end justify-end text-md mt-2">
          <p className="text-gray-700">
              <b className="pr-2">{t("manage-agencies.email")}: </b>
              {data1.email}
            </p>
            <p className="pr-2 text-gray-700">
              <b>{t("react.generel.phone")}:</b> {data1.phone}
            </p>
            <p className="pr-2 text-gray-700">
            <b>{t("manage-agencies.agency-number")}:</b> {data1.agencyNumber}
             </p>

          </div>

        </div>
      </div>
    );
  };
  const sectionPassanger = () => {
    function showTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-0", "invisible");
        tooltip.classList.add("opacity-100");
      }
    }

    function hideTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-100");
        tooltip.classList.add("opacity-0", "invisible");
      }
    }

    return (
      <div className="m-4 rounded bg-white shadow-sm border ">
        <div className="mb-1 gap-2 px-6 py-2 flex ">
          <h3 className="text-lg font-bold ">
            <i className="fa fa-users" aria-hidden="true"></i>{" "}
            {t("flightbooking.passengers")}
          </h3>
        </div>
        <div className="table-responsive  mb-1  px-4">
          <table className="table-borderless table  w-full">
            <thead>
              <tr className={"text-base border-t border-b font-semibold"}>
                <th className="px-2 py-2">{t("flights.firstname")}</th>
                <th className="px-2 py-2">{t("flights.surname")}</th>
                <th className="px-2 py-2">{t("flightbooking.type")}</th>
                <th className="px-2 py-2">
                  {t("app.flight-passengers-form.birthdate")}
                </th>
                {data1.status === "TICKETED" ? (
                  <th className="px-2 py-2">
                    {t("flightbooking.ticket-number")}
                  </th>
                ) : null}
                <th className="px-2 py-2">{t("flightbooking.fare")}</th>
                <th className="px-2 py-2">{t("flightbooking.tax")}</th>
                <th className="px-2 py-2">{t("app.booking.total-price")}</th>
              </tr>
            </thead>
            {paxdata.map((row) => (
              <tbody key={row.id}>
                <tr className="text-base border-t border-b hover:bg-gray-100">
                  <td className=" px-2 py-2">{row.firstname}</td>
                  <td className=" px-2 py-2">{row.surname} </td>
                  <td className=" px-2 py-2">{row.paxtype}</td>
                  <td className=" px-2 py-2 " colSpan={1}>
                    {formatServerDate(row.birthdate)}
                  </td>
                  {data1.status === "TICKETED" ? (
                    <td
                      className=" px-2 py-2"
                      dangerouslySetInnerHTML={{ __html: row.ticketnumber }}
                    ></td>
                  ) : null}
                  <td className=" px-2 py-2">{formatNumber(row.baseFare)} €</td>
                  <td className="  py-2">
                    {formatNumber(row.totalAmount - row.baseFare)} €
                  </td>
                  <td className="  py-2"colSpan={2}>
                    {formatNumber(row.totalAmount + data1.serviceFeePerPax)} €
                  </td>
                </tr>
              </tbody>
            ))}
            <tr className="border-0 hover:bg-gray-100 table-sm">
              <td className="border-0 px-4 " colSpan={4}></td>
              {data1.status === "TICKETED" ? (
                <td className="border-0 px-4  "></td>
              ) : null}
              <td className=" border-0 px-4 ">
                <div className="min-w-full  gap-2 px-4 sm:flex sm:justify-center">
                  <div
                    className="inline-flex rounded-md shadow-sm"
                    role="group"
                  >
                    <div
                      id={"price"}
                      className="tooltip invisible -translate-x-1/2 transform rounded bg-blue-300  p-1 text-xs text-gray-900 opacity-0 transition duration-700"
                    >
                      <span>
                        {currencyFormat(data1.totalPrice)}+{" "}
                        {currencyFormat(
                          data1.serviceFeePerPax * paxdata.length
                        )}{" "}
                        {"(Service Charge)"}={" "}
                        {currencyFormat(
                          data1.totalPrice +
                            data1.serviceFeePerPax * paxdata.length
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
              <td className=" border-0 px-4  "></td>

              <td className="h-4 w-4 border-0  " colSpan={2}>
                {currencyFormat(
                  data1.totalPrice + data1.serviceFeePerPax * paxdata.length
                )}
             {audit.marketingType === "B2B" &&   <button
                  onMouseOver={(e) => showTooltip("price")}
                  onMouseOut={(e) => hideTooltip("price")}
                >
                  <div className=" h-2 w-2 items-center mr-2 justify-center">
                    <InfoIcon fontSize="small" />
                  </div>
                </button>}
              </td>
            </tr>
          </table>
        </div>
     {audit.marketingType === "B2B"  &&  <div className="pb-2">
          <button
            ref={caseAddBtnRef}
            type="button"
            className="text-sm inline-flex w-full justify-center border rounded bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm hover:shadow-lg hover:text-black sm:ml-3 sm:w-auto"
            onClick={() => setOpenCreateFlightCaseModal(true)}
          >
            {t("flightcases.new-case")}
          </button>
          <button
            ref={caseHistoryBtnRef}
            type="button"
            className="text-sm inline-flex w-full justify-center border rounded bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm hover:shadow-lg hover:text-black sm:ml-3 sm:w-auto"
            onClick={() => setOpenHistoryFlightCaseModal(true)}
          >
            Case History
          </button>

          {bookingKey !== undefined && (
            <div>
              <CreateFlightCaseModal
                bookingKey={bookingKey}
                open={openCreateFlightCaseModal}
                closeModal={() => setOpenCreateFlightCaseModal(false)}
                addCaseSuccess={() => {
                  setOpenCreateFlightCaseModal(false);

                  if (caseHistoryBtnRef.current) {
                    caseHistoryBtnRef.current.focus();
                    setOpenHistoryFlightCaseModal(true);
                    setOpenHistoryFlightLastCreatedCase(true);
                  }
                }}
              />

              <HistoryFlightCaseModal
                bookingKey={bookingKey}
                open={openHistoryFlightCaseModal}
                openLastCreatedCase={openHistoryFlightLastCreatedCase}
                historyFlightCaseHandler={(value: boolean) => {
                  setOpenHistoryFlightCaseModal(value);
                } }
                closeOpenLastCreatedCase={() => setOpenHistoryFlightLastCreatedCase(false)} notifications={null} setNotifications={function (value: React.SetStateAction<INotificationModalScroll[] | null>): void {
                  throw new Error("Function not implemented.");
                } }              />
            </div>
          )}
        </div>}
      </div>
    );
  };

  function ModalFlight() {
    if (modalFlightIsopen === false) return null;
    return (
      <div>
        <div className="fixed left-0 right-0 z-50 -mt-64  ">
          <div className="border-4 shadow-sm">
            <div className=" flex w-full justify-start rounded bg-white p-1  ">
              <ButtonClose
                className=" bg-blueGray-100"
                onClick={HandleModalPax}
              ></ButtonClose>
            </div>
            <div className=" flex h-3/4 w-full items-center justify-center rounded bg-white ">
              <div className="flex items-center justify-between bg-white px-4 py-1 ">
                <div className="table-responsive p-4 ">
                  <table className="table-bordered table w-full">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Uçus No</th>
                        <th className="px-4 py-2">Kalkis</th>
                        <th className="px-4 py-2">Varis</th>
                        <th className="px-4 py-2">Stopover</th>
                        <th className="px-4 py-2">Sinif</th>
                        <th className="px-4 py-2">Kalkis Tarih / Saat</th>
                        <th className="px-4 py-2">Bagaj</th>
                        <th className="px-4 py-2">Koltuk</th>
                        <th className="px-4 py-2">Havayolu</th>
                        <th className="px-4 py-2">Açik Tarih</th>
                        <th className="px-4 py-2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {paxdata.map((pax) => (
                        <tr
                          key={pax.id}
                          className="cursor-pointer  hover:bg-gray-100"
                        >
                          <td className="px-1 py-2 ">
                            <Select
                              value={selectedOptionPax}
                              onChange={handleSelectChangePax}
                            >
                              {optionsPaxType.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </td>

                          <td className=" px-1 py-2 ">
                            <Select
                              value={selectedOptionGender}
                              onChange={handleSelectChangeGender}
                            >
                              {optionsGender.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </td>
                          <td className=" px-1 py-2">
                            <Input
                              value={formatServerDate(pax.birthdate)}
                            ></Input>
                          </td>
                          <td className=" px-1 py-2">
                            <Input
                              className="px-0"
                              onChange={HandleModalPax}
                              value={pax.firstname}
                            ></Input>
                          </td>
                          <td className=" px-1 py-2">
                            <Input value={pax.surname}></Input>
                          </td>
                          <td className=" px-1 py-2">
                            <Input defaultValue={pax.ticketnumber}></Input>
                          </td>
                          <td className=" px-1 py-2">
                            <Input
                              defaultValue={currencyFormat(pax.baseFare)}
                            ></Input>
                          </td>
                          <td className=" px-1 py-2">
                            <Input
                              defaultValue={currencyFormat(
                                pax.totalAmount - pax.baseFare
                              )}
                            ></Input>
                          </td>
                          <td className=" px-1 py-2">
                            <Input value={pax.surname}></Input>
                          </td>
                          <td className=" px-1 py-2">
                            <Input value={pax.surname}></Input>
                          </td>
                          <td className=" px-1 py-2"></td>
                          <td className=" px-1 py-0 ">
                            <ButtonDelete>Sil</ButtonDelete>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex w-full justify-end gap-2 rounded bg-white p-6 ">
              <ButtonPrimary onClick={HandleModalPax}>Iptal</ButtonPrimary>
              <ButtonPrimary>Onayla</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const reservationOptionsButtons = (row : pnrdata) => {
    const filekeyreservation = row.filekey;

    async function applyPgsDiscount(
      bookingKey: any,
      filekey: any
    ): Promise<void> {
      try {
        const response = await fetcherWithoutJSON(
          baseUrl +
            `/agency/getPgsCampaigns?bookingKey=${bookingKey}&filekey=${filekeyreservation}`
        );
        const res = await response.text();
        Swal.fire(
          res,
          "Dikkat: Yukarıda indirimli fiyat görüntüleniyorsa biletin fiyatına otomatik olarak arka planda indirim uygulanmış olup, 10 dakika içerisinde sonraki aşama olan “Biletme” gerçekleştirildikten sonra indirimli fiyatı ekranda yeniden görebilirsiniz.",
          "warning"
        );
      } catch (error) {
        console.error(error);
        Swal.fire(
          "Error",
          "An error occurred while applying the PGS discount.",
          "error"
        );
      }
    }

    async function cancelPnr(formElement: HTMLFormElement,fileKey: string) {
      try {
        setLoading(true);
        const confirmation = window.confirm(
          t("generals.are-you-sure-you-want-to-proceed")
        );
        if (confirmation) {
          if (formElement.length > 0) {

            formElement.action = baseUrl + `/agency/cancelPnr?filekey=${fileKey}`;
            formElement.addEventListener('submit', () => {
              setLoading(false);
            });
            formElement.submit();
          } else {
            setLoading(false);
            console.error("No forms found on the page.");
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setLoading(false);
      }
    }

    const handleVoid = async (bookingKey: string, fileKey: string): Promise<void> => {
      try {
        const confirmation = window.confirm(t("flightbooking.are-you-sure-you-want-to-void"));
        if (confirmation) {
          setLoading(true);
          const url = `/agency/pnr/void?bookingKey=${bookingKey}&filekey=${fileKey}`;
    
          const response = await fetcher(url);
          if (response && response.success === true) {
            Swal.fire({
              title: t("generals.success"),
              text: t("generals.reservationstate.voided"),
              icon: "success",
             
              confirmButtonText: t("generals.reload"),
              reverseButtons: true
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          } else {
            throw new Error(response?.message || "Failed to void PNR");
          }
        } else {
          console.log("Void operation canceled by the user");
        }
      } catch (error) {
        console.error(error);
        Swal.fire(t("app.general-error"),  "An error occurred while voiding PNR.", "error");
      } finally {
        setLoading(false);
      }
    };
    
    
    
    async function rescanPnr(
      bookingKey: string,
      filekey: string
    ){
      const payload = {
        bookingKey: bookingKey,
        filekey: filekey
      }
      try {
        setLoading(true);
        const response = await postFetcher(
          postRescanPnr(),
          payload,

        );
        if(response){
          setLoading(false);
        }
        const old: number = parseFloat(response.oldPrice);
        const newPrice: number = parseFloat(response.newPrice);
        const diff: number = newPrice - old;
        const newDiff = Math.abs(diff).toFixed(2);
        const depFlightRecommendation = response.depFlightRecommendation;
        const retFlightRecommendation = response.retFlightRecommendation;
        let diffColor: string = "green";
        let diffSign: string = "-";
        if (diff > 0) {
          diffColor = "red";
          diffSign = "+";
        }
        Swal.fire({
          title: t("flightbooking.options.query-current-price"),
          html:
            '<span style="font-weight:500">' +
            t("generals.old-price") +
            parseFloat(response.oldPrice).toFixed(2) +
            " EUR</b> " +
            "	<br>" +
            '	<b style="color:' +
            diffColor +
            '">' +
            t("generals.difference")  +
            " " +
            diffSign +
            newDiff +
            " EUR </b>" +
            "	<br>" +
            t("generals.new-price") +
            parseFloat(response.newPrice).toFixed(2) +
            " EUR</b> " +
            "	<br>" +
            "	<br>" +
            "<b style='color:red'>"+
            t("generals.old-booking-will-be-canceled-before-creating-new-booking")+
            "</b>" +
            "</span>",
          showCancelButton: true,

          confirmButtonText:
            '<i class="fa fa-plus" aria-hidden="true"></i>' +
            t("flights.reserve"),
          cancelButtonText:
            '<i class="fa fa-close" aria-hidden="true"></i>' +
            t("generals.abort"),
        }).then(async (result) => {
          setLoading(false)
          if (result.isConfirmed) {
            const payload1 = {
              bookingKey: bookingKey,
              filekey: filekey,
              depFlightRecommendation: depFlightRecommendation,
              retFlightRecommendation: retFlightRecommendation,
            };
            try {
              setLoading(true)

              const response1 = await postFetcher(
                postRescanPnrtakeReservation(),
                payload1
              );
              if (!response1.hasOwnProperty("status") || response1.status == "error") {
                Swal.fire(t("app.general-error"), response1.reason, "error");
                setLoading(false)
                return;
              }
              const newBookingKey = response1.newBookingKey;
              setLoading(false)

              if (newBookingKey)
                Swal.fire({
                  title: t("generals.success"),
                  showConfirmButton: false,
                  html:
                    '<span style="font-weight:500">' +
                    "" +
                    "	<br>" +
                    "	<br>" +
                    '	JUPITER Key: <a href="https://www.atrtouristik.com/ibe#/booking/detail/' +
                    newBookingKey +
                    '" target="_blank" class="btn btn-success ml-2"><i class="mdi mdi-open-in-new"></i> ' +
                    newBookingKey +
                    "</a><br>" +
                    "</span>",
                });
              else{
                setLoading(false)
                Swal.fire(t("app.general-error"), response1.reason, "error");
              }

            } catch {
              if (!response.hasOwnProperty("status") || response.status == "error") {
                setLoading(false)
                Swal.fire(t("app.general-error"), response.reason, "error");
                return;
              }
            }
          }
        });
      } catch (error) {}
    }

    return (
      <div className="text-center flex">
        {
            row.status === "RESERVATION" && (
              <div>
              <form id={`form_${row.filekey}`}>
      <input
        type="hidden"
        name="bookingKey"
        value={data1.bookingKey}
      ></input>
      <input
        type="hidden"
        name="filekey"
        value={row.filekey}
      ></input>
    </form>
                <button
                id="cancelPnr"
                  type="button"
                  onClick={() =>
                   { const button = document.getElementById("cancelPnr") as HTMLButtonElement | null;
                    if (button) {
                      disableButton([button]);
                   }
                    cancelPnr(document.getElementById(`form_${row.filekey}`) as HTMLFormElement,row.filekey)}}
                  className="text-sm rounded border bg-white px-4 py-2 font-medium  text-red-600 hover:border-red-600 hover:text-red-300 "
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                  {t("app.booking.cancel-pnr")}
                </button>
              </div>
            )
        }
        {row.rescanAvailable && 

              row.status === "RESERVATION" && (
                <div className="text-center">
                  <button
                  id="rescanPnr"
                    type="button"
                    className="text-sm rounded border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 "
                    onClick={() => {
                      const button = document.getElementById("rescanPnr") as HTMLButtonElement | null;
                      if (button) {
                        disableButton([button]);
                     }
                     rescanPnr(data1.bookingKey, row.filekey)}}
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i>{" "}
                    {t("flightbooking.options.query-current-price")}{" "}
                  </button>
                </div>
              )
          }
        {
          row.status === "RESERVATION" && row.airlineSystem === "PCCL" &&

              <div
                className="btn-group btn-group-sm"
                role="group"
                key={row.filekey}
              >
                <button
                  type="button"
                  className="text-sm rounded border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 "
                  onClick={() => applyPgsDiscount(data1.bookingKey, row.filekey)}
                >
                  <i className="mdi mdi-coins"></i> Pegasus İndirim Sorgula
                </button>
              </div>

          }

        {
         row.status === "TICKETED" && row.voidAvailable &&

              <div key={row.filekey} className="text-center">
                <form id={`form_${row.filekey}`}>
     
      <input
        type="hidden"
        name="filekey"
        value={row.filekey}
      ></input>
    </form>
                <button
                id="void"
                  type="button"
                  className="text-sm rounded border border-gray-200 bg-red-500 px-4 py-2 font-medium text-white hover:bg-red-900 hover:text-red-700 focus:z-10 focus:text-red-700 focus:ring-2 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                  onClick={() => {
                    const button = document.getElementById("void") as HTMLButtonElement | null;
                      if (button) {
                        disableButton([button]);
                     }

                    handleVoid(bookingKey as string,row.filekey)}}
                >
                  <i className="fa fa-times"></i>

                  <span>Void</span>
                </button>
              </div>
  }
      </div>
    );
  };

  const internalButtonsFlight = (bookingKey: string, filekey: string, id: any, status: string) => {


    async function refreshPnr(filekey: string) {
      Swal.fire({
        title: "Refresh PNR",
        html: "Troya ise, lütfen aşağıdaki haneye Troya görüntüsünü (*A) ekleyiniz:",
        input: "textarea",
      }).then(function (result) {
        if (result.hasOwnProperty("value")) {
          const forms = document.forms;

          if (forms.length > 0) {
            const form = forms[0];
            const formData = new FormData();
            const refreshUrl = refreshPnrURL(filekey)
            formData.append("additionalData", result.value);
            formData.append("bookingKey", data1.bookingKey);

            fetcherWithoutJSON(refreshUrl, {
              method: "POST",
              body: formData,
            });
            form.submit();
          } else {
            console.error("No forms found on the page.");
          }
        }
      });
    }

    function changePnrStatus(bookingKey: string, pnrId: number, currentStatus: string) {
      Swal.fire({
        title: "PNR statüsünü değiştir",
        input: "select",
        inputOptions: {
          RESERVATION: "Opsiyonlu rezervasyon",
          TICKETED: "Biletlenmiş kayıt",
          VOIDED: "Void edilmiş kayıt",
          CANCELED: "Acente talebiyle iptal kayıt",
          EXPIRED: "Tarihi geçmiş kayıt",
          REFUNDED: "İade edilmiş kayıt (Refund)",
        },
        inputPlaceholder: "Lütfen seçiniz",
        inputValue: currentStatus,
        showCancelButton: true,
      }).then(function (result) {
        if (result.hasOwnProperty("value")) {
          const forms = document.forms;
          const url = changePnrStatusURL(bookingKey, pnrId,result.value);
          if (forms.length > 0) {
            const form = forms[0];
            const data = new FormData(form);
            data.append("bookingKey", data1.bookingKey);
            fetcherWithoutJSON(url)
            form.submit();
          } else {
            console.error("No forms found on the page.");
          }
        }
      });
    }
    return userRole === "ADMIN" || userRole === "SYSTEM_EMPLOYEE"  ? (
      <div>
        <div>
          <div className="gap-2 px-4 sm:flex sm:justify-center sm:p-2">
            <form>
              <input
                type="hidden"
                name="bookingKey"
                value={data1.bookingKey}
              ></input>
            </form>
            <div
              className="mb-2 inline-flex flex-wrap rounded-md shadow-sm"
              role="group"
            >
              <button
              id="refreshPnr"
                type="button"
                onClick={() => {
                  const button = document.getElementById("refreshPnr") as HTMLButtonElement | null;
                  if (button) {
                    disableButton([button]);
                 }
                  refreshPnr(filekey)}}
                className="text-sm rounded-l-lg border border-gray-100 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 "
              >
                <i className="fa fa-refresh"></i> Refresh
              </button>


              <button
                type="button"
                onClick={() => changePnrStatus(bookingKey, id, status)}
                className="text-sm rounded-r-lg border border-gray-100 bg-white px-4 py-2 font-medium text-gray-900  hover:bg-gray-100 hover:text-blue-700 "
              >
                Change PNR
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  };
  //FLIGHT SECTION
  const sectionFlight = () => {
    if (!data1.flightList || data1.flightList.length === 0) return null;

    ///rescanPNR
    async function rescanPnrCreate(bookingKey: string, filekey: string) {
      const payload = {
        bookingKey: bookingKey,
        filekey: filekey,
      };

      try {
        setLoading(true);
        const response = await postFetcher(
          postRescanPnr(),
          payload
        );
      //  { if(response.hasOwnProperty('status')) {
      //     Swal.fire(t("app.general-error"), "error");
      //     setLoading(false)
      //     return
      //   }}
        const old: number = parseFloat(response.oldPrice);
        const newPrice: number = parseFloat(response.newPrice);
        const diff: number = newPrice - old;
        const newDiff = Math.abs(diff).toFixed(2);
        const depFlightRecommendation = response.depFlightRecommendation;
        const retFlightRecommendation = response.retFlightRecommendation;
        let diffColor: string = "green";
        let diffSign: string = "-";
        if (diff > 0) {
          diffColor = "red";
          diffSign = "+";
        }

        Swal.fire({
          title: t("flightbooking.options.query-current-price"),
          html: `<span style="font-weight:500">
                    ${t("generals.old-price")}: <b>${old.toFixed(2)} EUR</b><br>
                    <b style="color:${diffColor}">
                        ${t("generals.difference")}: ${diffSign}${newDiff} EUR
                    </b><br>
                    ${t("generals.new-price")}: <b>${newPrice.toFixed(2)} EUR</b>
                </span>`,
          showCancelButton: true,
          confirmButtonText: `<i class="fa fa-plus" aria-hidden="true"></i> ${t("flights.reserve")}`,
          cancelButtonText: `<i class="fa fa-close" aria-hidden="true"></i> ${t("generals.abort")}`,
        }).then(async (result) => {
          setLoading(false);

          if (result.isConfirmed) {
            const payload1 = {
              bookingKey: bookingKey,
              filekey: filekey,
              depFlightRecommendation: depFlightRecommendation,
              retFlightRecommendation: retFlightRecommendation,
            };


            try {
              setLoading(true);
              const response1 = await postFetcher(
                postRescanPnrtakeReservation(),
                payload1
              );
              const newBookingKey = response1.newBookingKey;
              setLoading(false);

              if (!response1.hasOwnProperty("status") || response1.status === "error") {
                Swal.fire(t("app.general-error"), response1.reason, "error");
                return;
              }

              Swal.fire({
                title: t("generals.success"),
                showConfirmButton: false,
                html: `<span style="font-weight:500">
                            <br>
                            <br>
                            JUPITER Key: <a href="https://www.atrtouristik.com/ibe#/booking/detail/${newBookingKey}" 
                                    target="_blank" class="btn btn-success ml-2">
                                    <i class="mdi mdi-open-in-new"></i> ${newBookingKey}
                                </a>
                            <br>
                        </span>`,
              });
            } catch (error) {
              setLoading(false);
              Swal.fire(t("app.general-error"), response.reason, "error");
              console.error('API request failed with error:', error);

            }
          }
        });
      } catch (error) {
        setLoading(false);
        Swal.fire(t("app.general-error"), "error");
        console.error('API request failed with error:', error);

      }
    }


    function DeletePnr(formElement: HTMLFormElement, filekeyPnr: string) {

      const confirmation = window.confirm(
        t("generals.are-you-sure-you-want-to-proceed")
      );

      if (confirmation) {
        setLoading(true);

        if (formElement.length > 0) {

          formElement.action = baseUrl + `/agency/deletePnr?filekey=${filekeyPnr}`;
          formElement.addEventListener('submit', () => {
            setLoading(false);
          });
          formElement.submit();
        } else {
          setLoading(false);
          console.error("No forms found on the page.");
        }
      }
      else
      setLoading(false);
    }
    async function validateRefund(filekey: string): Promise<void> {
      const payload = {
        bookingKey: data1.bookingKey,
        filekey: filekey}
      try {
        setLoading(true);
        const res = await postFetcher(
          postValidateRefundUrl(),
          payload
        );

        if (!res) {
          throw new Error("Failed to validate refund");
        }

        const data = await res;
        setLoading(false)
        window.refundToken = data.refundToken;

        console.log("Validate Refund succesfully loaded.");

        if (
          window.confirm(`${t("flightbooking.refund.the-total-refund-amount-you-will-get")}
                 ${parseFloat(data.refundAmount).toFixed(
                   2
                 )} €\n\n
                ${t("flightbooking.refund.attention-no-further-action-possible-after-refund")}\n\n
                ${t("generals.are-you-sure-you-want-to-proceed")}`)
        ) {
          await processRefund(filekey);
        }
      } catch (err) {
        console.error(err);
        window.alert("Failed to validate refund.");
      } finally {
        setLoading(false);
      }
    }

    async function processRefund(filekey: string): Promise<void> {
      const payload = {
        bookingKey: data1.bookingKey,
        filekey: filekey,
        token: window.refundToken
      }
      try {
      setLoading(true)
        const res = await postFetcher(postAgenyRefundUrl(),payload);

        if (!res) {
          throw new Error("Failed to process refund");
        }
        setLoading(false);
        console.log("Refund succesfully processed.");
        window.alert(t("generals.reservationstate-description.refunded"))
        setTimeout(() => window.location.reload(), 2000);
      } catch (err) {
        console.error(err);
        window.alert(t("flightbooking.refund.airline-system-is-not-able-to-refund-automatically"));
      } finally {
        setLoading(false);
      }
    }

    return (
      <div>
        <div className="flex justify-end px-4">
          { audit.marketingType === "B2B"  && <Timer initialTime={data1.remainingOptionTimeInSeconds} />}
        </div>
        {sectionPassanger()}

        {pnrdata.map((row) => (
          <div key={row.id}>
            <div className="m-4 border rounded bg-white shadow-sm ">
              <div className="mb-1 grid grid-cols-2">

              <div className="flex justify-start font-bold text-lg py-2 items-center gap-2 pl-4">
                  <i className="fa fa-plane" aria-hidden="true"></i>{" "}
                  {pnrdata.length === 2
                    ? pnrdata[0].id === row.id
                      ? t("flightbooking.departure_flights")
                      : t("flightbooking.return_flights")
                    : t("bookings.flights")}
                </div>
             <div className="flex justify-end">
             <div
                  className={`flex items-center rounded-l px-3 h-7 text-md
                            ${row.status === "TICKETED" ? "bg-green-400" : ""}
                            ${row.status === "RESERVATION" ? "bg-blue-400" : ""}
                            ${row.status === "CANCELED" ? "bg-red-400" : ""}
                            ${row.status === "VOIDED" ? "bg-red-400" : ""}
                            ${row.status === "REFUNDED" ? "bg-gray-400" : ""}
                            ${row.status === "EXPIRED" ? "bg-red-400" : ""}

`}
                >
                  {row.status === "RESERVATION" ? (
                    <>
                      {t("flightbooking.ticketing_deadline")}:{" "}
                      {formatServerDateTime(row.optionDate)}
                    </>
                  ) : null}
                  {row.status === "TICKETED" ? (
                    <>{t("flightbooking.ticketed")}</>
                  ) : null}
                  {row.status === "CANCELED" ? (
                    <>{t("flightbooking.canceled")}</>
                  ) : null}
                  {row.status === "VOIDED" ? <>Voided Kayit</> : null}
                  {row.status === "REFUNDED" ? (
                    <>{t("flightbooking.refunded")}</>
                  ) : null}
                  {row.status === "EXPIRED" ? (
                    <>{t("flightbooking.expired")}</>
                  ) : null}

                </div>
                <button
                  className="rounded-r px-3 items-center bg-gray-100 h-7 text-md"
                  onClick={() => copyButtonClickHandler(row.filekey)}
                >
                  <span className="text-base mr-2 font-semibold">
                    {row.filekey}
                  </span>
                  <i className="fa fa-copy" aria-hidden="true"></i>

                </button>
             </div>


              </div>


              <div className="table-responsive px-4 pb-0">
                <table className="table-borderless table w-full">
                  <thead>
                    <tr className={"text-base font-semibold border-t border-b"}>
                      <th>{t("generals.flight")}</th>
                      <th>{t("flights.departure")}</th>
                      <th>{t("flights.arrival")}</th>
                      <th>{t("flightbooking.class")}</th>
                      <th>{t("generals.date")}</th>
                      <th>{t("b2c-app.flights.results.filter.baggage")}</th>
                      <th>{t("flightbooking.airline")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.flightList?.map((flight) => (
                      <tr key={flight.id} className="text-base hover:bg-gray-100 border-t border-b">
                        <td>{flight.flightNumber}</td>
                        <td>
                          {flight.departurePortName + " (" + flight.departurePort + ")"}
                          <p>{flight.departureTime}</p>
                        </td>
                        <td>
                          {flight.arrivalPortName + " (" + flight.arrivalPort + ")"}
                          <p>{flight.arrivalTime}</p>
                        </td>
                        <td>{flight.classOfService}</td>
                        <td>
                          {formatServerDate(flight.departureDate) }

                        </td>
                        <td>{flight.baggageAllowance}</td>

                        <td>
                          <img
                            className="mx-auto h-12 w-12"
                            src={baseUrl + flight.airlineLogo}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {audit.marketingType === "B2B" && data1.status === "TICKETED" &&
                    <>
                  {data1.ssrRecords[0] ? (

                <div className=" border-t bg-white  p-2  ">
                   {SsrSection(row.filekey, row)}
                  <h2 className="p-2 my-1 font-semibold text-base">{t("ssr.selected.ssr")}</h2>
                  <table className="min-w-full table-auto p-2">
                <thead>
                  <tr>
                  <th className="border text-base">Type</th>
                  <th className="border text-base">Status</th>
                  <th className="border text-base">{t("homepage.register.remarks")}</th>
                  <th className="border text-base">{t("flights.price")}</th>
                  </tr>
                </thead>
                <tbody>
                {data1.ssrRecords.map((request: any, index: React.Key | null | undefined) => (
                  <>
                  <tr key={index} className="border">

                    <td className="py-2 px-4 text-md flex items-center justify-center gap-2">
                      {request.productCode && request.productCode}
                  {isAdmin && request.recordType ==="WHEELCHAIR" && request.type && request.type === "MANUAL" &&
                 <UpdateActionWCHR ssrRecordID={request.id} manualStatus={request.manualStatus} manualRemarks={request.manualRemarks} />}</td>
                    <td className="py-2 px-4 text-md">
                     {request.manualStatus && request.manualStatus === "CONFIRMED" ? t("b2c-app.generals.done") : null}
                    { request.manualStatus && request.manualStatus === "PENDING" ? t("b2c-app.profile.bookings.status.tf_pending_confirmation") : null}
                     {request.manualStatus && request.manualStatus === "FAILED" ? t("flight.ssr.wchr.failed") : null}
                     </td>
                    <td className="py-2 px-4 text-md">{request.remarks}</td>
                    <td className="py-2 px-4 text-md">{request.price} €</td>
                  </tr>
                  </>
                ))}
              </tbody>
            </table>
                </div>
              ) : (
                <div className="m-1 bg-white px-2 shadow-md">
                  {SsrSection(row.filekey, row)}
                </div>
              )}
                  </>
                }
                  {audit.marketingType === "B2B" && internalButtonsFlight(data1.bookingKey, row.filekey, row.id, row.status)}
            <div className="flex justify-center">

            {(row.status === "CANCELED" ||
  row.status === "EXPIRED" ||
  row.status === "VOIDED" ||
  row.status === "REFUNDED") &&  audit.marketingType === "B2B"  &&(
  <div>
    <form id={`form_${row.filekey}`}>
      <input
        type="hidden"
        name="bookingKey"
        value={data1.bookingKey}
      ></input>
      <input
        type="hidden"
        name="filekey"
        value={row.filekey}
      ></input>
    </form>
    <button
    id="deletePnr"
      type="button"
      onClick={() =>{
        const button = document.getElementById("deletePnr") as HTMLButtonElement | null;
                  if (button) {
                    disableButton([button]);
                 }
        DeletePnr(document.getElementById(`form_${row.filekey}`) as HTMLFormElement,row.filekey)}}
      className="text-sm rounded border bg-red-500 px-4 py-2 font-medium  text-white hover:border-red-600 hover:text-red-300 "
    >
      <i className="fa fa-trash" aria-hidden="true"></i>{" "}
      {t("generals.delete")}
    </button>
  </div>
)}
              {row.rescanAvailable && audit.marketingType === "B2B" &&
                (row.status === "CANCELED" || row.status === "EXPIRED") && (
                  <div className="text-center">
                    <button
                    id="rescanPnrCreate"
                      type="button"
                      className="text-sm rounded border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700"
                      onClick={() => {
                        const button = document.getElementById("rescanPnrCreate") as HTMLButtonElement | null;
                        if (button) {
                          disableButton([button]);
                       }
                        rescanPnrCreate(data1.bookingKey, row.filekey)}}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                      {t("flightbooking.options.create-new-reservation")}
                    </button>
                  </div>
                )}

              { audit.marketingType === "B2B" && reservationOptionsButtons(row)}

              {row.status === "TICKETED" && audit.marketingType === "B2B" && row.refundAvailable ? (
                <button
                id="validateRefund"
                  type="button"
                  className="text-sm border border-gray-200 rounded mt-0.5 bg-red-500 px-4 py-2 font-medium text-white hover:shadow-lg hover:text-red-700 focus:z-10 "
                  onClick={() => {
                    const button = document.getElementById("validateRefund") as HTMLButtonElement | null;
                        if (button) {
                          disableButton([button]);
                       }
                    validateRefund(row.filekey)}}
                >
                  <i className="fa fa-times"></i>

                  <span> {t("flightcases.refund-request")}</span>
                </button>
              ) : null}

              </div>

            { audit.marketingType === "B2B" && <div className="item-center mt-2  justify-end  bg-white px-4 font-semibold sm:flex">
                {currencyFormat(row.totalAmount)}
              </div>}

            </div>
          </div>
        ))}
      </div>
    );
  };
  const sectionTransfer = () => {
    const HandleMarkAsRefundedTra = () => {
      const forms = document.forms;
      if (forms.length > 0) {
        const form = forms[0];
        form.action = baseUrl + `/intern/markTrAsRefunded`;
        form.submit();
      } else {
        console.error("No forms found on the page.");
      }
    };

    if (
      !data1.transferReservation ||
      data1.transferReservation.length === 0 ||
      !transferData
    )
      return null;
    return (
      <div className="my-4">
        <section className="my-4 rounded border bg-white shadow-sm">
          <div className="mb-1 py-3">
            <div
              className={`mx-8 flex h-4 items-center justify-center rounded p-3
                                ${
                                  data1.status === "CONFIRMED"
                                    ? "bg-green-400"
                                    : ""
                                }
                                ${
                                  data1.status === "RESERVATION"
                                    ? "bg-blue-400"
                                    : ""
                                }
                                ${
                                  data1.status === "CANCELED"
                                    ? "bg-red-400"
                                    : ""
                                }
                                ${data1.status === "VOIDED" ? "bg-red-400" : ""}
                                ${
                                  data1.status === "REFUNDED"
                                    ? "bg-gray-400"
                                    : ""
                                }
                                ${
                                  data1.status === "EXPIRED" ? "bg-red-400" : ""
                                }
    
    `}
            >
              {data1.status === "RESERVATION" ? (
                <>
                  {t("flightbooking.ticketing_deadline")}:{" "}
                  {formatServerDate(data1.transferReservation.optionDate)}
                </>
              ) : null}
              {data1.status === "CONFIRMED" ? (
                <>{t("flightbooking.ticketed")}</>
              ) : null}
              {data1.status === "CANCELED" ? (
                <>{t("flightbooking.canceled")}</>
              ) : null}
              {data1.status === "VOIDED" ? <>Voided Kayit</> : null}
              {data1.status === "REFUNDED" ? (
                <>{t("flightbooking.refunded")}</>
              ) : null}
              {data1.status === "EXPIRED" ? (
                <>{t("flightbooking.expired")}</>
              ) : null}
            </div>
            <h3 className="p-2 font-bold">{t("transfer.details")}</h3>
            <div className="flex">
              <div className="w-1/2 p-4">
                <h3 className="items-center  font-bold">
                  {transferData?.transferProvider.transferProvider}
                </h3>
                <p>{transferData.transferProvider.tpTelephone}</p>
                <p>{transferData.transferProvider.tpEmail}</p>
              </div>
              <div className=" w-1/2 flex-wrap">
                <div className=" ml-32 w-1/2">
                  <img
                    alt="provider"
                    src={transferData.transferProvider.logoUrl}
                  />
                </div>

                <p className="text-sm">
                  {transferData.transferProvider.airportLocationDescription}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="my-4 rounded border bg-white shadow-sm">
          <div className="my-2 flex justify-around gap-4">
            <div className="text-sm ml-2 flex items-center">
              <p className="text-md font-semibold">
                {" "}
                {t("transfer.passenger-count")}:{" "}
                {transferData.adtCount +
                  transferData.chdCount +
                  transferData.infCount}
                ({t("generals.adult")}: {transferData.adtCount} ,
                {t("generals.child")}: {transferData.chdCount} ,
                {t("generals.infant")}: {transferData.infCount})
              </p>
            </div>
            <div className="">
              <p className=" font-semibold">
                Hotel : {transferData.hotelNameOrAddress}
              </p>
              <p className="text-sm">
                {t("transfer.hotel-name-or-address")} :{" "}
                {transferData.hotelAddress}
              </p>
            </div>
          </div>
        </section>

        <section className="my-4 rounded border shadow-sm">
          <div className="flex justify-around">
            <div className="text-lg grid grid-cols-1 grid-rows-4 gap-4 border bg-white p-6">
              <div className="text-md font-semibold">
                {t("pdf.transfer-pickup-information")}
              </div>
              <div className="text-md">
                <span className="font-semibold">{t("generals.date")} :</span>
                {transferData.flightDate}{" "}
              </div>

              <div className="text-md">
                <span className="font-semibold">
                  {t("react.transfer.pickup")}:{" "}
                </span>
                {transferData.pickupDestination.location}{" "}
              </div>

              <div className="text-md">
                <span className="font-semibold">
                  {t("react.transfer.destinaiton")}:{" "}
                </span>
                <span className="">
                  {transferData.targetDestination.location}
                  <p className="text-sm">
                    {transferData.hotelNameOrAddress}
                    {transferData.hotelAddress}
                  </p>
                </span>
              </div>
            </div>

            {transferData.returnFlightDate && (
              <div className=" grid grid-cols-1 grid-rows-4 gap-4 border bg-white p-6 ">
                <div className="text-md font-semibold">
                  {t("pdf.transfer-return-information")}
                </div>
                <div>
                  <span className="font-semibold">{t("generals.date")} :</span>
                  {transferData.returnFlightDate}
                </div>
                <div>
                  <span className="font-semibold">
                    {t("react.transfer.pickup")}:{" "}
                  </span>
                  <span className="">
                    {transferData.targetDestination.location}
                    <p className="text-sm">
                      {transferData.hotelNameOrAddress}
                      {transferData.hotelAddress}
                    </p>
                  </span>
                </div>
                <div>
                  <span className="font-semibold">
                    {t("react.transfer.destinaiton")}:{" "}
                  </span>
                  {transferData.pickupDestination.location}
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 grid-rows-4 gap-4  border bg-white p-6 ">
              <div>
                <span className="font-semibold">
                  {t("transfer.arrivaltime")}
                </span>
                <span></span>
              </div>

              <div>
                <span className="font-semibold">
                  {t("flights.departure_port")}:{" "}
                </span>
                <span>{transferData.flightDeparturePort}</span>
              </div>

              <div>
                <span className="font-semibold">
                  {t("generals.flight-number")}:{" "}
                </span>
                <span>{transferData.flightNo}</span>
              </div>

              <div>
                <span className="font-semibold">
                  {t("charter.arrival-date-and-time")}:{" "}
                </span>
                <span>
                  {transferData.departureTime}-{transferData.flightDate}{" "}
                </span>
              </div>
            </div>
            {transferData.returnFlightDate && (
              <div className="grid grid-cols-1 grid-rows-4 gap-4  border bg-white p-6">
                <div>
                  <span className="font-semibold">
                    {t("transfer.returntime")}{" "}
                  </span>
                  <span></span>
                </div>
                <div>
                  <span className="font-semibold">
                    {t("flights.departure_port")}:{" "}
                  </span>
                  <span>{transferData.returnFlightDeparturePort}</span>
                </div>
                <div>
                  <span className="font-semibold">
                    {t("generals.flight-number")}:{" "}
                  </span>
                  <span>{transferData.returnFlightNo}</span>
                </div>
                <div>
                  <span className="font-semibold">
                    {t("charter.departure-date-and-time")}:{" "}
                  </span>
                  <span>
                    {transferData.returnDepartureTime}-
                    {transferData.returnFlightDate}
                  </span>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="my-4 rounded border bg-white shadow-sm">
          <div className="items-center">
            <table className="table-bordered table-hover w-full table-auto ">
              <thead>
                <tr>
                  <th className="px-4 py-2">{t("react.hotel.firstname")}</th>
                  <th className="px-4 py-2">{t("react.hotel.lastname")}</th>
                  <th className="px-4 py-2">{t("charter.passenger-type")}</th>
                  <th className="px-4 py-2">{t("generals.gender")}</th>
                </tr>
              </thead>

              <tbody>
                {paxdata.map((row) => (
                  <tr key={row.id}>
                    <td className=" px-4 py-2">{row.firstname}</td>
                    <td className=" px-4 py-2">{row.surname} </td>
                    <td className=" px-4 py-2">{row.paxtype}</td>
                    <td className=" px-4 py-2">{row.gender}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="mt-2 p-2">Passenger Contact: {transferData.phone}</p>
            <div className="text-base  rounded bg-slate-100 p-1 text-gray-900">
              <span>
                {t("rentalcar.total")}:{" "}
                {currencyFormat(transferData.totalPrice)}
              </span>
            </div>
          </div>
        </section>

        <section className="my-4 rounded border bg-white py-3 shadow-sm">
          <div className="text-sm flex  flex-wrap items-center justify-center text-center">
            {data1.status === "CONFIRMED" &&
            (userRole === "ADMIN" || userRole === "SYSTEM_EMPLOYEE") ? (
              <div>
                  <form>
                  <input
                    type="hidden"
                    name="bookingKey"
                    value={data1.bookingKey}
                  ></input>
                </form>
              <button
              id="markAsRefundTra"
                type="button"
                className="text-sm rounded   border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                onClick={() => {
                  const button = document.getElementById("markAsRefundTra") as HTMLButtonElement | null;
                  if (button) {
                    disableButton([button]);
                 }
                  HandleMarkAsRefundedTra()}}
              >
                İade edildiğini yansıt
              </button>
              </div>
            ) : null}
          </div>
        </section>
      </div>
    );
  };

  const [remarks, setRemarks] = useState<string>("");
  useEffect(() => {
    setRemarks(data.remarks);
  }, [data1]);
  const RemarksSection = () => {
    const saveRemarks = async (bookingKey: string, remarks: string) => {
      const payload = {
        bookingKey: bookingKey,
        remarks: remarks,
      };


        const response = await postFetcher(postSaveRemarksUrl(), payload);
        if (response) {
          Swal.fire(t("generals.success"), '', 'success');
        } else {
          console.error('API request failed with status:', response);
          Swal.fire(t('app.general-error'), '', 'error');
        }

    };


    function handleSaveRemarksBtnClick(): void {
      if (bookingKey && remarks) {
        saveRemarks(bookingKey, remarks);
      }
    }

    return (
      <div className="my-1 rounded bg-white py-2  mx-4 ">
        <div className="border-1 flex h-full w-full justify-items-stretch rounded-md  pb-4 ">
          <input
            className="h-full w-3/4 rounded-l-md border-2 border-gray-200 p-2"
            onChange={(e) => setRemarks(e.target.value)}
            placeholder={data1.remarks ? "" : t("manage-agencies.remarks")}
            value={remarks}
            readOnly={false}
          />

          <button
            onClick={handleSaveRemarksBtnClick}
            className="text-sm  w-1/4 rounded-r-md border-t border-b border-gray-200 bg-gray-100 font-medium text-gray-900 hover:bg-gray-100"
          >
            {t("generals.save")}
          </button>
        </div>

      </div>
    );
  };
  const [ssrFilekey, setSsrfilekey] = useState("");

  const SsrSection = (filekeyData: string , pnr: pnrdata ) => {
    return (
      <div>



              <div className=" rounded bg-white ">
                <div className="p-1">

                <div className="border mb-1 bg-white p-2">
                  <span className="text-sm ">{t("ssr.select")}</span>
                </div>
                  {(data1.status === "TICKETED" ||
                    data1.status === "RESERVATION") && (
                    <div className="grid grid-flow-col justify-center gap-4 ">
                      { pnr.bookMealAvailable &&
                          (
                            <div className=" rounded border max-w-xs">
                              <button
                                type="button"
                                onClick={() => {
                                  setIsOpen(true);
                                  setSsrfilekey(filekeyData);
                                  window.scrollTo(0, 0);

                                }}
                                 className="h-full w-full text-gray-900 hover:bg-gray-200"
                              >
                                <div className="grid grid-cols-2 items-center">
                                    <RestaurantIcon
                                      color="primary"
                                      className=" text-lg min-h-full min-w-full "
                                    />
                                    <img
                                    className="h-16 object-contain"
                                    src="https://cdn.sunexpress.com/wp-content/uploads/sites/6/2022/03/10140557/SXS-book-a-seat-494x320.jpg"></img>

                                </div>
                              </button>
                            </div>
                          )
                      }
                      { pnr.bookSeatAvailable &&
                         (
                            <div className="rounded border max-w-xs">
                              <button
                                type="button"
                                onClick={() => {setIsSeatOpen(true)
                                                setSsrfilekey(filekeyData);
                                                window.scrollTo(0, 0);

                                }}
                                className="h-full w-full text-gray-900 hover:bg-gray-200">
                                <div className="grid grid-cols-2 items-center">
                                    <AirlineSeatReclineNormalIcon
                                      color="primary"
                                      className=" text-lg min-h-full min-w-full "
                                    />
                                    <img
                                    className="h-16 object-contain"
                                    src="https://cdn.sunexpress.com/wp-content/uploads/sites/3/2020/03/18181753/ibe-extras-seat-reservation_EN.jpg"></img>

                                </div>
                              </button>
                            </div>
                          )
                      }
                      { pnr.bookBaggageAvailable &&
                         (
                            <div className=" rounded border max-w-xs">
                              <button
                                type="button"
                                onClick={() => {setBaggageOpen(true);
                                                setSsrfilekey(filekeyData)
                                                  window.scrollTo(0, 0);
                                                }}
                                className="h-full w-full text-gray-900 hover:bg-gray-200"
                              >
                                <div className="grid grid-cols-2 items-center">

                                    <LuggageIcon
                                      color="primary"
                                      className=" text-lg min-h-full min-w-full "
                                    />
                                    <img
                                    className="h-16 object-contain"
                                    src="https://cdn.sunexpress.com/wp-content/uploads/sites/6/2022/03/10140605/SXS-ExtraLuggage-Newpics-494x320.jpg"></img>
                                  </div>

                              </button>
                            </div>
                          )
                      }

                      { <div className=' border rounded max-w-xs'>
                            <button type="button"
                               onClick={() => {setIsWheelchairopen(true);
                                      setSsrfilekey(filekeyData)
                                      window.scrollTo(0, 0);

                                    }}
                                 className="w-full h-full text-gray-900 hover:bg-gray-200">
                        <div className='grid grid-cols-2 items-center' >
                        <AccessibleIcon  color="primary" className=' min-w-full min-h-full text-lg '/>
                        <img
                        className="h-16 object-contain"
                        src='https://cms.ibsplc.aero/opencms/export/system/modules/com.ibsplc.ibe/resources/XQ/IBE/img/extras-wheelchair.webp'></img>
                        </div>
                          </button>
                                </div>
                      }

                      { pnr.bookAirportCheckinAvailable &&
                           (
                            <div className=" rounded border max-w-xs">
                              <button
                                type="button"
                                onClick={() => {setIsCheckinOpen(true);
                                  setSsrfilekey(filekeyData)
                                  window.scrollTo(0, 0);

                                }}
                                 className="h-full w-full text-gray-900 hover:bg-gray-200"
                              >
                                <div className="grid grid-cols-2 items-center">
                                    <AddTaskIcon
                                      color="primary"
                                      className=" text-lg min-h-full min-w-full "
                                    />
                                    <img
                                    className="h-16 object-contain"
                                    src="https://cms.ibsplc.aero/opencms/export/system/modules/com.ibsplc.ibe/resources/XQ/IBE/img/Check-in.png"></img>

                                </div>
                              </button>
                            </div>
                          )
                      }
                    </div>
                  )}

               {pnr.bookMealAvailable &&  <MealSelection />}
               {pnr.bookSeatAvailable && <SeatSelection />}
               <WheelChairSelection />
               {pnr.bookBaggageAvailable && <BaggageSelection />}
               {pnr.bookAirportCheckinAvailable && <CheckinSelection />}
                </div>
              </div>

      </div>
    );
  };
  const MealSelection = () => {
    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<any>();
    const [flightId, setFlightId] = useState<any>();
    const [filekey, setFilekey] = useState<any>();
    const [mealloading, setmealloading] = useState(false);
    const [selectedMeal, setSelectedMeal] = useState<string>("");
    const [confirmbox, setConfirmbox] = useState(false);

    interface SsrResponse {
      ssrList: string[];
    }

    const postSsr = async (
      key: string,
      filekey: string,
      selectedMeal: string
    ): Promise<SsrResponse> => {
      const url = confirmMealSelection();
      const params = new URLSearchParams({
        bookingKey: key,
        filekey,
        ssrKey: selectedMeal,
      });

      try {
        const response = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",

        });

        if (!response.ok) {
          const correlationID: any = response.headers.get("correlation-id");
          Swal.fire("Error Code: " + correlationID, "", "error");
        } else {
          Swal.fire("success", "", "success");
        }

        const data = await response.json();
        return data as SsrResponse;
      } catch (error) {
        Swal.fire("Error Code: unknown", "", "error");
        console.error(error);
        throw error;
      } finally {
        setmealloading(false);
        setConfirmbox(false);
      }
    };

    //post meal api

    type mealdata1 = {
      ssrKey: string;
      name: string;
      description: string;
      currencyCode: string;
      price: number;
    };

    const [testData, settestData] = useState<any>();
    const [isloading, setisloading] = useState(true);

    const getssrData = async () => {
      await fetcherWithoutJSON(
        getMealSsr() +
          new URLSearchParams({
            bookingKey: key.toString(),
            filekey: filekey,
            flightId: flightId,
            paxId: paxId,
          }),
        {
          method: "GET",
          credentials: "include",
          cache: "no-cache",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong on api server!");
          }
        })
        .then((data) => {
          settestData(data.ssrList);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(t("ssr.errorfrom-airline"), "", "error");

        })
        .finally(() => {
          setisloading(false);
        });
    };
    useEffect(() => {
      if (flightId && paxId && filekey) {
        getssrData();
      }
    }, [isgetData, paxId, flightId]);

    const mealdata1: any = testData;
    if (isOpen === true) {
      window.scrollTo(0, 0);
    }
    return (
      <div>
        {isOpen ? (
          <div>
            {isOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                  <ButtonClose onClick={() => setIsOpen(false)}></ButtonClose>

                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <RestaurantIcon />{" "}
                      <h2 className="p-2">{t("flightbooking.meals")}</h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">
                          3- {t("flightbooking.meals")} {t("generals.select")}{" "}
                        </h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.meals")} {t("generals.select")}{" "}
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.meals")} {t("generals.select")}{" "}
                        </h2>
                      )}
                    </div>

                    <div className="">
                      <div className="w-full  p-2">
                        <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                          {pnrdata.map(
                            (pnr) =>
                              pnr.bookMealAvailable &&
                              pnr.filekey === ssrFilekey && (
                                <div
                                  key={pnr.filekey}
                                  className="  w-2/6 p-2  "
                                >
                                  <h3 className=" font-bold">
                                    1- {t("flightbooking.flights")}
                                  </h3>

                                  {pnr.flightList?.map((flight) => (
                                    <div key={flight.id}>
                                      <button
                                        key={flight.id}
                                        className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                          flight.id === flightId
                                            ? "bg-gray-200"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setFlightId(flight.id);
                                          setPaxId("");
                                          settestData(null);
                                        }}
                                      >
                                        <div className="  text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                          {flightId === flight.id ? (
                                            <i
                                              className="fa fa-check"
                                              aria-hidden="true"
                                            ></i>
                                          ) : null}

                                          <p className="font-bold">
                                            {flight.flightNumber}
                                          </p>
                                          <p>
                                            {flight.departurePort} -{" "}
                                            {flight.arrivalPort}
                                          </p>
                                        </div>
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )
                          )}
                          {pnrdata.map(
                            (pnr) =>
                              pnr.bookMealAvailable &&
                              pnr.filekey === ssrFilekey && (
                                <div className=" flex w-1/6 items-stretch justify-center p-2  ">
                                  {flightId && (
                                    <div>
                                      <h3 className=" font-bold">
                                        2- {t("flightbooking.passengers")}
                                      </h3>

                                      <div>
                                        {pnr.bookMealAvailable &&
                                          pnr.filekey === ssrFilekey && (
                                            <div>
                                              {pnr.paxList.map((p) => (
                                                <div key={p.id}>
                                                  <button
                                                    key={p.id}
                                                    className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                                      p.id === paxId
                                                        ? "bg-gray-200"
                                                        : ""
                                                    }`}
                                                    onClick={() => {
                                                      setPaxId(p.id);
                                                      setFilekey(pnr.filekey);
                                                      //setIsgetData(!isgetData);
                                                      {
                                                      }
                                                    }}
                                                  >
                                                    <div className="text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                      {paxId === p.id ? (
                                                        <i
                                                          className="fa fa-check"
                                                          aria-hidden="true"
                                                        ></i>
                                                      ) : null}

                                                      <p className="">
                                                        {p.firstname}
                                                      </p>
                                                      <p className="font-bold">
                                                        {p.surname}
                                                      </p>
                                                    </div>
                                                  </button>
                                                </div>
                                              ))}
                                            </div>
                                          )}{" "}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                          )}
                          <div className=" w-4/6 p-2 mx-1 ">
                            { mealdata1 ?  mealdata1.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}
                            {paxId &&
                            flightId &&
                            data1.ssrRecords.find(
                              (rcr: any) => rcr.recordType === "MEAL"
                            )?.paxId === paxId &&
                            data1.ssrRecords.find(
                              (rcr: any) => rcr.recordType === "MEAL"
                            )?.flightId === flightId ? (
                              <span>{t("ssr.already.selected")}</span>
                            ) : (

                              mealdata1 &&
                              paxId &&
                              flightId &&
                              !isloading && (
                                <div className="relative p-3">
                                  <select
                                    className="focus:shadow-outline w-full appearance-none rounded border bg-white py-2 pl-3 pr-8 leading-tight text-gray-700 focus:outline-none"
                                    value={selectedMeal}
                                    onChange={(e) =>
                                      setSelectedMeal(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      {t("flightbooking.meals")}{" "}
                                      {t("generals.select")}{" "}
                                    </option>
                                    {!testData
                                    ? (
                                      <div>Havayolu kaynaklı hata alıyoruz</div>
                                    ) : (
                                      mealdata1.map((meal: any) => (
                                        <option
                                          key={meal.ssrKey}
                                          value={meal.ssrKey}
                                        >
                                          {meal.name} {meal.description} -{" "}
                                          {meal.price} {meal.currencyCode}
                                        </option>
                                      ))
                                    )}
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                    <svg
                                      className="h-4 w-4 fill-current text-gray-500"
                                      viewBox="0 0 20 20"
                                    ></svg>
                                  </div>
                                  <div className="mt-2 rounded border shadow-md">
                                    {selectedMeal && (
                                      <div className="p-8">
                                        <div className="border-1 text-md overflow-hidden text-center text-gray-900 ">
                                          <p className="font-bold">
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.name
                                            }
                                          </p>
                                          <p className="">
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.description
                                            }
                                          </p>
                                          <p className="">
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.price
                                            }{" "}
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.currencyCode
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>

                      {confirmbox && (
                        <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                          <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                            <div className="flex w-full justify-between p-2 ">
                              <ButtonClose
                                onClick={() => setConfirmbox(false)}
                              ></ButtonClose>

                              {mealloading && (
                                <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                              )}
                            </div>
                            <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                              <p className="font-bold">
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.name
                                }
                              </p>
                              <p className="">
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.description
                                }
                              </p>
                              <p className="">
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.price
                                }{" "}
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.currencyCode
                                }
                              </p>
                            </div>
                            <div className="p-2">
                              <p className=" text-red-600">
                                {t(
                                  "generals.attention-this-action-is-not-reversible"
                                )}
                              </p>
                              <p></p>
                              <p>
                                {" "}
                                {t("generals.are-you-sure-you-want-to-proceed")}
                              </p>
                              <div className="inline-flex  p-2 " role="group">
                                <button
                                  type="button"
                                  className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500
                                  "
                                  onClick={() => {
                                    setConfirmbox(false);
                                  }}
                                >
                                  {t("b2c-app.generals.cancel")}
                                </button>
                                <button
                                  id="confirmMeal"
                                  type="button"
                                  className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400"
                                  onClick={() => {
                                    const button = document.getElementById("confirmMeal") as HTMLButtonElement | null;
                                    if (button) {
                                      disableButton([button]);
                                   }
                                    postSsr(key, filekey, selectedMeal); //post ssr func will come
                                    setmealloading(true);
                                  }}
                                  disabled={mealloading}
                                >
                                  {t("generals.yes")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="inline-flex  p-2 " role="group">
                        <button
                          type="button"
                          className="text-sm rounded-l-lg border border-gray-200 bg-white-500 px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-white focus:z-10 focus:ring-2"
                          onClick={() => {
                            //setIsExpanded(false);
                            setPaxId("");
                            setFlightId("");
                            setIsOpen(false);
                            //setSelectedMeal("");
                            setSelectedMeal("");
                          }}
                        >
                          {t("b2c-app.generals.cancel")}
                        </button>
                        <button
                          type="button"
                          className="text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium text-white  hover:text-gray-200 hover:bg-primary-400 focus:z-10 focus:ring-2"
                          onClick={() => {
                            //  setIsExpanded(false);
                            // postSsr(key, filekey, selectedMeal); //post ssr func will come
                            setConfirmbox(true);
                          }}
                        >
                          {t("generals.add")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };
  const BaggageSelection = () => {
    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<any>();
    const [flightId, setFlightId] = useState<any>();
    const [filekey, setFilekey] = useState<any>();
    const [isloading, setisloading] = useState(true);
    const [baggageloading, setBaggageLoading] = useState(false);
    const [selectedBaggage, setSelectedBaggage] = useState<string>("");

    interface BaggageResponse {
      ssrList: string[];
    }

    const postSsr = async (
      key: string,
      filekey: string,
      selectedBaggage: string
    ): Promise<BaggageResponse> => {
      const url = confirmBaggageSelection();
      const params = new URLSearchParams({
        bookingKey: key,
        filekey,
        ssrKey: selectedBaggage,
      });

      try {
        const response = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",
        });

        if (!response.ok) {
          const correlationID: any = response.headers.get("correlation-id");
          Swal.fire("Error Code: " + correlationID, "", "error");
        } else {
          Swal.fire("Success", "", "success");
        }

        const data = await response.json();
        return data as BaggageResponse;
      } catch (error) {
        Swal.fire("Error Code: unknown", "", "error");
        console.error(error);
        throw error;
      } finally {
        setBaggageLoading(false);
        setBaggageOpen(false);
        setConfirmbox(false);
      }
    };

    //post wheelchair api

    const [baggageData, setBaggageData] = useState<any>();

    const getssrData = async () => {
      await fetcherWithoutJSON(
        getBaggageSsr() +
          new URLSearchParams({
            bookingKey: key.toString(),
            filekey: filekey,
            flightId: flightId,
            paxId: paxId,
          }),
        {
          method: "GET",

          credentials: "include",
          cache: "no-cache",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong on api server!");
          }
        })
        .then((data) => {
          setBaggageData(data.ssrList);
        })
        .catch((error) => {
          Swal.fire(t("ssr.errorfrom-airline"), "", "error");
          console.log(error);
        })
        .finally(() => {
          setisloading(false);
        });
    };
    useEffect(() => {
      if (flightId && paxId && filekey) {
        getssrData();
      }
    }, [isgetData, paxId, flightId]);

    const baggagedata1: any = baggageData;
    if (isBaggageOpen === true) {
      window.scrollTo(0, 0);
    }

    function showTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-0", "invisible");
        tooltip.classList.add("opacity-100");
      }
    }

    function hideTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-100");
        tooltip.classList.add("opacity-0", "invisible");
      }
    }

    function handleClick(data: React.SetStateAction<string>) {
      setisActive(true);
      setisdisabled(true);
      setBaggageData(data);
    }

    const [isActive, setisActive] = useState(false);
    const [isDisabled, setisdisabled] = useState(false);
    const [confirmbox, setConfirmbox] = useState(false);
    return (
      <div>
        {isBaggageOpen ? (
          <div>
            {isBaggageOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                  <ButtonClose
                    onClick={() => setBaggageOpen(false)}
                  ></ButtonClose>

                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <LuggageIcon /> <h2 className="p-2">Baggage Selection</h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">
                          3- {t("react.flight.addbaggage")}
                        </h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("react.flight.addbaggage")}
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("react.flight.addbaggage")}
                        </h2>
                      )}
                    </div>

                    <div className="">
                      {
                        <div className="w-full  p-2">
                          <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                            {pnrdata.map(
                              (pnr) =>
                                pnr.bookBaggageAvailable &&
                                pnr.filekey === ssrFilekey && (
                                  <div className="  w-2/6 p-2  ">
                                    <h3 className=" font-bold">
                                      1- {t("flightbooking.flights")}
                                    </h3>

                                    {pnr.flightList?.map((flight) => (
                                      <div key={flight.id}>
                                        <button
                                          key={flight.id}
                                          className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                            flight.id === flightId
                                              ? "bg-gray-100"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setFlightId(flight.id);
                                            setPaxId("");
                                            setBaggageData(null)
                                          }}
                                        >
                                          <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                            {flightId === flight.id ? (
                                              <i
                                                className="fa fa-check "
                                                aria-hidden="true"
                                              ></i>
                                            ) : null}
                                            <p className="font-bold">
                                              {flight.flightNumber}
                                            </p>
                                            <p>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </p>
                                          </div>
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                )
                            )}
                            {pnrdata.map(
                              (pnr) =>
                                pnr.bookBaggageAvailable &&
                                pnr.filekey === ssrFilekey && (
                                  <div className=" flex w-1/6 items-stretch justify-center p-2  ">
                                    {flightId && (
                                      <div>
                                        <h3 className=" font-bold">
                                          2- {t("flightbooking.passengers")}
                                        </h3>
                                        {pnr.bookBaggageAvailable &&
                                          pnr.filekey === ssrFilekey && (
                                            <div>
                                              {pnr.paxList.map((p) => (
                                                <div key={p.id}>
                                                  <button
                                                    key={p.id}
                                                    className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                                      p.id === paxId
                                                        ? "bg-gray-100"
                                                        : ""
                                                    }`}
                                                    onClick={() => {
                                                      setPaxId(p.id);
                                                      setFilekey(pnr.filekey);
                                                      //setIsgetData(!isgetData);
                                                      {
                                                      }
                                                    }}
                                                  >
                                                    <div className="  text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                      {paxId === p.id ? (
                                                        <i
                                                          className="fa fa-check "
                                                          aria-hidden="true"
                                                        ></i>
                                                      ) : null}

                                                      <p className="">
                                                        {p.firstname}
                                                      </p>
                                                      <p className="font-bold">
                                                        {p.surname}
                                                      </p>
                                                    </div>
                                                  </button>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                )
                            )}
                            <div className=" w-4/6 p-2 ">
                            { baggagedata1 ?  baggagedata1.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}

                              {paxId &&
                              flightId &&
                              // data1.ssrRecords.find(
                              //   (rcr: any) => rcr.recordType === "BAGGAGE"
                              // )?.paxId === paxId &&
                              // data1.ssrRecords.find(
                              //   (rcr: any) => rcr.recordType === "BAGGAGE"
                              // )?.flightId === flightId ? (
                              //   <span>{t("ssr.already.selected")}</span>
                              // ) :
                              (
                                baggagedata1 &&
                                paxId &&
                                flightId &&
                                !isloading && (
                                  <div className="relative flex justify-center p-2 ">
                                    <div className="relative">
                                      <select
                                        className="focus:shadow-outline w-full appearance-none rounded border bg-white py-2 pl-3 pr-8 leading-tight text-gray-700 focus:outline-none"
                                        value={selectedBaggage}
                                        onChange={(e) =>
                                          setSelectedBaggage(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          {t("react.flight.addbaggage")}
                                        </option>
                                        {!baggagedata1 ? (
                                          <div>
                                            Havayolu kaynaklı hata alıyoruz
                                          </div>
                                        ) : (
                                          baggagedata1.map((meal: any) => (
                                            <option
                                              key={meal.ssrKey}
                                              value={meal.ssrKey}
                                            >
                                              {meal.name.includes("KG") ? meal.name : meal.name + " KG"} - {meal.price}{" "}
                                              {meal.currencyCode}
                                            </option>
                                          ))
                                        )}
                                      </select>
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                        <svg
                                          className="h-4 w-4 fill-current text-gray-500"
                                          viewBox="0 0 20 20"
                                        ></svg>
                                      </div>
                                      <div className=" rounded border shadow-md">
                                        {selectedBaggage && (
                                          <div className="p-8">
                                            <div className="border-1 text-md overflow-hidden text-center text-gray-900 ">
                                              <p className="font-bold">
                                                {

                                                  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.name.includes("KG")

                                                ?  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.name
                                                  : baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.name + " KG"
                                                }
                                              </p>
                                              <p className="">
                                                {
                                                  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.price
                                                }
                                                {"  "}
                                                {
                                                  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.currencyCode
                                                }
                                              </p>
                                            </div>
                                            {confirmbox && (
                                              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                                                <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                                                  <div className="flex w-full justify-between p-2 ">
                                                    <ButtonClose
                                                      onClick={() =>
                                                        setConfirmbox(false)
                                                      }
                                                    ></ButtonClose>
                                                    {baggageloading && (
                                                      <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                                                    )}
                                                  </div>
                                                  <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                                                    <p className="font-bold">
                                                    {

                                                 baggagedata1.find(
                                                   (meal: any) =>
                                                     meal.ssrKey ===
                                                     selectedBaggage
                                                 )?.name.includes("KG")

                                               ?  baggagedata1.find(
                                                   (meal: any) =>
                                                     meal.ssrKey ===
                                                     selectedBaggage
                                                 )?.name
                                                 : baggagedata1.find(
                                                   (meal: any) =>
                                                     meal.ssrKey ===
                                                     selectedBaggage
                                                 )?.name + " KG"
                                               }
                                                    </p>
                                                    <p className="">
                                                      {
                                                        baggagedata1.find(
                                                          (meal: any) =>
                                                            meal.ssrKey ===
                                                            selectedBaggage
                                                        )?.price
                                                      }
                                                      {
                                                        baggagedata1.find(
                                                          (meal: any) =>
                                                            meal.ssrKey ===
                                                            selectedBaggage
                                                        )?.currencyCode
                                                      }{" "}
                                                    </p>
                                                  </div>
                                                  <div className="p-2">
                                                    <p className=" text-red-600">
                                                      {t(
                                                        "generals.attention-this-action-is-not-reversible"
                                                      )}
                                                    </p>
                                                    <p></p>
                                                    <p>
                                                      {" "}
                                                      {t(
                                                        "generals.are-you-sure-you-want-to-proceed"
                                                      )}
                                                    </p>
                                                    <div
                                                      className="inline-flex  p-2 "
                                                      role="group"
                                                    >
                                                      <button
                                                        type="button"
                                                        className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500 "
                                                        onClick={() => {
                                                          setConfirmbox(false);
                                                        }}
                                                      >
                                                        {t(
                                                          "b2c-app.generals.cancel"
                                                        )}
                                                      </button>
                                                      <button
                                                        id="confirmBaggage"
                                                        type="button"
                                                        className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400 "
                                                        onClick={() => {
                                                          const button = document.getElementById("confirmBaggage") as HTMLButtonElement | null;
                                                          if (button) {
                                                            disableButton([button]);
                                                         }
                                                          postSsr(
                                                            key,
                                                            filekey,
                                                            selectedBaggage
                                                          ); //post ssr func will come
                                                          setBaggageLoading(
                                                            true
                                                          );
                                                        }}
                                                        disabled={baggageloading}
                                                      >
                                                        {t("generals.yes")}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {!selectedBaggage ? (
                                              <div>
                                                {t("react.flight.addbaggage")}
                                              </div>
                                            ) : (
                                              <div
                                                className="inline-flex items-center justify-center  p-2 "
                                                role="group"
                                              >
                                                <button
                                                  type="button"
                                                  className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500 "
                                                  onClick={() => {
                                                    //setIsExpanded(false);
                                                    setPaxId("");
                                                    setFlightId("");
                                                    setBaggageOpen(false);
                                                    //setSelectedMeal("");
                                                    setSelectedBaggage("");
                                                  }}
                                                >
                                                  {t("b2c-app.generals.cancel")}
                                                </button>
                                                <button
                                                  type="button"
                                                  className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400 "
                                                  onClick={() => {
                                                    //  setIsExpanded(false);
                                                    setConfirmbox(true);
                                                  }}
                                                >
                                                  {t("generals.add")}
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };
  const CheckinSelection = () => {
    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<any>();
    const [flightId, setFlightId] = useState<any>();
    const [filekey, setFilekey] = useState<any>();
    const [checkLoading, setCheckLoading] = useState(false);
    const [selectedCheckin, setSelectedCheckin] = useState<string>("");

    interface CheckinResponse {
      ssrList: string[];
    }

    const postSsrCheckin = async (
      key: string,
      filekey: string,
      selectedCheckin: string
    ): Promise<CheckinResponse> => {
      const url = confirmAirportCheckinSelection();
      const params = new URLSearchParams({
        bookingKey: key,
        filekey,
        ssrKey: selectedCheckin,
      });

      try {
        const response = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",


        });

        if (!response.ok) {
          const correlationID: any = response.headers.get("correlation-id");
          Swal.fire("Error Code: " + correlationID, "", "error");
        } else {
          Swal.fire("Success", "", "success");
        }

        const data = await response.json();
        return data as CheckinResponse;
      } catch (error) {
        console.error(error);
        Swal.fire("Error Code: unknown", "", "error");

        throw error;
      } finally {
        setCheckLoading(false);
        setIsCheckinOpen(false);
      }
    };

    const [checkinData, setCheckinData] = useState<any>();
    const [isloading, setisloading] = useState(true);

    const getssrDataCheckin = async () => {
      await fetcherWithoutJSON(
        getAirportCheckinSsr() +
          new URLSearchParams({
            bookingKey: key.toString(),
            filekey: filekey,
            flightId: flightId,
            paxId: paxId,
          }),
        {
          method: "GET",

          credentials: "include",
          cache: "no-cache",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong on api server!");
          }
        })
        .then((data) => {
          setCheckinData(data.ssrList);
        })
        .catch((error) => {
          Swal.fire(t("ssr.errorfrom-airline"), "", "error");
          console.log(error);
        })
        .finally(() => {
          setisloading(false);
        });
    };
    useEffect(() => {
      if (flightId && paxId && filekey) {
        getssrDataCheckin();
      }
    }, [isgetData, paxId, flightId]);



    if (isBaggageOpen === true) {
      window.scrollTo(0, 0);
    }

    function showTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-0", "invisible");
        tooltip.classList.add("opacity-100");
      }
    }

    function hideTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-100");
        tooltip.classList.add("opacity-0", "invisible");
      }
    }

    function handleClick(data: React.SetStateAction<string>) {
      setisActive(true);
      setisdisabled(true);
      setCheckinData(data);
    }

    const [isActive, setisActive] = useState(false);
    const [isDisabled, setisdisabled] = useState(false);
    const [confirmbox, setConfirmbox] = useState(false);

    return (
      <div>
        {isChekinOpen ? (
          <div>
            {isChekinOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                  <ButtonClose
                    onClick={() => setIsCheckinOpen(false)}
                  ></ButtonClose>

                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <AddTaskIcon /> <h2 className="p-2">Airport Check In</h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">3- Add Check In</h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-
                          Add Check In
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3- Add
                          Check In
                        </h2>
                      )}
                    </div>

                    <div className="">
                      {
                        <div className="w-full  p-2">
                          <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                            {pnrdata.map(
                              (pnr) =>
                                pnr.bookAirportCheckinAvailable &&
                                pnr.filekey === ssrFilekey && (
                                  <div key={pnr.id} className="  w-2/6 p-2  ">
                                    <h3 className=" font-bold">
                                      1- {t("flightbooking.flights")}
                                    </h3>

                                    {pnr.flightList?.map((flight) => (
                                      <div key={flight.id}>
                                        <button
                                          key={flight.id}
                                          className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                            flight.id === flightId
                                              ? "bg-gray-100"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setFlightId(flight.id);
                                            setPaxId("");
                                            setCheckinData(null)
                                          }}
                                        >
                                          <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                            {flightId === flight.id ? (
                                              <i
                                                className="fa fa-check
                                                "
                                                aria-hidden="true"
                                              ></i>
                                            ) : null}
                                            <p className="font-bold">
                                              {flight.flightNumber}
                                            </p>
                                            <p>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </p>
                                          </div>
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                )
                            )}
                            {pnrdata.map(
                              (pnr) =>
                                pnr.bookBaggageAvailable &&
                                pnr.filekey === ssrFilekey && (
                                  <div className=" flex w-1/6 items-stretch justify-center p-2  ">
                                    {flightId && (
                                      <div>
                                        <h3 className=" font-bold">
                                          2- {t("flightbooking.passengers")}
                                        </h3>
                                        <div>
                                          {pnr.bookBaggageAvailable &&
                                            pnr.filekey === ssrFilekey && (
                                              <div>
                                                {pnr.paxList.map((p) => (
                                                  <div key={p.id}>
                                                    <button
                                                      key={p.id}
                                                      className={`w-full  border-2 p-2 text-center hover:bg-gray-100 ${
                                                        p.id === paxId
                                                          ? "bg-gray-100"
                                                          : ""
                                                      }`}
                                                      onClick={() => {
                                                        setPaxId(p.id);
                                                        setFilekey(pnr.filekey);
                                                        //setIsgetData(!isgetData);
                                                        {
                                                        }
                                                      }}
                                                    >
                                                      <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                        {paxId === p.id ? (
                                                          <i
                                                            className="fa fa-check "
                                                            aria-hidden="true"
                                                          ></i>
                                                        ) : null}

                                                        <p className="">
                                                          {p.firstname}
                                                        </p>
                                                        <p className="font-bold">
                                                          {p.surname}
                                                        </p>
                                                      </div>
                                                    </button>
                                                  </div>
                                                ))}
                                              </div>
                                            )}{" "}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                            )}
                            <div className=" w-4/6 p-2 ">
                            { checkinData ?  checkinData.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}

                              {paxId &&
                              flightId &&
                              data1.ssrRecords.find(
                                (rcr: any) => rcr.recordType === "OCFE"
                              )?.paxId === paxId &&
                              data1.ssrRecords.find(
                                (rcr: any) => rcr.recordType === "OCFE"
                              )?.flightId === flightId ? (
                                <span>{t("ssr.already.selected")}</span>
                              ) : (
                                checkinData &&
                                paxId &&
                                flightId &&
                                !isloading && (
                                  <div className="relative flex justify-center p-2 ">
                                    <div className="relative">
                                      <div className=" rounded border shadow-md">
                                        <div className="p-8">
                                          <div className="border-1 text-md overflow-hidden text-center text-gray-900 ">
                                            <p className="font-bold">
                                              {checkinData.map(
                                                (meal: any) => meal.name
                                              )}{" "}
                                              -{" "}
                                              {checkinData.map(
                                                (meal: any) => meal.description
                                              )}
                                            </p>
                                            <p className="">
                                              {checkinData.map(
                                                (meal: any) => meal.price
                                              )}
                                              {checkinData.map(
                                                (meal: any) => meal.currencyCode
                                              )}
                                            </p>
                                          </div>
                                          {confirmbox && (
                                            <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                                              <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                                                <div className="flex w-full justify-between p-2 ">
                                                  <ButtonClose
                                                    onClick={() => {
                                                      setConfirmbox(false);
                                                      setSelectedCheckin("");
                                                      setPaxId("");
                                                      setFlightId("");
                                                    }}
                                                  ></ButtonClose>
                                                  {checkLoading && (
                                                    <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                                                  )}
                                                </div>
                                                <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                                                  <p className="font-bold">
                                                    {checkinData.map(
                                                      (meal: any) => meal.name
                                                    )}
                                                    {checkinData.map(
                                                      (meal: any) =>
                                                        meal.description
                                                    )}
                                                  </p>
                                                  <p className="">
                                                    {checkinData.map(
                                                      (meal: any) => meal.price
                                                    )}
                                                    {checkinData.map(
                                                      (meal: any) =>
                                                        meal.currencyCode
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="p-2">
                                                  <p className=" text-red-600">
                                                    {t(
                                                      "generals.attention-this-action-is-not-reversible"
                                                    )}
                                                  </p>
                                                  <p></p>
                                                  <p>
                                                    {" "}
                                                    {t(
                                                      "generals.are-you-sure-you-want-to-proceed"
                                                    )}
                                                  </p>
                                                  <div
                                                    className="inline-flex  p-2 "
                                                    role="group"
                                                  >
                                                    <button
                                                      type="button"
                                                      className="text-sm rounded-l-lg border border-gray-200 bg-red-500 px-4 py-2  font-medium text-white hover:bg-red-700 hover:text-white "
                                                      onClick={() => {
                                                        setConfirmbox(false);
                                                        setSelectedCheckin("");
                                                        setPaxId("");
                                                        setFlightId("");
                                                      }}
                                                    >
                                                      {t(
                                                        "b2c-app.generals.cancel"
                                                      )}
                                                    </button>
                                                    <button
                                                    id="confirmCheckIn"
                                                      type="button"
                                                      className="text-sm rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900  hover:text-gray-200"
                                                      onClick={() => {
                                                        const button = document.getElementById("confirmCheckIn") as HTMLButtonElement | null;
                                                        if (button) {
                                                          disableButton([button]);
                                                       }
                                                        setCheckLoading(true);
                                                        postSsrCheckin(
                                                          key,
                                                          filekey,
                                                          selectedCheckin
                                                        ); //post ssr func will come
                                                      }}
                                                      disabled={checkLoading}
                                                    >
                                                      {t("generals.yes")}
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          <div
                                            className="inline-flex items-center justify-center  p-2 "
                                            role="group"
                                          >
                                            <button
                                              type="button"
                                              className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500"
                                              onClick={() => {
                                                //setIsExpanded(false);
                                                setPaxId("");
                                                setFlightId("");
                                                setIsCheckinOpen(false);
                                                //setSelectedMeal("");
                                                setSelectedCheckin("");
                                              }}
                                            >
                                              {t("b2c-app.generals.cancel")}
                                            </button>
                                            <button
                                              type="button"
                                              className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400"
                                              onClick={() => {
                                                //  setIsExpanded(false);
                                                setSelectedCheckin(
                                                  checkinData.map(
                                                    (chk: any) => chk.ssrKey
                                                  )
                                                );
                                                setConfirmbox(true);
                                              }}
                                            >
                                              {t("generals.add")}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  const WheelChairSelection = () => {
    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<any>();
    const [flightId, setFlightId] = useState<any>();
    const [filekey, setFilekey] = useState<any>();
    const [wheelloading, setWheelLoading] = useState(false);
    const [selectedWheelChair, setSelectedWheelChair] = useState<string>("");
    const [isloading, setisloading] = useState(false);

    interface WheelChairResponse {
      ssrList: string[];
    }

    const postSsr = async (
      key: string,
      filekey: string,
      selectedWheelChair: string,
    ): Promise<void> => {
      const url = confirmWheelChairSelection();
      const params = new URLSearchParams();
      setisloading(true)
      params.append('bookingKey', key);
      params.append('filekey', filekey);

      if (isBookwchr) {
        params.append('ssrKey', selectedWheelChair);
      } else {
        params.append('paxId', paxId)
        params.append('flightId', flightId)
        params.append('productCode',selectedWheelChair)
      }

      try {
        const response = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",
          credentials: "include",
          cache: "no-cache",
        });

        if (!response.ok) {
          const correlationID = response.headers.get("correlation-id");
          Swal.fire("Error Code: " + correlationID, "", "error");
        } else {
            Swal.fire({
              title: "Success!",
              text: t("flight.ssr.wchr.requestsuccess"),
              icon: "success",
              confirmButtonText: t("b2c-app.flights.inactivity-window-button"),
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }


      } catch (error) {

        Swal.fire("Error Code: " + "null", "", "error");
        throw error;
      } finally {
        setWheelLoading(false);
        setisloading(false)
        setIsWheelchairopen(false);
      }
    };


    //post wheelchair api

    const [wheelChairData, setWheelChairData] = useState<any>();
    const [confirmbox, setConfirmbox] = useState(false);
    const [isBookwchr, setisBookwchr] = useState<boolean>(false);
    const getssrData = async () => {
      setisloading(true)
      await fetcherWithoutJSON(
        getWheelChairSsr() +
          new URLSearchParams({
            bookingKey: key.toString(),
            filekey: filekey,
            flightId: flightId,
            paxId: paxId,
          }),
        {
          method: "GET",

          credentials: "include",
          cache: "no-cache",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong on api server!");
          }
        })
        .then((data) => {
          setWheelChairData(data.ssrList);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(t("ssr.errorfrom-airline"), "", "error");
        })
        .finally(() => {
          setisloading(false);
        });
    };
    useEffect(() => {
      if (flightId && paxId && filekey && isBookwchr) {
        getssrData();
      }
    }, [isgetData, paxId, flightId]);

    const wheelchairdata1: any = wheelChairData;
    const handleWheelChairOptionSelect = (selectedValue: string) => {
      // Handle the selected option value here
      setSelectedWheelChair(selectedValue)
      console.log('Selected option value:', selectedWheelChair);
    };

    return (
      <div>
        {
          <div>
            {isWheelchairOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                  <ButtonClose
                    onClick={() => setIsWheelchairopen(false)}
                  ></ButtonClose>

                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <AccessibleIcon />{" "}
                      <h2 className="p-2">
                        {t("flightbooking.wheelchair-request")}
                      </h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">
                          3- {t("flightbooking.wheelchair-request")}
                        </h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.wheelchair-request")}
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.wheelchair-request")}
                        </h2>
                      )}
                    </div>

                    <div className="">
                      {
                        <div>
                          <div className="w-full  p-2">
                            <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                              {pnrdata.map(
                                (pnr) =>
                                  pnr.filekey === ssrFilekey && (
                                    <div className="  w-2/6 p-2  ">
                                      <h3 className=" font-bold">
                                        1- {t("flightbooking.flights")}
                                      </h3>

                                      {pnr.flightList?.map((flight) => (
                                        <div key={flight.id}>
                                          <button
                                            key={flight.id}
                                            className={`w-full  border-2 p-2 text-center hover:bg-gray-100   ${
                                              flight.id === flightId
                                                ? "bg-gray-100"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              setFlightId(flight.id);
                                              setisBookwchr(pnr.bookWheelChairAvailable)
                                              setPaxId("");
                                            }}
                                          >
                                            <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                              {flightId === flight.id ? (
                                                <i
                                                  className="fa fa-check text-blue-300"
                                                  aria-hidden="true"
                                                ></i>
                                              ) : null}
                                              <p className="font-bold">
                                                {flight.flightNumber}
                                              </p>
                                              <p>
                                                {flight.departurePort} -{" "}
                                                {flight.arrivalPort}
                                              </p>
                                            </div>
                                          </button>
                                        </div>
                                      ))}
                                    </div>
                                  )
                              )}
                              {pnrdata.map(
                                (pnr) =>

                                  pnr.filekey === ssrFilekey && (
                                    <div className=" flex w-1/6 items-stretch justify-center p-2  ">
                                      {flightId && (
                                        <div>
                                          <h3 className=" font-bold">
                                            2- {t("flightbooking.passengers")}
                                          </h3>
                                          <div>
                                            { (
                                              <div>
                                                {pnr.paxList.map((p) => (
                                                  <div key={p.id}>
                                                    <button
                                                      key={p.id}
                                                      className={`w-full  border-2 p-2 text-center hover:bg-gray-100   ${
                                                        p.id === paxId
                                                          ? "bg-gray-100"
                                                          : ""
                                                      }`}
                                                      onClick={() => {
                                                        setPaxId(p.id);
                                                        setFilekey(pnr.filekey);
                                                        //setIsgetData(!isgetData);
                                                        {
                                                        }
                                                      }}
                                                    >
                                                      <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                        {paxId === p.id ? (
                                                          <i
                                                            className="fa fa-check text-blue-300"
                                                            aria-hidden="true"
                                                          ></i>
                                                        ) : null}

                                                        <p className="">
                                                          {p.firstname}
                                                        </p>
                                                        <p className="font-bold">
                                                          {p.surname}
                                                        </p>
                                                      </div>
                                                    </button>
                                                  </div>
                                                ))}
                                              </div>
                                            )}{" "}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                              )}
                              <div className=" w-4/6 p-2 mx-1 ">
                              { wheelChairData ?  wheelChairData.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}
                                  {paxId && flightId && !isBookwchr  && <div className="grid grid-flow-col pt-3">
                                  <WheelChairOptions onSelect={handleWheelChairOptionSelect} />
                                     <button
                                     id="confirmWchr"
                                     className="p-2 bg-primary-6000 rounded hover:bg-primary-600 text-white"
                                    onClick={() => {
                                      const button = document.getElementById("confirmWchr") as HTMLButtonElement | null;
                                      if (button) {
                                        disableButton([button]);
                                     }
                                      postSsr(
                                        key,
                                        filekey,
                                        selectedWheelChair,

                                      ); //post ssr func will come
                                      setWheelLoading(
                                        true
                                      );

                                    }}
                                    disabled={paxId && flightId && data1.ssrRecords.some(
                                      (rcr: any) => rcr.recordType === "WHEELCHAIR" && rcr.paxId === paxId && rcr.flightId === flightId) }
                                    >
                              {t("flight.ssr.wchr.request")}
                                    {wheelloading && <>
                                    <br></br><CircularProgress style={{ width: 20, height: 20 }} color="info" /> </>}
                                    </button>
                                  </div>}
                                {paxId && flightId && data1.ssrRecords.some(
                                 (rcr: any) => rcr.recordType === "WHEELCHAIR" && rcr.paxId === paxId && rcr.flightId === flightId)
                                ? (
                                  <span>{t("ssr.already.selected")}</span>
                                ) : (
                                  wheelChairData &&
                                  paxId &&
                                  flightId &&
                                  !isloading && (
                                    <div className="relative flex justify-center p-2 ">
                                      <div className="relative">
                                        <select
                                          className="focus:shadow-outline w-full appearance-none rounded border bg-white py-2 pl-3 pr-8 leading-tight text-gray-700 focus:outline-none"
                                          value={selectedWheelChair}
                                          onChange={(e) =>
                                            setSelectedWheelChair(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            {t("generals.select")}
                                          </option>
                                          {!wheelchairdata1 ? (
                                            <div>
                                             {t("ssr.errorfrom-airline")}
                                            </div>
                                          ) : (
                                            wheelchairdata1.map((meal: any) => (
                                              <option
                                                key={meal.ssrKey}
                                                value={meal.ssrKey}
                                              >
                                                {meal.name} - {meal.description}
                                              </option>
                                            ))
                                          )}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                          <svg
                                            className="h-4 w-4 fill-current text-gray-500"
                                            viewBox="0 0 20 20"
                                          ></svg>
                                        </div>

                                        <div className=" rounded border shadow-md">
                                          {selectedWheelChair && (
                                            <div className="p-8">
                                              <div className="border-1 text-md overflow-hidden text-center text-gray-500 ">
                                                <p className="font-bold">
                                                  {
                                                    wheelchairdata1.find(
                                                      (meal: any) =>
                                                        meal.ssrKey ===
                                                        selectedWheelChair
                                                    )?.name
                                                  }
                                                </p>
                                                <p className="">
                                                  {
                                                    wheelchairdata1.find(
                                                      (meal: any) =>
                                                        meal.ssrKey ===
                                                        selectedWheelChair
                                                    )?.description
                                                  }
                                                </p>
                                                <p className="">
                                                  {
                                                    wheelchairdata1.find(
                                                      (meal: any) =>
                                                        meal.ssrKey ===
                                                        selectedWheelChair
                                                    )?.price
                                                  }{" "}
                                                </p>
                                              </div>
                                              {confirmbox && (
                                                <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                                                  <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                                                    <div className="flex w-full justify-between p-2 ">
                                                      <ButtonClose
                                                        onClick={() =>
                                                          setConfirmbox(false)
                                                        }
                                                      ></ButtonClose>
                                                      {wheelloading && (
                                                        <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                                                      )}
                                                    </div>
                                                    <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                                                      <p className="font-bold">
                                                        {
                                                          wheelchairdata1.find(
                                                            (meal: any) =>
                                                              meal.ssrKey ===
                                                              selectedWheelChair
                                                          )?.name
                                                        }
                                                      </p>
                                                      <p className="">
                                                        {
                                                          wheelchairdata1.find(
                                                            (meal: any) =>
                                                              meal.ssrKey ===
                                                              selectedWheelChair
                                                          )?.description
                                                        }
                                                        {t("flights.price")}:{" "}
                                                        {
                                                          wheelchairdata1.find(
                                                            (meal: any) =>
                                                              meal.ssrKey ===
                                                              selectedWheelChair
                                                          )?.price
                                                        }{" "}
                                                      </p>
                                                    </div>
                                                    <div className="p-2">
                                                      <p className=" text-red-600">
                                                        {t(
                                                          "generals.attention-this-action-is-not-reversible"
                                                        )}
                                                      </p>
                                                      <p></p>
                                                      <p>
                                                        {" "}
                                                        {t(
                                                          "generals.are-you-sure-you-want-to-proceed"
                                                        )}
                                                      </p>
                                                      <div
                                                        className="inline-flex  p-2 "
                                                        role="group"
                                                      >
                                                        <button
                                                          type="button"
                                                          className="text-sm rounded-l-lg border border-gray-200 bg-red-500 px-4 py-2  font-medium text-white hover:bg-red-700 hover:text-white"
                                                          onClick={() => {
                                                            setConfirmbox(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {t(
                                                            "b2c-app.generals.cancel"
                                                          )}
                                                        </button>
                                                        <button
                                                          id="confirmWchr"
                                                          type="button"
                                                          className="text-sm rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900  hover:text-gray-200 "
                                                          onClick={() => {
                                                            const button = document.getElementById("confirmWchr") as HTMLButtonElement | null;
                                                            if (button) {
                                                              disableButton([button]);
                                                           }
                                                            postSsr(

                                                              key,
                                                              filekey,
                                                              selectedWheelChair
                                                            ); //post ssr func will come
                                                            setWheelLoading(
                                                              true
                                                            );
                                                          }}
                                                          disabled={wheelloading}
                                                        >
                                                          {t("generals.yes")}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              {!selectedWheelChair ? (
                                                <div>
                                                  {" "}
                                                  {t(
                                                    "flightbooking.wheelchair-request"
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  className="inline-flex items-center justify-center  p-2 "
                                                  role="group"
                                                >
                                                  <button
                                                    type="button"
                                                    className="text-sm rounded-l-lg border border-gray-200 bg-red-500 px-4 py-2  font-medium text-white hover:bg-red-700 hover:text-white "
                                                    onClick={() => {
                                                      //setIsExpanded(false);
                                                      setPaxId("");
                                                      setFlightId("");
                                                      setIsWheelchairopen(
                                                        false
                                                      );
                                                      //setSelectedMeal("");
                                                      setSelectedWheelChair("");
                                                    }}
                                                  >
                                                    {t(
                                                      "b2c-app.generals.cancel"
                                                    )}
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-medium  text-gray-900 hover:text-gray-700 "
                                                    onClick={() => {
                                                      //  setIsExpanded(false);
                                                      setConfirmbox(true);
                                                    }}
                                                  >
                                                    {t("generals.add")}
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
         }
      </div>
    );
  };

  const SeatSelection = () => {
    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<string>("");
    const [flightId, setFlightId] = useState<string>("");
    const [filekey, setFilekey] = useState<string>("");
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    const [selectedSeat, setSelectedSeat] = useState<string>("");
    const [confirmbox, setConfirmbox] = useState(false);

    const [seatloading, setSeatloading] = useState(false);
    const postSsr = async (selectedSsr: PaxData) => {
      try {
        const response = await postFetcher(confirmSeatSelection(), selectedSsr);
        console.log(response);
        if (response.success) {
          Swal.fire({
            title: "Success!",
            text: t("flight.ssr.wchr.requestsuccess"),
            icon: "success",
            confirmButtonText: t("b2c-app.flights.inactivity-window-button"),
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          const correlationID = response.headers.get("correlation-id");
          const errorMessage = correlationID
            ? "Error Code: " + correlationID
            : "An error occurred";
          Swal.fire({
            title: "Error",
            icon: "error",
            text: errorMessage,
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "An error occurred",
        });
      } finally {
        setSeatloading(false);
        setConfirmbox(false);
      }
    };


    //post seat api

    type seatdata1 = [
      {
        seatKey: string;
        rowNumber: number;
        seats: any;
        seatStatus: string;
        column: string;
        currencyCode: string;
        price: number;
        seatTypes: any;
      }
    ];

    const [seatData, setseatData] = useState<seatdata1>();
    const [isloading, setisloading] = useState<boolean>(false);

    const getssrData = async () => {
      setisloading(true);
      await fetcherWithoutJSON(
        getSeatmapUrl() +
          new URLSearchParams({
            bookingKey: key.toString(),
            filekey: filekey,
            flightId: flightId,
            // paxId: paxId,
          }),
        {
          method: "GET",

          credentials: "include",
          cache: "no-cache",
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong on api server!");
          }
        })
        .then((data) => {
          setseatData(data.seatRows);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(t("ssr.errorfrom-airline"), "", "error");
        })
        .finally(() => {
          setisloading(false);
        });
    };
    useEffect(() => {
      if (flightId && filekey) {
        getssrData();
      }
    }, [flightId]);

    const seatdata1: any = seatData;

    if (isSeatOpen === true) {
      window.scrollTo(0, 0);
    }

    function showTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-0", "invisible");
        tooltip.classList.add("opacity-100");
      }
    }

    function hideTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-100");
        tooltip.classList.add("opacity-0", "invisible");
      }
    }

    interface PaxData {
      bookingKey: string;
      filekey: string;
      paxList: PaxItem[];
    }

    interface PaxItem {
      paxId: string;
      ssrKey: string;
    }

    const [selectedSsr, setSelectedSsr] = useState<PaxData | null>(null);

    function handleClick(ssrKey: string, paxId: string) {
      if (selectedSsr === null) {
        const newPaxItem: PaxItem = {
          paxId,
          ssrKey,
        };

        const newSelectedSsr: PaxData = {
          bookingKey: key, // set mainly as a bookingKey
          filekey: filekey,
          paxList: [newPaxItem],
        };

        setSelectedSsr(newSelectedSsr);
      } else {
        const existingIndex = selectedSsr.paxList.findIndex(
          (item) => item.paxId === paxId
        );

        if (existingIndex !== -1) {
          const updatedSelectedSsr: PaxData = {
            ...selectedSsr,
            paxList: selectedSsr.paxList.map((item, index) => {
              if (index === existingIndex) {
                return { ...item, ssrKey };
              }
              return item;
            }),
          };

          setSelectedSsr(updatedSelectedSsr);
        } else {
          const newPaxItem: PaxItem = {
            paxId,
            ssrKey,
          };

          const updatedSelectedSsr: PaxData = {
            ...selectedSsr,
            paxList: [...selectedSsr.paxList, newPaxItem],
          };

          setSelectedSsr(updatedSelectedSsr);
        }
      }
    }

    function deletePaxById(paxIdToDelete: string) {
      if (!selectedSsr) {
        return; // No selectedSsr to modify
      }

      const updatedPaxList = selectedSsr.paxList.filter(
        (item) => item.paxId !== paxIdToDelete
      );

      if (updatedPaxList.length === 0) {
        setSelectedSsr(null); // If there are no items left, set selectedSsr to null
      } else {
        const updatedSelectedSsr: PaxData = {
          ...selectedSsr,
          paxList: updatedPaxList,
        };
        setSelectedSsr(updatedSelectedSsr);
      }
    }



    return (
      <div>
        {isSeatOpen && (
          <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
            <div className="w-full rounded bg-white">
              {isloading && <LinearProgress />}
              <ButtonClose onClick={() => setIsSeatOpen(false)}></ButtonClose>
              <div className=" rounded bg-white ">
                <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                  <AirlineSeatReclineNormalIcon />
                  <h2 className="p-2">{t("flightbooking.seat")}</h2>
                </div>
                <div className="w-full  p-2">
                  <div className=" w-min-screen z-10  flex gap-12 border border-gray-300  bg-white">
                    {pnrdata.map(
                      (pnr) =>
                        pnr.bookSeatAvailable &&
                        pnr.filekey === ssrFilekey && (
                          <div key={pnr.filekey} className="  w-1/4 p-2  ">
                            <h3 className=" font-bold">
                              {t("flightbooking.flights")}
                            </h3>
                            {pnr.flightList?.map((flight) => (
                              <div className="mt-2" key={flight.id}>
                                <button
                                  key={flight.id}
                                  className={`w-full  border-2 p-2 text-center hover:bg-gray-100 ${
                                    flight.id === flightId ? "bg-gray-100" : ""
                                  }`}
                                  onClick={() => {
                                    setFlightId(flight.id);
                                    setPaxId(pnr.paxList[0].id);
                                    setFilekey(pnr.filekey);
                                  }}
                                >
                                  <div className="   text-sm flex items-center justify-between space-x-3 px-2 text-gray-900 ">
                                    {flightId === flight.id ? (
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                    <p className="space font-bold">
                                      {flight.flightNumber}
                                    </p>
                                    <p>
                                      {flight.departurePort} -{" "}
                                      {flight.arrivalPort}
                                    </p>
                                  </div>
                                </button>
                              </div>
                            ))}
                          </div>
                        )
                    )}
                    <div className=" w-3/4 p-2 ">
                      {flightId &&
                      data1.ssrRecords.find(
                        (rcr: any) => rcr.recordType === "SEAT"
                      )?.paxId === paxId &&
                      data1.ssrRecords.find(
                        (rcr: any) => rcr.recordType === "SEAT"
                      )?.flightId === flightId ? (
                        <span>{t("ssr.already.selected")}</span>
                      ) : (
                        flightId &&
                        !isloading &&
                        seatData && (
                          <div className="relative  flex justify-center ">
                            <div className="max-h-screen min-w-fit overflow-x-scroll">
                              <div className="mx-2 rounded-full border-l-8 border-r-8 border-blue-300 py-24">
                                {!seatData ? (
                                  <div>{t("ssr.errorfrom-airline")}</div>
                                ) : (
                                  seatData.map((seat: any) => (
                                    <div>
                                      {seat.seats[0].seatTypes.includes(
                                        "LEG_SPACE"
                                      ) ? (
                                        <div className="text-sm w-full  text-gray-400">
                                          {" "}
                                          {"<   EXIT   >"}
                                        </div>
                                      ) : null}
                                      <div
                                        key={seat.row}
                                        className=" flex justify-start p-0.5 "
                                      >
                                        {seat.seats.map((s: any) => (
                                          <div className="">
                                            <button
                                              className={`relative mx-0.5 flex h-7 w-7 items-center justify-center   rounded  border-2 object-cover  text-center 
                            ${s.column === "C" ? "mr-4" : ""}
                            ${
                              s.seatTypes.includes("WINDOW") ||
                              s.seatTypes.includes("AISLE")
                                ? "bg-blue-200"
                                : "bg-green-200"
                            } 
                            ${s.seatTypes.includes("LEG_SPACE") ? "h-9 " : ""} 
                            ${
                              selectedSsr?.paxList.some(
                                (p) => p.ssrKey === s.seatKey
                              )
                                ? "cursor-not-allowed bg-green-600"
                                : ""
                            }
                            ${
                              s.seatStatus == "FREE"
                                ? ""
                                : "!important  bg-gray-400"
                            } hover:bg-green-400`}
                                              onClick={() =>
                                                handleClick(s.seatKey, paxId)
                                              }
                                              disabled={s.seatStatus !== "FREE"}
                                              onMouseOver={(e) =>
                                                showTooltip(s.seatKey)
                                              }
                                              onMouseOut={(e) =>
                                                hideTooltip(s.seatKey)
                                              }
                                              key={s.seatKey}
                                            >
                                              {s.seatStatus === "FREE" ? (
                                                <div className="text-xs ">
                                                  {" "}
                                                  {seat.rowNumber}
                                                  {s.column}
                                                  {s.seatTypes.includes(
                                                    "LEG_SPACE"
                                                  ) ? (
                                                    <p>XL</p>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <div className="relative h-7  w-7  bg-gray-200">
                                                  <div className="absolute h-0.5 w-9   origin-left rotate-45 transform rounded-full bg-red-600"></div>
                                                </div>
                                              )}
                                            </button>
                                            <div
                                              id={`${s.seatKey}`}
                                              className="tooltip invisible -translate-x-1/2 transform rounded bg-blue-300  p-1 text-xs text-gray-900 opacity-0 transition duration-700"
                                            >
                                              {seat.rowNumber} {s.column}{" "}
                                              {s.name}
                                              <p>
                                                {s.price} {s.currencyCode}
                                              </p>
                                              <p> {s.seatTypes}</p>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                            <div className=" w-full flex-grow">
                              {pnrdata.map(
                                (pnr) =>
                                  pnr.bookSeatAvailable &&
                                  pnr.filekey === ssrFilekey && (
                                    <div>
                                      {flightId && (
                                        <div>
                                          <h3 className=" font-bold">
                                            {" "}
                                            {t("flightbooking.passengers")}
                                          </h3>
                                          <div>
                                            {pnr.bookSeatAvailable &&
                                              pnr.filekey === ssrFilekey && (
                                                <div className="px-2 ">
                                                  {pnr.paxList.map((p) => (
                                                    <div
                                                      className="mt-2 flex items-center space-x-2"
                                                      key={p.id}
                                                    >
                                                      <button
                                                        key={p.id}
                                                        className={`w-full border-2 p-2 text-center hover:bg-gray-200  ${
                                                          p.id === paxId
                                                            ? "bg-gray-100"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          setPaxId(p.id);
                                                        }}
                                                      >
                                                        <div className="  text-sm flex items-center justify-between space-x-3 px-2 text-gray-900 ">
                                                          <span>
                                                            {p.firstname}{" "}
                                                            {p.surname}
                                                          </span>
                                                          <span className="font-bold">
                                                            {seatData.map(
                                                              (seat: any) => (
                                                                <div
                                                                  key={
                                                                    seat.seatKey
                                                                  }
                                                                >
                                                                  {seat.seats
                                                                    .filter(
                                                                      (
                                                                        s: any
                                                                      ) =>
                                                                        selectedSsr?.paxList.some(
                                                                          (
                                                                            pax
                                                                          ) =>
                                                                            pax.ssrKey ===
                                                                              s.seatKey &&
                                                                            pax.paxId ===
                                                                              p.id
                                                                        )
                                                                    )
                                                                    .map(
                                                                      (
                                                                        s: any
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            s.seatKey
                                                                          }
                                                                        >
                                                                          <span>
                                                                            {
                                                                              seat.rowNumber
                                                                            }
                                                                            {
                                                                              s.column
                                                                            }
                                                                            <span className="px-2">
                                                                              {" "}
                                                                              {
                                                                                s.price
                                                                              }{" "}
                                                                              {
                                                                                s.currencyCode
                                                                              }
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                </div>
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      </button>
                                                      <div className="flex h-4 w-4 items-center justify-end">
                                                        {selectedSsr?.paxList.some(
                                                          (pax) =>
                                                            pax.paxId === p.id
                                                        ) && (
                                                          <ButtonClose
                                                            onClick={() =>
                                                              deletePaxById(
                                                                p.id
                                                              )
                                                            }
                                                          ></ButtonClose>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              )}{" "}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                              )}
                              <div className=" border-1 rounded border-gray-300  px-12 ">
                                {confirmbox && (
                                  <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                                    <div className="flex-grow rounded bg-white">
                                      <ButtonClose
                                        onClick={() => setConfirmbox(false)}
                                      ></ButtonClose>
                                      {seatloading && <LinearProgress />}
                                      <div className="p-2">
                                        <p className=" text-red-600">
                                          {t(
                                            "generals.attention-this-action-is-not-reversible"
                                          )}
                                        </p>
                                        <p>
                                          {" "}
                                          {t(
                                            "generals.are-you-sure-you-want-to-proceed"
                                          )}
                                        </p>
                                        <div
                                          className="inline-flex  p-2 "
                                          role="group"
                                        >
                                          <button
                                            type="button"
                                            className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500"
                                            onClick={() => {
                                              setConfirmbox(false);
                                            }}
                                          >
                                            {t("b2c-app.generals.cancel")}
                                          </button>
                                          <button
                                            id="confirmSeat"
                                            type="button"
                                            className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400 "
                                            onClick={() => {
                                              const button = document.getElementById("confirmSeat") as HTMLButtonElement | null;
                                              if (button) {
                                                disableButton([button]);
                                             }
                                              selectedSsr &&
                                                postSsr(selectedSsr); //post ssr func will come
                                              setSeatloading(true);
                                            }}
                                            disabled={seatloading}
                                          >
                                            {t("generals.yes")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="inline-flex items-center justify-center rounded-md p-2  shadow-sm "
                                  role="group"
                                >
                                  <button
                                    type="button"
                                    className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500
                                    "
                                    onClick={() => {
                                      setPaxId("");
                                      setFlightId("");
                                      setIsSeatOpen(false);
                                      setSelectedSeat("");
                                    }}
                                  >
                                    {t("b2c-app.generals.cancel")}
                                  </button>
                                  <button
                                    type="button"
                                    className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400"
                                    onClick={() => {
                                      setConfirmbox(true);
                                    }}
                                  >
                                    {t("generals.add")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const FooterButtons = () => {
    const handleReiseplan = (): void => {
      window.open(
        getViewBookingUrl(bookingKey!) +
        '.pdf',
        "_blank"
      );
    };

    const handleReiseplanImza = () => {
      window.open(
        getViewBookingUrl(bookingKey!)  +
          ".pdf?withSignature=true",
        "_blank"
      );
    };

    const handleReiseplanImzaNooption = () => {
      window.open(
        getViewBookingUrl(bookingKey!)  +
          ".pdf?showOptionDate=false",
        "_blank"
      );
    };
    const insuruancepdf = () => {
      window.open(
        getViewBookingUrl(bookingKey!)  +
          ".pdf",
        "_blank"
      );
    };

    const sendEmail = () => {
      const email = prompt("E-Mail");
      if (email === null) return;

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            alert("E-Mail'iniz başarılı bir şekilde gönderilmiştir!");
          } else {
            alert("E-Mail gönderilemedi!");
          }
        }
      };

      xhr.open(
        "POST",
        getViewBookingUrl(bookingKey!)+`/sendMail`,
        true
      );
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.send(`emailAdress=${email}`);
    };

    const handlemodal = () => {
      setModal(!modal);
      window.scrollTo(0, 0);
    };

    function cancelBooking(): void {
      const confirmation = window.confirm(
        t("generals.are-you-sure-you-want-to-proceed")
      );

      if (confirmation) {
        setLoading(true);
        const form = document.forms[0];
        form.action = baseUrl + "/agency/cancelBooking";
        form.addEventListener('submit', () => {
          setLoading(false);
        });
        form.submit();
      }
    }
    const handlePaxConnect = async () => {

      const url = getPaxConnectBooking() + bookingKey + "/paxconnect";

      try {
        setLoading(true);
        const response = await fetcherWithoutJSON(url);

        if (response.ok) {
          setLoading(false);
          const responseText = await response.text();
          console.log(responseText);
          window.open(responseText, "_blank");
        } else {
          setLoading(false);
          console.error("Request failed with status " + response.status);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };




    if (data1.status === "CANCELED") return null;


    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget as HTMLButtonElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
      <div>
        <div className="rounded bg-white py-2">



<div className="">
              {(data1.status === "TICKETED" ||
                data1.status === "RESERVATION" ||
                data1.status === "CONFIRMED") && (
                <div className="inline-flex rounded-md shadow-sm" role="group">
<div>
            <button
                className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900 hover:bg-gray-900"
                onClick={(event) => handleClick(event)}>
                {t("pdf.booking")}
                <i className="fa fa-chevron-down text-black" aria-hidden="true"></i>
            </button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleClose(); handleReiseplan(); }}>
                    {t("pdf.booking")}
                </MenuItem>
                {pnrdata.map((pnr) => (
                    pnr.status === "RESERVATION" ? (
                        <MenuItem onClick={() => { handleClose(); handleReiseplanImzaNooption(); }}>
                            {t("pdf.without-option-date")}
                        </MenuItem>
                    ) : null
                ))}
                <MenuItem onClick={() => { handleClose(); handleReiseplanImza(); }}>
                    {t("pdf.signature")}
                </MenuItem>
                <MenuItem
                className="items-center flex"
                onClick={sendEmail}>
                    {t("generals.email")}
                </MenuItem>
            </Menu>
        </div>
                   {data1.status === "RESERVATION" && (
                    <><button
                    id="cancelBooking"
                        type="button"
                        onClick={() => {
                          const button = document.getElementById("cancelBooking") as HTMLButtonElement | null;
                          if (button) {
                            disableButton([button]);
                         }
                          cancelBooking()}}
                        className="text-sm  border   border-red-500 bg-red-500 px-4  py-2 text-center font-medium text-white hover:shadow-lg hover:text-red-700 focus:z-10 focus:text-red-700 focus:ring-2 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                      >
                      <i className="fa fa-times text-white" aria-hidden="true"></i>{" "}
                        {t("booking.cancel")}
                      </button>
                   { product.paxConnectEnabled &&
                   <button
                   id="paxConnect"
                   className=" border p-1   border-gray-200" onClick={() =>{

                    const button = document.getElementById("paxConnect") as HTMLButtonElement | null;
                    if (button) {
                      disableButton([button]);
                   }
                    handlePaxConnect()}}>
                          <img
                            alt="paxconnect"
                            className="h-5"
                            src="https://www.paxconnect.de/img/logo_paxconnect.png" />{" "}
                        </button>}
                        <form>
                          <input
                            type="hidden"
                            name="bookingKey"
                            value={data1.bookingKey}
                          ></input>

                          <input
                            type="hidden"
                            name="filekey"
                            value={pnrdata.map((row) => row.filekey)}
                          ></input>
                        </form><button
                          type="button"
                          onClick={handlemodal}
                          className="text-sm rounded-r-lg border  border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900  hover:bg-gray-100 hover:text-blue-700 "
                        >
                          <i className="fa fa-credit-card" aria-hidden="true"></i>{" "}
                          {t("booking.purchase")}
                        </button></>
                  )}





                </div>
              )}
</div>

        </div>

        <div>
          {modal && (
            <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
              <div className="w-1-4 rounded bg-white p-2">
                <ButtonClose onClick={handlemodal}></ButtonClose>
                <h1>
                  {t(
                    "flightbooking.ticketing.please-select-your-preferred-payment-method"
                  )}
                </h1>
                <div className=" rounded-md p-2 shadow-sm">
                  <div className="w-full py-1 ">
                    <PaymentButton
                      path={getIssueBookingUrl()}
                      params={{
                        bookingKey: data1.bookingKey,
                        paymentType: "INVOICE",
                      }}
                    />
                  </div>
                  <div className="w-full py-1">
                    <PaymentButton
                      path={getIssueBookingWithStripeUrl()}
                      params={{
                        bookingKey: data1.bookingKey,
                        paymentType: "KLARNA",
                      }}
                    />
                  </div>
                  <div className="w-full py-1">
                    <PaymentButton
                      path={getIssueBookingWithStripeUrl()}
                      params={{
                        bookingKey: data1.bookingKey,
                        paymentType: "STRIPE",
                      }}
                    />
       { data1.airlineCreditCardAvailable && <div className="w-full py-1 px-2">
         <Accordion defaultExpanded={false}>
      <AccordionSummary
        aria-controls="payment-details-content"
        id="payment-details-header"
        sx={{ p: 0, borderBottom: 'none' }}
      >
      <Button
        variant="contained"
        color="primary"
        className="w-full"
      >Airline CC</Button>
      </AccordionSummary>
      <AccordionDetails>
        <CreditCardForm
        bookingKey={newData.bookingKey}
        totalAmount= {newData?.totalAmount}/>
      </AccordionDetails>
    </Accordion>
         </div>}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      {loading &&
      <><LinearProgress color="inherit" />
      <div className={`absolute top-0 left-0 z-10 h-full w-full`}>
        <div
          className={`flex h-full items-center justify-center backdrop-blur-sm`}
        >
          <Spinner className="text-primary-6000" />
        </div>
      </div></>}
      {!loading && (
        <div>


          <div className="container relative flex items-center justify-between space-x-4 py-4 xl:space-x-8">
            <div className="flex  bg-white w-full items-center justify-center pb-20 text-center sm:block sm:p-0">
              <div className="inline-block   h-full w-full transform transition-all">
             {  audit.marketingType === "B2B" ? (<div className="  ">
                   {!isApp && (<BackButton/>)}
                   {isAdmin && (
                  <ButtonPrimary
                    onClick={() => window.open(`/ibe#/booking/detail/test/${bookingKey}`, '_blank')}
                  >
                    Reissue Test
                  </ButtonPrimary>
                )}

                  {sectionAgency()}
                  {RemarksSection()}
                  {sectionFlight()}
                  {sectionCarrent()}
                  {sectionHotel()}
                  {sectionTransfer()}
                  {packageTour && <PackageTourDetail packageTour={packageTour}/>}
                  {userRole === "SYSTEM_EMPLOYEE" || userRole === "ADMIN" ? (
                    <SectionPayment data={{ payments: paymentInfo }} />
                  ) : null}
                  {FooterButtons()}
                </div>)
                :
                (<div className=" mt-2 ">
                  <BackButton/>

                  {sectionFlight()}
                  {sectionCarrent()}
                  {sectionHotel()}
                  {sectionTransfer()}
                  {packageTour && <PackageTourDetail packageTour={packageTour}/>}
                {newData?.status === "RESERVATION" &&
                <PaymentThreed
                 bookingKey={newData?.bookingKey}
                 totalPrice={ data1.totalPrice + data1.serviceFeePerPax * paxdata.length}
                 />}

                </div>)
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingDetail;
