import React, {FC, useState} from "react"
import useQueryParameters from "../../../hooks/useQueryParameters";

import 'primeicons/primeicons.css';
import {Dialog} from "primereact/dialog";
import {useTranslation} from "react-i18next";
import HeroSearchForm from "../HeroSearchForm";
import {FlightContent} from "./FlightContent";
import {RentalcarContent} from "./RentalcarContent";
import { IoIosSearch } from "react-icons/io";
import {HotelContent} from "./HotelContent";


interface ISectionHeroHeaderMobile {}

export const SectionHeroHeaderMobile: FC<ISectionHeroHeaderMobile> = () => {
    const { t } = useTranslation()
    const queryParameters = useQueryParameters()

    const [ openSearchModal, setOpenSearchModal ] = useState<boolean>(false)

    const openSearchModalHandler = () => {
        setOpenSearchModal(!openSearchModal)
    }

    const content = () => {
        switch (queryParameters?.searchType) {
            case 'Flights':
                return <FlightContent />
            case 'Car Rental':
                return <RentalcarContent />
            case 'Hotel':
                return <HotelContent />
            default:
                return (
                    <>Something went wrong!</>
                )
        }
    }

    return (
        <div className={`cursor-pointer w-full`}>
            <div className={`flex justify-between items-center w-full px-3`} onClick={openSearchModalHandler}>
                <div className={`pt-2`}>
                    {content()}
                </div>
                <div className={`pr-3`}>
                    <IoIosSearch color={'#5a606b'} size={'1.7rem'} onClick={openSearchModalHandler}/>
                </div>
            </div>

            <Dialog
                className={`searchedit`}
                header={t('general.search.edit')}
                visible={openSearchModal}
                position={'bottom'}
                style={{ width: '100%', margin: 0 }}
                onHide={openSearchModalHandler}
                draggable={false}
                resizable={false}
            >
                <HeroSearchForm />
            </Dialog>
        </div>
    )
}
