import Select from 'components/ui/Select/Select';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Option {
  value: string;
  label: string;
}

interface Props {
  onSelect: (selectedValue: string) => void;
}
const WheelChairOptions: React.FC<Props>  = ({onSelect}) => {
    const [selectedValue, setSelectedValue] = useState<string>('');
    const {t} = useTranslation()
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedValue(value);
        onSelect(value); // Invoke the callback function with the selected value
      };

  const options: Option[] = [
    { value: 'WCHR', label: 'WCHR, PASSENGER NEEDS WHEELCHAIR SERVICE TILL APRON' },
    { value: 'WCHS', label: 'WCHS ,PASSENGER NEEDS WHEELCHAIR SERVICE TILL THE STAIRS' },
    { value: 'WCHC', label: 'WCHC, PASSENGER NEEDS WHEELCHAIR SERVICE TILL THE CABIN' },
  ];

  return (
    <Select value={selectedValue} onChange={handleChange}>
    <option value="">{t("generals.select")}</option>
    {options.map(option => (
      <option key={option.value} value={option.value}>
       {option.label}
      </option>
    ))}
  </Select>
  )
};

export default WheelChairOptions;
