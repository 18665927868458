import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { AtrTextfield } from "./AtrTextfield";
import { t } from "i18next";
import { AtrNationalityDropdown } from "./AtrNationalityDropdown";
import { AtrCheckbox } from "./AtrCheckbox";
import Typography from "@material-ui/core/Typography";
import { PhoneCountryCode } from "./PhoneCountryCode";

export function AddressInformation(props) {
  return (
    <div className="AddressInformation">
      <div
        style={{ fontSize: "21px", fontWeight: "400", paddingBottom: "1.25vw" }}
      >
        {t("react.address.information")}
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <AtrTextfield
            isError={
              props.showValidation && props.addressInformation.street === ""
            }
            name="AddressInformation-street"
            helperText={
              props.showValidation && props.addressInformation.street === ""
                ? t("react.validation.requiredfield")
                : ""
            }
            label={t("homepage.register.address-input")}
            style={{ width: "100%" }}
            updateValue={(value) => {
              props.updateAddressInformation("street", value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AtrTextfield
            isError={
              props.showValidation && props.addressInformation.zip === ""
            }
            name="ContactInformation-phone"
            helperText={
              props.showValidation && props.addressInformation.zip === ""
                ? t("react.validation.requiredfield")
                : ""
            }
            label={t("homepage.register.zip_code")}
            style={{ width: "100%" }}
            updateValue={(value) => {
              props.updateAddressInformation("zip", value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <AtrTextfield
            isError={
              props.showValidation && props.addressInformation.city === ""
            }
            name="ContactInformation-phone"
            helperText={
              props.showValidation && props.addressInformation.city === ""
                ? t("react.validation.requiredfield")
                : ""
            }
            label={t("manage-agencies.city")}
            style={{ width: "100%" }}
            updateValue={(value) => {
              props.updateAddressInformation("city", value);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
