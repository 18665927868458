import fetcher from 'api/helpers/fetcher';
import { Customer, ProductSupplier, PaymentAccount } from '../types/invoice';

const BASE_URL = '/agency/mars';

export const fetchCustomers = async (): Promise<Customer[]> => {
  return fetcher(`${BASE_URL}/customer-select2`);
};

export const fetchProductSuppliers = async (): Promise<ProductSupplier[]> => {
  return fetcher(`${BASE_URL}/productSuppliers-select2`);
};

export const fetchPaymentAccounts = async (): Promise<PaymentAccount[]> => {
  return fetcher(`${BASE_URL}/paymentAccounts-select2`);
};

