import React from "react";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import {AtrDateFormat} from "../../calendar";

export function AtrSelectSingleDatePicker( props )
{
    const className = props.name + '-calendar';
    const classCalendar = '.' + props.name + '-calendar';
    const closeDatePicker = () =>
    {
        $($( classCalendar )[ 0 ]).css( "display", "none" );
    }

    const correctInputValue = ( value, index ) =>
    {
        let newValue = '';

        for( let i = 0; i < value.length; i++ )
        {
            if ( i === index )
            {
                newValue += '.';
            }

            newValue += value[ i ];
        }

        return newValue;
    }

    console.log('Updated: ' + props.defaultValue );

    return(
        <div>
            <TextField
                className = { props.name }
                error = { props.isError }
                helperText = { props.helperText }
                style = { props.style }
                name = { props.name }
                label = { props.label }
                value = { props.defaultValue }
                variant = 'outlined'
                onChange = { ( e) =>
                {
                    const re = /^[0-9.\b]+$/;

                    let inputValue = e.target.value;

                    if ( !inputValue.includes( '..' ) )
                    {
                        if ( inputValue.slice( -1 ) === '.'  )
                        {
                            if ( inputValue.length === 3 || inputValue.length === 6 )
                            {
                                $( e.target ).val( inputValue );
                            }
                        }
                        if (re.test(inputValue))
                        {
                            if (inputValue.length < 11)
                            {
                                if (inputValue.length === 2 || inputValue.length === 5)
                                {
                                    if ( inputValue.length === 2 && inputValue[ inputValue.length - 1 ] === '.' )
                                    {
                                        inputValue = '0' + inputValue;
                                    }
                                    else if ( inputValue.length === 5 && inputValue[ inputValue.length - 1 ] === '.' )
                                    {
                                        let tmpInputValue = inputValue.split( '.' )[ 0 ] + '.';

                                        if ( inputValue.split( '.' )[ 1 ] === '0' )
                                        {
                                            tmpInputValue += '01.';
                                        }
                                        else
                                        {
                                            tmpInputValue += '0' + inputValue.split( '.' )[ 1 ] + '.';
                                        }
                                        inputValue = tmpInputValue;
                                    }

                                    $( e.target ).val( inputValue );
                                }
                                else if (inputValue.length > 2)
                                {
                                    if ( ( inputValue.length === 3 ) && inputValue.slice( -1 ) !== '.' )
                                    {
                                        inputValue = correctInputValue( inputValue, 2 );
                                    }
                                    else if ( ( inputValue.length === 6 ) && inputValue.slice( -1 ) !== '.' )
                                    {
                                        inputValue = correctInputValue( inputValue, 5 );
                                    }

                                    let inputValueSplit = inputValue.split('.');

                                    if (parseInt(inputValueSplit[0]) > 31)
                                    {
                                        inputValueSplit[0] = '31';
                                        $( e.target ).val(inputValueSplit[0] + '.');
                                    }

                                    if (inputValueSplit.length > 1 && parseInt(inputValueSplit[1]) > 12)
                                    {
                                        inputValueSplit[1] = '12';
                                        $( e.target ).val(inputValueSplit[0] + '.' + inputValueSplit[1] + '.');
                                    }
                                    else
                                    {
                                        let tmpValue = '';
                                        for ( let i = 0; i < inputValueSplit.length; i++ )
                                        {
                                            tmpValue += inputValueSplit[ i ];
                                            if ( ( i+1 ) < inputValueSplit.length )
                                            {
                                                tmpValue += '.';
                                            }
                                        }
                                        $( e.target ).val( tmpValue );
                                    }
                                }
                            }
                            else
                            {
                                $( e.target ).val(inputValue.substring(0, 10));
                            }

                            if (inputValue.length === 10)
                            {
                                let inputValueSplit = inputValue.split('.');
                                props.updateValue( new Date(inputValueSplit[2] + '-' + inputValueSplit[1] + '-' + inputValueSplit[0]) );
                            }
                        }
                    }
                    else
                    {
                        $( e.target ).val( inputValue.substring(0, inputValue.length - 1) )
                    }
                }}
                onClick = { ( ) => {
                    if ( typeof $($( classCalendar )[ 0 ]).attr( "style" ) !== 'undefined' )
                    {
                        $($( classCalendar )[ 0 ]).removeAttr( "style" );
                    }
                    else
                    {
                        $($( classCalendar )[ 0 ]).css( "display", "none" );
                    }
                } }
            />
            <div className = { className } style = {{ display: "none" }} data-passengerValue = 'calendar'  >
                <OutsideClickHandler
                    onOutsideClick={ ( event ) =>
                    {
                        if ( typeof $($( event.target ).closest( classCalendar )[ 0 ]).attr( 'data-passengervalue' ) === 'undefined' )
                        {
                            closeDatePicker();
                        }
                    }}>

                    <Calendar
                        maxDate = { new Date() }
                        value = { new Date() }
                        onClickDay = {
                            ( value, event ) => {
                                let classString = '.' + props.name;
                                $( classString ).val( value )

                                closeDatePicker();
                                props.updateValue( value );
                            }
                        }
                    />
                </OutsideClickHandler>
            </div>
        </div>
    )
}