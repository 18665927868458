import React, { FC, Fragment, useState } from "react";
import { Card } from "primereact/card";
import { Tooltip, Radio } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Tag } from "primereact/tag";
import { useTranslation } from "react-i18next";
import useQueryParameters from "hooks/useQueryParameters";
import { nanoid } from "@reduxjs/toolkit";
import { useReduxReducer } from "redux/store";
import { FlightCardRow } from "pages/FlightSearchPage/FlightCardRow";
import { getSinglePrice } from "utils/calcTotalFlightPrice";
import { FlightDetailsModal } from "pages/FlightSearchPage/FlightDetailsModal";
import { FlightOffer } from "api/types";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@mui/icons-material/Info";
import currencyFormat from "utils/currencyFormat";

interface IRegularFlightCard {
  offer: FlightOffer;
  index: number;
  selectedOfferId: string;
  onCardSelect: (offerId: string) => void;
}

export const ReissueFlightCard: FC<IRegularFlightCard> = ({
  offer,
  index,
  selectedOfferId,
  onCardSelect,
}) => {
  const { serviceFee } = useReduxReducer((state) => state.flight);
  const { t } = useTranslation();
  const handleCardClick = (offerId: string) => {
    onCardSelect(offerId);
  };

  return (
    <>
     
     <div
        key={`rflightcard-${offer.offerId}`}
        id={`flightCard-${offer.offerId}`}
        className={offer.offerId === selectedOfferId ? "cursor-pointer hover:bg-slate-200 bg-gray-100 p-2 relative border" : 'border relative cursor-pointer hover:bg-slate-200 p-2'}
        onClick={() => handleCardClick(offer.offerId)}
      >
        <div className={`grid grid-cols-12 gap-0 px-4 mr-2`}>
          <div className={`col-span-1 col-start-1 self-center`}>
            <Radio
              checked={selectedOfferId === offer.offerId}
              onChange={() => handleCardClick(offer.offerId)}
              value={offer.offerId}
              name="flight-offer-radio"
              inputProps={{ "aria-label": offer.offerId }}
            />
          </div>
          <div className={`col-span-8 col-start-2`}>
            {offer.flightTrips.map((flightTrip, _index) => (
              <React.Fragment key={offer.offerId}>
                <FlightCardRow
                  flightTrip={flightTrip}
                  availableSeats={offer.availableSeats}
                  src={offer.src}
                />
                {_index + 1 < offer.flightTrips.length && (
                  <hr className="my-4 mx-20 sm:my-6" />
                )}
              </React.Fragment>
            ))}
          </div>
          <div className={`col-span-2 col-start-10 self-center`}>
            <div className="flex justify-end">
              {offer.contingentSale && (
                <Tag
                  className={"px-0.5"}
                  value={t("flights.contingent-sale")}
                  style={{
                    backgroundColor: "rgb(21, 109, 161)",
                    textAlign: "center",
                    fontSize: "8px",
                  }}
                />
              )}
              {offer.atrPrimeOffer && (
                <Tooltip className="" title={t("react.flight.atr-prime-html")}>
                  <span className="bg-red-500 text-white text-xs rounded-lg font-semibold p-0.5 px-1">
                    ATR Prime
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="flex font-semibold text-lg items-center">
              <span>{currencyFormat(offer.totalPrice)}</span>
            </div>
            
          </div>
          <Tooltip className="" title={  <div
                    className={`grid-col-12 mr-2 grid items-center p-2 overscroll-y-auto text-xs bg-white text-black`}
                  >
               
                    {offer.brandFeatureList && 
                       offer.brandFeatureList.map(
                        (feature, index) =>
                          (
                            <Fragment key={index}>
                             { feature.featureIncluded 
                              ? <CheckIcon className="col-span-1 col-start-1 mr-1 text-green-700" />
                              : <CloseIcon className="col-span-1 col-start-1 mr-1 text-red-700" />} 
                              <span className={`col-span-11 col-start-2`}>
                              {feature.quantity ??  feature.quantity}{" "}{t("flight.brand.featurelist." + feature.featureType.toLowerCase()) }
                               
                              </span>
                              <small className="col-span-11 col-start-2">{feature.featureSubtext}</small>
                            </Fragment>
                          )
                      )
                    }
              
                  </div>}>
     <div className="flex items-center font-semibold text-primary-6000 text-lg space-x-1 "><InfoIcon className="mr-1" />  <span className="text-md">{offer.brandName}</span></div>
       </Tooltip>
        </div>
      
         
      </div>
      
    </>
  );
};


