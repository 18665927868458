import React, { useState } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import useRecentSearches from "hooks/useRecentSearches";
import useSearchAutocomplete from "api/hooks/useGetAutocomplete";
import { ClockIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import Spinner from "components/ui/Spinner/Spinner";
import { getTourSearchSuggestedUrl } from "api/helpers/api-constants";
import fetcher from "api/helpers/fetcher";
import useGetSuggested from "api/hooks/useGetSuggestet";
import { SearchTab } from "api/types";
import { useTranslation } from "react-i18next";
import { useReduxReducer } from "redux/store";
import HotelIcon from '@mui/icons-material/Hotel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import LocationCityIcon from '@mui/icons-material/LocationCity';

export interface AutocompleteInputProps<T> {
  category?: string;
  defaultValue?: any;
  queryType?: SearchTab;
  localStorageToken: string;
  autocompleteValue: T | null;
  getUrl: (term: string) => string;
  setAutocompleteValue: React.Dispatch<React.SetStateAction<T | null>>;
  Icon?: React.ReactNode & React.FC<React.ComponentProps<"svg">>;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  onInputDone?: () => void;
  error?: boolean;
  setDateFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  focus?: boolean;
  setFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  searchType?: string;
  blockBorder?: boolean;
}

function AutocompleteInput<
  T extends {
    id?: string;
    text?: string;
    label?: string;
    subLabel? :string;
    key?: string;
    title?: string;
    subTitle?: string;
    type?: string;
    blockBorder?: boolean;
  }
>({
  category,
  defaultValue,
  autoFocus = false,
  getUrl,
  autocompleteValue,
  localStorageToken,
  setAutocompleteValue,
  Icon = LocationMarkerIcon,
  placeHolder = "Airport",
  desc = "Leaving from",
  className = "nc-flex-1.5",
  onInputDone = () => {},
  error = false,
  setDateFocus = () => {},
  focus = false,
  setFocus = () => {},
  searchType,
  blockBorder,
}: AutocompleteInputProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const{ isAdmin } = useReduxReducer(state => state.general)
  const [showPopover, setShowPopover] = useState(autoFocus);

  const { query, setQuery, result } = useSearchAutocomplete<T[]>(
    autocompleteValue?.text || "",
    getUrl
  );
  const { suggested } = useGetSuggested<T[]>();
  const { t } = useTranslation();
  const { recentSearches } = useRecentSearches<T>(localStorageToken);
console.log(suggested)
  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (focus) {
      inputRef.current?.focus();
      setShowPopover(true);
      setAutocompleteValue(null);
    }
  }, [focus]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
    setFocus(false);
  };

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  const handlePopover = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setQuery("");
      inputRef.current.focus();
      setShowPopover(true);
    }
  };

  useEffect(() => {
    if (!showPopover) {
      if (!autocompleteValue) {
        setQuery("");
      } else {
        setQuery(autocompleteValue.text || autocompleteValue.label || "");
      }
    }
  }, [showPopover, autocompleteValue]);

  useEffect(() => {
    if (!showPopover && inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.value =
          autocompleteValue?.text || autocompleteValue?.label || "";
      }
    }
  }, [showPopover, autocompleteValue]);

  const handleSelectLocation = (item: T) => {
    setAutocompleteValue(item);
    onInputDone && onInputDone();
    setShowPopover(false);
    setDateFocus(true);
    setFocus(false);
  };
  const handleSelectLocationPackageTour = (item: any) => {
    setAutocompleteValue(item);
    onInputDone && onInputDone();
    setShowPopover(false);
    setDateFocus(true);
    setFocus(false);
  };

  const renderSuggestedSearches = () => {
    return (
      <div className="absolute left-0 top-full z-40 max-h-96 w-full min-w-[280px] overflow-y-auto  bg-white py-3 dark:bg-neutral-800 sm:min-w-[220px] sm:py-6">
        <h3 className="text-base sm:text-lg mt-2 block px-4 font-semibold text-neutral-800 dark:text-neutral-100 sm:mt-0 sm:px-8">
          {t("app.suggestions")}
        </h3>
        <div className="mt-2">
          <div>
            {suggested?.length && suggested.length > 0 ? (
              suggested.slice(0, 20).map((item) => (
                <span
                  onClick={() => handleSelectLocation(item)}
                  key={item.id}
                  className="flex cursor-pointer items-center space-x-2 rounded border px-2 py-2 shadow-sm hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
                >
                  <span className="block text-neutral-400">
                    <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                  </span>
                  <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                    {item.text || item.label}
                  </span>
                </span>
              ))
            ) : (
              <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
                {t("app.no-results")}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="text-base sm:text-lg mt-2 block px-4 font-semibold text-neutral-800 dark:text-neutral-100 sm:mt-0 sm:px-8">
          {t("app.recent-searches")}
        </h3>
        <div className="mt-2">
          {recentSearches.length > 0 ? (
            recentSearches.map((recentSearch) => (
              <span
                onClick={() => handleSelectLocation(recentSearch)}
                key={recentSearch.id}
                className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
              >
                <span className="block text-neutral-400">
                  <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                </span>
                <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                  {recentSearch.text || recentSearch.label}
                </span>
              </span>
            ))
          ) : (
            <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
              {category === "tour" ? (
                <>
                  <span
                    onClick={() => handleSelectLocation(defaultValue)}
                    key={defaultValue.id}
                    className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
                  >
                    <span className="block text-neutral-400">
                      <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                    </span>
                    <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                      {defaultValue.text || defaultValue.label}
                    </span>
                  </span>
                  <span className="py-4 px-4">
                    For more locations, enter name of the city, region or tour
                  </span>
                </>
              ) : (
                <>{t("app.no-results")}</>
              )}
            </span>
          )}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    return (
      <div className="absolute left-0 top-full z-40 max-h-96 w-full min-w-[280px] overflow-y-auto rounded border bg-white py-3 shadow-sm dark:bg-neutral-800 sm:py-6">
        {result?.length && result.length > 0 ? (
          result.map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.id}
              className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
            >
              <span className="block text-neutral-400">
                <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.text || item.label}
              </span>
            </span>
          ))
        ) : (
          <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
            {t("app.no-results")}
          </span>
        )}
      </div>
    );
  };
  const renderPackageTourValue = () => {
    return (
      <div className="absolute left-0 top-full z-40 max-h-96 w-full min-w-[280px] overflow-y-auto rounded border bg-white py-3 shadow-sm dark:bg-neutral-800 sm:py-6">
        {result?.length && result.length > 0 ? (
          result.map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.key}
              className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
            >
             <span className="block text-neutral-400 mr-2">
                {item.type === "CITY" &&  <LocationCityIcon className="h-4 w-4 sm:h-6 sm:w-6 text-primary-6000" />}
                {item.type === "HOTEL" &&  <HotelIcon  className="h-4 w-4 sm:h-6 sm:w-6 text-primary-6000" />}
                {item.type === "REGION" &&  <LocationMarkerIcon  className="h-4 w-4 sm:h-6 sm:w-6 text-primary-6000" />}


              </span>
              <span className="block font-medium text-gray-900 dark:text-neutral-200">
                {item.title}  
                <p className=" text-sm">{item.subTitle}</p>
              
              </span>
            </span>
          ))
        ) : (
          <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
            {t("app.no-results")}
          </span>
        )}
      </div>
    );
  };
  const renderHotelValue = () => {
    return (
      <div className="absolute left-0 top-full z-40 max-h-[600px] w-full min-w-[480px]  overflow-y-auto rounded border bg-white py-3 shadow-sm dark:bg-neutral-800 sm:py-6">
        {result?.length && result.length > 0 ? (
          result.map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.id}
              className="flex cursor-pointer items-center space-x-2 px-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 py-2"
            >
              <span className="block text-neutral-400 mr-2">
                {item.type === "CITY" &&  <LocationOnIcon className="h-4 w-4 sm:h-6 sm:w-6 text-primary-6000" />}
                {item.type === "HOTEL" &&  <HotelIcon  className="h-4 w-4 sm:h-6 sm:w-6 text-primary-6000" />}

              </span>
              <span className="block font-medium text-gray-900 dark:text-neutral-200">
                {item.label}  
                <p className=" text-sm">{item.subLabel}</p>
              
              </span>
            </span>
          ))
        ) : (
          <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
            {t("app.no-results")}
          </span>
        )}
      </div>
    );
  };
  const errorClassName = error ? "text-red-400" : "";

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => handlePopover()}
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none  `}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <Icon className="nc-icon-field" />
        </div>
        <div className={` ${blockBorder ? "border-right" : ""} flex-grow`}>
          <input
            className={`xl:text-base block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200`}
            placeholder={placeHolder}
            value={query}
            autoFocus={showPopover}
            onChange={(e) => {
              setAutocompleteValue((prevAutocomplete: any) => prevAutocomplete);
              setQuery(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span
            className={
              "-mt-1 block text-sm font-medium text-neutral-400 " +
              errorClassName
            }
          >
            {!!query ? placeHolder : desc}
          </span>
          {query && showPopover && (
            <ClearDataButton
              onClick={() => {
                setQuery("");
                setAutocompleteValue(null);
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="">
          {query
            ? searchType === "PackageTour"
              ? renderPackageTourValue()
                : searchType === ("HOTEL" || "VILLA")
                ? renderHotelValue()
              : renderSearchValue()
            : category === "tour"
            ? renderSuggestedSearches()
            : null}
        </div>
      )}
    </div>
  );
}

export default AutocompleteInput;

function useSWRImmutable<T>(arg0: string | null, fetcher: any): { data: any } {
  throw new Error("Function not implemented.");
}
