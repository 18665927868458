import React, { useState } from "react";
import { FC } from "react";
import moment from "moment";
import GuestsInput, { RoomInput } from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import NationalityInput from "../NationalityInput";
import SubmitButton from "../SubmitButton";
import {
  AirportSearchAutocompleteResult,
  HotelSearchAutocompleteResult,
  SearchPayload,
} from "api/types";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { getHotelPageUrl } from "utils/navigationLinks";
import useRecentSearches from "hooks/useRecentSearches";
import { MdFlightLand, MdFlightTakeoff } from "react-icons/md";
import HotelAndFlightQueryParametersService from "services/HotelAndFlightQueryParametersService";
import AutocompleteInput from "../AutocompleteInput";
import { getAirportAutocompleteUrl } from "api/helpers/api-constants";

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface TimeRage {
  startTime: string;
  endTime: string;
}

export interface FlightsAndHotelSearchFormProps {
  queryParameters: SearchPayload | null;
}

const FlightsAndHotelSearchForm: FC<FlightsAndHotelSearchFormProps> = ({
  queryParameters,
}) => {
 // const { appendRecentSearch } = useRecentSearches("flight-hotel-rc");

  const [dateRangeValue, setDateRangeValue] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const [locationDateRange, setLocationDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const [onlyNeedAccomodation, setOnlyNeedAccomodation] = useState(false);
  const [airportAutocompleteValue, setAirportAutocompleteValue] =
    useState<AirportSearchAutocompleteResult | null>(null);

  const [guests, setGuests] = useState<RoomInput[]>([
    { adults: 2, children: [] },
  ]);

  const [locationInputValue, setLocationInputValue] =
    useState<HotelSearchAutocompleteResult | null>(null);

  const navigate = useNavigate();

  const [nationality, setNationality] = useState("DE");

  const [missingInputError, setMissinInputError] = useState({
    nationality: false,
    airportFrom: false,
    locationTo: false,
    departingDate: false,
    returningDate: false,
    locationCheckIn: false,
    locationCheckOut: false,
  });

  React.useEffect(() => {
    if (queryParameters?.searchType === "Hotel & Flights") {
      if (
        queryParameters?.destinationId &&
        queryParameters?.label &&
        queryParameters?.destinationType
      ) {
        setLocationInputValue({
          id: queryParameters?.destinationId,
          label: queryParameters?.label,
          type: queryParameters?.destinationType,
        });
      }

      const nationality = queryParameters?.nationality;

      if (nationality) {
        setNationality(nationality);
      }

      const roomOccupancies = queryParameters?.roomOccupancies;

      if (roomOccupancies) {
        setGuests(roomOccupancies);
      }

      const originDestinationInformation =
        queryParameters?.flightSearch?.originDestinationInformation;

      if (originDestinationInformation) {
        const departing = originDestinationInformation[0];
        const returning = originDestinationInformation[1];

        setAirportAutocompleteValue({
          id: departing.origin!,
          text: departing.originText!,
        });

        setDateRangeValue({
          startDate: moment(departing.date!),
          endDate: moment(returning.date!),
        });

        const checkinDateString = queryParameters?.checkinDate;
        const checkoutDateString = queryParameters?.checkoutDate;

        if (checkinDateString && checkoutDateString) {
          setLocationDateRange({
            startDate: moment(checkinDateString),
            endDate: moment(checkoutDateString),
          });

          setOnlyNeedAccomodation(
            !(
              checkinDateString === departing.date! &&
              checkoutDateString === returning.date!
            )
          );
        }
      }
    }
  }, [JSON.stringify(queryParameters)]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    setMissinInputError({
      nationality: !nationality,
      airportFrom: !airportAutocompleteValue,
      locationTo: !locationInputValue,
      departingDate: !dateRangeValue.startDate,
      returningDate: !dateRangeValue.endDate,
      locationCheckIn: onlyNeedAccomodation && !locationDateRange.startDate,
      locationCheckOut: onlyNeedAccomodation && !locationDateRange.endDate,
    });

    const urlSearchParams = HotelAndFlightQueryParametersService.encode(
      nationality,
      airportAutocompleteValue,
      locationInputValue,
      dateRangeValue,
      guests,
      onlyNeedAccomodation,
      locationDateRange
    );

    if (urlSearchParams) {
    //  appendRecentSearch(locationInputValue);
      if (locationInputValue!.type === "HOTEL") {
        navigate(
          getHotelPageUrl(locationInputValue!.id) + "?" + urlSearchParams
        );
      } else {
        navigate("/search?" + urlSearchParams);
      }
    }
  }

  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit}
        className="relative mt-8 w-full rounded-3xl bg-white shadow-xl dark:bg-neutral-900 dark:shadow-2xl"
      >
        <div className="flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-800">
          <NationalityInput value={nationality} setValue={setNationality} />
          <GuestsInput
            guestValue={guests}
            setGuestValue={setGuests}
            subtitle={false}
            labelClassName="!text-base !font-medium"
            iconClassName="w-5 h-5"
          />
        </div>
        <div className=" [ nc-divide-field ] flex w-full flex-col rounded-full md:flex-row md:items-center ">
          <AutocompleteInput
            placeHolder="Airport"
            desc="Leaving from"
            getUrl={getAirportAutocompleteUrl}
            localStorageToken="hotel-flight-airport-departure"
            autocompleteValue={airportAutocompleteValue}
            setAutocompleteValue={setAirportAutocompleteValue}
            error={missingInputError.airportFrom}
          />

          <AutocompleteInput
            placeHolder="Location"
            desc="Where are you going?"
            getUrl={getAirportAutocompleteUrl}
            localStorageToken="hotel-and-flight-search-form-airport-arrival"
            autocompleteValue={locationInputValue}
            setAutocompleteValue={setLocationInputValue}
            error={missingInputError.airportFrom}
          />

          <DatesRangeInput
            wrapClassName="sm:!flex-row"
            defaultValue={dateRangeValue}
            onChange={(data) => {
              setDateRangeValue(data);
              setLocationDateRange({ startDate: null, endDate: null });
            }}
            prRanges={false}
            checkinLabel="Departing"
            checkoutLabel="Returning"
            checkinDateError={missingInputError.departingDate}
            checkoutDateError={missingInputError.returningDate}
            IconCheckin={MdFlightTakeoff}
            IconCheckout={MdFlightLand}
            value={dateRangeValue}
          />
          {!onlyNeedAccomodation && (
            <div className="flex justify-end p-4">
              <SubmitButton />
            </div>
          )}
        </div>
        <div className="[ nc-divide-field ] w-full grid-cols-4 rounded-full md:grid md:items-center ">
          <div className="col-span-3"></div>
        </div>
        {onlyNeedAccomodation && (
          <div className="[ nc-divide-field ] w-full grid-cols-4 rounded-full md:grid md:items-center ">
            <div className="col-span-3">
              <DatesRangeInput
                prRanges={false}
                wrapClassName="sm:!flex-row"
                defaultValue={locationDateRange}
                minDate={dateRangeValue.startDate}
                maxDate={dateRangeValue.endDate}
                onChange={(data) => setLocationDateRange(data)}
                checkinDateError={missingInputError.locationCheckIn}
                checkoutDateError={missingInputError.locationCheckOut}
                value={locationDateRange}
              />
            </div>

            <div className="flex justify-end p-4">
              <SubmitButton />
            </div>
          </div>
        )}
        <div className="flex flex-row flex-wrap border-t border-neutral-100 px-6 py-4 dark:border-neutral-800">
          <Checkbox
            labelClassName="!text-sm !text-neutral-400"
            inputClassName="!w-5 !h-5"
            className="ml-4"
            name="test"
            label="I only need accommodation for part of my trip"
            checked={onlyNeedAccomodation}
            onChange={() => setOnlyNeedAccomodation((prev) => !prev)}
          />
        </div>
      </form>
    </div>
  );
};

export default FlightsAndHotelSearchForm;
