import React, { useEffect } from 'react';

interface PaymentResponseProps {
  pageThreeD: any;
}

const PaymentResponseRender: React.FC<PaymentResponseProps> = ({ pageThreeD }) => {
  useEffect(() => {
    const submitThreedForm = () => {
      const threedForm = document.forms.namedItem('ThreedForm') as HTMLFormElement | null;

      if (threedForm) {
        threedForm.submit();
      }
    };
    const timeoutId = setTimeout(submitThreedForm, 50);

    return () => clearTimeout(timeoutId);
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: pageThreeD.pageThreeD }} />;
};

export default PaymentResponseRender;
