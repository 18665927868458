import { FormEvent } from "react";
import { useTranslation } from "react-i18next";


const SubmitButton = () => {

const { t } = useTranslation();

  return (
    <button
      type="submit"
      className="flex h-12 w-full items-center justify-center rounded-lg bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none md:h-12 md:w-16"
    >
      <span className="mr-3 md:hidden">{t("app.search")}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </button>
  );
};

export default SubmitButton;
