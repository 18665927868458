import React, {FC, ReactNode, useEffect, useRef, useState} from 'react'
import {MenuItem} from "primereact/menuitem";
import {Toast} from "primereact/toast";
import {SplitButton} from "primereact/splitbutton";
import {useTranslation} from "react-i18next";
import {useReduxReducer} from "../../../redux/store";
import {
    getCancelPnrURL,
    getDeletePnrURL,
    postRescanPnr,
    postRescanPnrtakeReservation
} from "../../../api/helpers/api-constants";
import postFetcher from "../../../api/helpers/postFetcher";
import {Dialog} from "primereact/dialog";
import {IRescanPNR, IRescanPnrTakeReservation} from "../../../api/types";
import currencyFormat from "../../../utils/currencyFormat";
import {BookingActionButtonDialogContent} from "./BookingActionButtonDialogContent";
import {Button} from "primereact/button";
import {ProgressBar} from "primereact/progressbar";
import './BookingDetail.css'

interface IBookingActionButtons {
    status: string
    rescanAvailable: boolean
    bookingKey: string
    fileKey: string
}

export const BookingActionButtons: FC<IBookingActionButtons> = ({status, rescanAvailable, bookingKey, fileKey}) => {
    const { t } = useTranslation()
    const { audit, product }= useReduxReducer(state => state.config)

    const toast = useRef<Toast>(null);

    const [ items, setItems ] = useState<MenuItem[]>([])
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ openModal, setOpenModal ] = useState<boolean>(false)
    const [ modalHeader, setModalHeader ] = useState<string>('')
    const [ modalFooterContent, setModalFooterContent ] = useState<ReactNode|null>(null)
    const [ rescanPNR, setRescanPNR ] = useState<IRescanPNR|null>(null)

    const deletePnrBtn: string[] = ['CANCELED', 'EXPIRED', 'VOIDED', 'REFUNDED']
    const deleteMenuItem: MenuItem = {
        label: t('generals.delete'),
        icon: 'pi pi-times',
        command: () => {
            sendAction(getDeletePnrURL(fileKey, bookingKey))
        }
    }

    const createNewReservationBtn: string[] = ['CANCELED', 'EXPIRED']
    const createNewReservationMenuItem: MenuItem = {
        label: t('flightbooking.options.create-new-reservation'),
        icon: 'pi pi-plus',
        command: () => {
            newReservationRequest()
        }
    }

    const reservationStatus: string[] = ['RESERVATION']
    const reservationMenuItem: MenuItem = {
        label: t("app.booking.cancel-pnr"),
        icon: 'pi pi-trash',
        command: () => {
            setIsLoading(true)
            openModalHandler()
            sendAction(getCancelPnrURL(fileKey, bookingKey))
            setIsLoading(false)
            window.location.reload()
        }
    }

    const refundBtn: string[] = ['TICKETED']
    const refundMenuItem: MenuItem = {
        label: t("flightcases.refund-request"),
        icon: 'pi pi-times',
        command: () => {
            console.log('clicked item')
        }
    }

    // const tmp1: MenuItem[] = [
    //     {
    //         label: t('general.refresh'),
    //         icon: 'pi pi-refresh',
    //         command: () => {
    //             toast.current!.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated' });
    //         }
    //     },
    //     {
    //         label: t('general.change.pnr'),
    //         icon: 'pi pi-arrow-right-arrow-left',
    //         command: () => {
    //             toast.current!.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' });
    //         }
    //     }
    //
    // ];

    useEffect(() => {
        const _tmpItems: MenuItem[] = []

        if (deletePnrBtn.includes(status) && audit.marketingType === "B2B")
            _tmpItems.push(deleteMenuItem)

        if (createNewReservationBtn.includes(status) && audit.marketingType === "B2B" && rescanAvailable)
            _tmpItems.push(createNewReservationMenuItem)

        if (reservationStatus.includes(status) && audit.marketingType === "B2B")
            _tmpItems.push(reservationMenuItem)

        if (refundBtn.includes(status) && audit.marketingType === "B2B")
            _tmpItems.push(refundMenuItem)

        setItems(_tmpItems)
    }, [status])

    useEffect(() => {
        if (!openModal) {
            setModalHeader('')
            setModalFooterContent(null)
        }
    }, [openModal])

    const newReservationRequest = async () => {
        setIsLoading(true)
        openModalHandler()
        const responseRescanPnr = await postFetcher(postRescanPnr(), {bookingKey: bookingKey, filekey: fileKey}) as IRescanPNR

        if (responseRescanPnr.hasOwnProperty('newPrice')) {
            const _rescanPNR: IRescanPNR = { ...responseRescanPnr, diffPrice: responseRescanPnr.newPrice - responseRescanPnr.oldPrice}
            setRescanPNR(_rescanPNR)
            setModalHeader(t('flightbooking.options.query-current-price'))
            setModalFooterContent(
                <button
                    className="flex h-10 w-full items-center justify-center rounded-lg bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none font-semibold"
                    onClick={() => rescanPnrTakeReservationAction(responseRescanPnr)}
                >{t('react.generel.reserve')}</button>
            )
        }
        setIsLoading(false)
    }

    const rescanPnrTakeReservationAction = async (_rescanPNR: IRescanPNR) => {
        setIsLoading(true)
        const responseRescanPnrTakeReservation: IRescanPnrTakeReservation = await postFetcher(postRescanPnrtakeReservation(), {
            bookingKey: bookingKey,
            filekey: fileKey,
            depFlightRecommendation: _rescanPNR.depFlightRecommendation,
            retFlightRecommendation: _rescanPNR.retFlightRecommendation
        }) as IRescanPnrTakeReservation

        if (responseRescanPnrTakeReservation.status === 'successful') {
            setModalHeader(t('flightbooking.options.query-current-price.new-reservation-created'))
            setRescanPNR({ ...rescanPNR!, newBookingKey: responseRescanPnrTakeReservation.newBookingKey })
            setModalFooterContent(
                <button
                    className="flex h-10 w-full items-center justify-center rounded-lg bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none font-semibold"
                    onClick={() => {
                        window.location.hash = '/app/booking/detail/' + responseRescanPnrTakeReservation.newBookingKey
                        openModalHandler()
                    }}
                >{t('general.new-bookingkey')}: {responseRescanPnrTakeReservation.newBookingKey}</button>
            )
        }

        setIsLoading(false)
    }

    const sendAction = async (url: string) => {
        const response = await fetch(url, {
            method: 'POST',
           
        })

        console.log(response.status)
        console.log(response)
    }

    const openModalHandler = () => {
        setOpenModal(!openModal)
    }

    return (
        <>
            {
                items && (
                    <>
                        <Toast ref={toast}></Toast>
                        <div className={`flex justify-between mb-4`}>
                            <div className={`text-xl font-semibold`}>{t('react.flight.label.flight')}</div>
                            <SplitButton model={items} severity="secondary" dropdownIcon={'pi pi-ellipsis-v'} text />
                        </div>
                    </>
                )
            }
            <Dialog
                className={'bookingdetail'}
                header={modalHeader}
                showHeader={!isLoading}
                visible={openModal}
                footer={isLoading ? undefined : modalFooterContent}
                position={'bottom'}
                style={{ width: '100%', margin: 0 }}
                onHide={openModalHandler}
                draggable={false}
                resizable={false}
            >
                {isLoading && <ProgressBar mode="indeterminate" style={{height: '3px'}}></ProgressBar>}
                {isLoading && <div className={`p-3 flex justify-center items-center font-semibold text-l`}>Bitte warten...</div>}
                {
                    !isLoading && (
                        <div className={`px-4 pt-1 pb-3`}>
                            <BookingActionButtonDialogContent
                                rescanPNR={rescanPNR}
                            />
                        </div>
                    )
                }
            </Dialog>
        </>
    )
}
