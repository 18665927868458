import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FlightIcon from "@material-ui/icons/Flight";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import HotelIcon from "@material-ui/icons/Hotel";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CarRentalIcon from "@mui/icons-material/CarRental";
import TourIcon from "@mui/icons-material/Tour";
import useRecentSearches from "hooks/useRecentSearches";
import { getHostLocationHref } from "utils/navigationLinks";

type Props = {
  className?: string;
};

type CardProps = {
  type: string;
  searchType?: string;
  departure?: string;
  arrival?: string;
  dateEnd?: any;
  dateStart?: any;
  travelers?: any;
  hotel?: any;
  dateRange?: any;
  guests?: any;
  url: string;
  arrivalAirport?: string;
  departureAirport?: string;
  departTime?: any;
  returnTime?: any;
  landingDate?: any;
  rentalLocation?: string;
  returnLocation?: string;
  startDateRent?: any;
  endDateRent?: any;
  tour?: string;
  dateRangeTour?: any;
  guestsTour?: number;
};

const SearchCard: React.FC<CardProps> = ({
  guests,
  hotel,
  dateRange,
  url,
  type,
  searchType,
  departure,
  arrival,
  dateEnd,
  dateStart,
  travelers,
  arrivalAirport,
  departureAirport,
  departTime,
  returnTime,
  landingDate,
  rentalLocation,
  returnLocation,
  startDateRent,
  endDateRent,
  tour,
  dateRangeTour,
  guestsTour,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {
        //Flight

        type === "flight" ? (
          <div className="">
            <a
              href={getHostLocationHref() + "flight-search?" + url}
              className="text-no-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-100 ease flex h-24 items-center justify-between overflow-hidden  rounded-br-lg bg-white p-2 text-sm shadow-sm transition duration-200 hover:shadow-2xl ">
                <div className="rounded-2xl bg-white p-2">
                  <FlightIcon />
                </div>
                <div className="flex flex-col justify-center px-2 text-xs">
                  <p className="text-lg font-semibold text-primary-6000">
                    {departure} &gt; {arrival}
                  </p>
                  {searchType === "Oneway" ? (
                    <p className="">
                      {new Date(dateStart)
                        .toLocaleString()
                        .split(",")[0]
                        .substring(0, 10)}
                    </p>
                  ) : (
                    <p className="">
                      {new Date(dateStart)
                        .toLocaleString()
                        .split(",")[0]
                        .substring(0, 10)}{" "}
                      -{" "}
                      {new Date(dateEnd)
                        .toLocaleString()
                        .split(",")[0]
                        .substring(0, 10)}
                    </p>
                  )}

                  <p className="text-center">
                    {searchType} - {travelers.adults}{" "}
                    {t("b2c-app.flights.traveler.adults")}
                  </p>
                </div>
                <div className="pl-4">
                  <ArrowRightIcon />
                </div>
              </div>
            </a>
          </div>
        ) : null
      }
      {
        //Hotel
        type === "hotel" ? (
          <div className="">
            <a
              href={getHostLocationHref() + "search?" + url}
              className="text-no-underline"
              target="_blank"
            >
              <div className="w-100  ease flex h-24 min-w-full items-center justify-between overflow-hidden rounded-br-lg bg-white p-2 text-sm shadow-sm transition duration-200 hover:shadow-2xl">
                <div className="rounded-2xl bg-white p-2">
                  <HotelIcon />
                </div>
                <div className="flex flex-col justify-center px-2 text-xs">
                  <p className="text-lg font-semibold text-primary-6000">
                    {hotel}
                  </p>
                  <p className="">
                    {guests[0].adults} {t("b2c-app.flights.traveler.adults")}
                  </p>
                  <p className="">
                    {new Date(dateRange.startDate)
                      .toLocaleString()
                      .split(",")[0]
                      .substring(0, 10)}{" "}
                    -{" "}
                    {new Date(dateRange.endDate)
                      .toLocaleString()
                      .split(",")[0]
                      .substring(0, 10)}
                  </p>
                </div>
                <div className="pl-4">
                  <ArrowRightIcon />
                </div>
              </div>
            </a>
          </div>
        ) : null
      }
      {
        //RentaCar
        type === "rentacar" ? (
          <div className="">
            <a
              href={getHostLocationHref() + "rent-car/results?" + url}
              className="text-no-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-100 ease flex h-24 items-center justify-between overflow-hidden rounded-br-lg bg-white p-2 text-sm shadow-sm transition duration-200 hover:shadow-2xl">
                <div className="rounded-2xl bg-white p-2">
                  <CarRentalIcon />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-md font-semibold text-primary-6000">
                    {rentalLocation}
                  </p>
                  <p className="">
                    {new Date(startDateRent)
                      .toLocaleString()
                      .split(",")[0]
                      .substring(0, 10)}{" "}
                    -{" "}
                    {new Date(endDateRent)
                      .toLocaleString()
                      .split(",")[0]
                      .substring(0, 10)}
                  </p>
                  <p className=""></p>
                </div>
                <div className="pl-4">
                  <ArrowRightIcon />
                </div>
              </div>
            </a>
          </div>
        ) : null
      }
      {
        //Transfer
        type === "transfer" ? (
          <div className="">
            <a
              href={getHostLocationHref() + "transfer/results?" + url}
              className="text-no-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-100 ease flex h-24 items-center justify-between overflow-hidden rounded-br-lg bg-white p-2 text-sm shadow-sm transition duration-200 hover:shadow-2xl">
                <div className="rounded-2xl bg-white p-2">
                  <AirportShuttleIcon />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-sm font-semibold text-primary-6000">
                    {departureAirport}
                  </p>

                  <p className="">
                    {" "}
                    {landingDate
                      ? new Date(landingDate)
                          .toLocaleString()
                          .split(",")[0]
                          .substring(0, 10)
                      : null}{" "}
                  </p>
                  <p className=""></p>
                  <div></div>
                </div>
                <div className="pl-4">
                  <ArrowRightIcon />
                </div>
              </div>
            </a>
          </div>
        ) : null
      }

      {
        //Tour
        type === "tour" ? (
          <div className="">
            <a
              href={getHostLocationHref() + url}
              className="text-no-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-100  ease flex h-24 min-w-full items-center justify-between overflow-hidden rounded-br-lg bg-white p-2 text-sm shadow-sm transition duration-200 hover:shadow-2xl">
                <div className="rounded-2xl bg-white p-2">
                  <TourIcon />
                </div>
                <div className="flex flex-col justify-center text-xs">
                  <p className="text-sm font-semibold text-primary-6000">
                    {tour}
                  </p>
                  <p className="">
                    {guestsTour} {t("b2c-app.flights.traveler.adults")}
                  </p>
                  <p className="">
                    {new Date(dateRangeTour.startDate)
                      .toLocaleString()
                      .split(",")[0]
                      .substring(0, 10)}{" "}
                    -{" "}
                    {new Date(dateRangeTour.endDate)
                      .toLocaleString()
                      .split(",")[0]
                      .substring(0, 10)}
                  </p>
                </div>
                <div className="pl-4">
                  <ArrowRightIcon />
                </div>
              </div>
            </a>
          </div>
        ) : null
      }
    </>
  );
};

const RecentSearches: React.FC<Props> = ({ className }) => {
  const [data, setData] = useState<CardProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const { clearRecentSearches } = useRecentSearches("recentSearches");

  const [clear, setClear] = useState<boolean>();

  useEffect(() => {
    getRecentData();
  }, [clear]);

  function getRecentData() {
    try {
      const data = localStorage.getItem("recentSearches");
      if (data) {
        setData(JSON.parse(data));
        setIsLoading(false);
      } else {
        setIsLoading(false);

        return {};
      }
    } catch (error) {
      console.error("Error retrieving data from local storage:", error);
      return null;
    }
  }

  return (
    <>
      {data.length > 0 ? (
        <div className="my-5 w-full">
          <div className="flex justify-between">
            <h2 className="text-xl font-medium">{t("app.recent-searches")}</h2>
            <div>
              <button
                className="mt-2 text-sm text-red-500 underline"
                onClick={() => {
                  clearRecentSearches();
                  setClear(!clear);
                }}
              >
                {t("flights.clear")}
              </button>
            </div>
          </div>
          <div
            className={`my-2 grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 ${className}`}
          >
            {isLoading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  width={288}
                  height={288}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width={288}
                  height={288}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width={288}
                  height={288}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width={288}
                  height={288}
                  animation="wave"
                />
              </>
            ) : (
              data?.map((item, index) => (
                <SearchCard
                  key={index}
                  type={item.type}
                  searchType={item.searchType}
                  departure={item.departure}
                  arrival={item.arrival}
                  dateEnd={item.dateEnd}
                  dateStart={item.dateStart}
                  travelers={item.travelers}
                  url={item.url}
                  hotel={item.hotel}
                  guests={item.guests}
                  dateRange={item.dateRange}
                  departureAirport={item.departureAirport}
                  arrivalAirport={item.arrivalAirport}
                  departTime={item.departTime}
                  returnTime={item.returnTime}
                  landingDate={item.landingDate}
                  rentalLocation={item.rentalLocation}
                  returnLocation={item.returnLocation}
                  startDateRent={item.startDateRent}
                  endDateRent={item.endDateRent}
                  tour={item.tour}
                  dateRangeTour={item.dateRangeTour}
                  guestsTour={item.guestsTour}
                />
              ))
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecentSearches;
