import React, { useEffect, useState } from "react";
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BookingApprovalItem } from "../BookingApproval";
import ConfirmationDialog from "components/ui/ConfirmationDialog/ConfirmationDialog";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { bookingApproval } from "api/helpers/api-constants";
 
interface BookingApprovalTableProps {
  bookingApprovalList: BookingApprovalItem[];
  onApprove: (id: number) => void;
  onReject: (id: number) => void;
  approvedSwitch: boolean;
}
interface Remarks {
  [id: number]:string
}
const BookingApprovalTable: React.FC<BookingApprovalTableProps> = ({ bookingApprovalList, onApprove, onReject, approvedSwitch}) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [dialogAction, setDialogAction] = useState<"approve" | "reject" | null>(null);
  const [remarks, setRemarks] = useState<Remarks>({});

  const handleRemarkChange = (bookingId : number, value : string) => {
    setRemarks(prevRemarks => ({
      ...prevRemarks,
      [bookingId]: value,
    }));
  };

  const handleOpenDialog = (action: "approve" | "reject", id: number) => {
    setDialogAction(action);
    setSelectedId(id);
    setDialogOpen(true);
  };

  const handleConfirmAction = () => {
    if (selectedId && dialogAction) {
      if (dialogAction === "approve") {
        onApprove(selectedId);
      } else if (dialogAction === "reject") {
        onReject(selectedId);
      }
    

    }
    setDialogOpen(false);
    setSelectedId(null);
    setDialogAction(null);
  };

  
  const handleUpdateRemark = async (id: number, remarks:string, approved: boolean) => {
    try {
  
      const response = await fetch(bookingApproval(approved), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          approved,
          remarks,
        }),
      });
  
      if (response.ok) {
        console.log(`Successfully updated booking approval for ${id}`);

      }
    } catch (error) {
      console.error(`Error updating booking approval for ${id}:`, error);
    }
      
  };  
  
  useEffect(() => {
    const initialRemarks : any = {};
    bookingApprovalList.forEach((booking) => {
      initialRemarks[booking.id] = booking.remarks; 
    });
    setRemarks(initialRemarks);
  }, [bookingApprovalList]);
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("general.bookingkey")}</TableCell>
              <TableCell align="center">{t("bookings.products")}</TableCell>
              <TableCell align="center">{t("booking.remarks")}</TableCell>
              <TableCell align="center">{t("homepage.login.username")}</TableCell>
              <TableCell align="center">{t("manage-agencies.agency")}</TableCell>
              <TableCell align="center">{t("manage-users.status")}</TableCell>
              <TableCell align="center"></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {bookingApprovalList.map((booking) => (

            <TableRow key={booking.bookingKey}>
                <TableCell align="center">
                  <a
                    className="text-primary-6000"
                    href={`ibe#/booking/detail/${booking.bookingKey}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {booking.bookingKey}
                  </a>
                </TableCell>

                <TableCell align="center">{booking.productType}</TableCell>
                <TableCell align="center" className="flex justify-center">
                <TextField
                    value={remarks[booking.id]}
                    onChange={(e) => handleRemarkChange(booking.id, e.target.value)}
                    variant="outlined"
                    size="small"
                    placeholder={t("generals.add-new")}
                  />
                  {booking.remarks !== remarks[booking.id] && <IconButton
                    color="primary"
                    onClick={() => handleUpdateRemark(booking.id, remarks[booking.id], booking.approved)}
                  >
                    <CheckIcon />
                  </IconButton>}
               </TableCell>
                <TableCell align="center">{booking.username}</TableCell>
                <TableCell align="center">{booking.agency}</TableCell>
                <TableCell align="center">{booking.approved ? t("generals.approved") : t("generals.notyetapproved")}</TableCell>

                <TableCell>
                
        <div className="flex justify-center">
          <IconButton
            color="success"
            onClick={() => handleOpenDialog("approve", booking.id)}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => handleOpenDialog("reject", booking.id)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirmAction}
        message={""}
      />
    </>
  );
};

export default BookingApprovalTable;
