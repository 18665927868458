export function getNationalities()
{
        return(
            [
                    {
                            value: "GB",
                            country: "UNITED KINGDOM"
                    },
                    {
                            value: "AF",
                            country: "AFGHANISTAN"
                    },
                    {
                            value: "AL",
                            country: "ALBANIA"
                    },
                    {
                            value: "DZ",
                            country: "ALGERIA"
                    },
                    {
                            value: "AS",
                            country: "AMERICAN SAMOA"
                    },
                    {
                            value: "AD",
                            country: "ANDORRA"
                    },
                    {
                            value: "AO",
                            country: "ANGOLA"
                    },
                    {
                            value: "AI",
                            country: "ANGUILLA"
                    },
                    {
                            value: "AQ",
                            country: "ANTARCTICA"
                    },
                    {
                            value: "AG",
                            country: "ANTIGUA AND BARBUDA"
                    },
                    {
                            value: "AR",
                            country: "ARGENTINA"
                    },
                    {
                            value: "AM",
                            country: "ARMENIA"
                    },
                    {
                            value: "AW",
                            country: "ARUBA"
                    },
                    {
                            value: "AU",
                            country: "AUSTRALIA"
                    },
                    {
                            value: "AT",
                            country: "AUSTRIA"
                    },
                    {
                            value: "AZ",
                            country: "AZERBAIJAN"
                    },
                    {
                            value: "BS",
                            country: "BAHAMAS"
                    },
                    {
                            value: "BH",
                            country: "BAHRAIN"
                    },
                    {
                            value: "BD",
                            country: "BANGLADESH"
                    },
                    {
                            value: "BB",
                            country: "BARBADOS"
                    },
                    {
                            value: "BY",
                            country: "BELARUS"
                    },
                    {
                            value: "BE",
                            country: "BELGIUM"
                    },
                    {
                            value: "BZ",
                            country: "BELIZE"
                    },
                    {
                            value: "BJ",
                            country: "BENIN"
                    },
                    {
                            value: "BM",
                            country: "BERMUDA"
                    },
                    {
                            value: "BT",
                            country: "BHUTAN"
                    },
                    {
                            value: "BO",
                            country: "BOLIVIA"
                    },
                    {
                            value: "BA",
                            country: "BOSNIA AND HERZEGOVINA"
                    },
                    {
                            value: "BW",
                            country: "BOTSWANA"
                    },
                    {
                            value: "BR",
                            country: "BRAZIL"
                    },
                    {
                            value: "BN",
                            country: "BRUNEI DARUSSALAM"
                    },
                    {
                            value: "BG",
                            country: "BULGARIA"
                    },
                    {
                            value: "BF",
                            country: "BURKINA FASO"
                    },
                    {
                            value: "BI",
                            country: "BURUNDI"
                    },
                    {
                            value: "KH",
                            country: "CAMBODIA"
                    },
                    {
                            value: "CM",
                            country: "CAMEROON"
                    },
                    {
                            value: "CA",
                            country: "CANADA"
                    },
                    {
                            value: "CV",
                            country: "CAPE VERDE"
                    },
                    {
                            value: "KY",
                            country: "CAYMAN ISLANDS"
                    },
                    {
                            value: "CF",
                            country: "CENTRAL AFRICAN REPUBLIC"
                    },
                    {
                            value: "TD",
                            country: "CHAD"
                    },
                    {
                            value: "CL",
                            country: "CHILE"
                    },
                    {
                            value: "CN",
                            country: "CHINA"
                    },
                    {
                            value: "CX",
                            country: "CHRISTMAS ISLAND"
                    },
                    {
                            value: "CC",
                            country: "COCOS ISLANDS"
                    },
                    {
                            value: "CO",
                            country: "COLOMBIA"
                    },
                    {
                            value: "KM",
                            country: "COMOROS"
                    },
                    {
                            value: "CD",
                            country: "CONGO"
                    },
                    {
                            value: "CG",
                            country: "CONGO"
                    },
                    {
                            value: "CK",
                            country: "COOK ISLANDS"
                    },
                    {
                            value: "CR",
                            country: "COSTA RICA"
                    },
                    {
                            value: "CI",
                            country: "COTE D IVOIRE"
                    },
                    {
                            value: "HR",
                            country: "CROATIA"
                    },
                    {
                            value: "CU",
                            country: "CUBA"
                    },
                    {
                            value: "CY",
                            country: "CYPRUS"
                    },
                    {
                            value: "CZ",
                            country: "CZECHIA"
                    },
                    {
                            value: "DK",
                            country: "DENMARK"
                    },
                    {
                            value: "DJ",
                            country: "DJIBOUTI"
                    },
                    {
                            value: "DM",
                            country: "DOMINICA"
                    },
                    {
                            value: "DO",
                            country: "DOMINICAN REPUBLIC"
                    },
                    {
                            value: "EC",
                            country: "ECUADOR"
                    },
                    {
                            value: "EG",
                            country: "EGYPT"
                    },
                    {
                            value: "SV",
                            country: "EL SALVADOR"
                    },
                    {
                            value: "GQ",
                            country: "EQUATORIAL GUINEA"
                    },
                    {
                            value: "ER",
                            country: "ERITREA"
                    },
                    {
                            value: "EE",
                            country: "ESTONIA"
                    },
                    {
                            value: "ET",
                            country: "ETHIOPIA"
                    },
                    {
                            value: "FK",
                            country: "FALKLAND ISLANDS"
                    },
                    {
                            value: "FO",
                            country: "FAROE ISLANDS"
                    },
                    {
                            value: "FJ",
                            country: "FIJI"
                    },
                    {
                            value: "FI",
                            country: "FINLAND"
                    },
                    {
                            value: "FR",
                            country: "FRANCE"
                    },
                    {
                            value: "PF",
                            country: "FRENCH POLYNESIA"
                    },
                    {
                            value: "GA",
                            country: "GABON"
                    },
                    {
                            value: "GM",
                            country: "GAMBIA"
                    },
                    {
                            value: "GE",
                            country: "GEORGIA"
                    },
                    {
                            value: "DE",
                            country: "GERMANY"
                    },
                    {
                            value: "GH",
                            country: "GHANA"
                    },
                    {
                            value: "GI",
                            country: "GIBRALTAR"
                    },
                    {
                            value: "GR",
                            country: "GREECE"
                    },
                    {
                            value: "GL",
                            country: "GREENLAND"
                    },
                    {
                            value: "GD",
                            country: "GRENADA"
                    },
                    {
                            value: "GU",
                            country: "GUAM"
                    },
                    {
                            value: "GT",
                            country: "GUATEMALA"
                    },
                    {
                            value: "GW",
                            country: "GUINEA-BISSAU"
                    },
                    {
                            value: "GN",
                            country: "GUINEA"
                    },
                    {
                            value: "GY",
                            country: "GUYANA"
                    },
                    {
                            value: "HT",
                            country: "HAITI"
                    },
                    {
                            value: "VA",
                            country: "HOLY SEE VATICAN CITY STATE"
                    },
                    {
                            value: "HN",
                            country: "HONDURAS"
                    },
                    {
                            value: "HK",
                            country: "HONG KONG"
                    },
                    {
                            value: "HU",
                            country: "HUNGARY"
                    },
                    {
                            value: "IS",
                            country: "ICELAND"
                    },
                    {
                            value: "IN",
                            country: "INDIA"
                    },
                    {
                            value: "ID",
                            country: "INDONESIA"
                    },
                    {
                            value: "IR",
                            country: "IRAN"
                    },
                    {
                            value: "IQ",
                            country: "IRAQ"
                    },
                    {
                            value: "IE",
                            country: "IRELAND"
                    },
                    {
                            value: "IM",
                            country: "ISLE OF MAN"
                    },
                    {
                            value: "IL",
                            country: "ISRAEL"
                    },
                    {
                            value: "IT",
                            country: "ITALY"
                    },
                    {
                            value: "JM",
                            country: "JAMAICA"
                    },
                    {
                            value: "JP",
                            country: "JAPAN"
                    },
                    {
                            value: "JO",
                            country: "JORDAN"
                    },
                    {
                            value: "KZ",
                            country: "KAZAKSTAN"
                    },
                    {
                            value: "KE",
                            country: "KENYA"
                    },
                    {
                            value: "KI",
                            country: "KIRIBATI"
                    },
                    {
                            value: "KP",
                            country: "KOREA DEMOCRATIC PEOPLES REPUBLIC OF"
                    },
                    {
                            value: "KR",
                            country: "KOREA REPUBLIC OF"
                    },
                    {
                            value: "XK",
                            country: "KOSOVO"
                    },
                    {
                            value: "KW",
                            country: "KUWAIT"
                    },
                    {
                            value: "KG",
                            country: "KYRGYZSTAN"
                    },
                    {
                            value: "LA",
                            country: "LAO PEOPLES DEMOCRATIC REPUBLIC"
                    },
                    {
                            value: "LV",
                            country: "LATVIA"
                    },
                    {
                            value: "LB",
                            country: "LEBANON"
                    },
                    {
                            value: "LS",
                            country: "LESOTHO"
                    },
                    {
                            value: "LR",
                            country: "LIBERIA"
                    },
                    {
                            value: "LY",
                            country: "LIBYAN ARAB JAMAHIRIYA"
                    },
                    {
                            value: "LI",
                            country: "LIECHTENSTEIN"
                    },
                    {
                            value: "LT",
                            country: "LITHUANIA"
                    },
                    {
                            value: "LU",
                            country: "LUXEMBOURG"
                    },
                    {
                            value: "MO",
                            country: "MACAU"
                    },
                    {
                            value: "MK",
                            country: "MACEDONIA"
                    },
                    {
                            value: "MG",
                            country: "MADAGASCAR"
                    },
                    {
                            value: "MW",
                            country: "MALAWI"
                    },
                    {
                            value: "MY",
                            country: "MALAYSIA"
                    },
                    {
                            value: "MV",
                            country: "MALDIVES"
                    },
                    {
                            value: "ML",
                            country: "MALI"
                    },
                    {
                            value: "MT",
                            country: "MALTA"
                    },
                    {
                            value: "MH",
                            country: "MARSHALL ISLANDS"
                    },
                    {
                            value: "MR",
                            country: "MAURITANIA"
                    },
                    {
                            value: "MU",
                            country: "MAURITIUS"
                    },
                    {
                            value: "YT",
                            country: "MAYOTTE"
                    },
                    {
                            value: "MX",
                            country: "MEXICO"
                    },
                    {
                            value: "FM",
                            country: "MICRONESIA"
                    },
                    {
                            value: "MD",
                            country: "MOLDOVA"
                    },
                    {
                            value: "MC",
                            country: "MONACO"
                    },
                    {
                            value: "MN",
                            country: "MONGOLIA"
                    },
                    {
                            value: "ME",
                            country: "MONTENEGRO"
                    },
                    {
                            value: "MS",
                            country: "MONTSERRAT"
                    },
                    {
                            value: "MA",
                            country: "MOROCCO"
                    },
                    {
                            value: "MZ",
                            country: "MOZAMBIQUE"
                    },
                    {
                            value: "MM",
                            country: "MYANMAR"
                    },
                    {
                            value: "NA",
                            country: "NAMIBIA"
                    },
                    {
                            value: "NR",
                            country: "NAURU"
                    },
                    {
                            value: "NP",
                            country: "NEPAL"
                    },
                    {
                            value: "AN",
                            country: "NETHERLANDS ANTILLES"
                    },
                    {
                            value: "NL",
                            country: "NETHERLANDS"
                    },
                    {
                            value: "NC",
                            country: "NEW CALEDONIA"
                    },
                    {
                            value: "NZ",
                            country: "NEW ZEALAND"
                    },
                    {
                            value: "NI",
                            country: "NICARAGUA"
                    },
                    {
                            value: "NE",
                            country: "NIGER"
                    },
                    {
                            value: "NG",
                            country: "NIGERIA"
                    },
                    {
                            value: "NU",
                            country: "NIUE"
                    },
                    {
                            value: "MP",
                            country: "NORTHERN MARIANA ISLANDS"
                    },
                    {
                            value: "NO",
                            country: "NORWAY"
                    },
                    {
                            value: "OM",
                            country: "OMAN"
                    },
                    {
                            value: "PK",
                            country: "PAKISTAN"
                    },
                    {
                            value: "PW",
                            country: "PALAU"
                    },
                    {
                            value: "PA",
                            country: "PANAMA"
                    },
                    {
                            value: "PG",
                            country: "PAPUA NEW GUINEA"
                    },
                    {
                            value: "PY",
                            country: "PARAGUAY"
                    },
                    {
                            value: "PE",
                            country: "PERU"
                    },
                    {
                            value: "PH",
                            country: "PHILIPPINES"
                    },
                    {
                            value: "PN",
                            country: "PITCAIRN"
                    },
                    {
                            value: "PL",
                            country: "POLAND"
                    },
                    {
                            value: "PT",
                            country: "PORTUGAL"
                    },
                    {
                            value: "PR",
                            country: "PUERTO RICO"
                    },
                    {
                            value: "QA",
                            country: "QATAR"
                    },
                    {
                            value: "RO",
                            country: "ROMANIA"
                    },
                    {
                            value: "RU",
                            country: "RUSSIAN FEDERATION"
                    },
                    {
                            value: "RW",
                            country: "RWANDA"
                    },
                    {
                            value: "BL",
                            country: "SAINT BARTHELEMY"
                    },
                    {
                            value: "SH",
                            country: "SAINT HELENA"
                    },
                    {
                            value: "KN",
                            country: "SAINT KITTS AND NEVIS"
                    },
                    {
                            value: "LC",
                            country: "SAINT LUCIA"
                    },
                    {
                            value: "MF",
                            country: "SAINT MARTIN"
                    },
                    {
                            value: "PM",
                            country: "SAINT PIERRE AND MIQUELON"
                    },
                    {
                            value: "VC",
                            country: "SAINT VINCENT AND THE GRENADINES"
                    },
                    {
                            value: "WS",
                            country: "SAMOA"
                    },
                    {
                            value: "SM",
                            country: "SAN MARINO"
                    },
                    {
                            value: "ST",
                            country: "SAO TOME AND PRINCIPE"
                    },
                    {
                            value: "SA",
                            country: "SAUDI ARABIA"
                    },
                    {
                            value: "SN",
                            country: "SENEGAL"
                    },
                    {
                            value: "RS",
                            country: "SERBIA"
                    },
                    {
                            value: "SC",
                            country: "SEYCHELLES"
                    },
                    {
                            value: "SL",
                            country: "SIERRA LEONE"
                    },
                    {
                            value: "SG",
                            country: "SINGAPORE"
                    },
                    {
                            value: "SK",
                            country: "SLOVAKIA"
                    },
                    {
                            value: "SI",
                            country: "SLOVENIA"
                    },
                    {
                            value: "SB",
                            country: "SOLOMON ISLANDS"
                    },
                    {
                            value: "SO",
                            country: "SOMALIA"
                    },
                    {
                            value: "ZA",
                            country: "SOUTH AFRICA"
                    },
                    {
                            value: "ES",
                            country: "SPAIN"
                    },
                    {
                            value: "LK",
                            country: "SRI LANKA"
                    },
                    {
                            value: "SD",
                            country: "SUDAN"
                    },
                    {
                            value: "SR",
                            country: "SURINAME"
                    },
                    {
                            value: "SZ",
                            country: "SWAZILAND"
                    },
                    {
                            value: "SE",
                            country: "SWEDEN"
                    },
                    {
                            value: "CH",
                            country: "SWITZERLAND"
                    },
                    {
                            value: "SY",
                            country: "SYRIAN ARAB REPUBLIC"
                    },
                    {
                            value: "TW",
                            country: "TAIWAN"
                    },
                    {
                            value: "TJ",
                            country: "TAJIKISTAN"
                    },
                    {
                            value: "TZ",
                            country: "TANZANIA"
                    },
                    {
                            value: "TH",
                            country: "THAILAND"
                    },
                    {
                            value: "TL",
                            country: "TIMOR-LESTE"
                    },
                    {
                            value: "TG",
                            country: "TOGO"
                    },
                    {
                            value: "TK",
                            country: "TOKELAU"
                    },
                    {
                            value: "TO",
                            country: "TONGA"
                    },
                    {
                            value: "TT",
                            country: "TRINIDAD AND TOBAGO"
                    },
                    {
                            value: "TN",
                            country: "TUNISIA"
                    },
                    {
                            value: "TR",
                            country: "TURKEY"
                    },
                    {
                            value: "TM",
                            country: "TURKMENISTAN"
                    },
                    {
                            value: "TC",
                            country: "TURKS AND CAICOS ISLANDS"
                    },
                    {
                            value: "TV",
                            country: "TUVALU"
                    },
                    {
                            value: "UG",
                            country: "UGANDA"
                    },
                    {
                            value: "UA",
                            country: "UKRAINE"
                    },
                    {
                            value: "AE",
                            country: "UNITED ARAB EMIRATES"
                    },
                    {
                            value: "US",
                            country: "UNITED STATES"
                    },
                    {
                            value: "UY",
                            country: "URUGUAY"
                    },
                    {
                            value: "UZ",
                            country: "UZBEKISTAN"
                    },
                    {
                            value: "VU",
                            country: "VANUATU"
                    },
                    {
                            value: "VE",
                            country: "VENEZUELA"
                    },
                    {
                            value: "VN",
                            country: "VIETNAM"
                    },
                    {
                            value: "VG",
                            country: "VIRGIN ISLANDS BRITISH"
                    },
                    {
                            value: "VI",
                            country: "VIRGIN ISLANDS U.S."
                    },
                    {
                            value: "WF",
                            country: "WALLIS AND FUTUNA"
                    },
                    {
                            value: "YE",
                            country: "YEMEN"
                    },
                    {
                            value: "ZM",
                            country: "ZAMBIA"
                    },
                    {
                            value: "ZW",
                            country: "ZIMBABWE"
                    }
            ]
        )
}