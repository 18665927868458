import React, {FC} from "react";
import {useReduxReducer} from "../../../redux/store";
import useQueryParameters from "../../../hooks/useQueryParameters";
import {formatDateShortMonth} from "../../../utils/formatDate";

export const FlightContent: FC = () => {
    const { isOneWay, isMultiFlight } = useReduxReducer(state => state.flight)
    const queryParameters = useQueryParameters()

    const content = () => {
        const depPort: string = queryParameters ? queryParameters.originDestinationInformation![0].origin! : ''
        const arrPort: string = queryParameters ? queryParameters.originDestinationInformation![queryParameters.originDestinationInformation!.length - 1 ][isOneWay ? 'destination' : 'origin']! : ''
        const depDate: string = queryParameters ? queryParameters.originDestinationInformation![0].date! : ''
        const arrDate: string = queryParameters ? queryParameters.originDestinationInformation![queryParameters.originDestinationInformation!.length - 1 ].date! : ''

        return (
            <>
                <div className={`text-xl font-semibold pt-1 pb-0`}>
                    {`${depPort} - ${arrPort}`}
                </div>
                <div className={`text-sm pt-0 pb-1`}>
                    {`${formatDateShortMonth(depDate)} ${isOneWay ? '' : `- ${formatDateShortMonth(arrDate)}`}`}
                    &nbsp;&nbsp;
                    <span className="pi pi-user text-sm"></span>
                    &nbsp;
                    { queryParameters!.adtCount! + queryParameters!.chdCount! + queryParameters!.infCount! }
                </div>
            </>
        )
    }

    const multiflightContent = () => {
        return (
            <>
                <div className={`flex`}>
                    {
                        queryParameters?.originDestinationInformation?.map((destination, index) => {
                            const depPort: string = destination.origin!
                            const arrPort: string = destination.destination!
                            const depDate: string = destination.date

                            return (
                                <div className={`pr-2.5`}>
                                    <div className={`text-xl font-semibold pt-1 pb-0`}>
                                        {`${depPort} - ${arrPort}`}
                                    </div>
                                    <div className={`text-sm pt-0 pb-1 text-left`}>
                                        {`${formatDateShortMonth(depDate)}`}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={`flex text-sm`}>
                    <div>
                        <span className="pi pi-user text-sm"></span>
                        &nbsp;
                        { queryParameters!.adtCount! + queryParameters!.chdCount! + queryParameters!.infCount! }
                    </div>
                    <div>
                        &nbsp;&nbsp;
                        - Economy Class
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                isMultiFlight ?
                    multiflightContent()
                    :
                    content()
            }
        </>
    )
}
