import React, {FC, Fragment, useRef, useState} from "react";
import { Dialog, Transition } from '@headlessui/react'
import TextFieldRef from "../TextFieldMui/TextFieldRef";
import postFetcher, {postParamRequest} from "../../../api/helpers/postFetcher";
import {getAddFlightCaseUrl} from "../../../api/helpers/api-constants";
import {IStatusResponse} from "../../../api/types";

interface ICreateFlightCaseModal {
    bookingKey: string,
    open: boolean,
    closeModal: () => void,
    addCaseSuccess: () => void
}

const CreateFlightCaseModal: FC<ICreateFlightCaseModal> = ({bookingKey, open, closeModal, addCaseSuccess}) => {

    const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

    const subjectRef = useRef<HTMLTextAreaElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const init = () => {
        setShowErrorMsg(false);
    }

    const closeHandler = () => {
        init();
        closeModal();
    }

    const addCaseHandler = () => {
        addCaseSuccess();
        closeHandler();
    }

    const sendCase = async () => {
        if ((subjectRef.current === null || subjectRef.current.value.length === 0) || (textAreaRef.current === null || textAreaRef.current.value.length === 0)) {
            setShowErrorMsg((subjectRef.current === null || subjectRef.current.value.length === 0) || (textAreaRef.current === null || textAreaRef.current.value.length === 0))
            return
        }

        if (showErrorMsg)
            setShowErrorMsg(false);

        const payload: object = {
            bookingKey: bookingKey,
            newCaseSubject: subjectRef.current.value,
            newCaseText: textAreaRef.current.value
        }

        const response: IStatusResponse = await postFetcher(getAddFlightCaseUrl(), payload);
        if (response.status === "success")
            addCaseHandler()
    }

    const listView = () => {
        return (
            <>
                {listViewHeader()}
                {listViewContent()}
                {listViewBottom()}
            </>
        )
    }

    const listViewHeader = () => {
        return (
            <div className="bg-gray-50 px-4 py-3 sm:px-6 border-b text-center">
                <h3 className="text-lg font-medium text-red-500 pt-2">
                    Neuer Case
                </h3>
            </div>
        )
    }

    const listViewContent = () => {
        return (
            <div className="max-h-96  py-6 px-8 mx-3 text-left">
                <div className="row pb-4">
                    Neuer Case: {bookingKey}
                </div>
                <div className="pb-4">
                    <div className="row">Betreff</div>
                    <div className="row">
                        <TextFieldRef
                            showError={showErrorMsg && (subjectRef.current === null || subjectRef.current.value.length === 0)}
                            ref={subjectRef}
                            helperText={'Geben Sie bitte einen Betreff an.'}
                            size="small"
                            style={{ width: 230 }}
                        />
                    </div>
                </div>
                <div className="">
                    <div className="row">
                        Bitte erläutern Sie hier Ihre Anfrage
                    </div>
                    <div className="row">
                        <TextFieldRef
                            ref={textAreaRef}
                            style={{ width: "100%" }}
                            showError={showErrorMsg && (textAreaRef.current === null || textAreaRef.current.value.length === 0)}
                            helperText={'Geben Sie bitte eine Nachricht an.'}
                            placeholder='Beispiel: "Unser Passagier möchte die Tickets aufgrund des Coronavirus auf den OPEN Status setzen lassen im Rahmen der von der Fluggesellschaft bereitgestellten Möglichkeiten."'
                            multiline={true}
                            minRows={3}
                            maxRows={3}
                        />
                    </div>
                </div>

            </div>
        )
    }

    const listViewBottom = () => {
        return (
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border-t border-gray-300">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-green-600 hover:text-white sm:ml-3 sm:w-auto"
                    onClick={sendCase}
                >
                    Absenden
                </button>
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-400 hover:text-white sm:ml-3 sm:w-auto"
                    onClick={closeHandler}
                >
                    Schließen
                </button>
            </div>
        )
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="relative transform rounded-lg xl:w-980 lg:w-96 md:w80 sm:w-64 bg-white text-left shadow-xl transition-all sm:my-8 z-40 overflow-scroll">
                                    {listView()}
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
export default CreateFlightCaseModal