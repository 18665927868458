import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BestPricesModel, FlightOffer, IAllPricesInclServiceFee, IBaseData, TSelectedFlights} from "../../api/types";

interface IFlightState {
    serviceFee: number
    baseOffers: IBaseData
    offers: FlightOffer[]
    mixOffers: FlightOffer[][]
    selectedFlights: TSelectedFlights[]
    allPricesInclServiceFee: IAllPricesInclServiceFee
    selectedMultiFlightTab: number
    isLoading: boolean
    isRoundtrip: boolean
    isOneWay: boolean
    isMultiFlight: boolean
    bestPriceByreturnDate?: BestPricesModel[] 
    bestPriceBydepartDate?: BestPricesModel[] 

}

const initialState: IFlightState = {
    serviceFee: 0,
    baseOffers: {offers: [], mixOffers: []},
    offers: [],
    mixOffers: [[],[]],
    selectedFlights: [],
    allPricesInclServiceFee: {},
    selectedMultiFlightTab: 0,
    isLoading: true,
    isRoundtrip: true,
    isOneWay: false,
    isMultiFlight: false,
    bestPriceByreturnDate: [],
    bestPriceBydepartDate: [],

}

const flightSlice = createSlice({
    name: 'flight',
    initialState,
    reducers: {
        setServiceFee: (state, action: PayloadAction<number>) => {
            state.serviceFee = action.payload;
        },
        setBaseOffers: (state, action: PayloadAction<IBaseData>) => {
            state.baseOffers.offers = action.payload.offers
            state.baseOffers.mixOffers = action.payload.mixOffers
        },
        setOffers: (state, action: PayloadAction<FlightOffer[]>) => {
            state.offers = action.payload
        },
        setMixOffers: (state, action: PayloadAction<FlightOffer[][]>) => {
            action.payload.forEach((_offers, index) => state.mixOffers[index] = _offers)
        },
        setSelectedFlights: (state, action: PayloadAction<TSelectedFlights[]>) => {
            action.payload.forEach((value, index) => state.selectedFlights[index] = value)
        },
        removeSelectedFlightValue: (state, action: PayloadAction<number>) => {
            state.selectedFlights.splice(action.payload, 1)
        },
        setAllPricesInclServiceFee: (state, action: PayloadAction<IAllPricesInclServiceFee>) => {
            Object.keys(action.payload).forEach((key) => state.allPricesInclServiceFee[key] = action.payload[key])
        },
        setSelectedMultiFlightTab: (state, action: PayloadAction<number>) => {
            state.selectedMultiFlightTab = action.payload
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
          state.isLoading = action.payload
        },
        setIsRoundtrip: (state, action: PayloadAction<boolean>) => {
          state.isRoundtrip = action.payload
        },
        setIsOneWay: (state, action: PayloadAction<boolean>) => {
          state.isOneWay = action.payload
        },
        setIsMultiFlight: (state, action: PayloadAction<boolean>) => {
          state.isMultiFlight = action.payload
        },
        setBestPriceBydepartDate: (state, action: PayloadAction<BestPricesModel[]>) => {
            state.bestPriceBydepartDate = action.payload
        },
        setBestPriceByreturnDate: (state, action: PayloadAction<BestPricesModel[]>) => {
            state.bestPriceByreturnDate = action.payload
        },
        clearBaseOffer: (state) => {
            return {
                ...state,
                baseOffers: {offers: [], mixOffers: []},
                mixOffers: [[],[]],
                offers: [],
                allPricesInclServiceFee: {},
                selectedFlights: [],
                selectedMultiFlightTab: 0
            }
        },
        clearOffers: (state) => {
            return {
                ...state,
                offers: []
            }
        },
        clearMixOffers: (state) => {
            return {
                ...state,
                mixOffers: []
            }
        },
        clearAllPricesInclServiceFee: (state) => {
            return {
                ...state,
                allPricesInclServiceFee: {}
            }
        }
    }
})

export const {
    setServiceFee,
    setBaseOffers,
    setOffers,
    setMixOffers,
    setSelectedFlights,
    removeSelectedFlightValue,
    setAllPricesInclServiceFee,
    setSelectedMultiFlightTab,
    clearBaseOffer,
    clearOffers,
    clearMixOffers,
    clearAllPricesInclServiceFee,
    setIsLoading,
    setIsRoundtrip,
    setIsOneWay,
    setIsMultiFlight,
    setBestPriceBydepartDate,
    setBestPriceByreturnDate,
} = flightSlice.actions

export default flightSlice.reducer
