import React, { useState } from 'react';
import ButtonPrimary from 'components/ui/Button/ButtonPrimary';
import ConfirmationDialog from 'components/ui/ConfirmationDialog/ConfirmationDialog';
import { postParamRequest } from 'api/helpers/postFetcher';
import { postReissueConfirm } from 'api/helpers/api-constants';
import { LinearProgress } from '@material-ui/core';
import currencyFormat from 'utils/currencyFormat';
import { useTranslation } from 'react-i18next';
import CustomSweetAlert from 'components/ui/CustomSweetAlert/CustomSweetAlert';

interface ConfirmationSwalProps {
  confirmation: {
    amount: number;
    currencyCode: string;
    key: string;
  };
}

const ReissueConfirmation: React.FC<ConfirmationSwalProps> = ({ confirmation }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [alertState, setAlertState] = useState<{
      open: boolean;
      title: string;
      message: string;
      type: 'success' | 'error' | 'warning' | 'info';
    }>({
      open: false,
      title: '',
      message: '',
      type: 'info'
    });
    const { t } = useTranslation();

    const reissueConfirm = async () => {
      const payload = {
         key: confirmation.key
      };
      setIsLoading(true);
      try {
        const response = await postParamRequest(postReissueConfirm(), payload);
        if (response.success) {
          if(response.message){
            setAlertState({
              open: true,
              title: 'Error',
              message: response.message || "An error occurred",
              type: 'error'
            });
          }
          else{
            setIsSuccess(true);
            setAlertState({
              open: true,
              title: t("generals.success"),
              message: t("generals.success"),
              type: 'success'
            });
          }
        } 
        else{
          setAlertState({
            open: true,
            title: 'Error',
            message: "An error occurred",
            type: 'error'
          });
        }
      } catch (error) {
        console.error(error);
        setAlertState({
          open: true,
          title: 'Error',
          message: "An error occurred",
          type: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    };

    const handleConfirm = () => {
     reissueConfirm();
    };
  
    const handleButtonClick = () => {
      setIsDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };

    const handleCloseAlert = () => {
      setAlertState({ ...alertState, open: false });
      if (alertState.type === 'success') {
        window.location.reload();
      }
    };

  return (
    <div>
      <div className="border p-2 mt-2">
        <h1 className="text-xl p-1 px-4 w-3/5 font-semibold">{t("flight.reissue.pricesummary")}</h1>
        <p>{t("flightbooking.reissue.total-amount-to-be-paid-for-all-passengers")}</p>
        <p className='font-semibold'>{currencyFormat(confirmation.amount)}</p>

        <div className='flex justify-end'>
          <ButtonPrimary 
            onClick={handleButtonClick} 
            disabled={!confirmation.key || isSuccess || isLoading}
          >
            {isSuccess ? t("generals.success") : t("b2c-app.confirmation-request.confirm")}
            {isLoading && <LinearProgress />}
          </ButtonPrimary>
        </div>
      </div>
      
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />

      <CustomSweetAlert
        open={alertState.open}
        onClose={handleCloseAlert}
        title={alertState.title}
        message={alertState.message}
        type={alertState.type}
      />
    </div>
  );
};

export default ReissueConfirmation;

