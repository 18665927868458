import React, { useState, useEffect, useMemo } from 'react';
import { TextField, MenuItem, CircularProgress, Grid, InputAdornment, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Customer, ProductSupplier, PaymentAccount } from '../../types/invoice';
import { fetchCustomers, fetchProductSuppliers, fetchPaymentAccounts } from '../../utils/api';
import { useFormContext } from '../../contexts/FormContext';
import { filterAndPaginateCustomers } from '../../utils/customerUtils';

export const InvoiceForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [productSuppliers, setProductSuppliers] = useState<ProductSupplier[]>([]);
  const [paymentAccounts, setPaymentAccounts] = useState<PaymentAccount[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [customersData, suppliersData, accountsData] = await Promise.all([
          fetchCustomers(),
          fetchProductSuppliers(),
          fetchPaymentAccounts(),
        ]);
        setCustomers(customersData);
        setProductSuppliers(suppliersData);
        setPaymentAccounts(accountsData);
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const { filteredCustomers, hasMore } = useMemo(() => 
    filterAndPaginateCustomers(customers, searchTerm, page),
    [customers, searchTerm, page]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    updateFormData({
      invoiceDetails: {
        ...formData.invoiceDetails,
        [name]: name === 'totalAmount' ? parseFloat(value) || 0 : value,
      },
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="customer-select"
          options={filteredCustomers}
          getOptionLabel={(option) => option.text}
          value={customers.find(c => c.id === formData.invoiceDetails.customerId) || null}
          onChange={(event, newValue) => {
            updateFormData({
              invoiceDetails: {
                ...formData.invoiceDetails,
                customerId: newValue ? newValue.id : '',
                customerName: newValue ? newValue.text : '',
              },
            });
          }}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
            setPage(1);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Customer"
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: (event) => {
              const listboxNode = event.currentTarget;
              if (
                listboxNode.scrollTop + listboxNode.clientHeight ===
                listboxNode.scrollHeight
              ) {
                if (hasMore) {
                  setPage((prevPage) => prevPage + 1);
                }
              }
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          name="productSupplierId"
          label="Product Supplier"
          value={formData.invoiceDetails.productSupplierId}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        >
          {productSuppliers.map((supplier) => (
            <MenuItem key={supplier.id} value={supplier.id}>
              {supplier.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          name="paymentAccountId"
          label="Payment Account"
          value={formData.invoiceDetails.paymentAccountId}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        >
          {paymentAccounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="productRefId"
          label="Product Reference ID"
          value={formData.invoiceDetails.productRefId}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </Grid>
     
      <Grid item xs={12} sm={6}>
        <TextField
          select
          name="productType"
          label="Product Type"
          value={formData.invoiceDetails.productType}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        >
          <MenuItem value="flight">Flight</MenuItem>
          <MenuItem value="car">Car Rental</MenuItem>
          <MenuItem value="hotel">Hotel</MenuItem>
          <MenuItem value="generic">Generic Product</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          select
          name="invoiceType"
          label="Invoice Type"
          value={formData.invoiceDetails.invoiceType}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        >
          <MenuItem value="regular">Regular</MenuItem>
          <MenuItem value="refund">Refund</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

