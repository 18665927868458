import Skeleton from '@mui/material/Skeleton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PackageTourBannerCard } from './PackageTourBannerCard';
import moment from 'moment';
import { IPackageTourBanner } from 'api/types';

export const PackageTourBanner: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cityIndex, setcityIndex] = useState<number>(0);
    const {t} = useTranslation()

interface MockBanners {
 banners:  IPackageTourBanner []
}
    
const mockBanners: MockBanners = {
  banners: [
    {
      packageLocation: {
        subTitle: "Türkei",
        title: "Kemer & Beldibi",
        type: "REGION",
        key: "REGION:1264",
      },
      dateRange: {
        startDate: moment().add(3, "months"),
        endDate: moment().add(4, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "FRA",
          text: "Frankfurt Main (FRA)",
          iataCode: "FRA"
        },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://i.travelapi.com/lodging/2000000/1470000/1469400/1469323/c36aaf91_z.jpg",
    },
    {
      packageLocation: {
        subTitle: "Türkei",
        title: "Antalya & Belek",
        type: "REGION",
        key: "REGION:1335"
    },
      dateRange: {
        startDate: moment().add(4, "months"),
        endDate: moment().add(5, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "DUS",
          text: "Dusseldorf (DUS)",
          iataCode: "DUS"
      },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://i.travelapi.com/lodging/2000000/1550000/1544200/1544162/6c88a4d6_z.jpg",
    },
    {
      packageLocation: {
        subTitle: "Türkei",
        title: "Side & Alanya",
        type: "REGION",
        key: "REGION:1275"
    },
      dateRange: {
        startDate: moment().add(3, "month"),
        endDate: moment().add(4, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "BER",
          text: "Berlin Brandenburg (BER)",
          iataCode: "BER"
        },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://i.travelapi.com/lodging/2000000/1490000/1486600/1486596/13d72fdc_z.jpg",
    },
    {
      packageLocation: {
        subTitle: "Türkei - Antalya & Belek",
        title:"Antalya - Konyaalti Beach",
        type: "CITY",
        key: "REGION:1341"
      },
      dateRange: {
        startDate: moment().add(6, "month"),
        endDate: moment().add(7, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "STR",
          text: "Stuttgart (STR)",
        iataCode: "STR"
        },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://i.travelapi.com/lodging/2000000/1210000/1205200/1205182/bad0d9aa_z.jpg",
    },
    {
      packageLocation: {
        subTitle: "Türkei",
        title: "Side & Alanya",
        type: "REGION",
        key: "REGION:1275"
    },
      dateRange: {
        startDate: moment().add(6, "month"),
        endDate: moment().add(7, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "CGN",
          text: "Koln Bonn (CGN)",
        iataCode: "CGN"
        },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://flyatr.s3.amazonaws.com/n-b-827c4ecf-40f5-4851-8258-af3ed4e2c0d2.jpg",
    },
    {
      packageLocation: {
        subTitle: "Türkei",
        title: "Antalya & Belek",
        type: "REGION",
        key: "REGION:1335"
    },
      dateRange: {
        startDate: moment().add(6, "month"),
        endDate: moment().add(7, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "FRA",
          text: "Frankfurt Main (FRA)",
          iataCode: "FRA"
        },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://i.travelapi.com/lodging/11000000/10010000/10001800/10001750/d34901bb_z.jpg",
    },
    {
      packageLocation: {
        subTitle: "Türkei - Ayvalik, Cesme & Izmir",
        title: "Izmir - Kemalpasa",
        type: "CITY",
        key: "REGION:1393"
      },
      dateRange: {
        startDate: moment().add(6, "month"),
        endDate: moment().add(7, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "BER",
          text: "Berlin Brandenburg (BER)",
        iataCode: "BER"
        },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://i.travelapi.com/lodging/3000000/2540000/2534400/2534377/b89d4b69_z.jpg",
    },
    {
      packageLocation: {
        subTitle: "Türkei",
        title: "Bodrum",
        type: "REGION",
        key: "REGION:1363"
      },
      dateRange: {
        startDate: moment().add(6, "month"),
        endDate: moment().add(7, "months"),
      },
      paxList: [
        { age: 30, type: "ADULT" },
        { age: 30, type: "ADULT" },
      ],
      airportList: [
        {
          id: "DUS",
          text: "Dusseldorf (DUS)",
          iataCode: "DUS"
      },
      ],
      tripDurationAsNights: 7,
      imageUrl:
      "https://i.travelapi.com/lodging/9000000/8200000/8191600/8191597/584c541b_z.jpg",
    },
  ],
};

    

  return (
    <div className='px-8 p-4 rounded-lg border  shadow-sm'>
        <span className='text-gray-900 font-semibold text-2xl '>{t("homepage.bannerpackage")}</span>
      {isLoading && (
        <div className={`grid xl:grid-cols-4 gap-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 my-3`}>
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />

        </div>
      )}
 
      <div className='grid xl:grid-cols-4 gap-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 my-3 transition-opacity'>
      { mockBanners.banners.map((banner) => (
             <PackageTourBannerCard banner={banner}
  />

))}
      </div>
    </div>
  );
};
