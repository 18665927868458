import useSWR from 'swr';
import { InvoiceResponse, InvoiceQueryParams } from '../types/invoice';
import fetcher from 'api/helpers/fetcher';

const fetchInvoices = (url: string): Promise<InvoiceResponse> => fetcher(url);

export const useGetInvoices = (params?: InvoiceQueryParams) => {
  // If `params` is undefined, disable the query by setting the key to `null`
  const queryString = params ? new URLSearchParams(params as any).toString() : null;
  const url = queryString ? `/agency/mars/invoices?${queryString}` : null;

  const { data, error, isValidating, mutate } = useSWR<InvoiceResponse>(
    url, // Key: URL or null to disable fetch
    fetchInvoices, // Fetcher function
    {
      revalidateOnFocus: true,
      dedupingInterval: 60 * 1000, // Avoid duplicate requests for 1 minute
      fallbackData: undefined, // Use `undefined` as initial value
    }
  );

  return { data, error, isValidating, mutate };
};
