import React from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Button, 
  IconButton,
  Grid,
  Paper,
  Typography
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { useFormContext } from '../../contexts/FormContext';

interface Passenger {
  id: number;
  paxType: 'ADT' | 'CHD' | 'INF';
  gender: 'M' | 'F';
  name: string;
  surname: string;
  ticketNumber: string;
  tariff: string;
  serviceFee: number;
}

const PaxFormComponent: React.FC = () => {
  const { formData, updateFormData } = useFormContext();

  const addPassenger = () => {
    const newPassengerId = formData.passengers.length > 0 
      ? Math.max(...formData.passengers.map(p => p.id)) + 1 
      : 1;
    
    updateFormData({
      passengers: [
        ...formData.passengers,
        {
          id: newPassengerId,
          paxType: 'ADT',
          gender: 'MALE',
          name: '',
          surname: '',
          ticketNumber: '',
          tariff: '',
          serviceFee: 0
        }
      ]
    });
  };

  const removePassenger = (id: number) => {
    updateFormData({
      passengers: formData.passengers.filter(p => p.id !== id)
    });
  };

  const updatePassenger = (id: number, field: keyof (typeof formData.passengers)[0], value: string | number) => {
    updateFormData({
      passengers: formData.passengers.map(p => 
        p.id === id ? {...p, [field]: value} : p
      )
    });
  };

  return (
    <div>
      {formData.passengers.map((passenger, index) => (
        <Paper key={passenger.id} className="p-4 mb-4 relative">
          <Typography variant="h6" className="mb-4">
            Passenger {index + 1}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Passenger Type</InputLabel>
                <Select
                  value={passenger.paxType}
                  label="Passenger Type"
                  onChange={(e) => updatePassenger(passenger.id, 'paxType', e.target.value)}
                >
                  <MenuItem value="ADT">Adult</MenuItem>
                  <MenuItem value="CHD">Child</MenuItem>
                  <MenuItem value="INF">Infant</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  value={passenger.gender}
                  label="Gender"
                  onChange={(e) => updatePassenger(passenger.id, 'gender', e.target.value)}
                >
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Name"
                value={passenger.name}
                onChange={(e) => updatePassenger(passenger.id, 'name', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Surname"
                value={passenger.surname}
                onChange={(e) => updatePassenger(passenger.id, 'surname', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Ticket Number"
                value={passenger.ticketNumber}
                onChange={(e) => updatePassenger(passenger.id, 'ticketNumber', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Tariff"
                value={passenger.tariff}
                onChange={(e) => updatePassenger(passenger.id, 'tariff', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Service Fee"
                type="number"
                value={passenger.serviceFee}
                onChange={(e) => updatePassenger(passenger.id, 'serviceFee', Number(e.target.value))}
                fullWidth
              />
            </Grid>
          </Grid>
          {formData.passengers.length > 1 && (
            <IconButton 
              onClick={() => removePassenger(passenger.id)}
              className="absolute top-2 right-2"
              color="error"
              size="small"
            >
              <Delete />
            </IconButton>
          )}
        </Paper>
      ))}
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={addPassenger}
        startIcon={<Add />}
        className="mt-4"
      >
        Add Passenger
      </Button>
    </div>
  );
};

export default PaxFormComponent;

