import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { SpinnerDotted } from 'spinners-react';
import { fetcherWithoutJSON } from 'api/helpers/fetcher';
import {  confirmWheelChairSelection, getWheelChairSsr  } from 'api/helpers/api-constants';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';
import { disableButton } from 'utils/disableButton';
import { SsrSelectionProps } from './MealSelection';
import AccessibleIcon from "@mui/icons-material/Accessible";
import WheelChairOptions from 'pages/BookingDetail/components/WheelChairOptions';
import { CircularProgress } from '@mui/material';
import { BookingFlight } from 'api/types';


const WheelChairSelection: React.FC<SsrSelectionProps> = ({ bookingKey, filekey, route, isOpen, setIsOpen }) => {
    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<number | null>(null);
    const [flightId, setFlightId] = useState<number | null>(null);
    const [wheelloading, setWheelLoading] = useState<boolean>(false);
    const [selectedWheelChair, setSelectedWheelChair] = useState<string>("");
    const [isloading, setisloading] = useState<boolean>(false);
    const [flightData, setflightData] = useState<BookingFlight>();

    const {t} = useTranslation()
    interface WheelChairResponse {
      ssrList: string[];
    }

    const postSsr = async (
      key: string,
      filekey: string,
      selectedWheelChair: string,
    ): Promise<void> => {
        if(paxId && flightId){
            const url = confirmWheelChairSelection();
      const params = new URLSearchParams();
      setisloading(true)
      params.append('bookingKey', key);
      params.append('filekey', filekey);

      if (isBookwchr) {
        params.append('ssrKey', selectedWheelChair);
      } else {
        params.append('paxId', paxId.toString())
        params.append('flightId', flightId.toString())
        params.append('productCode',selectedWheelChair)
      }

      try {
        const response = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",
          credentials: "include",
          cache: "no-cache",
        });

        if (!response.ok) {
          const correlationID = response.headers.get("correlation-id");
          Swal.fire("Error Code: " + correlationID, "", "error");
        } else {
            Swal.fire({
              title: "Success!",
              text: t("flight.ssr.wchr.requestsuccess"),
              icon: "success",
              confirmButtonText: t("b2c-app.flights.inactivity-window-button"),
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          }


      } catch (error) {

        Swal.fire("Error Code: " + "null", "", "error");
        throw error;
      } finally {
        setWheelLoading(false);
        setisloading(false)
        setIsOpen(false);
      }
        }
      
    };


    //post wheelchair api

    const [wheelChairData, setWheelChairData] = useState<any>();
    const [confirmbox, setConfirmbox] = useState(false);
    const [isBookwchr, setisBookwchr] = useState<boolean>(false);
    const getssrData = async () => {
        if(paxId && flightId){
            setisloading(true)
            await fetcherWithoutJSON(
              getWheelChairSsr() +
                new URLSearchParams({
                  bookingKey: bookingKey,
                  filekey: filekey,
                  flightId: flightId.toString(),
                  paxId: paxId.toString(),
                }),
              {
                method: "GET",
      
                credentials: "include",
                cache: "no-cache",
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  return response.json();
                } else {
                  throw new Error("Something went wrong on api server!");
                }
              })
              .then((data) => {
                setWheelChairData(data.ssrList);
              })
              .catch((error) => {
                console.log(error);
                Swal.fire(t("ssr.errorfrom-airline"), "", "error");
              })
              .finally(() => {
                setisloading(false);
              });
        }
     
    };
    useEffect(() => {
      if (flightId && paxId && filekey && isBookwchr) {
        getssrData();
      }
    }, [isgetData, paxId, flightId]);

    const wheelchairdata1: any = wheelChairData;
    const handleWheelChairOptionSelect = (selectedValue: string) => {
      // Handle the selected option value here
      setSelectedWheelChair(selectedValue)
      console.log('Selected option value:', selectedWheelChair);
    };

    return (
      <div>
    
            {isOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                <ButtonClose onClick={() =>{
            setIsOpen(false)
            setFlightId(null)
            setPaxId(null)}
              }></ButtonClose>

                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <AccessibleIcon />{" "}
                      <h2 className="p-2">
                        {t("flightbooking.wheelchair-request")}
                      </h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">
                          3- {t("flightbooking.wheelchair-request")}
                        </h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.wheelchair-request")}
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.wheelchair-request")}
                        </h2>
                      )}
                    </div>

                    <div className="">
                      {
                        <div>
                          <div className="w-full  p-2">
                            <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                            <div className="  w-2/6 p-2  ">
                                      <h3 className=" font-bold">
                                        1- {t("flightbooking.flights")}
                                      </h3>
                              {route.trips?.map((trip) => (
                                        trip.flights.map((flight) => (
                                        <div key={flight.id}>
                                          <button
                                            key={flight.id}
                                            className={`w-full  border-2 p-2 text-center hover:bg-gray-100   ${
                                              flight.id === flightId
                                                ? "bg-gray-100"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              setFlightId(flight.id);
                                              setisBookwchr(route.bookWheelChairAvailable)
                                              setPaxId(null);
                                              setflightData(flight)

                                            }}
                                          >
                                            <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                              {flightId === flight.id ? (
                                                <i
                                                  className="fa fa-check text-blue-300"
                                                  aria-hidden="true"
                                                ></i>
                                              ) : null}
                                                 <span className="flex items-center space-x-2">
                                              <img className='h-5 w-5' src={flight.airlineLogo} alt='airline'></img>
                                            <span className='font-bold'>{flight.flightNumber}</span>  
                                           
                                            </span>
                                            <span>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </span>
                                            </div>
                                          </button>
                                        </div>
                                     
                                     ))
                                     ))}
                              </div>

                              
                                    <div className=" flex w-1/6 items-stretch justify-center p-2  ">
                                      {flightId && (
                                        <div>
                                          <h3 className=" font-bold">
                                            2- {t("flightbooking.passengers")}
                                          </h3>
                                          <div>
                                           
                                              <div>
                                                {route.passengers.map((p) => (
                                                  <div key={p.id}>
                                                    <button
                                                      key={p.id}
                                                      className={`w-full  border-2 p-2 text-center hover:bg-gray-100   ${
                                                        p.id === paxId
                                                          ? "bg-gray-100"
                                                          : ""
                                                      }`}
                                                      onClick={() => {
                                                        setPaxId(p.id);
                                                        //setIsgetData(!isgetData);
                                                        {
                                                        }
                                                      }}
                                                    >
                                                      <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                        {paxId === p.id ? (
                                                          <i
                                                            className="fa fa-check text-blue-300"
                                                            aria-hidden="true"
                                                          ></i>
                                                        ) : null}

                                                        <p className="">
                                                          {p.firstname}
                                                        </p>
                                                        <p className="font-bold">
                                                          {p.surname}
                                                        </p>
                                                      </div>
                                                    </button>
                                                  </div>
                                                ))}
                                              </div>
                                         {" "}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  
                            
                              <div className=" w-4/6 p-2 mx-1 ">
                              { wheelChairData ?  wheelChairData.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}
                                  {paxId && flightId && !isBookwchr  && <div className="grid grid-flow-col pt-3">
                                  <WheelChairOptions onSelect={handleWheelChairOptionSelect} />
                                     <button
                                     id="confirmWchr"
                                     className="p-2 bg-primary-6000 rounded hover:bg-primary-600 text-white"
                                    onClick={() => {
                                      const button = document.getElementById("confirmWchr") as HTMLButtonElement | null;
                                      if (button) {
                                        disableButton([button]);
                                     }
                                      postSsr(
                                        bookingKey,
                                        filekey,
                                        selectedWheelChair,

                                      ); //post ssr func will come
                                      setWheelLoading(
                                        true
                                      );

                                    }}
                                    disabled={
                                        flightData &&
                                        flightData.ssrRecords &&
                                        flightData.ssrRecords.some(
                                          (rcr) => rcr.recordType === "WHEELCHAIR" && rcr.paxId === paxId && rcr.flightId === flightId
                                        )
                                      }
                                      
                                    >
                              {t("flight.ssr.wchr.request")}
                                    {wheelloading && <>
                                    <br></br><CircularProgress style={{ width: 20, height: 20 }} color="info" /> </>}
                                    </button>
                                  </div>}
                                {paxId && flightId&& flightData && flightData.ssrRecords.some(
                                 (rcr: any) => rcr.recordType === "WHEELCHAIR" && rcr.paxId === paxId && rcr.flightId === flightId)
                                ? (
                                  <span>{t("ssr.already.selected")}</span>
                                ) : (
                                  wheelChairData &&
                                  paxId &&
                                  flightId &&
                                  !isloading && (
                                    <div className="relative flex justify-center p-2 ">
                                      <div className="relative">
                                        <select
                                          className="focus:shadow-outline w-full appearance-none rounded border bg-white py-2 pl-3 pr-8 leading-tight text-gray-700 focus:outline-none"
                                          value={selectedWheelChair}
                                          onChange={(e) =>
                                            setSelectedWheelChair(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            {t("generals.select")}
                                          </option>
                                          {!wheelchairdata1 ? (
                                            <div>
                                             {t("ssr.errorfrom-airline")}
                                            </div>
                                          ) : (
                                            wheelchairdata1.map((meal: any) => (
                                              <option
                                                key={meal.ssrKey}
                                                value={meal.ssrKey}
                                              >
                                                {meal.name} - {meal.description}
                                              </option>
                                            ))
                                          )}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                          <svg
                                            className="h-4 w-4 fill-current text-gray-500"
                                            viewBox="0 0 20 20"
                                          ></svg>
                                        </div>

                                        <div className=" rounded border shadow-md">
                                          {selectedWheelChair && (
                                            <div className="p-8">
                                              <div className="border-1 text-md overflow-hidden text-center text-gray-500 ">
                                                <p className="font-bold">
                                                  {
                                                    wheelchairdata1.find(
                                                      (meal: any) =>
                                                        meal.ssrKey ===
                                                        selectedWheelChair
                                                    )?.name
                                                  }
                                                </p>
                                                <p className="">
                                                  {
                                                    wheelchairdata1.find(
                                                      (meal: any) =>
                                                        meal.ssrKey ===
                                                        selectedWheelChair
                                                    )?.description
                                                  }
                                                </p>
                                                <p className="">
                                                  {
                                                    wheelchairdata1.find(
                                                      (meal: any) =>
                                                        meal.ssrKey ===
                                                        selectedWheelChair
                                                    )?.price
                                                  }{" "}
                                                </p>
                                              </div>
                                              {confirmbox && (
                                                <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                                                  <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                                                    <div className="flex w-full justify-between p-2 ">
                                                      <ButtonClose
                                                        onClick={() =>
                                                          setConfirmbox(false)
                                                        }
                                                      ></ButtonClose>
                                                      {wheelloading && (
                                                        <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                                                      )}
                                                    </div>
                                                    <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                                                      <p className="font-bold">
                                                        {
                                                          wheelchairdata1.find(
                                                            (meal: any) =>
                                                              meal.ssrKey ===
                                                              selectedWheelChair
                                                          )?.name
                                                        }
                                                      </p>
                                                      <p className="">
                                                        {
                                                          wheelchairdata1.find(
                                                            (meal: any) =>
                                                              meal.ssrKey ===
                                                              selectedWheelChair
                                                          )?.description
                                                        }
                                                        {t("flights.price")}:{" "}
                                                        {
                                                          wheelchairdata1.find(
                                                            (meal: any) =>
                                                              meal.ssrKey ===
                                                              selectedWheelChair
                                                          )?.price
                                                        }{" "}
                                                      </p>
                                                    </div>
                                                    <div className="p-2">
                                                      <p className=" text-red-600">
                                                        {t(
                                                          "generals.attention-this-action-is-not-reversible"
                                                        )}
                                                      </p>
                                                      <p></p>
                                                      <p>
                                                        {" "}
                                                        {t(
                                                          "generals.are-you-sure-you-want-to-proceed"
                                                        )}
                                                      </p>
                                                      <div
                                                        className="inline-flex  p-2 "
                                                        role="group"
                                                      >
                                                        <button
                                                          type="button"
                                                          className="text-sm rounded-l-lg border border-gray-200 bg-red-500 px-4 py-2  font-medium text-white hover:bg-red-700 hover:text-white"
                                                          onClick={() => {
                                                            setConfirmbox(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {t(
                                                            "b2c-app.generals.cancel"
                                                          )}
                                                        </button>
                                                        <button
                                                          id="confirmWchr"
                                                          type="button"
                                                          className="text-sm rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900  hover:text-gray-200 "
                                                          onClick={() => {
                                                            const button = document.getElementById("confirmWchr") as HTMLButtonElement | null;
                                                            if (button) {
                                                              disableButton([button]);
                                                           }
                                                            postSsr(

                                                              bookingKey,
                                                              filekey,
                                                              selectedWheelChair
                                                            ); //post ssr func will come
                                                            setWheelLoading(
                                                              true
                                                            );
                                                          }}
                                                          disabled={wheelloading}
                                                        >
                                                          {t("generals.yes")}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              {!selectedWheelChair ? (
                                                <div>
                                                  {" "}
                                                  {t(
                                                    "flightbooking.wheelchair-request"
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  className="inline-flex items-center justify-center  p-2 "
                                                  role="group"
                                                >
                                                  <button
                                                    type="button"
                                                    className="text-sm rounded-l-lg border border-gray-200 bg-red-500 px-4 py-2  font-medium text-white hover:bg-red-700 hover:text-white "
                                                    onClick={() => {
                                                      //setIsExpanded(false);
                                                      setPaxId(null);
                                                      setFlightId(null);
                                                      setIsOpen(
                                                        false
                                                      );
                                                      //setSelectedMeal("");
                                                      setSelectedWheelChair("");
                                                    }}
                                                  >
                                                    {t(
                                                      "b2c-app.generals.cancel"
                                                    )}
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-medium  text-gray-900 hover:text-gray-700 "
                                                    onClick={() => {
                                                      //  setIsExpanded(false);
                                                      setConfirmbox(true);
                                                    }}
                                                  >
                                                    {t("generals.add")}
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
     
    );
  };
  export default WheelChairSelection