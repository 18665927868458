import React, { useMemo } from "react";
import { useParams } from "react-router";
import { AtrDateFormatForSearchRequest } from "../calendar";

export default () => {

  const params = useParams();

  const searchParams = useMemo(() => {
    try {
      const depDepartureAirport = JSON.parse(params.depDepartureAirport);
      const depArrivalAirport = JSON.parse(params.depArrivalAirport);
      return {
        status: "ok",
        depDepartureAirport: depDepartureAirport,
        depArrivalAirport: depArrivalAirport,
        depDate: params.depDate,
        retDate: params.retDate,
        adtCount: params.selectedAdtCount,
        chdCount: params.selectedChdCount,
        infCount: params.selectedInfCount,
        cabinClass: params.cabinClass,
        roundtrip: params.roundtrip,
        multiFlights: params.multiFlights,
      };
    } catch {
      return {
        status: "error",
      };
    }
  }, [
    params.depDepartureAirport,
    params.depArrivalAirport,
    params.depDate,
    params.retDate,
    params.selectedAdtCount,
    params.selectedChdCount,
    params.selectedInfCount,
    params.cabinClass,
    params.roundtrip,
    params.multiFlights,
  ]);

  return searchParams;
};

export const createFlightQueryString = (
  searchparameter,
  departureDepPort,
  departureRetPort,
  multiFlights,
  isMultiFlights,
  isRoundtrip
) => {
  for (let i = 0; i < departureDepPort.length; i++) {
    departureDepPort[i].text = departureDepPort[i].text.replace(/\//g, "");
  }

  for (let i = 0; i < departureRetPort.length; i++) {
    departureRetPort[i].text = departureRetPort[i].text.replace(/\//g, "");
  }

  if (isRoundtrip && !isMultiFlights) {
    return `/${encodeURI(JSON.stringify(departureDepPort))}/${encodeURI(
      JSON.stringify(departureRetPort)
    )}/${searchparameter.originDestinationInformation[0].date}/${
      searchparameter.originDestinationInformation[1].date
    }/${searchparameter.adtCount}/${searchparameter.chdCount}/${
      searchparameter.infCount
    }/${searchparameter.cabinClass}/${isRoundtrip}/${isMultiFlights}`.replace(
      /"/g,
      ""
    );
  } else if (!isRoundtrip && !isMultiFlights) {
    return `/${encodeURI(JSON.stringify(departureDepPort))}/${encodeURI(
      JSON.stringify(departureRetPort)
    )}/${searchparameter.originDestinationInformation[0].date}/${
      searchparameter.adtCount
    }/${searchparameter.chdCount}/${searchparameter.infCount}/${
      searchparameter.cabinClass
    }/${isRoundtrip}/${isMultiFlights}`.replace(/"/g, "");
  } else if (isMultiFlights) {
    let airports = "";
    let dates = "";
    for (let i = 0; i < multiFlights.length; i++) {
      airports += `${encodeURI(
        JSON.stringify(multiFlights[i].departurePort)
      )}-${encodeURI(JSON.stringify(multiFlights[i].returnPort))}`;
      dates += AtrDateFormatForSearchRequest(
        multiFlights[i].selectedFlightDate
      );
      if (i + 1 < multiFlights.length) {
        airports += "+";
        dates += "+";
      }
    }

    console.log(airports, dates, searchparameter, isMultiFlights)
    return `/${airports}/${dates}/${searchparameter.adtCount}/${searchparameter.chdCount}/${searchparameter.infCount}/${searchparameter.cabinClass}/true/${isMultiFlights}`.replace(
      /"/g,
      ""
    );
  }
};
