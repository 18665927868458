import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import OutsideClickHandler from "react-outside-click-handler";
import Calendar from "react-calendar";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export function AtrSearchDatepicker() {
  const classes = useStyles();
  return (
    <TextField
      id="date"
      label="Abflugdatum"
      type="date"
      defaultValue=" "
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export function AtrDate(date) {
  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  Date.prototype.reduceDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
  };

  if (typeof date === "undefined") {
    return new Date();
  }

  return new Date(date);
}

export function AtrDateFormat(date, withYear = true) {
  let day = date.getDate();
  let month = date.getMonth();
  month = month + 1;

  if (String(day).length === 1) day = "0" + day;
  if (String(month).length === 1) month = "0" + month;

  let dateT = day + "." + month + "." + date.getFullYear();
  if (!withYear) {
    dateT = day + "." + month + ".";
  }

  return dateT;
}

export function AtrDateFormatShortYear(date) {
  let day = date.getDate();
  let month = date.getMonth();
  month = month + 1;

  if (String(day).length === 1) day = "0" + day;
  if (String(month).length === 1) month = "0" + month;

  let dateT = day + "." + month + "." + date.getFullYear().toString().slice(-2);

  return dateT;
}

export function AtrDateMonthFormat(date, withYear = false) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let monthCode = "";

  switch (month) {
    case 1:
      monthCode = "Jan.";
      break;
    case 2:
      monthCode = "Feb.";
      break;
    case 3:
      monthCode = "Mär.";
      break;
    case 4:
      monthCode = "Apr.";
      break;
    case 5:
      monthCode = "Mai";
      break;
    case 6:
      monthCode = "Jun.";
      break;
    case 7:
      monthCode = "Jul.";
      break;
    case 8:
      monthCode = "Aug.";
      break;
    case 9:
      monthCode = "Sep.";
      break;
    case 10:
      monthCode = "Okt.";
      break;
    case 11:
      monthCode = "Nov.";
      break;
    case 12:
      monthCode = "Dez.";
      break;
  }

  if (withYear) {
    return day + ". " + monthCode + " " + year;
  } else {
    return day + ". " + monthCode;
  }
}

export function AtrDateFullMonthFormat(date, withYear = false) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let monthCode = "";

  switch (month) {
    case 1:
      monthCode = t("react.month.month_1");
      break;
    case 2:
      monthCode = t("react.month.month_2");
      break;
    case 3:
      monthCode = t("react.month.month_3");
      break;
    case 4:
      monthCode = t("react.month.month_4");
      break;
    case 5:
      monthCode = t("react.month.month_5");
      break;
    case 6:
      monthCode = t("react.month.month_6");
      break;
    case 7:
      monthCode = t("react.month.month_7");
      break;
    case 8:
      monthCode = t("react.month.month_8");
      break;
    case 9:
      monthCode = t("react.month.month_9");
      break;
    case 10:
      monthCode = t("react.month.month_10");
      break;
    case 11:
      monthCode = t("react.month.month_11");
      break;
    case 12:
      monthCode = t("react.month.month_12");
      break;
  }

  if (withYear) {
    return day + ". " + monthCode + " " + year;
  } else {
    return day + ". " + monthCode;
  }
}

export function AtrDateFormatForSearchRequest(date) {
  let day = date.getDate();
  let month = date.getMonth();
  month = month + 1;

  if (String(day).length == 1) day = "0" + day;
  if (String(month).length == 1) month = "0" + month;

  let dateT = date.getFullYear() + "-" + month + "-" + day;

  return dateT;
}

export function AtrTimeFormat(date) {
  let time = date.getHours() + ":" + date.getMinutes();

  let counts = time.split(":");
  let newTime = "";

  if (counts[0].length == 1) {
    newTime += "0" + counts[0] + ":";
  } else {
    newTime += counts[0] + ":";
  }

  if (parseInt(counts[1]) < 10) {
    newTime += "0" + counts[1];
  } else {
    newTime += counts[1];
  }

  return newTime;
}

export function CalcExpiredDate(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

export function DateFromAtrDateFormat(date) {
  return new Date(
    date.split(".")[1] + "." + date.split(".")[0] + "." + date.split(".")[2]
  );
}

export function AtrDateTimeFromString(date, hour, minute) {
  let dateTime = DateFromAtrDateFormat(date);
  dateTime.setHours(hour);
  dateTime.setMinutes(minute);

  return dateTime;
}
