import React, { FormEvent, useEffect, useState } from "react";
import GuestsInput, { RoomInput } from "../GuestsInput";
import { FocusedInputShape } from "react-dates";
import DatesRangeInput from "../DatesRangeInput";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { getTourSearchPageUrl } from "utils/navigationLinks";
import useRecentSearches from "hooks/useRecentSearches";
import { SearchPayload, TourSearchAutocompleteResult } from "api/types";
import AutocompleteInput from "../AutocompleteInput";
import { getTourSearchAutocompleteUrl } from "api/helpers/api-constants";
import TourQueryParametersService from "../../../../services/TourQueryParametersService";
import { useDispatch } from "react-redux";
import { SetTabSelections } from "redux/slices/tabsSlice";
import { useTranslation } from "react-i18next";

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface TourSearchFormProps {
  queryParameters: SearchPayload | null;
}
const TourSearchForm: FC<TourSearchFormProps> = ({ queryParameters }) => {
  const { appendRecentSearch } = useRecentSearches("recentSearches");

  const [tour, setTour] = useState<TourSearchAutocompleteResult | null>({
    id: "100000",
    label: "Tümü",
  });

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: queryParameters?.afterDate
      ? moment(queryParameters?.afterDate)
      : moment().add(5, "days"),
    endDate: queryParameters?.beforeDate
      ? moment(queryParameters?.beforeDate)
      : moment().add(20, "days"),
  });

  const [guests, setGuests] = useState<Array<RoomInput>>([
    { adults: 2, children: [] },
  ]);

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  const [missingInputError, setMissinInputError] = useState({
    location: false,
    afterDate: false,
    beforeDate: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (queryParameters?.searchType === "Tour") {
      if (queryParameters?.destinationId && queryParameters?.label) {
        setTour({
          id: queryParameters?.destinationId,
          label: queryParameters?.label,
        });
      }

      if (queryParameters?.beforeDate && queryParameters?.afterDate) {
        setDateRange({
          startDate: moment(queryParameters?.afterDate),
          endDate: moment(queryParameters?.beforeDate),
        });
      }

      if (queryParameters?.roomOccupancies) {
        setGuests(queryParameters?.roomOccupancies);
      }
    }
  }, [JSON.stringify(queryParameters)]);

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    dispatch(SetTabSelections({ currentTab: "Tour" }));

    setMissinInputError({
      location: !tour,
      afterDate: !dateRange.startDate,
      beforeDate: !dateRange.endDate,
    });

    const urlSearchParams = TourQueryParametersService.encode(
      tour,
      dateRange,
      guests
    );

    if (urlSearchParams) {
      const recentData = {
        type: "tour",
        tour: tour?.label,
        dateRangeTour: dateRange,
        guestsTour: guests[0]?.adults,
        url:   "tour-search?" + urlSearchParams.toString(),
      };

      navigate(getTourSearchPageUrl(tour!.id) + "?" + urlSearchParams);
      appendRecentSearch(recentData);
    }
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="relative mt-0 w-full rounded-b-lg bg-white shadow-sm dark:bg-neutral-900 dark:shadow-2xl"
      >
        <div className=" [ nc-divide-field ] flex w-full flex-col rounded-full md:flex-row md:items-center ">
          <div className="[ nc-divide-field ] relative flex flex-grow flex-col md:flex-row">
            <AutocompleteInput
              defaultValue={{ id: "12", label: "test" }}
              category="tour"
              placeHolder={t("react.hotel.location")}
              desc={t("react.generel.tour.route")}
              autocompleteValue={tour}
              setAutocompleteValue={setTour}
              localStorageToken="tour-search-form-autocomplete"
              getUrl={getTourSearchAutocompleteUrl}
              onInputDone={() => setDateFocused("startDate")}
              error={missingInputError.location}
            />

            <DatesRangeInput
              value={dateRange}
              defaultValue={dateRange}
              defaultFocus={dateFocused}
              prRanges={false}
              onFocusChange={(focus) => setDateFocused(focus)}
              onChange={(data) => setDateRange(data)}
              checkinDateError={missingInputError.afterDate}
              checkoutDateError={missingInputError.beforeDate}
            />
            <div className="my-auto">
              <GuestsInput guestValue={guests} setGuestValue={setGuests} />
            </div>
            {/* BUTTON SUBMIT OF FORM */}
            <div className="justify center flex items-center px-4 py-4 lg:py-0">
              <SubmitButton />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TourSearchForm;
