import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface SubNavItem {
  text: string;
  href: string;
}

interface NavItem {
  icon: React.ElementType<SvgIconProps>;
  text: string;
  subnav?: SubNavItem[];
}

interface SidebarProps {
  nav: NavItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ nav }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);

  const toggleAccordion = (index: number): void => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div
      className={`fixed top-10 left-0 h-full mb-32 w-64 bg-slate-50 text-gray-900 p-5 transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0`}
    >
      <nav>
        <ul className="space-y-2">
          {nav.map((item, index) => (
            <li key={index}>
              <button
                className="flex items-center justify-between w-full p-2 text-gray-900 rounded-md hover:bg-slate-100"
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex items-center">
                  <div className="mr-3 p-2 bg-gray-200 rounded-md">
                    <item.icon className="text-gray-600" />
                  </div>
                  <span>{item.text}</span>
                </div>
                {item.subnav && (
                  activeAccordion === index ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                )}
              </button>
              {item.subnav && activeAccordion === index && (
                <ul className="ml-6 mt-2 space-y-1">
                  {item.subnav.map((subitem, subIndex) => (
                    <li key={subIndex}>
                      <a
                        href={"#" + subitem.href}
                        className="block p-2 rounded-md text-gray-900 hover:bg-slate-100"
                      >
                        {subitem.text}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;

