import React, { useEffect, useState } from 'react';
import { BookingRoute, FlightOffer } from 'api/types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReissueSearchForm from './ReissueSearchForm';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';
import ButtonPrimary from 'components/ui/Button/ButtonPrimary';
import CachedIcon from '@mui/icons-material/Cached';
import { ReissueSummary } from './ReissueSummary';
import ResissueFlightList from './ReissueFlightList';
import { useTranslation } from 'react-i18next';
import { useReduxReducer } from 'redux/store';
import Tooltip from '@mui/material/Tooltip';
interface IReissue {
  route: BookingRoute;
  bookingKey: string;
  tripIndex: number;

}

const ReissueModal: React.FC<IReissue> = ({ route , bookingKey, tripIndex}) => {
  const {t} = useTranslation()
  const {isAdmin} = useReduxReducer(state => state.general)
  const [open, setOpen] = useState(false);
  const [newFlight, setnewFlight] = useState<FlightOffer | null>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [flighList, setflighList] = useState<FlightOffer[]>([]);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const handleFlightList = (offers: FlightOffer[]) => {
    setflighList(offers)
    setnewFlight(undefined)
  };
  const handleNewFlight = (offer: FlightOffer | null) => {
    if(offer)
    setnewFlight(offer)
  }
const oldFlight = 
       route.trips[tripIndex]
    
useEffect(() => {
  setflighList([])
  setnewFlight(null)
}, [open]);
  return (
    <>
   <div className='flex justify-end px-4 mt-xl-n4'>
      <ButtonPrimary  onClick={handleOpen}>
        <CachedIcon/>
         {t("flightcases.reissue-request")}
      </ButtonPrimary></div>
      <Dialog open={open} onClose={handleClose} fullWidth ={true} maxWidth={false}>
      <ButtonClose  onClick={handleClose} />
            
        <DialogTitle> PNR: {route.filekey}</DialogTitle>
        <DialogContent>
            <div className='h-screen grid grid-cols-12 p-4 gap-4 md:flex-row'>
              <div className='col-span-8'>
              <ReissueSearchForm route={route} bookingKey={bookingKey} onFlightList={handleFlightList}  tripIndex={tripIndex} />
            {flighList && flighList.length > 0 && <ResissueFlightList list={flighList} isLoading={isLoading} onNewFlightSelect={handleNewFlight}/>}
              </div>
           <div className=' col-span-4'>
           <ReissueSummary oldFlight={oldFlight} newFlight={newFlight} route={route} bookingKey={bookingKey} flightTripIndex={tripIndex}/>
           </div>
            </div>
      
        </DialogContent>
        <DialogActions>
         
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReissueModal;
