import {
    getIssueBookingUrl,
    getViewBookingUrl
} from "api/helpers/api-constants";
import PaymentButton from "../PaymentButton";
import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import {getStaticUrl} from "utils/getStaticUrl";
import {useReduxReducer} from "redux/store";
import {IBookingData, IRetrieveBookingData, PnrModel} from "api/types";
import {Dialog} from "primereact/dialog";
import {LoadingOverlay} from "../../../components/common/Loading/LoadingOverlay";


interface IFooterButtons {
    bookingKey: string
    data1: IBookingData
    newData: IRetrieveBookingData
    status: string
    pnrdata: PnrModel[]
}

export const FooterButtons: FC<IFooterButtons> = ({bookingKey, data1, newData, status, pnrdata}) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation()
    const {audit, product} = useReduxReducer(state => state.config)

    const handleReiseplan = (): void => {
        const pdfUrl = getViewBookingUrl(bookingKey!) + '.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = bookingKey + '.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const sendEmail = () => {
        const email = prompt("E-Mail");
        if (email === null) return;

        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    alert("E-Mail'iniz başarılı bir şekilde gönderilmiştir!");
                } else {
                    alert("E-Mail gönderilemedi!");
                }
            }
        };

        xhr.open(
            "POST",
            getViewBookingUrl(bookingKey!) + `/sendMail`,
            true
        );
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.send(`emailAdress=${email}`);
    };

    const handlemodal = () => {
        setModal(!modal);
    };

    function cancelBooking(): void {
        const confirmation = window.confirm(
            t("generals.are-you-sure-you-want-to-proceed")
        );

        if (confirmation) {
            setLoading(true);
            const form = document.forms[0];
            form.action = getStaticUrl('b2b') + "/agency/cancelBooking";
            form.addEventListener('submit', () => {
                setLoading(false);
            });
            form.submit();
        }
    }

    if (data1.status === "CANCELED") return null;

    return (
        <div className={`mx-2 pb-3`}>
            {loading && <LoadingOverlay />}
            <div>
                {(data1.status === "TICKETED" ||
                    data1.status === "RESERVATION" ||
                    data1.status === "CONFIRMED") && (
                    <div className="flex justify-center items-center">
                        <button
                            type="button"
                            onClick={() => handleReiseplan()}
                            className="text-sm justify-center rounded-l-lg border  border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                        >
                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i><br/>
                            {t("pdf.booking")}
                        </button>

                        <button
                            type="button"
                            id="sendEmailButton"
                            onClick={sendEmail}
                            className="text-sm border-t border-b text-nowrap border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                        >
                            <i className="fa fa-envelope-o" aria-hidden="true"></i><br/>
                            {t("generals.email")}
                        </button>

                        {(data1.status === "TICKETED" ||
                            data1.status === "RESERVATION" ||
                            data1.status === "CONFIRMED") && data1.status === "RESERVATION" && (
                            <>
                                <button
                                    type="button"
                                    onClick={cancelBooking}
                                    className="text-sm border-t border-b border-l border-gray-200 bg-white px-4  py-2 text-center font-medium text-gray-900 hover:bg-red-900 hover:text-red-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                                >
                                    <i className="fa fa-trash-o text-red-500" aria-hidden="true"></i><br/>
                                    {t("booking.cancel")}
                                </button>
                                <button
                                    type="button"
                                    onClick={handlemodal}
                                    className="text-sm rounded-r-lg border border-primary-6000 bg-primary-6000 px-4 py-2 text-center font-medium text-white  hover:bg-primary-500"
                                >
                                    <i className="fa fa-credit-card" aria-hidden="true"></i><br/>
                                    {t("booking.purchase")}
                                </button>
                                <form>
                                    <input
                                        type="hidden"
                                        name="bookingKey"
                                        value={data1.bookingKey}
                                    ></input>

                                    <input
                                        type="hidden"
                                        name="filekey"
                                        value={pnrdata.map((row) => row.filekey)}
                                    ></input>
                                </form>
                            </>
                        )
                        }
                    </div>
                )}
            </div>

            <Dialog
                className={`w-full`}
                header={false}
                visible={modal}
                position={'bottom'}
                footer={() => {}}
                style={{ height: '25%', margin: 0}}
                onHide={handlemodal}
                draggable={false}
                resizable={false}
            >
                <div>
                    <div className="w-full py-1">
                        <PaymentButton
                            path={getIssueBookingUrl()}
                            params={{
                                bookingKey: data1.bookingKey,
                                paymentType: "INVOICE",
                            }}
                        />
                    </div>
                    <div className="w-full py-1">
                        <PaymentButton
                            path={getIssueBookingUrl()}
                            params={{
                                bookingKey: data1.bookingKey,
                                paymentType: "WALLET",
                            }}
                        />
                    </div>
                    {/*<div className="w-full py-1">*/}
                    {/*    <PaymentButton*/}
                    {/*        path={getIssueBookingWithStripeUrl()}*/}
                    {/*        params={{*/}
                    {/*            bookingKey: data1.bookingKey,*/}
                    {/*            paymentType: "KLARNA",*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className="w-full py-1">*/}
                    {/*    <PaymentButton*/}
                    {/*        path={getIssueBookingWithStripeUrl()}*/}
                    {/*        params={{*/}
                    {/*            bookingKey: data1.bookingKey,*/}
                    {/*            paymentType: "STRIPE",*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*{data1.airlineCreditCardAvailable && (*/}
                    {/*    <div className="w-full py-1 px-2">*/}
                    {/*        <Accordion defaultExpanded={false}>*/}
                    {/*            <AccordionSummary*/}
                    {/*                aria-controls="payment-details-content"*/}
                    {/*                id="payment-details-header"*/}
                    {/*                sx={{p: 0, borderBottom: 'none'}}*/}
                    {/*            >*/}
                    {/*                <Button*/}
                    {/*                    variant="contained"*/}
                    {/*                    color="primary"*/}
                    {/*                    className="w-full"*/}
                    {/*                >Airline CC</Button>*/}
                    {/*            </AccordionSummary>*/}
                    {/*            <AccordionDetails>*/}
                    {/*                <CreditCardForm*/}
                    {/*                    bookingKey={newData.bookingKey}*/}
                    {/*                    totalAmount={newData?.totalAmount}*/}
                    {/*                />*/}
                    {/*            </AccordionDetails>*/}
                    {/*        </Accordion>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </Dialog>
        </div>
    );
};
