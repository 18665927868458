import { Skeleton, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAdvertisement } from 'api/helpers/api-constants';
import fetcher from 'api/helpers/fetcher';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import currencyFormat from 'utils/currencyFormat';

type Props = {
  className?: string;
};

type CardProps = {
  title: string;
  text: string;
  imageUrl: string;
  price: number;
  targetLink: string;
  enabled: boolean;
};

const CheapFlightCard: React.FC<CardProps> = ({ title, text, imageUrl, price, targetLink, enabled }) => {
  if (!enabled) {
    return null;
  }

  return (
    <div className="bg-white w-100 rounded-lg shadow-md overflow-hidden transition duration-200 ease hover:shadow-2xl flex-flow-row">
  <a href={targetLink} target="_blank" rel="noopener noreferrer" className="text-no-underline">
    <figure className="w-full">
      <div className="relative h-2/3">
        <img src={imageUrl} alt={title} className="object-cover h-48 w-full" />
      </div>
    </figure>
   
    <div className="grid grid-rows-2 row-span-3 items-center p-2 h-24">
      <div className="gap-2 text-md font-bold items-center text-gray-900 mt-2">
        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">{title}</span> 
      </div>
      <div className="flex justify-between items-center"> 
        <span className="items-center text-sm gap-2 text-primary-6000">{text}</span>
        <span className="text-primary-6000 font-semibold text-xl px-1">{currencyFormat(price)}</span>
      </div>
    </div>
  </a>
</div>

  
  );
};

const BestOffers: React.FC<Props> = ({ className }) => {
  const [data, setData] = useState<CardProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    getAdvertisementData();
  }, []);

  const getAdvertisementData = async () => {
    try {
      const response = await fetcher(getAdvertisement());
      if (response) {
        setData(response.filter((item: any)=> item.enabled));
      } else {
        throw new Error('Something went wrong on the API server!');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
console.log(data)
  return (
    <>
      <div className="px-8 p-4 rounded-lg border">
        <span className="text-gray-900 font-semibold text-2xl">{t("homepage.bannerhotel")}</span>
        <div className={`grid xl:grid-cols-4 gap-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 my-3 ${className}`}>
          {isLoading ? (
            <>
              <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
              <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
              <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
              <Skeleton variant="rectangular" width={288} height={288} animation="wave" />

            </>
          ) : (
            <>
              {data.slice(0,8).map((item, index) => (
                <CheapFlightCard
                  key={index}
                  title={item.title}
                  text={item.text}
                  imageUrl={item.imageUrl}
                  price={item.price}
                  targetLink={"/ibe#" + item.targetLink}
                  enabled={item.enabled}
                />
              ))}
              
            </>
          )}
        </div>
        {data.length > 8 && (
               
                <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      border: 0,
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '&.Mui-expanded': {
                        minHeight: 'auto',
                      },
                    }}
                  >
                 <div className="w-full rounded-xl shadow-none border-none">{t("generals.bannermorehotels")}</div>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div className="grid xl:grid-cols-4 gap-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 my-3">
                      {data.slice(8).map((item, index) => (
                        <CheapFlightCard
                          key={index + 8}
                          title={item.title}
                          text={item.text}
                          imageUrl={item.imageUrl}
                          price={item.price}
                          targetLink={"/ibe#" + item.targetLink}
                          enabled={item.enabled}
                        />

                      ))} </div>
                  </AccordionDetails>
                </Accordion> 
                                   

              )}
      </div>
    </>
  );
};

export default BestOffers;
