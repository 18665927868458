import { Popover, Transition } from "@headlessui/react";
import Input from "components/ui/Input/Input";
import React, { Fragment } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { t } from "i18next";
import { getSearchBookingUrl } from "../../../api/helpers/api-constants";
import { useNavigate } from "react-router-dom";
import { fetcherWithoutJSON } from "api/helpers/fetcher";

const SearchDropdown = () => {

  const inputRef = React.createRef<HTMLInputElement>();

  const navigate = useNavigate();

  const keyboardHandler = async (event: any, close: any) => {
    if (event.key === "Enter" && inputRef.current?.value !== undefined) {
      await fetcherWithoutJSON(getSearchBookingUrl(inputRef.current?.value)).then((response) => response.json()).then((data) => {
        if (data.status === 'success') {
          navigate(`/booking/detail/${data.bookingKey}`)
          setTimeout(() => {
            close()
          }, 100);
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open, close }) => {
          if (open) {
            setTimeout(() => {
               inputRef.current?.focus();
            }, 100);
          }

          return (
            <>
              <Popover.Button 
                className="flex h-12 w-12 items-center justify-center rounded-l text-2xl text-neutral-700 hover:bg-neutral-100 focus:outline-none dark:text-neutral-300 dark:hover:bg-neutral-800 md:text-[22px]"
              >
                <AiOutlineSearch />
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  
                  className="absolute right-0 z-10 mt-3 w-screen max-w-sm flex justify-between focus:outline-none"
                >
                  <div className="flex w-full justify-end">
                    <Input
                      ref={inputRef}
                      type="search"
                      placeholder={t("navbar.search-filekey")}
                      onKeyDown={(event) => keyboardHandler(event, close)}
                    />
                    <button
                      type="submit"
                      className="flex items-center justify-center px-1 rounded-r bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none"
                      onClick={() => {
                        keyboardHandler({ key: "Enter" }, close);
                      }}
                    >
                      <span className="sr-only">{t("app.search")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1.5}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </button>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

export default SearchDropdown;
