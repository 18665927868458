import React, {FC, ReactNode, useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {useReduxReducer} from "../../redux/store";
import {setIsApp} from "../../redux/slices/generalSlice";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {IBookingData, IRetrieveBookingData, UserModel} from "../../api/types";
import fetcher from "../../api/helpers/fetcher";
import {bookingRetrieveUrl, getGetBookingUrl, getHost, getUserDataUrl} from "../../api/helpers/api-constants";
import {AgencyInformation} from "./components/AgencyInformation";
import {PassagierInformation} from "./components/PassagierInformation";
import {FlightInformation} from "./components/FlightInformation";
import {copyHandler} from "../../utils/copyHandler";
import {RentalcarInformation} from "./components/RentalcarInformation";
import {HotelReservationInformation} from "./components/HotelReservationInformation";
import './BookingDetail.css'
import {useTranslation} from "react-i18next";
import { FooterButtons } from "./components/FooterButtons";
import Timer from "../../components/common/Timer/Timer";

export const MobileBookingDetail: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch()
    const { isApp } = useReduxReducer(state => state.general)
    const { audit }= useReduxReducer(state => state.config)

    const { bookingKey } = useParams<{ bookingKey: string }>();

    const [ userRole, setUserRole ] = useState<string>("")
    const [ user, setUser ] = useState<UserModel|null>(null)
    const [ isAirlinecc, setIsAirlinecc ] = useState<boolean>(false)
    const [ agencyEmail, setAgencyEmail ] = useState<string|null>(null)
    const [ bookingData, setBookingData ] = useState<IBookingData|null>(null)
    const [ retrieveData, setRetrieveData ] = useState<IRetrieveBookingData|null>(null)
    const [ retrieveDataError, setRetrieveDataError ] = useState<boolean>(false)

    useEffect(() => {
        if (!isApp)
            dispatch(setIsApp(true))
    }, [isApp])

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await fetcher(getUserDataUrl());

                if (response) {
                    setUserRole(response.userRole);
                    setIsAirlinecc(response.airlineCreditCardAllowed)
                } else {
                    throw new Error('Something went wrong on the API server!');
                }
            } catch (error) {
                console.error(error);
            }
        };

        getUserInfo();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const response = await fetcher(getGetBookingUrl(bookingKey!), {
                method: 'GET',
               
            })

            if (response) {
                setBookingData(response)
                setAgencyEmail((response as IBookingData).email)
                setUser((response as IBookingData).user)
            }
        }

        if (bookingKey)
            getData()
    }, [bookingKey])

    useEffect(() => {
        const getData = async () => {
            const response = await fetcher(bookingRetrieveUrl(bookingKey!, ''), {
                method: 'POST',
               
            })

            if (response.hasOwnProperty('status') && response.status === 'error') {
                setRetrieveDataError(true)
                setRetrieveData(null)
            } else {
                setRetrieveDataError(false)
                setRetrieveData(response as IRetrieveBookingData)
            }
        }

        if (agencyEmail)
            getData()
    }, [agencyEmail])

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className={`bookingdetail`}>
            <div className={`flex ${location.state ? 'justify-between' : 'justify-end' } items-center my-3 mx-2`}>
                {location.state &&
                    <div className={`text-gray-600`} onClick={handleGoBack}>
                        <i className="pi pi-arrow-left" style={{ fontSize: '1.2rem' }}></i>
                    </div>
                }
                <div onClick={() => copyHandler(bookingKey!)} className={`flex gap-x-2 text-l px-4 py-0.5 rounded text-center bg-gray-200 border border-gray-600 cursor-pointer`}>
                    <div><i className="pi pi-copy"></i></div>
                    <div>{bookingKey}</div>
                </div>
            </div>
            {
                retrieveDataError && (
                    <div className={`m-2`}>{t('generals.error-info')}</div>
                )
            }
            {
                (retrieveData && bookingData && !retrieveDataError) && (
                    <>
                        {/*{userRole === 'ADMIN' && <div className={`my-3 mx-2`}>### ATR ONLY ###</div>}*/}

                        <AgencyInformation
                            agencyName={user ? user.agency!.name! : ''}
                            email={retrieveData.email}
                            phone={retrieveData.phone}
                            agencyNumber={user ? user.agency!.agencyNumber! : ''}
                        />

                        {/*{userRole === 'ADMIN' && <div className={`my-3 mx-2`}>### NOTIZEN ###</div>}*/}

                        <div className="flex justify-end px-4">
                            { audit.marketingType === "B2B"  && <Timer initialTime={retrieveData.remainingOptionTimeInSeconds} />}
                        </div>

                        {retrieveData.pnrList.length > 0 && (
                            <PassagierInformation
                                paxList={retrieveData.mergedPaxList}
                                totalAmount={retrieveData.totalAmount}
                                serviceFee={retrieveData.serviceFeePerPax}
                            />
                        )}

                        {retrieveData.pnrList.length > 0 && (
                            bookingData.pnrList.map((pnr, index) => (
                                <FlightInformation
                                    bookingKey={bookingKey!}
                                    flightTrip={pnr}
                                    index={index}
                                    status={retrieveData.status}
                                    statusColorCode={retrieveData.statusColorCode}
                                />
                            ))
                        )}

                        {retrieveData.restRentalcarReservation && (
                            <RentalcarInformation
                                rentalcarInformation={retrieveData.restRentalcarReservation}
                                status={retrieveData.status}
                                statusColorCode={retrieveData.statusColorCode}
                                supplierReservationNumber={bookingData.rentalCarReservation!.supplierReservationNumber}
                            />
                        )}

                        {retrieveData.hotelReservation && (
                            <HotelReservationInformation
                                hotelData={retrieveData.hotelReservation}
                                statusColorCode={retrieveData.statusColorCode}
                                status={retrieveData.status}
                            />
                        )}
                        {retrieveData && bookingData &&
                            <FooterButtons
                            bookingKey={retrieveData.bookingKey}
                            data1={bookingData}
                            newData={retrieveData}
                            pnrdata={bookingData.pnrList}
                            status={retrieveData.status} />
                        }
                    </>
                )
            }
        </div>
    )
}

export const splitEmail = (email: string): ReactNode => {
    if (!email.includes('@'))
        return email

    const _email: string[] = email.split('@')

    return (
        <>
            <div>{_email[0]}@</div>
            <div>{_email[1]}</div>
        </>
    )
}
