import { TourPackageBookingDetailResponse } from "api/types";
import FlightTripDetailPackage from "pages/PackageTour/PackageTourHotel/components/FlightTripDetailPackage";
import HotelHeader from "pages/PackageTour/PackageTourHotel/components/HotelHeader";
import { FaBed, FaUtensils, FaCar, FaPersonBooth, FaUser, FaChild, FaBaby, FaSun, FaMoon } from "react-icons/fa";
import currencyFormat from "utils/currencyFormat";
import { formatDate } from "utils/formatDate";
import { Table } from '@mui/material'; 
import { useTranslation } from "react-i18next";



interface PackageTourDetailProps {
    packageTour: TourPackageBookingDetailResponse;
   }

export const PackageTourDetail: React.FC<PackageTourDetailProps> = ({ packageTour }) => {
  const {t} = useTranslation()
    function countPaxType(array :any, paxtype:string) {
        const filteredPassengers = array.filter((item: any) => item.type === paxtype);
        return filteredPassengers.length;
      }
      function calculateDateDifference(checkInDate: any, checkOutDate: any): number {
        const startDate = new Date(checkInDate);
        const endDate = new Date(checkOutDate);
        const timeDifference = endDate.getTime() - startDate.getTime();
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
        return daysDifference;
      }
    return(
<>
<HotelHeader hotel={packageTour.hotel} />
<div className="bg-white py-2 border-b my-2 overflow-hidden"
        >
          {/* Full Width Header */}
        
      
          <Table size="small" className=" overflow-x-auto mx-auto ">
      <thead className="border-b">
        <tr>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          </th>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t("app.flight-passengers-form.firstname")}
          </th>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {t("app.flight-passengers-form.surname")}
          </th>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {t("app.flight-passengers-form.birthdate")}
          </th>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {t("b2c-app.flights.form.gender")}
          </th>
          <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {t("general.paxtype")}
          </th>
        </tr>
      </thead>
      <tbody >
        {packageTour.paxList && packageTour.paxList.map((pax) => (
          <tr key={pax.paxId} className="border-b hover:bg-slate-200 ">
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
              {pax.paxId}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
              {pax.name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
              {pax.surname}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
              {pax.birthDate}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
              {pax.gender}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
              {pax.type}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <div className="flex justify-around border-b text-gray-800 items-center px-4 py-2">
            <span className='text-base font-semibold text-gray-800 '>{formatDate(packageTour.accommodation.checkInDate)} - {formatDate(packageTour.accommodation.checkOutDate)}            </span>
            <span className="font-semibold text-xl text-gray-800"> {calculateDateDifference(packageTour.accommodation.checkInDate, packageTour.accommodation.checkOutDate)} {t("app.rentacar.days")}</span>
          </div>
          {/* 3 Columns */}
          <div className="grid grid-cols-3 gap-4 px-4 mt-4  text-gray-800">
            {/* Left Column */}
            <div className="bg-white p-2 grid grid-rows-2">
             <div>
                <span className="font-semibold text-base text-gray-800 py-1 m-1">{packageTour.paxList && packageTour.paxList.length} {t("b2c-app.flights.form.passengers")}</span>
                <div className='flex justify-start items-center'><FaUser className='mr-1' /> <span>{countPaxType(packageTour.paxList, "ADULT")} {t("react.flight.adults")}</span></div>
                <div className='flex justify-start items-center'><FaChild className='mr-1' /><span>{countPaxType(packageTour.paxList, "CHILD")} {t("b2c-app.flights.traveler.children")}</span></div>
                <div className='flex justify-start items-center'><FaBaby className='mr-1' /><span>{countPaxType(packageTour.paxList, "INF")} {t("b2c-app.flights.traveler.infants")}</span></div>
            </div>
             
              <div>
                <span className="font-semibold text-l text-gray-800 py-1 m-1">{t("hotel.room")}</span>
                <div className='flex justify-start items-center '><FaUtensils className='mr-1' /> <span>{packageTour.accommodation.boardType}</span></div>
                <div className='flex justify-start items-center '><FaBed className='mr-1' /> <span>{packageTour.accommodation.roomType}</span></div>
                <div className='flex justify-start items-center '><FaMoon className='mr-1' /> <span>{calculateDateDifference(packageTour.accommodation.checkInDate, packageTour.accommodation.checkOutDate)} Nights</span></div>
              </div>
            </div>

            {/* Middle Column */}
            <div className="col-span-1 col-start-2 bg-white grid grid-rows-2 p-2 gap-2">
              <div className="row-span-1">
                {packageTour.outboundFlights.map((flight: any, index: number) => (
                  <FlightTripDetailPackage key={index} flight={flight} />
                ))}      </div>
              <div className="row-span-1">
                {packageTour.inboundFlights.map((flight: any, index: number) => (
                  <FlightTripDetailPackage key={index} flight={flight} />
                ))}
              </div>
            </div>
            {/* Right Column */}
         
     
              <div className='grid grid-rows-2 items-end'>
          
          <div className="grid grid-rows-2 gap-2 sm:h-auto">
          <span className="flex justify-center"> <img
              src={packageTour.tourOperator.logoUrl}
              alt="tourOperator"
              className="h-auto object-fit"
            /></span>
           
                      <span className='text-xs text-gray-800 '><pre>{packageTour.tourOperator.name}</pre></span>

          </div>
 
        <div className="grid items-end">         <div className='font-semibold text-base flex justify-between items-center text-gray-800 space-x-2' ><span>Total Price: </span>
                  <span className='text-xl '>{currencyFormat(packageTour.price.totalPrice)}</span>
                </div>
                <div className=' text-slate-500 text-sm flex justify-between'>
                     <span>per person:</span>  <span>{currencyFormat(packageTour.price.perPersonPrice)}</span> </div></div>
        </div>
        </div>
        </div>
        </>
      
    )
}