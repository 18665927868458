import { SearchPayload, SearchTab } from "api/types";
import React from "react";
import { useLocation } from "react-router-dom";
import FlightQueryParametersService from "services/FlightQueryParametersService";
import HotelAndFlightQueryParametersService from "services/HotelAndFlightQueryParametersService";
import HotelQueryParametersService from "services/HotelQueryParametersService";
import PackageTourQueryParametersService from "services/PackageTourQueryParameterService";
import RentalCarQueryParametersService from "services/RentalCarQueryParametersService";
import VillaQueryParameterService from "services/VillaQueryParameterService";
import TourQueryParametersService from "../services/TourQueryParametersService";
 
function useQueryParameters() {
  const { search } = useLocation();

  return React.useMemo((): SearchPayload | null => {

    const urlParams = new URLSearchParams(search);
    let searchType = (urlParams.get("searchType") as SearchTab) || "Hotel";


    switch (searchType) {
      case "Hotel":
        return HotelQueryParametersService.decode(urlParams);
        case "HOTEL":
          return HotelQueryParametersService.decode(urlParams);
      case "Villa":
        return VillaQueryParameterService.decode(urlParams);
        case "VILLA":
        return VillaQueryParameterService.decode(urlParams);
      case "Hotel & Flights":
        return HotelAndFlightQueryParametersService.decode(urlParams);
      case "Flights":
        return FlightQueryParametersService.decode(urlParams);
      case "Car Rental":
        return RentalCarQueryParametersService.decode(urlParams);
      case "Tour":
        return TourQueryParametersService.decode(urlParams);
        case "PackageTour":
        return PackageTourQueryParametersService.decode(urlParams);
      default:
        return null;
    }
  }, [search]);
}

export default useQueryParameters;
