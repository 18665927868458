import React, {FC} from 'react'
import {IHotelReservation} from "../../../api/types";
import {HotelInformation} from "./HotelInformation";
import {HotelGuestInformation} from "./HotelGuestInformation";

interface IHotelReservationInformation {
    hotelData: IHotelReservation
    statusColorCode: string
    status: string
}

export const HotelReservationInformation: FC<IHotelReservationInformation> = ({hotelData, statusColorCode, status}) => {
    return (
        <>
            <HotelInformation
                hotelData={hotelData}
                statusColorCode={statusColorCode}
                status={status}
            />
            <HotelGuestInformation rooms={hotelData.rooms} />
        </>
    )
}
