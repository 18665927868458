import {SearchPayload, TourSearchAutocompleteResult} from "api/types";
import { RoomInput } from "components/common/HeroSearchForm/GuestsInput";
import { DateRange } from "components/common/HeroSearchForm/TourSearchForm";
import RoomOccupanciesService from "./RoomOccupanciesService";
import ValidationService from "./ValidationService";

class TourQueryParametersService {
  static encode(
    tour: TourSearchAutocompleteResult | null,
    dateRange: DateRange,
    guests: RoomInput[]
  ): string | null {
    if (!tour || !dateRange.startDate || !dateRange.endDate) {
      return null;
    }

    let roomOccupancies =
      RoomOccupanciesService.stringifyRoomOccupancies(guests);

    return new URLSearchParams({
      searchType: "Tour",
      afterDate: dateRange.startDate.format("YYYY-MM-DD"),
      beforeDate: dateRange.endDate.format("YYYY-MM-DD"),
      destinationId: tour.id,
      label: tour.label,
      roomOccupancies,
    }).toString();
  }

  static decode(urlParams: URLSearchParams): SearchPayload | null {
    let afterDate = urlParams.get("afterDate") || null;
    let beforeDate = urlParams.get("beforeDate") || null;
    let destinationId = urlParams.get("destinationId") || null;
    let label = urlParams.get("label") || null;
    let roomOccupanciesParameter = urlParams.get("roomOccupancies") || null;

    afterDate = ValidationService.date(afterDate) ? afterDate : null;
    beforeDate = ValidationService.date(beforeDate) ? beforeDate : null;
    roomOccupanciesParameter = ValidationService.roomOccupancies(
      roomOccupanciesParameter
    )
      ? roomOccupanciesParameter
      : null;

    if (
      !afterDate ||
      !beforeDate ||
      !destinationId ||
      !label ||
      !roomOccupanciesParameter
    ) {
      return null;
    }

    const { roomOccupancies, totalGuests } =
      RoomOccupanciesService.getRoomOccupanciesValues(
        roomOccupanciesParameter!
      );

    return {
      searchType: "Tour",
      afterDate: afterDate!,
      beforeDate: beforeDate!,
      label: label!,
      destinationId: destinationId!,
      roomOccupancies,
      totalGuests,
    };
  }
}

export default TourQueryParametersService;
