import Skeleton from '@mui/material/Skeleton';
import { getFlightBanner } from 'api/helpers/api-constants';
import fetcher from 'api/helpers/fetcher';
import { IFlightBanner } from 'api/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlightBannerCard } from './FlightBannerCard';

export const FlightBanner: React.FC = () => {
    const [flightBanners, setFlightBanners] = useState<IFlightBanner[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cityIndex, setcityIndex] = useState<number>(0);
    const {t} = useTranslation()
    const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetcher(getFlightBanner());
      if(response)
      setFlightBanners(response.cities);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCityClick = (cityIndex: number) => {
    setcityIndex(cityIndex)
  };

  return (
    <div className='px-8 p-4 rounded-lg border  shadow-sm'>
        <span className='text-gray-900 font-semibold text-2xl '>{t("homepage.bannerflight")}</span>
      {isLoading && (
        <div className={`grid xl:grid-cols-4 gap-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 my-3`}>
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />
          <Skeleton variant="rectangular" width={288} height={288} animation="wave" />

        </div>
      )}
    <div className='flex flex-row flex-wrap gap-4 pt-4'>
    {!isLoading && flightBanners && flightBanners.length > 0 && flightBanners.map((city: IFlightBanner, index) => (
        <div
         key={index}>
         <button
    key={index}
    className= {cityIndex === index
        ? 'bg-primary-6000 text-white text-md  shadow-md  p-2 rounded cursor-pointer hover:bg-gray-400 hover:shadow-sm focus:ring-none'
        : 'bg-white shadow-md text-gray-900 text-md p-2 rounded cursor-pointer hover:bg-gray-400 hover:shadow-sm focus:ring-none'}
    onClick={() => handleCityClick(index)}>
      {city.cityName}
  </button>
        </div>
      ))}
    </div>
      <div className='grid xl:grid-cols-4 gap-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 my-3 transition-opacity'>
      {!isLoading && flightBanners && flightBanners[cityIndex]?.banners && flightBanners[cityIndex].banners.map((item, index) => (
             <FlightBannerCard
    key={index}
    {...item}
  />

))}
      </div>
    </div>
  );
};
