import { getTourSearchResultsUrl } from "api/helpers/api-constants";
import usePostRequest from "api/hooks/usePostRequest";
import { SearchPayload, TourSearchResults } from "api/types";
import Heading2 from "components/common/Heading/Heading2";
import useTourResultFilter from "hooks/filters/useTourResultFilter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ProgressBar from "components/ui/ProgressBar/ProgressBar";
import { StayCardTour } from "components/common/StayCardTour";
import Pagination from "components/ui/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import { usePagination } from "react-use-pagination";
import {  Checkbox, FormControlLabel, FormGroup, Tab, Tabs, Typography } from '@mui/material';
import useQueryParameters from "hooks/useQueryParameters";
import { Star } from "@mui/icons-material";

const PAGE_SIZE = 12;

const SuggestedTours = () => {
    const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
    const [sortOption, setSortOption] = useState<'priceAsc' | 'priceDesc' | 'closer' | 'uçaklı'>('priceAsc');
    const queryParameters: SearchPayload = {
        searchType: "Tour",
        label: "Tümü",
        afterDate: moment().add(5, "days").format("YYYY-MM-DD"),
        beforeDate: moment().add(20, "days").format("YYYY-MM-DD"),
        destinationId: "100000",
        totalGuests: 2,
        roomOccupancies: [
            {
                adults: 2,
                children: [],
            },
        ],
    };



    const { data, isLoading } = usePostRequest<TourSearchResults>({
        searchUrl: getTourSearchResultsUrl,
        payload: queryParameters,
    });

    const { t } = useTranslation();
    const results = useTourResultFilter(data, queryParameters?.totalGuests);

    // Extract unique regions
    const regions = Array.from(new Set(results.map((offer) => offer.regionName)));

    const {
        startIndex,
        endIndex,
        currentPage,
        setPage,
        setPreviousPage,
        setNextPage,
        previousEnabled,
        nextEnabled,
    } = usePagination({
        totalItems: results.length ? results.length : 0,
        initialPageSize: PAGE_SIZE,
    });

    useEffect(() => {
        setPage(0);
    }, [selectedRegions, sortOption]);

    // Sort and filter results based on selected regions and sort option
    const resultToShow = results
    .filter((tour) => (selectedRegions.length ? selectedRegions.includes(tour.regionName) : true))
    .filter((tour) => (sortOption === "uçaklı" ? tour.name.includes("Uçaklı") : true))
    .sort((a, b) => {
        if (sortOption === 'priceAsc') return a.price - b.price;
        if (sortOption === 'priceDesc') return b.price - a.price;
        if (sortOption === 'closer') {
            // Convert startDate to timestamps for comparison
            const aStartDate = moment(a.travelDates[0].startDate, "ddd MMM DD HH:mm:ss [CET] YYYY").valueOf();
            const bStartDate = moment(b.travelDates[0].startDate, "ddd MMM DD HH:mm:ss [CET] YYYY").valueOf();
            return aStartDate - bStartDate;
        }
        return 0;
    })
        .slice(startIndex, endIndex + 1) || [];

    useEffect(() => {
        if (queryParameters) {
            setTimeout(() => {
                document.getElementsByClassName("nc-HeroSearchForm")[0].scrollIntoView({ behavior: "smooth" });
            }, 200);
        }
    }, [JSON.stringify(queryParameters)]);

    if (!queryParameters) return null;

    // Handle region checkbox toggle
    const handleRegionToggle = (region: string) => {
        setSelectedRegions((prevSelectedRegions) =>
            prevSelectedRegions.includes(region)
                ? prevSelectedRegions.filter((r) => r !== region)
                : [...prevSelectedRegions, region]
        );
    };

    // Handle sort option change
    const handleSortChange = (_: React.ChangeEvent<{}>, newValue: 'priceAsc' | 'priceDesc' | 'closer') => {
        setSortOption(newValue);
    };

    return (
        <div className="my-12 mx-auto pb-8 dark:bg-neutral-800">
            <div className="mb-8 space-y-8 lg:mb-11">
                {isLoading && <ProgressBar progress={data?.finished || 5} />}
            </div>
            {results.length > 0 ? (
                <>
                    <div className="grid grid-cols-12 gap-4">
                        {/* Filter Section */}
                        <div className="col-span-3">
                            <div className="mb-4 bg-white shadow border p-4 rounded-lg">
                                <Typography variant="h6" className="text-neutral-600 dark:text-neutral-300">
                                    {t("flights.filters")}
                                </Typography>
                                <div>
                                    {regions.map((region) => (
                                        <div key={region} className="flex gap-1 items-center">
                                            <Checkbox
                                                checked={selectedRegions.includes(region)}
                                                onChange={() => handleRegionToggle(region)}
                                                name={region}
                                            />
                                            <Typography variant="inherit" className="text-neutral-600 dark:text-neutral-300">
                                                {region}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Tour Cards Section */}
                        <div className="col-span-9 grid gap-4">
                            <div className="bg-white grid border rounded-lg p-4 shadow">
                                <div className="flex justify-between">
                                    <span className="font-semibold text-xl">{queryParameters?.label}</span>
                                    <span className="text-primary-6000 font-medium">
                                        {results.length} {t("app.generals.results-found")}
                                    </span>
                                </div>
                                <div className="border-t w-full border-gray-300 mt-4 flex justify-center">
                                    <Tabs
                                        value={sortOption}
                                        onChange={handleSortChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                    >
                                        <Tab label={t("app.price-sorted-ascending")} value="priceAsc" />
                                        <Tab label={t("app.price-sorted-descending")} value="priceDesc" />
                                        <Tab label={t("app.sort.closertour")} value="closer" />
                                        <Tab
                                            icon={<Star className="text-primary-6000" />} 
                                            iconPosition="start"
                                            label={t("app.sort.ucakli")}
                                            value="uçaklı"
                                        />                                    </Tabs>
                                </div>
                                {resultToShow.length === 0 && <span className="text-center text-neutral-400">{t("app.no-search-results")}</span>}
                            </div>
                            {resultToShow.map((item) => (
                                <StayCardTour key={item.id} data={item} />
                            ))}
                        </div>
                    </div>
                    <div className="mt-16 flex items-center justify-center">
                        <Pagination
                            currentPage={currentPage}
                            setPage={setPage}
                            totalPages={Math.ceil(results.length / PAGE_SIZE)}
                            setPreviousPage={setPreviousPage}
                            setNextPage={setNextPage}
                            idToScrollOnPageChange="heading-2"
                            nextEnabled={nextEnabled}
                            previousEnabled={previousEnabled}
                        />
                    </div>
                </>
            ) : (
                <div className="text-center text-neutral-400">{t("app.no-search-results")}</div>
            )}
        </div>
    );
};
export default SuggestedTours;
