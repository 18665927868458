import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "components/ui/NcImage/NcImage";
import I404Png from "images/404.png";

const Page404: React.FC = () => (
  <div className="nc-Page404">
    <Helmet>
      <title>
        404 || Book your Travel with ATR Touristik: Flights, Accommodations,
        Transfer, Rental Car and more
      </title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="mx-auto max-w-2xl space-y-2 text-center">
        <NcImage src={I404Png} />
        <span className="block text-sm font-medium tracking-wider text-neutral-800 dark:text-neutral-200 sm:text-base">
          THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.{" "}
        </span>
        <div className="pt-8">
          <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
        </div>
      </header>
    </div>
  </div>
);

export default Page404;
