import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from '@material-ui/core';
import { CheckCircle, Error, Warning, Info, Close } from '@material-ui/icons';

interface CustomSweetAlertProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  onConfirm?: () => void;
}

const CustomSweetAlert: React.FC<CustomSweetAlertProps> = ({
  open,
  onClose,
  title,
  message,
  type,
  onConfirm,
}) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircle style={{ color: '#4caf50', fontSize: 48 }} />;
      case 'error':
        return <Error style={{ color: '#f44336', fontSize: 48 }} />;
      case 'warning':
        return <Warning style={{ color: '#ff9800', fontSize: 48 }} />;
      case 'info':
        return <Info style={{ color: '#2196f3', fontSize: 48 }} />;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getIcon()}
            <span style={{ marginLeft: '16px' }}>{title}</span>
          </div>
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm || onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomSweetAlert;

