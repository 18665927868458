import React, {FC, useEffect, useState} from "react"
import {IRentalcarDriverInformation, IRentalCarReservationDetails} from "../../../api/types";
import {useTranslation} from "react-i18next";
import {DriverInformation} from "./DriverInformation";
import {VehicleInformation} from "./VehicleInformation";

export interface IRentalcarInformation {
    rentalcarInformation: IRentalCarReservationDetails
    statusColorCode: string
    status: string
    supplierReservationNumber: string|null
}

export const RentalcarInformation: FC<IRentalcarInformation> = ({
                                                                    rentalcarInformation,
                                                                    statusColorCode,
                                                                    status,
                                                                    supplierReservationNumber
}) => {
    const { t } = useTranslation()

    const [ rentalcarDriverInformation, setRentalcarDriverInformation ] = useState<IRentalcarDriverInformation[]>([])

    useEffect(() => {
        const _rentalcarDriverInformation: IRentalcarDriverInformation[] = []

        _rentalcarDriverInformation.push({
            firstname: rentalcarInformation.firstname,
            surname: rentalcarInformation.surname,
            birthdate: rentalcarInformation.birthdate,
            phone: rentalcarInformation.mobilePhone,
            email: rentalcarInformation.email
        })

        if (rentalcarInformation.firstname2) {
            _rentalcarDriverInformation.push({
                firstname: rentalcarInformation.firstname2!,
                surname: rentalcarInformation.surname2!,
                birthdate: rentalcarInformation.birthdate2!,
                phone: rentalcarInformation.mobilePhone2!
            })
        }

        setRentalcarDriverInformation(_rentalcarDriverInformation)
    }, [rentalcarInformation])

    return (
        <>
            <VehicleInformation
                rentalcarInformation={rentalcarInformation}
                statusColorCode={statusColorCode}
                status={status}
                supplierReservationNumber={supplierReservationNumber}
            />
            <DriverInformation rentalcarDriverInformation={rentalcarDriverInformation} />
        </>
    )
}
