import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_WEB } from "data/navigation";
import { useReduxReducer } from "redux/store";

function Navigation() {
  const { header } = useReduxReducer(state => state.config);

  // Default navigation
  let navigation = NAVIGATION_WEB;
  
  // Check if bookingsLinkEnabled is false
  if (!header.bookingsLinkEnabled) {
    navigation = navigation.filter(item => item.name !== "Buchungen");
    navigation = navigation.filter(item => item.name !== "Dashboard"); //to show on b2b pages
  }
  
  // Check if moreLinksEnabled is false
  if (!header.moreLinksEnabled) {
    navigation = navigation.filter(item => item.name !== "Weitere Links");
  }
  return (
    <ul className="ml-auto nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {navigation.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
