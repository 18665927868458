import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DataFilters {
  accommodationType: Array<string>;
  amenities: Array<string>;
  boards: Array<string>;
}

export enum Order {
  ReccomendedAccomodation,
  PriceFromLowest,
  PriceFromHighest,
  StarsFromLowest,
  StarsFromHighest,
  HotelAZ,
  HotelZA,
}

const initialState = Order.ReccomendedAccomodation;

export const filtersSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrder } = filtersSlice.actions;

export default filtersSlice.reducer;
