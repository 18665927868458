import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/atr-logo-dark-touristik.gif";
import logoLightImg from "images/atr-logo-dark-touristik.gif";
import { useReduxReducer } from "redux/store";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  const { header } = useReduxReducer((state) => state.config);

  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-8 ${imgLight ? "dark:hidden" : ""}`}
          src={header.defaultLogo
            ? header.defaultLogo
            : ""}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      
    </Link>
  );
};

export default Logo;
