import React, { FC, useEffect } from "react";
import twFocusClass from "utils/twFocusClass";
import usePaginationRange, { DOTS } from "hooks/usePaginationRange";

export interface PaginationProps {
  currentPage: number;
  setPage: (page: number) => void;
  totalPages: number;
  className?: string;
  nextEnabled: boolean;
  previousEnabled: boolean;
  idToScrollOnPageChange: string;
  setNextPage: () => void;
  setPreviousPage: () => void;
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  setPage,
  nextEnabled,
  previousEnabled,
  setNextPage,
  setPreviousPage,
  totalPages,
  idToScrollOnPageChange,
  className = "",
}) => {
  const paginationRange = usePaginationRange({
    total: totalPages,
    initialPage: 1,
  });

  function handleChangePage(index: number) {
    document.getElementById(idToScrollOnPageChange)?.scrollIntoView();
    setPage(index);
  }

  function handlePreviousPage() {
    if (previousEnabled) {
      setPreviousPage();
    }
  }

  function handleNextPage() {
    if (nextEnabled) {
      setNextPage();
    }
  }

  useEffect(() => {
    paginationRange.setPage(currentPage + 1);
  }, [currentPage]);

  return (
    <nav
      className={`nc-Pagination flex justify-center space-x-1 text-base font-medium sm:grid-cols-12 sm:gap-4 ${className}`}
    >
      <PaginationButton
        currentPage={currentPage}
        onClick={handlePreviousPage}
        isEnabled={previousEnabled}
        label="<"
      />
      {paginationRange.range.map((index, i) => {
        if (index === DOTS) {
          return (
            <div className="inline-flex h-11 w-11 items-center justify-center" key={i}>
              &#8230;
            </div>
          );
        }
        return (
          <PaginationButton
            key={i}
            index={Number(index)}
            currentPage={paginationRange.active || 1}
            onClick={() => handleChangePage(index - 1)}
          />
        );
      })}
      <PaginationButton
        currentPage={currentPage}
        onClick={handleNextPage}
        isEnabled={nextEnabled}
        label=">"
      />
    </nav>
  );
};

interface PaginationButtonProps {
  index?: number;
  currentPage: number;
  onClick: (index: number) => void | (() => void);
  label?: string;
  isEnabled?: boolean;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  index,
  currentPage,
  label,
  onClick,
  isEnabled = true,
}) => {
  if (index === currentPage) {
    // RETURN ACTIVE PAGINATION
    return (
      <span
        key={index}
        className={`inline-flex h-11 w-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
      >
        {label || index}
      </span>
    );
  }

  // RETURN UNACTIVE PAGINATION
  return (
    <button
      key={index}
      className={`inline-flex h-11 w-11 items-center justify-center rounded-full border border-neutral-200 ${twFocusClass()} ${
        isEnabled
          ? "bg-white text-neutral-6000 hover:bg-neutral-100 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-400 dark:hover:bg-neutral-800"
          : "dark:border-neutral-600 bg-neutral-50 text-neutral-500 opacity-80 dark:bg-neutral-800 dark:text-neutral-300"
      }`}
      onClick={() => onClick(index || 0)}
    >
      {label || index || 0}
    </button>
  );
};

export default Pagination;
