import { AccordionDetails } from '@material-ui/core';
import { Accordion, AccordionSummary } from '@mui/material';
import { BookingFlight } from 'api/types';
import UpdateActionWCHR from 'pages/BookingDetail/components/UpdateActionWCHR';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxReducer } from 'redux/store';
import { getDateTime } from 'utils/getDateTime';
import { getStaticUrl } from 'utils/getStaticUrl';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type FlightTableProps = {
  flights: BookingFlight[];
};

const FlightTable: React.FC<FlightTableProps> = ({ flights }) => {
  const { t } = useTranslation();
  const { isAdmin } = useReduxReducer((state) => state.general);
const [isExpanded, setisExpanded] = useState<boolean>(false);
  return (
    <div className="table-responsive px-4 pb-0 my-2">
      <table className="table-borderless table w-full">
        <thead>
          <tr className="text-base font-semibold border-t border-b">
            
            <th>{t('generals.flight')}</th>
            <th>{t('flights.departure')}</th>
            <th>{t('flights.arrival')}</th>
            <th>{t('flightbooking.class')}</th>
            <th>{t('generals.date')}</th>
            <th>{t('b2c-app.flights.results.filter.baggage')}</th>
            <th>{t('flightbooking.airline')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {flights.map((flight) => (
            <React.Fragment key={flight.id}>
         <tr className="text-base hover:bg-gray-100 border-t border-b">
         
       
            
                <td>{flight.flightNumber}
                {flight.ssrRecords.length > 0 && 
          <tr className=''>
          <Button onClick={() => setisExpanded(!isExpanded)}>
            {isExpanded ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
            ssr
          </Button>
          </tr>
          
        }
              
                </td>
                <td>
                  {flight.departurePortName + ' (' + flight.departurePort + ')'}
                  <p>{getDateTime(flight.departureDate || '', 'hour')}</p>
                </td>
                <td>
                  {flight.arrivalPortName + ' (' + flight.arrivalPort + ')'}
                  <p>{getDateTime(flight.arrivalDate || '', 'hour')}</p>
                </td>
                <td>{flight.classOfService}</td>
                <td>{getDateTime(flight.departureDate || '', 'date')}</td>
                <td>{flight.baggageAllowance}</td>
                <td>
                  <img
                    className="mx-auto h-12 w-12"
                    src={getStaticUrl('b2b') + flight.airlineLogo}
                    alt={`${flight.flightNumber} logo`}
                  />
                  
                </td>
                

              </tr>
           
              {isExpanded && flight.ssrRecords.length > 0 && (
          <tr>
            
            <td>
             <div className="grid expandable-content bg-slate-100">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="border text-base">{t("pdf.insurance.type")}</TableCell>
                  <TableCell className="border text-base">{t('bookings.status')}</TableCell>
                  <TableCell className="border text-base">{t('homepage.register.remarks')}</TableCell>
                  <TableCell className="border text-base">{t('flights.price')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flight.ssrRecords.map((request, index) => (
                  <TableRow key={index} className="border">
                    <TableCell className="py-2 px-4 text-md flex items-center justify-center gap-2">
                      {request.productCode}
                      {isAdmin && request.recordType === 'WHEELCHAIR' && request.type === 'MANUAL' && (
                        <UpdateActionWCHR ssrRecordID={request.id} manualStatus={request.manualStatus} manualRemarks={request.manualRemarks} />
                      )}
                      {request.type}
                    </TableCell>
                    <TableCell className="py-2 px-4 text-md">
                      {request.manualStatus === 'CONFIRMED' && t('b2c-app.generals.done')}
                      {request.manualStatus === 'PENDING' && t('b2c-app.profile.bookings.status.tf_pending_confirmation')}
                      {request.manualStatus === 'FAILED' && t('flight.ssr.wchr.failed')}
                      {!request.manualStatus && t('b2c-app.generals.done')}
                    </TableCell>
                    <TableCell className="py-2 px-4 text-md">{request.remarks}</TableCell>
                    <TableCell className="py-2 px-4 text-md">{request.price} €</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div></td>

          </tr>
         
        )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default FlightTable;
