import React from "react";
import { Grid } from "@material-ui/core";
import { AtrTextfield } from "../utils/UI/AtrTextfield";
import { AtrDateFormat } from "../calendar";
import { AtrSelectSingleDatePicker } from "../utils/UI/AtrSelectSingleDatePicker";
import { t } from "i18next";
export function FlightRentalcarDriverInformation(props) {
  return (
    <div className="FlightRentalcarDriverInformation">
      <div
        style={{ fontWeight: "500", fontSize: "20px", paddingBottom: "15px" }}
      >
        Angaben zum Fahrer
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AtrTextfield
            name="flightrentalcardriverinformation-firstname"
            isError={
              props.showValidation &&
              (props.vehicleDriverInformation.firstname === null ||
                props.vehicleDriverInformation.firstname.length === 0)
            }
            helperText={
              props.showValidation &&
              (props.vehicleDriverInformation.firstname === null ||
                props.vehicleDriverInformation.firstname.length === 0)
                ? t("react.validation.requiredfield")
                : ""
            }
            label="Vorname"
            defaultValue={
              props.vehicleDriverInformation.firstname !== null &&
              props.vehicleDriverInformation.firstname.length > 0
                ? props.vehicleDriverInformation.firstname
                : ""
            }
            style={{ width: "100%" }}
            updateValue={(value) =>
              props.updateDriverInformation("firstname", value)
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AtrTextfield
            name="flightrentalcardriverinformation-surname"
            isError={
              props.showValidation &&
              (props.vehicleDriverInformation.surname === null ||
                props.vehicleDriverInformation.surname.length === 0)
            }
            helperText={
              props.showValidation &&
              (props.vehicleDriverInformation.surname === null ||
                props.vehicleDriverInformation.surname.length === 0)
                ? t("react.validation.requiredfield")
                : ""
            }
            label="Nachname"
            defaultValue={
              props.vehicleDriverInformation.surname !== null &&
              props.vehicleDriverInformation.surname.length > 0
                ? props.vehicleDriverInformation.surname
                : ""
            }
            style={{ width: "100%" }}
            updateValue={(value) =>
              props.updateDriverInformation("surname", value)
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AtrSelectSingleDatePicker
            name="flightrentalcardriverinformation-birthdate"
            isError={
              props.showValidation &&
              props.vehicleDriverInformation.birthdate === null
            }
            helperText={
              props.showValidation &&
              props.vehicleDriverInformation.birthdate === null
                ? t("react.validation.requiredfield")
                : ""
            }
            label="Geburtsdatum"
            defaultValue={
              props.vehicleDriverInformation.birthdate !== null
                ? AtrDateFormat(
                    new Date(props.vehicleDriverInformation.birthdate)
                  )
                : ""
            }
            style={{ width: "100%" }}
            updateValue={(value) =>
              props.updateDriverInformation("birthdate", value)
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
