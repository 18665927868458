import { IconButton, Tooltip } from '@mui/material';
import { postCancellationFeeHotel } from 'api/helpers/api-constants';
import StartRating from 'components/common/StartRating';
import React, { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import currencyFormat from 'utils/currencyFormat';
import { formatServerDate } from 'utils/formatserverdate';
import InfoIcon from "@mui/icons-material/Info";
import { BookingPassenger } from 'api/types';

interface Room {
  roomType: string;
  board: string;
}


type paxdata = {
    flightList: [];
    ancillaryServicesRequests: [];
    baseFare: number;
    birthdate: string;
    firstname: string;
    gender: string;
    id: number;
    ticketnumber: string;
    passportCountryOfIssue: string;
    passportNationality: string;
    passportNumber: string;
    paxtype: string;
    surname: string;
    ticketed: boolean;
    totalAmount: number;
  };


export interface HotelBooking {
  hotelName: string;
  hotel: {
      amenities: string[];
      checkinTime: string;
      fees: string;
      thumbnail: string;
      address: string;
      city: string | null;
      policies: string;
      description: string;
      stars: number;
      photos: string[];
      features: string[];
      province: string;
      phone: string;
      name: string;
      rank: number;
      accommodationType: string;
      id: number;
      checkoutTime: string;
      email: string;
      geolocation: {
          latitude: number;
          longitude: number;
      };
  };
  address: string;
  city: string | null;
  phone: string;
  stars: number;
  checkinDate: string;
  checkinTime: string ;
  checkoutDate: string;
  checkoutTime: string;
  rooms: {
      travellers: {
          isLead: boolean;
          nationality: string;
          phone: string;
          surname: string;
          name: string;
          mobile: string;
          type: "adult" | "child";
          title: "Mr" | "Mrs";
          email: string;
          birthDate?: string;
          age?: number;
      }[];
  }[];
  totalAmount: number;
  tax: number;
  taxInfo:string | null;
  nights: number
}


interface SectionHotelProps {
  data: HotelBooking;
  status:string;
  paxdata: BookingPassenger[];
  bookingKey: string
}

const SectionHotel: React.FC<SectionHotelProps> = ({ data, status, paxdata, bookingKey }) => {
  const [loading, setLoading] = useState(false);
  console.log(data.totalAmount)
const {t} = useTranslation()

  const hotel = data;
  const hotelOfferTax = data.taxInfo ? JSON.parse(data.taxInfo) : null;

  const cancelHotel = async (formElement: HTMLFormElement, bookingKey: string) => {
    try {
      setLoading(true);

      const response = await fetch(postCancellationFeeHotel(), {
        method: 'POST',
        headers: {
          'Accept': 'text/plain, */*; q=0.01',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: new URLSearchParams({ bookingKey }).toString(),
      });

      const responseText = await response.text();
      const data = JSON.parse(responseText.replace(/\n/g, ''));
      let msg = '';

      if (data.hasOwnProperty('fee')) {
        const cancellationFee = data.fee.amount;
        msg = `
          Rezervasyon iptal edilecektir.<br>
          Bu iptalden dolayı oluşacak olan masraf: <b style='color:red'>${cancellationFee} EUR</b><br><br>
          <span style='color:red; font-weight:bold;'>İşlemi gerçekleştirmek istediğinize emin misiniz?</span>
        `;
      } else {
        msg = data.freeText;
      }

      const confirmation = await Swal.fire({
        title: t("generals.are-you-sure-you-want-to-proceed"),
        html: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t("generals.confirm"),
        cancelButtonText: t("b2c-app.generals.cancel"),
        reverseButtons: true,
      });

      if (confirmation.isConfirmed) {
        const formData = new FormData(formElement);

        const submitResponse = await fetch("/agency/hotels/cancelReservation", {
          method: 'POST',
          body: formData,
        });

        if (submitResponse.ok) {
          await Swal.fire({
            title: t("generals.success"),
            text: t("b2c-app.generals.submission_success"),
            icon: 'success',
            confirmButtonText: t("b2c-app.flights.inactivity-window-button"),
            showCancelButton: true,
            cancelButtonText: t("generals.close")
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(); 
            }
          });
        } else {
          await Swal.fire({
            title: t("generals.error"),
            text: t("b2c-app.generals.submission_failed"),
            icon: 'error',
            confirmButtonText: t("generals.ok")
          });
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  return (
    <div className="my-4">
      <section className="my-4 rounded border bg-white shadow-sm">
        <div className="mb-1 py-3">
          <h3 className="text-lg font-bold">Hotel</h3>
        </div>
        
        <div className="mb-4 grid grid-cols-1 grid-rows-1 gap-4">
          <div className="flex-wrap">
            <h3 className="mb-2 flex items-center justify-center font-bold">
              {hotel.hotelName}
            </h3>
            <div className="mb-2 flex items-center justify-center">
              <StartRating point={hotel.stars} />
            </div>
            <div className="flex items-center justify-center">
              <img className="h-80" src={hotel.hotel.photos[0]} alt="Hotel" />
            </div>
          </div>
        </div>

        <div className={`flex h-4 items-center justify-center rounded p-3
          ${status === "CONFIRMED" ? "bg-green-400" : ""}
          ${status === "RESERVATION" ? "bg-blue-400" : ""}
          ${status === "CANCELED" ? "bg-red-400" : ""}
          ${status === "VOIDED" ? "bg-red-400" : ""}
          ${status === "REFUNDED" ? "bg-gray-400" : ""}
          ${status === "EXPIRED" ? "bg-red-400" : ""}
        `}>
          {status === "RESERVATION" && <>{t("flightbooking.ticketing_deadline")}</>}
          {status === "CONFIRMED" && <>{t("flightbooking.ticketed")}</>}
          {status === "CANCELED" && <>{t("flightbooking.canceled")}</>}
          {status === "VOIDED" && <>Voided Kayit</>}
          {status === "REFUNDED" && <>{t("flightbooking.refunded")}</>}
          {status === "EXPIRED" && <>{t("flightbooking.expired")}</>}
        </div>
        
        {/*hotel.hotel.supplierBookingId && (
          <div className="flex justify-center items-center text-lg px-4 text-primary-6000 font-semibold">
            ReservationID: {hotel.supplierBookingId}
          </div>
        )*/}
      </section>

      <section className="my-4 rounded bg-white">
        {hotel.rooms?.map((room :any, i:number) => (
          <div className="p-6" key={i}>
            <div className="text-md mb-2 flex justify-center rounded border-2 p-2">
              {t("hotel.room")} {i + 1}
            </div>
            <div className="grid grid-cols-5 grid-rows-1 gap-4">
              <div>
                <p><b>{t("react.hotel.check-in")}</b></p>
                <p>{formatServerDate(data.checkinDate)}</p>
              </div>
              <div className="text-md">
                <p><b>{t("react.hotel.room")}</b></p>
                {room.roomType}
              </div>
              <div>
                <p><b>{t("react.hotel.board")}</b></p>
                {room.board}
              </div>
              <div>
                <p><b>{t("hotel.room")}</b></p>
                {currencyFormat(hotel.totalAmount / hotel.nights)}
              </div>
              <div>
                <p><b>{t("react.hotel.check-out")}</b></p>
                <p>{formatServerDate(data.checkoutDate)}</p>
              </div>
            </div>
          </div>
        ))}
      </section>

      <section className="my-4 rounded bg-white">
        <div className="p-6">
          <table className="table-bordered table-hover w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">{t("react.hotel.firstname")}</th>
                <th className="px-4 py-2">{t("react.hotel.lastname")}</th>
                <th className="px-4 py-2">{t("charter.passenger-type")}</th>
                <th className="px-4 py-2">{t("generals.gender")}</th>
              </tr>
            </thead>
            <tbody>
              {paxdata.map((row:any) => (
                <tr key={row.id}>
                  <td className="px-4 py-2">{row.firstname}</td>
                  <td className="px-4 py-2">{row.surname}</td>
                  <td className="px-4 py-2">{row.paxtype}</td>
                  <td className="px-4 py-2">{row.gender}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <div className="flex flex-col space-y-4 px-4">
        {hotelOfferTax && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {t("generals.tax-and-fees")}
              <Tooltip title="This is the total amount for all taxes and fees that will be charged to your booking. This amount includes applicable destination fees and other charges set by the property. This amount may also include fees that ATR and/or the property you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </span>
            <span>{currencyFormat(hotelOfferTax.taxesAndFees)}</span>
          </div>
        )}

        {hotelOfferTax && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Affiliate Service Charge</span>
            <span>{currencyFormat(hotel.totalAmount - hotelOfferTax.baseRate - hotelOfferTax.taxesAndFees)}</span>
          </div>
        )}

        <div className="border-t border-b space-y-2 py-2">
          {hotelOfferTax && hotelOfferTax.feePaymentInProperty !== 0 && (
            <>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="font-semibold">Due at property</span>
                <span>approx. {currencyFormat(hotelOfferTax.feePaymentInProperty)}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="font-semibold">Due at now</span>
                <span>{currencyFormat(hotel.totalAmount)}</span>
              </div>
            </>
          )}
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between text-xl font-semibold">
          <span>{t("react.generel.totalprice")}</span>
          <span>{currencyFormat(hotel.totalAmount ) }</span>
        </div>
      </div>

      {hotelOfferTax && (
        <div className="mt-0 px-4 text-left pt-3 pb-0">
          <h2 className="text-[16px] font-semibold mt-2">{t("react.hotel.check-in")}</h2>
          <span className="text-sm" dangerouslySetInnerHTML={{ __html: hotel.checkinTime }} />
          <h2 className="text-[16px] font-semibold mt-2">{t("react.hotel.check-out")}</h2>
          <span className="text-sm" dangerouslySetInnerHTML={{ __html: hotel.checkoutTime }} />
          <h2 className="text-[16px] font-semibold mt-2">Fees & Policies</h2>
          <span className="text-sm" dangerouslySetInnerHTML={{ __html: hotel.hotel.fees }} />
          <span className="text-sm" dangerouslySetInnerHTML={{ __html: hotel.hotel.policies }} />
        </div>
      )}

      {status === "CONFIRMED" && (
        <div>
          <form id={`form_${bookingKey}`}>
            <input type="hidden" name="bookingKey" value={bookingKey} />
          </form>
          <button
            id="cancelHotel"
            type="button"
            onClick={() => {
              const button = document.getElementById("cancelHotel") as HTMLButtonElement;
              if (button) {
                button.disabled = true;
              }
              const formElement = document.getElementById(`form_${bookingKey}`) as HTMLFormElement;
              cancelHotel(formElement, bookingKey);
            }}
            className="text-sm rounded border bg-red-500 px-4 py-2 font-medium hover:border-red-600 text-white"
          >
            <i className="fa fa-close" aria-hidden="true"></i> {t("booking.cancel")}
          </button>
        </div>
      )}

      <section className="my-4 rounded border bg-white shadow-sm">
        <div className="p-6">
          <p>Passenger Contact: 004969273166800, HOTEL@ATRTOURISTIK.COM</p>
        </div>
      </section>
    </div>
  );
};

export default SectionHotel;
