import React, { FC } from 'react'
import {useTranslation} from "react-i18next";
import {splitEmail} from "../MobileBookingDetail";

interface IAgencyInformation {
    agencyName: string
    email: string
    phone: string
    agencyNumber: string
}

export const AgencyInformation: FC<IAgencyInformation> = ({agencyName, email, phone, agencyNumber}) => {
    const { t } = useTranslation();

    return (
        <div className={`my-3 mx-2 mb-3 px-3 pb-3 pt-3 shadow-md bg-white rounded grid grid-cols-7`}>
            <div className={`col-span-3 font-semibold`}>{t('manage-agencies.agency')}:</div>
            <div className={`col-span-4`}>{agencyName}</div>
            <div className={`col-span-3 font-semibold`}>{t('manage-agencies.email')}:</div>
            <div className={`col-span-4`}>{email.length > 15 ? splitEmail(email) : email}</div>
            <div className={`col-span-3 font-semibold`}>{t('manage-agencies.phone')}:</div>
            <div className={`col-span-4`}>{phone}</div>
            <div className={`col-span-3 font-semibold`}>{t('manage-agencies.agency-number')}:</div>
            <div className={`col-span-4`}>{agencyNumber}</div>
        </div>
    )
}
