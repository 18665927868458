import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { getNationalities } from "../utils/Nationality";
import { Grid, TextField } from "@material-ui/core";
import { t } from "i18next";
import $ from "jquery";
import { JupiterDatePicker } from "./JupiterDatePicker";
import { AtrDateFormat } from "../calendar";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";

export function SetPassportData(props) {
  const [openDatepicker, setOpenDatepicker] = useState(false);

  const invalidMsg = t("react.validation.requiredfield");
  const nationalities = getNationalities();

  return (
    <React.Fragment>
      <Grid item xs={12} md={2} />
      <Grid item xs={12} md={3}>
        <TextField
          style={{ width: "100%" }}
          label={t("flights.passportnationality")}
          variant="outlined"
          select
          value={props.default}
          defaultValue={props.default}
          onChange={(event) => {
            props.updatePassportNationality(event.target.value);
          }}
        >
          {nationalities.map((option) => (
            <MenuItem key={option.value} value={option.country}>
              {option.country}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          error={props.passportinfo.passportNumber.showValidation}
          helperText={
            props.passportinfo.passportNumber.showValidation ? invalidMsg : ""
          }
          style={{ width: "100%" }}
          label={t("flights.passportnumber")}
          variant="outlined"
          onBlur={(e) => {
            props.updatePassportNumber(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          error={props.passportinfo.passportExpiryDate.showValidation}
          helperText={
            props.passportinfo.passportExpiryDate.showValidation
              ? invalidMsg
              : ""
          }
          style={{ width: "100%" }}
          label={t("flights.passportexpirydate")}
          variant="outlined"
          value={AtrDateFormat(
            new Date(props.passportinfo.passportExpiryDate.value)
          )}
          onChange={(e) => {
            const re = /^[0-9.\b]+$/;

            let inputValue = e.target.value;

            if (!inputValue.includes("..")) {
              if (inputValue.slice(-1) === ".") {
                if (inputValue.length === 3 || inputValue.length === 6) {
                  $(e.target).val(inputValue);
                }
              }
              if (re.test(inputValue)) {
                if (inputValue.length < 11) {
                  if (inputValue.length === 2 || inputValue.length === 5) {
                    if (
                      inputValue.length === 2 &&
                      inputValue[inputValue.length - 1] === "."
                    ) {
                      inputValue = "0" + inputValue;
                    } else if (
                      inputValue.length === 5 &&
                      inputValue[inputValue.length - 1] === "."
                    ) {
                      let tmpInputValue = inputValue.split(".")[0] + ".";

                      if (inputValue.split(".")[1] === "0") {
                        tmpInputValue += "01.";
                      } else {
                        tmpInputValue += "0" + inputValue.split(".")[1] + ".";
                      }
                      inputValue = tmpInputValue;
                    }

                    $(e.target).val(inputValue);
                  } else if (inputValue.length > 2) {
                    if (
                      inputValue.length === 3 &&
                      inputValue.slice(-1) !== "."
                    ) {
                      inputValue = correctInputValue(inputValue, 2);
                    } else if (
                      inputValue.length === 6 &&
                      inputValue.slice(-1) !== "."
                    ) {
                      inputValue = correctInputValue(inputValue, 5);
                    }

                    let inputValueSplit = inputValue.split(".");

                    if (parseInt(inputValueSplit[0]) > 31) {
                      inputValueSplit[0] = "31";
                      $(e.target).val(inputValueSplit[0] + ".");
                    }

                    if (
                      inputValueSplit.length > 1 &&
                      parseInt(inputValueSplit[1]) > 12
                    ) {
                      inputValueSplit[1] = "12";
                      $(e.target).val(
                        inputValueSplit[0] + "." + inputValueSplit[1] + "."
                      );
                    } else {
                      let tmpValue = "";
                      for (let i = 0; i < inputValueSplit.length; i++) {
                        tmpValue += inputValueSplit[i];
                        if (i + 1 < inputValueSplit.length) {
                          tmpValue += ".";
                        }
                      }
                      $(e.target).val(tmpValue);
                    }
                  }
                } else {
                  $(e.target).val(inputValue.substring(0, 10));
                }

                if (inputValue.length === 10) {
                  let inputValueSplit = inputValue.split(".");

                  setOpenDatepicker(false);
                  props.updatePassportExpiryDate(
                    new Date(
                      inputValueSplit[2] +
                        "-" +
                        inputValueSplit[1] +
                        "-" +
                        inputValueSplit[0]
                    )
                  );
                }
              }
            } else {
              $(e.target).val(inputValue.substring(0, inputValue.length - 1));
            }
          }}
          onClick={() => {
            setOpenDatepicker(true);
          }}
        />
        <div id={"passportexpire-" + props.genderType}>
          {openDatepicker ? (
            <OutsideClickHandler
              onOutsideClick={(event) => {
                if (
                  typeof $(event.target)
                    .closest('div[id^="passportexpire-"]')
                    .attr("data-passengervalue") === "undefined"
                ) {
                  setOpenDatepicker(false);
                }
              }}
            >
              <Calendar
                value={props.passportinfo.passportExpiryDate.value}
                onClickDay={(value, event) => {
                  setOpenDatepicker(false);
                  props.updatePassportExpiryDate(value);
                }}
              />
            </OutsideClickHandler>
          ) : (
            <div />
          )}
        </div>
      </Grid>
      <Grid item xs={12} md={1} />

      <Grid item xs={12} md={2} />
      <Grid item xs={12} md={3}>
        <TextField
          style={{ width: "100%" }}
          label={t("flights.passportcountryofissue")}
          variant="outlined"
          select
          value={props.default}
          defaultValue={props.default}
          onChange={(event) => {
            props.updatePassportCountryOfIssue(event.target.value);
          }}
        >
          {nationalities.map((option) => (
            <MenuItem key={option.value} value={option.country}>
              {option.country}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={7} />
    </React.Fragment>
  );
}

function correctInputValue(value, index) {
  let newValue = "";

  for (let i = 0; i < value.length; i++) {
    if (i === index) {
      newValue += ".";
    }

    newValue += value[i];
  }

  return newValue;
}
