import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { fetcherWithoutJSON } from 'api/helpers/fetcher';
import { confirmSeatSelection, getSeatmapUrl } from 'api/helpers/api-constants';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';
import { BookingFlight } from 'api/types';
import { disableButton } from 'utils/disableButton';
import { SsrSelectionProps } from './MealSelection';
import postFetcher from 'api/helpers/postFetcher';
import { LinearProgress } from '@mui/material';
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import ConfirmationDialog from 'components/ui/ConfirmationDialog/ConfirmationDialog';

interface SsrResponse {
    ssrList: string[];
  }
  type seatdata1 = [
    {
      seatKey: string;
      rowNumber: number;
      seats: any;
      seatStatus: string;
      column: string;
      currencyCode: string;
      price: number;
      seatTypes: any;
    }
  ];
  interface PaxData {
    bookingKey: string;
    filekey: string;
    paxList: PaxItem[];
  }

  interface PaxItem {
    paxId: number | null;
    ssrKey: string;
  }

  
  const SeatSelection: React.FC<SsrSelectionProps> = ({ bookingKey, filekey, route, isOpen, setIsOpen }) => {
    const {t} = useTranslation()
    const [paxId, setPaxId] = useState<number | null>(null);
    const [flightId, setFlightId] = useState<number | null>(null);
    const [flightData, setflightData] = useState<BookingFlight>();
    const [confirmbox, setConfirmbox] = useState(false);
    const [seatData, setseatData] = useState<seatdata1>();
    const [isloading, setisloading] = useState<boolean>(false);
    const [selectedSsr, setSelectedSsr] = useState<PaxData | null>(null);
    const [selectedSeat, setselectedSeat] = useState<boolean>(false);

    const postSsr = async (selectedSsr: PaxData) => {
      try {
        const response = await postFetcher(confirmSeatSelection(), selectedSsr);
        console.log(response);
        if (response.success) {
          Swal.fire({
            title: "Success!",
            text: t("flight.ssr.wchr.requestsuccess"),
            icon: "success",
            confirmButtonText: t("b2c-app.flights.inactivity-window-button"),
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          const correlationID = response.headers.get("correlation-id");
          const errorMessage = correlationID
            ? "Error Code: " + correlationID
            : "An error occurred";
          Swal.fire({
            title: "Error",
            icon: "error",
            text: errorMessage,
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "An error occurred",
        });
      } finally {
        setConfirmbox(false);
      }
    };
    const handleConfirm = () => {
        const button = document.getElementById("confirmSeat") as HTMLButtonElement | null;
        if (button) {
          disableButton([button]);
        }
        selectedSsr && postSsr(selectedSsr); //post ssr func will come
      };

    //post seat api

   
  
    const getssrData = async () => {
        if(flightId){   setisloading(true);
            await fetcherWithoutJSON(
              getSeatmapUrl() +
                new URLSearchParams({
                  bookingKey: bookingKey,
                  filekey: filekey,
                  flightId: flightId?.toString(),
                  // paxId: paxId,
                }),
              {
                method: "GET",
      
                credentials: "include",
                cache: "no-cache",
              }
            )
              .then((response) => {
                if (response.status === 200) {
                  return response.json();
                } else {
                  throw new Error("Something went wrong on api server!");
                }
              })
              .then((data) => {
                setseatData(data.seatRows);
              })
              .catch((error) => {
                console.log(error);
                Swal.fire(t("ssr.errorfrom-airline"), "", "error");
              })
              .finally(() => {
                setisloading(false);
              });}
   
    };
    useEffect(() => {
      if (flightId && filekey) {
        getssrData();
      }
    }, [flightId]);

    function showTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-0", "invisible");
        tooltip.classList.add("opacity-100");
      }
    }

    function hideTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-100");
        tooltip.classList.add("opacity-0", "invisible");
      }
    }

  


    function handleClick(ssrKey: string, paxId: number | null) {
        setselectedSeat(true)
      if (selectedSsr === null) {
        const newPaxItem: PaxItem = {
          paxId,
          ssrKey,
        };

        const newSelectedSsr: PaxData = {
          bookingKey: bookingKey, // set mainly as a bookingKey
          filekey: filekey,
          paxList: [newPaxItem],
        };

        setSelectedSsr(newSelectedSsr);
      } else {
        const existingIndex = selectedSsr.paxList.findIndex(
          (item) => item.paxId === paxId
        );

        if (existingIndex !== -1) {
          const updatedSelectedSsr: PaxData = {
            ...selectedSsr,
            paxList: selectedSsr.paxList.map((item, index) => {
              if (index === existingIndex) {
                return { ...item, ssrKey };
              }
              return item;
            }),
          };

          setSelectedSsr(updatedSelectedSsr);
        } else {
          const newPaxItem: PaxItem = {
            paxId,
            ssrKey,
          };

          const updatedSelectedSsr: PaxData = {
            ...selectedSsr,
            paxList: [...selectedSsr.paxList, newPaxItem],
          };

          setSelectedSsr(updatedSelectedSsr);
        }
      }
    }

    function deletePaxById(paxIdToDelete: number | null) {
      if (!selectedSsr) {
        return; // No selectedSsr to modify
      }

      const updatedPaxList = selectedSsr.paxList.filter(
        (item) => item.paxId !== paxIdToDelete
      );

      if (updatedPaxList.length === 0) {
        setSelectedSsr(null); // If there are no items left, set selectedSsr to null
      } else {
        const updatedSelectedSsr: PaxData = {
          ...selectedSsr,
          paxList: updatedPaxList,
        };
        setSelectedSsr(updatedSelectedSsr);
      }
    }
const [isAlreadySelected, setisAlreadySelected] = useState<boolean>(false); // is true if already bought seats
useEffect(() => {
  if(flightData){
    const seatRecords = flightData!.ssrRecords.filter(
      (rcr: any) => rcr.recordType === "SEAT"
    );
    
    const isAlreadySelected = seatRecords.some(
      (record: any) => record.paxId === paxId && record.flightId === flightId
    );
    setisAlreadySelected(isAlreadySelected)
  }
}, [flightId, paxId]);

    return (
      <div>
        {isOpen && (
          <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
            <div className="w-full rounded bg-white">
              {isloading && <LinearProgress />}
              <ButtonClose onClick={() =>{
            setIsOpen(false)
            setFlightId(null)
            setPaxId(null)}
              }></ButtonClose>
              <div className=" rounded bg-white ">
                <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                  <AirlineSeatReclineNormalIcon />
                  <h2 className="p-2">{t("flightbooking.seat")}</h2>
                </div>
                <div className="w-full  p-2">
                  <div className=" w-min-screen z-10  flex gap-12 border border-gray-300  bg-white">
                  <div  className="  w-1/4 p-2  ">
                  <h3 className=" font-bold">
                              {t("flightbooking.flights")}
                            </h3>
                  {route.trips?.map((trip) => (
                                    trip.flights.map((flight) => (
                              <div className="mt-2" key={flight.id}>
                                <button
                                  key={flight.id}
                                  className={`w-full  border-2 p-2 text-center hover:bg-gray-100 ${
                                    flight.id === flightId ? "bg-gray-100" : ""
                                  }`}
                                  onClick={() => {
                                    setFlightId(flight.id);
                                    setPaxId(route.passengers[0].id);
                                    setflightData(flight)
                                  }}
                                >
                                  <div className="   text-sm flex items-center justify-between space-x-3 px-2 text-gray-900 ">
                                    {flightId === flight.id ? (
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    ) : null}
                                      <span className="flex items-center space-x-2">
                                              <img className='h-5 w-5' src={flight.airlineLogo} alt='airline'></img>
                                            <span className='font-bold'>{flight.flightNumber}</span>  
                                           
                                            </span>
                                            <span>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </span>
                                  </div>
                                </button>
                              </div>
                         
                         ))
                         ))}
                          </div>
                          {flightId && seatData &&(
                                        <div className='py-2'>
                                          <h3 className=" font-bold">
                                            {" "}
                                            {t("flightbooking.passengers")}
                                          </h3>
                                            
                                                <div className="px-2 ">
                                                  {route.passengers.map((p) => (
                                                    <div
                                                      className="mt-2 flex items-center space-x-2"
                                                      key={p.id}
                                                    >
                                                      <button
                                                        key={p.id}
                                                        className={`w-full border-2 p-2 text-center hover:bg-gray-200  ${
                                                          p.id === paxId
                                                            ? "bg-gray-100"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          setPaxId(p.id);
                                                        }}
                                                      >
                                                        <div className="  text-sm flex items-center justify-between space-x-3 px-2 text-gray-900 ">
                                                          <span>
                                                            {p.firstname}{" "}
                                                            {p.surname}
                                                          </span>
                                                          <span className="font-bold">
                                                            {seatData.map(
                                                              (seat: any) => (
                                                                <div
                                                                  key={
                                                                    seat.seatKey
                                                                  }
                                                                >
                                                                  {seat.seats
                                                                    .filter(
                                                                      (
                                                                        s: any
                                                                      ) =>
                                                                        selectedSsr?.paxList.some(
                                                                          (
                                                                            pax
                                                                          ) =>
                                                                            pax.ssrKey ===
                                                                              s.seatKey &&
                                                                            pax.paxId ===
                                                                              p.id
                                                                        )
                                                                    )
                                                                    .map(
                                                                      (
                                                                        s: any
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            s.seatKey
                                                                          }
                                                                        >
                                                                          <span>
                                                                            {
                                                                              seat.rowNumber
                                                                            }
                                                                            {
                                                                              s.column
                                                                            }
                                                                            <span className="px-2">
                                                                              {" "}
                                                                              {
                                                                                s.price
                                                                              }{" "}
                                                                              {
                                                                                s.currencyCode
                                                                              }
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                </div>
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      </button>
                                                      <div className="flex h-4 w-4 items-center justify-end">
                                                        {selectedSsr?.paxList.some(
                                                          (pax) =>
                                                            pax.paxId === p.id
                                                        ) && (
                                                          <ButtonClose
                                                            onClick={() =>
                                                              deletePaxById(
                                                                p.id
                                                              )
                                                            }
                                                          ></ButtonClose>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              {" "}
                                              <div className=" border-1 rounded border-gray-300  px-12 ">
                               
                                <div
                                  className="inline-flex items-center justify-center rounded-md p-2  shadow-sm "
                                  role="group"
                                >
                                  <button
                                    type="button"
                                    className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500
                                    "
                                    onClick={() => {
                                      setPaxId(null);
                                      setFlightId(null);
                                      setIsOpen(false);
                                    }}
                                  >
                                    {t("b2c-app.generals.cancel")}
                                  </button>
                                  <button
                                    type="button"
                                    className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-700"
                                    onClick={() => {
                                      setConfirmbox(true);
                                    }}
                                    disabled={!selectedSeat}
                                  >
                                    {t("generals.add")}
                                  </button>
                                </div>
                              </div>
                                        </div>
                                      )}
                                      <ConfirmationDialog
                                        isOpen={confirmbox}
                                        onClose={() => setConfirmbox(false)}
                                        onConfirm={handleConfirm}
                                        />
                    <div className=" w-2/4 p-2 ">
                      {isAlreadySelected ? (
                        <span>{t("ssr.already.selected")}</span>
                      ) : (
                        flightId &&
                        !isloading &&
                        seatData && (
                          <div className="relative  flex justify-center ">
                            <div className="max-h-screen min-w-fit overflow-x-scroll">
                              <div className="mx-2 rounded-full border-l-8 border-r-8 border-blue-300 py-24">
                                {!seatData ? (
                                  <div>{t("ssr.errorfrom-airline")}</div>
                                ) : (
                                  seatData.map((seat: any) => (
                                    <div>
                                      {seat.seats[0].seatTypes.includes(
                                        "LEG_SPACE"
                                      ) ? (
                                        <div className="text-sm w-full  text-gray-400">
                                          {" "}
                                          {"<   EXIT   >"}
                                        </div>
                                      ) : null}
                                      <div
                                        key={seat.row}
                                        className=" flex justify-start p-0.5 "
                                      >
                                        {seat.seats.map((s: any) => (
                                          
                                          <div className="">
                                            <button
                                            
                                              className={`relative mx-0.5 flex h-7 w-7 items-center justify-center   rounded  border-2 object-cover  text-center 
                            ${s.column === "C" ? "mr-4" : ""}
                            ${
                              s.seatTypes.includes("WINDOW") ||
                              s.seatTypes.includes("AISLE")
                                ? "bg-blue-200"
                                : "bg-green-200"
                            } 
                            ${s.seatTypes.includes("LEG_SPACE") ? "h-9 " : ""} 
                            ${
                              selectedSsr?.paxList.some(
                                (p) => p.ssrKey === s.seatKey
                              )
                                ? "cursor-not-allowed bg-green-600"
                                : ""
                            }
                            ${s.seatStatus ==="FREE" ? "" : "hover:cursor-not-allowed cursor-not-allowed"} 
                            ${
                              s.seatStatus == "FREE"
                                ? ""
                                : "!important  bg-gray-400"
                            } hover:bg-green-400`}
                                              onClick={() =>
                                                handleClick(s.seatKey, paxId)
                                              }
                                              disabled={
                                                selectedSsr?.paxList.some((p) => p.ssrKey === s.seatKey) || 
                                                s.seatStatus !== "FREE"
                                              }
                                              onMouseOver={(e) =>
                                                showTooltip(s.seatKey)
                                              }
                                              onMouseOut={(e) =>
                                                hideTooltip(s.seatKey)
                                              }
                                              key={s.seatKey}
                                            >
                                              {s.seatStatus === "FREE" ? (
                                                <div className="text-xs ">
                                                  {" "}
                                                  {seat.rowNumber}
                                                  {s.column}
                                                  {s.seatTypes.includes(
                                                    "LEG_SPACE"
                                                  ) ? (
                                                    <p>XL</p>
                                                  ) : null}
                                                </div>
                                              ) : (
                                                <div className="relative h-7  w-7  bg-gray-200">
                                                  <div className="absolute h-0.5 w-9   origin-left rotate-45 transform rounded-full bg-red-600"></div>
                                                </div>
                                              )}
                                            </button>
                                            <div
                                              id={`${s.seatKey}`}
                                              className="tooltip invisible -translate-x-1/2 transform rounded bg-blue-300  p-1 text-xs text-gray-900 opacity-0 transition duration-700"
                                            >
                                              {seat.rowNumber} {s.column}{" "}
                                              {s.name}
                                              <p>
                                                {s.price} {s.currencyCode}
                                              </p>
                                              <p> {s.seatTypes}</p>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  export default SeatSelection