import { LinearProgress } from '@mui/material';
import { FlightOffer } from 'api/types';
import { RegularFlightCard } from 'pages/FlightSearchPage/RegularFlightCard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReissueFlightCard } from './ReissueFlightCard';

interface IReissueFlightList {
  list: FlightOffer[];
  isLoading: boolean;
  onNewFlightSelect: (offer: FlightOffer | null) => void;
}

const ReissueFlightList: React.FC<IReissueFlightList> = ({ list, isLoading, onNewFlightSelect }) => {
  const [selectedOfferId, setSelectedOfferId] = useState<string>("");
  const [newFlight, setNewFlight] = useState<FlightOffer | null>();
  const { t } = useTranslation();

  const handleCardSelect = (offerId: string) => {
    setSelectedOfferId(offerId);
    const selectedFlight: FlightOffer | undefined = list.find((offer) => offer.offerId === offerId);
    if(selectedFlight){
      setNewFlight(selectedFlight);
      onNewFlightSelect(selectedFlight);
    }
   
  };

  return (
    <div className='py-2 mt-4'>
      {isLoading ? (
        <LinearProgress />
      ) : (
        list.map((offer, index: number) => (
          <ReissueFlightCard
            key={offer.offerId}
            offer={offer}
            index={index}
            selectedOfferId={selectedOfferId}
            onCardSelect={handleCardSelect}
          />
        ))
      )}
    </div>
  );
};

export default ReissueFlightList;
