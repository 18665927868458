import React, { FC } from "react"
import {IRescanPNR} from "../../../api/types";
import currencyFormat from "../../../utils/currencyFormat";
import {useTranslation} from "react-i18next";

interface IBookingActionButtonDialogContent {
    rescanPNR: IRescanPNR|null
}

export const BookingActionButtonDialogContent: FC<IBookingActionButtonDialogContent> = ({rescanPNR}) => {
    const { t } = useTranslation()

    return (
        <>
            {
                rescanPNR && (
                    rescanPNR.hasOwnProperty('newBookingKey') ?
                        <div className={`flex justify-center items-center text-l font-semibold`}>{t('flightbooking.options.query-current-price.new-reservation-created-description')}</div> :
                        <div className={`grid grid-cols-2`}>
                            <div className={`col-span-1 pb-2 font-semibold`}>{t("generals.old-price")}:</div>
                            <div className={`col-span-1 pb-2 text-right`}>{currencyFormat(rescanPNR.oldPrice)}</div>
                            <div className={`col-span-1 pb-2 font-semibold`}>{t("generals.new-price")}:</div>
                            <div className={`col-span-1 pb-2 text-right`}>{currencyFormat(rescanPNR.newPrice)}</div>
                            <div className={`col-span-1 font-semibold ${rescanPNR.diffPrice! <= 0 ? 'text-green-600' : 'text-red-600' }`}>{t("generals.difference")}: </div>
                            <div className={`col-span-1 font-semibold text-right ${rescanPNR.diffPrice! <= 0 ? 'text-green-600' : 'text-red-600' }`}>{`${rescanPNR.diffPrice! > 0 ? '+' : '-'}${currencyFormat(rescanPNR.diffPrice!)}`}</div>
                        </div>
                )
            }
        </>
    )
}
