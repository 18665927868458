import React, {FC, useEffect, useState} from "react"
import {Dialog} from "primereact/dialog";
import {useTranslation} from "react-i18next";
import { InputText } from "primereact/inputtext";
import {CustomButton} from "../CustomButton";
import { ClockIcon } from "@heroicons/react/outline";
import timeSelectOptions from "utils/timeSelectOptions";
import HeroSelect from "../HeroSearchForm/HeroSelect";
import { ListBox } from "primereact/listbox";

interface IMobileCustomCalendarForRac {
    
    rentalTime: string | null;
    setRentalTime: React.Dispatch<React.SetStateAction<string | null>>;
    returnTime: string | null;
    setReturnTime: React.Dispatch<React.SetStateAction<string | null>>;
    type: string;
}

export const MobileCustomHourSelect: FC<IMobileCustomCalendarForRac> = (
    {
       rentalTime,
       setRentalTime,
       returnTime,
       setReturnTime,
       type,
    }) => {

    const [ openModal, setOpenModal ] = useState<boolean>(false)
    const [ value, setValue] = useState<string>((rentalTime && returnTime) ? `${rentalTime} - ${returnTime}` : '');

    const { t } = useTranslation()

    useEffect(() => {
        getValue()
    }, [rentalTime, returnTime])

    const openModalHandler = (newValue: boolean = false) => {
        setOpenModal(!openModal)
        getValue()
    }

    const selectDateHandler = () => {
        openModalHandler(true)
        
            

    }

    const getValue = () => {
        let _value = "";

        if (rentalTime && type==="rentalTime")
            _value += rentalTime

        if (returnTime && type==="returnTime")
            _value +=  returnTime

        setValue(_value)
    }

    const dialogFooter = () => {
        return (
            <>
                <div className={`grid grid-cols-2 text-center mt-2 mb-2`}>
                    <div className={`col-span-1 p-2`}>
                        <span className={`text-xs`}>{t('rentalcar.car-pickup-time')}</span>
                        <div className={`font-semibold`}>
                            {
                                rentalTime ?
                                    rentalTime :
                                    
                                    '--'
                            }
                        </div>
                    </div>
                    {
                      
                            <div className={`col-span-1 p-2  border-l-2`}>
                                <span className={`text-xs`}>{t('rentalcar.car-return-time')}</span>
                                <div className={`font-semibold`}>
                                    {
                                       returnTime ?
                                            returnTime
                                            :
                                            '--'
                                    }
                                </div>
                            </div>
                        
                    }
                </div>
                <CustomButton
                    value={t('b2c-app.generals.select')}
                    clickeHandler={selectDateHandler}
                />
            </>
        )
    }

    return (
        <>
        <span className="p-input-icon-left w-full">
    <i className="pi pi-clock" />
            <InputText
                className={`text-sm w-full pl-10 rounded focus:outline-0 focus:border-none focus:outline-offset-0 border-neutral-200 bg-gray-200 dark:border-neutral-700 dark:bg-neutral-900 cursor-pointer`}
                type={'text'}
                readOnly
                value={value}
                placeholder={t('rentalcar.select.hours')}
                onChange={(e) => setValue(e.target.value)}
                onClick={() => openModalHandler(false)}
            />
</span>
            <Dialog
                className={'atr-datepicker'}
                header={t('rentalcar.select.hours')}
                footer={dialogFooter}
                visible={openModal}
                position={'bottom'}
                style={{ height: '70%', width: '100%', margin: 0 }}
                onHide={openModalHandler}
                draggable={false}
                resizable={false}
            >
                <div className="grid grid-cols-2 text-center mt-2 mb-2">
                
           <ListBox value={rentalTime} onChange={(e) => setRentalTime(e.value)} options={timeSelectOptions} optionLabel="label" className="w-full flex  justify-center" />
           <ListBox value={returnTime} onChange={(e) => setReturnTime(e.value)} options={timeSelectOptions} optionLabel="label" className="w-full flex  justify-center" />


                </div>
               
            </Dialog>
        </>
    );
}
