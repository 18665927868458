import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getHost } from "../../actions";
import Sticky from "react-sticky-el";
import { createFlightQueryString } from "./FlightQueryParams";
import superagent from "superagent";
import { PictureAsPdf } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { t } from "i18next";
const useStyles = makeStyles((theme) => ({
  priceoverviewHeader: {
    backgroundColor: "#eaeaea",
    padding: "10px",
    fontWeight: "700",
  },
  priceoverviewList: {
    padding: "15px",
    display: "grid",
    gridTemplateRows: "auto auto auto",
  },
  flightContainer: {
    display: "grid",
    gridTemplateRows: "auto auto auto auto auto auto",
  },
  priceoverviewlistTotal: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    fontWeight: "700",
    marginTop: "5px",
    paddingTop: "5px",
    borderBottom: "double 3px #e4e4e4",
    borderTop: "1px solid #e4e4e4",
  },
}));

export function FlightOfferExportBucket(props) {
  const serviceFeePerPaxAndRoute = props.serviceFeePerPaxAndRoute;
  const isMultiFlight = props.isMultiFlight;
  const selectedFlight = props.selectedFlight;
  const location = props.location;
  const checkedMultiFlights = props.checkedMultiFlights;
  const roundTrip = props.roundTrip;

  const classes = useStyles();

  const getOfferIds = () => {
    let tmpOfferId = "";
    if (typeof props.selectedFlight.offerId === "undefined") {
      for (let i = 0; i < props.selectedFlight.length; i++) {
        tmpOfferId += props.selectedFlight[i].offerId;
        if (i + 1 < props.selectedFlight.length) {
          tmpOfferId += "+";
        }
      }
    } else {
      tmpOfferId = props.selectedFlight.offerId;
    }
    return tmpOfferId;
  };

  const transferToPaxconnect = () => {
    let adtPrice = 0;
    let chdPrice = 0;
    let infPrice = 0;

    let singleReco = props.selectedFlight.hasOwnProperty("adtPrice");
    if (singleReco) {
      adtPrice = props.selectedFlight.adtPrice;
      chdPrice = props.selectedFlight.chdPrice;
      infPrice = props.selectedFlight.infPrice;
    } else {
      for (let i = 0; i < props.selectedFlight.length; i++) {
        adtPrice += props.selectedFlight[i].adtPrice;
        chdPrice += props.selectedFlight[i].chdPrice;
        infPrice += props.selectedFlight[i].infPrice;
      }
    }

    let legCount =
      props.location.searchParameter.originDestinationInformation.length;
    adtPrice += legCount * serviceFeePerPaxAndRoute;
    chdPrice += legCount * serviceFeePerPaxAndRoute;
    infPrice += legCount * serviceFeePerPaxAndRoute;

    let returnUrl = window.searchUrl;
    //take it totally url decoded and url encode after that
    returnUrl = encodeURIComponent(returnUrl);

    superagent
      .get(
        getHost() +
          "/agency/flightSearch/paxconnect?recos=" +
          getOfferIds() +
          "&adtPrice=" +
          adtPrice +
          "&chdPrice=" +
          chdPrice +
          "&infPrice=" +
          infPrice +
          "&returnUrl=" +
          returnUrl
      )
      .withCredentials()
      .then((res) => {
        window.open(res.text);
      })
      .catch((err) => {
        alert("An unknown error occured.");
      });
  };

  const openItineraryPdf = () => {
    window.open(
      getHost() + "/agency/flightSearch/offerPdf?recos=" + getOfferIds(),
      "_blank"
    );
  };

  return (
    <div
      className={
        !props.isMobile
          ? "selectedvehicle-priceoverview"
          : "selectedvehicle-priceoverview-mobile"
      }
    >
      <div className={classes.priceoverviewHeader}>
        {t("react.flight.create-offer")}
      </div>
      <div className={classes.priceoverviewList}>
        {/*Paxconnect*/}
        <div>
          <img
            src="https://www.paxconnect.de/img/logo_paxconnect.png"
            onClick={() => transferToPaxconnect(props)}
            style={{
              width: "100%",
              cursor: "pointer",
              padding: "10px 10px 3px 10px",
            }}
          />
        </div>

        <Button
          variant="contained"
          color="default"
          className={classes.button}
          style={{ margin: "15px 10px 10px 10px" }}
          startIcon={<PictureAsPdf />}
          onClick={() => openItineraryPdf()}
        >
          {t("react.general.itinerary")}
        </Button>
      </div>
    </div>
  );
}
