import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMaskChangeEvent, InputMask } from 'primereact/inputmask';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button/Button';
import { getIssueBookingUrl } from 'api/helpers/api-constants';


interface CreditCardFormProps {
  onSubmit?: (formData: FormData) => void;
  bookingKey: string;
  totalAmount:number;
}

interface FormData {
    [key: string]: string;
    paymentType: string;
    creditCardNumber: string;
    creditCardHolder: string;
    creditCardExpiryMonth: string;
    creditCardExpiryYear: string;
    cvc: string;
    bookingKey:string;
    creditCardHolderName: string;
    creditCardHolderSurname: string;


  }
const CreditCardForm: React.FC<CreditCardFormProps> = ({ onSubmit, bookingKey,totalAmount}) => {


  const [formData, setFormData] = useState<FormData>({
    creditCardNumber: '',
    paymentType:'AIRLINE_CC',
    creditCardHolder: '',
    creditCardExpiryMonth: '',
    creditCardExpiryYear: '',
    cvc: '',
    bookingKey:bookingKey,
    creditCardHolderName: '',
    creditCardHolderSurname: '',

  });

  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | InputMaskChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
  
    if (name === 'creditCardHolderName' || name === 'creditCardHolderSurname') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        creditCardHolder: name === 'creditCardHolderName' ?
          `${value} ${prevData.creditCardHolderSurname}` :
          `${prevData.creditCardHolderName} ${value}`,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  

  const handleSubmit = (e: React.FormEvent) => {
    const { creditCardHolderName, creditCardHolderSurname, ...formDataWithoutName } = formData;
    e.preventDefault();

        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", getIssueBookingUrl());

        for (const key in formDataWithoutName) {
            if (formDataWithoutName.hasOwnProperty(key)) {
              const hiddenField = document.createElement("input");
              hiddenField.setAttribute("type", "hidden");
              hiddenField.setAttribute("name", key);
              hiddenField.setAttribute("value", String(formDataWithoutName[key]));
              form.appendChild(hiddenField);
            }
          }

          document.body.appendChild(form);
        form.submit();


  };

  return (
    <div className="max-w-md mx-auto bg-gray-200 px-2.5 pb-2">
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="creditCardNumber" className="text-sm font-medium text-gray-600">
            {t('flightbooking.ticketing.airlinecc.card-number')}
          </label>
          <InputMask
            mask="9999 9999 9999 9999"
            id="creditCardNumber"
            name="creditCardNumber"
            value={formData.creditCardNumber}
            onChange={handleInputChange}
            className="mt-1 p-2 border rounded-md w-full"
            placeholder="____ ____ ____ ____"
            required
          />
        </div>
        <div>
          <p className='text-sm font-medium text-gray-600'>{t('flightbooking.ticketing.airlinecc.credit-card-holder')}</p>
          <label htmlFor="creditCardHolderName" className="text-sm font-medium text-gray-600">
          {t("b2c-app.flights.form.firstname") }
          </label>
          <InputText
            id="creditCardHolderName" 
            name="creditCardHolderName"
            value={formData.creditCardHolderName}
            onChange={(e) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>)}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>
        <div>
          <label htmlFor="creditCardHolderSurname" className="text-sm font-medium text-gray-600">
            {t("app.flight-passengers-form.surname") }
          </label>
          <InputText
            id="creditCardHolderSurname"
            name="creditCardHolderSurname"
            value={formData.creditCardHolderSurname}
            onChange={(e) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>)}
            className="mt-1 p-2 border rounded-md w-full"
            required
          />
        </div>
        <div className="grid grid-rows-2 space-x-2">

          <div className='flex justify-between  px-2 items-center'>

            <span className="text-sm  font-medium text-gray-600">
              {t('flightbooking.ticketing.airlinecc.expiry-date')} :
            </span>
            <div> <InputMask
              mask="99"
              id="creditCardExpiryMonth"
              name="creditCardExpiryMonth"
              value={formData.creditCardExpiryMonth}
              onChange={handleInputChange}
              className="mt-1 p-2 border rounded-md w-10"
              placeholder="__"
              required
              pattern=".*\S+.*" 
              title="This field is required"
            />
           <span className='text-sm text-gray-600 px-1'>/</span>
            <InputMask
              mask="9999"
              id="creditCardExpiryYear"
              name="creditCardExpiryYear"
              value={formData.creditCardExpiryYear}
              onChange={handleInputChange}
              className="mt-1 p-2 border rounded-md w-16"
              placeholder="____"
              required
            /></div>


          </div>
          <div className='flex justify-between space-x-2 items-center pr-2 '>
            <span className="text-sm font-medium text-gray-600">
              {t('flightbooking.ticketing.airlinecc.security-code')} :
            </span>
            <InputMask
              mask="999"
              id="cvc"
              name="cvc"
              value={formData.cvc}
              onChange={handleInputChange}
              className="mt-1 p-2 border rounded-md w-12"
              placeholder="___"
              required
            />
          </div>
        </div>
        <Button type="submit" className="bg-primary-6000 text-white w-full text-center p-2 rounded-md">
          {t('flights.submit')}
        </Button>
      </form>
    </div>
  );
};

export default CreditCardForm;
