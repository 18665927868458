import { CabinClass, MultiCitySearchModel, TSelectedFlights } from "api/types";
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import { IoClose } from "react-icons/io5";
import { Button } from "@mui/material";
import { getAirportAutocompleteUrl } from "api/helpers/api-constants";
import { FaExchangeAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import SubmitButton from "../SubmitButton";
import AutocompleteForMultiFlight from "../AutoCompleteForMultiFlight";
import DateInputMultiFlight from "../DateInputMultiFlight";
import { TravelersInputValue } from "../TravelersInput";
import { useDispatch } from "react-redux";
import { SetTabSelections } from "redux/slices/tabsSlice";
import FlightQueryParametersService from "services/FlightQueryParametersService";
import { useNavigate } from "react-router-dom";
import {
  clearBaseOffer,
  setIsLoading,
  setIsMultiFlight,
  setIsOneWay,
  setIsRoundtrip,
  setSelectedFlights,
} from "../../../../redux/slices/flightSlice";
import moment from "moment";
import { FlightSearchType } from "../../SearchEngines/FlightSearch/FlightSearch";

type Props = {
  searchType: FlightSearchType;
  travelers: TravelersInputValue;
  cabinClass: CabinClass;
  index: number;
  multiCitySearchNumber: number;
  setMultiCitySearchNumber: (value: number) => void;
  multiCitySearch: MultiCitySearchModel[];
  setMultiCitySearch: Dispatch<SetStateAction<MultiCitySearchModel[]>>;
  first?: boolean;
  multiCityMissingInput: {
    departureAirport: boolean;
    arrivalAirport: boolean;
    departureDate: boolean;
  }[];
  setMultiCityMissingInput: Dispatch<SetStateAction<any>>;
  isMobile: boolean;
  handleErrorCheck?: boolean;
  withBaggageOnly: boolean;

};

const MultiCityForm: FC<Props> = ({
  index,
  searchType,
  travelers,
  cabinClass,
  multiCitySearchNumber,
  setMultiCitySearchNumber,
  multiCitySearch,
  setMultiCitySearch,
  first,
  multiCityMissingInput,
  setMultiCityMissingInput,
  isMobile,
  handleErrorCheck,
  withBaggageOnly,

  
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [departureFocus, setDepartureFocus] = useState(false);
  const [returnFocus, setReturnFocus] = useState(false);
  const [dateFocus, setDateFocus] = useState(false);

  const handleAirportsChange = (index: number) => {
    let newMultiCitySearch = [...multiCitySearch];
    let temp = newMultiCitySearch[index].departureAirport;
    newMultiCitySearch[index].departureAirport =
      newMultiCitySearch[index].arrivalAirport;
    newMultiCitySearch[index].arrivalAirport = temp;
    setMultiCitySearch(newMultiCitySearch);
  };

    const handleSubmitMulti = () => {
        dispatch(setIsOneWay(false))
        dispatch(setIsRoundtrip(false))
        dispatch(setIsMultiFlight(true))
        dispatch(setIsLoading(true))

    dispatch(clearBaseOffer());
    dispatch(
      setSelectedFlights(
        Array.from({ length: multiCitySearchNumber }).fill(
          null
        ) as TSelectedFlights[]
      )
    );

    let newInputErrors = [...multiCityMissingInput];
    multiCitySearch.forEach((search, index) => {
      if (!search.departureAirport) {
        newInputErrors[index].departureAirport = true;
      } else {
        newInputErrors[index].departureAirport = false;
      }
      if (!search.arrivalAirport) {
        newInputErrors[index].arrivalAirport = true;
      } else {
        newInputErrors[index].arrivalAirport = false;
      }
      if (!search.departureDate) {
        newInputErrors[index].departureDate = true;
      } else {
        newInputErrors[index].departureDate = false;
      }
    });

    setMultiCityMissingInput(newInputErrors);

    if (!checkObjectsNotNull(multiCitySearch)) {
      return;
    }

    dispatch(SetTabSelections({ currentTab: "Flights" }));

    const urlSearchParams = FlightQueryParametersService.encode(
      searchType,
      travelers,
      cabinClass,
      null,
      null,
      multiCitySearch,
      {
        startDate: null,
        endDate: null,
      },
      null,
      withBaggageOnly,
    );

    if (urlSearchParams) {
      navigate("/flight-search?" + urlSearchParams);
    }
  };
  useEffect(() => {
   if(handleErrorCheck)  {
    
    let newInputErrors = [...multiCityMissingInput];
        multiCitySearch.forEach((search, index) => {
          if (!search.departureAirport) {
            newInputErrors[index].departureAirport = true;
          } else {
            newInputErrors[index].departureAirport = false;
          }
          if (!search.arrivalAirport) {
            newInputErrors[index].arrivalAirport = true;
          } else {
            newInputErrors[index].arrivalAirport = false;
          }
          if (!search.departureDate) {
            newInputErrors[index].departureDate = true;
          } else {
            newInputErrors[index].departureDate = false;
          }
        });
    
        setMultiCityMissingInput(newInputErrors);
      
        if (!checkObjectsNotNull(multiCitySearch)) {
          return;
        }
      }
    
    
       
    
  }, [handleErrorCheck]);
  
  return (
    <div
      className=" [ nc-divide-field ] flex w-full flex-col border-t border-neutral-100 md:flex-row md:items-center "
      key={index}
    >
      <div className="[ nc-divide-field ] relative flex flex-grow flex-col md:flex-row ">
        {index !== 0 && (
          <span
            className="absolute top-1/3 right-7 flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-red-400 text-white transition-all duration-300 hover:scale-105"
            onClick={() => {
              setMultiCitySearchNumber(multiCitySearchNumber - 1);

              let newMultiCitySearch = [...multiCitySearch];
              let newErrors = [...multiCityMissingInput];

              newErrors.splice(index, 1);
              newMultiCitySearch.splice(index, 1);

              setMultiCityMissingInput(newErrors);
              setMultiCitySearch(newMultiCitySearch);
            }}
          >
            <IoClose />
          </span>
        )}
        <AutocompleteForMultiFlight
          placeHolder={t("app.flight-search.airport")}
          desc={t("app.flight-search.from")}
          getUrl={getAirportAutocompleteUrl}
          autocompleteValue={multiCitySearch}
          setAutocompleteValue={setMultiCitySearch}
          localStorageToken="airport-departure"
          index={index}
          flightDirection="departure"
          error={
            multiCityMissingInput[index]
              ? multiCityMissingInput[index].departureAirport
              : false
          }
           focus={departureFocus}
           setFocus={setDepartureFocus}
           setDateFocus={setReturnFocus}
        />
        <Button onClick={() => handleAirportsChange(index)}>
          <FaExchangeAlt />
        </Button>

        <AutocompleteForMultiFlight
          placeHolder={t("app.flight-search.airport")}
          desc={t("app.flight-search.to")}
          getUrl={getAirportAutocompleteUrl}
          autocompleteValue={multiCitySearch}
          setAutocompleteValue={(value) => setMultiCitySearch(value)}
          localStorageToken="airport-arrival" 
          index={index}
          flightDirection="arrival"
          error={
            multiCityMissingInput[index]
              ? multiCityMissingInput[index].arrivalAirport
              : false
          }
           focus={returnFocus}
           onFocusChange={returnFocus}
           setFocus={setReturnFocus}
           setDateFocus={setDateFocus}
        />

                <DateInputMultiFlight
                    departureAirport={multiCitySearch[index]?.departureAirport}
                    arrivalAirport={multiCitySearch[index]?.arrivalAirport}
                    label={t("b2c-app.flights.date")}
                    multiCitySearch={multiCitySearch}
                    setMultiCitySearch={setMultiCitySearch}
                    numberOfMonths={isMobile ? 1 : 2}
                    index={index}
                    minDate={multiCitySearch[index - 1]?.departureDate ?? moment().subtract(1,'days')}
                    error={multiCityMissingInput[index] ? multiCityMissingInput[index].departureDate : false}
                 dateFocus={dateFocus}
                 setDateFocus={setDateFocus}
                />
                {first ? <div
                    className={cn(
                        "justify center flex items-center w-28 py-4 lg:py-0",
                    )}
                >
                    
                </div>
                    : <div
                        className={cn(
                            "hidden lg:invisible lg:block  px-4 py-4 lg:py-0",
                        )}
                    >
                        <SubmitButton />
                    </div>}
            </div>
        </div >
    )
}

export default MultiCityForm;

export function checkObjectsNotNull(arr: any[]) {
    return arr.every(obj => {
        return Object.values(obj).every(value => value !== null);
    });
}
