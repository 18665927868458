import React, {FC} from "react"
import { Message } from 'primereact/message';


interface IMessage {
    className?: string
    severity: "success"|"info"|"warn"|"error"|undefined
    content: string
}

export const CustomMessage: FC<IMessage> = (
{
    className = "",
    severity,
    content
}) => {
    return (
        <Message
           className={className}
           severity={severity}
           content={content}
        />
    )
}
