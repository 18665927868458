import React, { ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import ButtonPrimary from 'components/ui/Button/ButtonPrimary';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message?: string | ReactNode; // Message can be a string or a React node
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ isOpen, onClose, onConfirm, message }) => {
  const { t } = useTranslation();
  
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("generals.attention-this-action-is-not-reversible")}</DialogTitle>
      <DialogContent>
        {typeof message === 'string' ? (
          <DialogContentText>{message}</DialogContentText>
        ) : (
          message
        )}
        {!message && t("generals.are-you-sure-you-want-to-proceed")} 
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("b2c-app.generals.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {t("generals.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
