import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DataFilters, HotelSearchResult} from "../../api/types";

interface IHotelState {
    hotelData: HotelSearchResult[]|undefined
    hotelFilteredData: HotelSearchResult[]|undefined
    hotelFilter: DataFilters|null
    selectedHotelStars: number[]|null
    selectedSortValueHotel: number
    selectedAmenitiesCategories: string[]|null
    selectedBoards: string[]|null
    selectedAccommodationType: string[]|null
}

const initialState: IHotelState = {
    hotelData: undefined,
    hotelFilteredData: undefined,
    hotelFilter: null,
    selectedHotelStars: null,
    selectedSortValueHotel: 0,
    selectedAmenitiesCategories: null,
    selectedBoards: null,
    selectedAccommodationType: null
}

const hotelSlice = createSlice({
    name: 'hotel',
    initialState,
    reducers: {
        setHotelData: (state, action: PayloadAction<HotelSearchResult[]|undefined>) => {
            state.hotelData = action.payload
        },
        setHotelFilteredData: (state, action: PayloadAction<HotelSearchResult[]|undefined>) => {
            state.hotelFilteredData = action.payload
        },
        setHotelFilter: (state, action: PayloadAction<DataFilters|null>) => {
            state.hotelFilter = action.payload
        },
        setSelectedHotelStars: (state, action: PayloadAction<number[]|null>) => {
            state.selectedHotelStars = action.payload
        },
        setSelectedSortValueHotel: (state, action: PayloadAction<number>) => {
            state.selectedSortValueHotel = action.payload
        },
        setSelectedAmenitiesCategories: (state, action: PayloadAction<string[]|null>) => {
            state.selectedAmenitiesCategories = action.payload
        },
        setSelectedBoards: (state, action: PayloadAction<string[]|null>) => {
            state.selectedBoards = action.payload
        },
        setSelectedAccommodationType: (state, action: PayloadAction<string[]|null>) => {
            state.selectedAccommodationType = action.payload
        },
        resetFilter: (state) => {
            state.selectedHotelStars = null
            state.selectedAmenitiesCategories = null
            state.selectedBoards = null
            state.selectedAccommodationType = null
        }
    }
})

export const {
    setHotelData,
    setHotelFilteredData,
    setHotelFilter,
    setSelectedHotelStars,
    setSelectedSortValueHotel,
    setSelectedAmenitiesCategories,
    setSelectedBoards,
    setSelectedAccommodationType,
    resetFilter
} = hotelSlice.actions

export default hotelSlice.reducer
