import React from 'react';
import { RootSearchPageState, useReduxReducer } from 'redux/store';
import { FlightBanner } from './components/FlightBanner';
import { RentalcarBanner } from './components/RentalcarBanner';
import BookingListLight from 'pages/B2BDashboard/components/BookingListLight';
import ButtonPrimary from 'components/ui/Button/ButtonPrimary';
import ApproachingFlights from 'pages/B2BDashboard/components/AproachingFlights';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BestOffers from 'pages/PageHome/BestOffers';
import { isB2B } from 'api/helpers/checkUserRole';
import { PackageTourBanner } from './components/PackageTourBanner';

interface IBannerB2C {

}

export const Banners: React.FC<IBannerB2C> = ({}) => {
  const selectedTabs = useReduxReducer((state: RootSearchPageState) => state.tabs);
  const navigate = useNavigate()
  const {t} = useTranslation()
  const{isB2C} =useReduxReducer(state => state.general)
  const renderContent = () => {
    switch (selectedTabs.activeTab) {
      case "Car Rental":
        return <RentalcarBanner />;
        case "Transfer":
        return <RentalcarBanner />;
      case "Flights":
        return (
          <>
           {!isB2C && <div className="grid xl:grid-cols-2 gap-8 ">
  {/* Upcoming Options Section */}

    <BookingListLight filterStatus={"RESERVATION"} />

  {/* Approaching Flights Section */}
  
    <ApproachingFlights filterStatus={"ALL"} showAll={false} />
  
  </div>}

            <FlightBanner />
          </>
        );
        case ("HOTEL"):
          return(
            <BestOffers/>
          );
          case ("VILLA"):
            return(
              <BestOffers/>
            );
            case ("PackageTour"):
              return(
                <PackageTourBanner/>
              );
      default:
        return<> </>;
    }
  };
  
  return (
    <>
      {renderContent()}
    </>
  );
  
 
};




