export const getBookingStatus = (status: string): string => {
    switch (status.toUpperCase()) {
        case 'CONFIRMED' || 'TICKETED':
            return 'flightbooking.ticketed'
        case 'CANCELED':
            return 'flightbooking.canceled'
        case 'VOIDED':
            return 'generals.reservationstate.voided'
        case 'REFUNDED':
            return 'flightbooking.refunded'
        case 'EXPIRED':
            return 'flightbooking.expired'
        default:
            return status
    }
}
