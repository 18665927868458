import React, {FC, useEffect, useState} from 'react'
import {formatNumber} from "../../utils/formatNumber";
import {IDateRange} from "../../api/types";
import {useTranslation} from "react-i18next";

interface ITotalTable {
    data: {[type: string]: number},
    dataLastYear: {[type: string]: number},
    dateRange: IDateRange,
    dateRangeLastYear: IDateRange
}

export const TotalTable: FC<ITotalTable> = ({
    data,
    dataLastYear,
    dateRange,
    dateRangeLastYear,
}) => {
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [totalPriceLastYear, setTotalPriceLastYear] = useState<number>(0)

    const { t } = useTranslation()

    const types: string[] = [
        t('react.flight.label.flight'),
        t('react.searchmask.tab.hotel'),
        t('react.searchmask.flightnhotel'),
        t('react.searchmask.dynamic'),
        t('rentalcar.rentalcar'),
        t('transfer.transfer'),
        t('navbar.insurance')
    ]

    useEffect(() => {
        let _totalPrice: number = 0
        let _totalPriceLastYear: number = 0

        types.map(type => {
            _totalPrice += data.hasOwnProperty(type) ? data[type] : 0
            _totalPriceLastYear += dataLastYear.hasOwnProperty(type) ? dataLastYear[type] : 0
        })

        setTotalPrice(_totalPrice)
        setTotalPriceLastYear(_totalPriceLastYear)
    }, [dataLastYear])

    return (
        <div className={`card px-4 py-3 mb-3`}>
            <div className={`pt-2`}>
                <div className={`grid grid-cols-12`}>
                    <div className={`col-span-4`}>
                        <h1 className="mb-3 text-lg font-semibold">{t('app.general.comparelastyear')}</h1>
                    </div>
                    <div className={`col-span-4`}>
                        <h1 className="mb-3 text-lg font-semibold text-right pr-3">
                            {`${dateRangeLastYear.startDate?.format('DD.MM.YYYY')} - ${dateRangeLastYear.endDate?.format('DD.MM.YYYY')}`}
                        </h1>
                    </div>
                    <div className={`col-span-4`}>
                        <h1 className="mb-3 text-lg font-semibold text-right pr-3">
                            {`${dateRange.startDate?.format('DD.MM.YYYY')} - ${dateRange.endDate?.format('DD.MM.YYYY')}`}
                        </h1>
                    </div>
                </div>
                <div className={`grid grid-cols-12`}>
                    <div className={`col-span-4`}>
                        <h1 className="mb-3 text-lg font-semibold">{t('rentalcar.total')}</h1>
                    </div>
                    <div className={`col-span-4 pr-3 text-right`}>
                        <h1 className="mb-3 text-lg font-semibold">{formatNumber(totalPriceLastYear)} EUR</h1>
                    </div>
                    <div className={`col-span-4 pr-3 font-semibold text-right ${(totalPrice! < totalPriceLastYear!) ? 'text-red-500' : 'text-green-500'}`}>
                        <h1 className="mb-3 text-lg font-semibold">{formatNumber(totalPrice!)} EUR</h1>
                    </div>
                </div>
                {
                    types.map((_data, index) => {
                        return (
                            <div key={`types-${index}`} className={`grid grid-cols-12 border-t ${index%2 === 0 && 'bg-gray-100'}`}>
                                <div className={`col-span-4 py-1 pl-2`}>{_data}</div>
                                <div className={`col-span-4 py-1 pr-3 text-right`}>{ dataLastYear.hasOwnProperty(_data) ? formatNumber(dataLastYear[_data]) + ' EUR' : 'n.V.'}</div>
                                <div className={`col-span-4 py-1 pr-3 ${data.hasOwnProperty(_data) && 'font-semibold'} text-right ${data.hasOwnProperty(_data) ? (data[_data] < (dataLastYear.hasOwnProperty(_data) ? dataLastYear[_data] : 0)) ? 'text-red-500' : 'text-green-500' : ''}`}>{ data.hasOwnProperty(_data) ? formatNumber(data[_data]) + ' EUR' : 'n.V.'}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
