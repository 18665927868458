import React, { FC } from 'react'
import {IHotelReservation} from "../../../api/types";
import {useTranslation} from "react-i18next";
import {getBookingStatus} from "../../../utils/bookingStatus";
import { Rating } from 'primereact/rating';
import {formatDate} from "../../../utils/formatDate";

interface IHotelInformation {
    hotelData: IHotelReservation
    statusColorCode: string
    status: string
}

export const HotelInformation: FC<IHotelInformation> = ({hotelData, statusColorCode, status}) => {
    const { t } = useTranslation()

    return (
        <div className={`my-3 mx-2 mb-3 shadow-md bg-white rounded`}>
            <div className={`flex justify-between items-start cursor-pointer px-3 pb-3 pt-3`}>
                <div className={`text-xl font-semibold`}>{t('react.searchmask.tab.hotel')}</div>
                <div className={`text-l px-4 py-0.5 rounded text-center`} style={{backgroundColor: '#'+statusColorCode}}>{t(getBookingStatus(status))}</div>
            </div>
            <img
                src={hotelData.hotel.thumbnail}
                alt={hotelData.hotel.thumbnail}
            />
            <div className={`px-3 pb-3 pt-3`}>
                <div className={`pb-3`}>
                    <div className={`text-xl font-semibold`}>{hotelData.hotelName}</div>
                    <div className={`flex justify-start items-start pt-0.5`}>
                        <Rating value={hotelData.hotel.stars} readOnly disabled cancel={false} />
                    </div>
                </div>
                <div className={`pb-3`}>
                    <div className={`font-semibold`}>{t('rentalcar.address')}:</div>
                    <div className={`text-md`}>{hotelData.address}</div>
                </div>
                <div className={`grid grid-cols-12`}>
                    <div className={`col-span-2 font-semibold`}>{t('react.hotel.check-in')}:</div>
                    <div className={`col-span-4 text-center`}>{formatDate(hotelData.checkinDate.split('T')[0])}</div>
                    <div className={`col-span-3 font-semibold`}>Checkin:</div>
                    <div className={`col-span-3`}>{hotelData.hotel.checkinTime}</div>
                    <div className={`col-span-2 font-semibold`}>{t('react.hotel.check-out')}:</div>
                    <div className={`col-span-4 text-center`}>{formatDate(hotelData.checkoutDate.split('T')[0])}</div>
                    <div className={`col-span-3 font-semibold`}>Checkout:</div>
                    <div className={`col-span-3`}>{hotelData.hotel.checkoutTime}</div>
                </div>
            </div>
        </div>
    )
}
