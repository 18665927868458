import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatServerDate } from 'utils/formatserverdate';
import { disableButton } from 'utils/disableButton';
import { Booking } from 'api/types'; // Assuming Booking interface is imported from API types
import currencyFormat from 'utils/currencyFormat';
import { useReduxReducer } from 'redux/store';

export interface TransferData {
    recoCode: string;
    firstname: string;
    surname: string;
    passengers: string; // Consider parsing this JSON string into an array of objects
    pickupDate: string;
    returnDate: string;
    adtCount: number;
    chdCount: number;
    infCount: number;
    vehicleType: string;
    totalPrice: number;
    email: string;
    phone: string;
    hotelNameOrAddress: string;
    hotelAddress: string;
    hotelPostcode: string;
    hotelCity: string;
    flightNo: string;
    flightDate: string;
    departureTime: string;
    flightDeparturePort: string;
    returnFlightNo: string;
    returnFlightDate: string;
    returnDepartureTime: string;
    returnFlightDeparturePort: string;
    isRoundTrip: boolean;
    transferOptions: any; // Replace 'any' with a specific type if known
    baggageAllowance: number;
    vehicleName: string;
    vehicleDescription: string;
    vehicleImage: string;
    transferTime: number;
    transferDistance: string;
    pickupDestination: {
      id: number;
      location: string;
      latlng: string;
      type: string;
      typecode: string;
      code: string | null;
      pactId: string | null;
      paxDriveId: string | null;
    };
    targetDestination: {
      id: number;
      location: string;
      latlng: string;
      type: string;
      typecode: string;
      code: string | null;
      pactId: string | null;
      paxDriveId: string | null;
    };
    transferProvider: {
      id: number;
      transferProvider: string;
      transferProviderLabel: string;
      tpContactPerson: string;
      tpTelephone: string;
      tpEmail: string;
      tpCountry: string;
      logoUrl: string;
      airportLocationDescription: string;
      nonAirportLocationDescription: string;
      freeSaleAdvancePurchaseHours: number;
      disabledAgencyIds: string;
      enabledAgencyIds: string;
      externalProvider: boolean;
      enabled: boolean;
    };
  }
  
interface SectionTransferProps {
  data: Booking
  transferData:TransferData
  status: string
  
}

const TransferDetail: React.FC<SectionTransferProps> = ({ data, transferData, status }) => {
  const { t } = useTranslation();
  const baseUrl = 'your_base_url_here'; // Replace with your actual base URL
const {isAdmin} = useReduxReducer(state => state.general)
  const handleMarkAsRefundedTra = () => {
    const forms = document.forms;
    if (forms.length > 0) {
      const form = forms[0];
      form.action = `${baseUrl}/intern/markTrAsRefunded`;
      form.submit();
    } else {
      console.error("No forms found on the page.");
    }
  };



  return (
    <div className="my-4">
      <section className="my-4 rounded border bg-white shadow-sm">
        <div className="mb-1 py-3">
          <div
            className={`mx-8 flex h-4 items-center justify-center rounded p-3
              ${status === "CONFIRMED" ? "bg-green-400" : ""}
              ${status === "RESERVATION" ? "bg-blue-400" : ""}
              ${status === "CANCELED" ? "bg-red-400" : ""}
              ${status === "VOIDED" ? "bg-red-400" : ""}
              ${status === "REFUNDED" ? "bg-gray-400" : ""}
              ${status === "EXPIRED" ? "bg-red-400" : ""}
            `}
          >
            {status === "RESERVATION" ? (
              <>
                {t("flightbooking.ticketing_deadline")}:{" "}
                {/*formatServerDate(data.transferReservation.optionDate)*/}
              </>
            ) : null}
            {status === "CONFIRMED" ? <>{t("flightbooking.ticketed")}</> : null}
            {status === "CANCELED" ? <>{t("flightbooking.canceled")}</> : null}
            {status === "VOIDED" ? <>Voided Kayit</> : null}
            {status === "REFUNDED" ? <>{t("flightbooking.refunded")}</> : null}
            {status === "EXPIRED" ? <>{t("flightbooking.expired")}</> : null}
          </div>
          <h3 className="p-2 font-bold">{t("transfer.details")}</h3>
          <div className="flex">
            <div className="w-1/2 p-4">
              <h3 className="items-center font-bold">
                {transferData?.transferProvider.transferProvider}
              </h3>
              <p>{transferData.transferProvider.tpTelephone}</p>
              <p>{transferData.transferProvider.tpEmail}</p>
            </div>
            <div className="w-1/2 flex-wrap ml-32">
              <div className="ml-32 w-1/2">
                <img alt="provider" src={transferData.transferProvider.logoUrl} />
              </div>
              <p className="text-sm">
                {transferData.transferProvider.airportLocationDescription}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="my-4 rounded border bg-white shadow-sm">
        <div className="my-2 flex justify-around gap-4">
          <div className="text-sm ml-2 flex items-center">
            <p className="text-md font-semibold">
              {t("transfer.passenger-count")}:{" "}
              {transferData.adtCount + transferData.chdCount + transferData.infCount} (
              {t("generals.adult")}: {transferData.adtCount},{" "}
              {t("generals.child")}: {transferData.chdCount},{" "}
              {t("generals.infant")}: {transferData.infCount})
            </p>
          </div>
          <div className="">
            <p className="font-semibold">
              Hotel : {transferData.hotelNameOrAddress}
            </p>
            <p className="text-sm">
              {t("transfer.hotel-name-or-address")}: {transferData.hotelAddress}
            </p>
          </div>
        </div>
      </section>

      <section className="my-4 rounded border shadow-sm">
        <div className="flex justify-around">
          <div className="text-lg grid grid-cols-1 grid-rows-4 gap-4 border bg-white p-6">
            <div className="text-md font-semibold">
              {t("pdf.transfer-pickup-information")}
            </div>
            <div className="text-md">
              <span className="font-semibold">{t("generals.date")}:</span>{" "}
              {transferData.flightDate}
            </div>
            <div className="text-md">
              <span className="font-semibold">{t("react.transfer.pickup")}:</span>{" "}
              {transferData.pickupDestination.location}
            </div>
            <div className="text-md">
              <span className="font-semibold">{t("react.transfer.destinaiton")}:</span>{" "}
              <span>
                {transferData.targetDestination.location}
                <p className="text-sm">
                  {transferData.hotelNameOrAddress}
                  {transferData.hotelAddress}
                </p>
              </span>
            </div>
          </div>

          {transferData.returnFlightDate && (
            <div className="grid grid-cols-1 grid-rows-4 gap-4 border bg-white p-6">
              <div className="text-md font-semibold">
                {t("pdf.transfer-return-information")}
              </div>
              <div>
                <span className="font-semibold">{t("generals.date")}:</span>{" "}
                {transferData.returnFlightDate}
              </div>
              <div>
                <span className="font-semibold">{t("react.transfer.pickup")}:</span>{" "}
                <span>
                  {transferData.targetDestination.location}
                  <p className="text-sm">
                    {transferData.hotelNameOrAddress}
                    {transferData.hotelAddress}
                  </p>
                </span>
              </div>
              <div>
                <span className="font-semibold">{t("react.transfer.destinaiton")}:</span>{" "}
                {transferData.pickupDestination.location}
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 grid-rows-4 gap-4 border bg-white p-6">
            <div>
              <span className="font-semibold">{t("transfer.arrivaltime")}</span>
            </div>
            <div>
              <span className="font-semibold">{t("flights.departure_port")}:</span>{" "}
              {transferData.flightDeparturePort}
            </div>
            <div>
              <span className="font-semibold">{t("generals.flight-number")}:</span>{" "}
              {transferData.flightNo}
            </div>
            <div>
              <span className="font-semibold">{t("charter.arrival-date-and-time")}:</span>{" "}
              {transferData.departureTime}-{transferData.flightDate}
            </div>
          </div>

          {transferData.returnFlightDate && (
            <div className="grid grid-cols-1 grid-rows-4 gap-4 border bg-white p-6">
              <div>
                <span className="font-semibold">{t("transfer.returntime")}</span>
              </div>
              <div>
                <span className="font-semibold">{t("flights.departure_port")}:</span>{" "}
                {transferData.returnFlightDeparturePort}
              </div>
              <div>
                <span className="font-semibold">{t("generals.flight-number")}:</span>{" "}
                {transferData.returnFlightNo}
              </div>
              <div>
                <span className="font-semibold">{t("charter.departure-date-and-time")}:</span>{" "}
                {transferData.returnDepartureTime}-{transferData.returnFlightDate}
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="my-4 rounded border bg-white shadow-sm">
        <div className="items-center">
          <table className="table-bordered table-hover w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">{t("react.hotel.firstname")}</th>
                <th className="px-4 py-2">{t("react.hotel.lastname")}</th>
                <th className="px-4 py-2">{t("charter.passenger-type")}</th>
                <th className="px-4 py-2">{t("generals.gender")}</th>
              </tr>
            </thead>
            <tbody>
              {data.passengers.map((row) => (
                <tr key={row.id}>
                  <td className="px-4 py-2">{row.firstname}</td>
                  <td className="px-4 py-2">{row.surname}</td>
                  <td className="px-4 py-2">{row.paxtype}</td>
                  <td className="px-4 py-2">{row.gender}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="mt-2 p-2">Passenger Contact: {transferData.phone}</p>
          <div className="text-base rounded bg-slate-100 p-1 text-gray-900">
            <span>
              {t("rentalcar.total")}: {currencyFormat(transferData.totalPrice)}
            </span>
          </div>
        </div>
      </section>

      <section className="my-4 rounded border bg-white py-3 shadow-sm">
        <div className="text-sm flex flex-wrap items-center justify-center text-center">
          {status === "CONFIRMED" && isAdmin && (
            <div>
              <form>
                <input type="hidden" name="bookingKey" value={data.bookingKey} />
              </form>
              <button
                id="markAsRefundTra"
                type="button"
                className="text-sm rounded border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                onClick={() => {
                  const button = document.getElementById("markAsRefundTra") as HTMLButtonElement | null;
                  if (button) {
                    disableButton([button]);
                  }
                  handleMarkAsRefundedTra();
                }}
              >
                İade edildiğini yansıt
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default TransferDetail;
