import React, {FC, useState} from 'react'
import {useTranslation} from "react-i18next";
import {PnrDetailPage, PnrModel} from "../../../api/types";
import {Tag} from "primereact/tag";
import {getBookingStatus} from "../../../utils/bookingStatus";
import {copyHandler} from "../../../utils/copyHandler";
import {BookingActionButtons} from "./BookingActionButtons";
import {ProgressBar} from "primereact/progressbar";

interface IFlightInformation {
    bookingKey: string
    flightTrip: PnrModel
    index: number
    status: string
    statusColorCode: string
}

export const FlightInformation: FC<IFlightInformation> = ({bookingKey, flightTrip, index, status, statusColorCode}) => {
    const { t } = useTranslation()

    return (
        <div key={`flighttrip-${index}`} className={`my-3 mx-2 mb-3 shadow-md bg-white rounded`}>
            <div className={`px-3 pb-3 pt-3`}>
                <BookingActionButtons
                    status={status}
                    rescanAvailable={flightTrip.rescanAvailable}
                    bookingKey={bookingKey}
                    fileKey={flightTrip.filekey}
                />
                <div className={`flex justify-between cursor-pointer pb-1`}>
                    <div className={`text-l px-4 py-0.5 rounded text-center`} style={{backgroundColor: '#'+statusColorCode}}>{t(getBookingStatus(status))}</div>
                    {
                        flightTrip.filekey && (
                            <div onClick={() => copyHandler(flightTrip.filekey)} className={` flex gap-x-2 text-l px-4 py-0.5 rounded text-center bg-gray-200 border border-gray-600`}>
                                <div><i className="pi pi-copy"></i></div>
                                <div>{flightTrip.filekey}</div>
                            </div>
                        )
                    }
                </div>
                {
                    flightTrip.flightList.map((flight, index) =>
                        <div className={`border p-2 my-2`} key={index}>
                            <div className={`flex justify-between pb-3`}>
                                <div className={`text-l font-semibold`}>{`${index+1}. ${t('react.flight.label.flight')} ${flight.departureDateStr}`}</div>
                                <div className={`flex gap-x-2 justify-end text-right`}>
                                    <Tag>{flight.flightNumber}</Tag>
                                    <Tag>{flight.classOfService}</Tag>
                                    <Tag>{flight.baggageAllowance}</Tag>
                                </div>
                            </div>
                            <div key={`flightInfo-${index}`} className={`text-l grid grid-cols-5`}>
                                <div className={`col-span-1`}>
                                    <img
                                        src={flight.airlineLogo}
                                        className="w-14"
                                        alt={flight.airline}
                                    />
                                </div>
                                <div className={`col-span-4`}>
                                    <div>{`${flight.departureTimeStr} ${flight.departurePortName} (${flight.departurePort})`}</div>
                                    <div>{`${flight.arrivalTimeStr} ${flight.arrivalPortName} (${flight.arrivalPort})`}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={`flex justify-between text-l font-semibold px-1 pt-2`}>
                    <div>Total</div>
                    <div>{flightTrip.totalAmount.toFixed(2).replace('.', ',')} €</div>
                </div>
            </div>
        </div>
    )
}
