import { useState, useCallback } from 'react';
import axios from 'axios';

interface InvoiceDetail {
  id: number;
  bookingReference: string;
  jupiterBooking: boolean;
  flightBookings: FlightBooking[];
  rentalCarBookings: any[];
  payments: any[];
  creationDate: number;
  invoiceDueDate: number;
  travelDate: number;
  invoiceOwnerUserName: string;
  agencyCustomerNumber: string;
  agencyCustomerName: string;
  invoiceNumber: string;
  invoiceStatus: string;
  productType: string;
  totalPurchasePrice: number;
  totalSalesPrice: number;
  outstandingBalance: number;
  paid: boolean;
  travelRegistrant: string;
}

interface FlightBooking {
  id: number;
  airline: string;
  supplier: {
    id: number;
    invoiceOwnerAgencyId: number;
    supplierName: string;
    notes: string;
  };
  airlineFilekey: string;
  bookingReference: string;
  jupiterBooking: boolean;
  flightList: Flight[];
  paxList: Passenger[];
  bookingDate: number;
  travelDate: number;
  invoiceStatus: string;
  invoiceOwnerUserName: string;
  agencyCustomerNumber: string;
  agencyCustomerName: string;
  totalNetFare: number;
  totalPurchasePrice: number;
  totalSalesPrice: number;
  expectedTotalIncentive: number;
  paxCount: number;
  flightCount: number;
  travelRegistrant: string;
}

interface Flight {
  id: number;
  flightNumber: string;
  airline: string;
  classOfService: string;
  baggageAllowance: string;
  departureDate: number;
  arrivalDate: number;
  departurePort: string;
  arrivalPort: string;
  cabinClass: string;
}

interface Passenger {
  id: number;
  paxtype: string;
  gender: string;
  birthdate: number;
  firstname: string;
  surname: string;
  ticketnumber: string;
  baseFare: number;
  tax: number;
  serviceFee: number;
  hiddenServiceFee: number;
}

export const useGetDetail = () => {
  const [loading, setLoading] = useState(false);
  const [errorDetail, setErrorDetail] = useState<string | null>(null);
  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetail | null>(null);

  const fetchInvoiceDetail = useCallback(async (invoiceNumber: string) => {
    setLoading(true);
    setErrorDetail(null);
    try {
      const response = await axios.get<InvoiceDetail>(`/agency/mars/invoices/${invoiceNumber}`);
      setInvoiceDetail(response.data);
    } catch (err) {
      setErrorDetail('Failed to fetch invoice details');
      console.error('Error fetching invoice details:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, errorDetail, invoiceDetail, fetchInvoiceDetail };
};

