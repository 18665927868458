import React from 'react'
import Sidebar from './components/Sidebar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HelpIcon from '@mui/icons-material/Help';

interface LayoutProps {
  children: React.ReactNode
}
const navigation = [
    {
      icon: DashboardIcon,
      text: 'Home',
      subnav: [
         { text: 'Overview', href: '/accounting' },
       
       ],
    },
    {
      icon: ReceiptIcon,
      text: 'Invoices',
      subnav: [
        { text: 'Create New', href: '/accounting/invoices/createnew' },
        { text: 'Invoice List', href: '/accounting/invoices' },
      ],
    },
    {
      icon: SettingsIcon,
      text: 'Wallet',
      subnav: [
        { text: 'Wallet', href: '/accounting/walletnew' },
      
      ],
    },
    {
      icon: HelpIcon,
      text: 'Transactions',
      subnav: [
        { text: 'Transactions', href: '/help/faqs' },
     
      ],
    },
  ];

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex min-h-screen bg-gray-100">
              <Sidebar nav={navigation} />

      <main className="flex-1 p-8 lg:ml-64">
        {children}
      </main>
    </div>
  )
}

export default Layout
