import {IAgentSalesreport} from "../api/types";

export function createFlightSummary(flightData: IAgentSalesreport[]): {[date: string]: {[airlineCode: string]: number}} {
    const flightSummary: {[date: string]: {[airlineCode: string]: number}} = {}

    if (flightData.length > 0) {
        flightData.map(_data => {
            if (Object.keys(flightSummary).includes(_data.EntryDate)) {
                if (Object.keys(flightSummary[_data.EntryDate]).includes(_data.AirlineCode))
                    flightSummary[_data.EntryDate][_data.AirlineCode] = flightSummary[_data.EntryDate][_data.AirlineCode] + Number.parseInt(_data.SalePrice.toFixed(2))
                else
                    flightSummary[_data.EntryDate][_data.AirlineCode] = Number.parseInt(_data.SalePrice.toFixed(2))
            } else {
                flightSummary[_data.EntryDate] = {}
                flightSummary[_data.EntryDate][_data.AirlineCode] = Number.parseInt(_data.SalePrice.toFixed(2))
            }
        })

    }

    return flightSummary
}

export function createSalesreportSummary(_data: IAgentSalesreport[], type: string): {[date: string]: {[type: string]: number}} {
    const summary: {[date: string]: {[type: string]: number}} = {}

    if (_data.length > 0) {
        _data.map(_data => {
            summary[_data.EntryDate] = {}
            summary[_data.EntryDate][type] = _data.SalePrice
        })
    }

    return summary
}

export function createTotal(t: any, _data: IAgentSalesreport[]): {[type: string]: number} {
    const _total: {[type: string]: number} = {}

    _data.map(value => {
        const _typeName: string = getTypeName(t, value.BookingTypeID.toString())
        if (_total.hasOwnProperty(_typeName))
            _total[_typeName] = _total[_typeName] + value.SalePrice
        else
            _total[_typeName] = value.SalePrice
    })

    return _total
}

export function getTypeName(t: any, key: string): string {
    const types: {[key: string]: string} = {
        "1": t('react.flight.label.flight'),
        "2": t('react.searchmask.tab.hotel'),
        "3": t('react.searchmask.flightnhotel'),
        "4": t('react.searchmask.dynamic'),
        "5": t('rentalcar.rentalcar'),
        "6": t('transfer.transfer'),
        "7": t('navbar.insurance')
    }
    return types[key]
}
