import {IAgentSalesreport} from "../../../api/types";
import moment from "moment/moment";

export const sortSalesReportsAscending = (reports: IAgentSalesreport[]): IAgentSalesreport[] => {
    return reports.sort((a, b) => moment(a.EntryDate, 'DD.MM.YYYY').diff(moment(b.EntryDate, 'DD.MM.YYYY')));
}

export const sortSalesReportsDescending = (reports: IAgentSalesreport[]): IAgentSalesreport[] => {
    return reports.sort((a, b) => moment(b.EntryDate, 'DD.MM.YYYY').diff(moment(a.EntryDate, 'DD.MM.YYYY')));
}
