import React, {FC, useEffect, useState} from "react"
import {Chart} from "primereact/chart";
import IconButton from "@mui/material/IconButton";
import BarChartIcon from "@mui/icons-material/BarChart";
import FunctionsIcon from '@mui/icons-material/Functions';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {CustomMessage} from "../../components/ui/Message/CustomMessage";
import {ProgressBar} from "primereact/progressbar";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";

interface IVerticalBar {
    data: {[date: string]: {[type: string]: number}}|{[type: string]: number}
    dataLastYear: {[date: string]: {[type: string]: number}}|{[type: string]: number}|null
    showTotal: boolean
    currentYear?: number
    lastYear?: number
    isLoading: boolean
    showDiagramIcons: boolean
    infoText: string
    dataSets: {[key: string]: number[]}
    sortedDataSets: [string, number][]
    selectedAirlines?: string[]|null
    setSelectedAirline: (value: string[]) => void
    
}

export const VerticalBar: FC<IVerticalBar> = ({
                                                  data,
                                                  dataLastYear = null,
                                                  showTotal = false,
                                                  currentYear = '',
                                                  lastYear = '',
                                                  isLoading,
                                                  showDiagramIcons,
                                                  infoText,
                                                  dataSets,
                                                  sortedDataSets,
                                                  selectedAirlines = null,
                                                  setSelectedAirline,
}) => {

    const [airlines, setAirlines] = useState<{code: string}[]>([])
    const [_selectedAirlines, _setSelectedAirlines] = useState<{code: string}[]>([])
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [chartType, setChartType] = useState<"bar"|"line"|"doughnut">('bar')
    const [isShowTotal, setShowTotal ] = useState<boolean>(false)

    const documentStyle = getComputedStyle(document.documentElement);
    const color: any[] = [
        documentStyle.getPropertyValue('--blue-500'),
        documentStyle.getPropertyValue('--pink-500'),
        documentStyle.getPropertyValue('--orange-500'),
        documentStyle.getPropertyValue('--green-500'),
        documentStyle.getPropertyValue('--red-500'),
        documentStyle.getPropertyValue('--purple-500'),
        documentStyle.getPropertyValue('--teal-500'),
        documentStyle.getPropertyValue('--yellow-500'),
        documentStyle.getPropertyValue('--cyan-500'),
        documentStyle.getPropertyValue('--deep-purple-500'),
        documentStyle.getPropertyValue('--lime-500'),
        documentStyle.getPropertyValue('--amber-500'),
        documentStyle.getPropertyValue('--indigo-500'),
        documentStyle.getPropertyValue('--brown-500'),
        documentStyle.getPropertyValue('--pink-A200')
]

    useEffect(() => {
        if (sortedDataSets.length > 0) {
            const _dataSets: any[] = []

            if (showTotal) {
                _dataSets.push({
                    label: currentYear,
                    backgroundColor: color[1],
                    borderColor: color[1],
                    data: dataLastYear
                })
                _dataSets.push({
                    label: lastYear,
                    backgroundColor: color[0],
                    borderColor: color[0],
                    data: data
                })
            }
            else if (isShowTotal)
                _dataSets.push({
                    label: 'Total',
                    backgroundColor: sortedDataSets.slice(0, selectedAirlines ? selectedAirlines.length : 4).map((_data, i) => color[i%color.length]),
                    borderColor: sortedDataSets.slice(0, selectedAirlines ? selectedAirlines.length : 4).map((_data, i) => color[i%color.length]),
                    data: sortedDataSets.filter(key => selectedAirlines ? selectedAirlines.includes(key[0]) : true).map(_data => _data[1])
                })
            else
                sortedDataSets.filter(key => selectedAirlines ? selectedAirlines.includes(key[0]) : true).map((key, index) =>
                    _dataSets.push({
                        label: key[0],
                        backgroundColor: color[index%color.length],
                        borderColor: color[index%color.length],
                        data: dataSets[key[0]]
                    })
                )

            const _data = {
                labels: isShowTotal ? sortedDataSets.filter(key => selectedAirlines ? selectedAirlines.includes(key[0]) : true).map(key => key) : Object.keys(data),
                datasets: _dataSets
            }

            const options = {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltip: {
                        mode: 'index',
                    },
                    legend: {
                        labels: {
                            fontColor: '#000'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#000',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: true,
                            drawBorder: true
                        }
                    },
                    y: {
                        ticks: {
                            color: '#000'
                        },
                        grid: {
                            display: true,
                            drawBorder: false
                        }
                    }
                }
            }

            setChartData(_data)
            setChartOptions(options)
        }
    }, [sortedDataSets, isShowTotal, selectedAirlines, data])

    useEffect(() => {
        if (sortedDataSets.length > 0) {
            const _airlines: {code: string}[] = []
            sortedDataSets.map(_data => _airlines.push({code: _data[0]}))
            setAirlines(_airlines)
        }
    }, [sortedDataSets])

    useEffect(() => {
        if (selectedAirlines) {
            const _airlines: {code: string}[] = []
            selectedAirlines.map(data => _airlines.push({code: data}))
            _setSelectedAirlines(_airlines)
        }
    }, [selectedAirlines])

    const selectedAirlineHandler = (e: MultiSelectChangeEvent) => {
        const _airlines: string[] = []
        const _selectedAirlines: {code: string}[] = e.value
        _selectedAirlines.map(data => _airlines.push(data.code))
        setSelectedAirline(_airlines)
        _setSelectedAirlines(e.value)
    }

    return (
        <>
            {
                (Object.keys(data).length > 0 || isLoading) ?
                    <div className={`card relative my-3 ${isLoading ? '' : 'p-4'}`}>
                        {
                            isLoading && (
                                <ProgressBar mode="indeterminate" style={{ height: '3px' }}></ProgressBar>
                            )
                        }
                        <div className={`${showDiagramIcons ? '' : 'hidden'} flex flex-row-reverse`}>
                            <IconButton aria-label="funcitonIcon" color="success" onClick={() => setShowTotal(true)}>
                                <FunctionsIcon />
                            </IconButton>
                            <IconButton aria-label="line" color="secondary" onClick={() => {
                                setChartType("line")
                                setShowTotal(false)
                            }}>
                                <ShowChartIcon />
                            </IconButton>
                            <IconButton aria-label="bar" color="primary" onClick={() => {
                                setChartType("bar")
                                setShowTotal(false)
                            }}>
                                <BarChartIcon />
                            </IconButton>
                            {
                                selectedAirlines && (
                                    <MultiSelect
                                        value={_selectedAirlines}
                                        onChange={selectedAirlineHandler}
                                        options={airlines}
                                        optionLabel="code"
                                        filter
                                        placeholder="Select Airlines"
                                        maxSelectedLabels={0}
                                        className="pr-1"
                                    />
                                )
                            }
                        </div>
                        <Chart
                            type={isShowTotal || showTotal ? 'bar' : chartType}
                            data={chartData}
                            options={chartOptions}
                        />
                    </div>
                    :
                    <CustomMessage
                        className="border-primary w-full justify-content-start mt-3"
                        severity={"info"}
                        content={infoText}
                    />
            }
        </>
    )
}
