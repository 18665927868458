import React, { useEffect, useRef, useState } from "react";
import ClearDataButton from "./ClearDataButton";
import useSearchAutocomplete from "api/hooks/useGetAutocomplete";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import AddIcon from '@mui/icons-material/Add';
import useGetSuggested from "api/hooks/useGetSuggestet";
import { AirportList, SearchTab } from "api/types";
import { Chip, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import useQueryParameters from "hooks/useQueryParameters";
import ToastNotification from "components/ui/ToastNotification/ToastNotification";

export interface AutocompleteForFlightProps<T> {
  category?: string;
  defaultValue?: any;
  queryType?: SearchTab;
  localStorageToken?: string;
  autocompleteValue: T | null;
  getUrl: (term: string) => string;
  setAutocompleteValue: React.Dispatch<React.SetStateAction<T | null>>;
  Icon?: React.ReactNode & React.FC<React.ComponentProps<"svg">>;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  onInputDone?: () => void;
  error?: boolean;
  setDateFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  focus?: boolean;
  setFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  searchType?: string;
  airportList?: AirportList;
  setAirportList?: React.Dispatch<React.SetStateAction<AirportList | []>>;
  airportChange?: boolean; 
  maxInputValuesLength?: number;
}

function AutocompleteForFlight<
  T extends { id?: string; text?: string; label?: string; iataCode?: string }
>({
  category,
  autoFocus,
  getUrl,
  autocompleteValue,
  setAutocompleteValue,
  Icon = LocationMarkerIcon,
  placeHolder = "Airport",
  desc = "Leaving from",
  className = "nc-flex-1.5",
  onInputDone = () => {},
    error = false,
  setDateFocus = () => {},
  focus,
  setFocus = () => {},
  setAirportList,
  airportList,
  searchType,
  airportChange,
  maxInputValuesLength, 
}: AutocompleteForFlightProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef1 = useRef<HTMLDivElement>(null);
  const containerRef2 = useRef<HTMLDivElement>(null);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const { query, setQuery, result } = useSearchAutocomplete<T[]>(
    autocompleteValue?.text || "",
    getUrl
  );
  interface MyState<T extends { id: string; text?: string; label?: string }> {
    data: (T | null)[];
  }
  const [inputValues, setInputValues] = useState<MyState<{ id: string; text?: string; label?: string }>>({
    data:  [],
  });
  
  const { suggested } = useGetSuggested<T[]>();
  const { t } = useTranslation();
  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef1.current &&
      !containerRef1.current.contains(event.target as Node) &&
      containerRef2.current &&
      !containerRef2.current.contains(event.target as Node)
    ) {
      setShowPopover(false);
    }
  };
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
  const resetNotification = () => {
  setMessage(null);
  setSeverity('info');
       };
  
  
  const [focusedIndex, setFocusedIndex] = useState(0); // Initialize focused index state
  useEffect(() => {
    let focusedIndex = 0; // Initialize focused index
  
    const handleGlobalClick = (event: MouseEvent) => {
      handleClickOutside(event as any);
    };
  
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown" && result?.length > 0) {
        focusedIndex = (focusedIndex + 1) % result.length; 
        setFocusedIndex(focusedIndex)// Increment and wrap around
        event.preventDefault();
      }
      else if (event.key === "Tab" && result?.length > 0) {
        focusedIndex = (focusedIndex + 1) % result.length; 
        setFocusedIndex(focusedIndex)// Increment and wrap around
        event.preventDefault();
      } else if (event.key === "ArrowUp" && result?.length > 0) {
        focusedIndex = (focusedIndex - 1 + result.length) % result.length;
        setFocusedIndex(focusedIndex) // Decrement and wrap around
        event.preventDefault();
      } else if (event.key === "Enter" && focusedIndex >= 0 && focusedIndex < result.length) {
        handleSelectLocation(result[focusedIndex]); // Select the focused item
      }
    };
  
    document.addEventListener("mousedown", handleGlobalClick);
    inputRef.current?.addEventListener("keydown", handleKeyPress);
  
    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
      inputRef.current?.removeEventListener("keydown", handleKeyPress);
    };
  }, [result]);
  
  

  useEffect(() => {
    if (autoFocus === true || focus === true) setShowPopover(true);
  }, [autoFocus, focus]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
      setQuery("");
    }

    if (!showPopover) {
      if (!autocompleteValue) {
        setQuery("");
      } else {
        setQuery(autocompleteValue.text || autocompleteValue.label || "");
      }
    }
  }, [showPopover, autocompleteValue]);

  useEffect(() => {
    if (!showPopover && inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.value =
          autocompleteValue?.text || autocompleteValue?.label || "";
      }
    }
  }, [showPopover, autocompleteValue]);

  const handleSelectLocation = (item: any) => {
    const newItem:  T | null = {
      id: item.id,
      text: item.text   } as T;
      if (maxInputValuesLength && inputValues.data.length >= maxInputValuesLength) {
        setMessage(t("reissue.selectoneairport"))
        setSeverity("warning")
        return;
      }
      setInputValues((prevInputValues: any) => {
        return {
          data: [...prevInputValues.data, newItem],
        };
      });
      
      onInputDone && onInputDone();
      setFocus(false);
      setShowPopover(false);
      setDateFocus(true);
      setFocusedIndex(0)
      if (setAirportList && airportList) {
        setAirportList([...airportList, { ...item, iataCode: item?.id }]);
      }
     else {
      console.error("Duplicate airport!");
    }
  };

  const handleChipDelete = (chipIndex: any) => {
    setInputValues(
      (
        prevInputValues: MyState<{ id: string; text?: string; label?: string }>
      ) => {
        const updatedData = prevInputValues.data.filter(
          (item) => item?.id !== chipIndex
        );
        return {
          data: updatedData,
        };
      }
    );
    if (setAirportList && airportList) {
      setAirportList((prevAirportList: AirportList | null) => {
        if (prevAirportList) {
          return prevAirportList.filter((item: any) => item?.id !== chipIndex);
        } else {
          return [];
        }
      });
    }
    setQuery("");
    setFocus(false);
    setDateFocus(false);
    setFocusedIndex(0)
  };

  useEffect(() => {
    const idValues = inputValues?.data.map((value) => value?.id);
    const textValues = inputValues?.data.map((value) => value?.text);
    const updatedAutocompleteValue = {
      ...autocompleteValue,
      id: idValues.join(","),
      text: textValues.join(","),
    } as T;
    setAutocompleteValue(updatedAutocompleteValue);

  }, [inputValues]);

  useEffect(() => {
    handleChangeAirport()
  }, [airportChange]);


  useEffect(() => {
    if(inputValues.data!.length < 1){
      handleChangeAirport()
    }
  }, [autocompleteValue]);

const handleChangeAirport =() => {
    if (
      autocompleteValue &&
      autocompleteValue.id &&
      autocompleteValue.text
    ) {
      const idValues = autocompleteValue.id.split(",");
      const textValues = autocompleteValue.text.split(",");
      const updatedData = idValues.map((id, index) => ({
        id: id.trim(),
        text: textValues[index].trim(),
      }));
      setInputValues({ data: updatedData });
    }
  }


  const renderSearchValue = () => {
    return (
      <div
        className="absolute left-0 top-full z-40 mt-0 w-full min-w-[250px] overflow-y-auto rounded border border-neutral-200 bg-white py-3 shadow-sm dark:bg-neutral-800 sm:min-w-[350px] sm:py-6"
        ref={containerRef1}
      >
        {inputValues.data.length > 0 && 
        <div className="flex justify-center">
                  <span className="font-semibold text-sm"> {t("flight.search.selected-airports")}</span>

        </div>
        
        }
         <Stack direction={inputValues.data.length > 2 ? "column" : "row"} spacing={0.5} p={1} px={4} alignItems="flex-start">
    {autocompleteValue && inputValues.data.map((value: any, index: number) => (
      <div
      key={index}
      className="flex items-center border border-gray-300 rounded-md p-1 m-1 text-sm"
    >
      <span className="flex-grow">
        {`${value?.text!.split(" ")[0]} ${value?.id}`}
      </span>
      <button
        onClick={() => handleChipDelete(value.id)}
        className="ml-2 text-neutral-500 hover:text-neutral-700 focus:outline-none"
        aria-label="Delete"
      >
        &#10005; {/* Cross icon (×) for deletion */}
      </button>
    </div>
    ))}
  </Stack>
          {result?.length && result.length > 0 ? (
               <><div className="flex justify-center">
            <span className="font-semibold text-sm"> {t("flight.search.select-airport")}</span>

          </div><Stack direction="column" spacing={0.5} p={1} px={2} alignItems="flex-center">
              {result.map((item, index) => (
           <div
           key={item.id}
           onClick={() => handleSelectLocation({ ...item, text: item.text + '+' })}
           className={`cursor-pointer m-1 p-2 border rounded-lg ${
             index === focusedIndex ? 'bg-gray-300' : 'bg-white'
           } hover:bg-primary-50`}
         >
           <div className="grid grid-cols-8 gap-4">
             <div className="text-neutral-400 text-xs flex items-center w-full col-span-7">
               <span className="pr-0.5">
                 <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
               </span>
               <span className="font-base block text-neutral-700 dark:text-neutral-200 truncate">
                 {item.text || item.label}
               </span>
             </div>
             <div className="flex justify-end text-neutral-400 col-span-1">
               <AddIcon className="h-4 w-4 sm:h-6 sm:w-6" />
             </div>
           </div>
         </div>
         
              ))}
            </Stack></>
) : (
  <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
    {t("app.no-search-results")}
  </span>
)}
      </div>
    );
  };

  const errorClassName = error ? "text-red-400" : "text-neutral-400";
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
   
    setQuery(value);
    setAutocompleteValue((prevAutocomplete: any) => prevAutocomplete);
    setFocusedIndex(0);
  };

  return (
    <div className={`relative flex ${className}`}>
      <div
        ref={containerRef2}
        onClick={() => {
          setShowPopover(true);
          setDateFocus(false);
          setFocusedIndex(0);
          setFocus(true);
        }}
        className={`relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 px-4 text-left focus:outline-none  ${
          showPopover ? "rounded-3xl  dark:bg-neutral-800" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400 flex ite">
          <Icon className="nc-icon-field" />
          <span
              className={
                " block text-xs pl-0.5 font-medium text-neutral-400 " + errorClassName
              }
            >
               {!!inputValues || !!query ? desc : placeHolder}

            </span>
        </div>
        <div className="flex-grow py-1">
        
             <Stack direction={ 
              inputValues.data.length === 1 
              ? "row": "column"} 
              spacing={0.5} pt={0} alignItems="stretch" justifyContent={"flex-center"}>
       
            {autocompleteValue && !showPopover &&
              inputValues.data.map((value: any, index: number) => (
                <div
                  key={index}
                  className="flex justify-between items-center px-2 p-1 border rounded-full bg-gray-100 hover:bg-primary-50"
                >
                  <span className="text-sm whitespace-nowrap font-medium px-1">
                    {searchType === "PackageTour"
                      ? value.id
                      : (value?.text!.split(" ")[0] + " " + value?.id).length > 16
                      ? value.id
                      : value?.text!.split(" ")[0] + " " + value?.id}
                  </span>
                  <button
                    onClick={() => handleChipDelete(value.id)}
                    className="ml-2  hover:text-red-500 text-neutral-500 focus:outline-none"
                  >
                    &#10005;
                  </button>
                </div>
              
              
              ))}
               <input
            className={`xl:text-base -mb-[6px] block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200 `}
            placeholder={inputValues.data.length > 0 ? '' : placeHolder}
            value={showPopover ? query : ''}
            autoFocus={showPopover}
            onChange={handleInputChange}
          // onBlur={handleBlurFunction}
            ref={inputRef}
          />
          </Stack>
         
          {query && showPopover && (
            <ClearDataButton
              onClick={() => {
                setQuery("");
                setAutocompleteValue(null);
                setInputValues({ data: [] });
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="">
          {
             renderSearchValue()
            }
        </div>
      )}
           <ToastNotification message={message} severity={severity} resetNotification={resetNotification} />

    </div>
  );
}

export default AutocompleteForFlight;


