import React from 'react';
import currencyFormat from 'utils/currencyFormat';
import { formatDate } from 'utils/formatDate';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BannerCardRentalcar } from 'api/types';
import RentalCarQueryParametersService from 'services/RentalCarQueryParametersService';

export const RentalcarBannerCard: React.FC<BannerCardRentalcar> = (banner: BannerCardRentalcar) => {
const navigate = useNavigate()  
const HandleNavigateSearch = () => {
    const dateRange = {
        startDate: moment(banner.pickupDate),
        endDate: moment(banner.returnDate)
    }
  const urlSearchParams = RentalCarQueryParametersService.encode(
    "27",
    false,
 {
    id:banner.pickupLocationCode,
    text:banner.pickupLocationName
 },
{
    id:banner.returnLocationCode,
    text:banner.returnLocationName
 },
   dateRange,
   "12:00",
   "12:00",
    ) 

if(urlSearchParams)
navigate("/rent-car/results?" + urlSearchParams);
}
 
  return (
    <div
    onClick={HandleNavigateSearch}
    className="bg-white rounded-lg shadow-md overflow-hidden transition duration-200 ease hover:shadow-2xl hover:cursor-pointer">
      <figure className="w-full">
        <div className="relative h-2/3">
          <img src={banner.imageUrl} alt={""} className=" object-contain h-48  p-4 w-full" />
        </div>
      </figure>
        <div className="grid grid-rows-2 p-2">
      <div className='flex justify-between gap-1 text-base font-bold items-center text-gray-900 p-1'>
        <span> {banner.vehicleName}</span>
        <img width="60" height="60" className="" src={banner.providerLogoUrl} alt=""/>    

       </div>
      <div className='flex justify-between items-center text-sm gap-4 text-gray-900 px-1'> 
      <span className='gap-1 '>{banner.pickupLocationName} {formatDate(banner.pickupDate)}</span>
      <span className="text-primary-6000 font-semibold text-xl">{currencyFormat(banner.price) }</span>
      </div>
      </div>
  </div>
  );
};




