import { Booking } from 'api/types';
import { CarDataType } from 'data/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxReducer } from 'redux/store';
import currencyFormat from 'utils/currencyFormat';
import { formatServerDate } from 'utils/formatserverdate';
import { getStaticUrl } from 'utils/getStaticUrl';

export interface RentalCarData {
    firstname: string;
    surname: string;
    birthdate: string; // Assuming it's a date string
    mobilePhone: string;
    firstname2: string | null;
    surname2: string | null;
    birthdate2: string | null; // Assuming it's a date string
    mobilePhone2: string | null;
    landlinePhone: string | null;
    email: string;
    additionalInformation: string | null;
    flightNumber: string;
    flightTime: string;
    flightDeparturePort: string;
    pickupDate: string; // Assuming it's a date string
    returnDate: string; // Assuming it's a date string
    totalDays: number;
    dailyRentPrice: number;
    dropFee: number;
    systemFee: number;
    totalAmount: number;
    purchasePrice: number;
    deposit: number;
    depositPaymentType: string;
    optionsJson: string;
    xmlBookingParameters: string;
    address: string;
    phone: string;
    supplier: any; // Replace with specific type if known
    supplierLogo: any; // Replace with specific type if known
    rentalCarImageUrl: string;
    rentalCarBrand: string;
    rentalCarType: string;
    rentalCarGroup: string;
    pickupLocation: string;
    returnLocation: string;
    provider: string;
    vehicle: {
      id: number;
      brand: {
        name: string;
        logo: string;
      };
      name: string;
      model_name: string;
      vehicle_group: string;
      vehicle_type: string;
      body_style: string;
      gear_type: string;
      fuel_type: string;
      fuel_capacity: number;
      fuel_type_unit: string;
      min_driver_age: number;
      min_driver_license_age: number;
      deposit_price: number;
      daily_km_limit: number;
      color: string;
      weight: number;
      height: number;
      width: number;
      length: number;
      passenger_capacity: number;
      large_suitcase_capacity: number;
      small_suitcase_capacity: number;
      segment_code: string;
      segment_text: string;
      unique_model_code: string;
      old_unique_model_code: string;
      image: string;
    };
    price_details: {
      total_price: number;
      daily_price: number;
      total_listing_price: number;
      dropoff_price: number;
      pay_later_price: number;
      point: number;
      currency: string;
      currency_symbol: string;
    };
    pickup_branch: {
      id: number;
      old_id: string;
      name: string;
      address: {
        address_line_1: string;
        address_line_2: string;
        city: string;
        township: string;
        postal_code: string;
        country_code: string;
        country: string;
        lat: number;
        lon: number;
      };
      phone: string;
      slug: string;
      iata_code: string;
      working_hours: {
        [day: string]: {
          start_hour: string;
          close_hour: string;
        };
      };
      simplified_working_hours: {
        [dayRange: string]: {
          start_hour: string;
          close_hour: string;
        };
      };
      working_hours_text: string;
      drop_location: string;
      contact_email: string;
      contact_person: string;
      map_image_url: string;
      get_cms_url: {
        url: string;
        category_url: string;
      };
      locations: {
        id: number;
        name: string;
      }[];
    };
    dropoff_branch: {
      id: number;
      old_id: string;
      name: string;
      address: {
        address_line_1: string;
        address_line_2: string;
        city: string;
        township: string;
        postal_code: string;
        country_code: string;
        country: string;
        lat: number;
        lon: number;
      };
      phone: string;
      slug: string;
      iata_code: string;
      working_hours: {
        [day: string]: {
          start_hour: string;
          close_hour: string;
        };
      };
      simplified_working_hours: {
        [dayRange: string]: {
          start_hour: string;
          close_hour: string;
        };
      };
      working_hours_text: string;
      drop_location: string;
      contact_email: string;
      contact_person: string;
      map_image_url: string;
      get_cms_url: {
        url: string;
        category_url: string;
      };
      locations: {
        id: number;
        name: string;
      }[];
    };
    purchased_addons: any[]; // Replace with specific type if known
    customer_note: string | null;
  }
  
interface Props {
 carrentData: RentalCarData
    data: Booking
}

const RentACarDetail: React.FC<Props> = ({
  carrentData,
  data,
 
}) => {
    const {t} = useTranslation()
    const baseUrl = getStaticUrl('b2b');
    const {isAdmin} = useReduxReducer(state => state.general)
  const HandleMarkAsRefunded = () => {
    const confirmation = window.confirm(t("flightbooking.options.are-you-sure"));


    if (confirmation) {
      let forms = document.forms;
      if (forms.length === 0) {
        let form = document.createElement("form");
        document.getElementsByTagName("body")[0].appendChild(form);
        forms = document.forms;
      }
      if (forms.length > 0) {
        const form = forms[0];
        form.action = baseUrl + `/intern/markRcrAsRefunded`;
        form.submit();
      } else {
        console.error("No forms found on the page.");
      }
    } else {
      console.log("Mark as refunded operation cancelled.");
    }
  };
const provider = JSON.parse(carrentData.provider)
  const HandleDeleteRent = () => {
    const forms = document.forms;
    if (forms.length > 0) {
      const form = forms[0];
      form.action = baseUrl + `/agency/deleteRentalCarReservation`;
      form.submit();
    } else {
      console.error("No forms found on the page.");
    }
  };

  return (
    <div className="my-4">
      <section className="shadow-sm">
        <div className="bg-white  py-3">
          <h3 className="border-bottom pb-4 font-bold">Rent A Car</h3>
        </div>
        <div className="justify-between bg-white p-6 xl:flex">
        <div className="sm:w-full xl:w-1/3">
              <p>{provider.displayName}</p>
              <img
                className=" w-sm h-sm p-8 "
                src={provider.logo}
              />
            </div>
          <div className="sm:w-full xl:w-1/3">
            <img
              className="w-sm h-sm object-contain p-8"
              src={carrentData.rentalCarImageUrl}
              alt="Rental Car Image"
            />
          </div>
          <div className="px-4 pt-4 sm:w-full xl:w-1/3">
            {carrentData.rentalCarBrand +
              " " +
              carrentData.rentalCarType}
            <p>or</p>
            <p>{carrentData.rentalCarGroup}</p>
          </div>
        </div>
        <div
          className={`flex h-4 items-center justify-around rounded p-3 
                            ${
                              data.status === "CONFIRMED" ? "bg-green-400" : ""
                            }
                            ${
                              data.status === "RESERVATION"
                                ? "bg-blue-400"
                                : ""
                            }
                            ${data.status  === "CANCELED" ? "bg-red-400" : ""}
                            ${data.status  === "VOIDED" ? "bg-red-400" : ""}
                            ${data.status  === "REFUNDED" ? "bg-gray-400" : ""}
                            ${data.status  === "EXPIRED" ? "bg-red-400" : ""}`}
        >
          {data.status  === "RESERVATION" ? (
            <>
              {t("flightbooking.ticketing_deadline")}:{" "}
              {/*formatServerDate(carrentData.optionDate)*/}
            </>
          ) : null}
          {data.status  === "CONFIRMED" ? <>{t("flightbooking.ticketed")}</> : null}
          {data.status === "CANCELED" ? <>{t("flightbooking.canceled")}</> : null}
          {data.status  === "VOIDED" ? <>Voided Kayit</> : null}
          {data.status === "REFUNDED" ? <>{t("flightbooking.refunded")}</> : null}
          {data.status === "EXPIRED" ? <>{t("flightbooking.expired")}</> : null}
          <div className="flex h-4 items-center justify-center rounded p-3">
            {t("rentalcar.deposit")}: {currencyFormat(carrentData.deposit)}
          </div>
          <div className="flex h-4 items-center justify-center rounded p-3">
            {t("rentalcar.total")}: {currencyFormat(carrentData.totalAmount)}
          </div>
        </div>
        <div className="mb-4 grid grid-cols-1 gap-4 bg-white p-4 md:grid-cols-5 md:p-6 lg:p-8">
          <div>
            <p>
              <b>{t("rentalcar.pickup-location")}</b>
            </p>
            <p>{carrentData.pickupLocation}</p>
          </div>
          <div className="">
            <p>
              <b>{t("rentalcar.pickup-date")}</b>
            </p>
            {formatServerDate(carrentData.pickupDate)}
          </div>
          <div className="">
            <p>
              <b>{t("rentalcar.days")}</b>
            </p>
            {carrentData.totalDays}
          </div>
          <div className="">
            <p>
              <b>{t("rentalcar.return-date")}</b>
            </p>
            {formatServerDate(carrentData.returnDate)}
          </div>
          <div>
            <p>
              <b>{t("rentalcar.return-location")}</b>
            </p>
            <p>{carrentData.returnLocation}</p>
          </div>
        </div>
      </section>

      <div className={"my-4"}>
        <section className="shadow-sm">
          <div className="border-bottom-0 rounded border bg-white py-4">
            <h3 className="border-bottom pb-4 font-bold">
              {t("rentalcar.driver")}
            </h3>
          </div>
          <div className="border-top-0 mb-4 rounded border bg-white p-4 md:p-6 lg:p-8">
            {data.passengers.map((row: any, index: number) => (
              <div key={index} className={"grid grid-cols-1 gap-4 md:grid-cols-5"}>
                <div>
                  <p>
                    <b>{t("rentalcar.firstname")}</b>
                  </p>
                  <p>{row.firstname}</p>
                </div>
                <div className="">
                  <p>
                    <b>{t("rentalcar.surname")}</b>
                  </p>
                  {row.surname}
                </div>
                <div className="">
                  <p>
                    <b>{t("rentalcar.phone")}</b>
                  </p>
                  {data.contact.phone}
                </div>
                <div className="">
                  <p>
                    <b>{t("rentalcar.birthdate")}</b>
                  </p>
                  {formatServerDate(row.birthdate)}
                </div>
                <div>
                  <p>
                    <b>{t("rentalcar.email")}</b>
                  </p>
                  <p>{data.contact.email}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="shadow-sm">
          <div className="flex justify-center rounded border bg-white">
            <div className="inline-flex justify-center p-2 " role="group">
              {data.status === "CONFIRMED" &&
              isAdmin ? (
                <div>
                  <form>
                    <input
                      type="hidden"
                      name="bookingKey"
                      value={data.bookingKey}
                    />
                  </form>
                  <button
                    id="markAsRefunded"
                    type="button"
                    className="text-sm flex  justify-center rounded  border border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                    onClick={() => {
                      const button = document.getElementById("markAsRefunded") as HTMLButtonElement | null;
                      if (button) {
                        button.disabled = true;
                      }
                      HandleMarkAsRefunded();
                    }}
                  >
                    {t("flightcases.refund-request")}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RentACarDetail;
