import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import { Button } from 'primereact/button';
import NcInputNumberAdult from "../NcInputNumberAdult";
import NcInputNumber from "../NcInputNumber";
import {TravelersInputValue} from "../HeroSearchForm/TravelersInput";

interface IMobileFlightPassengerSelection {
    className?: string
    adtCount: number
    chdCount: number
    infCount: number
    setTravelers: (value: TravelersInputValue) => void
}

export const MobileFlightPassengerSelection: FC<IMobileFlightPassengerSelection> = (
    {
        className = '',
        adtCount,
        chdCount,
        infCount,
        setTravelers
    }) => {

    const [ _adtCount, setAdtCount ] = useState<number>(adtCount)
    const [ _chdCount, setChdCount ] = useState<number>(chdCount)
    const [ _infCount, setInfCount ] = useState<number>(infCount)
    const [ openModal, setOpenModal ] = useState<boolean>(false)

    const { t } = useTranslation()

    const passengers: {adtCount: number, chdCount: number, infCount: number} = {adtCount: _adtCount, chdCount: _chdCount, infCount: _infCount}

    useEffect(() => {
        if(openModal) {
            passengers.adtCount = _adtCount
            passengers.chdCount = _chdCount
            passengers.infCount = _infCount
        }
    }, [openModal])

    const openModalHandler = () => {
        setOpenModal(!openModal)
    }

    const updatePassengerCounts = () => {
        setAdtCount(passengers.adtCount)
        setChdCount(passengers.chdCount)
        setInfCount(passengers.infCount)
        setTravelers({
            adults: passengers.adtCount,
            children: passengers.chdCount,
            infants: passengers.infCount,
        })
        openModalHandler()
    }

    const footerContent = (
        <div className={`w-full`}>
            <Button className={`w-full`} label={t('generals.save')} onClick={updatePassengerCounts} autoFocus />
        </div>
    );

    return (
        <>
            <div
                onClick={openModalHandler}
                className={`flex items-center text-sm rounded-sm border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                style={{
                    padding: '0.4rem 0.7rem',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: '#e5e7eb'
                }}
            >
                <i className={`pi ${(_adtCount + _chdCount + _infCount) === 1 ? 'pi-user' : 'pi-users'}`} style={{ fontSize: '1.3rem' }}></i>
                &nbsp;&nbsp;
                <div className={`text-xl font-light`}>
                    {
                        (_adtCount + _chdCount + _infCount) === 1 ?
                            t('general.traveling.person').replace('%PERSON%', (_adtCount + _chdCount + _infCount).toString()) :
                            `${(_adtCount + _chdCount + _infCount)} ${t('react.searchmask.flight.traveler')}`
                    }
                </div>
            </div>

            <Dialog
                header={t('react.hotel.travellers')}
                visible={openModal}
                footer={footerContent}
                position={'bottom'}
                style={{ width: '100%', margin: 0 }}
                onHide={openModalHandler}
                draggable={false}
                resizable={false}
            >
                <div className={`mb-4`}>
                    <NcInputNumberAdult
                        defaultValue={_adtCount}
                        onChange={(adults) => passengers.adtCount = adults}
                        label={t("generals.adult")}
                    />
                </div>
                <div className={`mb-4`}>
                    <NcInputNumber
                        defaultValue={_chdCount}
                        onChange={(child) => passengers.chdCount = child}
                        label={t("generals.child")}
                    />
                </div>
                <div>
                    <NcInputNumber
                        defaultValue={_infCount}
                        onChange={(infants) => passengers.infCount = infants}
                        label={t("generals.infant")}
                    />
                </div>
            </Dialog>
        </>
    )
}
