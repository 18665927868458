import React, {FC, useEffect, useState} from "react"
import {IAgentSalesreport, TBookingType} from "../../api/types";
import {formatNumber} from "../../utils/formatNumber";
import {sortSalesReportsAscending, sortSalesReportsDescending} from "./utils/Sort";
import {useTranslation} from "react-i18next";

interface IAllSingleEntriesTable {
    entries: IAgentSalesreport[]
    bookingType: TBookingType
    itemCount?: number
}

export const AllSingleEntriesTable: FC<IAllSingleEntriesTable> = ({entries, bookingType, itemCount}) => {
    const { t } = useTranslation()
    const [sortFunction, setSortFunction] = useState<0|1>(0)
    const [sortEntries, setSortEntries] = useState<IAgentSalesreport[]>([])

    useEffect(() => {
        if (sortFunction === 0)
            setSortEntries(sortSalesReportsDescending([...entries]))
        else if (sortFunction === 1)
            setSortEntries(sortSalesReportsAscending([...entries]))
    }, [sortFunction, entries])

    const sortHandler = () => {
        setSortFunction(sortFunction === 0 ? 1 : 0)
    }

    return (
        <div> 
            {
                entries.length > 0 && (
                    <>
                        <h1 className={`font-semibold mb-3 mt-4`}>{t('general.singleentries')}</h1>
                        <div className={`card px-4 py-3 mb-3`}>
                            <div className={`pt-2`}>
                                <div className={`grid grid-cols-12`}>
                                    <div className={`col-span-2 flex`}>
                                        <h1 className="mb-3 text-lg font-semibold">{t('sales-report.date-range')}</h1> &nbsp;
                                        <i
                                            className={`pi ${sortFunction === 0 ? 'pi-sort-amount-down' : 'pi-sort-amount-up'} cursor-pointer`}
                                            onClick={sortHandler}
                                        ></i>
                                    </div>
                                    <div className={`col-span-5`}>
                                        {
                                            bookingType === "0" && (
                                                <h1 className="mb-3 text-lg font-semibold text-right pr-3">
                                                    {t('flightbooking.airline')}
                                                </h1>
                                            )
                                        }
                                    </div>
                                    <div className={`col-span-5`}>
                                        <h1 className="mb-3 text-lg font-semibold text-right pr-3">
                                            {t('generals.amount')}
                                        </h1>
                                    </div>
                                </div>
                               
                                {itemCount ? (
    sortEntries.slice(0,itemCount).map((_data, index) => {
        return (
            <div key={`singleentrie-${index}`} className={`grid grid-cols-12 border-t ${index%2 === 0 && 'bg-gray-100'}`}>
                <div className={`col-span-2 py-1 pl-2`}>{_data.EntryDate}</div>
                <div className={`col-span-5 py-1 pr-3 text-right`}>{_data.AirlineCode}</div>
                <div className={`col-span-5 py-1 pr-3 font-semibold text-right`}>{formatNumber(_data.SalePrice)} EUR</div>
            </div>
        )
    })
) : (
    sortEntries.map((_data, index) => {
        return (
            <div key={`singleentrie-${index}`} className={`grid grid-cols-12 border-t ${index%2 === 0 && 'bg-gray-100'}`}>
                <div className={`col-span-2 py-1 pl-2`}>{_data.EntryDate}</div>
                <div className={`col-span-5 py-1 pr-3 text-right`}>{_data.AirlineCode}</div>
                <div className={`col-span-5 py-1 pr-3 font-semibold text-right`}>{formatNumber(_data.SalePrice)} EUR</div>
            </div>
        )
    })
)}

                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
