import React, { useEffect, useState } from 'react';
import { Paper, Typography, LinearProgress, TextField, Button, Box, Checkbox, FormControlLabel } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import fetcher from 'api/helpers/fetcher';
import { getWalletAgencyOverview } from 'api/helpers/api-constants';
import BackButton from 'components/ui/ButtonBack/ButtonBack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface WalletAgency {
  lastTransactionDate: string;
  balance: number;
  agencyNumber: string;
  numOfTransactions: number;
  agencyName: string;
  walletPaymentAgency: boolean;
}

const WalletAgencies: React.FC = () => {
  const [data, setData] = useState<WalletAgency[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const [filterByWalletPayment, setFilterByWalletPayment] = useState<boolean>(false); // New state for checkbox

  useEffect(() => {
    const fetchData = async () => {
      try {
        const walletAgencies = await fetcher(getWalletAgencyOverview());
        setData(walletAgencies);
      } catch (error) {
        console.error('Error fetching wallet agency data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filter data based on search term and checkbox state
  const filteredData = data
    .filter((agency) =>
      agency.agencyName.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
      agency.agencyNumber.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    )
    .filter((agency) => !filterByWalletPayment || agency.walletPaymentAgency); // Filter by walletPaymentAgency if checkbox is checked

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: t('densityTooltip') } }} // Translate tooltip
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: t('exportTooltip') }, // Translate export tooltip
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  const handleGetDetails = (agencyNumber: string) => {
    navigate(`/wallet/${agencyNumber}`);
  };

  // Define columns for the DataGrid
  const columns: GridColDef[] = [
    {
      field: 'agencyName',
      headerName: t('manage-agencies.agency'),
      flex: 1,
    },
    {
      field: 'agencyNumber',
      headerName: t('manage-agencies.agency-number'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'lastTransactionDate',
      headerName: t('Son İşlemler'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) =>
        moment(params.value as string).format('DD/MM/YYYY hh:mm'),
    },
    {
      field: 'numOfTransactions',
      headerName: t('İşlem sayısı'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'balance',
      headerName: t('wallet.balance'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            color: params.value < 0 ? 'red' : 'green',
            fontWeight: 600,
          }}
        >
          {params.value.toFixed(2)}
        </span>
      ),
    },
    {
      field: 'details',
      headerName: t('generals.details'),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          onClick={() => handleGetDetails(params.row.agencyNumber)}
        >
          {t('detaylar')}
        </Button>
      ),
    },
  ];

  const localeText = {
    toolbarDensity: t('react.wallet.density'),
    toolbarDensityLabel: t('react.wallet.density'),
    toolbarDensityCompact: t('0'),
    toolbarDensityStandard: t('1'),
    toolbarDensityComfortable: t('2'),
    toolbarExport: t('export'),
    toolbarExportLabel: t('exportLabel'),
    toolbarExportCSV: t('exportCSV'),
    noRowsLabel: t('generals.no-entry-found'),
    footerRowSelected: (count: number) => t('rowsSelected', { count }),
    footerTotalRows: t('generals.total'),
    paginationRowsPerPage: t(''), 
  };

  return (
    <div className="container my-12 space-y-8 min-h-screen">
      <BackButton />
      <Paper>
        <Typography variant="h6" gutterBottom align="center">
          {t('react.wallet.agencies')}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" className="mb-4 px-2">
          <TextField
            label={t('searchByNameOrNumber')}
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ minWidth: '30rem' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterByWalletPayment}
                onChange={(e) => setFilterByWalletPayment(e.target.checked)}
              />
            }
            label={'Sadece Wallet Acenteleri'} // Add translation key for checkbox label
          />
        </Box>
        
        <div style={{ height: "auto", width: '100%' }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}      
            pageSizeOptions={[25, 50, 100]}
            getRowId={(row) => row.agencyNumber}
            autoHeight
            loading={loading}
            localeText={localeText}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </div>
      </Paper>
    </div>
  );
};

export default WalletAgencies;
