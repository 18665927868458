import React, { FC } from "react";
import { RadioGroup as ReactRadioGroup } from "@headlessui/react";
import cn from "classnames";

export interface RadioGroupProps {
  values: { label: string; value: string }[];
  wrapperClassName?: string;
  className?: string;
  error?: string;
  selected: string;
  onChange: (value: string) => void;
}

const RadioGroup: FC<RadioGroupProps> = ({
  values,
  selected,
  wrapperClassName = "",
  className = "",
  error = "",
  onChange,
}) => {
  return (
    <div>
      <ReactRadioGroup value={selected} onChange={onChange} className="mt-2">
        <div
          className={cn(
            "grid grid-cols-3 gap-3 sm:grid-cols-3",
            wrapperClassName
          )}
        >
          {values.map((option, i) => (
            <ReactRadioGroup.Option
              key={i}
              value={option.value}
              className={({ active, checked }) =>
                cn(
                  active
                    ? "border-primary-300 ring ring-primary-200 ring-opacity-50 dark:border-neutral-700 dark:ring-primary-6000 dark:ring-opacity-25"
                    : "",
                  checked
                    ? "border-transparent bg-primary-6000 text-white hover:bg-primary-700 dark:bg-primary-700"
                    : "border-neutral-200 bg-white text-neutral-900 hover:bg-neutral-50",
                  "flex cursor-pointer items-center justify-center rounded border py-1.5 px-2.5 text-xs font-medium dark:border-neutral-700 dark:bg-neutral-800 dark:text-white sm:flex-1",
                  className
                )
              }
            >
              <ReactRadioGroup.Label as="p" className="whitespace-nowrap">
                {option.label}
              </ReactRadioGroup.Label>
            </ReactRadioGroup.Option>
          ))}
        </div>
      </ReactRadioGroup>
      {error && (
        <small className="text-xs text-red-400 dark:text-red-200">
          {error}
        </small>
      )}
    </div>
  );
};

export default RadioGroup;
