import React, { useState } from 'react';
import { Button, Menu, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'; 
import CreditCardForm from './CreditCardForm';
import { Booking } from 'api/types';
import { useTranslation } from 'react-i18next';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';
import PaymentButton from '../PaymentButton';
import { getStaticUrl } from 'utils/getStaticUrl';
import { getIssueBookingUrl, getIssueBookingWithStripeUrl, getPaxConnectBooking, getViewBookingUrl } from 'api/helpers/api-constants';
import { useReduxReducer } from 'redux/store';
import SorsatButtons from './SorsatButtons';

interface Props {
 data1: Booking
}

const FooterButtonsNew: React.FC<Props> = ({ data1 }) => {
  const [modal, setModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const baseUrl = getStaticUrl('b2b');
  const{product} = useReduxReducer(state => state.config)
  const {isAdmin, isB2C} = useReduxReducer(state => state.general)
  const handleReiseplan = (): void => {
    window.open(
      getViewBookingUrl(data1.bookingKey) +
        '.pdf',
      "_blank"
    );
  };

  const handleReiseplanImza = () => {
    window.open(
      getViewBookingUrl(data1.bookingKey) +
        ".pdf?withSignature=true",
      "_blank"
    );
  };

  const handleReiseplanImzaNooption = () => {
    window.open(
      getViewBookingUrl(data1.bookingKey) +
        ".pdf?showOptionDate=false",
      "_blank"
    );
  };

  const insuruancepdf = () => {
    window.open(
      getViewBookingUrl(data1.bookingKey) +
        ".pdf",
      "_blank"
    );
  };

  const sendEmail = () => {
    const email = prompt("E-Mail");
    if (email === null) return;

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          alert("E-Mail'iniz başarılı bir şekilde gönderilmiştir!");
        } else {
          alert("E-Mail gönderilemedi!");
        }
      }
    };

    xhr.open(
      "POST",
      getViewBookingUrl(data1.bookingKey) + `/sendMail`,
      true
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(`emailAdress=${email}`);
  };

  const handlemodal = () => {
    setModal(!modal);
    window.scrollTo(0, 0);
  };

  const cancelBooking = (): void => {
    const confirmation = window.confirm(
      t("generals.are-you-sure-you-want-to-proceed")
    );

    if (confirmation) {
      setLoading(true);
      const form = document.forms[0]; // Adjust if there are multiple forms
      form.action = baseUrl + "/agency/cancelBooking";
      form.addEventListener('submit', () => {
        setLoading(false);
      });
      form.submit();
    }
  };

  const handlePaxConnect = async () => {
    const url = getPaxConnectBooking() + data1.bookingKey + "/paxconnect";

    try {
      setLoading(true);
      const response = await fetch(url);

      if (response.ok) {
        setLoading(false);
        const responseText = await response.text();
        console.log(responseText);
        window.open(responseText, "_blank");
      } else {
        setLoading(false);
        console.error("Request failed with status " + response.status);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
const {t} = useTranslation()
  return (
    <div>
      <div className="rounded bg-white py-2">
        <div className="">
        <div className="inline-flex rounded-md shadow-sm" role="group">

          {data1.eligibleToViewVoucher  && 
                <div>
                  <button
                    className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900 hover:bg-gray-900"
                    onClick={(event) => handleClick(event)}>
                    {t("pdf.booking")}
                    <i className="fa fa-chevron-down text-black" aria-hidden="true"></i>
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => { handleClose(); handleReiseplan(); }}>
                      {t("pdf.booking")}
                    </MenuItem>
                    {data1.product.flightReservation?.routes.map((pnr, index) => (
                      pnr.status === "RESERVATION" ? (
                        <MenuItem key={index} onClick={() => { handleClose(); handleReiseplanImzaNooption(); }}>
                          {t("pdf.without-option-date")}
                        </MenuItem>
                      ) : null
                    ))}
                    <MenuItem onClick={() => { handleClose(); handleReiseplanImza(); }}>
                      {t("pdf.signature")}
                    </MenuItem>
                    <MenuItem
                      className="items-center flex"
                      onClick={sendEmail}>
                      {t("generals.email")}
                    </MenuItem>
                  </Menu>
                </div>
              }
                {
                (data1.status === "RESERVATION"  || data1.status === "ON_REQUEST_WAITING_FOR_PAYMENT") && (
                  <>
                    <button
                      id="cancelBooking"
                      type="button"
                      onClick={() => {
                        const button = document.getElementById("cancelBooking") as HTMLButtonElement | null;
                        if (button) {
                          button.disabled = true;
                        }
                        cancelBooking()
                      }}
                      className="text-sm  border   border-red-500 bg-red-500 px-4  py-2 text-center font-medium text-white hover:shadow-lg hover:text-red-700 focus:z-10 focus:text-red-700 focus:ring-2 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
                    >
                      <i className="fa fa-times text-white" aria-hidden="true"></i>{" "}
                      {t("booking.cancel")}
                    </button>
                    {product.paxConnectEnabled &&
                      <button
                        id="paxConnect"
                        className=" border p-1   border-gray-200"
                        onClick={() => {
                          const button = document.getElementById("paxConnect") as HTMLButtonElement | null;
                          if (button) {
                            button.disabled = true;
                          }
                          handlePaxConnect()
                        }}>
                        <img
                          alt="paxconnect"
                          className="h-5"
                          src="https://www.paxconnect.de/img/logo_paxconnect.png" />{" "}
                    </button>}
                    <form>
                      <input
                        type="hidden"
                        name="bookingKey"
                        value={data1.bookingKey}
                      />
                      <input
                        type="hidden"
                        name="filekey"
                        value={data1.product.flightReservation?.routes.map((row) => row.filekey)}
                      />
                    </form>
                    <button
                      type="button"
                      onClick={handlemodal}
                      disabled={!(data1.agency.userGrantedForBooking)}
                      className="text-sm rounded-r-lg border border-gray-200 bg-white px-4 py-2 text-center font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700"
                      title={!(data1.agency.userGrantedForBooking) ? t("booking.youarenotpermitted") : ""}
                    >
                      <i className="fa fa-credit-card" aria-hidden="true"></i> {t("booking.purchase")}
                    </button>

                  </>
                )}
              </div>
        
          {data1.status ==="ON_REQUEST_PENDING" && isAdmin && <SorsatButtons bookingKey={data1.bookingKey}/>}
          {data1.status === "ON_REQUEST_DECLINED" &&<span className='text-red-500 font-semibold text-center'>{t("generals.on-request.on-request-declined")}</span>}
          {data1.status === "ON_REQUEST_PENDING" &&<span className='text-red-500 font-semibold text-center'>{t("generals.on-request.please-contact-us")}</span>}

        </div>
      </div>

      <div>
        {modal && (
          <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
            <div className="w-1-4 rounded bg-white p-2">
            
              <ButtonClose onClick={handlemodal}></ButtonClose>
              <h1>
                {t(
                  "flightbooking.ticketing.please-select-your-preferred-payment-method"
                )}
              </h1>
              <div className=" rounded-md p-2 shadow-sm">
                <div className="w-full py-1 ">
               
                  <PaymentButton
                    path={getIssueBookingUrl()}
                    params={{
                      bookingKey: data1.bookingKey,
                      paymentType: "INVOICE",
                    }}
                  />
                </div>
               {!isB2C && <div className="w-full py-1">
                  <PaymentButton
                    path={getIssueBookingWithStripeUrl()}
                    params={{
                      bookingKey: data1.bookingKey,
                      paymentType: "KLARNA",
                    }}
                  />
                </div>}
                <div className="w-full py-1">
                  <PaymentButton
                    path={getIssueBookingWithStripeUrl()}
                    params={{
                      bookingKey: data1.bookingKey,
                      paymentType: "IDEAL",
                    }}
                  />
                </div>
                <div className="w-full py-1">
                  <PaymentButton
                    path={getIssueBookingWithStripeUrl()}
                    params={{
                      bookingKey: data1.bookingKey,
                      paymentType: "STRIPE",
                    }}
                  />
                  { data1.product.flightReservation?.airlineCreditCardAvailable &&
                    <div className="w-full py-1 px-2">
                      <Accordion defaultExpanded={false}>
                        <AccordionSummary
                          aria-controls="payment-details-content"
                          id="payment-details-header"
                          sx={{ p: 0, borderBottom: 'none' }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            className="w-full"
                          >Airline CC</Button>
                        </AccordionSummary>
                        <AccordionDetails>
                        
                          <CreditCardForm
                            bookingKey={data1.bookingKey}
                            totalAmount={data1.pricing.totalAmount} // Replace with actual variable
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FooterButtonsNew;
