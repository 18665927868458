import { SearchPayload } from "api/types";
import ValidationService from "./ValidationService";

class TransferQueryParametersService {
  static encode(formValues: any): URLSearchParams | null {
    const {
      arrivalAirport,
      departureAirport,
      departureDate,
      travelers,
      roundTrip,
      landingDate,
      departTime,
      returnTime,
    } = formValues;
    if (departureAirport && arrivalAirport) {
      const dt: any = {
        searchType: "Transfer",
        pickupLocationId: departureAirport?.id,
        destinationLocationId: arrivalAirport?.id,
        pickupSubLocationId: departureAirport?.subLocationId || "0",
        destinationSubLocationId: arrivalAirport?.subLocationId || "0",
        adtCount: travelers.adults,
        chdCount: travelers.children,
        infCount: travelers.infants,
        pickupDate: departureDate!.format("DD.MM.YYYY"),
        returnDate: roundTrip
          ? landingDate!.format("DD.MM.YYYY")
          : null,
        pickupHour: departTime?.substring(0, 2),
        pickupMinute: departTime?.substring(3, 5),
        returnHour: returnTime?.substring(0, 2),
        returnMinute: returnTime?.substring(3, 5),
        pickupLocationName: departureAirport?.name,
        destinationName: arrivalAirport?.name,
        roundTrip: roundTrip,
      };
      return new URLSearchParams({ ...dt });
    }

    return null;
  }

  static decode(urlParams: URLSearchParams): SearchPayload | null {
    let pickupLocationId = urlParams.get("pickupLocationId") || null;
    let destinationLocationId = urlParams.get("destinationLocationId") || null;
    let pickupSubLocationId = urlParams.get("pickupSubLocationId") || null || "0";
    let destinationSubLocationId = urlParams.get("destinationSubLocationId") || null || "0";
    let adtCount = Number(urlParams.get("adtCount") || null);
    let chdCount = Number(urlParams.get("chdCount") || null);
    let infCount = Number(urlParams.get("infCount") || null);
    let pickupDate = urlParams.get("pickupDate") || null;
    let returnDate = urlParams.get("returnDate") || null;
    let pickupHour = urlParams.get("pickupHour") || null;
    let pickupMinute = urlParams.get("pickupMinute") || null;
    let returnHour = urlParams.get("returnHour") || null;
    let returnMinute = urlParams.get("returnMinute") || null;
    let pickupLocation = urlParams.get("pickupLocationName") || null;
    let returnLocation = urlParams.get("destinationName") || null;
    let roundTrip = urlParams.get("roundTrip") || null;

    pickupDate = ValidationService.date(pickupDate, "DD.MM.YYYY")
      ? pickupDate
      : null;
    returnDate = ValidationService.date(returnDate, "DD.MM.YYYY")
      ? returnDate
      : null;

    if (
      !pickupLocationId
    ) {
      return null;
    }
    return {
      searchType: "Transfer",
      pickupSubLocationId,
      pickupLocationId,
      destinationLocationId,
      destinationSubLocationId,
      adtCount,
      chdCount,
      infCount,
      pickupDate,
      returnDate,
      pickupHour,
      pickupMinute,
      returnHour,
      returnMinute,
      pickupLocation,
      returnLocation,
      roundTrip,
    }
  }
}

export default TransferQueryParametersService;
