import React, {FC, useEffect, useState} from "react"
import {IAgentInvoiceList} from "../../api/types";
import {getAgentInvoicePdfUrl} from "../../api/helpers/api-constants";
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import {useTranslation} from "react-i18next";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { fetcherWithoutJSON } from "api/helpers/fetcher";

interface IInvoice {
    invoiceNo: string
    status: string
    bookingType: string
    date: string
    dateForSortField: string
    pnr: string
    amount: string
}

interface IInvoiceTable {
    data: IAgentInvoiceList[]
    showLinearBar: boolean
    showErrorAlert: boolean
    bookingTypes: string[]
}

export const InvoiceTable: FC<IInvoiceTable> = (
    {
        data,
        showLinearBar,
        showErrorAlert,
        bookingTypes
    }) => {
    const {t} = useTranslation();

    const [ selectedInvoiceNo, setSelectedInvoiceNo ] = useState<string>('')
    const [ invoices, setInvoices ] = useState<IInvoice[]>([])

    useEffect(() => {
        const _invoices: IInvoice[] = []
        data.map(_data => {
            const date: string[] = _data.InvoiceDate.split(" ")[0].split(".")
            _invoices.push({
                invoiceNo: _data.InvoiceNo,
                status: _data.Remain === 0 ? t('general.status.paid') : t('general.status.unpaid'),
                bookingType: _data.IsAvis ? 'Avis' : bookingTypes[_data.BookingTypeID],
                date: _data.InvoiceDate.split(" ")[0],
                dateForSortField: date[2] + date[1] + date[0],
                pnr: _data.RefPNR,
                amount: _data.Amount.toFixed(2).replace(".", ",") + " €"
            })
        })
        setInvoices(_invoices)
    }, [data])

    const clickHandler = (invoiceNumber: string) => {
        setSelectedInvoiceNo(invoiceNumber)

        fetcherWithoutJSON(getAgentInvoicePdfUrl(invoiceNumber))
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                window.open(link.href, "_blank")?.focus()
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setSelectedInvoiceNo('')
            })
    }

    return (
        <>
            { showErrorAlert ?
                <Alert className="mt-3" severity="error">{t('react.hotel.an-error-occured')}</Alert>
                :
                <div className="relative overflow-hidden rounded-xl border mt-3 border-neutral-100 bg-white transition-shadow dark:border-neutral-800 dark:bg-neutral-900 }">
                    { showLinearBar && (<LinearProgress color="inherit" />)}

                    <DataTable
                        value={invoices}
                        removableSort
                        selectionMode="single"
                        onSelectionChange={(e) => clickHandler(e.value.invoiceNo)}
                        loading={selectedInvoiceNo.length > 0}
                        className={'text-sm'}
                    >
                        <Column
                            className="border-b border-slate-100 font-bold text-slate-500 dark:border-slate-700 dark:text-slate-400"
                            header={t('general.invoice.number')}
                            field="invoiceNo"
                            sortable
                        ></Column>
                        <Column
                            className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400"
                            header={t('general.booking.type')}
                            field="bookingType"
                            sortable
                        ></Column>
                        <Column
                            className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400"
                            header={t('react.general.date')}
                            field="date"
                            sortable
                            sortField="dateForSortField"
                        ></Column>
                        <Column
                            className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400"
                            header="PNR"
                            field="pnr"
                            sortable
                        ></Column>
                        <Column
                            className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400"
                            header={t('bookings.status')}
                            field="status"
                            sortable
                        ></Column>
                        <Column
                            className="border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-400"
                            header={t('generals.amount')}
                            field="amount"
                            sortable
                        ></Column>
                    </DataTable>
                </div>
            }
        </>
    )
}
