import { useEffect, useState } from "react";
import { useLocalStorageValue } from "./useLocalStorageValue";

const MAX_RECENT_SEARCHES = 4;

function useRecentSearches<T>(key: string) {
  const [localStorageValue, setLocalStorageValue] = useLocalStorageValue({ key });
  const [recentSearches, setRecentSearches] = useState<Array<T>>(
    JSON.parse(localStorageValue ?? "[]")
  );
  function appendRecentSearch(newSearch: T) {
    const updatedSearches = [newSearch, ...recentSearches.slice(0, MAX_RECENT_SEARCHES - 1)];
    setRecentSearches(updatedSearches);
    setLocalStorageValue(JSON.stringify(updatedSearches));
  }
  function clearRecentSearches() {
    setRecentSearches([]);
    setLocalStorageValue(JSON.stringify([]));
  }
  useEffect(() => {
    
    setLocalStorageValue(JSON.stringify(recentSearches));
  }, []);
  return { recentSearches, appendRecentSearch, clearRecentSearches };
}

export default useRecentSearches;
