import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import useQueryParameters from "../../../hooks/useQueryParameters";
import {formatDateReverse, formatDateShortMonth} from "../../../utils/formatDate";

export const RentalcarContent: FC = () => {
    const { t } = useTranslation()
    const queryParameters = useQueryParameters()

    return (
        <div className={`flex-row justify-center items-center`}>
            <div className={`text-l font-semibold pt-1 pb-0`}>
                {queryParameters?.pickupLocation}
                {JSON.parse(queryParameters?.otherReturnStation!) && (
                    <a>
                        &nbsp;&nbsp;
                        <span className="pi pi-chevron-right text-l font-bold"></span>
                        &nbsp;&nbsp;
                        {queryParameters?.returnLocation}
                    </a>
                )}
            </div>
            <div className={`text-sm pt-0 pb-2`}>
                {`${buildDate(queryParameters?.pickupDate!)}, ${queryParameters?.departTime} - ${buildDate(queryParameters?.returnDate!)}, ${queryParameters?.returnTime}`}
            </div>
        </div>
    )
}

const buildDate = (date: string): string => {
    return formatDateShortMonth(formatDateReverse(date))
}
