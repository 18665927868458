import {SearchPayload, TPostRequest} from "../types";
import {useEffect, useState} from "react";
import { fetcherWithoutJSON } from "api/helpers/fetcher";

interface PropsI {
  searchUrl: () => string;
  payload: SearchPayload | null;
}

function usePostRequest<T>({ searchUrl, payload }: PropsI): TPostRequest {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const requestOptions = {
    method: "POST",
    headers: new Headers({"Content-Type": "application/json"}),
    body: JSON.stringify(payload),
  };

  const getAPIData = async () => {
    setLoading(true);
    try {
      const response = await fetcherWithoutJSON(searchUrl(), requestOptions);
      const json = await response.json();
      setData(json);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    getAPIData();
  }, [JSON.stringify(payload)]);

  return {
    data: data as T,
    error,
    isLoading
  };
}

export default usePostRequest;
