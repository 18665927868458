import { useState } from 'react';

export function useModalStates() {
  const [isMealOpen, setIsMealOpen] = useState(false);
  const [isSeatOpen, setIsSeatOpen] = useState(false);
  const [isBaggageOpen, setIsBaggageOpen] = useState(false);
  const [isWheelchairOpen, setIsWheelchairOpen] = useState(false);
  const [isCheckinOpen, setIsCheckinOpen] = useState(false);

  return {
    isMealOpen, setIsMealOpen,
    isSeatOpen, setIsSeatOpen,
    isBaggageOpen, setIsBaggageOpen,
    isWheelchairOpen, setIsWheelchairOpen,
    isCheckinOpen, setIsCheckinOpen
  };
}
