import React from 'react';

interface ActionButtonsProps {
  bookingKey: string;
}

const SorsatButtons: React.FC<ActionButtonsProps> = ({ bookingKey }) => {
  const handleApprove = async () => {
    try {
      const response = await fetch(`/intern/makeSorSatBuyable?bookingKey=${bookingKey}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error approving the booking:', error);
    }
  };

  const handleDecline = async () => {
    try {
      const response = await fetch(`/intern/makeSorSatDeclined?bookingKey=${bookingKey}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error declining the booking:', error);
    }
  };

  return (
    <div className="flex justify-center space-x-4">
      <button
        onClick={handleApprove}
        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        SorSat Onayla
      </button>
      <button
        onClick={handleDecline}
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
      >
        SorSat Reddet
      </button>
    </div>
  );
};

export default SorsatButtons;
