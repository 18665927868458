import React, { useMemo } from 'react';
import { 
  Paper, 
  Typography, 
  Grid, 
  TextField, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem
} from '@mui/material';
import { useFormContext } from '../../contexts/FormContext';
import moment from 'moment';
import { DatePickermui } from './DatePickermui';

const InvoiceDraftSummary: React.FC = () => {
  const { formData, updateFormData } = useFormContext();

  const handleInvoiceDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    updateFormData({
      invoiceDetails: {
        ...formData.invoiceDetails,
        [name]: name === 'totalAmount' ? parseFloat(value) || 0 : value,
      },
    });
  };

  const handlePassengerChange = (id: number, field: string, value: string | number) => {
    updateFormData({
      passengers: formData.passengers.map(p => 
        p.id === id ? {...p, [field]: value} : p
      )
    });
  };

  const handleFlightChange = (id: number, field: string, value: any) => {
    updateFormData({
      flights: formData.flights.map(f => 
        f.id === id ? {...f, [field]: value} : f
      )
    });
  };

  const handleCarRentalChange = (id: number, field: string, value: any) => {
    updateFormData({
      carRentals: formData.carRentals.map(c => 
        c.id === id ? {...c, [field]: value} : c
      )
    });
  };

  const handleHotelBookingChange = (id: number, field: string, value: any) => {
    updateFormData({
      hotelBookings: formData.hotelBookings.map(h => 
        h.id === id ? {...h, [field]: value} : h
      )
    });
  };
  const handleGenericProductChange = (id: number, field: string, value: any) => {
    updateFormData({
      genericProducts: formData.genericProducts.map(p => 
        p.id === id ? {...p, [field]: value} : p
      )
    });
  };

  const totalAmount = useMemo(() => {
    let total = 0;
    switch (formData.invoiceDetails.productType) {
      case 'flight':
        total = formData.passengers.reduce((sum, passenger) => sum + Number(passenger.serviceFee), 0);
        break;
      case 'car':
        total = formData.carRentals.reduce((sum, rental) => {
          const days = moment(rental.returnDate).diff(moment(rental.pickupDate), 'days');
          return sum + (rental.dailyRate * days);
        }, 0);
        break;
      case 'hotel':
        total = formData.hotelBookings.reduce((sum, booking) => {
          const nights = moment(booking.checkOutDate).diff(moment(booking.checkInDate), 'days');
          return sum + (booking.pricePerNight * nights);
        }, 0);
        break;
        case 'generic':
          total = formData.genericProducts.reduce((sum, product) => sum + (product.quantity * product.price), 0);
          break;
    }
    return total;
  }, [formData]);

  return (
    <Paper className="p-6">
      <Typography variant="h5" className="mb-4">Invoice Draft Summary</Typography>
      
      <Typography variant="h6" className="mt-4 mb-2">Invoice Details</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Customer Name"
            name="customerName"
            value={formData.invoiceDetails.customerName}
            onChange={handleInvoiceDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Product Supplier ID"
            name="productSupplierId"
            value={formData.invoiceDetails.productSupplierId}
            onChange={handleInvoiceDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Payment Account ID"
            name="paymentAccountId"
            value={formData.invoiceDetails.paymentAccountId}
            onChange={handleInvoiceDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Product Reference ID"
            name="productRefId"
            value={formData.invoiceDetails.productRefId}
            onChange={handleInvoiceDetailsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Product Type"
            name="productType"
            value={formData.invoiceDetails.productType}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Invoice Type"
            name="invoiceType"
            value={formData.invoiceDetails.invoiceType}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" className="mt-4 mb-2">Passengers</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Surname</TableCell>
                  <TableCell>Ticket Number</TableCell>
                  <TableCell>Tariff</TableCell>
                  <TableCell>Service Fee</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.passengers.map((passenger) => (
                  <TableRow key={passenger.id}>
                    <TableCell>
                      <TextField
                        select
                        fullWidth
                        value={passenger.paxType}
                        onChange={(e) => handlePassengerChange(passenger.id, 'paxType', e.target.value)}
                      >
                        <MenuItem value="ADT">Adult</MenuItem>
                        <MenuItem value="CHD">Child</MenuItem>
                        <MenuItem value="INF">Infant</MenuItem>
                      </TextField>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={passenger.name}
                        onChange={(e) => handlePassengerChange(passenger.id, 'name', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={passenger.surname}
                        onChange={(e) => handlePassengerChange(passenger.id, 'surname', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={passenger.ticketNumber}
                        onChange={(e) => handlePassengerChange(passenger.id, 'ticketNumber', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={passenger.tariff}
                        onChange={(e) => handlePassengerChange(passenger.id, 'tariff', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={passenger.serviceFee}
                        onChange={(e) => handlePassengerChange(passenger.id, 'serviceFee', Number(e.target.value))}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

      {formData.invoiceDetails.productType === 'flight' && (
        <>
         
          <Typography variant="h6" className="mt-4 mb-2">Flights</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Airline</TableCell>
                  <TableCell>Flight Number</TableCell>
                  <TableCell>Class</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.flights.map((flight) => (
                  <TableRow key={flight.id}>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={flight.airline}
                        onChange={(e) => handleFlightChange(flight.id, 'airline', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={flight.flightNumber}
                        onChange={(e) => handleFlightChange(flight.id, 'flightNumber', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        select
                        fullWidth
                        value={flight.class}
                        onChange={(e) => handleFlightChange(flight.id, 'class', e.target.value)}
                      >
                        <MenuItem value="Economy">Economy</MenuItem>
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="First">First</MenuItem>
                      </TextField>
                    </TableCell>
                    <TableCell>
                      <DatePickermui
                        date={flight.date}
                        handleDateChange={(date) => handleFlightChange(flight.id, 'date', date)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={flight.from}
                        onChange={(e) => handleFlightChange(flight.id, 'from', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={flight.to}
                        onChange={(e) => handleFlightChange(flight.id, 'to', e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {formData.invoiceDetails.productType === 'car' && (
        <>
          <Typography variant="h6" className="mt-4 mb-2">Car Rentals</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Car Type</TableCell>
                  <TableCell>Pickup Date</TableCell>
                  <TableCell>Return Date</TableCell>
                  <TableCell>Pickup Location</TableCell>
                  <TableCell>Return Location</TableCell>
                  <TableCell>Daily Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.carRentals.map((rental) => (
                  <TableRow key={rental.id}>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={rental.carType}
                        onChange={(e) => handleCarRentalChange(rental.id, 'carType', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickermui
                        date={rental.pickupDate}
                        handleDateChange={(date) => handleCarRentalChange(rental.id, 'pickupDate', date)}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickermui
                        date={rental.returnDate}
                        handleDateChange={(date) => handleCarRentalChange(rental.id, 'returnDate', date)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={rental.pickupLocation}
                        onChange={(e) => handleCarRentalChange(rental.id, 'pickupLocation', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={rental.returnLocation}
                        onChange={(e) => handleCarRentalChange(rental.id, 'returnLocation', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={rental.dailyRate}
                        onChange={(e) => handleCarRentalChange(rental.id, 'dailyRate', Number(e.target.value))}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {formData.invoiceDetails.productType === 'hotel' && (
        <>
          <Typography variant="h6" className="mt-4 mb-2">Hotel Bookings</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Hotel Name</TableCell>
                  <TableCell>Check-in Date</TableCell>
                  <TableCell>Check-out Date</TableCell>
                  <TableCell>Room Type</TableCell>
                  <TableCell>Number of Guests</TableCell>
                  <TableCell>Price per Night</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.hotelBookings.map((booking) => (
                  <TableRow key={booking.id}>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={booking.hotelName}
                        onChange={(e) => handleHotelBookingChange(booking.id, 'hotelName', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickermui
                        date={booking.checkInDate}
                        handleDateChange={(date) => handleHotelBookingChange(booking.id, 'checkInDate', date)}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePickermui
                        date={booking.checkOutDate}
                        handleDateChange={(date) => handleHotelBookingChange(booking.id, 'checkOutDate', date)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={booking.roomType}
                        onChange={(e) => handleHotelBookingChange(booking.id, 'roomType', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={booking.numberOfGuests}
                        onChange={(e) => handleHotelBookingChange(booking.id, 'numberOfGuests', Number(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={booking.pricePerNight}
                        onChange={(e) => handleHotelBookingChange(booking.id, 'pricePerNight', Number(e.target.value))}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {formData.invoiceDetails.productType === 'generic' && (
        <>
          <Typography variant="h6" className="mt-4 mb-2">Generic Products</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.genericProducts.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={product.name}
                        onChange={(e) => handleGenericProductChange(product.id, 'name', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={product.quantity}
                        onChange={(e) => handleGenericProductChange(product.id, 'quantity', Number(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        value={product.description}
                        onChange={(e) => handleGenericProductChange(product.id, 'description', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        type="number"
                        value={product.price}
                        onChange={(e) => handleGenericProductChange(product.id, 'price', Number(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      ${(product.quantity * product.price).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Typography variant="h6" className="mt-4">
        Total Amount: ${totalAmount.toFixed(2)}
      </Typography>
    </Paper>
  );
};

export default InvoiceDraftSummary;

