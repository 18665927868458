import {
  AirportSearchAutocompleteResult,
  CabinClass,
  MultiCitySearchModel,
  SearchPayload,
  SearchTab,
} from "api/types";
import { TravelersInputValue } from "components/common/HeroSearchForm/TravelersInput";
import { DateRange } from "components/common/HeroSearchForm/HotelSearchForm";
import moment from "moment";
import ValidationService from "./ValidationService";
import {FlightSearchType} from "../components/common/SearchEngines/FlightSearch/FlightSearch";

class FlightQueryParametersService {
  static encode(
    flightSearchType: FlightSearchType,
    travelers: TravelersInputValue,
    cabinClass: CabinClass,
    departureAirport: AirportSearchAutocompleteResult | null,
    arrivalAirport: AirportSearchAutocompleteResult | null,
    multiCitySearch: MultiCitySearchModel[] | null,
    roundTripDateRange: DateRange,
    departureFirstTrip: moment.Moment | null,
    withBaggageOnly?: boolean,
    showDirectFlights?: boolean,
    excludeGdsFlights?: boolean,
  ): string | null {
    const common = {
      showDirectFlights: String(showDirectFlights),
      withBaggageOnly: String(withBaggageOnly),
      excludeGdsFlights: String(excludeGdsFlights),
      searchType: "Flights",
      flightSearchType,
      cabinClass,
      adults: String(travelers.adults),
      children: String(travelers.children),
      infants: String(travelers.infants),
    };
    if (flightSearchType === "Round-Trip") {
      if (
        !departureAirport ||
        !arrivalAirport ||
        !roundTripDateRange.startDate ||
        !roundTripDateRange.endDate
      ) {
        return null;
      }

      return new URLSearchParams({
        departureAirportId: departureAirport.id,
        departureAirportText: departureAirport.text,
        arrivalAirportId: arrivalAirport.id,
        arrivalAirportText: arrivalAirport.text,
        departureDate: roundTripDateRange.startDate!.format("YYYY-MM-DD"),
        returningDate: roundTripDateRange.endDate!.format("YYYY-MM-DD"),
        
        ...common,
      }).toString();
    } else if (flightSearchType === "Oneway") {
      if (!departureAirport || !arrivalAirport || !departureFirstTrip) {
        return null;
      }

      return new URLSearchParams({
        departureAirportId: departureAirport.id,
        departureAirportText: departureAirport.text,
        arrivalAirportId: arrivalAirport.id,
        arrivalAirportText: arrivalAirport.text,
        departureFirstTrip: departureFirstTrip!.format("YYYY-MM-DD"),
        ...common,
      }).toString();
    } else if (flightSearchType === "Multi-City") {
      if (
        !multiCitySearch
      ) {
        return null;
      }

      return new URLSearchParams({
        multiCitySearch: JSON.stringify(multiCitySearch),
        ...common,
      }).toString();
    }

    return null;
  }

  static decode(urlParams: URLSearchParams): SearchPayload | null {
    let adtCount = urlParams.get("adults") || null;
    let chdCount = urlParams.get("children") || null;
    let infCount = urlParams.get("infants") || null;
    let flightSearchType =
      (urlParams.get("flightSearchType") as FlightSearchType) || null;
    let cabinClass = urlParams.get("cabinClass") || null;
    let departureAirportId = urlParams.get("departureAirportId") || null;
    let departureAirportText = urlParams.get("departureAirportText") || null;
    let arrivalAirportId = urlParams.get("arrivalAirportId") || null;
    let arrivalAirportText = urlParams.get("arrivalAirportText") || null;
    let multiCitySearch = urlParams.get("multiCitySearch") || null;
    let departureDate = urlParams.get("departureDate") || null;
    let returningDate = urlParams.get("returningDate") || null;
    let departureFirstTrip = urlParams.get("departureFirstTrip") || null;
    let departureSecondTrip = urlParams.get("departureSecondTrip") || null;
    let withBaggageOnlyParam = urlParams.get("withBaggageOnly");
    let withBaggageOnly = withBaggageOnlyParam !== null ? withBaggageOnlyParam === 'true' : false;
    let showDirectFlightsParam = urlParams.get("showDirectFlights");
    let showDirectFlights = showDirectFlightsParam !== null ? showDirectFlightsParam === 'true' : false;
    let excludeGdsFlightsParams = urlParams.get("excludeGdsFlights");
    let excludeGdsFlights =  excludeGdsFlightsParams !== null ? excludeGdsFlightsParams === 'true' : false;

    departureDate = ValidationService.date(departureDate)
      ? departureDate
      : null;
    returningDate = ValidationService.date(returningDate)
      ? returningDate
      : null;

    departureFirstTrip = ValidationService.date(departureFirstTrip)
      ? departureFirstTrip
      : null;

    departureSecondTrip = ValidationService.date(departureSecondTrip)
      ? departureSecondTrip
      : null;

    if (
      (!departureAirportId &&
        !departureAirportText &&
        !arrivalAirportId &&
        !arrivalAirportText &&
        !multiCitySearch) ||
      adtCount === null ||
      chdCount === null ||
      infCount === null ||
      !flightSearchType ||
      !cabinClass
    ) {
      return null;
    }

    const common = {
      searchType: "Flights" as SearchTab,
      adtCount: Number(adtCount),
      chdCount: Number(chdCount),
      infCount: Number(infCount),
      cabinClass: cabinClass as CabinClass,
      cheapestBrandsOnly: Boolean(withBaggageOnly ? false : true),
      withBaggageOnly: Boolean(withBaggageOnly),
      showDirectFlights:Boolean(showDirectFlights),
      excludeGdsFlights:Boolean(excludeGdsFlights)
    };

    if (flightSearchType === "Round-Trip") {
      if (!departureDate || !returningDate) return null;

      return {
        ...common,
        flightSearchType: "Round-Trip",
        originDestinationInformation: [
          {
            date: departureDate,
            origin: departureAirportId,
            originText: departureAirportText,
            destination: arrivalAirportId,
            destinationText: arrivalAirportText,
          },
          {
            date: returningDate,
            origin: arrivalAirportId,
            originText: arrivalAirportText,
            destination: departureAirportId,
            destinationText: departureAirportText,
          },

        ],
      };
    }

    if (flightSearchType === "Oneway") {
      if (!departureFirstTrip) return null;

      return {
        ...common,
        flightSearchType: "Oneway",
        originDestinationInformation: [
          {
            date: departureFirstTrip,
            origin: departureAirportId,
            originText: departureAirportText,
            destination: arrivalAirportId,
            destinationText: arrivalAirportText,
          },
        ],
      };
    }

    if (flightSearchType === "Multi-City") {
      if (
        !multiCitySearch
      )
        return null;

      let newMultiCitySearch = JSON.parse(multiCitySearch)

      return {
        ...common,
        flightSearchType: "Multi-City",
        originDestinationInformation: newMultiCitySearch.map((element: MultiCitySearchModel) => {
          return {
            date: element.departureDate,
            origin: element.departureAirport?.id,
            originText: element.departureAirport?.text,
            destination: element.arrivalAirport?.id,
            destinationText: element.arrivalAirport?.text,
          }
        })
      };
    }

    return null;
  }
}

export default FlightQueryParametersService;
