import "./ProgressBar.css";
import React from "react";
import { Progress } from "reactstrap";

interface ProgressBarProps {
  progress: number;
}
const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const percentage = Math.round(progress) + "%";

  return (
    <div className="relative w-full rounded bg-neutral-200 dark:bg-neutral-800">
      <div
        style={{ width: (progress || 5) + "%" }}
        className="shim-blue absolute top-0 h-4 rounded transition-all"
      > <div className="flex justify-center items-center  text-sm h-4">{percentage}</div>
      </div>
     
    </div>
  );
};

export default ProgressBar;
