import React, { useEffect, useState } from "react";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { RentalSearchAutocompleteResult} from "api/types";
import { getRentalLocationAutocompleteUrl } from "api/helpers/api-constants";
import { useTranslation } from "react-i18next";
import { MobileAutoComplete } from "components/common/SectionHeroArchivePage/MobileAutoComplete";
import { InputSwitch } from 'primereact/inputswitch';
import "./RacCustom.css"
import { MobileCustomCalendarForRac } from "components/common/CustomCalendar/MobileCustomCalendarForRac";
import { MobileCustomHourSelect } from "components/common/CustomCalendar/MobileCustomHourSelect";
import { MobileCustomAgeSelect } from "components/common/CustomCalendar/MobileCustomAgeSelect";


interface CarRentalSearchFormProps{

  otherReturnStation: boolean;
  setOtherReturnStation: React.Dispatch<React.SetStateAction<boolean>>;
  age: string | null;
  setAge: React.Dispatch<React.SetStateAction<string | null>>;
  startDate: moment.Moment | null;
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  endDate: moment.Moment | null;
  setEndDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  rentalLocation: RentalSearchAutocompleteResult | null;
  setRentalLocation: React.Dispatch<React.SetStateAction<RentalSearchAutocompleteResult | null>>;
  returnLocation: RentalSearchAutocompleteResult | null;
  setReturnLocation: React.Dispatch<React.SetStateAction<RentalSearchAutocompleteResult | null>>;
  rentalTime: string | null;
  setRentalTime: React.Dispatch<React.SetStateAction<string | null>>;
  returnTime: string | null;
  setReturnTime: React.Dispatch<React.SetStateAction<string | null>>;
  missingInputError: any | null;
  rentalTimeFocus: boolean;
  setRentalTimeFocus: React.Dispatch<React.SetStateAction<boolean>>;
  returnTimeFocus: boolean;
  setReturnTimeFocus: React.Dispatch<React.SetStateAction<boolean>>;
  dateFocus: boolean;
  setDateFocus: React.Dispatch<React.SetStateAction<boolean>>;
  returnDateFocus: boolean;
  setReturnDateFocus: React.Dispatch<React.SetStateAction<boolean>>;
  returnLocationFocus: boolean;
  setReturnLocationFocus: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (value: React.FormEvent) => void;
}

export const CarRentalSearchForm: React.FC<CarRentalSearchFormProps> = ({
    otherReturnStation,
    setOtherReturnStation,
    age,
    setAge,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rentalLocation,
    setRentalLocation,
    returnLocation,
    setReturnLocation,
    rentalTime,
    setRentalTime,
    returnTime,
    setReturnTime,
    handleSubmit,
  }) => {

  const { t } = useTranslation();
  const [ selectedDate, setSelectedDate ] = useState<[moment.Moment|null, moment.Moment|null]>([startDate, endDate])
useEffect(() => {
    setStartDate(selectedDate[0])
    setEndDate(selectedDate[1])
}, [selectedDate]);
  return (
    <div className={`bg-white px-3 pt-2 pb-3`}>
    <form
        onSubmit={handleSubmit}
        className="relative justify-center items-center">
        <div className="w-full">
          <div className="items-center py-1 mb-2 flex justify-between ">
            <span className="text-xs">{t("react.searchmask.otherlocation")}</span>
            <InputSwitch
            checked={otherReturnStation}
            onChange={() => setOtherReturnStation((prev) => !prev)}
            className={otherReturnStation ? 'atr-inputSwitch' : ''} />
          </div>
        </div>


          <MobileAutoComplete
            className={'rounded-t-lg bg-gray-200'}
            defaultValue={t("app.rentacar.pickup-location")}
            selectedDestination={ rentalLocation}
            setDestination={setRentalLocation}
            getUrl={getRentalLocationAutocompleteUrl}
            placeholder={t("react.filter.from")}
                            />
          {otherReturnStation && (
            <MobileAutoComplete
            className={'rounded-b-lg mt-0.5 bg-gray-200'}
            defaultValue={t("rentalcar.return-location")}
            selectedDestination={returnLocation}
            setDestination={setReturnLocation}
            getUrl={getRentalLocationAutocompleteUrl}
            placeholder={t("rentalcar.return-location")}
        />
          )}
          <div className="grid grid-cols-2 grid-rows-2 mt-1  gap-0.5">
            <MobileCustomCalendarForRac
             selectedDate={[startDate,endDate]}
             setSelectedDate={(value) => setSelectedDate(value)}
              type={'startDate'}/>
            <MobileCustomHourSelect
            rentalTime={rentalTime}
            setRentalTime={setRentalTime}
            returnTime={returnTime}
            setReturnTime={setReturnTime}
            type={"rentalTime"} />
             <MobileCustomCalendarForRac
            selectedDate={[startDate,endDate]}
            setSelectedDate={(value) => setSelectedDate(value)}
            type={'endDate'}/>
            <MobileCustomHourSelect
            rentalTime={rentalTime}
            setRentalTime={setRentalTime}
            returnTime={returnTime}
            setReturnTime={setReturnTime}
            type={"returnTime"} />

          </div>
           <MobileCustomAgeSelect
               age={age}
               setAge={setAge} />

          <div className="justify center ml-auto flex w-full items-center py-4 lg:w-min lg:py-0">
            <SubmitButton />
          </div>
      </form>
      </div>
  );
};

const ageSelectOptions = new Array(63).fill(null).map((_, i) => ({
    value: String(i + 18),
    label: String(i + 18) + " years old",
  }));

export default CarRentalSearchForm;
