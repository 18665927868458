import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { SpinnerDotted } from 'spinners-react';
import { fetcherWithoutJSON } from 'api/helpers/fetcher';
import { confirmBaggageSelection, getBaggageSsr,  } from 'api/helpers/api-constants';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';
import { disableButton } from 'utils/disableButton';
import { SsrSelectionProps } from './MealSelection';

import LuggageIcon from "@mui/icons-material/Luggage";

const BaggageSelection: React.FC<SsrSelectionProps> = ({ bookingKey, filekey, route, isOpen, setIsOpen }) => {    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<number | null>(null);
    const [flightId, setFlightId] = useState<number | null>(null);
    const [tripIndex, settripIndex] = useState<number>(0);
    const [isloading, setisloading] = useState<boolean>(true);
    const [baggageloading, setBaggageLoading] = useState<boolean>(false);
    const [selectedBaggage, setSelectedBaggage] = useState<string>("");
    const {t} = useTranslation()
    interface BaggageResponse {
      ssrList: string[];
    }

    const postSsr = async (
      key: string,
      filekey: string,
      selectedBaggage: string
    ): Promise<BaggageResponse> => {
      const url = confirmBaggageSelection();
      const params = new URLSearchParams({
        bookingKey: key,
        filekey,
        ssrKey: selectedBaggage,
      });

      try {
        const response : any = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",
        });

        if (!response.ok) {

          Swal.fire("Error Code: " + response.CorrelationId, "", "error");
        } else {
          Swal.fire("Success", "", "success");
        }

        const data = await response.json();
        return data as BaggageResponse;
      } catch (error) {
        Swal.fire("Error Code: unknown", "", "error");
        console.error(error);
        throw error;
      } finally {
        setBaggageLoading(false);
        setIsOpen(false);
        setConfirmbox(false);
      }
    };

    //post wheelchair api

    const [baggageData, setBaggageData] = useState<any>();

    const getssrData = async () => {
      setisloading(true)
      setSelectedBaggage("")
        if(flightId && paxId){
          let params = 
          (route.bookBaggageByTrip 
            ?  
             new URLSearchParams({
            bookingKey: bookingKey,
            filekey: filekey,
           // flightId: flightId.toString(),
            paxId: paxId.toString(),
            tripIndex: tripIndex?.toString(),
          }) 
          : 
          new URLSearchParams({
            bookingKey: bookingKey,
            filekey: filekey,
            flightId: flightId.toString(),
            paxId: paxId.toString(),
          
          }))
            await fetcherWithoutJSON(
                getBaggageSsr() +
                 params,
                {
                  method: "GET",
        
                  credentials: "include",
                  cache: "no-cache",
                }
              )
                .then((response) => {
                  if (response.status === 200) {
                    return response.json();
                  } else {
                    throw new Error("Something went wrong on api server!");
                  }
                })
                .then((data) => {
                  setBaggageData(data.ssrList);
                })
                .catch((error) => {
                  Swal.fire(t("ssr.errorfrom-airline"), "", "error");
                  console.log(error);
                })
                .finally(() => {
                  setisloading(false);
                });
        }

       
      };

   
    useEffect(() => {
      if (flightId && paxId && filekey) {
        getssrData();
      }
    }, [isgetData, paxId, flightId]);

    const baggagedata1: any = baggageData;
    if (isOpen === true) {
      window.scrollTo(0, 0);
    }

   
    const [confirmbox, setConfirmbox] = useState(false);
    return (
      <div>
       
            {isOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                <ButtonClose onClick={() =>{
            setIsOpen(false)
            setFlightId(null)
            setPaxId(null)}
              }></ButtonClose>

                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <LuggageIcon /> <h2 className="p-2">Baggage Selection</h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">
                          3- {t("react.flight.addbaggage")}
                        </h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("react.flight.addbaggage")}
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("react.flight.addbaggage")}
                        </h2>
                      )}
                    </div>

                    <div className="">
                      {
                        <div className="w-full  p-2">
                          <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                          <div className="w-2/6 p-2">
  <h3 className="font-bold">1- {t("flightbooking.flights")}</h3>
  {route.trips?.map((trip, tripIdx: number) => (
    <div key={tripIdx}>
      {route.bookBaggageByTrip ? (
        <button
        className={`w-full border-2 p-2 text-center hover:bg-gray-100 ${tripIdx === tripIndex ? "bg-gray-100" : ""}`}
          onClick={() => {
            setFlightId(trip.flights[0].id); 
            setPaxId(null);
            setBaggageData(null);
            settripIndex(tripIdx)
          }}
        >
          {trip.flights.map((flight) => (
            <div key={flight.id} className="text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                 {tripIndex === tripIdx && <i className="fa fa-check" aria-hidden="true"></i>}
                 <span className="flex items-center space-x-2">
                                              <img className='h-5 w-5' src={flight.airlineLogo} alt='airline'></img>
                                            <span className='font-bold'>{flight.flightNumber}</span>  
                                           
                                            </span>
                                            <span>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </span>
            </div>
          ))}
        </button>
      ) : (
        trip.flights.map((flight, index: number) => (
          <div key={flight.id}>
            <div
              className={`w-full border-2 p-2 text-center hover:bg-gray-100 ${flight.id === flightId ? "bg-gray-100" : ""}`}
              onClick={() => {
                setFlightId(flight.id);
                setPaxId(null);
                setBaggageData(null);
              }}
            >
              <div className="text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                {flightId === flight.id && <i className="fa fa-check" aria-hidden="true"></i>}
                <span className="flex items-center space-x-2">
                                              <img className='h-5 w-5' src={flight.airlineLogo} alt='airline'></img>
                                            <span className='font-bold'>{flight.flightNumber}</span>  
                                           
                                            </span>
                                            <span>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </span>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  ))}
</div>


                           
                                  <div className=" flex w-1/6 items-stretch justify-center p-2  ">
                                    {flightId && (
                                      <div>
                                        <h3 className=" font-bold">
                                          2- {t("flightbooking.passengers")}
                                        </h3>
                                       
                                            <div>
                                              {route.passengers.map((p) => (
                                                <div key={p.id}>
                                                  <button
                                                    key={p.id}
                                                    className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                                      p.id === paxId
                                                        ? "bg-gray-100"
                                                        : ""
                                                    }`}
                                                    onClick={() => {
                                                      setPaxId(p.id);
                                                      //setIsgetData(!isgetData);
                                                      {
                                                      }
                                                    }}
                                                  >
                                                    <div className="  text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                      {paxId === p.id ? (
                                                        <i
                                                          className="fa fa-check "
                                                          aria-hidden="true"
                                                        ></i>
                                                      ) : null}

                                                      <p className="">
                                                        {p.firstname}
                                                      </p>
                                                      <p className="font-bold">
                                                        {p.surname}
                                                      </p>
                                                    </div>
                                                  </button>
                                                </div>
                                              ))}
                                            </div>
                                     
                                      </div>
                                    )}
                                  </div>
                              
                            <div className=" w-4/6 p-2 ">
                            { baggagedata1 ?  baggagedata1.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}

                              {paxId &&
                              flightId &&
                              // data1.ssrRecords.find(
                              //   (rcr: any) => rcr.recordType === "BAGGAGE"
                              // )?.paxId === paxId &&
                              // data1.ssrRecords.find(
                              //   (rcr: any) => rcr.recordType === "BAGGAGE"
                              // )?.flightId === flightId ? (
                              //   <span>{t("ssr.already.selected")}</span>
                              // ) :
                              (
                                baggagedata1 &&
                                paxId &&
                                flightId &&
                                !isloading && (
                                  <div className="relative flex justify-center p-2 ">
                                    <div className="relative">
                                      <select
                                        className="focus:shadow-outline w-full appearance-none rounded border bg-white py-2 pl-3 pr-8 leading-tight text-gray-700 focus:outline-none"
                                        value={selectedBaggage}
                                        onChange={(e) =>
                                          setSelectedBaggage(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          {t("react.flight.addbaggage")}
                                        </option>
                                        {!baggagedata1 ? (
                                          <div>
                                            Havayolu kaynaklı hata alıyoruz
                                          </div>
                                        ) : (
                                          baggagedata1.map((meal: any) => (
                                            <option
                                              key={meal.ssrKey}
                                              value={meal.ssrKey}
                                            >
                                              {meal.name.includes("KG") ? meal.name : meal.name + " KG"} - {meal.price}{" "}
                                              {meal.currencyCode}
                                            </option>
                                          ))
                                        )}
                                      </select>
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                        <svg
                                          className="h-4 w-4 fill-current text-gray-500"
                                          viewBox="0 0 20 20"
                                        ></svg>
                                      </div>
                                      <div className=" rounded border shadow-md">
                                        {selectedBaggage && (
                                          <div className="p-8">
                                            <div className="border-1 text-md overflow-hidden text-center text-gray-900 ">
                                              <p className="font-bold">
                                                {

                                                  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.name.includes("KG")

                                                ?  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.name
                                                  : baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.name + " KG"
                                                }
                                              </p>
                                              <p className="">
                                                {
                                                  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.price
                                                }
                                                {"  "}
                                                {
                                                  baggagedata1.find(
                                                    (meal: any) =>
                                                      meal.ssrKey ===
                                                      selectedBaggage
                                                  )?.currencyCode
                                                }
                                              </p>
                                            </div>
                                            {confirmbox && (
                                              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                                                <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                                                  <div className="flex w-full justify-between p-2 ">
                                                    <ButtonClose
                                                      onClick={() =>
                                                        setConfirmbox(false)
                                                      }
                                                    ></ButtonClose>
                                                    {baggageloading && (
                                                      <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                                                    )}
                                                  </div>
                                                  <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                                                    <p className="font-bold">
                                                    {

                                                 baggagedata1.find(
                                                   (meal: any) =>
                                                     meal.ssrKey ===
                                                     selectedBaggage
                                                 )?.name.includes("KG")

                                               ?  baggagedata1.find(
                                                   (meal: any) =>
                                                     meal.ssrKey ===
                                                     selectedBaggage
                                                 )?.name
                                                 : baggagedata1.find(
                                                   (meal: any) =>
                                                     meal.ssrKey ===
                                                     selectedBaggage
                                                 )?.name + " KG"
                                               }
                                                    </p>
                                                    <p className="">
                                                      {
                                                        baggagedata1.find(
                                                          (meal: any) =>
                                                            meal.ssrKey ===
                                                            selectedBaggage
                                                        )?.price
                                                      }
                                                      {
                                                        baggagedata1.find(
                                                          (meal: any) =>
                                                            meal.ssrKey ===
                                                            selectedBaggage
                                                        )?.currencyCode
                                                      }{" "}
                                                    </p>
                                                  </div>
                                                  <div className="p-2">
                                                    <p className=" text-red-600">
                                                      {t(
                                                        "generals.attention-this-action-is-not-reversible"
                                                      )}
                                                    </p>
                                                    <p></p>
                                                    <p>
                                                      {" "}
                                                      {t(
                                                        "generals.are-you-sure-you-want-to-proceed"
                                                      )}
                                                    </p>
                                                    <div
                                                      className="inline-flex  p-2 "
                                                      role="group"
                                                    >
                                                      <button
                                                        type="button"
                                                        className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500 "
                                                        onClick={() => {
                                                          setConfirmbox(false);
                                                        }}
                                                      >
                                                        {t(
                                                          "b2c-app.generals.cancel"
                                                        )}
                                                      </button>
                                                      <button
                                                        id="confirmBaggage"
                                                        type="button"
                                                        className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400 "
                                                        onClick={() => {
                                                          const button = document.getElementById("confirmBaggage") as HTMLButtonElement | null;
                                                          if (button) {
                                                            disableButton([button]);
                                                         }
                                                          postSsr(
                                                            bookingKey,
                                                            filekey,
                                                            selectedBaggage
                                                          ); //post ssr func will come
                                                          setBaggageLoading(
                                                            true
                                                          );
                                                        }}
                                                        disabled={baggageloading}
                                                      >
                                                        {t("generals.yes")}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {!selectedBaggage ? (
                                              <div>
                                                {t("react.flight.addbaggage")}
                                              </div>
                                            ) : (
                                              <div
                                                className="inline-flex items-center justify-center  p-2 "
                                                role="group"
                                              >
                                                <button
                                                  type="button"
                                                  className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500 "
                                                  onClick={() => {
                                                    //setIsExpanded(false);
                                                    setPaxId(null);
                                                    setFlightId(null);
                                                    setIsOpen(false);
                                                    //setSelectedMeal("");
                                                    setSelectedBaggage("");
                                                  }}
                                                >
                                                  {t("b2c-app.generals.cancel")}
                                                </button>
                                                <button
                                                  type="button"
                                                  className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400 "
                                                  onClick={() => {
                                                    //  setIsExpanded(false);
                                                    setConfirmbox(true);
                                                  }}
                                                >
                                                  {t("generals.add")}
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
          
            )}
      </div>
    );
  };
  export default BaggageSelection