export const getStaticUrl = (env: string): string => {
    switch (env) {
        case 'b2c':
        return 'https://www.atrtouristik.com'; 
      case 'agencyb2c':
        return 'https://agency.atrtouristik.com';
      case 'development':
        return 'https://dev.atrtouristik.com';
      case 'b2b':
        return 'https://www.atrtouristik.com';
      default:
        throw new Error(`Unknown environment: ${env}`);
    }
  };

  // usage:  const apiUrl = getStaticUrl(process.env.REACT_APP_ENV || 'development'); 