import React, {FC} from "react"
import Select from "../../ui/Select/Select";
import {TFlightType} from "../../../api/types";
import {useTranslation} from "react-i18next";

interface IMobileFlightTypeSelection {
    className?: string
    flightType: TFlightType
    setFlightType: (value: TFlightType) => void
}

export const MobileFlightTypeSelection: FC<IMobileFlightTypeSelection> = (
    {
        className = '',
        flightType,
        setFlightType
    }) => {

    const { t } = useTranslation()

    const handleFlightTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFlightType(e.target.value as TFlightType);
    }

    const flightTypeSelectOptions: {value: TFlightType, label: string}[] = [
        { value: "Round-Trip", label: t("flights.roundtrip") },
        { value: "Oneway", label: t("flights.oneway") },
        { value: "Multi-City", label: t("react.searchmask.flight.multiflight") },
    ]

    return (
        <Select
            className={`${className} lg:w-40`}
            name="flight-type"
            id="flight-type"
            sizeClass={'text-l'}
            onChange={handleFlightTypeChange}
            value={flightType}
        >
            {flightTypeSelectOptions.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    )
}
