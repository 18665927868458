import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {getBookingStatus} from "../../../utils/bookingStatus";
import {copyHandler} from "../../../utils/copyHandler";
import {IProvider, IRentalCarReservationDetails} from "../../../api/types";
import {extractTime, formatDate} from "../../../utils/formatDate";
import {Tag} from "primereact/tag";
import currencyFormat from "../../../utils/currencyFormat";

interface IVehicleInformation {
    rentalcarInformation: IRentalCarReservationDetails
    statusColorCode: string
    status: string
    supplierReservationNumber: string|null
}

export const VehicleInformation: FC<IVehicleInformation> = ({
                                                                rentalcarInformation,
                                                                statusColorCode,
                                                                status,
                                                                supplierReservationNumber
}) => {
    const { t } = useTranslation()

    const [ provider, setProvider ] = useState<IProvider|null>(null)

    useEffect(() => {
        setProvider(JSON.parse(rentalcarInformation.provider) as IProvider)
    }, [rentalcarInformation])

    return (
        <div className={`my-3 mx-2 mb-3 px-3 pb-3 pt-3 shadow-md bg-white rounded`}>
            <div className={`flex justify-between items-start`}>
                <div className={`text-xl font-semibold pb-3`}>{t('rentalcar.rentalcar')}</div>
                <div className={`flex gap-x-2 justify-end text-right`}>
                    {rentalcarInformation.rentalCarGroup && (
                        <Tag>{rentalcarInformation.rentalCarGroup}</Tag>
                    )}
                    {rentalcarInformation.depositPaymentType === "NO_DEPOSIT" && (
                        <Tag>{t('rentalcar.no-deposit')}</Tag>
                    )}
                </div>
            </div>
            <div className={`flex justify-between cursor-pointer pb-3`}>
                <div className={`text-l px-4 py-0.5 rounded text-center`} style={{backgroundColor: '#'+statusColorCode}}>{t(getBookingStatus(status))}</div>
                {supplierReservationNumber && (
                    <div onClick={() => copyHandler(supplierReservationNumber)} className={` flex gap-x-2 text-l px-4 py-0.5 rounded text-center bg-gray-200 border border-gray-600`}>
                        <div><i className="pi pi-copy"></i></div>
                        <div>{supplierReservationNumber}</div>
                    </div>
                )}
            </div>
            {
                provider && (
                    <>
                        <div className={`flex justify-start items-end gap-x-1`}>
                            <div className={`text-xl font-semibold`}>{rentalcarInformation.rentalCarBrand} {rentalcarInformation.rentalCarType}</div>
                            <div className={`text-l`}>{t('rentalcar.or-similar')}</div>
                        </div>
                        <div className={`py-3`}>
                            <img
                                src={provider.logo!}
                                className="w-24"
                                alt={provider.displayName}
                            />
                        </div>
                        <div className={`pt-2 pb-3`}>
                            <img
                                src={rentalcarInformation.rentalCarImageUrl}
                                alt={rentalcarInformation.rentalCarBrand}
                            />
                        </div>
                        <div className={`mt-3`}>
                            <div className={`text-md font-semibold`}>{t('rentalcar.pickup-location')}:</div>
                            <div className={`text-md`}>{rentalcarInformation.pickupLocation}</div>
                            <div className={`flex gap-x-1`}>
                                <div className={`text-md`}>{formatDate(rentalcarInformation.pickupDate.split('T')[0])}</div>
                                &#x2022;
                                <div className={`text-md`}>{extractTime(rentalcarInformation.pickupDate)}</div>
                            </div>
                        </div>
                        <div className={`mt-2.5`}>
                            <div className={`text-md font-semibold`}>{t('react.rentalcar.returnlocation')}:</div>
                            <div className={`text-md`}>{rentalcarInformation.returnLocation}</div>
                            <div className={`flex gap-x-1`}>
                                <div className={`text-md`}>{formatDate(rentalcarInformation.returnDate.split('T')[0])}</div>
                                &#x2022;
                                <div className={`text-md`}>{extractTime(rentalcarInformation.returnDate)}</div>
                            </div>
                        </div>
                        <div className={`mt-3 flex gap-x-1`}>
                            <div className={`text-md font-semibold`}>{t('rentalcar.rental-period')}:</div>
                            <div className={`text-md`}>{rentalcarInformation.totalDays} {t('rentalcar.days')}</div>
                        </div>
                        <div className={`mt-3`}>
                            <div className={`flex justify-between text-l font-semibold`}>
                                <div>{t('react.hotel.total-price')}:</div>
                                <div>{currencyFormat(rentalcarInformation.totalAmount)}</div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
