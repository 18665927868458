import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  divContainerClass?: string;
    className?: string;
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      divContainerClass = "",
      className = "",
      sizeClass = "h-8 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded",
      children,
      type = "text",
      error = "",
      ...args
    },
    ref
  ) => {
    return (
      <div className={divContainerClass}>
        <input
          ref={ref}
          type={type}
          className={`block w-full border-neutral-200 bg-white focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 ${rounded} ${fontClass} ${sizeClass} ${className} ${
            error
              ? "dark:focus:ring-red-6000 border-red-200 focus:border-red-300 focus:ring-red-200 focus:ring-opacity-50 dark:border-red-700 dark:bg-red-900"
              : ""
          }`}
          {...args}
        />
        {error && (
          <small className="text-xs text-red-400 dark:text-red-200">
            {error}
          </small>
        )}
      </div>
    );
  }
);

export default Input;
