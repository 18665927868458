import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ReloadPopupProps {
  showPopup: boolean;
  onClose: () => void;
}

const ReloadPopup: React.FC<ReloadPopupProps> = ({ showPopup, onClose }) => {
  const {t} = useTranslation()
  const handleReload = () => {
    window.location.reload();
    onClose()
    };
console.log(showPopup)
  return (
    <Dialog open={showPopup} onClose={onClose}>
      <DialogTitle>{t("generals.jupiterisupdated")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
         {t("generals.reloadpage")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReload}>{t("generals.reload")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReloadPopup;
