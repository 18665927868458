import { SearchPayload } from "api/types";
import RentalCarQueryParametersService from "services/RentalCarQueryParametersService";
import create from "zustand";

interface RentalCarPageStore {
  setSearchPayload: (parameteres: URLSearchParams | null) => void;
  searchPayload: SearchPayload | null;
}

const useRentalCarPageStore = create<RentalCarPageStore>((set) => ({
  setSearchPayload: (paremeteres) => {
    set({
      searchPayload: RentalCarQueryParametersService.decode(
        paremeteres as URLSearchParams
      ),
    });
  },
  searchPayload: null,
}));

export default useRentalCarPageStore;
