import React, {FC, Fragment, useEffect, useRef, useState} from "react";
import { Dialog, Transition } from '@headlessui/react'
import {AtrDateFormat} from "../../../oldFlights/components/calendar";
import { BiArrowBack } from "react-icons/bi";
import {checkIsAdmin, checkIsEmployee} from "../../../oldFlights/actions";
import fetcher from "../../../api/helpers/fetcher";
import {getNotificationSaveMsgUrl, getResolveNotificationCaseUrl} from "../../../api/helpers/api-constants";
import {INotificationModalScroll, IStatusResponse, IToastAlert} from "../../../api/types";
import {ToastAlert} from "../Alert/ToastAlert";
import postFetcher, { postParamRequest } from "../../../api/helpers/postFetcher";
import {useTranslation} from "react-i18next";
import {useReduxReducer} from "../../../redux/store";
import { Button, LinearProgress } from "@mui/material";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useNavigate } from "react-router-dom";

interface INotifications {
    notifications: INotificationModalScroll[],
    open: boolean,
    notResolvedNotificationIds?: number[],
    emptyNotResolvedNotificationIds?: () => void,
    openLastCreatedCase?: boolean,
    closeModal: () => void,
    closeOpenLastCreatedCase?: () => void
    isLoading?: boolean, 
}

const NotificationModalScroll: FC<INotifications> = (
    {
        notifications,
        open,
        notResolvedNotificationIds= [],
        emptyNotResolvedNotificationIds,
        openLastCreatedCase = false,
        closeModal,
        closeOpenLastCreatedCase,
        isLoading,
    }
    ) => {
    const [selectedNotify, setSelectedNotify] = useState<INotificationModalScroll|null>(null);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertInfo, setAlertInfo] = useState<IToastAlert>();
    const navigate = useNavigate();
    const closeButtonRef = useRef(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const {t} = useTranslation();
    const { isApp, isMobile } = useReduxReducer(state => state.general)

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    }, [showAlert])

    useEffect(() => {
        if (openLastCreatedCase) {
            setSelectedNotify(notifications[notifications.length-1]);
        }
        else
            setSelectedNotify(null);
    }, [openLastCreatedCase])

    useEffect(() => {
        if (notResolvedNotificationIds!.length === 1) {
            let notResolvedNotification: INotificationModalScroll|undefined = notifications.find(notification => notification.id === notResolvedNotificationIds[0]);
            setSelectedNotify(notResolvedNotification!);
        }
    }, [notResolvedNotificationIds])

    const selectNotificationHandler = (notification: INotificationModalScroll|null) => {
        setSelectedNotify(notification)
        closeOpenLastCreatedCaseHandler()
        emptyNotResolvedNotificationIdsHandler()
    }

    const closeOpenLastCreatedCaseHandler = () => {
        if (closeOpenLastCreatedCase !== undefined && openLastCreatedCase)
            closeOpenLastCreatedCase()
    }

    const emptyNotResolvedNotificationIdsHandler = () => {
        if (emptyNotResolvedNotificationIds !== undefined && notResolvedNotificationIds.length > 0)
            emptyNotResolvedNotificationIds()
    }

    const atrOnlyClickHandler = async () => {
        const response: IStatusResponse = await fetcher(getResolveNotificationCaseUrl());
        let status: "success"|"error" = response.status === "success" ? "success" : "error";
        let msg: string = response.status === "success" ? "Fall erfolgreich gelöst" : "Fall konnte leider nicht erfolgreich beendet werden";
        setAlertInfo({type: status, msg: msg});
        setSelectedNotify(null);
        setShowAlert(true);
    }

    const sendClickHandler = async () => {
        const message: string|null = (textAreaRef.current !== null && textAreaRef.current.value.length > 0) ? textAreaRef.current.value : null;

        let status: "success"|"error";
        let msg: string;

        if (message !== null) {
            const response: IStatusResponse = await postParamRequest(getNotificationSaveMsgUrl(), {id: selectedNotify?.id, msg: message});
            status = response.status === "success" ? "success" : "error";
            msg = response.status === "success" ? "Fall erfolgreich gelöst" : "Fall konnte leider nicht erfolgreich beendet werden";
            setSelectedNotify(null);
        } else {
            status = "error";
            msg = "Bitte geben Sie eine Textnachricht ein!";
        }

        setAlertInfo({type: status, msg: msg});
        setShowAlert(true);
    }

    const listView = () => {
        return (
            <>
                {listViewHeader()}
                {notifications.length > 0 ? listViewContent() : isLoading ?   <LinearProgress/> : noEntryFoundContent()}
                {listViewBottom()}
            </>
        )
    }

    const listViewHeader = () => {
        return (
            <div className="bg-gray-50 px-4 py-3 sm:px-6 border-b text-center">
                <h3 className="text-xl font-medium text-red-500 pt-2">
                    {t("navbar.notifications")}
                </h3>
            </div>
        )
    }

    const listViewContent = () => {
        return (
            <div className="max-h-96 mx-auto px-6 overflow-y-scroll text-left">
                {notifications.map((notification, index) => {
                    return (
                        <div key={index} className={`my-1 flex ${!notification.status.includes('CASE_RESOLVED') ? 'text-red-500' : 'text-sky-500'}`}>
                            <div className="text-lg font-medium text-gray-500">{index+1}:&nbsp;&nbsp;</div>
                            <div
                                key={notification.id}
                                onClick={() => selectNotificationHandler(notification)}
                                className="text-lg font-medium cursor-pointer"
                            >
                                {`${AtrDateFormat(new Date(notification.date))} ${notification.title}`}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const noEntryFoundContent = () => {
        return (
            <div className="max-h-96 mx-auto px-6  overflow-y-scroll text-center">
            <div>{t('generals.no-entry-found')}</div>  
            </div>
        )
    }

    const listViewBottom = () => {
        return (
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border-t border-gray-300">
                {closeButton()}
            </div>
        )
    }

    const closeButton = () => {
        return (
            <button
                ref={closeButtonRef}
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-400 hover:text-white sm:ml-3 sm:w-auto"
                onClick={() => {
                    selectNotificationHandler(null);
                    closeModal();
                }}
            >
                {t("generals.close")}
            </button>
        )
    }

    const selectedView = () => {
        return (
            <>
                {selectedHeader()}
                {selectedContent()}
                {selectedBottom()}
            </>
        )
    }
    const navigateToDetailPage = () => {
        navigate(`/booking/detail/${selectedNotify?.bookingKey}`)
        closeModal()
    }
    const selectedHeader = () => {
        return (
            <div className="grid grid-rows-1 grid-flow-col gap-4 border-b">
                <div className="bg-gray-50 p-4 sm:px-6 text-left items-center">
                    <h3 className="text-2xl font-bold text-gray-500 cursor-pointer">
                        <BiArrowBack onClick={() => {selectNotificationHandler(null)}} />
                    </h3>
                </div>
                <div className="bg-gray-50 p-4 sm:px-6 text-center">
                    <h3 className="text-lg font-medium text-red-500">
                        {selectedNotify?.title}
                    </h3>
                </div>
                <div className="p-4 sm:px-6 text-right">
                    <button
                        onClick={() =>navigateToDetailPage()} 
                        className="bg-primary-6000 rounded p-2 text-white text-lg font-medium">
                        {selectedNotify?.bookingKey}
                        <ArrowOutwardIcon/>
                    </button>
                    
                </div>
            </div>
        )
    }

    const selectedContent = () => {
        const text: string = selectedNotify !== null ? selectedNotify.correspondence : "<h3>Keine Benachrichtigung</h3>";

        return (
            <>
                <div className="px-4 py-3 bg-gray-200">
                    <div dangerouslySetInnerHTML={{ __html:  text}} />
                </div>
                <div className="grid grid-rows-1 grid-flow-col gap-4">
                    <div>
                        <h3 className="justify-center p-4 text-lg font-medium text-red-500">
                           {t("navbar.notification.informthecustomer")}
                        </h3>
                    </div>
                    <div className="bg-white p-4 sm:flex sm:flex-row-reverse sm:px-6 border-gray-300">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-medium ring-1 ring-gray-400 bg-white text-gray-400 hover:text-gray-600 sm:ml-3 sm:w-auto"
                        >
                            {t("navbar.notification.customerconfirmed")}
                        </button>
                    </div>
                </div>
                <div className="px-4 pt-1 pb-4">
                    <textarea
                        id="message"
                        ref={textAreaRef}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder='Beispiel: "Der Kunde stimmt zu, bitte bestätigen Sie den Vorschlag."' />
                </div>
            </>
        )
    }

    const selectedBottom = () => {
        return (
            <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 border-t border-gray-300">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
                    onClick={sendClickHandler}
                >
                    {t("generals.submit")}
                </button>
                {closeButton()}
                {
                   // checkIsAdmin() will change to redux isAdmin use reducer
                   (checkIsAdmin() || checkIsEmployee()) &&
                    (<button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 sm:ml-3 sm:w-auto"
                        onClick={atrOnlyClickHandler}
                    >
                        ATR Only: Case'i Kapat
                    </button>)
                }
            </div>
        )
    }

    return (
        <>
            {showAlert && (
                <ToastAlert type={alertInfo?.type} msg={alertInfo?.msg} />
            )}
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    initialFocus={closeButtonRef}
                    as="div"
                    className={`relative ${isApp || isMobile ? 'z-98' : 'z-40'}`}
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="relative transform  rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 z-40 overflow-scroll">
                                    {selectedNotify === null ? listView() : selectedView()}
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
)
}

export default NotificationModalScroll
