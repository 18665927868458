import useQueryParameters from "hooks/useQueryParameters";
import React, {FC, useEffect, useState} from "react";
import useSearchPageStore from "store/searchPageStore";
import FlightsAndHotelSearchForm from "./FlightsAndHotelSearchForm";
import {FlightSearch} from "./FlightsSearchForm";
import HotelSearchForm from "./HotelSearchForm";
import TransferSearchForm from "./TransferSearchForm";
import TourSearchForm from "./TourSearchForm";
import {IUserData, SearchTab} from "api/types";
import { SetTabSelections } from "redux/slices/tabsSlice";
import { RootSearchPageState, useReduxReducer } from "redux/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PackageTourSearchForm from "./PackageTourSearchForm";
import { getUserRole } from "api/helpers/checkUserRole";
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import CarRentalIcon from '@mui/icons-material/CarRental';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import TourIcon from '@mui/icons-material/Tour';
import FestivalIcon from '@mui/icons-material/Festival';
import CottageIcon from '@mui/icons-material/Cottage';
import { getUserDataUrl } from "api/helpers/api-constants";
import fetcher from "api/helpers/fetcher";
import VillaSearchForm from "./VillaSearchForm";
import { SearchTypeTabSection } from "./SearchTypeTabSection";
import CarRentalSearch from "./CarRentalSearchForm";
import { setIsAdmin } from "redux/slices/generalSlice";
import Button from "@mui/material/Button";
import { ReturnOldPage } from "utils/navigationLinks";
import { useNavigate } from "react-router-dom";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: SearchTab;
}
interface UserInfo {
  serviceFee: number;
  packageToursAllowed: boolean;
  airlineCreditCardAllowed: boolean;
  crispTokenId: string;
  rescanPnrAllowed: boolean;
  toursAllowed: boolean;
  emailSignature: string;
  agencyPhoneNumber: string;
  nickname: string;
  company: string;
  userRole: string;
  showPaxconnectButtons: boolean;
  email: string;
}
const HeroSearchForm: FC<HeroSearchFormProps> = ({
                                                   className = "",
                                                   currentTab = "Flights",
                                                   currentPage,
                                                 }) => {

  const { t } = useTranslation("common");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryParameters = useQueryParameters();

  const {userData} = useReduxReducer(state => state.general)
  const setSelectedTab = useSearchPageStore((state) => state.setActiveTab);
  const { isMobile, isApp } = useReduxReducer(state => state.general)
  const { product }= useReduxReducer(state => state.config)
  const selectedTabs = useReduxReducer((state: RootSearchPageState) => state.tabs);

 
  function getTabsArray(userData: IUserData) {
    const productMapping: Record<string, string> = {
      flight: "Flights",
      hotel: "HOTEL",
      villa: "VILLA",
      carRental: "Car Rental",
      transfer: "Transfer",
      tour: "Tour",
      packageTour :"PackageTour"
    };

    const tabs: string[] = [];

    // Use a loop to iterate over the product properties
    for (const [key, value] of Object.entries(product)) {
      if (typeof value === 'object' && value.enabled && productMapping[key]) {
        tabs.push(productMapping[key]);
      }
    }

  

    return tabs;
  }

  const userRole = getUserRole();
  const tabs =  getTabsArray(userData!);
  const translateTabs = (tab: SearchTab) => {
    switch (tab) {
      case "Flights":
        return t('react.searchmask.tab.flight')
      case "HOTEL":
        return t('react.searchmask.tab.hotel')
      case "Car Rental":
        return t('react.searchmask.tab.carrental')
      case "Transfer":
        return t('react.searchmask.tab.transfer')
      case "Tour":
        return t('react.searchmask.tab.tour2')
      case "PackageTour":
        return t('react.searchmask.tab.packagetour')
        case "VILLA":
          return t('react.searchmask.tab.villa')
      default:
        return null;
    }
  }

  const tabIcons = (tab: SearchTab) => {
    switch (tab) {
      case "Flights" :
        return <FlightIcon fontSize={"small"} />
      case "HOTEL":
      return <HotelIcon fontSize={"small"} />
      case "Car Rental":
        return <CarRentalIcon fontSize={"small"} />
      case"Transfer":
        return <TransferWithinAStationIcon fontSize={"small"} />
      case"Tour":
        return <TourIcon fontSize={"small"} />
      case "PackageTour":
        return <FestivalIcon fontSize={"small"} />
        case "VILLA":
          return <CottageIcon fontSize={"small"} />
      default:
        return null;
    }
  };


  useEffect(() => {
    if (queryParameters && queryParameters.searchType) {
      dispatch(SetTabSelections({ activeTab: queryParameters.searchType }))
      dispatch(SetTabSelections({ currentTab: queryParameters.searchType }))
    }
  }, [queryParameters])

  const getHome = () => {
  navigate("/")
 };
  const renderTab = () => {

    return (
      <ul className="hiddenScrollbar flex space-x-3 overflow-x-auto lg:space-x-3 xl:space-x-3 h-12 list-none ">
        {tabs.map((tab: any) => {
          const active = tab === selectedTabs.activeTab;

          return (
            <li
              onClick={() => { dispatch(SetTabSelections({ currentTab: tab, activeTab: tab })); setSelectedTab(tab); getHome() }} // selected tab will add
              className={`flex flex-shrink-0 rounded-t-lg cursor-pointer items-center text-lg font-medium px-3  ${active
                ? "  bg-white border-b-2 text-atrRed border-atrRed"
                : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400 "
                } `}
              key={tab}
            >

              {tabIcons(tab)}
              <span className={"pl-1"}>{translateTabs(tab)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    return (
      <div className=" animate-fadeIn" key={selectedTabs.activeTab}>
        {(() => {
          switch (selectedTabs.activeTab) {
            case "Hotel":
            case "HOTEL":
              return <HotelSearchForm queryParameters={queryParameters} />;
            case "Hotel & Flights":
              return <FlightsAndHotelSearchForm queryParameters={queryParameters} />;
            case "Flights":
              return <FlightSearch queryParameters={queryParameters} />;
            case "Car Rental":
              return <CarRentalSearch />;
            case "Transfer":
              return <TransferSearchForm />;
            case "Tour":
              return <TourSearchForm queryParameters={queryParameters} />;
            case "PackageTour":
              return <PackageTourSearchForm queryParameters={queryParameters} />;
            case "Villa":
            case "VILLA":
              return <VillaSearchForm queryParameters={queryParameters} />;
            default:
              return null;
          }
        })()}
      </div>
    );
  };
  
  

  return (
    <div
      className={`nc-HeroSearchForm w-full lg:py-0  ${className}`}
      data-nc-id="HeroSearchForm"
    >
      <div className="flex justify-center items-center lg:flex-row bg-white rounded-t-lg border-b  pb-0">
        {
          !isApp && (
          !isMobile ? (
              <>
                {renderTab()}
              </>
          )
              :
              <SearchTypeTabSection />)
        }
      </div>

      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
