import React, { useState } from 'react';
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal';
import AccessibleIcon from '@material-ui/icons/Accessible';
import LuggageIcon from "@mui/icons-material/Luggage";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { useTranslation } from 'react-i18next';
import { BookingRoute } from 'api/types';
import MealSelection from './components/MealSelection';
import { useModalStates } from 'hooks/useSsrModalStates';
import SeatSelection from './components/SeatSelection';
import BaggageSelection from './components/BaggageSelection';
import WheelChairSelection from './components/WheelChairSelection';
import CheckInSelection from './components/CheckInSelection';


type SsrSectionProps = {
  filekey: string;
  route: BookingRoute;
  bookingKey: string;
};

const SsrSection: React.FC<SsrSectionProps> = ({ filekey, route , bookingKey}) => {
  const {
    isMealOpen, setIsMealOpen,
    isSeatOpen, setIsSeatOpen,
    isBaggageOpen, setIsBaggageOpen,
    isWheelchairOpen, setIsWheelchairOpen,
    isCheckinOpen, setIsCheckinOpen
  } = useModalStates();
  const handleButtonClick = (action: () => void) => {
    action();
    window.scrollTo(0, 0);
  };

  const {t} = useTranslation()

  return (
    <div className="rounded bg-white">
      <div className="p-1">
        <div className=" py-2">
          <span className="text-base font-semibold ">{t("ssr.select")}</span>
        </div>
        {(route.status === "TICKETED" || route.status === "RESERVATION") && (
          <div className="grid grid-flow-col justify-center gap-4">
            {route.bookMealAvailable && ( 
              <div className="rounded border max-w-xs">
                <button
                  type="button"
                  onClick={() => handleButtonClick(() => setIsMealOpen(true))}
                  className="h-full w-full text-gray-900 hover:bg-gray-200"
                >
                  <div className="grid grid-cols-2 items-center">
                    <RestaurantIcon color="primary" className="text-lg min-h-full min-w-full" />
                    <img
                      className="h-16 object-contain"
                      src="https://cdn.sunexpress.com/wp-content/uploads/sites/6/2022/03/10140557/SXS-book-a-seat-494x320.jpg"
                      alt="Book a meal"
                    />
                  </div>
                </button>
              </div>
            )}
            {route.bookSeatAvailable && (
              <div className="rounded border max-w-xs">
                <button
                  type="button"
                  onClick={() => handleButtonClick(() => setIsSeatOpen(true))}
                  className="h-full w-full text-gray-900 hover:bg-gray-200"
                >
                  <div className="grid grid-cols-2 items-center">
                    <AirlineSeatReclineNormalIcon color="primary" className="text-lg min-h-full min-w-full" />
                    <img
                      className="h-16 object-contain"
                      src="https://cdn.sunexpress.com/wp-content/uploads/sites/3/2020/03/18181753/ibe-extras-seat-reservation_EN.jpg"
                      alt="Book a seat"
                    />
                  </div>
                </button>
              </div>
            )}
            {route.bookBaggageAvailable && (
              <div className="rounded border max-w-xs">
                <button
                  type="button"
                  onClick={() => handleButtonClick(() => setIsBaggageOpen(true))}
                  className="h-full w-full text-gray-900 hover:bg-gray-200"
                >
                  <div className="grid grid-cols-2 items-center">
                    <LuggageIcon color="primary" className="text-lg min-h-full min-w-full" />
                    <img
                      className="h-16 object-contain"
                      src="https://cdn.sunexpress.com/wp-content/uploads/sites/6/2022/03/10140605/SXS-ExtraLuggage-Newpics-494x320.jpg"
                      alt="Book baggage"
                    />
                  </div>
                </button>
              </div>
            )}
            <div className='rounded border max-w-xs'>
              <button
                type="button"
                onClick={() => handleButtonClick(() => setIsWheelchairOpen(true))}
                className="w-full h-full text-gray-900 hover:bg-gray-200"
              >
                <div className='grid grid-cols-2 items-center'>
                  <AccessibleIcon color="primary" className='min-w-full min-h-full text-lg' />
                  <img
                    className="h-16 object-contain"
                    src='https://cms.ibsplc.aero/opencms/export/system/modules/com.ibsplc.ibe/resources/XQ/IBE/img/extras-wheelchair.webp'
                    alt="Book a wheelchair"
                  />
                </div>
              </button>
            </div>
            {route.bookAirportCheckinAvailable && (
              <div className="rounded border max-w-xs">
                <button
                  type="button"
                  onClick={() => handleButtonClick(() => setIsCheckinOpen(true))}
                  className="h-full w-full text-gray-900 hover:bg-gray-200"
                >
                  <div className="grid grid-cols-2 items-center">
                    <AddTaskIcon color="primary" className="text-lg min-h-full min-w-full" />
                    <img
                      className="h-16 object-contain"
                      src="https://cms.ibsplc.aero/opencms/export/system/modules/com.ibsplc.ibe/resources/XQ/IBE/img/Check-in.png"
                      alt="Airport check-in"
                    />
                  </div>
                </button>
              </div>
            )}
          </div>
        )}
        {route.bookMealAvailable && <MealSelection route={route} bookingKey={bookingKey} filekey={route.filekey}  isOpen={isMealOpen} setIsOpen={setIsMealOpen}/>}
        {route.bookSeatAvailable && <SeatSelection route={route} bookingKey={bookingKey} filekey={route.filekey}  isOpen={isSeatOpen} setIsOpen={setIsSeatOpen}/>}
        {route.bookBaggageAvailable && <BaggageSelection route={route} bookingKey={bookingKey} filekey={route.filekey}  isOpen={isBaggageOpen} setIsOpen={setIsBaggageOpen}/>}
        {<WheelChairSelection route={route} bookingKey={bookingKey} filekey={route.filekey}  isOpen={isWheelchairOpen} setIsOpen={setIsWheelchairOpen}/>}
        {route.bookAirportCheckinAvailable && <CheckInSelection route={route} bookingKey={bookingKey} filekey={route.filekey}  isOpen={isCheckinOpen} setIsOpen={setIsCheckinOpen}/>}
      </div>
    </div>
  );
};

export default SsrSection;
