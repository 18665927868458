// disables a button element for a delay time 
// must use reserve, payment or any other approvement process
// sample call is
            //  <button
            //     id="reserve"
            //     onClick={() => {
            //       const button = document.getElementById("reserve") as HTMLButtonElement | null;
            //       if (button) {
            //         disableButton([button]);
            //       }
            //       handleSubmitForm();
            //     }}
            //   >
            //     button text
            //   </button>

export function disableButton(elements: (HTMLButtonElement | HTMLInputElement)[], delay = 5000) {
    elements.forEach(element => element.disabled = true);
  
    if (delay > 0) {
      setTimeout(() => elements.forEach(element => element.disabled = false), delay);
    }
  }
  