export default new Array(24)
  .fill(null)
  .reduce(
    (prev, _, index) => [
      ...prev,
      ...["00", "15", "30", "45"].map(
        (minute) => `${index <= 9 ? `0${index}` : index}:${minute}`
      ),
    ],
    []
  )
  .map((value: string) => ({ value, label: value }));
