
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useTranslation } from 'react-i18next';
import { SpinnerDotted } from 'spinners-react';
import { fetcherWithoutJSON } from 'api/helpers/fetcher';
import { confirmAirportCheckinSelection, confirmSeatSelection, getAirportCheckinSsr, getSeatmapUrl } from 'api/helpers/api-constants';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';
import { BookingFlight, BookingRoute } from 'api/types';
import { disableButton } from 'utils/disableButton';
import { SsrSelectionProps } from './MealSelection';
import postFetcher from 'api/helpers/postFetcher';
import { LinearProgress } from '@mui/material';
import AddTaskIcon from "@mui/icons-material/AddTask";

interface SsrResponse {
    ssrList: string[];
  }
  
  const CheckInSelection: React.FC<SsrSelectionProps> = ({ bookingKey, filekey, route, isOpen, setIsOpen }) => {

    const [isgetData, setIsgetData] = useState<boolean>(false);
    const [paxId, setPaxId] = useState<number | null>(null);
    const [flightId, setFlightId] = useState<number | null>(null);
    const [checkLoading, setCheckLoading] = useState<boolean>(false);
    const [selectedCheckin, setSelectedCheckin] = useState<string>("");
    const [flightData, setflightData] = useState<BookingFlight>();

    const {t} = useTranslation()
    interface CheckinResponse {
      ssrList: string[];
    }

    const postSsrCheckin = async (
      key: string,
      filekey: string,
      selectedCheckin: string
    ): Promise<CheckinResponse> => {
      const url = confirmAirportCheckinSelection();
      const params = new URLSearchParams({
        bookingKey: key,
        filekey,
        ssrKey: selectedCheckin,
      });

      try {
        const response = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",


        });

        if (!response.ok) {
          const correlationID: any = response.headers.get("correlation-id");
          Swal.fire("Error Code: " + correlationID, "", "error");
        } else {
          Swal.fire("Success", "", "success");
        }

        const data = await response.json();
        return data as CheckinResponse;
      } catch (error) {
        console.error(error);
        Swal.fire("Error Code: unknown", "", "error");

        throw error;
      } finally {
        setCheckLoading(false);
        setIsOpen(false);
      }
    };

    const [checkinData, setCheckinData] = useState<any>();
    const [isloading, setisloading] = useState(true);

    const getssrDataCheckin = async () => {
        if(flightId && paxId){
            await fetcherWithoutJSON(
                getAirportCheckinSsr() +
                  new URLSearchParams({
                    bookingKey: bookingKey,
                    filekey: filekey,
                    flightId: flightId.toString(),
                    paxId: paxId.toString(),
                  }),
                {
                  method: "GET",
        
                  credentials: "include",
                  cache: "no-cache",
                }
              )
                .then((response) => {
                  if (response.status === 200) {
                    return response.json();
                  } else {
                    throw new Error("Something went wrong on api server!");
                  }
                })
                .then((data) => {
                  setCheckinData(data.ssrList);
                })
                .catch((error) => {
                  Swal.fire(t("ssr.errorfrom-airline"), "", "error");
                  console.log(error);
                })
                .finally(() => {
                  setisloading(false);
                });
        }
      
    };
    useEffect(() => {
      if (flightId && paxId && filekey) {
        getssrDataCheckin();
      }
    }, [isgetData, paxId, flightId]);



    if (isOpen === true) {
      window.scrollTo(0, 0);
    }

    function showTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-0", "invisible");
        tooltip.classList.add("opacity-100");
      }
    }

    function hideTooltip(data: any) {
      const tooltip = document.getElementById(data);
      if (tooltip) {
        tooltip.classList.remove("opacity-100");
        tooltip.classList.add("opacity-0", "invisible");
      }
    }

    function handleClick(data: React.SetStateAction<string>) {
      setisActive(true);
      setisdisabled(true);
      setCheckinData(data);
    }

    const [isActive, setisActive] = useState(false);
    const [isDisabled, setisdisabled] = useState(false);
    const [confirmbox, setConfirmbox] = useState(false);

    return (
      <div>
        
            {isOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                <ButtonClose onClick={() =>{
            setIsOpen(false)
            setFlightId(null)
            setPaxId(null)}
              }></ButtonClose>
                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <AddTaskIcon /> <h2 className="p-2">Airport Check In</h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">3- Add Check In</h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-
                          Add Check In
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3- Add
                          Check In
                        </h2>
                      )}
                    </div>

                    <div className="">
                      {
                        <div className="w-full  p-2">
                          <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                          <div className="  w-2/6 p-2  ">
                                    <h3 className=" font-bold">
                                      1- {t("flightbooking.flights")}
                                    </h3>
                            {
                                    route.trips?.map((trip) => (
                                        trip.flights.map((flight) => (
                                      <div key={flight.id}>
                                        <button
                                          key={flight.id}
                                          className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                            flight.id === flightId
                                              ? "bg-gray-100"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setFlightId(flight.id);
                                            setflightData(flight)
                                            setPaxId(null);
                                            setCheckinData(null)
                                          }}
                                        >
                                          <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                            {flightId === flight.id ? (
                                              <i
                                                className="fa fa-check
                                                "
                                                aria-hidden="true"
                                              ></i>
                                            ) : null}
                                               <span className="flex items-center space-x-2">
                                              <img className='h-5 w-5' src={flight.airlineLogo} alt='airline'></img>
                                            <span className='font-bold'>{flight.flightNumber}</span>  
                                           
                                            </span>
                                            <span>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </span>
                                          </div>
                                        </button>
                                        </div>
                                            ))
                                            ))}
                            </div>

                            
                                  <div className=" flex w-1/6 items-stretch justify-center p-2  ">
                                    {flightId && 
                                      <div>
                                        <h3 className=" font-bold">
                                          2- {t("flightbooking.passengers")}
                                        </h3>
                                        
                                              <div>
                                                {route.passengers.map((p) => (
                                                  <div key={p.id}>
                                                    <button
                                                      key={p.id}
                                                      className={`w-full  border-2 p-2 text-center hover:bg-gray-100 ${
                                                        p.id === paxId
                                                          ? "bg-gray-100"
                                                          : ""
                                                      }`}
                                                      onClick={() => {
                                                        setPaxId(p.id);
                                                      }}
                                                    >
                                                      <div className=" text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                        {paxId === p.id ? (
                                                          <i
                                                            className="fa fa-check "
                                                            aria-hidden="true"
                                                          ></i>
                                                        ) : null}

                                                        <p className="">
                                                          {p.firstname}
                                                        </p>
                                                        <p className="font-bold">
                                                          {p.surname}
                                                        </p>
                                                      </div>
                                                    </button>
                                                  </div>
                                                    ))}
                                              </div>
                                        {" "}
                                        </div>
                                    }
                                  </div>
                                
                            
                            <div className=" w-4/6 p-2 ">
                            { checkinData ?  checkinData.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}

                              {paxId &&
                              flightId && flightData &&
                              flightData.ssrRecords.find(
                                (rcr: any) => rcr.recordType === "OCFE"
                              )?.paxId === paxId &&
                              flightData.ssrRecords.find(
                                (rcr: any) => rcr.recordType === "OCFE"
                              )?.flightId === flightId ? (
                                <span>{t("ssr.already.selected")}</span>
                              ) : (
                                checkinData &&
                                paxId &&
                                flightId &&
                                !isloading && (
                                  <div className="relative flex justify-center p-2 ">
                                    <div className="relative">
                                      <div className=" rounded border shadow-md">
                                        <div className="p-8">
                                          <div className="border-1 text-md overflow-hidden text-center text-gray-900 ">
                                            <p className="font-bold">
                                              {checkinData.map(
                                                (meal: any) => meal.name
                                              )}{" "}
                                              -{" "}
                                              {checkinData.map(
                                                (meal: any) => meal.description
                                              )}
                                            </p>
                                            <p className="">
                                              {checkinData.map(
                                                (meal: any) => meal.price
                                              )}
                                              {checkinData.map(
                                                (meal: any) => meal.currencyCode
                                              )}
                                            </p>
                                          </div>
                                          {confirmbox && (
                                            <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                                              <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                                                <div className="flex w-full justify-between p-2 ">
                                                  <ButtonClose
                                                    onClick={() => {
                                                      setConfirmbox(false);
                                                      setSelectedCheckin("");
                                                      setPaxId(null);
                                                      setFlightId(null);
                                                    }}
                                                  ></ButtonClose>
                                                  {checkLoading && (
                                                    <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                                                  )}
                                                </div>
                                                <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                                                  <p className="font-bold">
                                                    {checkinData.map(
                                                      (meal: any) => meal.name
                                                    )}
                                                    {checkinData.map(
                                                      (meal: any) =>
                                                        meal.description
                                                    )}
                                                  </p>
                                                  <p className="">
                                                    {checkinData.map(
                                                      (meal: any) => meal.price
                                                    )}
                                                    {checkinData.map(
                                                      (meal: any) =>
                                                        meal.currencyCode
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="p-2">
                                                  <p className=" text-red-600">
                                                    {t(
                                                      "generals.attention-this-action-is-not-reversible"
                                                    )}
                                                  </p>
                                                  <p></p>
                                                  <p>
                                                    {" "}
                                                    {t(
                                                      "generals.are-you-sure-you-want-to-proceed"
                                                    )}
                                                  </p>
                                                  <div
                                                    className="inline-flex  p-2 "
                                                    role="group"
                                                  >
                                                    <button
                                                      type="button"
                                                      className="text-sm rounded-l-lg border border-gray-200 bg-red-500 px-4 py-2  font-medium text-white hover:bg-red-700 hover:text-white "
                                                      onClick={() => {
                                                        setConfirmbox(false);
                                                        setSelectedCheckin("");
                                                        setPaxId(null);
                                                        setFlightId(null);
                                                      }}
                                                    >
                                                      {t(
                                                        "b2c-app.generals.cancel"
                                                      )}
                                                    </button>
                                                    <button
                                                    id="confirmCheckIn"
                                                      type="button"
                                                      className="text-sm rounded-r-lg border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900  hover:text-gray-200"
                                                      onClick={() => {
                                                        const button = document.getElementById("confirmCheckIn") as HTMLButtonElement | null;
                                                        if (button) {
                                                          disableButton([button]);
                                                       }
                                                        setCheckLoading(true);
                                                        postSsrCheckin(
                                                          bookingKey,
                                                          filekey,
                                                          selectedCheckin
                                                        ); //post ssr func will come
                                                      }}
                                                      disabled={checkLoading}
                                                    >
                                                      {t("generals.yes")}
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          <div
                                            className="inline-flex items-center justify-center  p-2 "
                                            role="group"
                                          >
                                            <button
                                              type="button"
                                              className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500"
                                              onClick={() => {
                                                //setIsExpanded(false);
                                                setPaxId(null);
                                                setFlightId(null);
                                                setIsOpen(false);
                                                //setSelectedMeal("");
                                                setSelectedCheckin("");
                                              }}
                                            >
                                              {t("b2c-app.generals.cancel")}
                                            </button>
                                            <button
                                              type="button"
                                              className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400"
                                              onClick={() => {
                                                //  setIsExpanded(false);
                                                setSelectedCheckin(
                                                  checkinData.map(
                                                    (chk: any) => chk.ssrKey
                                                  )
                                                );
                                                setConfirmbox(true);
                                              }}
                                            >
                                              {t("generals.add")}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
        
      </div>
    );
  };
  export default CheckInSelection