import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Swal from 'sweetalert2';
import { disableButton } from 'utils/disableButton';
import { useTranslation } from 'react-i18next';


interface Flight {
    id: number;
    flightNumber: string;
    airline: string;
    operatingAirline: string | null;
    airlineLogo: string;
    classOfService: string;
    selectedSeats: string | null;
    baggageAllowance: string;
    departureDate: number;
    arrivalDate: number;
    departurePort: string;
    arrivalPort: string;
    stopoverPort: string | null;
    operatingCarrierInformation: string | null;
    aircraft: string | null;
    cabinClass: string;
    fareBasis: string | null;
    brandName: string;
    availableSeats: number;
    tripIndex: number;
    availabilityBreak: boolean;
    depDate: number;
    arrDate: number;
    departureTime: string;
    departurePortName: string;
    arrivalPortName: string;
    arnk: boolean;
    durationInSeconds: number;
    flightNumberWithoutAirlineCode: string;
    openFlight: boolean;
    timeString: string;
    departureTimeStr: string;
    arrivalTimeStr: string;
    departureDateStr: string;
    arrivalDateStr: string;
    arrivalTime: string;
  }
  
  interface Passenger {
    id: number;
    paxtype: string;
    gender: string;
    birthdate: number;
    firstname: string;
    surname: string;
    ticketnumber: string;
    totalAmount: number;
    baseFare: number;
    frequentFlyerNumber: string | null;
    assistanceChoices: string | null;
    meal: string | null;
    passportNumber: string | null;
    passportExpiryDate: string | null;
    passportCountryOfIssue: string | null;
    passportNationality: string | null;
    ancillaryServicesRequests: string | null;
    hesCode: string | null;
    ticketed: boolean;
    paxRefId: string | null;
  }
  
 export interface PnrDataJson {
    id: number;
    filekey: string;
    airlineFilekey: string | null;
    flightList: Flight[];
    paxList: Passenger[];
    airlineSystem: string;
    airline: string;
    creationDate: number;
    optionDate: string | null;
    ticketingDate: number;
    totalAmount: number;
    status: string;
    itFare: boolean;
    serviceFeePerPax: number;
    specialServiceRequests: string[];
    immediatePaymentRequired: boolean;
    rescanDiff: string | null;
    bookBaggageAvailable: boolean;
    bookMealAvailable: boolean;
    bookAirportCheckinAvailable: boolean;
    bookWheelChairAvailable: boolean;
    bookSeatAvailable: boolean;
    rawSpecialServiceRequests: string;
    airlineLogo: string;
    rescanAvailable: boolean;
    eligilbleForCorendonVoid: boolean;
    contingentBooking: boolean;
    refundAvailable: boolean;
    voidAvailable: boolean;
    reissueAvailable: boolean;
    reissueAvailableV2: boolean;
  }
  

  const UpdatePaxDialog = ({ bookingKey, filekey }: { bookingKey: string, filekey: string }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [pnrData, setPnrData] = useState<PnrDataJson | null>(null);
    const {t} = useTranslation()
  
    useEffect(() => {
      if (open) {
        fetchPnrData();
      }
    }, [open]);
  
    const fetchPnrData = async () => {
      try {
        const response = await fetch(`/intern/getPnrJson?bookingKey=${bookingKey}&filekey=${filekey}`);
        const data = await response.json();
        setPnrData(data);
      } catch (error) {
        console.error('Error fetching PNR data:', error);
      }
    };
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    const handleInputChange = (paxId: number, field: string, value: string | number | Date | null) => {
      setPnrData((prevData) => prevData ? ({
        ...prevData,
        paxList: prevData.paxList.map((pax) =>
          pax.id === paxId ? { ...pax, [field]: value } : pax
        ),
      }) : null);
    };
  
    const handleSubmit = async () => {
        try {
          const formData = new FormData();
          pnrData?.paxList.forEach(pax => {
            formData.append(`${pax.id}-paxtype`, pax.paxtype);
            formData.append(`${pax.id}-gender`, pax.gender);
            formData.append(`${pax.id}-birthdate`, pax.birthdate?.toString() || '');
            formData.append(`${pax.id}-firstname`, pax.firstname);
            formData.append(`${pax.id}-surname`, pax.surname);
            formData.append(`${pax.id}-ticketnumber`, pax.ticketnumber);
            formData.append(`${pax.id}-baseFare`, pax.baseFare.toString());
            formData.append(`${pax.id}-tax`, (pax.totalAmount - pax.baseFare).toString());
          });
      
          const response = await fetch(`/intern/updatePassengers?bookingKey=${bookingKey}&filekey=${filekey}`, {
            method: 'POST',
            body: formData,
          });
      
          if (response.ok) {
            // Success: Show a success dialog
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Passengers updated successfully!',
              confirmButtonText: 'OK',
            });
            handleClose();
            window.location.reload()
          } else {
            throw new Error('Failed to update passengers');
          }
        } catch (error) {
          // Error: Show an error dialog
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to update passengers. Please try again.',
            confirmButtonText: 'OK',
          });
          console.error('Error updating passengers:', error);
        }
      };
      
      const removePax = async (paxId: number) => {
        try {
          const response = await fetch(`/intern/removePax?bookingKey=${bookingKey}&filekey=${filekey}&paxId=${paxId}`, {
            method: 'POST',
          });
      
          if (response.ok) {
            // Success: Show a success dialog
            Swal.fire({
              icon: 'success',
              title: 'Passenger Removed',
              text: 'Passenger removed successfully!',
              confirmButtonText: 'OK',
            });
            fetchPnrData(); // Refresh data after removal
          } else {
            throw new Error('Failed to remove passenger');
          }
        } catch (error) {
          // Error: Show an error dialog
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to remove passenger. Please try again.',
            confirmButtonText: 'OK',
          });
          console.error('Error removing passenger:', error);
        }
      };
    return ( 
      <>
              <button
                  id="updatePax"
                  type="button"
                  onClick={() => {
                    const button = document.getElementById("updatePax") as HTMLButtonElement | null;
                    if (button) {
                      disableButton([button]);
                   }
                   handleOpen()}}
                  className="text-sm border border-gray-100 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 "
                >
                  <i className="fa fa-edit px-1"></i> Pax Güncelle
                </button>
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>Pax Güncelle</DialogTitle>
          <DialogContent>
            {pnrData && (
              <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '4px' }}>Yolcu Tipi</TableCell>
                  <TableCell style={{ padding: '4px' }}>Cinsiyet</TableCell>
                  <TableCell style={{ padding: '4px' }}>Doğum tarihi	</TableCell>
                  <TableCell style={{ padding: '4px' }}>Önisim</TableCell>
                  <TableCell style={{ padding: '4px' }}>Soyisim</TableCell>
                  <TableCell style={{ padding: '4px' }}>Bilet No	</TableCell>
                  <TableCell style={{ padding: '4px' }}>Net EUR</TableCell>
                  <TableCell style={{ padding: '4px' }}>Tax EUR</TableCell>
                  <TableCell style={{ padding: '4px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pnrData.paxList.map((pax) => (
                  <TableRow key={pax.id}>
                    <TableCell style={{ padding: '4px' }}>
                      <Select
                        value={pax.paxtype}
                        onChange={(e) => handleInputChange(pax.id, 'paxtype', e.target.value)}
                      >
                        <MenuItem value="ADT">ADT</MenuItem>
                        <MenuItem value="CHD">CHD</MenuItem>
                        <MenuItem value="INF">INF</MenuItem>
                        <MenuItem value="DIS">DIS</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Select
                        value={pax.gender}
                        onChange={(e) => handleInputChange(pax.id, 'gender', e.target.value)}
                      >
                        <MenuItem value="MALE">Male</MenuItem>
                        <MenuItem value="FEMALE">Female</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <Box
                        sx={{
                          display: 'grid',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid #ccc',
                          borderRadius: '6px',
                          padding: '4px',
                        }}
                      >
                        <input
                            type="date"
                            value={moment(pax.birthdate).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                handleInputChange(pax.id, 'birthdate', e.target.value)
                            }
                            style={{
                                width: '100%',
                                border: 'none',
                                outline: 'none',
                                padding: '4px',
                                borderRadius: '6px',
                            }}
                            min={moment().subtract(99, 'years').format("YYYY-MM-DD")}
                            max={moment().format("YYYY-MM-DD")}
                            />
                      </Box>
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <TextField
                        value={pax.firstname}
                        onChange={(e) => handleInputChange(pax.id, 'firstname', e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <TextField
                        value={pax.surname}
                        onChange={(e) => handleInputChange(pax.id, 'surname', e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <TextField
                        value={pax.ticketnumber || ''}
                        onChange={(e) => handleInputChange(pax.id, 'ticketnumber', e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <TextField
                        type="number"
                        value={pax.baseFare}
                        onChange={(e) => handleInputChange(pax.id, 'baseFare', parseFloat(e.target.value))}
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <TextField
                        type="number"
                        value={pax.totalAmount - pax.baseFare}
                        onChange={(e) => handleInputChange(pax.id, 'totalAmount', parseFloat(e.target.value) + pax.baseFare)}
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell style={{ padding: '4px' }}>
                      <IconButton onClick={() => removePax(pax.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("b2c-app.generals.cancel")}</Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>{t("agency-settings.save")}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default UpdatePaxDialog;