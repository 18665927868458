import React from "react";
import TextField from "@material-ui/core/TextField";

export function AtrTextfield( props )
{
    const handleChange = ( event ) =>
    {
        if ( props?.updateValue )
        {
            props.updateValue( event.target.value );
        }
    }

    return(
        <TextField
            error = { props.isError }
            helperText = { props.helperText }
            style = { props.style }
            name = { props.name }
            label = { props.label }
            value = { props.defaultValue }
            variant = 'outlined'
            onChange = { handleChange }
        />
    )
}