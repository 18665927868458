import React, {FC, useEffect, useState} from "react";
import {getFlightCaseUrl} from "../../../api/helpers/api-constants";
import fetcher from "../../../api/helpers/fetcher";
import NotificationModalScroll from "./NotificationModalScroll";
import {INotificationModalScroll} from "../../../api/types";

interface IHistoryFlightCaseModal {
    bookingKey: string,
    open: boolean,
    openLastCreatedCase: boolean,
    historyFlightCaseHandler: (value: boolean) => void
    closeOpenLastCreatedCase: () => void
    notifications:INotificationModalScroll[]| null
    setNotifications:React.Dispatch<React.SetStateAction<INotificationModalScroll[] | null>>
}

const HistoryFlightCaseModal: FC<IHistoryFlightCaseModal> = (
    {
        bookingKey,
        open,
        openLastCreatedCase = false,
        historyFlightCaseHandler,
        closeOpenLastCreatedCase,
        notifications,
        setNotifications
    }) => {
    const [notResolvedNotificationIds, setNotResolvedNotificationIds] = useState<number[]>([]);

    useEffect(() => {
        if (notifications === null)
            fetchData();
    }, []);

    useEffect(() => {
        if (openLastCreatedCase)
            fetchData();
    }, [openLastCreatedCase]);
// test here
    useEffect(() => {
        if (notifications !== null && notifications.length > 0) {
            let notResolvedNotifications: number[] = notifications.filter(notification => !notification.status.includes('CASE_RESOLVED')).map(notification => notification.id)
            setNotResolvedNotificationIds(notResolvedNotifications);
            if (notResolvedNotifications.length > 0)
                historyFlightCaseHandler(true);
        }
    }, [notifications])

    const fetchData = async () => {
        const _notifications = await fetcher(getFlightCaseUrl(bookingKey));
        setNotifications(_notifications);
    }

    const emptyNotResolvedNotificationIds = () => {
        setNotResolvedNotificationIds([]);
    }

    return (
        <NotificationModalScroll
            notifications={notifications !== null ? notifications : []}
            open={open}
            notResolvedNotificationIds={notResolvedNotificationIds}
            emptyNotResolvedNotificationIds={emptyNotResolvedNotificationIds}
            openLastCreatedCase={openLastCreatedCase}
            closeModal={() => historyFlightCaseHandler(false)}
            closeOpenLastCreatedCase={closeOpenLastCreatedCase}
        />
    )
}
export default HistoryFlightCaseModal;