import React, { useState } from "react";
import GuestsInput, { RoomInput } from "../GuestsInput";
import { FocusedInputShape } from "react-dates";
import DatesRangeInput from "../DatesRangeInput";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { FC } from "react";
import NationalityInput from "../NationalityInput";
import { getHotelPageUrl } from "utils/navigationLinks";
import useRecentSearches from "hooks/useRecentSearches";
import { HotelSearchAutocompleteResult, SearchPayload } from "api/types";
import HotelQueryParametersService from "services/HotelQueryParametersService";
import AutocompleteInput from "../AutocompleteInput";
import { getHotelSearchAutocompleteUrl } from "api/helpers/api-constants";
import { useDispatch } from "react-redux";
import { SetTabSelections } from "redux/slices/tabsSlice";
import { useReduxReducer } from "redux/store";
import { useTranslation } from "react-i18next";
import {HotelSearchFormMobile} from "./HotelSearchFormMobile";

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface MissingInput {
  nationality: boolean,
  location: boolean,
  checkinDate: boolean,
  checkoutDate: boolean
}

export interface HotelSearchFormProps {
  queryParameters: SearchPayload | null;
}

const HotelSearchForm: FC<HotelSearchFormProps> = ({ queryParameters }) => {
  const { isMobile } = useReduxReducer(state => state.general)
  const { appendRecentSearch } = useRecentSearches("recentSearches");

  const [hotel, setHotel] = useState<HotelSearchAutocompleteResult | null>(
    null
  );

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment().add("days", 1),
    endDate: moment().add("days", 2),
  });

  const [nationality, setNationality] = useState("DE");

  const [guests, setGuests] = useState<Array<RoomInput>>([
    { adults: 2, children: [] },
  ]);

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  const [missingInputError, setMissinInputError] = useState<MissingInput>({
    nationality: false,
    location: false,
    checkinDate: false,
    checkoutDate: false,
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (queryParameters?.searchType === "HOTEL") {
      if (
        queryParameters?.destinationId &&
        queryParameters?.label &&
        queryParameters?.destinationType
      ) {
        setHotel({
          id: queryParameters?.destinationId,
          label: queryParameters?.label,
          type: queryParameters?.destinationType,
        });
      }

      if (queryParameters?.checkinDate && queryParameters?.checkoutDate) {
        setDateRange({
          startDate: moment(queryParameters?.checkinDate),
          endDate: moment(queryParameters?.checkoutDate),
        });
      }

      if (queryParameters?.nationality) {
        setNationality(queryParameters?.nationality);
      }

      if (queryParameters?.roomOccupancies) {
        setGuests(queryParameters?.roomOccupancies);
      }
    }
  }, [JSON.stringify(queryParameters)]);

  function returnQueryDateRange () {
    if(queryParameters && queryParameters.checkinDate && queryParameters.checkoutDate)
  setDateRange({
    startDate: moment(queryParameters.checkinDate),
    endDate: moment(queryParameters.checkoutDate),
  });
  else return
  };
  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    dispatch(SetTabSelections({ currentTab: "HOTEL" }));

    setMissinInputError({
      nationality: !nationality,
      location: !hotel,
      checkinDate: !dateRange.startDate,
      checkoutDate: !dateRange.endDate,
    });

    const urlSearchParams = HotelQueryParametersService.encode(
      nationality,
      hotel,
      dateRange,
      guests
    );

    if (urlSearchParams) {
      const recentData = {
        type: "hotel",
        hotel: hotel?.label,
        dateRange: dateRange,
        guests: guests,
        url: urlSearchParams,
      };

      appendRecentSearch(recentData);

      const targetUrl = hotel!.type === "HOTEL"
        ?'/ibe#' + getHotelPageUrl(hotel!.id) + "?" + urlSearchParams
        : "/ibe#/search?" + urlSearchParams;

        const redirectLink = document.createElement("a");
        redirectLink.href = targetUrl;
        redirectLink.target = "_blank"; // Open in a new tab
        document.body.appendChild(redirectLink);
        redirectLink.click();
        document.body.removeChild(redirectLink);
       returnQueryDateRange()
    }
  }

  const { t } = useTranslation();
  const location = t("react.hotel.location");
  return (
    <>
      {
        isMobile ?
            <HotelSearchFormMobile
                queryParameters={queryParameters}
                hotel={hotel}
                setHotel={setHotel}
                dateRange={dateRange}
                setDateRange={setDateRange}
                nationality={nationality}
                setNationality={setNationality}
                guests={guests}
                setGuests={setGuests}
                missingInputError={missingInputError}
                handleSubmit={(e: React.FormEvent) => handleSubmit(e)}
            />
        :
            <div>
              <form
                  onSubmit={handleSubmit}
                  className="relative w-full rounded-b-lg  bg-white shadow-sm dark:bg-neutral-900 dark:shadow-2xl"
              >
                <div className=" [ nc-divide-field ] flex w-full flex-col rounded-full md:flex-row md:items-center ">
                  <div className="[ nc-divide-field ] relative flex flex-grow flex-col md:flex-row ">

                    <AutocompleteInput
                        placeHolder={location}
                        blockBorder={true}
                        desc={t("hotel.searching-for-best-hotel-offers")}
                        autocompleteValue={hotel}
                        setAutocompleteValue={setHotel}
                        localStorageToken="hotel-search-form-autocomplete"
                        getUrl={getHotelSearchAutocompleteUrl}
                        onInputDone={() => setDateFocused("startDate")}
                        error={missingInputError.location}
                        searchType={"HOTEL"}
                    />
                    <DatesRangeInput
                        defaultValue={dateRange}
                        defaultFocus={dateFocused}
                        checkinLabel={t("app.add-date")}
                        checkoutLabel={t("app.add-date")}
                        prRanges={false}
                        onFocusChange={(focus) => setDateFocused(focus)}
                        onChange={(data) => setDateRange(data)}
                        checkinDateError={missingInputError.checkinDate}
                        checkoutDateError={missingInputError.checkoutDate}
                        value={dateRange}
                    />
                    <GuestsInput guestValue={guests} setGuestValue={setGuests} />
                    <NationalityInput
                        value={nationality}
                        setValue={setNationality}
                        error={missingInputError.nationality}
                    />
                    {/* BUTTON SUBMIT OF FORM */}
                    <div className="justify center flex items-center px-4 py-4 lg:py-0">
                      <SubmitButton />
                    </div>
                  </div>
                </div>
              </form>
            </div>
      }
    </>
);
};

export default HotelSearchForm;
