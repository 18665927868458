import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const handleClick = () => {
    navigate(-1);
    setTimeout(() => {
      window.location.reload();
    }, 1000); // 1-second delay
  };
  
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
  <button
      className={`p-1 flex items-center justify-center rounded !leading-none disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ml-4 mt-4 `}
      onClick={handleClick}
    >

      <ArrowLeftIcon className="w-5 h-5" />
        <span>{t("react.generel.back")}</span>
    </button> 
      <h1>{t("app.general-error")}</h1>
      <p>{t("app.general.error-back")}</p>
     
    </div>
  );
};

export default ErrorPage;
