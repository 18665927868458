import React from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

interface TimerProps {
  initialTime: number;
}

const timerStyle: React.CSSProperties = {
  backgroundColor: "transparent",
  color: "alert",
  padding: "2px",
  borderRadius: "2px",
  fontFamily: "monospace",
  fontSize: "20px",
  textAlign: "center",
};


const Timer: React.FC<TimerProps> = ({ initialTime }) => {

  const {t} = useTranslation()
  const renderer = ({ days, hours, minutes, seconds, completed }: { days: number, hours: number, minutes: number, seconds: number, completed: boolean }) => {
  
    if (completed) {
      return <span>{""}</span>;
    } else {
      const formattedTime = `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      return <div style={timerStyle}>{formattedTime}</div>;
    }
  };

  return (
    <Countdown
      date={Date.now() + (initialTime * 1000)}
      renderer={renderer}
    />
  );
};

export default Timer;


