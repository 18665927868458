import React, { useState } from 'react';
import Layout from "../layout";

import { Stepper, Step, StepLabel, Button, Paper } from '@mui/material';
import { FormProvider, useFormContext } from '../contexts/FormContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CarRentalForm from './components/CarRentalForm';
import FlightInformationForm from './components/FlightInformationForm';
import { InvoiceForm } from './components/InvoiceForm';
import PaxFormComponent from './components/PaxFormComponent';
import InvoiceDraftSummary from './components/InvoiceDraftSummary';
import HotelBookingForm from './components/HotelBookingForm';
import GenericProductForm from './components/GenericFormComponent';

const CreateInvoicesContent: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { formData } = useFormContext();
  
  const steps = ['Invoice Details', 'Product Information', 'Passenger Information', 'Draft Summary'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    // Handle final form submission here
    console.log('Form submitted');
  };

  const renderProductForm = () => {
    switch (formData.invoiceDetails.productType) {
      case 'flight':
        return <FlightInformationForm />;
      case 'car':
        return <CarRentalForm />;
      case 'hotel':
        return <HotelBookingForm />;
      case 'generic':
        return <GenericProductForm />;
      default:
        return null;
    }
  };


  return (
    <Paper className="p-6 max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Create Invoice</h1>
      <Stepper activeStep={activeStep} alternativeLabel className="mb-8">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="mt-8">
        {activeStep === 0 && <InvoiceForm />}
        {activeStep === 1 && renderProductForm()}
        {activeStep === 2 && <PaxFormComponent />}
        {activeStep === 3 && <InvoiceDraftSummary />}
      </div>
      <div className="mt-8 flex justify-between">
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="outlined"
        >
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            Next
          </Button>
        )}
      </div>
    </Paper>
  );
};

const CreateInvoices: React.FC = () => {
  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormProvider>
          <CreateInvoicesContent />
        </FormProvider>
      </LocalizationProvider>
    </Layout>
  );
};

export default CreateInvoices;

