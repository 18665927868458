import * as React from "react";
import Switch from "@mui/material/Switch";
import { t } from "i18next";
export default function AtrSwitch(props) {
  const handleChange = (event) => {
    props.changeIsFamily(event.target.checked);
  };

  return (
    <React.Fragment>
      {t("insurance.single")}&nbsp;
      <Switch
        checked={props.isFamily}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
      &nbsp;{t("insurance.family")}
    </React.Fragment>
  );
}
