import { RentalSearchAutocompleteResult, SearchPayload } from "api/types";
import { DateRange } from "components/common/HeroSearchForm/HotelSearchForm";
import ValidationService from "./ValidationService";

class RentalCarQueryParametersService {
  static encode(
    age: string | null,
    otherReturnStation: boolean,
    rentalLocation: RentalSearchAutocompleteResult | null,
    returnLocation: RentalSearchAutocompleteResult | null,
    dateRange: DateRange,
    departTime: string | null,
    returnTime: string | null
  ) {
    if (
      !age ||
      (otherReturnStation && !returnLocation) ||
      !rentalLocation ||
      !dateRange.endDate ||
      !dateRange.startDate ||
      !returnTime ||
      !departTime
    ) {
      return null;
    }

    const dt: any = {
      searchType: "Car Rental",
      age: age,
      pickupLocationId: rentalLocation?.id,
      returnLocationId: returnLocation?.id
        ? returnLocation?.id
        : rentalLocation?.id,
      pickupAirportCode: null,
      returnAirportCode: null,
      pickupDate: dateRange.startDate!.format("DD.MM.YYYY"),
      returnDate: dateRange.endDate!.format("DD.MM.YYYY"),
      pickupHour: departTime?.substring(0, 2),
      pickupMinute: departTime?.substring(3, 5),
      returnHour: returnTime?.substring(0, 2),
      returnMinute: returnTime?.substring(3, 5),
      itFares: false,
      pickupLocation: rentalLocation?.text,
      returnLocation: returnLocation?.text
        ? returnLocation?.text
        : rentalLocation?.text,
      otherReturnStation: otherReturnStation,
    };

    if (otherReturnStation && returnLocation) {
      dt.returnLocationId = returnLocation?.id;
    }

    return new URLSearchParams({ ...dt });
  }

  static decode(urlParams: URLSearchParams): SearchPayload | null {
    let age = urlParams.get("age") || null;
    let pickupLocationId = urlParams.get("pickupLocationId") || null;
    let returnLocationId = urlParams.get("returnLocationId") || null;
    let pickupAirportCode = urlParams.get("pickupAirportCode") || null;
    let returnAirportCode = urlParams.get("returnAirportCode") || null;
    let pickupDate = urlParams.get("pickupDate") || null;
    let returnDate = urlParams.get("returnDate") || null;
    let pickupHour = urlParams.get("pickupHour") || null;
    let pickupMinute = urlParams.get("pickupMinute") || null;
    let returnHour = urlParams.get("returnHour") || null;
    let returnMinute = urlParams.get("returnMinute") || null;
    let itFares = urlParams.get("itFares") || null;
    let pickupLocation = urlParams.get("pickupLocation") || null;
    let returnLocation = urlParams.get("returnLocation") || null;
    let otherReturnStation = urlParams.get("otherReturnStation") || null;

    pickupDate = ValidationService.date(pickupDate, "DD.MM.YYYY")
      ? pickupDate
      : null;
    returnDate = ValidationService.date(returnDate, "DD.MM.YYYY")
      ? returnDate
      : null;

    let departTime =
      pickupHour && pickupMinute ? `${pickupHour}:${pickupMinute}` : null;
    let returnTime =
      returnHour && returnMinute ? `${returnHour}:${returnMinute}` : null;

    if (
      !age ||
      !pickupLocationId ||
      !returnLocationId ||
      !pickupDate ||
      !returnDate ||
      !departTime ||
      !returnTime
    ) {
      return null;
    }

    return {
      searchType: "Car Rental",
      age,
      pickupLocationId,
      returnLocationId,
      pickupAirportCode,
      returnAirportCode,
      pickupDate,
      returnDate,
      pickupHour,
      pickupMinute,
      returnHour,
      returnMinute,
      itFares,
      pickupLocation,
      returnLocation,
      departTime,
      returnTime,
      otherReturnStation,
    };
  }
}

export default RentalCarQueryParametersService;
