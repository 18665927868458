import React, { FC } from "react";
import { TourDetails } from "../../../api/types";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { getTourPageUrl } from "../../../utils/navigationLinks";
import currencyFormat from "../../../utils/currencyFormat";
import { useDispatch } from "react-redux";
import { Card, CardContent, CardMedia, Typography, Chip, Box, Button } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import moment from "moment";


export interface StayCardTProps {
  className?: string;
  data: TourDetails;
}

export const StayCardTour: FC<StayCardTProps> = ({ className = "", data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPrice = () => {
    let priceAmount = data.price || 0;
    return currencyFormat(priceAmount);
  };

  const renderContent = () => {
    return (
      <CardContent>
        <Typography variant="h6" component="h3" gutterBottom>
          {data.name}
        </Typography>
        <div className="flex justify-between ">
        <div>
        <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
          <Chip label={data.accommodationType} variant="outlined" size="medium" color="info" />
          <Chip label={data.regionName} variant="outlined" size="medium" color="success" />
        </Box>
     
        <Box display="flex" alignItems="center" mb={1} mt={1}>
          <CalendarToday fontSize="small" className="text-primary-6000"/>
          <Typography variant="body2"  ml={1} className="text-primary-6000">
            {moment(
                moment(data.travelDates[0].startDate, "ddd MMM DD HH:mm:ss [CET] YYYY").valueOf())
                .format("DD.MM.YYYY") } 
                -
                 {moment(
                    moment(data.travelDates[0].endDate, "ddd MMM DD HH:mm:ss [CET] YYYY").valueOf())
                    .format("DD.MM.YYYY")}
          </Typography>
        </Box>
        </div>
        <div  className="px-4 grid gap-0">

<span className="text-primary-6000 font-semibold text-2xl">          {getPrice()}
   
</span>
<small>          {t("generals.per-person")}
</small>
</div>
        </div>
      
        

<div className="flex justify-end items-center ">
   
<Link
  to={getTourPageUrl(data.offerId)}
  target="_blank"
  className="bg-[#156da1] text-white px-4 py-2 rounded shadow-md hover:text-white hover:bg-[#135a84] hover:shadow-lg transition-all duration-200"
>
  {t("b2c-app.generals.next")}
</Link>

        
</div>
     
            
          
      </CardContent>
    );
  };

  return (
    <div className="flex gap-2 border shadow rounded-lg bg-white">
         <img
  className=" w-1/4 h-32 md:h-auto p-2 rounded-lg object-cover"
  src={data.thumbnailImage[0] || "/placeholder.svg"}
  alt={data.name}
/>

      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        {renderContent()}
      </Box>
    </div>
 
  );
};
