import React, { useEffect, useState } from "react";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RentalSearchAutocompleteResult, SearchPayload } from "api/types";
import AutocompleteInput from "../AutocompleteInput";
import { getRentalLocationAutocompleteUrl } from "api/helpers/api-constants";
import Checkbox from "components/ui/Checkbox/Checkbox";
import HeroSelect from "../HeroSelect";
import { ClockIcon, UserIcon } from "@heroicons/react/outline";
import RentalCarQueryParametersService from "services/RentalCarQueryParametersService";
import useRentalCarPageStore from "store/rentalCarPageStore";
import { useDispatch } from "react-redux";
import { SetTabSelections } from "redux/slices/tabsSlice";
import { useTranslation } from "react-i18next";
import { unstable_batchedUpdates } from "react-dom";
import DateInput from "../DateInput";
import {useReduxReducer} from "../../../../redux/store";

import DatesRangeInput from "../DatesRangeInput";
import timeSelectOptions from "utils/timeSelectOptions";
import useRecentSearches from "hooks/useRecentSearches";
import CarRentalSearchForm from "./CarRentalSearchForm";
import CarRentalSearchFormMobile from "./CarRentalSearchFormMobile";
export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

const CarRentalSearch = () => {
  const { isMobile } = useReduxReducer(state => state.general)
  const { search } = useLocation();
  const { setSearchPayload, searchPayload } = useRentalCarPageStore(
    (state) => state
  );
  const { appendRecentSearch } = useRecentSearches("recentSearches");
  const [otherReturnStation, setOtherReturnStation] = useState(false);
  const [age, setAge] = useState<string | null>("27");
  const [startDate, setStartDate] = useState<moment.Moment | null>(
    moment().add(7, "days")
  );
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    moment().add(10, "days")
  );
  const [rentalLocation, setRentalLocation] =
    useState<RentalSearchAutocompleteResult | null>(null);
  const [returnLocation, setReturnLocation] =
    useState<RentalSearchAutocompleteResult | null>(null);
  const [rentalTime, setRentalTime] = useState<string | null>("12:00");
  const [returnTime, setReturnTime] = useState<string | null>("12:00");
  const [rentalTimeFocus, setRentalTimeFocus] = useState(false);
  const [returnTimeFocus, setReturnTimeFocus] = useState(false);
  const [dateFocus, setDateFocus] = useState<boolean>(false);
  const [returnDateFocus, setReturnDateFocus] = useState<boolean>(false);
  const [returnLocationFocus, setReturnLocationFocus] =
    useState<boolean>(false);
  const [missingInputError, setMissinInputError] = useState({
    rentalLocation: false,
    returnLocation: false,
    departingDate: false,
    returningDate: false,
    age: false,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    setSearchPayload(urlParams);
  }, []);

  useEffect(() => {
    if (searchPayload) {
      unstable_batchedUpdates(() => {
        setRentalLocation({
          id: searchPayload.pickupLocationId
            ? searchPayload.pickupLocationId
            : "",
          text: searchPayload.pickupLocation
            ? searchPayload.pickupLocation
            : "",
        });
        setReturnLocation({
          id: searchPayload.returnLocationId
            ? searchPayload.returnLocationId
            : "",
          text: searchPayload.returnLocation
            ? searchPayload.returnLocation
            : "",
        });
        setAge(searchPayload.age ?? "18");
        setOtherReturnStation(searchPayload.otherReturnStation === "true");
        setStartDate(
          searchPayload.pickupDate
            ? moment(searchPayload.pickupDate, "DD.MM.YYYY")
            : null
        );
        setEndDate(
          searchPayload.returnDate
            ? moment(searchPayload.returnDate, "DD.MM.YYYY")
            : null
        );
        setRentalTime(searchPayload.departTime ?? "12:00");
        setReturnTime(searchPayload.returnTime ?? "12:00");
      });
    }
  }, [searchPayload]);

  useEffect(() => {
    !otherReturnStation && setReturnLocation(rentalLocation);
  }, [otherReturnStation]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    dispatch(SetTabSelections({ currentTab: "Car Rental" }));

    setMissinInputError({
      rentalLocation: !rentalLocation,
      returnLocation: otherReturnStation ? !returnLocation : false,
      departingDate: !startDate,
      returningDate: !endDate,
      age: false,
    });

    const urlSearchParams = RentalCarQueryParametersService.encode(
      age,
      otherReturnStation,
      rentalLocation,
      returnLocation,
      {
        startDate,
        endDate,
      },
      rentalTime,
      returnTime
    );

    if (urlSearchParams) {
      const recentData = {
        type: "rentacar",
        rentalLocation: rentalLocation?.text,
        returnLocation: returnLocation?.text,
        startDateRent: startDate,
        endDateRent: endDate,
        url: urlSearchParams.toString(),
      };
      appendRecentSearch(recentData);
      setSearchPayload(urlSearchParams);
      navigate("/rent-car/results?" + urlSearchParams.toString());
    }
  }

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: searchPayload?.pickupDate
      ? moment(searchPayload?.pickupDate)
      : moment().add(5, "days"),
    endDate: searchPayload?.returnDate
      ? moment(searchPayload.returnDate)
      : moment().add(20, "days"),
  });

  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setEndDate(moment(startDate).add(1, "day"));
    }
  }, [startDate]);

  useEffect(() => {
    if (!otherReturnStation) {
      setReturnLocation(rentalLocation);
    }
  }, [rentalLocation]);

  return (
    <div>
      {isMobile ?

      <CarRentalSearchFormMobile
otherReturnStation={otherReturnStation}
setOtherReturnStation={setOtherReturnStation}
age={age}
setAge={setAge}
startDate={startDate}
setStartDate={setStartDate}
endDate={endDate}
setEndDate={setEndDate}
rentalLocation={rentalLocation}
setRentalLocation={setRentalLocation}
returnLocation={returnLocation}
setReturnLocation={setReturnLocation}
missingInputError={missingInputError}
rentalTime={rentalTime}
setRentalTime={setRentalTime}
returnTime={returnTime}
setReturnTime={setReturnTime}
rentalTimeFocus={rentalTimeFocus}
setRentalTimeFocus={setRentalTimeFocus}
returnTimeFocus={returnTimeFocus}
setReturnTimeFocus={setReturnTimeFocus}
dateFocus={dateFocus}
setDateFocus={setDateFocus}
returnDateFocus={returnDateFocus}
setReturnDateFocus={setReturnDateFocus}
returnLocationFocus={returnLocationFocus}
setReturnLocationFocus={setReturnLocationFocus}
handleSubmit={(e: React.FormEvent) => handleSubmit(e)}

/>
:
<CarRentalSearchForm
otherReturnStation={otherReturnStation}
setOtherReturnStation={setOtherReturnStation}
age={age}
setAge={setAge}
startDate={startDate}
setStartDate={setStartDate}
endDate={endDate}
setEndDate={setEndDate}
rentalLocation={rentalLocation}
setRentalLocation={setRentalLocation}
returnLocation={returnLocation}
setReturnLocation={setReturnLocation}
rentalTime={rentalTime}
setRentalTime={setRentalTime}
returnTime={returnTime}
setReturnTime={setReturnTime}
missingInputError={missingInputError}
rentalTimeFocus={rentalTimeFocus}
setRentalTimeFocus={setRentalTimeFocus}
returnTimeFocus={returnTimeFocus}
setReturnTimeFocus={setReturnTimeFocus}
dateFocus={dateFocus}
setDateFocus={setDateFocus}
returnDateFocus={returnDateFocus}
setReturnDateFocus={setReturnDateFocus}
returnLocationFocus={returnLocationFocus}
setReturnLocationFocus={setReturnLocationFocus}
handleSubmit={(e: React.FormEvent) => handleSubmit(e)}

/>    }

    </div>
  );
};


export default CarRentalSearch;
