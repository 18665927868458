import { useEffect, useState } from "react";
import { useDebouncedValue } from "hooks/useDebouncedValue";
import useSWRImmutable from "swr/immutable";

import fetcher from "api/helpers/fetcher";
import { getTourSearchSuggestedUrl } from "api/helpers/api-constants";

function useGetSuggested<T>() {
  const { data: suggested } = useSWRImmutable<T>(
    getTourSearchSuggestedUrl(),
    fetcher
  );


  return {
    //@ts-ignore
    suggested: (suggested?.results ? suggested.results : suggested) as T,
  };
}

export default useGetSuggested;
