import React, {FC, useEffect, useState} from "react"
import {TextField} from "@mui/material";
import {RangeDatePicker} from "../../components/ui/DatePicker/RangeDatePicker";
import {IDateRange} from "../../api/types";
import {useTranslation} from "react-i18next";
import {FocusedInputShape} from "react-dates";
import {InvoiceTypeFilter} from "./InvoiceTypeFilter";

interface IInvoiceFilter {
    dateRange: IDateRange
    withPartnerCodeFilter: boolean
    withSearchField: boolean
    partnerCode?: string
    searchInvoiceNo?: string
    searchBookingNo?: string
    searchPartnerCodeLabel?: string
    searchInvoiceNoLabel?: string
    searchBookingNoLabel?: string
    selectedBookingType: string
    bookingTypes: string[]
    setPartnerCode?: (value: string) => void
    setSearchInvoiceNo?: (value: string) => void
    setSearchBookingNo?: (value: string) => void
    setDateRange: (date: IDateRange) => void
    setSelectedBookingType: (type: string) => void
    datePickerIsOpen?: (isOpen: boolean) => void
}

export const InvoiceFilter: FC<IInvoiceFilter> = ({
                                                      dateRange,
                                                      withPartnerCodeFilter,
                                                      withSearchField,
                                                      partnerCode,
                                                      searchInvoiceNo,
                                                      searchBookingNo,
                                                      searchPartnerCodeLabel,
                                                      searchInvoiceNoLabel,
                                                      searchBookingNoLabel,
                                                      selectedBookingType,
                                                      setPartnerCode,
                                                      setSearchInvoiceNo,
                                                      setSearchBookingNo,
                                                      bookingTypes,
                                                      setDateRange,
                                                      setSelectedBookingType,
                                                      datePickerIsOpen
}) => {
    const [onFocusChange, setFocusedInput] = useState<FocusedInputShape|null>(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (typeof datePickerIsOpen !== 'undefined')
            datePickerIsOpen(onFocusChange !== null)
    }, [onFocusChange])

    const changeDateRangeHandler = (date: IDateRange) => {
        setDateRange(checkChangeDate(date));
        if (date.startDate !== null && date.endDate !== null)
            onFocusInputHandler(null);
    }

    const checkChangeDate = (date: IDateRange): IDateRange => {
        if (date.startDate !== dateRange.startDate && date.endDate === dateRange.endDate)
            date.endDate = null;
        return date;
    }

    const onFocusInputHandler = (focus: FocusedInputShape|null) => {
        setFocusedInput(focus);
    }

    return (
        <div className="flex items-center justify-end relative">

            <>
                <RangeDatePicker
                    className="absolute inset-x-0 bottom-0"
                    dateRange={dateRange}
                    onDatesChangeHandler={changeDateRangeHandler}
                    onFocusChange={onFocusChange}
                    onFocusInputHandler={onFocusInputHandler}
                />
                <TextField
                    className="pr-2"
                    label={t('react.general.date')}
                    size="small"
                    style={{ width: 230 }}
                    value={`${dateRange.startDate?.format('DD.MM.YYYY')} - ${dateRange.endDate?.format('DD.MM.YYYY')}`}
                    onClick={() => setFocusedInput('startDate')}
                    InputProps={{readOnly: true}}
                />
            </>

            <InvoiceTypeFilter
                className="pr-2"
                selectedType={selectedBookingType}
                setSelectedType={setSelectedBookingType}
                label={t('general.booking.type')}
                types={bookingTypes}
            />

            {
                withPartnerCodeFilter && (
                    <TextField
                        className="pr-2"
                        label={searchPartnerCodeLabel}
                        size="small"
                        defaultValue={partnerCode}
                        onChange={(e) => {
                            setPartnerCode!(e.target.value);
                        }}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                setPartnerCode!(e.target.value);
                            }
                        }}
                    />
                )
            }

            {
                withSearchField && (
                    <>
                        <TextField
                            className="pr-2"
                            label={searchInvoiceNoLabel}
                            size="small"
                            defaultValue={searchInvoiceNo}
                            onChange={(e) => {
                                setSearchInvoiceNo!(e.target.value);
                            }}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    setSearchInvoiceNo!(e.target.value);
                                }
                            }}
                        />
                        <TextField
                            className="pr-2"
                            label={searchBookingNoLabel}
                            size="small"
                            defaultValue={searchBookingNo}
                            onChange={(e) => {
                                setSearchBookingNo!(e.target.value);
                            }}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    setSearchBookingNo!(e.target.value);
                                }
                            }}
                        />
                    </>
                )
            }

        </div>
    )
}
