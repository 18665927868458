import React from 'react';
import { 
  TextField, 
  Button,
  Grid,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import moment from 'moment';
import { HotelBooking, useFormContext } from 'pages/Accounting/contexts/FormContext';
import { DatePickermui } from './DatePickermui';

const HotelBookingForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext();

  const addHotelBooking = () => {
    const newHotelBookingId = formData.hotelBookings.length > 0 
      ? Math.max(...formData.hotelBookings.map(h => h.id)) + 1 
      : 1;
    
    updateFormData({
      hotelBookings: [
        ...formData.hotelBookings,
        {
          id: newHotelBookingId,
          hotelName: '',
          checkInDate: moment(),
          checkOutDate: moment().add(1, 'day'),
          roomType: '',
          numberOfGuests: 1,
          pricePerNight: 0,
        }
      ]
    });
  };

  const removeHotelBooking = (id: number) => {
    updateFormData({
      hotelBookings: formData.hotelBookings.filter(h => h.id !== id)
    });
  };

  const updateHotelBooking = (id: number, field: keyof HotelBooking, value: any) => {
    updateFormData({
      hotelBookings: formData.hotelBookings.map(h => 
        h.id === id ? {...h, [field]: value} : h
      )
    });
  };

  return (
    <div>
      {formData.hotelBookings.map((hotelBooking, index) => (
        <Paper key={hotelBooking.id} className="p-4 mb-4 relative">
          <Typography variant="h6" className="mb-4">
            Hotel Booking {index + 1}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Hotel Name"
                value={hotelBooking.hotelName}
                onChange={(e) => updateHotelBooking(hotelBooking.id, 'hotelName', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickermui
                date={hotelBooking.checkInDate}
                handleDateChange={(date) => updateHotelBooking(hotelBooking.id, 'checkInDate', date)}
                className="w-full"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickermui
                date={hotelBooking.checkOutDate}
                handleDateChange={(date) => updateHotelBooking(hotelBooking.id, 'checkOutDate', date)}
                className="w-full"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Room Type"
                value={hotelBooking.roomType}
                onChange={(e) => updateHotelBooking(hotelBooking.id, 'roomType', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Number of Guests"
                type="number"
                value={hotelBooking.numberOfGuests}
                onChange={(e) => updateHotelBooking(hotelBooking.id, 'numberOfGuests', Number(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Price per Night"
                type="number"
                value={hotelBooking.pricePerNight}
                onChange={(e) => updateHotelBooking(hotelBooking.id, 'pricePerNight', Number(e.target.value))}
                fullWidth
              />
            </Grid>
          </Grid>
          {formData.hotelBookings.length > 1 && (
            <IconButton 
              onClick={() => removeHotelBooking(hotelBooking.id)}
              className="absolute top-2 right-2"
              color="error"
              size="small"
            >
              <Delete />
            </IconButton>
          )}
        </Paper>
      ))}
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={addHotelBooking}
        startIcon={<Add />}
        className="mt-4"
      >
        Add Hotel Booking
      </Button>
    </div>
  );
};

export default HotelBookingForm;

