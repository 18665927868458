import React, { FC } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Tippy from "@tippyjs/react";

export interface CheckboxProps {
  label?: any;
  subLabel?: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  checked?: boolean;
  name: string;
  error?: string;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  tooltip?: string;
  extraLabel?: String;
  type?: "checkbox" | "radio";
}

const Checkbox: FC<CheckboxProps> = ({
  subLabel = "",
  label = "",
  name,
  error = "",
  className = "",
  inputClassName = "",
  labelClassName = "",
  checked,
  defaultChecked,
  tooltip = "",
  extraLabel = "",
  onChange,
  type = "checkbox",
}) => {
  return (
    <div className={`text-sm sm:text-base flex ${className}`}>
      <input
        id={name}
        name={name}
        type={type}
        checked={checked}
        className={
          "focus:ring-action-primary mt-1 h-5 w-5 rounded border-neutral-500 text-primary-500 focus:ring-transparent dark:bg-neutral-700 dark:checked:bg-primary-500 " +
          inputClassName +
          (error
            ? " border-red-500 text-red-500 focus:ring-red-500 dark:bg-red-700 dark:checked:bg-red-500 "
            : "")
        }
        defaultChecked={defaultChecked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      {label && (
        <label
          htmlFor={name}
          className={`ml-2 mb-1  mt-1 cursor-pointer ${
            extraLabel ? "justify-between" : "flex-col justify-center"
          } `}
        >
          <span
            className={
              error
                ? " text-red-900 dark:text-red-100 "
                : " text-neutral-900 dark:text-neutral-100 " + labelClassName
            }
          >
            {label}
            {tooltip && (
              <Tippy placement="bottom" content={<div>{tooltip}</div>}>
                <span>
                  <FaInfoCircle className="ml-1 inline-block cursor-pointer " />
                </span>
              </Tippy>
            )}
          </span>
          <span className="text-sm text-gray-500">{extraLabel}</span>
          {subLabel && (
            <p className="text-sm mt-1 font-light text-neutral-500 dark:text-neutral-400">
              {subLabel}
            </p>
          )}
        </label>
      )}
      {error && (
        <small className="text-xs text-red-400 dark:text-red-200">
          {error}
        </small>
      )}
    </div>
  );
};

export default Checkbox;
