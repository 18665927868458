import { useState, useCallback, useEffect } from 'react';

export function useLocalStorageValue<T extends string>({
  key,
  defaultValue = undefined,
  json = false
}: {
  key: string;
  defaultValue?: T;
  json?: boolean;
}) {
  const [value, setValue] = useState<T>(
    typeof window !== 'undefined' && 'localStorage' in window
      ? (window.localStorage.getItem(key) as T)
      : ((defaultValue ?? '') as T)
  );

  const setLocalStorageValue = useCallback(
    (val: T | ((prevState: T) => T)) => {
      if (typeof val === 'function') {
        setValue((current) => {
          let result = val(current);
          if (json) {
            result = JSON.stringify(result) as T
          }
          window.localStorage.setItem(key, result);
          return result;
        });
      } else {
        window.localStorage.setItem(key, val);
        setValue(val);
      }
    },
    [key]
  );


  useEffect(() => {
    function listener(event: StorageEvent) {
      if (event.storageArea === window.localStorage && event.key === key) {
        setValue(event.newValue as T);
      }
    }

    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, []);

  useEffect(() => {
    if (defaultValue && !value) {
      setLocalStorageValue(defaultValue);
    }
  }, [defaultValue, value, setLocalStorageValue]);

  return [value || defaultValue, setLocalStorageValue] as const;
}