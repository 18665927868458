import { createSlice } from "@reduxjs/toolkit";
import { SearchTab } from "api/types";

interface TabsSelection {
    activeTab: SearchTab;
    currentTab: SearchTab;
}

const initialState = {
    activeTab: "Flights",
    currentTab: "Flights"
} as TabsSelection;

const tabsSelection = createSlice({
    name: "tabsSelection",
    initialState,
    reducers: {
        SetTabSelections(state, action) {
            if(action.payload["activeTab"]) {
                state.activeTab = action.payload["activeTab"];
            } else if(action.payload["currentTab"]) {
                state.currentTab = action.payload["currentTab"];
            } else {
                state.activeTab = action.payload;
            }
        }
    }
})

export const { SetTabSelections } = tabsSelection.actions;
export default tabsSelection.reducer;
