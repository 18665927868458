export function getDateTime(dateString: string, type: 'date' | 'hour'): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return 'Invalid date string';
    }

    if (type === 'date') {
        // Return the full date in dd.mm.yyyy format
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
    } else if (type === 'hour') {
        // Return the hour in hh:mm format
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
        return `${hour}:${minute}`;
    } else {
        // This case should never occur because of type restrictions
        return 'Invalid type. Please specify "date" or "hour".';
    }
}

// Example usage:
// console.log(getDateTime('2024-07-18T06:40:00', 'date')); // Outputs: 18.07.2024
// console.log(getDateTime('2024-07-18T06:40:00', 'hour')); // Outputs: 06:40
