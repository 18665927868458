import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { getPickupLocationAutocompleteUrl } from "api/helpers/api-constants";
import { Virtuoso } from "react-virtuoso";
import { SearchTab } from "api/types";
import { useTranslation } from "react-i18next";
import fetcher from "api/helpers/fetcher";
import HotelIcon from '@mui/icons-material/Hotel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
export interface IAutocompleteTransfer
{
  id: string
  internal:boolean
  name: string
  subLocationId:string
  type:string
}

export interface AutocompleteInputProps<T> {
  queryType?: SearchTab;
  localStorageToken: string;
  autocompleteValue: IAutocompleteTransfer | null;
  getUrl: (term: string) => string;
  setAutocompleteValue: React.Dispatch<React.SetStateAction<IAutocompleteTransfer | null>>;
  Icon?: React.ReactNode & React.FC<React.ComponentProps<"svg">>;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  onInputDone?: () => void;
  error?: boolean;
  setNextFocus?: Dispatch<SetStateAction<boolean>>;
}

function AutocompleteInputForTransfer2({
  autoFocus = false,
  autocompleteValue,
  localStorageToken,
  setAutocompleteValue,
  Icon = LocationMarkerIcon,
  placeHolder = "Airport",
  desc = "Leaving from",
  className = "nc-flex-1.5",
  onInputDone = () => {},
  error = false,
  setNextFocus = () => {},
}: any) {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPopover, setShowPopover] = useState(autoFocus);

  const [query, setQuery] = React.useState("");
  const [allRes, setAllRes] = useState([]);

  const [result, setResult] = useState<any>([]);

  // const { recentSearches } = useRecentSearches<any>(localStorageToken);
  const { t } = useTranslation();
  async function getResults() {
    try {
      const url = getPickupLocationAutocompleteUrl();
  
      const res = await fetcher(url, { method: "GET" });
  
      if (res) {
        setResult(res);
        setAllRes(res); 
      }
    } catch (error) {
      console.log("error", error);
      alert("error occurred");
    }
  }

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }

    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
      setQuery("");
    }

    if (!showPopover) {
      if (!autocompleteValue) {
        setQuery("");
      } else {
        setQuery(autocompleteValue?.name || autocompleteValue?.label || "");
      }
    }
  }, [showPopover, autocompleteValue]);

  useMemo(() => {
    if (query && query.length > 2 && allRes.length > 0) {
      const filtered = allRes.filter((item: any) =>
        item?.name.toLowerCase().includes(query.toLowerCase())
      );
      setResult(filtered);
    } else {
      // setResult(allRes)
    }
  }, [query]);

  useEffect(() => {
    getResults();
  }, []);

  const handleSelectLocation = (item: any) => {
    setAutocompleteValue(item);
    onInputDone && onInputDone();
    setShowPopover(false);
    setNextFocus(true);
  };

  //const renderRecentSearches = () => {
  //return (
  {
    /*  <>
        <h3 className="mt-2 block px-4 text-base font-semibold text-neutral-800 dark:text-neutral-100 sm:mt-0 sm:px-8 sm:text-lg">
          {t("app.recent-searches")}
        </h3>
        <div className="mt-2">
          {recentSearches.length > 0 ? (
            recentSearches.map((recentSearch) => (
              <span
                onClick={() => handleSelectLocation(recentSearch)}
                key={recentSearch.id}
                className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
              >
                <span className="block text-neutral-400">
                  <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                </span>
                <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                  {recentSearch.name || recentSearch.label}
                </span>
              </span>
            ))
          ) : (
            <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
              {t("app.no-recent-searches")}
            </span>
          )}
        </div>
          </> */
  }
  // );
  //};

  const renderSearchValue = () => {
    function RenderRow({ index }: any) {
      return (
        <span
          onClick={() => handleSelectLocation(result[index])}
          key={result[index]?.id}
          className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
        >
          <span className="block text-neutral-400">
            {result[index].type === "City" &&   <LocationOnIcon className="h-4 w-4 sm:h-6 sm:w-6" />}
            {result[index].type === "Hotel" &&  <HotelIcon className="h-4 w-4 sm:h-6 sm:w-6"/>}
            
          
           
          </span>
          <span className="block font-medium text-neutral-700 dark:text-neutral-200">
            {result[index].name || result[index]?.label}
          </span>
        </span>
      );
    }
    return (
      <div className="absolute left-0 top-full z-40 max-h-96 w-full min-w-[280px] overflow-y-auto  border bg-white  py-3 shadow-sm dark:bg-neutral-800 sm:min-w-[240px] sm:py-6">
        {result?.length && result.length > 0 ? (
          <Virtuoso
            style={{ height: "300px" }}
            totalCount={result.length}
            itemContent={(index) => <RenderRow index={index} />}
          />
        ) : (
          <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
            {t("app.no-recent-searches")}
          </span>
        )}
      </div>
    );
  };

  const errorClassName = error ? "text-red-400" : "";

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none  ${
          showPopover ? "rounded-3xl  dark:bg-neutral-800" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <Icon className="nc-icon-field" />
        </div>
        <div className="flex-grow">
          <input
            className={`xl:text-lg block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200`}
            placeholder={placeHolder}
            value={query}
            autoFocus={showPopover}
            onChange={(e) => {
              setAutocompleteValue((prevAutocomplete: any) => prevAutocomplete);
              setQuery(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span
            className={
              "-mt-1 block text-sm font-medium text-neutral-400" +
              errorClassName
            }
          >
            {!!query ? placeHolder : desc}
          </span>
          {query && showPopover && (
            <ClearDataButton
              onClick={() => {
                setQuery("");
                setAutocompleteValue(null);
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="">
          {query ? (
            renderSearchValue()
          ) : (
            <span ref={containerRef} className="invisible">
              {t("b2c-app.generals.search")}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default AutocompleteInputForTransfer2;
