import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import ClearDataButton from "./ClearDataButton";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { Listbox } from "@headlessui/react";

export interface HeroSelectProps {
  selectOptions: Array<{ label: string; value: string }>;
  value: string | null;
  setValue: React.Dispatch<React.SetStateAction<string | null>>;
  Icon?: React.ReactNode & React.FC<React.ComponentProps<"svg">>;
  OptionIcon?: React.ReactNode & React.FC<React.ComponentProps<"svg">>;
  desc?: string;
  className?: string;
  clearDataButton?: boolean;
  error?: boolean;
  ref?: HTMLButtonElement | null;
  focus?: boolean;
  setFocus?: (val: boolean) => void;
  nextFocus?: boolean;
  setNextFocus?: Dispatch<SetStateAction<boolean>>;
  isHorizontal?: boolean;
}

function HeroSelect({
  selectOptions,
  value,
  setValue,
  Icon = LocationMarkerIcon,
  OptionIcon,
  desc,
  className = "",
  clearDataButton = false,
  error = false,
  focus,
  setFocus,
  nextFocus,
  setNextFocus,
  isHorizontal,
}: HeroSelectProps) {
  const itemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focus && setFocus) {
      itemRef.current?.click();
      setFocus(false);
    }
  }, [focus]);

  const errorClassName = error ? "text-red-400" : "";

  return (
    <div className={`relative flex ${className}`}>
      <Listbox
        value={value}
        onChange={(e) => {
          setValue(e);
          if (setNextFocus) {
            setNextFocus(true);
          }
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Button
              className={`relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none  ${
                open ? "rounded dark:bg-neutral-800" : ""
              }`}
              ref={itemRef}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <Icon className="nc-icon-field" />
              </div>
              <div
                className={` ${
                  isHorizontal ? "d-flex mt-1 py-2" : "flex-grow"
                }`}
              >
                <div
                  className={`xl:text-lg mr-1 mt-1 block w-full truncate border-none bg-transparent p-0 font-bold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200`}
                >
                  {value}
                </div>

                {desc && (
                  <span
                    className={
                      `text-sm  ${
                        isHorizontal ? "mt-2 pt-[3px]" : "-mt-0.5"
                      }   block font-light text-neutral-400 ` + errorClassName
                    }
                  >
                    <span className="block text-sm font-medium leading-none text-neutral-400 line-clamp-1">
                      {desc}
                    </span>
                  </span>
                )}

                {clearDataButton && value && open && (
                  <ClearDataButton
                    onClick={() => {
                      setValue(null);
                    }}
                  />
                )}
              </div>
            </Listbox.Button>

            <Listbox.Options className="absolute top-full z-40 max-h-96 w-full  overflow-y-auto bg-white py-3 shadow-sm dark:bg-neutral-800 sm:py-6">
              {selectOptions.map((selectOption, i) => (
                <Listbox.Option
                  key={i}
                  value={selectOption.value}
                  className="cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
                >
                  {OptionIcon && (
                    <span className="block text-neutral-400">
                      <OptionIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                    </span>
                  )}
                  <span className="block text-center text-sm font-medium text-neutral-700 dark:text-neutral-200">
                    {selectOption.label}
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
}

export default HeroSelect;
