import {
  MegamenuItem,
  NavItemType,
} from "components/ui/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";
import {
  getAgencyAdminManageUserUrl,
  getAgencyAdminSettingsUrl,
  getAgencyInsuranceUrl,
} from "../api/helpers/api-constants";
import { ReturnOldPage } from "utils/navigationLinks";

const moreLinks: NavItemType[] = [

  {
    id: ncNanoId(),
    href: getAgencyAdminManageUserUrl(),
    targetBlank: true,
    targetOldVersion: true,
    name: "User",
    i18nextKey: "navbar.user"
  },
 
  {
    id: ncNanoId(),
    href: '/insurance',
    targetBlank: false,
    targetOldVersion: false,
    name: "Versicherung",
    i18nextKey: "navbar.insurance"
  },
  {
    id: ncNanoId(),
    href: getAgencyAdminSettingsUrl(),
    targetBlank: true,
    targetOldVersion: true,
    name: "Einstellungen",
    i18nextKey: "navbar.settings"
  },
  {
    id: ncNanoId(),
    href: "/invoice",
    targetBlank: false,
    targetOldVersion: false,
    name: "Invoice",
    i18nextKey: "navbar.invoice"
  },
  {
    id: ncNanoId(),
    href: "/booking-approval",
    targetBlank: false,
    targetOldVersion: false,
    name: "Booking Approval",
    i18nextKey: "generals.bookingapproval",
  },
  {
    id: ncNanoId(),
    href: "/salesreport",
    targetBlank: false,
    targetOldVersion: false,
    name: "Salesreport",
    i18nextKey: "navbar.salesreport"
  },
  {
    id: ncNanoId(),
    href: "/accounting/wallet",
    targetBlank: false,
    targetOldVersion: false,
    name: "Wallet",
    i18nextKey: "flightbooking.ticketing.wallet",
  },
];

export const NAVIGATION_WEB: NavItemType[] = [

  {
    id: ncNanoId(),
    href: "/",
    name: "Reise buchen",
    i18nextKey: "navbar.newbooking"
  },
  {
   id: ncNanoId(),
   href: "/bookings",
   name: "Buchungen",
   i18nextKey: "navbar.bookings"
  },
  {
    id: ncNanoId(),
    href: "/dashboard",
    name: "Dashboard",
    i18nextKey: "navbar.dashboard"
   },
  {
    id: ncNanoId(),
    href: "?",
    name: "Weitere Links",
    children: moreLinks,
    type: "dropdown",
    i18nextKey: "navbar.morelinks"
  }
];
export const NAVIGATION_MOBILE: NavItemType[] = [
  {
    id: ncNanoId(),
    href:  ReturnOldPage+"#/",
    isNewUrl: true,
    name: "Zur alten Suchmaske",
    i18nextKey: "react.general.return-to-old-search-form"
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Reise buchen",
    i18nextKey: "navbar.newbooking"
  },
  {
   id: ncNanoId(),
   href: "/bookings",
   name: "Buchungen",
   i18nextKey: "navbar.bookings"
  },
  {
    id: ncNanoId(),
    href: "?",
    name: "Weitere Links",
    children: moreLinks,
    type: "dropdown",
    i18nextKey: "navbar.morelinks"
  }
];

export const NAVIGATION_APP: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/app/dashboard",
    name: "Home",
    i18nextKey: "navbar.home"
  },
  {
    id: ncNanoId(),
    href: "/app/Flight",
    name: "Flights",
    i18nextKey: "react.searchmask.tab.flight"
  },
  {
    id: ncNanoId(),
    href: "/app/Car Rental",
    name: "Rentalcar",
    i18nextKey: "react.searchmask.tab.rentacar"
  },
  {
    id: ncNanoId(),
    href: "/app/Hotel",
    name: "Hotel",
    i18nextKey: "react.searchmask.tab.hotel"
  },
  {
    id: ncNanoId(),
    href: "/app/bookings",
    name: "Bookings",
    i18nextKey: "navbar.bookings"
  }
  // {
  //   id: ncNanoId(),
  //   href: "/bookings?a=1",
  //   isNewUrl: true,
  //   name: "Buchungen",
  //   i18nextKey: "navbar.bookings"
  // }
]
