import React, {FC, useEffect, useState} from "react"
import {Dialog} from "primereact/dialog";
import {useTranslation} from "react-i18next";
import { InputText } from "primereact/inputtext";
import {CustomButton} from "../CustomButton";
import { ListBox } from "primereact/listbox";
import 'primeicons/primeicons.css';

interface IMobileCustomCalendarAgeSelect {
    
    age: string | null;
  setAge: React.Dispatch<React.SetStateAction<string | null>>;
}

export const MobileCustomAgeSelect: FC<IMobileCustomCalendarAgeSelect> = (
    {
     age,
     setAge,
    }) => {

    const [ openModal, setOpenModal ] = useState<boolean>(false)
    const { t } = useTranslation()
    const openModalHandler = (newValue: boolean = false) => {
        setOpenModal(!openModal)
    }
    const selectDateHandler = () => {
        openModalHandler(true)
        setAge(age)   
    }

    const ageSelectOptions = new Array(63).fill(null).map((_, i) => ({
        value: String(i + 18),
        label: String(i + 18) ,
      }));

    const dialogFooter = () => {
        return (
            <>
                <CustomButton
                    value={t('b2c-app.generals.select')}
                    clickeHandler={selectDateHandler}
                />
            </>
        )
    }

    return (
        <>
        <span className="p-input-icon-left w-full">
    <i className="pi pi-user" />

            <InputText
                className={`text-sm w-full mt-1 pl-10 rounded focus:outline-0 focus:border-none focus:outline-offset-0 border-neutral-200 bg-gray-200 dark:border-neutral-700 dark:bg-neutral-900 cursor-pointer`}
                type={'text'}
                readOnly
                value={age ? age + ' ' + t("react.hotel.age-years-old")  :''}
                placeholder={t('sales-report.date-range')}
                onChange={(e) => setAge(e.target.value)}
                onClick={() => openModalHandler(false)}
            />
</span>
            <Dialog
                className={'atr-datepicker'}
                header={t('rentalcar.driver-age')}
                footer={dialogFooter}
                visible={openModal}
                position={'bottom'}
                style={{ height: '70%', width: '100%', margin: 0 }}
                onHide={openModalHandler}
                draggable={false}
                resizable={false}
            >
                <div className="flex justify-center">
                <ListBox value={age} onChange={(e) => setAge(e.value)} options={ageSelectOptions} optionLabel="label" className="w-full flex justify-center" />
                </div>
             
               
            </Dialog>
        </>
    );
}
