import React, {FC, useEffect, useState} from "react"
import {IAgentInvoiceList, IDateRange} from "../../api/types";
import postFetcher from "../../api/helpers/postFetcher";
import {getAgentInvoiceListUrl} from "../../api/helpers/api-constants";
import {InvoiceTable} from "./InvoiceTable";
import Pagination from "../../components/ui/Pagination/Pagination";
import moment from "moment";
import {usePagination} from "react-use-pagination";
import {InvoiceFilter} from "./InvoiceFilter";
import {useTranslation} from "react-i18next";
import {TransactionTable} from "./TransactionTable";

export const InvoicePage: FC = () => {
    const {t} = useTranslation();

    const [loadData, setLoadData] = useState<boolean>(true);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [data, setData] = useState<IAgentInvoiceList[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [searchInvoiceNo, setSearchInvoiceNo] = useState<string>("");
    const [searchBookingNo, setSearchBookingNo] = useState<string>("");
    const [dateRange, setDateRange] = useState<IDateRange>({startDate: moment().subtract(3, 'weeks'), endDate: moment()});
    const [selectedBookingType, setSelectedBookingType ] = useState<string>("0")

    const PAGE_SIZE: number = 10;

    const bookingTypes = [
        t('react.tourpackage.filter.all'),
        t('react.searchmask.tab.flight'),
        t('react.searchmask.tab.hotel'),
        t('react.searchmask.tab.flightsandhotel'),
        t('homepage.products.product5.title'),
        t('react.searchmask.tab.rentacar'),
        t('react.searchmask.tab.transfer'),
        t('navbar.insurance'),
        t('general.only-avis')
    ]

    const {
        startIndex,
        endIndex,
        currentPage,
        setPage,
        setPreviousPage,
        setNextPage,
        previousEnabled,
        nextEnabled,
    } = usePagination({
        totalItems: rowCount,
        initialPageSize: PAGE_SIZE,
    });

    useEffect(() => {
        if (!loadData)
            fetchData();
    }, [loadData])

    useEffect(() => {
        updateLoadData();
    }, [currentPage])

    useEffect(() => {
        updateLoadData()
    }, [searchInvoiceNo, searchBookingNo, selectedBookingType])

    useEffect(() => {
        if (dateRange.startDate !== null && dateRange.endDate !== null) {
            updateLoadData();
            setPage(0);
        }
    }, [dateRange])

    const fetchData = async () => {
        setShowErrorAlert(false);

        const agentInvoiceListRequest = {
            pageNumber: currentPage+1,
            pageSize: PAGE_SIZE,
            invoiceDateBegin: dateRange.startDate?.format("DD.MM.YYYY"),
            invoiceDateEnd: dateRange.endDate?.format("DD.MM.YYYY"),
            pnr: searchBookingNo,
            invoiceNo: searchInvoiceNo,
            bookingTypeId: Number.parseInt(selectedBookingType)
        }
        const response = await postFetcher(getAgentInvoiceListUrl(), agentInvoiceListRequest);
        if(response.status === 200) {
            setData(response.Invoices);
            setRowCount(response.RowCount);
        } else {
            setShowErrorAlert(true);
        }
        updateLoadData();
    }

    const updateLoadData = () => {
        setLoadData(!loadData);
    }

    return (
        <main className="container pt-5 pb-5">
            <div className="flex w-full justify-between items-center">
                <h1 className="mb-3 text-lg font-semibold">{t("navbar.billing")}</h1>

                <InvoiceFilter
                    dateRange={dateRange}
                    withPartnerCodeFilter={false}
                    withSearchField={true}
                    searchInvoiceNo={searchInvoiceNo}
                    searchBookingNo={searchBookingNo}
                    searchInvoiceNoLabel={t('general.invoice.number')}
                    searchBookingNoLabel={t('pdf.bookingnumber')}
                    selectedBookingType={selectedBookingType}
                    bookingTypes={bookingTypes}
                    setSearchInvoiceNo={((value) => setSearchInvoiceNo(value))}
                    setSearchBookingNo={((value) => setSearchBookingNo(value))}
                    setDateRange={((value) => setDateRange(value))}
                    setSelectedBookingType={((type) => setSelectedBookingType(type))}
                />
            </div>

            <>
                <InvoiceTable
                    data={data}
                    showLinearBar={!loadData}
                    showErrorAlert={showErrorAlert}
                    bookingTypes={bookingTypes}
                />
                {
                    !showErrorAlert && (
                        <div className="mt-5 flex items-center justify-center">
                            <Pagination
                                currentPage={currentPage}
                                setPage={setPage}
                                totalPages={Math.ceil(rowCount/PAGE_SIZE)}
                                setPreviousPage={setPreviousPage}
                                setNextPage={setNextPage}
                                idToScrollOnPageChange="heading-2"
                                nextEnabled={nextEnabled}
                                previousEnabled={previousEnabled}
                            />
                        </div>
                    )
                }
            </>

            <TransactionTable
                dateRange={dateRange}
            />

        </main>
    )
}
