import fetcher from "./fetcher";
import { buildUrl } from "./api-constants";

const postFetcher = async (url: string, payload: object) => {
    const requestOptions = {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(payload),
    };

    return await fetcher(url, requestOptions)
}
export default postFetcher;

export const postParamRequest = async (url: string, param: object) => {
    const requestOptions = {
        method: "POST"
    }
    return await fetcher(buildUrl(url, param), requestOptions);
}
