import React, {FC} from 'react'
import {ReservationDetailsRoom} from "../../../api/types";
import {useTranslation} from "react-i18next";

interface IHotelGuestInformation {
    rooms: ReservationDetailsRoom[]
}

export const HotelGuestInformation: FC<IHotelGuestInformation> = ({rooms}) => {
    const { t } = useTranslation()

    return (
        <div className={`my-3 mx-2 mb-3 px-3 pb-3 pt-3 shadow-md bg-white rounded`}>
            {rooms.map((room, index) => (
                <div key={`room-${index}`} className={`py-2`}>
                    <div className={`text-xl font-semibold`}>{t('react.hotel.rooms')} {index+1}</div>
                </div>
            ))}
        </div>
    )
}
