import { connect } from "react-redux";
import React from "react";
import $ from "jquery";
import superagent from "superagent";
import fetch from "cross-fetch";

export function getHostForDebugging() {
  return "http://localhost:8080";
}

export function getHost() {
  if (
    window.location.origin.includes(":8080") ||
    window.location.origin.includes("elasticbeanstalk.com")
  )
    return window.location.origin;

  if (window.location.origin.includes(":3000"))
    return "https://www.atrtouristik.com";

  if (window.location.origin.includes("cloud.atrtouristik.com"))
    return "https://cloud.atrtouristik.com";

  return "https://www.atrtouristik.com";
}

export function isB2C() {
  return window.hasOwnProperty("b2cApiKey") && window.b2cApiKey.length > 10;
}

export function getApiKeyParams() {
  return "";
}

let searchId = null;
export function setSearchId(newSearchid) {
  searchId = newSearchid;
}
export function getSearchId() {
  return searchId;
}

export function flightState(state = [], action) {
  if (action.type.hasOwnProperty("searchParameter")) {
    state = action.type;
  }

  return state;
}

let globalStore;
export function setGlobalStore(store) {
  globalStore = store;
}

export function getGlobalStore() {
  return globalStore;
}

export function i18n(key) {
  return window.i18n[key];
}

export async function getAirportInformation(term) {
  const response = await fetch(
    getHost() +
      "/getAirportsByKeyword?term=" +
      term +
      "&_type=query&q=" +
      term +
      "&" +
      getApiKeyParams()
  );
  const countries = await response.json();

  return countries.results[0];
}

export async function loadDestinationsForPickupLocation(pickupLocationId) {
  let body = { pickupLocationId: pickupLocationId };
  await superagent
    .get(
      getHost() +
        "/api/transferSearch/getDestinationsForPickupLocation?" +
        getApiKeyParams()
    )
    .query(body)
    .withCredentials()
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .then((res, err) => {
      if (res.body.length > 0) {
        setTransferDestinationLocations(res.body);
      }
    });
}

let transferDestinationLocations = [];
export function setTransferDestinationLocations(locations) {
  transferDestinationLocations = locations;
}
export function getTransferDestinationLocations() {
  return transferDestinationLocations;
}

export async function loadTransferLocations() {
  const res = await superagent
    .get(
      getHost() +
        "/api/transferSearch/getAllPickupLocations?" +
        getApiKeyParams()
    )
    .withCredentials()
    .set("Accept", "application/json")
    .set("Content-Type", "application/json");

  if (res.status === 200) {
    if (JSON.parse(res.text).length > 0) {
      setTransferLocations(JSON.parse(res.text));
    }
  }
}

let transferLocations = [];
export function setTransferLocations(locations) {
  transferLocations = locations;
}
export function getTransferLocations() {
  if (transferLocations.length === 0) {
    (async () => {
      await loadTransferLocations();
    })();
  }

  return transferLocations;
}

export async function loadRentalCarLocations() {
  await superagent
    .get(getHost() + "/api/rentalcar/search/get-locations?" + getApiKeyParams())
    .withCredentials()
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .then((res, err) => {
      if (res.body.length > 0) {
        setRentalCarLocations(res.body);
      }
    });
}

let rentalcarLocations = [];
export function setRentalCarLocations(locations) {
  rentalcarLocations = locations;
}
export function getRentalCarLocations() {
  return rentalcarLocations;
}

export function checkIsAdmin() {
  let isAdmin = false;

  if (typeof $("#root").attr("data-role") !== "undefined") {
    isAdmin = $("#root").attr("data-role") === "true";
  }

  return isAdmin;
}

export function getAgencyNumber() {
  let agencyNumber = "8028";

  if (typeof $("#root").attr("data-agemcy") !== "undefined") {
    agencyNumber = $("#root").attr("data-agemcy");
  }

  return agencyNumber;
}

export function checkElementOnScreen(ref) {
  if (
    typeof ref !== "undefined" &&
    ref.current !== null &&
    ref.current.getBoundingClientRect().top + ref.current.offsetHeight <=
      window.innerHeight
  )
    return true;

  return false;
}

export function checkLoadingFurtherFlightsOnScreen() {
  let elem = document.getElementById("loadfurtherflights");
  if (elem !== null && elem.getBoundingClientRect().y < window.innerHeight)
    return true;

  return false;
}

export function checkIsEmployee() {
  let isEmployee = false;

  if (typeof $("#root").attr("data-empl") !== "undefined") {
    isEmployee = $("#root").attr("data-empl") === "true";
  }

  return isEmployee;
}

export function validationDateValue(date) {
  let date_regex = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.([0-9]{4})$/;
  if (date_regex.test(date)) {
    return true;
  } else {
    return false;
  }
}

export function checkAge(type, birthdate, flightdate) {
  if (type.includes("chd")) {
    let maxDate = new Date(
      flightdate.setFullYear(new Date().getFullYear() - 12)
    );
    //let minDate = new Date( flightdate.setFullYear( new Date().getFullYear() - 2 ) );

    if (maxDate > birthdate) {
      return false;
    }
  } else if (type.includes("inf")) {
    let maxDate = new Date(
      flightdate.setFullYear(new Date().getFullYear() - 2)
    );

    if (maxDate > birthdate) {
      return false;
    }
  }

  return true;
}

export function checkAgeIsLeastMinAge(minAge, birthdate) {
  if (typeof birthdate !== "string") {
    let today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    let m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
    return age >= minAge;
  }

  return true;
}

export function scrollTillOfferIsVisible(element, offerId) {
  if ($("#flight-" + offerId).length > 0) {
    $(element).animate(
      {
        scrollTop: $("#flight-" + offerId).position().top,
      },
      1000
    );
  }

  /*console.log( 'length: ' + !( $( 'div[id^=flight-]' + offerId ).length > 0 ) );
    if ( !$( '#flight-' + offerId ).length > 0 )
    {
        $( element ).animate({
            scrollTop: $( element + " div[id^=flight-]" ).last().position().top
        }, 500 );

        setTimeout( () =>
        {
            console.log( 'setTimeout' );
            scrollTillOfferIsVisible( element, offerId );
        }, 500 );
    }
    else
    {
        console.log( 'else' );
        $( element ).animate({
            scrollTop: $( "#flight-" + offerId ).position().top
        }, 2000 );

        return null;
    }*/
}

export const searchFlight = async (offerId) => {
  let isError = false;
  const res = await superagent
    .get(getHost() + "/api/flight/search/offer?" + getApiKeyParams())
    .query({ offerId: offerId })
    .withCredentials()
    .catch((err) => {
      isError = true;
    });

  if (isError) {
    return {};
  }

  return JSON.parse(res.text);
};

export function removeLeadingZero(phone) {
  if (phone.charAt(0) === "0") {
    return phone.substring(1);
  }

  return phone;
}

let b2cInitDepartureport = null;
export function getB2CinitDeparturePort() {
  return b2cInitDepartureport === null ? [] : b2cInitDepartureport;
}

let b2cAgencyName = null;
export function getB2CAgencyName() {
  return b2cAgencyName;
}

export async function loadB2AgencyInformation() {
  let b2cHost = { host: window.location.host };

  let response = await superagent
    .post(getHost() + "/api/flight/b2cagencyinformation?" + getApiKeyParams())
    .query(b2cHost)
    .withCredentials()
    .set("Accept", "application/json")
    .set("Content-Type", "application/json");

  if (response.status === 200) {
    let res = await JSON.parse(response.text);
    b2cAgencyName = res.agency;
    b2cInitDepartureport = res.hasOwnProperty("departureport")
      ? res.departureport
      : null;

    return res;
  }
}

let agencyInformation = null;
export async function loadB2BAgencyInformation() {
  const res = await superagent
    .get(getHost() + "/api/flight/checkproductpermissions?" + getApiKeyParams())
    .withCredentials();

  if (res.status === 200) {
    return JSON.parse(res.text);
  }

  return null;
}

export async function getAgencyInformation() {
  if (agencyInformation === null)
    agencyInformation = await loadB2BAgencyInformation();

  return agencyInformation;
}

export default connect(setGlobalStore, getGlobalStore, flightState);
