import React, { useEffect, useRef, useState } from 'react';
import InfoIcon from '@material-ui/icons/Info'; // Assuming you're using Material-UI for icons
import { useTranslation } from 'react-i18next';
import { formatServerDate } from 'utils/formatserverdate';
import currencyFormat from 'utils/currencyFormat';
import HistoryFlightCaseModal from 'components/ui/Modal/HistoryFlightCaseModal';
import CreateFlightCaseModal from 'components/ui/Modal/CreateFlightCaseModal';
import { Booking, BookingPassenger, INotificationModalScroll } from 'api/types';
import { getDateTime } from 'utils/getDateTime';
import fetcher from 'api/helpers/fetcher';
import { getFlightCaseUrl } from 'api/helpers/api-constants';

interface PaxData {
  id: string;
  firstname: string;
  surname: string;
  paxtype: string;
  birthdate: string;
  baseFare: number;
  totalAmount: number;
  ticketnumber: string;
}

interface data {
  bookingKey: string;
  status: string;
  totalPrice: number;
  serviceFeePerPax: number;
  user: {
    agency: {
      rescanPnrAllowed: boolean;
    };
  };
}

interface Audit {
  marketingType: string;
}

interface SectionPassengerProps {
  paxdata: BookingPassenger[];
  data: Booking;
  audit: Audit;
  bookingKey: string;
  status: string;

}

const SectionPassenger: React.FC<SectionPassengerProps> = ({
  paxdata,
  data,
  audit,
  bookingKey,
  status

}) => { 
  const caseAddBtnRef = useRef<HTMLButtonElement>(null);
  const caseHistoryBtnRef = useRef<HTMLButtonElement>(null);
  const [notifications, setnotifications] = useState<INotificationModalScroll[] | null >([]);
  const [openCreateFlightCaseModal, setOpenCreateFlightCaseModal] =
    useState<boolean>(false);
  const [openHistoryFlightCaseModal, setOpenHistoryFlightCaseModal] =
    useState<boolean>(false);
  const [
    openHistoryFlightLastCreatedCase,
    setOpenHistoryFlightLastCreatedCase,
  ] = useState<boolean>(false);
    const {t} = useTranslation()
    function formatNumber(num: number): string {
        if (Number.isInteger(num)) {
          return num.toLocaleString("en-US") + ",00";
        } else {
          return num.toLocaleString("en-US");
        }
      }
      const fetchData = async () => {
        const _notifications = await fetcher(getFlightCaseUrl(bookingKey));
        setnotifications(_notifications);
    }
    useEffect(() => {
        fetchData()
    }, [bookingKey]);
    function showTooltip(data: any) {
        const tooltip = document.getElementById(data);
        if (tooltip) {
          tooltip.classList.remove("opacity-0", "invisible");
          tooltip.classList.add("opacity-100");
        }
      }
  
      function hideTooltip(data: any) {
        const tooltip = document.getElementById(data);
        if (tooltip) {
          tooltip.classList.remove("opacity-100");
          tooltip.classList.add("opacity-0", "invisible");
        }
      }
  
      return (
        <div className=" rounded bg-white shadow-sm border mx-2">
          <div className="mb-1 gap-2 px-6 py-2 flex ">
            <h3 className="text-lg font-bold ">
              <i className="fa fa-users" aria-hidden="true"></i>{" "}
              {t("flightbooking.passengers")}
            </h3>
          </div>
          <div className="table-responsive  mb-1  px-4">
            <table className="table-borderless table  w-full">
              <thead>
                <tr className={"text-base border-t border-b font-semibold"}>
                  <th className="px-2 py-2">{t("flights.firstname")}</th>
                  <th className="px-2 py-2">{t("flights.surname")}</th>
                  <th className="px-2 py-2">{t("flightbooking.type")}</th>
                  <th className="px-2 py-2">
                    {t("app.flight-passengers-form.birthdate")}
                  </th>
                  {status === "TICKETED" ? (
                    <th className="px-2 py-2">
                      {t("flightbooking.ticket-number")}
                    </th>
                  ) : null}
                  <th className="px-2 py-2">{t("flightbooking.fare")}</th>
                  <th className="px-2 py-2">{t("flightbooking.tax")}</th>
                  <th className="px-2 py-2">{t("app.booking.total-price")}</th>
                </tr>
              </thead>
              {paxdata.map((row) => (
                <tbody key={row.id}>
                  <tr className="text-base border-t border-b hover:bg-gray-100">
                    <td className=" px-2 py-2">{row.firstname}</td>
                    <td className=" px-2 py-2">{row.surname} </td>
                    <td className=" px-2 py-2">{row.paxtype}</td>
                    <td className=" px-2 py-2 " colSpan={1}>
                       {row.birthdate ?  getDateTime(row.birthdate,'date') :""}
                    </td>
                    {status === "TICKETED" ? (
                      <td
                        className=" px-2 py-2"
                        dangerouslySetInnerHTML={{ __html: row.ticketnumber as String }}
                      ></td>
                    ) : null}
                    <td className=" px-2 py-2">{formatNumber(row.baseFare)} €</td>
                    <td className="  py-2">
                      {formatNumber(row.totalAmount - row.baseFare)} €
                    </td>
                    
                    <td className="  py-2"colSpan={2}>
                      {formatNumber(row.totalAmount + data.pricing.serviceFeePerPax)} €
                    </td>
                  </tr>
                </tbody>
              ))}
              <tr className="border-0 hover:bg-gray-100 table-sm">
                <td className="border-0 px-4 " colSpan={4}></td>
                {status === "TICKETED" ? (
                  <td className="border-0 px-4  "></td>
                ) : null}
                <td className=" border-0 px-4 ">
                  <div className="min-w-full  gap-2 px-4 sm:flex sm:justify-center">
                    <div
                      className="inline-flex rounded-md shadow-sm"
                      role="group"
                    >
                      <div
                        id={"price"}
                        className="tooltip invisible -translate-x-1/2 transform rounded bg-blue-300  p-1 text-xs text-gray-900 opacity-0 transition duration-700"
                      >
                        <span>
                          {currencyFormat(data.pricing.totalAmount -(data.pricing.serviceFeePerPax * paxdata.length))}+{" "}
                          {currencyFormat(
                            data.pricing.serviceFeePerPax * paxdata.length
                          )}{" "}
                          {"(Service Charge)"}={" "}
                          {currencyFormat(
                            data.pricing.totalAmount
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td className=" border-0 px-4  "></td>
  
                <td className="h-4 w-4 border-0  " colSpan={2}>
                  {currencyFormat(
                    data.pricing.totalAmount 
                  )}
               {audit.marketingType === "B2B" &&   <button
                    onMouseOver={(e) => showTooltip("price")}
                    onMouseOut={(e) => hideTooltip("price")}
                  >
                    <div className=" h-2 w-2 items-center mr-2 justify-center">
                      <InfoIcon fontSize="small" />
                    </div>
                  </button>}
                </td>
              </tr>
            </table>
          </div>
       {audit.marketingType === "B2B"  &&  <div className="pb-2">
            <button
              ref={caseAddBtnRef}
              type="button"
              className="text-sm inline-flex w-full justify-center border rounded bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm hover:shadow-lg hover:text-black sm:ml-3 sm:w-auto"
              onClick={() => setOpenCreateFlightCaseModal(true)}
            >
              {t("flightcases.new-case")}
            </button>
            <button
              ref={caseHistoryBtnRef}
              type="button"
              className="text-sm inline-flex w-full justify-center border rounded bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm hover:shadow-lg hover:text-black sm:ml-3 sm:w-auto"
              onClick={() => setOpenHistoryFlightCaseModal(true)}
            >
              Case History
            </button>
  
            {bookingKey !== undefined && (
              <div>
                <CreateFlightCaseModal
                  bookingKey={bookingKey}
                  open={openCreateFlightCaseModal}
                  closeModal={() => setOpenCreateFlightCaseModal(false)}
                  addCaseSuccess={() => {
                    setOpenCreateFlightCaseModal(false);
  
                    if (caseHistoryBtnRef.current) {
                      caseHistoryBtnRef.current.focus();
                      setOpenHistoryFlightCaseModal(true);
                      setOpenHistoryFlightLastCreatedCase(true);
                    }
                  }}
                />
  
                <HistoryFlightCaseModal
                  bookingKey={bookingKey}
                  open={openHistoryFlightCaseModal}
                  openLastCreatedCase={openHistoryFlightLastCreatedCase}
                  historyFlightCaseHandler={(value: boolean) => {
                    setOpenHistoryFlightCaseModal(value);
                  }}
                  closeOpenLastCreatedCase={() =>
                    setOpenHistoryFlightLastCreatedCase(false)
                  }
                  notifications={notifications}
                  setNotifications={setnotifications}
                />
              </div>
            )}
          </div>}
        </div>
      );
};

export default SectionPassenger;
