
import { useTranslation } from "react-i18next";



export interface TourDetailResponse {
  
tourName: string;
  location: string;
  startDate: string;
  endDate: string;
  status: string;}
    
   
interface TourDetailProps{
    tourDetail:TourDetailResponse
}
const TourDetail: React.FC<TourDetailProps> = ({ tourDetail }) => {
  const {t} = useTranslation()
   
    return(
<>
<div className="  bg-white shadow-sm border rounded-lg mx-2 my-4">
    <div className="flex justify-end">
    <span className={`flex items-center rounded-lg px-3 h-7 text-md text-gray-900
                            ${tourDetail.status === "TICKETED" ? "bg-green-400" : ""}
                            ${tourDetail.status === "RESERVATION" ? "bg-blue-400" : ""}
                            ${tourDetail.status === "CANCELED" ? "bg-red-400" : ""}
                            ${tourDetail.status === "VOIDED" ? "bg-red-400" : ""}
                            ${tourDetail.status === "REFUNDED" ? "bg-gray-400" : ""}
                            ${tourDetail.status === "EXPIRED" ? "bg-red-400" : ""}

`} >
    {(() => {
  switch (tourDetail?.status) {
    case "RESERVATION":
      return <>{t("b2c-app.profile.bookings.status.reservation")}</>;
    case "TICKETED":
      return <>{t("flightbooking.ticketed")}</>;
    case "CANCELED":
      return <>{t("flightbooking.canceled")}</>;
    case "VOIDED":
      return <>Voided Kayit</>;
    case "REFUNDED":
      return <>{t("flightbooking.refunded")}</>;
    case "EXPIRED":
      return <>{t("flightbooking.expired")}</>;
    default:
      return null;
  }
})()}


        </span>
</div>
    
      <div className="bg-white shadow-md rounded-lg pt-2 px-4 w-full ">
        <h1 className="text-2xl font-bold mb-4">{tourDetail.tourName}</h1>
        <p className=" border-b p-0.5">  {tourDetail.location}
        </p>
        <div className="flex justify-between py-4">
          <span className="font-semibold">{t("generals.start-date")}</span>
          {new Date(tourDetail.startDate).toLocaleDateString('en-GB')}
          <span className="font-semibold">{t("generals.end-date")} </span>
          {new Date(tourDetail.endDate).toLocaleDateString('en-GB')}
        </div>
      </div>
    </div>
        </>
      
    )
}

export default TourDetail