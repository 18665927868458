import { SearchPayload } from "api/types";
import TransferQueryParametersService from "services/TransferQueryParametersService";
import { URLSearchParams } from "url";
import create from "zustand";

interface TransferPageStore {
  setSearchPayload: (parameteres: URLSearchParams | null) => void;
  searchPayload: SearchPayload | null;
}

const useTransferPageStore = create<TransferPageStore>((set) => ({
  setSearchPayload: (
    paremeteres
  ) => {
    set({
      searchPayload: TransferQueryParametersService.decode(
        paremeteres as URLSearchParams
      ),
    });
  },
  searchPayload: null
}));

export default useTransferPageStore;
