import { SearchPayload } from "api/types";
import RoomOccupanciesService from "services/RoomOccupanciesService";

export const ReturnOldPage: string = "https://www.atrtouristik.com/agency/create-new-reservation"

export function getHotelPageUrl(
  hotelId: string | number,
  queryParameters?: SearchPayload
) {
  if (!queryParameters) return `/hotel/${hotelId}`;

  const { totalGuests, flightSearch, ...rest } = queryParameters;

  //@ts-ignore
  const urlSearchParams = new URLSearchParams({
    ...rest,
    destinationType: "HOTEL",
    destinationId: String(hotelId),
    roomOccupancies: RoomOccupanciesService.stringifyRoomOccupancies(
      queryParameters.roomOccupancies!
    ),
  });

  if (queryParameters?.searchType === "Hotel & Flights") {
    urlSearchParams.set(
      "arrivalAirport",
      flightSearch?.originDestinationInformation[0].origin!
    );
    urlSearchParams.set(
      "arrivalText",
      flightSearch?.originDestinationInformation[0].originText!
    );
    urlSearchParams.set(
      "returningDate",
      flightSearch?.originDestinationInformation[1].date!
    );
    urlSearchParams.set(
      "departingDate",
      flightSearch?.originDestinationInformation[0].date!
    );
  }

  return `/hotel/${hotelId}?${urlSearchParams.toString()}`;
}

export function getTourSearchPageUrl(
  tourId: string | number,
  queryParameters?: SearchPayload
) {
  if (!queryParameters) return `/tour-search`;

  const { totalGuests, flightSearch, ...rest } = queryParameters;

  //@ts-ignore
  const urlSearchParams = new URLSearchParams({
    ...rest,
    destinationType: "TOUR",
    destinationId: String(tourId),
    roomOccupancies: RoomOccupanciesService.stringifyRoomOccupancies(
      queryParameters.roomOccupancies!
    ),
  });

  return `/tour?${urlSearchParams.toString()}`;
}

export function getTourPageUrl(
  tourId: string | number
) {
  const urlSearchParams = new URLSearchParams({
    destinationType: "TOUR",
    offerId: String(tourId)
  });
  return `/tour?${urlSearchParams.toString()}`;
}

export function getOfferPageUrl(
  offerId: string | number,
  flightSearchId?: string,
  cheapestFlightPricePerPerson?: number,
) {
  return `/offer-details/${offerId}?${
    flightSearchId && cheapestFlightPricePerPerson
      ? new URLSearchParams({
          flightSearchId,
          cheapestFlightPricePerPerson: String(cheapestFlightPricePerPerson),
        }).toString()
      :""
  }`;
}

export function getTourOfferPageUrl(
  tourCode: string
) {
  return `/touroffer-details?${
    new URLSearchParams({tourCode}).toString()
  }`;
}
export function getHostLocationHref() {
  if(window.location.href.includes("#/") )
     return window.location.href;
    else 
   return window.location.href + "#/" 
  }