import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import { Button } from 'primereact/button';
import {RoomInput} from "../GuestsInput";
import NcInputNumberAdult from "../../NcInputNumberAdult";
import NcInputNumber from "../../NcInputNumber";

interface IMobileHotelGuestsRoom {
    className?: string
    guests: RoomInput[]
    setGuests: (value: RoomInput[]) => void
}

export const MobileHotelGuestsRoomSelection: FC<IMobileHotelGuestsRoom> = (
    {
        className = '',
        guests,
        setGuests
    }) => {
    const { t } = useTranslation()

    const [ openModal, setOpenModal ] = useState<boolean>(false)
    const [ totalGuestCount, setTotalGuestCount ] = useState<number>(0)

    useEffect(() => {
        let _totalCount: number = 0
        guests.map(guest => _totalCount += (guest.adults + guest.children.length))
        setTotalGuestCount(_totalCount)
    }, [guests])

    const guestCountAdultHandler = (index: number, value: number) => {
        let _guests: RoomInput[] = [ ...guests ]
        _guests[index].adults = value
        setGuests(_guests)
    }

    const guestAddChildrenHandler = (index: number, value: number) => {
        let _guests: RoomInput[] = [ ...guests ]
        if (_guests[index].children.length < value)
            _guests[index].children.push(0)
        else if (_guests[index].children.length > value)
            _guests[index].children.pop()
        setGuests(_guests)
    }

    const addRoomHandler = () => {
        let _guests: RoomInput[] = [ ...guests ]
        _guests.push({
            adults: 1,
            children: []
        })
        setGuests(_guests)
    }

    const removeRoomHandler = (index: number) => {
        let _guests = [ ...guests ].filter((room, _index) => _index !== index)
        setGuests(_guests)
    }

    const openModalHandler = () => {
        if (openModal)
            setGuests(guests)
        setOpenModal(!openModal)
    }

    const updateHandler = () => {
        openModalHandler()
    }

    const footerContent = (
        <div className={`w-full`}>
            <Button className={`w-full`} label={t('generals.save')} onClick={updateHandler} autoFocus />
        </div>
    );

    return (
        <>
            <div
                onClick={openModalHandler}
                className={`${className} flex justify-between items-center text-sm rounded-sm border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                style={{
                    padding: '0.7rem 0.5rem',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: '#e5e7eb'
                }}
            >
                <i className="pi pi-user-plus" style={{ fontSize: '1.1rem' }}></i>
                &nbsp;&nbsp;
                <div>{ totalGuestCount }</div>
            </div>

            <Dialog
                header={t('react.hotel.travellers')}
                visible={openModal}
                footer={footerContent}
                position={'bottom'}
                style={{ width: '100%', margin: 0 }}
                onHide={openModalHandler}
                draggable={false}
                resizable={false}
            >
                {
                    guests.map((guest, index) =>
                        <div key={`guestRoom-${index}`} className={`my-3`}>
                            <div className={`grid grid-cols-2 pt-2 pb-3`}>
                                <div className={`${index > 0 ? 'col-span-1' : 'col-span-2'}`}>
                                    <h1 className={`xxs:text-md font-semibold`}>Raum {index+1}</h1>
                                </div>
                                {
                                    index > 0 &&
                                    <div className={`col-span-1 flex justify-end`}>
                                        <span onClick={() => removeRoomHandler(index)} className={`text-atrRed`}>remove</span>
                                    </div>
                                }
                            </div>
                            <div className={`mb-4`}>
                                <NcInputNumberAdult
                                    defaultValue={guest.adults}
                                    showDefaultValue={true}
                                    onChange={(adults) => guestCountAdultHandler(index, adults)}
                                    label={t("generals.adult")}
                                />
                            </div>
                            <div className={`mb-4`}>
                                <NcInputNumber
                                    defaultValue={guest.children.length}
                                    showDefaultValue={true}
                                    onChange={(value) => guestAddChildrenHandler(index, value)}
                                    label={t("generals.child")}
                                />
                            </div>
                        </div>
                    )
                }

                <div className={`text-l xxs:text-md ${guests.length <= 4 ? 'text-atrRed' : 'text-customGray'} font-semibold text-right`}>
                    <span onClick={guests.length <= 4 ? addRoomHandler : () => {}}>add Room</span>
                </div>
            </Dialog>
        </>
    )
}
