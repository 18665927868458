import Wallet from "pages/Wallet";
import Layout from "../layout";

const WalletNew: React.FC = () => {
   
    return (
        <Layout>
        <h1 className="text-3xl font-bold mb-4">Wallet</h1>
        <Wallet/>
      </Layout>    );
};

export default WalletNew;
