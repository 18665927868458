import React, {FC, ReactNode, useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {IRentalcarDriverInformation, IRentalCarReservationDetails} from "../../../api/types";
import {formatDate} from "../../../utils/formatDate";
import {splitEmail} from "../MobileBookingDetail";

export interface IDriverInformation {
    rentalcarDriverInformation: IRentalcarDriverInformation[]
}

export const DriverInformation: FC<IDriverInformation> = ({rentalcarDriverInformation}) => {
    const { t } = useTranslation()

    return (
        <div className={`my-3 mx-2 mb-3 px-3 pb-3 pt-3 shadow-md bg-white rounded`}>
            {rentalcarDriverInformation.map((driver, index) => (
                <div key={index}>
                    <div className={`text-xl font-semibold mb-3`}>{index === 0 ? t('rentalcar.main-driver') : t('rentalcar.additional-driver')}</div>
                    <div className={`grid grid-cols-2`}>
                        <div className={`col-span-1 text-l font-semibold py-2`}>{t('general.name')}:</div>
                        <div className={`col-span-1 text-l py-2`}>{driver.firstname}</div>
                        <div className={`col-span-1 text-l font-semibold py-2`}>{t('general.surname')}:</div>
                        <div className={`col-span-1 text-l py-2`}>{driver.surname}</div>
                        <div className={`col-span-1 text-l font-semibold py-2`}>{t('general.birthdate')}:</div>
                        <div className={`col-span-1 text-l py-2`}>{formatDate(driver.birthdate.split('T')[0])}</div>
                    </div>
                    {driver.phone && (
                        <div className={`grid grid-cols-2`}>
                            <div className={`col-span-1 text-l font-semibold py-2`}>{t('rentalcar.phone')}:</div>
                            <div className={`col-span-1 text-l py-2`}>{driver.phone}</div>
                        </div>
                    )}
                    {driver.email && (
                        <div className={`grid grid-cols-2`}>
                            <div className={`col-span-1 text-l font-semibold py-2`}>{t('rentalcar.email')}:</div>
                            <div className={`col-span-1 text-l py-2`}>{driver.email.length > 16 ? splitEmail(driver.email) : driver.email}</div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}
