import React, { Component } from "react";
import "./FlightCheckout.css";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import {
  AtrDate,
  AtrDateFormat,
  AtrDateFormatForSearchRequest,
} from "../calendar/AtrSearchDatepicker";
import "../calendar/Calendar.css";
import {
  checkAge,
  checkIsAdmin,
  getApiKeyParams,
  getB2CAgencyName,
  getHost,
  getSearchId,
  isB2C,
  loadB2AgencyInformation,
  removeLeadingZero,
  validationDateValue,
} from "../../actions/index";
import { Redirect } from "react-router";
import $ from "jquery";
import superagent from "superagent";
import { BuildPassengerSection } from "./FlightPassengerInformation";
import "./LoadOverlay.css";
import styled, { css } from "styled-components";
import { calcTotalTravelTimeinHoursAndMinutes } from "../utils/utils";
import { FlightPriceOverview } from "./FlightPriceOverview";
import { FlightOfferExportBucket } from "./FlightOfferExportBucket";
import { StickyContainer, Sticky } from "react-sticky";
import { AddInsuranceToFlight } from "./AddInsuranceToFlight";
import { LoadingOverlayCustom } from "../../components/utils/UI/LoadingOverlay";
import { t } from "i18next";
import { createFlightQueryString } from "./FlightQueryParams";
import { SetPassportData } from "./SetPassportData";
import { JupiterSubmitButton } from "../utils/UI/JupiterSubmitButton";
import { ContactInformation } from "../utils/UI/ContactInformation";
import { Grid } from "@material-ui/core";
import { FlightRentalcarDriverInformation } from "./FlightRentalcarDriverInformation";
import { PhoneCountryCode } from "../utils/UI/PhoneCountryCode";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AtrResponsiveDialog } from "../utils/UI/AtrResponsiveDialog";
import { AddressInformation } from "../utils/UI/AddressInformation";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

let currentFlightList = {};
let adtCount = 0;
let chdCount = 0;
let infCount = 0;

let totalPrice = 0;

let flightInfoDom = [];

let goHome = false;

let responsiveDialogTitle = "";
let responsiveDialogMsg = "";

const driverInformation = {
  firstname: null,
  surname: null,
  birthdate: null,
};

const passportInfo = {
  passportNationality: {
    value: "",
    showValidation: false,
  },
  passportCountryOfIssue: {
    value: "",
    showValidation: false,
  },
  passportExpiryDate: {
    value: new Date(AtrDate(new Date()).addDays(180)),
    showValidation: false,
  },
  passportNumber: {
    value: "",
    showValidation: false,
  },
};

const b2cContactInformation = {
  email: "",
  confirmEmail: "",
  country: "",
  address: "",
  postcode: "",
  city: "",
  phoneCountryCode: "+49",
  phone: "",
  getSmsConfirmation: false,
};
const newAddressInformation = {
  street: "",
  zip: "",
  city: "",
};
const host = getHost();

class FlightCheckout extends Component {
  gender = [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
  ];

  state = {
    passengerObject: {},
    goBack: false,
    loaded: true,
    rentalcarslist: [],
    fetchedRentalcarslist: false,
    openDatePicker: false,
    showValidation: false,
    selectedCheckoutVehicle: null,
    vehicleDriverInformation: driverInformation,
    insurancePrices: null,
    selectedInsurance: null,
    optionBooking: [],
    fetchedOptionBooking: false,
    selectedBaggage: {},
    reservationFailed: false,
    errorMessage: null,
    isSelectBaggageReady: false,
    roundTrip: this.props.location.state.roundtrip,
    checkedMultiFlights: this.props.location.state.checkedMultiFlights,
    bookingKey: null,
    contactInformation: b2cContactInformation,
    addressInformation: newAddressInformation,
    isWideSize: window.innerWidth > 1100,
    isMediumSize: window.innerWidth <= 1100 && window.innerWidth > 900,
    isMobile: window.innerWidth <= 900,
    agencyName: "",
    openResponsiveDialog: false,
  };

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.reserve = this.reserve.bind(this);
    this.openCalendar = this.openCalendar.bind(this);
    this.closeDatePicker = this.closeDatePicker.bind(this);
    this.initDatePicker = this.initDatePicker.bind(this);
    this.determineInsurancePrices = this.determineInsurancePrices.bind(this);
    this.updateSize = this.updateSize.bind(this);

    window.addEventListener("resize", this.updateSize);
  }

  updateSize(e) {
    if (e.currentTarget.window.innerWidth > 1100 && !this.state.isWideSize) {
      this.setState({ isWideSize: true, isMediumSize: false, isMobile: false });
    } else if (
      e.currentTarget.window.innerWidth <= 900 &&
      !this.state.isMobile
    ) {
      this.setState({ isWideSize: false, isMediumSize: false, isMobile: true });
    } else if (
      e.currentTarget.window.innerWidth > 900 &&
      e.currentTarget.window.innerWidth <= 1100 &&
      !this.state.isMediumSize
    ) {
      this.setState({ isWideSize: false, isMediumSize: true, isMobile: false });
    }
  }

  openCalendar() {
    if (!this.state.openDatePicker) {
      this.setState({ openDatePicker: true });
    }
  }

  initDatePicker() {
    if (
      !$(".react-google-flight-datepicker > .dialog-date-picker").hasClass(
        "open"
      )
    ) {
      if (!this.state.isMobile) {
        $(".mobile-roundtrip-datepicker").removeClass("deactive-datepicker");
      }

      $(".react-google-flight-datepicker > .dialog-date-picker")
        .removeClass("hide")
        .removeClass("close")
        .addClass("open");

      $("#start-date-input-button").click();
    }

    $(
      ".react-google-flight-datepicker > .dialog-date-picker .dialog-footer button"
    ).click(function () {
      if (
        $(".react-google-flight-datepicker > .dialog-date-picker").hasClass(
          "open"
        )
      ) {
        $(".react-google-flight-datepicker > .dialog-date-picker")
          .removeClass("open")
          .removeClass("hide")
          .addClass("close");

        if (!this.state.isMobile) {
          $(".mobile-roundtrip-datepicker").addClass("deactive-datepicker");
        }
      }
    });

    $(".react-google-flight-datepicker > .dialog-date-picker button").click(
      function () {
        if (
          $(".react-google-flight-datepicker > .dialog-date-picker").hasClass(
            "open"
          )
        ) {
          $(".react-google-flight-datepicker > .dialog-date-picker")
            .removeClass("open")
            .removeClass("hide")
            .addClass("close");

          if (!this.state.isMobile) {
            $(".mobile-roundtrip-datepicker").addClass("deactive-datepicker");
          }
        }
      }
    );
  }

  addLeadingZero(time) {
    let counts = time.split(":");
    let newTime = "";

    if (counts[0].length == 1) {
      newTime += "0" + counts[0] + ":";
    } else {
      newTime += counts[0] + ":";
    }

    if (counts[1].length == 1) {
      newTime += "0" + counts[1];
    } else {
      newTime += counts[1];
    }

    return newTime;
  }

  determineWeekDay(week) {
    switch (week) {
      case 0:
        return "So.";
      case 1:
        return "Mo.";
      case 2:
        return "Di.";
      case 3:
        return "Mi.";
      case 4:
        return "Do.";
      case 5:
        return "Fr.";
      case 6:
        return "Sa.";
    }
  }

  determineMonth(month) {
    switch (month) {
      case 0:
        return t("react.month.month_1");
      case 1:
        return t("react.month.month_2");
      case 2:
        return t("react.month.month_3");
      case 3:
        return t("react.month.month_4");
      case 4:
        return t("react.month.month_5");
      case 5:
        return t("react.month.month_6");
      case 6:
        return t("react.month.month_7");
      case 7:
        return t("react.month.month_8");
      case 8:
        return t("react.month.month_9");
      case 9:
        return t("react.month.month_10");
      case 10:
        return t("react.month.month_11");
      case 11:
        return t("react.month.month_12");
    }
  }

  UNSAFE_componentWillMount() {
    window.scroll(0, 0);
    if (typeof this.props.location.state.selectedFlight !== "undefined") {
      currentFlightList = this.props.location.state.selectedFlight;
      adtCount = this.props.location.state.adtCount;
      chdCount = this.props.location.state.chdCount;
      infCount = this.props.location.state.infCount;
      goHome = false;
    } else {
      goHome = true;
      return <Navigate to={"/"} />;
    }

    let passengerAdtObject = {
      gender: "Mr.",
      firstname: "",
      surname: "",
      maxDate: new Date().setFullYear(new Date().getFullYear() - 12),
    };

    let passengerChdObject = {
      gender: "Mr.",
      firstname: "",
      surname: "",
      minDate: new Date().setFullYear(new Date().getFullYear() - 11),
      maxDate: new Date().setFullYear(new Date().getFullYear() - 2),
      openDatePicker: false,
    };

    let passengerInfObject = {
      gender: "Mr.",
      firstname: "",
      surname: "",
      minDate: new Date().setFullYear(new Date().getFullYear() - 2),
      maxDate: Date.now(),
      openDatePicker: false,
    };

    let passengerObject = {};

    for (let i = 0; i < adtCount; i++) {
      let adt = "adt-" + (i + 1);
      let passengerAdtString = JSON.stringify(passengerAdtObject);
      passengerObject[adt] = JSON.parse(passengerAdtString);

      passengerObject[adt]["birthdate"] = new Date().setFullYear(
        new Date().getFullYear() - 18
      );

      passengerObject[adt]["displayBirthdate"] = "";
      passengerObject[adt]["passportInfo"] = Object.assign({}, passportInfo);

      if (i === 0) {
        passengerObject[adt]["phoneCountryCode"] = "+49";
      }
    }
    for (let i = 0; i < chdCount; i++) {
      let chd = "chd-" + (i + 1);
      let passengerChdString = JSON.stringify(passengerChdObject);
      passengerObject[chd] = JSON.parse(passengerChdString);

      passengerObject[chd]["birthdate"] = new Date().setFullYear(
        new Date().getFullYear() - 11
      );

      passengerObject[chd]["displayBirthdate"] = "";
      passengerObject[chd]["passportInfo"] = Object.assign({}, passportInfo);
    }
    for (let i = 0; i < infCount; i++) {
      let inf = "inf-" + (i + 1);
      let passengerInfString = JSON.stringify(passengerInfObject);
      passengerObject[inf] = JSON.parse(passengerInfString);

      passengerObject[inf]["birthdate"] = new Date().setFullYear(
        new Date().getFullYear() - 2
      );

      passengerObject[inf]["displayBirthdate"] = "";
      passengerObject[inf]["passportInfo"] = Object.assign({}, passportInfo);
    }

    this.state.passengerObject = passengerObject;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.selectedCheckoutVehicle !== this.state.selectedCheckoutVehicle
    ) {
      this.determineInsurancePrices();
    }
    if (this.state.fetchedOptionBooking) {
      this.initBaggageData();
    }
  }

  initBaggageData() {
    let baggage = {};
    let countSegment = this.state.roundTrip ? 2 : 1;

    if (this.state.checkedMultiFlights) {
      countSegment = this.props.multiFlights.length;
    }

    let tmpBaggage = [];
    for (let a = 0; a < countSegment; a++) {
      tmpBaggage.push({
        desc: "",
        key: "-1",
        price: 0,
      });
    }

    for (let i = 1; i <= adtCount; i++) {
      baggage["adt-" + i] = tmpBaggage;
    }

    for (let i = 1; i <= chdCount; i++) {
      baggage["chd-" + i] = tmpBaggage;
    }

    for (let i = 1; i <= infCount; i++) {
      baggage["inf-" + i] = tmpBaggage;
    }

    if (!this.state.isSelectBaggageReady) {
      this.setState({
        selectedBaggage: baggage,
        isSelectBaggageReady: true,
        loaded: true,
      });
    }
  }

  componentDidMount() {
    if (goHome) return;

    if (isB2C()) {
      loadB2AgencyInformation().then((res, err) => {
        this.setState({ agencyName: res.agency });
      });
    }

    if (
      !this.state.fetchedOptionBooking &&
      this.props.location.state.selectedFlight[0]
        .offersAncillaryServicesDuringFlightSearch
    ) {
      (async () => {
        await this.queryAncillaryServices();
      })();
    }
    if (!this.state.isMobile) {
    }
    if (this.state.insurancePrices === null) {
      this.determineInsurancePrices();
    }
  }

  async queryAncillaryServices() {
    if (
      currentFlightList[0].offersAncillaryServicesDuringFlightSearch &&
      (currentFlightList.length == 1 ||
        currentFlightList[1].offersAncillaryServicesDuringFlightSearch)
    ) {
      let optionSearchParameter = "offerId=" + this.getOfferIds();
      await superagent
        .get(
          host + "/api/flight/search/ancillary-services?" + getApiKeyParams()
        )
        .query(optionSearchParameter)
        .withCredentials()
        .then((res, err) => {
          let tmpBaggageOption = JSON.parse(res.text);
          if (tmpBaggageOption.length > 0) {
            tmpBaggageOption[0].options.push({
              descr: t("general.flight.withoutadditionalbaggage"),
              key: "-1",
              price: 0.0,
            });
            this.setState({
              optionBooking: tmpBaggageOption,
              fetchedOptionBooking: true,
            });
          } else {
            this.setState({ fetchedOptionBooking: true });
          }
        });
    }
  }

  determineInsurancePrices() {
    if (this.props.location.state.departureDate < AtrDate().addDays(7)) return;

    let vehiclePrice = 0;
    if (this.state.selectedCheckoutVehicle !== null)
      vehiclePrice = this.state.selectedCheckoutVehicle.effectiveTotalPrice;

    let adtPrice = 0;
    let chdPrice = 0;
    let infPrice = 0;
    let serviceFee = 0;

    if (this.props.location.state.isRoundTripFlightAvailable === null) {
      for (let i = 0; i < currentFlightList.length; i++) {
        adtPrice += adtCount * currentFlightList[i].adtPrice;
        chdPrice += chdCount * currentFlightList[i].chdPrice;
        infPrice += infCount * currentFlightList[i].infPrice;
      }
    } else {
      adtPrice +=
        adtCount *
        this.props.location.state.isRoundTripFlightAvailable.adtPrice;
      chdPrice +=
        chdCount *
        this.props.location.state.isRoundTripFlightAvailable.chdPrice;
      infPrice +=
        infCount *
        this.props.location.state.isRoundTripFlightAvailable.infPrice;
    }

    let countRoute = 0;
    for (let i = 0; i < currentFlightList.length; i++) {
      for (let a = 0; a < currentFlightList[i].flightTrips.length; a++) {
        countRoute++;
      }
    }

    serviceFee =
      (adtCount + chdCount + infCount) *
      countRoute *
      this.props.location.state.serviceFeePerPaxAndRoute;

    let totalPrice = adtPrice + chdPrice + infPrice + vehiclePrice + serviceFee;

    let passengerCount = adtCount + chdCount + infCount;

    let insuranceData =
      "passengerCount=" + passengerCount + "&totalTravelAmount=" + totalPrice;

    superagent
      .post(host + "/agency/makeReservation/determineInsurancePrice")
      .type("form")
      .send(insuranceData)
      .withCredentials()
      .then((res, err) => {
        if (Object.keys(JSON.parse(res.text)).length > 0) {
          if (
            this.state.selectedInsurance !== null &&
            res.text !== JSON.stringify(this.state.insurancePrices)
          ) {
            let tmpInsurance = determineNewInsurance(
              JSON.parse(res.text),
              this.state.selectedInsurance
            );

            if (
              JSON.stringify(tmpInsurance) !==
              JSON.stringify(this.state.selectedInsurance)
            ) {
              let showDialog =
                Number.parseInt(tmpInsurance.price) >
                Number.parseInt(this.state.selectedInsurance.price);
              responsiveDialogTitle = t("general.price-update");
              responsiveDialogMsg = t("insurance.alert.price-update");
              this.setState({
                openResponsiveDialog: showDialog,
                selectedInsurance: tmpInsurance,
              });
            }
          }

          this.setState({ insurancePrices: JSON.parse(res.text) });
        } else {
          this.setState({ insurancePrices: null });
        }
      });

    //versicherung erstmal entfernt
    //this.setState({ insurancePrices: {} });
  }

  closeDatePicker() {
    for (let i = 0; i < adtCount; i++) {
      let type = "adt-" + (i + 1);
      $("#calendar-" + type).css("display", "none");
    }
    for (let i = 0; i < chdCount; i++) {
      let type = "chd-" + (i + 1);
      $("#calendar-" + type).css("display", "none");
    }
    for (let i = 0; i < infCount; i++) {
      let type = "inf-" + (i + 1);
      $("#calendar-" + type).css("display", "none");
    }
  }

  loadingOverlay() {
    return <LoadingOverlayCustom msg={t("general.flight.trip-put-together")} />;
  }

  handleChangeBaggage = (event) => {
    let baggageUpdate = JSON.parse(JSON.stringify(this.state.selectedBaggage));

    let passengerType = event.target.name.split("addbaggage-")[1];
    let index = event.target.name.split("-i")[0];

    if (!baggageUpdate.hasOwnProperty(passengerType)) {
      baggageUpdate[passengerType] = {};
    }

    if (event.target.value.key !== "-1") {
      baggageUpdate[passengerType][index].price = parseFloat(
        event.target.value.descr
          .replace(/ /g, "")
          .replace(/EUR/g, "")
          .split("-")[2]
      );
      baggageUpdate[passengerType][index].desc = event.target.value.descr;
      baggageUpdate[passengerType][index].key = event.target.value.key;
    } else {
      baggageUpdate[passengerType][index] = {};
      baggageUpdate[passengerType][index].desc = event.target.value.descr;
      baggageUpdate[passengerType][index].key = "";
      baggageUpdate[passengerType][index].price = 0.0;
    }

    this.setState({ selectedBaggage: baggageUpdate });
  };

  handleChangeGender = (event) => {
    let genderUpdate = this.state.passengerObject;
    genderUpdate[event.target.name.split("passengerObject-")[1]].gender =
      event.target.value;
    this.setState({ passengerObject: genderUpdate });
  };

  buildFlightSection(flight, directionClass, direction) {
    let flightNumber = flight[0].flightNumber;
    let baggage = flight[0].baggageAllowance;
    if (
      this.props.location.state.isRoundTripFlightAvailable !== null &&
      this.props.location.state.isRoundTripFlightAvailable.hasOwnProperty(
        "baggageAllowance"
      )
    ) {
      baggage =
        this.props.location.state.isRoundTripFlightAvailable.baggageAllowance.split(
          "/"
        )[direction];
    }

    let departureDate = new Date(
      flight[0].departureDateStr.split(".")[2] +
        "-" +
        flight[0].departureDateStr.split(".")[1] +
        "-" +
        flight[0].departureDateStr.split(".")[0]
    ).getTime();
    //let arrivalDate = flight[flight.length - 1].arrivalDate;
    let arrivalDate = new Date(
      flight[flight.length - 1].arrivalDateStr.split(".")[2] +
        "-" +
        flight[flight.length - 1].arrivalDateStr.split(".")[1] +
        "-" +
        flight[flight.length - 1].arrivalDateStr.split(".")[0]
    ).getTime();
    let departurePort = flight[0].departurePort;
    let arrivalPort = flight[flight.length - 1].arrivalPort;
    let cabinClass = flight[0].cabinClass;
    let classOfService = flight[0].classOfService;
    let airlineLogo = flight[0].airlineLogo;
    let airlineName = flight[0].airlineName;

    let totalFlightTime = calcTotalTravelTimeinHoursAndMinutes(flight);

    let departureTime = flight[0].departureTimeStr;
    let arrivalTime = flight[flight.length - 1].arrivalTimeStr;

    if (!this.state.isMobile) {
      return (
        <div className={directionClass}>
          <Grid container spacing={2}>
            <Grid item xs={2} md={1}>
              <div className="flightcheckout-header-info-airline">
                <img style={{ width: "65px" }} src={airlineLogo} />
              </div>
            </Grid>
            <Grid item xs={10} md={3}>
              <div className="flightcheckout-header-info-date">
                <div>
                  {this.determineWeekDay(new Date(departureDate).getDay())},{" "}
                  {new Date(departureDate).getDate()}{" "}
                  {this.determineMonth(new Date(departureDate).getMonth())}
                </div>
                <div>
                  <div>
                    {departurePort} {departureTime}{" "}
                    <a style={{ fontWeight: "bolder", opacity: "0.7" }}>
                      &#8594;
                    </a>{" "}
                    {arrivalPort} {arrivalTime}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div className="flightcheckout-header-info-from-to">
                <div>
                  <div>
                    {flight[0].departurePortName} &#8594;{" "}
                    {flight[flight.length - 1].arrivalPortName}
                  </div>
                  <div>
                    {flight.length > 1
                      ? flight.length > 2
                        ? flight.length - 1 + " Stops"
                        : flight.length - 1 + " Stop"
                      : "Nonstop"}
                    , {totalFlightTime}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div className="flightcheckout-header-info-further">
                <div>
                  <div>
                    {t("react.flight.flightnumber")}: <b>{flightNumber}</b>
                  </div>
                  <div>
                    {t("react.flight.baggage")}: <b>{baggage}</b>
                  </div>
                  <div>
                    {t("react.flight.cabinclass")}:{" "}
                    <b>
                      {cabinClass} [ {classOfService} ]
                    </b>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else if (this.state.isMobile) {
      directionClass = "mobile-" + directionClass;
      return (
        <div className={directionClass}>
          <div style={{ display: "flex" }}>
            <div className="mobile-flightcheckout-header-info-airline">
              <img style={{ width: "60px" }} src={airlineLogo} />
            </div>
            <div className="mobile-flightcheckout-header-info-date">
              <div>
                {this.determineWeekDay(new Date(departureDate).getDay())},{" "}
                {new Date(departureDate).getDate()}{" "}
                {this.determineMonth(new Date(departureDate).getMonth())}
              </div>
              <div>
                <div>
                  {departurePort} {departureTime}{" "}
                  <a style={{ fontWeight: "bolder", opacity: "0.7" }}>
                    &#8594;
                  </a>{" "}
                  {arrivalPort} {arrivalTime}
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-flightcheckout-header-info-from-to">
            <div>
              <div>
                {t("react.flight.generel.departureairport")}:{" "}
                <a style={{ color: "#838383" }}>
                  {flight[0].departurePortName}
                </a>
              </div>
              <div>
                {t("react.flight.generel.targetairport")}:{" "}
                <a style={{ color: "#838383" }}>
                  {flight[flight.length - 1].arrivalPortName}
                </a>
              </div>
              <div>
                {t("react.flight.generel.traveltime")}:{" "}
                <a style={{ color: "#838383" }}>
                  {flight.length > 1
                    ? flight.length > 2
                      ? flight.length - 1 + " Stops"
                      : flight.length - 1 + " Stop"
                    : "Nonstop"}
                  , {totalFlightTime}
                </a>
              </div>
            </div>
          </div>
          <div className="mobile-flightcheckout-header-info-further">
            <div>
              <div>
                {t("react.flight.flightnumber")}: {flightNumber}
              </div>
              <div>
                {t("react.flight.baggage")}: {baggage}
              </div>
              <div>
                {t("react.flight.cabinclass")}: {cabinClass} [ {classOfService}{" "}
                ]
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  buildPassengerSection(passengerCount, type) {
    let passengerDOM = [];

    let startIndex = 0;
    if (type === "adt") {
      startIndex = 1;
    } else {
      startIndex = 0;
    }

    if (this.props.isWideSize) {
      if (type === "adt") {
        passengerDOM.push(
          <div style={{ paddingBottom: "12px", fontWeight: "500" }}>
            {t("react.checkout.adultinformation")}
          </div>
        );
      } else if (type === "chd") {
        passengerDOM.push(
          <div style={{ paddingBottom: "12px", fontWeight: "500" }}>
            {t("react.checkout.childinformation")}
          </div>
        );
      } else {
        passengerDOM.push(
          <div style={{ paddingBottom: "12px", fontWeight: "500" }}>
            {t("react.checkout.infantinformation")}
          </div>
        );
      }
    }

    for (let i = startIndex; i < passengerCount; i++) {
      if (type === "adt") {
        passengerDOM.push(
          <div style={{ padding: "12px 0", fontWeight: "500" }}>
            {i + 1}. {t("react.checkout.adultinformation")}
          </div>
        );
      } else if (type === "chd") {
        passengerDOM.push(
          <div style={{ padding: "12px 0", fontWeight: "500" }}>
            {i + 1}. {t("react.checkout.childinformation")}
          </div>
        );
      } else {
        passengerDOM.push(
          <div style={{ padding: "12px 0", fontWeight: "500" }}>
            {i + 1}. {t("react.checkout.infantinformation")}
          </div>
        );
      }

      let genderType = type + "-" + (i + 1);
      let invalidMsg = t("react.validation.requiredfield");

      passengerDOM.push(
        <BuildPassengerSection
          showValidation={this.state.showValidation}
          passengerObject={this.state.passengerObject}
          selectedBaggage={this.state.selectedBaggage}
          optionBooking={this.state.optionBooking}
          isBrowser={!this.state.isMobile}
          isMobile={this.state.isMobile}
          passengerType={genderType}
          departureDate={
            currentFlightList[currentFlightList.length - 1].flightList[
              currentFlightList[currentFlightList.length - 1].flightList
                .length - 1
            ][0].departureDate
          }
          index={i}
          t={t()}
          invalidMsg={invalidMsg}
          updatePassengerValue={(newPassengerObject) => {
            this.setState({ passengerObject: newPassengerObject });
          }}
          updateBaggage={(newBaggage) => {
            this.setState({ selectedBaggage: newBaggage });
          }}
          isSelectBaggageReady={this.state.isSelectBaggageReady}
          isPassportRequired={
            this.props.location.state.selectedFlight[0].passportRequired
          }
          isHesCodeRequired={
            this.props.location.state.selectedFlight[0].hesCodeRequired
          }
        />
      );
    }

    return <div>{passengerDOM}</div>;
  }

  goBack() {
    this.setState({ goBack: true });
  }

  getOfferIds() {
    let tmpOfferId = "";

    if (this.props.location.state.isRoundTripFlightAvailable === null) {
      if (
        typeof this.props.location.state.selectedFlight.offerId === "undefined"
      ) {
        for (
          let i = 0;
          i < this.props.location.state.selectedFlight.length;
          i++
        ) {
          tmpOfferId += this.props.location.state.selectedFlight[i].offerId;
          if (i + 1 < this.props.location.state.selectedFlight.length) {
            tmpOfferId += "+";
          }
        }
      } else {
        tmpOfferId = this.props.location.state.selectedFlight.offerId;
      }
    } else {
      tmpOfferId =
        this.props.location.state.isRoundTripFlightAvailable
          .newDepGalileoFlightIdentificator;
      if (
        this.props.location.state.isRoundTripFlightAvailable
          .newRetGalileoFlightIdentificator.length > 0
      ) {
        tmpOfferId +=
          "+" +
          this.props.location.state.isRoundTripFlightAvailable
            .newRetGalileoFlightIdentificator;
      }
    }
    return tmpOfferId;
  }

  reserve() {
    let showValidaion = this.checkToShowValidation();
    if (!showValidaion) {
      let requestBody = {};
      let tmpBaggageReservationObject = getBaggageReservationObject(
        this.state.selectedBaggage
      );
      requestBody["ancillaryTotalPrice"] =
        tmpBaggageReservationObject.totalprice;
      requestBody["serviceFeePerPax"] =
        this.props.location.state.serviceFeePerPaxAndRoute;
      requestBody["street"] = this.state.addressInformation.street;
      requestBody["zip"] = this.state.addressInformation.zip;
      requestBody["city"] = this.state.addressInformation.city;
      requestBody["customerEmail"] = !isB2C()
        ? this.state.passengerObject["adt-1"].email
        : this.state.contactInformation.email;
      requestBody["rentalCarOfferId"] =
        this.state.selectedCheckoutVehicle !== null
          ? this.state.selectedCheckoutVehicle.recoCode
          : null;
      requestBody["customerPhone"] = !isB2C()
        ? this.state.passengerObject["adt-1"].phoneCountryCode.replace(
            "+",
            "00"
          ) + removeLeadingZero(this.state.passengerObject["adt-1"].phone)
        : this.state.contactInformation.phoneCountryCode.replace("+", "00") +
          removeLeadingZero(this.state.contactInformation.phone);
      requestBody["offerId"] = this.getOfferIds();
      requestBody["insuranceType"] =
        this.state.selectedInsurance !== null
          ? this.state.selectedInsurance.type
          : null;
      requestBody["insuranceTariffCode"] =
        this.state.selectedInsurance !== null
          ? this.state.selectedInsurance.tarifcode
          : null;
      requestBody["insurancePrice"] =
        this.state.selectedInsurance !== null
          ? parseFloat(this.state.selectedInsurance.price).toFixed(2)
          : null;

      if (this.state.selectedCheckoutVehicle !== null) {
        requestBody["rentalcarFirstname"] =
          this.state.vehicleDriverInformation.firstname;
        requestBody["rentalcarSurname"] =
          this.state.vehicleDriverInformation.surname;
        requestBody["rentalcarBirthdate"] = AtrDateFormatForSearchRequest(
          new Date(this.state.vehicleDriverInformation.birthdate)
        );
      }

      let paxList = [];
      let mainPax = {
        paxtype: "ADT",
        firstname: this.state.passengerObject["adt-1"].firstname,
        surname: this.state.passengerObject["adt-1"].surname,
        gender:
          this.state.passengerObject["adt-1"].gender === "Mr."
            ? "MALE"
            : "FEMALE",
        birthdate: AtrDateFormatForSearchRequest(
          new Date(this.state.passengerObject["adt-1"]["birthdate"])
        ),
        passportExpiryDate: this.props.location.state.selectedFlight[0]
          .passportRequired
          ? this.state.passengerObject["adt-1"].passportInfo.passportExpiryDate
              .value
          : "",
        passportNumber: this.props.location.state.selectedFlight[0]
          .passportRequired
          ? this.state.passengerObject["adt-1"].passportInfo.passportNumber
              .value
          : "",
        passportNationality: this.props.location.state.selectedFlight[0]
          .passportRequired
          ? this.state.passengerObject["adt-1"].passportInfo.passportNationality
              .value
          : "",
        passportCountryOfIssue: this.props.location.state.selectedFlight[0]
          .passportRequired
          ? this.state.passengerObject["adt-1"].passportInfo
              .passportCountryOfIssue.value
          : "",
        ancillaryServicesRequests:
          tmpBaggageReservationObject.hasOwnProperty("adt-1") &&
          tmpBaggageReservationObject["adt-1"].hasOwnProperty("keys") &&
          tmpBaggageReservationObject["adt-1"].keys.length > 0
            ? tmpBaggageReservationObject["adt-1"].keys
            : [],
      };

      if (this.props.location.state.selectedFlight[0].hesCodeRequired) {
        mainPax["hesCode"] = this.state.passengerObject["adt-1"].hesCode;
      }

      paxList.push(mainPax);

      for (let i = 1; i < adtCount; i++) {
        paxList.push(
          this.createPaxlist(i + 1, "ADT", tmpBaggageReservationObject)
        );
      }
      for (let i = 0; i < chdCount; i++) {
        paxList.push(
          this.createPaxlist(i + 1, "CHD", tmpBaggageReservationObject)
        );
      }
      for (let i = 0; i < infCount; i++) {
        paxList.push(
          this.createPaxlist(i + 1, "INF", tmpBaggageReservationObject)
        );
      }

      requestBody["paxList"] = paxList;

      superagent
        .post(host + "/api/flight/reserve?" + getApiKeyParams())
        .send(JSON.stringify(requestBody))
        .withCredentials()
        .set("Accept", "application/json")
        .set("Content-Type", "application/json")
        .then((res) => {
          if (!isB2C()) {
            window.location.href =
              host + "/agency/viewBooking/" + JSON.parse(res.text).bookingKey;
          } else if (isB2C()) {
            this.setState({
              bookingKey: {
                bookingKey: JSON.parse(res.text).bookingKey,
                email: requestBody.customerEmail,
              },
            });
          }
        })
        .catch((err) => {
          MySwal.fire({
            title: JSON.parse(err.message).message,
            showConfirmButton: false,
            icon: "warning",
            html: t("generals.an-error-occured"),
          });
          window.scroll(0, 0);
          this.setState({
            reservationFailed: true,
            loaded: true,
            errorMessage: err.message,
          });
        });

      this.setState({ loaded: false });
    } else {
      this.setState({ showValidation: true });
    }
  }

  checkToShowValidation() {
    let keys = Object.keys(this.state.passengerObject);

    if (isB2C()) {
      let contactInformationKeys = Object.keys(this.state.contactInformation);

      for (let i = 0; i < contactInformationKeys.length; i++) {
        let key = contactInformationKeys[i];

        /*if ( key === 'getSmsConfirmation' )
                {
                    continue;
                }*/

        if (
          key !== "email" &&
          key !== "confirmEmail" &&
          key !== "phone" &&
          key !== "phoneCountryCode"
        ) {
          continue;
        }

        if (this.state.contactInformation[key] === "") {
          return true;
        }
      }

      if (
        this.state.contactInformation.email !==
        this.state.contactInformation.confirmEmail
      ) {
        return true;
      }

      if (this.state.selectedCheckoutVehicle !== null) {
        let driverInformationKey = Object.keys(
          this.state.vehicleDriverInformation
        );

        if (
          this.state.vehicleDriverInformation[driverInformationKey] === null ||
          this.state.vehicleDriverInformation[driverInformationKey] === ""
        ) {
          return true;
        }
      }
    }

    for (let key of keys) {
      if (this.state.passengerObject[key]["firstname"] === "") {
        return true;
      }
      if (this.state.passengerObject[key]["surname"] === "") {
        return true;
      }
      if (this.state.passengerObject[key]["displayBirthdate"] === "") {
        return true;
      } else {
        let isDateInvalid = !validationDateValue(
          this.state.passengerObject[key]["displayBirthdate"]
        );
        if (isDateInvalid) {
          return true;
        }

        let isAgeInvalid = !checkAge(
          key,
          new Date(this.state.passengerObject[key]["birthdate"]),
          new Date(
            currentFlightList[currentFlightList.length - 1].flightList[
              currentFlightList[currentFlightList.length - 1].flightList
                .length - 1
            ][0].departureDate
          )
        );
        if (isAgeInvalid) {
          return true;
        }
      }
      if (key === "adt-1") {
        if (
          (!isB2C() &&
            !this.state.passengerObject[key].hasOwnProperty("email")) ||
          this.state.passengerObject[key]["email"] === ""
        ) {
          return true;
        }
        if (
          (!isB2C() &&
            !this.state.passengerObject[key].hasOwnProperty("phone")) ||
          this.state.passengerObject[key]["phone"] === ""
        ) {
          return true;
        }
        if (
          this.props.location.state.selectedFlight[0].hesCodeRequired &&
          (!this.state.passengerObject[key].hasOwnProperty("hesCode") ||
            this.state.passengerObject[key]["hesCode"] === "")
        ) {
          return true;
        }
      }
    }

    let addressInformationKeys = Object.keys(this.state.addressInformation);
    if (this.state.selectedInsurance !== null) {
      for (let i = 0; i < addressInformationKeys.length; i++) {
        let key = addressInformationKeys[i];

        if (this.state.addressInformation[key] === "") {
          return true;
        }
      }
    }

    return false;
  }

  createPaxlist(passengerCount, type, baggageReservationObject) {
    let passengerTypeAndNumber = type.toLowerCase() + "-" + passengerCount;
    let birthdateSelector = "birthdate" + passengerTypeAndNumber;
    let pax = {};

    pax["paxtype"] = type;
    pax["gender"] =
      this.state.passengerObject[passengerTypeAndNumber].gender === "Mr."
        ? "MALE"
        : "FEMALE";
    pax["firstname"] =
      this.state.passengerObject[passengerTypeAndNumber].firstname;
    pax["surname"] = this.state.passengerObject[passengerTypeAndNumber].surname;
    pax["birthdate"] = AtrDateFormatForSearchRequest(
      new Date(this.state.passengerObject[passengerTypeAndNumber].birthdate)
    );
    pax["passportExpiryDate"] = this.props.location.state.selectedFlight[0]
      .passportRequired
      ? this.state.passengerObject[passengerTypeAndNumber].passportInfo
          .passportExpiryDate.value
      : "";
    pax["passportNumber"] = this.props.location.state.selectedFlight[0]
      .passportRequired
      ? this.state.passengerObject[passengerTypeAndNumber].passportInfo
          .passportNumber.value
      : "";
    pax["passportNationality"] = this.props.location.state.selectedFlight[0]
      .passportRequired
      ? this.state.passengerObject[passengerTypeAndNumber].passportInfo
          .passportNationality.value
      : "";
    pax["passportCountryOfIssue"] = this.props.location.state.selectedFlight[0]
      .passportRequired
      ? this.state.passengerObject[passengerTypeAndNumber].passportInfo
          .passportCountryOfIssue.value
      : "";
    pax["ancillaryServicesRequests"] =
      baggageReservationObject.hasOwnProperty(passengerTypeAndNumber) &&
      baggageReservationObject[passengerTypeAndNumber].hasOwnProperty("keys") &&
      baggageReservationObject[passengerTypeAndNumber].keys.length > 0
        ? baggageReservationObject[passengerTypeAndNumber].keys
        : [];
    // "ancillaryServicesRequests": tmpBaggageReservationObject.hasOwnProperty('adt-1') && tmpBaggageReservationObject[ 'adt-1' ].hasOwnProperty('keys') ? tmpBaggageReservationObject[ 'adt-1' ].keys : ""

    if (this.props.location.state.selectedFlight[0].hesCodeRequired) {
      pax["hesCode"] =
        this.state.passengerObject[passengerTypeAndNumber].hesCode;
    }

    return pax;
  }

  render() {
    flightInfoDom = [];

    for (let i = 0; i < currentFlightList.length; i++) {
      flightInfoDom.push(
        this.buildFlightSection(
          currentFlightList[i].flightList[0],
          "flightcheckout-header-info-departure"
        )
      );
      if (currentFlightList[i].flightList.length > 1) {
        flightInfoDom.push(
          this.buildFlightSection(
            currentFlightList[i].flightList[
              currentFlightList[i].flightList.length - 1
            ],
            "flightcheckout-header-info-return"
          )
        );
      }
    }

    /*if ( currentFlightList.length > 1 )
        {
            for ( let i = 0; i < currentFlightList.length; i++ )
            {
                flightInfoDom.push( this.buildFlightSection( currentFlightList[ i ].flightList[ 0 ], 'flightcheckout-header-info-departure', 0 ) )
            }
        }
        else
        {
            if ( !this.state.isMobile )
            {
                flightInfoDom.push( this.buildFlightSection( currentFlightList.flightList[ 0 ], 'flightcheckout-header-info-departure', 1 ) )
                if ( currentFlightList.flightList.length > 1 )
                {
                    flightInfoDom.push( this.buildFlightSection( currentFlightList.flightList[ currentFlightList.flightList.length - 1 ], 'flightcheckout-header-info-return') )
                }
            }
            else if ( this.state.isMobile )
            {
                flightInfoDom.push( this.buildFlightSection( currentFlightList.flightList[ 0 ], 'flightcheckout-header-info-departure' ) )
                if ( currentFlightList.flightList.length > 1 )
                {
                    flightInfoDom.push( this.buildFlightSection( currentFlightList.flightList[ currentFlightList.flightList.length - 1 ], 'flightcheckout-header-info-return') )
                }
            }
        }*/

    let flightCheckoutStyle = {};
    flightCheckoutStyle = {
      maxWidth: "1380px",
      margin: "0px auto",
      padding: "15px 15px 20px",
    };

    let showValidationFirstname =
      this.state.showValidation &&
      this.state.passengerObject["adt-1"]["firstname"] === "";
    let showValidationSurname =
      this.state.showValidation &&
      this.state.passengerObject["adt-1"]["surname"] === "";
    let showValidationBirthdate =
      this.state.showValidation &&
      (this.state.passengerObject["adt-1"]["displayBirthdate"] === "" ||
        !validationDateValue(
          this.state.passengerObject["adt-1"]["displayBirthdate"]
        ));
    let showValidationEmail =
      this.state.showValidation &&
      (!this.state.passengerObject["adt-1"].hasOwnProperty("email") ||
        this.state.passengerObject["adt-1"]["email"] === "");
    let showValidationPhone =
      this.state.showValidation &&
      (!this.state.passengerObject["adt-1"].hasOwnProperty("phone") ||
        this.state.passengerObject["adt-1"]["phone"] === "" ||
        this.state.passengerObject["adt-1"]["phone"].length < 8);
    let showValidationHesCode =
      this.state.showValidation &&
      (!this.state.passengerObject["adt-1"].hasOwnProperty("hesCode") ||
        this.state.passengerObject["adt-1"]["hesCode"] === "" ||
        this.state.passengerObject["adt-1"]["hesCode"].length < 6);

    let invalidMsg = t("react.validation.requiredfield");

    if (this.state.bookingKey !== null) {
      return (
        <Navigate
          to={`/viewBooking/${this.state.bookingKey.bookingKey}/${this.state.bookingKey.email}`}
        />
      );
    }

    if (this.state.goBack) {
      if (isB2C()) {
        window.location.href =
          window.location.origin +
          "/booking#/flightresults" +
          createFlightQueryString(
            this.props.location.state.searchParameter,
            this.props.location.state.departureDepPort,
            this.props.location.state.departureRetPort,
            this.props.location.state.multiFlights,
            this.state.checkedMultiFlights,
            this.state.roundTrip
          );
      } else {
        return (
          <Navigate
            to={`/flightresults${createFlightQueryString(
              this.props.location.state.searchParameter,
              this.props.location.state.departureDepPort,
              this.props.location.state.departureRetPort,
              this.props.location.state.multiFlights,
              this.state.checkedMultiFlights,
              this.state.roundTrip
            )}?searchType=Flights`}
            state={{
              skipSearch: true,
              serviceFeePerPaxAndRoute:
                this.props.location.state.serviceFeePerPaxAndRoute,
              finishedLoading: 100,
              searchParameter: this.props.location.state.searchParameter,
              multiFlights: this.props.location.state.multiFlights,
              selectedOffers: this.props.location.state.selectedOffers,
              checkedMultiFlights: this.state.checkedMultiFlights,
              selectedFlight: this.props.location.state.selectedFlight,
              flightResultList: this.props.location.state.flightResultList,
              flightFilter: this.props.location.state.flightFilter,
              searchId: getSearchId(),
              departureDepPort: this.props.location.state.departureDepPort,
              departureRetPort: this.props.location.state.departureRetPort,
              isRoundTripFlightAvailable:
                this.props.location.state.isRoundTripFlightAvailable,
              roundTrip: this.state.roundTrip,
              departureDate: this.props.location.state.departureDate,
              returnDate: this.props.location.state.returnDate,
              adtCount: this.props.location.state.adtCount,
              chdCount: this.props.location.state.chdCount,
              infCount: this.props.location.state.infCount,
              cabinClass: this.props.location.state.cabinClass,
              comingFromCheckout: true,
              showMixableOffers: this.props.location.state.hasOwnProperty(
                "showMixableOffers"
              )
                ? this.props.location.state.showMixableOffers
                : true,
            }}
          />
        );
      }
    }

    if (goHome) {
      return <Navigate to="/" />;
    }

    let stickyDistanceFromTop = "25";
    if (document.getElementsByClassName("left-sidebar").length > 0) {
      let navBarHeight =
        document.getElementsByClassName("left-sidebar")[0].offsetHeight;
      stickyDistanceFromTop = navBarHeight + 25;
    }

    return (
      <div className="FlightCheckout" style={flightCheckoutStyle}>
        <div>
          <h4 style={{ fontWeight: "600" }}>
            {t("react.checkout.checkdetails")}
          </h4>
        </div>

        {this.state.reservationFailed ? (
          <div className="alert alert-danger" role="alert">
            {t("general.notification-reservation-error")}
            <br />
            <br />
            {this.state.errorMessage}
          </div>
        ) : (
          ""
        )}

        <AtrResponsiveDialog
          open={this.state.openResponsiveDialog}
          title={responsiveDialogTitle}
          msg={responsiveDialogMsg}
          handleClick={() =>
            this.setState({
              openResponsiveDialog: !this.state.openResponsiveDialog,
            })
          }
          reject={() =>
            this.setState({
              openResponsiveDialog: !this.state.openResponsiveDialog,
              selectedInsurance: null,
            })
          }
        />

        {!this.state.isMobile ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <div>
                <div className="flightcheckout-card">{flightInfoDom}</div>

                <div>
                  {this.state.insurancePrices !== null ? (
                    <AddInsuranceToFlight
                      adtCount={this.props.location.state.adtCount}
                      chdCount={this.props.location.state.chdCount}
                      infCount={this.props.location.state.infCount}
                      insurancePrices={this.state.insurancePrices}
                      isInsuranceSelected={
                        this.state.selectedInsurance !== null
                      }
                      selectedInsurance={(insurance) => {
                        this.setState({ selectedInsurance: insurance });
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <div className="flightcheckout-card-passagierinfo">
                    <div
                      style={{
                        fontSize: "21px",
                        fontWeight: "400",
                        paddingBottom: "1.25vw",
                      }}
                    >
                      {t("react.checkout.passengerdata")}
                    </div>

                    <div className="flightcheckout-card-main-passenger">
                      <div
                        style={{
                          paddingBottom: "0.74vw",
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                        {t("react.checkout.maincontact")}
                      </div>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={2}>
                            <TextField
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-passengerObject-adt-1"
                              id="flightcheckout-card-main-passenger-passengerObject-adt-1"
                              label={t("react.generel.gender")}
                              variant="outlined"
                              select
                              value={this.state.passengerObject["adt-1"].gender}
                              defaultValue={
                                this.state.passengerObject["adt-1"].gender
                              }
                              onChange={this.handleChangeGender}
                            >
                              {this.gender.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              error={showValidationFirstname}
                              helperText={
                                showValidationFirstname ? invalidMsg : ""
                              }
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-firstname"
                              label={t("react.generel.firstname")}
                              variant="outlined"
                              onBlur={(e) => {
                                this.state.passengerObject["adt-1"].firstname =
                                  e.target.value;

                                if (
                                  this.state.vehicleDriverInformation
                                    .firstname === null
                                ) {
                                  let tmpVehicleDriverInformation =
                                    Object.assign(
                                      {},
                                      this.state.vehicleDriverInformation
                                    );
                                  tmpVehicleDriverInformation.firstname =
                                    e.target.value;

                                  this.setState({
                                    passengerObject: this.state.passengerObject,
                                    vehicleDriverInformation:
                                      tmpVehicleDriverInformation,
                                  });
                                } else {
                                  this.setState({
                                    passengerObject: this.state.passengerObject,
                                  });
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              error={showValidationSurname}
                              helperText={
                                showValidationSurname ? invalidMsg : ""
                              }
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-surname"
                              label={t("react.generel.name")}
                              variant="outlined"
                              onBlur={(e) => {
                                this.state.passengerObject["adt-1"].surname =
                                  e.target.value;

                                if (
                                  this.state.vehicleDriverInformation
                                    .surname === null
                                ) {
                                  let tmpVehicleDriverInformation =
                                    Object.assign(
                                      {},
                                      this.state.vehicleDriverInformation
                                    );
                                  tmpVehicleDriverInformation.surname =
                                    e.target.value;

                                  this.setState({
                                    passengerObject: this.state.passengerObject,
                                    vehicleDriverInformation:
                                      tmpVehicleDriverInformation,
                                  });
                                } else {
                                  this.setState({
                                    passengerObject: this.state.passengerObject,
                                  });
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              error={showValidationBirthdate}
                              helperText={
                                showValidationBirthdate
                                  ? !validationDateValue(
                                      this.state.passengerObject["adt-1"][
                                        "displayBirthdate"
                                      ]
                                    )
                                    ? "Das Datum bitte in folgendem Format eingeben: dd.mm.yyyy"
                                    : invalidMsg
                                  : ""
                              }
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-birthdate"
                              id="flightcheckout-card-main-passenger-birthdate"
                              label={t("react.generel.birthdate")}
                              variant="outlined"
                              onChange={(e) => {
                                const re = /^[0-9.]*$/;

                                let inputValue = e.target.value;

                                if (
                                  re.test(inputValue) &&
                                  !inputValue.includes("..")
                                ) {
                                  if (inputValue.slice(-1) === ".") {
                                    if (
                                      inputValue.length === 3 ||
                                      inputValue.length === 6
                                    ) {
                                      $(e.target).val(inputValue);
                                    }
                                  }
                                  if (re.test(inputValue)) {
                                    if (inputValue.length < 11) {
                                      if (
                                        inputValue.length === 2 ||
                                        inputValue.length === 5
                                      ) {
                                        if (
                                          inputValue.length === 2 &&
                                          inputValue[inputValue.length - 1] ===
                                            "."
                                        ) {
                                          inputValue = "0" + inputValue;
                                        } else if (
                                          inputValue.length === 5 &&
                                          inputValue[inputValue.length - 1] ===
                                            "."
                                        ) {
                                          let tmpInputValue =
                                            inputValue.split(".")[0] + ".";

                                          if (
                                            inputValue.split(".")[1] === "0"
                                          ) {
                                            tmpInputValue += "01.";
                                          } else {
                                            tmpInputValue +=
                                              "0" +
                                              inputValue.split(".")[1] +
                                              ".";
                                          }
                                          inputValue = tmpInputValue;
                                        }

                                        $(e.target).val(inputValue);
                                      } else if (inputValue.length > 2) {
                                        if (
                                          inputValue.length === 3 &&
                                          inputValue.slice(-1) !== "."
                                        ) {
                                          inputValue = correctInputValue(
                                            inputValue,
                                            2
                                          );
                                        } else if (
                                          inputValue.length === 6 &&
                                          inputValue.slice(-1) !== "."
                                        ) {
                                          inputValue = correctInputValue(
                                            inputValue,
                                            5
                                          );
                                        }

                                        let inputValueSplit =
                                          inputValue.split(".");

                                        if (parseInt(inputValueSplit[0]) > 31) {
                                          inputValueSplit[0] = "31";
                                          $(e.target).val(
                                            inputValueSplit[0] + "."
                                          );
                                        }

                                        if (
                                          inputValueSplit.length > 1 &&
                                          parseInt(inputValueSplit[1]) > 12
                                        ) {
                                          inputValueSplit[1] = "12";
                                          $(e.target).val(
                                            inputValueSplit[0] +
                                              "." +
                                              inputValueSplit[1] +
                                              "."
                                          );
                                        } else {
                                          let tmpValue = "";
                                          for (
                                            let i = 0;
                                            i < inputValueSplit.length;
                                            i++
                                          ) {
                                            tmpValue += inputValueSplit[i];
                                            if (
                                              i + 1 <
                                              inputValueSplit.length
                                            ) {
                                              tmpValue += ".";
                                            }
                                          }
                                          $(e.target).val(tmpValue);
                                        }
                                      }
                                    } else {
                                      $(e.target).val(
                                        inputValue.substring(0, 10)
                                      );
                                    }

                                    if (inputValue.length === 10) {
                                      let inputValueSplit =
                                        inputValue.split(".");
                                      let tmpPassengerObject =
                                        this.state.passengerObject;

                                      tmpPassengerObject["adt-1"]["birthdate"] =
                                        new Date(
                                          inputValueSplit[2] +
                                            "-" +
                                            inputValueSplit[1] +
                                            "-" +
                                            inputValueSplit[0]
                                        );
                                      tmpPassengerObject["adt-1"][
                                        "displayBirthdate"
                                      ] = inputValue;
                                      this.closeDatePicker();

                                      if (
                                        this.state.vehicleDriverInformation
                                          .birthdate === null
                                      ) {
                                        let tmpVehicleDriverInformation =
                                          Object.assign(
                                            {},
                                            this.state.vehicleDriverInformation
                                          );
                                        tmpVehicleDriverInformation.birthdate =
                                          new Date(
                                            inputValueSplit[2] +
                                              "-" +
                                              inputValueSplit[1] +
                                              "-" +
                                              inputValueSplit[0]
                                          );

                                        this.setState({
                                          passengerObject:
                                            this.state.passengerObject,
                                          vehicleDriverInformation:
                                            tmpVehicleDriverInformation,
                                        });
                                      }

                                      this.setState({
                                        passengerObject: tmpPassengerObject,
                                      });
                                    }
                                  }
                                } else {
                                  $(e.target).val(
                                    inputValue.substring(
                                      0,
                                      inputValue.length - 1
                                    )
                                  );
                                }
                              }}
                              onClick={() => {
                                if (
                                  typeof $("#calendar-adt-1").attr("style") !==
                                  "undefined"
                                ) {
                                  $("#calendar-adt-1").removeAttr("style");
                                } else {
                                  $("#calendar-adt-1").css("display", "none");
                                }
                              }}
                              InputLabelProps={
                                this.state.passengerObject["adt-1"][
                                  "displayBirthdate"
                                ] !== ""
                                  ? { shrink: true }
                                  : {}
                              }
                            />
                            <div
                              id="calendar-adt-1"
                              style={{ display: "none" }}
                              data-passengerValue="adt-1"
                            >
                              <OutsideClickHandler
                                onOutsideClick={(event) => {
                                  if (
                                    typeof $(event.target)
                                      .closest('div[id^="calendar-"]')
                                      .attr("data-passengervalue") ===
                                    "undefined"
                                  ) {
                                    this.closeDatePicker();
                                  }
                                }}
                              >
                                <Calendar
                                  maxDate={
                                    new Date(
                                      this.state.passengerObject[
                                        "adt-1"
                                      ].maxDate
                                    )
                                  }
                                  value={
                                    new Date(
                                      this.state.passengerObject["adt-1"][
                                        "birthdate"
                                      ]
                                    )
                                  }
                                  onClickDay={(value, event) => {
                                    let tmpPassengerObject =
                                      this.state.passengerObject;

                                    tmpPassengerObject["adt-1"]["birthdate"] =
                                      value;
                                    tmpPassengerObject["adt-1"][
                                      "displayBirthdate"
                                    ] = AtrDateFormat(value);

                                    $(
                                      "#flightcheckout-card-main-passenger-birthdate"
                                    ).val(
                                      tmpPassengerObject["adt-1"][
                                        "displayBirthdate"
                                      ]
                                    );

                                    this.closeDatePicker();

                                    if (
                                      this.state.vehicleDriverInformation
                                        .birthdate === null
                                    ) {
                                      let tmpVehicleDriverInformation =
                                        Object.assign(
                                          {},
                                          this.state.vehicleDriverInformation
                                        );
                                      tmpVehicleDriverInformation.birthdate =
                                        value;

                                      this.setState({
                                        passengerObject:
                                          this.state.passengerObject,
                                        vehicleDriverInformation:
                                          tmpVehicleDriverInformation,
                                      });
                                    }
                                    this.setState({
                                      passengerObject: tmpPassengerObject,
                                    });
                                  }}
                                />
                              </OutsideClickHandler>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={1} />
                          {!isB2C() ? (
                            <React.Fragment>
                              <Grid item xs={12} md={2} />
                              <Grid item xs={12} md={3}>
                                <TextField
                                  error={showValidationEmail}
                                  helperText={
                                    showValidationEmail ? invalidMsg : ""
                                  }
                                  style={{ width: "100%" }}
                                  name="flightcheckout-card-main-passenger-email"
                                  label="E-Mail"
                                  variant="outlined"
                                  onBlur={(e) => {
                                    this.state.passengerObject["adt-1"][
                                      "email"
                                    ] = e.target.value;
                                    this.setState({
                                      passengerObject:
                                        this.state.passengerObject,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <PhoneCountryCode
                                  customerObject={{
                                    value:
                                      this.state.passengerObject["adt-1"][
                                        "phoneCountryCode"
                                      ],
                                  }}
                                  style={{ width: "100%" }}
                                  updateCustomerValue={(phoneCountryCode) => {
                                    let tmpCustomerObject = Object.assign(
                                      {},
                                      this.state.passengerObject
                                    );
                                    tmpCustomerObject["adt-1"][
                                      "phoneCountryCode"
                                    ] = phoneCountryCode;

                                    this.setState({
                                      passengerObject: tmpCustomerObject,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  error={showValidationPhone}
                                  helperText={
                                    showValidationPhone
                                      ? this.state.passengerObject[
                                          "adt-1"
                                        ].hasOwnProperty("phone") &&
                                        this.state.passengerObject["adt-1"][
                                          "phone"
                                        ].length < 8
                                        ? t("react.flight.phone.minlengtherror")
                                        : invalidMsg
                                      : ""
                                  }
                                  style={{ width: "100%" }}
                                  name="flightcheckout-card-main-passenger-phone"
                                  label={t("react.generel.phone")}
                                  variant="outlined"
                                  onBlur={(e) => {
                                    this.state.passengerObject["adt-1"][
                                      "phone"
                                    ] = e.target.value;
                                    this.setState({
                                      passengerObject:
                                        this.state.passengerObject,
                                    });
                                  }}
                                />
                              </Grid>
                              {this.props.location.state.selectedFlight[0]
                                .hesCodeRequired ||
                              (this.props.location.state.selectedFlight.length >
                                1 &&
                                this.props.location.state.selectedFlight[1]
                                  .hesCodeRequired) ? (
                                <React.Fragment>
                                  <Grid item xs={12} md={2} />
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      error={showValidationHesCode}
                                      helperText={
                                        showValidationHesCode
                                          ? this.state.passengerObject[
                                              "adt-1"
                                            ].hasOwnProperty("hesCode") &&
                                            this.state.passengerObject["adt-1"][
                                              "hesCode"
                                            ].length < 6
                                            ? t(
                                                "react.flight.hescode.minlengtherror"
                                              )
                                            : invalidMsg
                                          : ""
                                      }
                                      style={{ width: "100%" }}
                                      name="flightcheckout-card-main-passenger-hescode"
                                      label="HES Code"
                                      variant="outlined"
                                      onBlur={(e) => {
                                        this.state.passengerObject["adt-1"][
                                          "hesCode"
                                        ] = e.target.value;
                                        this.setState({
                                          passengerObject:
                                            this.state.passengerObject,
                                        });
                                      }}
                                    />
                                  </Grid>
                                </React.Fragment>
                              ) : (
                                <Grid item xs={12} md={5} />
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {this.state.isSelectBaggageReady ? (
                            <React.Fragment>
                              {this.state.optionBooking.map((option, index) => (
                                <Grid item xs={12} md={3}>
                                  <TextField
                                    style={{ width: "100%" }}
                                    name={
                                      index +
                                      "-i-flightcheckout-addbaggage-adt-1"
                                    }
                                    id="flightcheckout-addbaggage-adt-1"
                                    label={t("react.checkout.addbaggage")}
                                    variant="outlined"
                                    select
                                    onChange={this.handleChangeBaggage}
                                  >
                                    {this.state.optionBooking[
                                      index
                                    ].options.map((option) => (
                                      <MenuItem key={option.key} value={option}>
                                        {option.descr}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              ))}
                              <Grid
                                item
                                xs={12}
                                md={
                                  this.state.optionBooking.length === 1
                                    ? 7
                                    : this.state.optionBooking === 2
                                    ? 4
                                    : 9
                                }
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {isB2C() ? (
                            <React.Fragment>
                              <Grid item xs={12} md={2} />
                              <Grid item xs={12} md={3}>
                                <TextField
                                  style={{ width: "100%" }}
                                  name="flightcheckout-card-main-passenger-miles-mores"
                                  label="Miles & Mores"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} md={7} />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {this.props.location.state.selectedFlight[0]
                            .passportRequired ? (
                            <SetPassportData
                              passportinfo={
                                this.state.passengerObject["adt-1"].passportInfo
                              }
                              genderType={"adt-1"}
                              updatePassportNationality={(nationality) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportNationality.value = nationality;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                              updatePassportNumber={(passportNumber) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportNumber.value = passportNumber;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                              updatePassportExpiryDate={(expiryDate) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportExpiryDate.value = expiryDate;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                              updatePassportCountryOfIssue={(
                                countryOfIssue
                              ) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportCountryOfIssue.value = countryOfIssue;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                            />
                          ) : (
                            <div />
                          )}
                        </Grid>
                      </div>
                    </div>

                    {adtCount > 1
                      ? this.buildPassengerSection(adtCount, "adt")
                      : ""}
                    {chdCount >= 1
                      ? this.buildPassengerSection(chdCount, "chd")
                      : ""}
                    {infCount >= 1
                      ? this.buildPassengerSection(infCount, "inf")
                      : ""}
                  </div>

                  {!isB2C() ? (
                    ""
                  ) : (
                    <div>
                      <div className="flightcheckout-card-contactinformation">
                        <div
                          style={{
                            fontSize: "21px",
                            fontWeight: "400",
                            paddingBottom: "1.25vw",
                          }}
                        >
                          {t("react.general.contactinformation")}
                        </div>

                        <ContactInformation
                          showValidation={this.state.showValidation}
                          contactInformation={this.state.contactInformation}
                          updateContactInformation={(type, value) => {
                            let tmpContactInformation = Object.assign(
                              {},
                              this.state.contactInformation
                            );
                            tmpContactInformation[type] = value;
                            this.setState({
                              contactInformation: tmpContactInformation,
                            });
                          }}
                        />
                      </div>
                      <div className="flightcheckout-card-contactinformation-confirmation-information">
                        *Nach Abschluss der Buchung erhalten Sie Ihre
                        Buchungsbestätigung per E-Mail.
                      </div>
                    </div>
                  )}

                  {this.state.selectedCheckoutVehicle !== null ? (
                    <FlightRentalcarDriverInformation
                      showValidation={this.state.showValidation}
                      vehicleDriverInformation={
                        this.state.vehicleDriverInformation
                      }
                      updateDriverInformation={(type, value) => {
                        let tmpVehicleDriverInformation =
                          this.state.vehicleDriverInformation;
                        tmpVehicleDriverInformation[type] = value;
                        this.setState({
                          vehicleDriverInformation: tmpVehicleDriverInformation,
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {this.state.selectedInsurance !== null ? (
                    <AddressInformation
                      showValidation={this.state.showValidation}
                      addressInformation={this.state.addressInformation}
                      updateAddressInformation={(type, value) => {
                        let tmpAddressInformation = Object.assign(
                          {},
                          this.state.addressInformation
                        );
                        tmpAddressInformation[type] = value;
                        this.setState({
                          addressInformation: tmpAddressInformation,
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {/*
                                            !isB2C() ?
                                                ''
                                                :
                                                <div className = "flightcheckout-card-remember-contact">
                                                    <AtrCheckbox
                                                        name = "checkbox-remember-contact"
                                                        helperText = ""
                                                        label = "Speichern Sie Ihre Daten für zukünfitge Buchungen"
                                                        style = {{ width: "100%" }}
                                                    />
                                                </div>
                                        */}

                  {!isB2C() ? (
                    ""
                  ) : (
                    <div className="flightcheckout-card-guidelines">
                      <div style={{ fontSize: "13px" }}>
                        Im Hinblick auf Nur-Flug-Buchungen treten wir,{" "}
                        {this.state.agencyName}, lediglich als Vermittler auf.
                        Unsere Pflichten beschränken sich auf die Vermittlung
                        des dabei zwischen Ihnen und der Fluggesellschaft
                        zustande kommenden Luftbeförderungsvertrages. Dieses
                        Vertragsverhältnis wird durch die Allgemeinen
                        Geschäfts-/Beförderungsbedingungen der Fluggesellschaft
                        geregelt. Bitte lesen Sie diese Bedingungen sorgfältig
                        durch, da sie vertragsrelevante Informationen (z.B.
                        Umbuchungs-, Rücktrittsbedingungen, Haftungsregelungen
                        etc.) enthalten. Bitte beachten Sie auch die
                        Tarifbestimmungen der Fluggesellschaft. Diese können
                        Sonderregelungen aufweisen, z.B. im Hinblick auf die
                        Gepäckbeförderung oder das Check-In-Verfahren. Für die
                        Einhaltung der Pass-, Visa-, Devisen- und/ oder
                        Gesundheitsbestimmungen des jeweiligen Reiseziellandes
                        ist der Reisende selber verantwortlich.Wir empfehlen
                        Ihnen, sich hierüber möglichst frühzeitig bei den dazu
                        autorisierten Stellen (Konsulate, Botschaften,
                        Auswärtiges Amt, etc.) zu informieren. Dies gilt
                        insbesondere im Hinblick auf die Frage, ob bei
                        planmäßigen Zwischenlandungen ein Visum für das
                        Transitland erforderlich ist. Bitte lassen Sie 2 Tage
                        vor Abflug (beim Hin und/oder Rückflug) die Flugzeiten
                        bei der jeweils zuständigen Fluggesellschaft oder bei
                        Ihrem Reisebüro auf eventuell Änderungen prüfen. Bitte
                        seien Sie 2 Stunden vor dem Abflug am Check-In, halten
                        Sie sich an die Freigepäcksgrenze. Bei Übergewicht
                        können Gebühren entstehen, bei denen das Reisebüro nicht
                        haftet. Bei eventuellen Änderungen der Reisedaten ab
                        Ticketausstellung entstehen generell Umbuchungsgebühren.
                        Bei Nichtreiseantritt und Stornierung vor Abflug
                        entstehen Stornokosten.
                      </div>
                    </div>
                  )}

                  <div style={{ display: "flow-root" }}>
                    <div style={{ float: "left", paddingTop: "20px" }}>
                      <JupiterSubmitButton
                        btnWidth={"auto"}
                        btnHeight={"60px"}
                        btnLabel={t("react.generel.back")}
                        btnOnClick={() => {
                          this.goBack();
                        }}
                      />
                    </div>
                    <div style={{ float: "right", paddingTop: "20px" }}>
                      <JupiterSubmitButton
                        btnWidth={"250px"}
                        btnHeight={"60px"}
                        btnLabel={
                          isB2C()
                            ? t("react.generel.booknow")
                            : t("react.generel.reserve")
                        }
                        btnOnClick={() => {
                          this.reserve();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <StickyContainer>
                <Sticky topOffset={-stickyDistanceFromTop}>
                  {({ style, isSticky }) => (
                    <React.Fragment>
                      <div
                        style={{
                          ...style,
                          top: isSticky ? `${stickyDistanceFromTop}px` : "0px",
                          marginTop: "1.5vw",
                        }}
                      >
                        <FlightPriceOverview
                          adtCount={this.props.location.state.adtCount}
                          chdCount={this.props.location.state.chdCount}
                          infCount={this.props.location.state.infCount}
                          t={this.props.location.state.t}
                          selectedFlight={
                            this.props.location.state.selectedFlight
                          }
                          isRoundTripFlightAvailable={
                            this.props.location.state.isRoundTripFlightAvailable
                          }
                          selectedBaggage={this.state.selectedBaggage}
                          isMultiFlight={
                            this.props.location.state.checkedMultiFlights
                              ? true
                              : this.props.location.state.selectedFlight
                                  .length > 1
                          }
                          serviceFeePerPaxAndRoute={
                            this.props.location.state.serviceFeePerPaxAndRoute
                          }
                          vehicle={this.state.selectedCheckoutVehicle}
                          insurance={this.state.selectedInsurance}
                        />
                      </div>
                      {!isB2C() ? (
                        <FlightOfferExportBucket
                          location={this.props.location}
                          checkedMultiFlights={this.state.checkedMultiFlights}
                          roundTrip={this.state.roundTrip}
                          isMultiFlight={
                            this.props.location.state.checkedMultiFlights
                              ? true
                              : this.props.location.state.selectedFlight
                                  .length > 1
                          }
                          selectedFlight={
                            this.props.location.state.selectedFlight
                          }
                          serviceFeePerPaxAndRoute={
                            this.props.location.state.serviceFeePerPaxAndRoute
                          }
                        />
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  )}
                </Sticky>
              </StickyContainer>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <div style={{ marginTop: "1.5vw" }}>
                <FlightPriceOverview
                  adtCount={this.props.location.state.adtCount}
                  chdCount={this.props.location.state.chdCount}
                  infCount={this.props.location.state.infCount}
                  t={this.props.location.state.t}
                  selectedFlight={this.props.location.state.selectedFlight}
                  isRoundTripFlightAvailable={
                    this.props.location.state.isRoundTripFlightAvailable
                  }
                  selectedBaggage={this.state.selectedBaggage}
                  isMultiFlight={
                    this.props.location.state.checkedMultiFlights
                      ? true
                      : this.props.location.state.selectedFlight.length > 1
                  }
                  serviceFeePerPaxAndRoute={
                    this.props.location.state.serviceFeePerPaxAndRoute
                  }
                  vehicle={this.state.selectedCheckoutVehicle}
                  insurance={this.state.selectedInsurance}
                />
              </div>
              {!isB2C() ? (
                <FlightOfferExportBucket
                  location={this.props.location}
                  checkedMultiFlights={this.state.checkedMultiFlights}
                  roundTrip={this.state.roundTrip}
                  isMultiFlight={
                    this.props.location.state.checkedMultiFlights
                      ? true
                      : this.props.location.state.selectedFlight.length > 1
                  }
                  selectedFlight={this.props.location.state.selectedFlight}
                  serviceFeePerPaxAndRoute={
                    this.props.location.state.serviceFeePerPaxAndRoute
                  }
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <div>
                <div className="flightcheckout-card">{flightInfoDom}</div>
                <div>
                  {this.state.insurancePrices !== null && checkIsAdmin() ? (
                    <AddInsuranceToFlight
                      adtCount={this.props.location.state.adtCount}
                      chdCount={this.props.location.state.chdCount}
                      infCount={this.props.location.state.infCount}
                      insurancePrices={this.state.insurancePrices}
                      isInsuranceSelected={
                        this.state.selectedInsurance !== null
                      }
                      selectedInsurance={(insurance) => {
                        this.setState({ selectedInsurance: insurance });
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <div className="flightcheckout-card-passagierinfo">
                    <div
                      style={{
                        fontSize: "21px",
                        fontWeight: "400",
                        paddingBottom: "1.25vw",
                      }}
                    >
                      {t("react.checkout.passengerdata")}
                    </div>

                    <div className="flightcheckout-card-main-passenger">
                      <div
                        style={{
                          paddingBottom: "0.74vw",
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                        {t("react.checkout.maincontact")}
                      </div>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={2}>
                            <TextField
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-passengerObject-adt-1"
                              id="flightcheckout-card-main-passenger-passengerObject-adt-1"
                              label={t("react.generel.gender")}
                              variant="outlined"
                              select
                              value={this.state.passengerObject["adt-1"].gender}
                              defaultValue={
                                this.state.passengerObject["adt-1"].gender
                              }
                              onChange={this.handleChangeGender}
                            >
                              {this.gender.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              error={showValidationFirstname}
                              helperText={
                                showValidationFirstname ? invalidMsg : ""
                              }
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-firstname"
                              label={t("react.generel.firstname")}
                              variant="outlined"
                              onBlur={(e) => {
                                this.state.passengerObject["adt-1"].firstname =
                                  e.target.value;
                                this.setState({
                                  passengerObject: this.state.passengerObject,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              error={showValidationSurname}
                              helperText={
                                showValidationSurname ? invalidMsg : ""
                              }
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-surname"
                              label={t("react.generel.name")}
                              variant="outlined"
                              onBlur={(e) => {
                                this.state.passengerObject["adt-1"].surname =
                                  e.target.value;
                                this.setState({
                                  passengerObject: this.state.passengerObject,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              error={showValidationBirthdate}
                              helperText={
                                showValidationBirthdate ? invalidMsg : ""
                              }
                              style={{ width: "100%" }}
                              name="flightcheckout-card-main-passenger-birthdate"
                              id="flightcheckout-card-main-passenger-birthdate"
                              label={t("react.generel.birthdate")}
                              variant="outlined"
                              onChange={(e) => {
                                const re = /^[0-9.]*$/;

                                let inputValue = e.target.value;

                                if (
                                  re.test(inputValue) &&
                                  !inputValue.includes("..")
                                ) {
                                  if (inputValue.slice(-1) === ".") {
                                    if (
                                      inputValue.length === 3 ||
                                      inputValue.length === 6
                                    ) {
                                      $(e.target).val(inputValue);
                                    }
                                  }
                                  if (re.test(inputValue)) {
                                    if (inputValue.length < 11) {
                                      if (
                                        inputValue.length === 2 ||
                                        inputValue.length === 5
                                      ) {
                                        if (
                                          inputValue.length === 2 &&
                                          inputValue[inputValue.length - 1] ===
                                            "."
                                        ) {
                                          inputValue = "0" + inputValue;
                                        } else if (
                                          inputValue.length === 5 &&
                                          inputValue[inputValue.length - 1] ===
                                            "."
                                        ) {
                                          let tmpInputValue =
                                            inputValue.split(".")[0] + ".";

                                          if (
                                            inputValue.split(".")[1] === "0"
                                          ) {
                                            tmpInputValue += "01.";
                                          } else {
                                            tmpInputValue +=
                                              "0" +
                                              inputValue.split(".")[1] +
                                              ".";
                                          }
                                          inputValue = tmpInputValue;
                                        }

                                        $(e.target).val(inputValue);
                                      } else if (inputValue.length > 2) {
                                        if (
                                          inputValue.length === 3 &&
                                          inputValue.slice(-1) !== "."
                                        ) {
                                          inputValue = correctInputValue(
                                            inputValue,
                                            2
                                          );
                                        } else if (
                                          inputValue.length === 6 &&
                                          inputValue.slice(-1) !== "."
                                        ) {
                                          inputValue = correctInputValue(
                                            inputValue,
                                            5
                                          );
                                        }

                                        let inputValueSplit =
                                          inputValue.split(".");

                                        if (parseInt(inputValueSplit[0]) > 31) {
                                          inputValueSplit[0] = "31";
                                          $(e.target).val(
                                            inputValueSplit[0] + "."
                                          );
                                        }

                                        if (
                                          inputValueSplit.length > 1 &&
                                          parseInt(inputValueSplit[1]) > 12
                                        ) {
                                          inputValueSplit[1] = "12";
                                          $(e.target).val(
                                            inputValueSplit[0] +
                                              "." +
                                              inputValueSplit[1] +
                                              "."
                                          );
                                        } else {
                                          let tmpValue = "";
                                          for (
                                            let i = 0;
                                            i < inputValueSplit.length;
                                            i++
                                          ) {
                                            tmpValue += inputValueSplit[i];
                                            if (
                                              i + 1 <
                                              inputValueSplit.length
                                            ) {
                                              tmpValue += ".";
                                            }
                                          }
                                          $(e.target).val(tmpValue);
                                        }
                                      }
                                    } else {
                                      $(e.target).val(
                                        inputValue.substring(0, 10)
                                      );
                                    }

                                    if (inputValue.length === 10) {
                                      let inputValueSplit =
                                        inputValue.split(".");
                                      let tmpPassengerObject =
                                        this.state.passengerObject;

                                      tmpPassengerObject["adt-1"]["birthdate"] =
                                        new Date(
                                          inputValueSplit[2] +
                                            "-" +
                                            inputValueSplit[1] +
                                            "-" +
                                            inputValueSplit[0]
                                        );
                                      tmpPassengerObject["adt-1"][
                                        "displayBirthdate"
                                      ] = inputValue;
                                      this.closeDatePicker();
                                      this.setState({
                                        passengerObject: tmpPassengerObject,
                                      });
                                    }
                                  }
                                } else {
                                  $(e.target).val(
                                    inputValue.substring(
                                      0,
                                      inputValue.length - 1
                                    )
                                  );
                                }
                              }}
                              onClick={() => {
                                if (
                                  typeof $("#calendar-adt-1").attr("style") !==
                                  "undefined"
                                ) {
                                  $("#calendar-adt-1").removeAttr("style");
                                } else {
                                  $("#calendar-adt-1").css("display", "none");
                                }
                              }}
                              InputLabelProps={
                                this.state.passengerObject["adt-1"][
                                  "displayBirthdate"
                                ] !== ""
                                  ? { shrink: true }
                                  : {}
                              }
                            />
                            <div
                              id="calendar-adt-1"
                              style={{ display: "none" }}
                              data-passengerValue="adt-1"
                            >
                              <OutsideClickHandler
                                onOutsideClick={(event) => {
                                  if (
                                    typeof $(event.target)
                                      .closest('div[id^="calendar-"]')
                                      .attr("data-passengervalue") ===
                                    "undefined"
                                  ) {
                                    this.closeDatePicker();
                                  }
                                }}
                              >
                                <Calendar
                                  maxDate={
                                    new Date(
                                      this.state.passengerObject[
                                        "adt-1"
                                      ].maxDate
                                    )
                                  }
                                  value={
                                    new Date(
                                      this.state.passengerObject["adt-1"][
                                        "birthdate"
                                      ]
                                    )
                                  }
                                  onClickDay={(value, event) => {
                                    let tmpPassengerObject =
                                      this.state.passengerObject;

                                    tmpPassengerObject["adt-1"]["birthdate"] =
                                      value;
                                    tmpPassengerObject["adt-1"][
                                      "displayBirthdate"
                                    ] = AtrDateFormat(value);

                                    $(
                                      "#flightcheckout-card-main-passenger-birthdate"
                                    ).val(
                                      tmpPassengerObject["adt-1"][
                                        "displayBirthdate"
                                      ]
                                    );

                                    this.closeDatePicker();
                                    this.setState({
                                      passengerObject: tmpPassengerObject,
                                    });
                                  }}
                                />
                              </OutsideClickHandler>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={1} />
                          {!isB2C() ? (
                            <React.Fragment>
                              <Grid item xs={12} md={2} />
                              <Grid item xs={12} md={3}>
                                <TextField
                                  error={showValidationEmail}
                                  helperText={
                                    showValidationEmail ? invalidMsg : ""
                                  }
                                  style={{ width: "100%" }}
                                  name="flightcheckout-card-main-passenger-email"
                                  label="E-Mail"
                                  variant="outlined"
                                  onBlur={(e) => {
                                    this.state.passengerObject["adt-1"][
                                      "email"
                                    ] = e.target.value;
                                    this.setState({
                                      passengerObject:
                                        this.state.passengerObject,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  error={showValidationPhone}
                                  helperText={
                                    showValidationPhone
                                      ? this.state.passengerObject[
                                          "adt-1"
                                        ].hasOwnProperty("phone") &&
                                        this.state.passengerObject["adt-1"][
                                          "phone"
                                        ].length < 8
                                        ? t("react.flight.phone.minlengtherror")
                                        : invalidMsg
                                      : ""
                                  }
                                  style={{ width: "100%" }}
                                  name="flightcheckout-card-main-passenger-phone"
                                  label={t("react.generel.phone")}
                                  variant="outlined"
                                  onBlur={(e) => {
                                    this.state.passengerObject["adt-1"][
                                      "phone"
                                    ] = e.target.value;
                                    this.setState({
                                      passengerObject:
                                        this.state.passengerObject,
                                    });
                                  }}
                                />
                              </Grid>
                              {this.props.location.state.selectedFlight[0]
                                .hesCodeRequired ? (
                                <React.Fragment>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      error={showValidationHesCode}
                                      helperText={
                                        showValidationHesCode
                                          ? this.state.passengerObject[
                                              "adt-1"
                                            ].hasOwnProperty("hesCode") &&
                                            this.state.passengerObject["adt-1"][
                                              "hesCode"
                                            ].length < 6
                                            ? t(
                                                "react.flight.hescode.minlengtherror"
                                              )
                                            : invalidMsg
                                          : ""
                                      }
                                      style={{ width: "100%" }}
                                      name="flightcheckout-card-main-passenger-hescode"
                                      label="HES Code"
                                      variant="outlined"
                                      onBlur={(e) => {
                                        this.state.passengerObject["adt-1"][
                                          "hesCode"
                                        ] = e.target.value;
                                        this.setState({
                                          passengerObject:
                                            this.state.passengerObject,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={1} />
                                </React.Fragment>
                              ) : (
                                <Grid item xs={12} md={4} />
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {this.state.isSelectBaggageReady ? (
                            <React.Fragment>
                              <Grid item xs={12} md={2} />
                              {this.state.optionBooking.map((option, index) => (
                                <Grid item xs={12} md={3}>
                                  <TextField
                                    style={{ width: "100%" }}
                                    name={
                                      index +
                                      "-i-flightcheckout-addbaggage-adt-1"
                                    }
                                    id="flightcheckout-addbaggage-adt-1"
                                    label={t("react.checkout.addbaggage")}
                                    variant="outlined"
                                    select
                                    onChange={this.handleChangeBaggage}
                                  >
                                    {this.state.optionBooking[
                                      index
                                    ].options.map((option) => (
                                      <MenuItem key={option.key} value={option}>
                                        {option.descr}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              ))}
                              <Grid
                                item
                                xs={12}
                                md={
                                  this.state.optionBooking.length === 1
                                    ? 7
                                    : this.state.optionBooking === 2
                                    ? 4
                                    : 9
                                }
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {isB2C() ? (
                            <React.Fragment>
                              <Grid item xs={12} md={2} />
                              <Grid item xs={12} md={3}>
                                <TextField
                                  error={showValidationEmail}
                                  helperText={
                                    showValidationEmail ? invalidMsg : ""
                                  }
                                  style={{ width: "100%" }}
                                  name="flightcheckout-card-main-passenger-miles-mores"
                                  label="Miles & More"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} md={7} />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {this.props.location.state.selectedFlight[0]
                            .passportRequired ? (
                            <SetPassportData
                              passportinfo={
                                this.state.passengerObject["adt-1"].passportInfo
                              }
                              genderType={"adt-1"}
                              updatePassportNationality={(nationality) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportNationality.value = nationality;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                              updatePassportNumber={(passportNumber) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportNumber.value = passportNumber;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                              updatePassportExpiryDate={(expiryDate) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportExpiryDate.value = expiryDate;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                              updatePassportCountryOfIssue={(
                                countryOfIssue
                              ) => {
                                let tmpPassengerObject = Object.assign(
                                  {},
                                  this.state.passengerObject
                                );
                                tmpPassengerObject[
                                  "adt-1"
                                ].passportInfo.passportCountryOfIssue.value = countryOfIssue;

                                this.setState({
                                  passengerObject: tmpPassengerObject,
                                });
                              }}
                            />
                          ) : (
                            <div />
                          )}
                        </Grid>
                      </div>
                    </div>

                    {adtCount > 1
                      ? this.buildPassengerSection(adtCount, "adt")
                      : ""}
                    {chdCount >= 1
                      ? this.buildPassengerSection(chdCount, "chd")
                      : ""}
                    {infCount >= 1
                      ? this.buildPassengerSection(infCount, "inf")
                      : ""}
                  </div>

                  {!isB2C() ? (
                    ""
                  ) : (
                    <div>
                      <div className="flightcheckout-card-contactinformation">
                        <div
                          style={{
                            fontSize: "21px",
                            fontWeight: "400",
                            paddingBottom: "1.25vw",
                          }}
                        >
                          {t("react.general.contactinformation")}
                        </div>

                        <ContactInformation
                          showValidation={this.state.showValidation}
                          contactInformation={this.state.contactInformation}
                          updateContactInformation={(type, value) => {
                            let tmpContactInformation = Object.assign(
                              {},
                              this.state.contactInformation
                            );
                            tmpContactInformation[type] = value;
                            this.setState({
                              contactInformation: tmpContactInformation,
                            });
                          }}
                        />
                      </div>
                      <div className="flightcheckout-card-contactinformation-confirmation-information">
                        *Nach Abschluss der Buchung erhalten Sie Ihre
                        Buchungsbestätigung per E-Mail.
                      </div>
                    </div>
                  )}

                  {!isB2C()
                    ? ""
                    : /*--<div className = "flightcheckout-card-remember-contact">
                                                    <AtrCheckbox
                                                        name = "checkbox-remember-contact"
                                                        helperText = ""
                                                        label = "Speichern Sie Ihre Daten für zukünfitge Buchungen"
                                                        style = {{ width: "100%" }}
                                                    />
                                                </div>*/
                      ""}

                  {!isB2C() ? (
                    ""
                  ) : (
                    <div className="flightcheckout-card-guidelines">
                      <div style={{ fontSize: "13px" }}>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                        ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        diam nonumy eirmod tempor invidunt ut labore et dolore
                        magna aliquyam erat, sed diam voluptua. At vero eos et
                        accusam et justo duo dolores et ea rebum. Stet clita
                        kasd gubergren, no sea takimata sanctus est Lorem ipsum
                        dolor sit amet.
                      </div>
                    </div>
                  )}

                  <div style={{ display: "flow-root" }}>
                    <div style={{ float: "left", paddingTop: "20px" }}>
                      <JupiterSubmitButton
                        btnWidth={"auto"}
                        btnHeight={"60px"}
                        btnLabel={t("react.generel.back")}
                        btnOnClick={() => {
                          this.goBack();
                        }}
                      />
                    </div>
                    <div style={{ float: "right", paddingTop: "20px" }}>
                      <JupiterSubmitButton
                        btnWidth={"250px"}
                        btnHeight={"60px"}
                        btnLabel={
                          isB2C()
                            ? t("react.generel.booknow")
                            : t("react.generel.reserve")
                        }
                        btnOnClick={() => {
                          this.reserve();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}

        {this.props.location.state.selectedFlight[0]
          .offersAncillaryServicesDuringFlightSearch &&
        !this.state.isSelectBaggageReady &&
        false
          ? this.loadingOverlay()
          : ""}
        {!this.state.loaded ? this.loadingOverlay() : ""}
      </div>
    );
  }
}

export default FlightCheckout;

function correctInputValue(value, index) {
  let newValue = "";

  for (let i = 0; i < value.length; i++) {
    if (i === index) {
      newValue += ".";
    }

    newValue += value[i];
  }

  return newValue;
}

function getBaggageReservationObject(selectedBaggage) {
  let baggage = {};
  let totalPrice = 0.0;
  let keys = Object.keys(selectedBaggage);
  for (let i = 0; i < keys.length; i++) {
    baggage[keys[i]] = [];
    let selectedBaggageKeys = [];
    for (let a = 0; a < selectedBaggage[keys[i]].length; a++) {
      if (
        selectedBaggage[keys[i]][a].key !== "-1" &&
        selectedBaggage[keys[i]][a].hasOwnProperty("price")
      ) {
        totalPrice += selectedBaggage[keys[i]][a].price;
        selectedBaggageKeys.push(selectedBaggage[keys[i]][a].key);
      } else {
        selectedBaggageKeys.push("");
      }
    }
    baggage[keys[i]].keys = selectedBaggageKeys;
  }

  baggage["totalprice"] = totalPrice;

  return baggage;
}

function determineNewInsurance(insurance, selectedInsurance) {
  let keys = Object.keys(insurance);
  for (let i = 0; i < keys.length; i++) {
    let tmpInsurance = insurance[keys[i]];
    if (
      tmpInsurance.type === selectedInsurance.type &&
      tmpInsurance.withDeductible === selectedInsurance.withDeductible
    ) {
      return tmpInsurance;
    }
  }

  return selectedInsurance;
}
