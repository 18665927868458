import { nationalities } from "components/common/HeroSearchForm/NationalityInput";
import moment from "moment";

class ValidationService {
  static date(dateString: string | null, format: string = "YYYY-MM-DD") {
    return moment(dateString, format, true).isValid();
  }

  static nationality(code: string | null) {
    return nationalities.some((nationality) => nationality.code === code);
  }

  static roomOccupancies(roomOccupancies: string | null) {
    return /^(\[[0-9]+(,[0-9]+)*\]\|*)+$/.test(roomOccupancies || "");
  }
}

export default ValidationService;
