import React from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Button,
  Grid,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import moment from 'moment';
import { useFormContext } from 'pages/Accounting/contexts/FormContext';
import { DatePickermui } from './DatePickermui';

interface FlightInfo {
  id: number;
  airline: string;
  flightNumber: string;
  class: 'Economy' | 'Business' | 'First';
  date: moment.Moment;
  from: string;
  to: string;
  baggage: string;
  stopover: string;
  departureTime: string;
  arrivalTime: string;
}

const FlightInformationForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext();

  const addFlight = () => {
    const newFlightId = formData.flights.length > 0 
      ? Math.max(...formData.flights.map(f => f.id)) + 1 
      : 1;
    
    updateFormData({
      flights: [
        ...formData.flights,
        {
          id: newFlightId,
          airline: '',
          flightNumber: '',
          class: 'Economy',
          date: moment(),
          from: '',
          to: '',
          baggage: '',
          stopover: '',
          departureTime: '',
          arrivalTime: ''
        }
      ]
    });
  };

  const removeFlight = (id: number) => {
    updateFormData({
      flights: formData.flights.filter(f => f.id !== id)
    });
  };

  const updateFlight = (id: number, field: keyof (typeof formData.flights)[0], value: any) => {
    updateFormData({
      flights: formData.flights.map(f => 
        f.id === id ? {...f, [field]: value} : f
      )
    });
  };

  return (
    <div>
      {formData.flights.map((flight, index) => (
        <Paper key={flight.id} className="p-4 mb-4 relative">
          <Typography variant="h6" className="mb-4">
            Flight {index + 1}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Airline"
                value={flight.airline}
                onChange={(e) => updateFlight(flight.id, 'airline', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Flight Number"
                value={flight.flightNumber}
                onChange={(e) => updateFlight(flight.id, 'flightNumber', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Class</InputLabel>
                <Select
                  value={flight.class}
                  label="Class"
                  onChange={(e) => updateFlight(flight.id, 'class', e.target.value as 'Economy' | 'Business' | 'First')}
                >
                  <MenuItem value="Economy">Economy</MenuItem>
                  <MenuItem value="Business">Business</MenuItem>
                  <MenuItem value="First">First</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
            <DatePickermui
                date={flight.date}
                handleDateChange={(date) => updateFlight(flight.id, 'date', date)}
                className="w-full"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="From"
                value={flight.from}
                onChange={(e) => updateFlight(flight.id, 'from', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="To"
                value={flight.to}
                onChange={(e) => updateFlight(flight.id, 'to', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Baggage"
                value={flight.baggage}
                onChange={(e) => updateFlight(flight.id, 'baggage', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Stopover"
                value={flight.stopover}
                onChange={(e) => updateFlight(flight.id, 'stopover', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Departure Time"
                value={flight.departureTime}
                onChange={(e) => updateFlight(flight.id, 'departureTime', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Arrival Time"
                value={flight.arrivalTime}
                onChange={(e) => updateFlight(flight.id, 'arrivalTime', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          {formData.flights.length > 1 && (
            <IconButton 
              onClick={() => removeFlight(flight.id)}
              className="absolute top-2 right-2"
              color="error"
              size="small"
            >
              <Delete />
            </IconButton>
          )}
        </Paper>
      ))}
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={addFlight}
        startIcon={<Add />}
        className="mt-4"
      >
        Add Flight
      </Button>
    </div>
  );
};

export default FlightInformationForm;

