import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { postSaveRemarksUrl } from 'api/helpers/api-constants';
import postFetcher from 'api/helpers/postFetcher';

type RemarksSectionProps = {
  bookingKey: string;
  initialRemarks: string;
};

const RemarksSection: React.FC<RemarksSectionProps> = ({ bookingKey, initialRemarks }) => {
  const { t } = useTranslation();
  const [remarks, setRemarks] = useState<string>(initialRemarks || "");



  const saveRemarks = async (bookingKey: string, remarks: string) => {
    const payload = {
      bookingKey: bookingKey,
      remarks: remarks,
    };

    try {
      const response = await postFetcher(postSaveRemarksUrl(), payload);
      if (response) {
        Swal.fire(t("generals.success"), '', 'success');
      } else {
        console.error('API request failed with status:', response);
        Swal.fire(t('app.general-error'), '', 'error');
      }
    } catch (error) {
      console.error('API request failed with error:', error);
      Swal.fire(t('app.general-error'), '', 'error');
    }
  };

  const handleSaveRemarksBtnClick = () => {
    if (bookingKey && remarks) {
      saveRemarks(bookingKey, remarks);
    }
  };

  return (
    <div className="my-1 roundedpy-2 mx-2">
      <div className="border-1 flex h-full w-full justify-items-stretch rounded-md pb-4">
        <input
          className="h-full w-3/4 rounded-l-md border-2 border-gray-200 p-2"
          onChange={(e) => setRemarks(e.target.value)}
          placeholder={initialRemarks ? "" : t("manage-agencies.remarks")}
          value={remarks}
          readOnly={false}
        />
        <button
          onClick={handleSaveRemarksBtnClick}
          className="text-sm w-1/4 rounded-r-md border-t border-b border-gray-200 bg-gray-100 font-medium text-gray-900 hover:bg-gray-100"
        >
          {t("generals.save")}
        </button>
      </div>
    </div>
  );
};

export default RemarksSection;
