import React, { FormEvent, useEffect, useState } from "react";
import GuestsInput, { RoomInput } from "../GuestsInput";
import { FocusedInputShape } from "react-dates";
import DatesRangeInput from "../DatesRangeInput";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Airport, AirportList, PackagePaxList, PackageTourAutocompleteResult, SearchPayload } from "api/types";
import AutocompleteInput from "../AutocompleteInput";
import { getAirportAutocompleteUrl, getPackageTour_Autocomplete_url } from "api/helpers/api-constants";
import { useDispatch } from "react-redux";
import { SetTabSelections } from "redux/slices/tabsSlice";
import { useTranslation } from "react-i18next";
import AutocompleteForFlight from "../AutocompleteForFlight";
import PackageTourQueryParametersService from "services/PackageTourQueryParameterService";

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface PackageTourSearchForm {
  queryParameters: SearchPayload | null;
}
const PackageTourSearchForm: FC<PackageTourSearchForm> = ({ queryParameters }) => {


  const [packageLocation, setpackageLOcation] = useState<PackageTourAutocompleteResult | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: queryParameters?.startDate ? moment(queryParameters?.startDate) :moment().add(1, 'month').startOf('month'),
    endDate: queryParameters?.endDate ? moment(queryParameters?.endDate) : moment().add(2, 'months').endOf('month'),
  });
  const groupPaxToRooms = (paxList: PackagePaxList): RoomInput[] => {
    const adults = paxList.filter((pax) => pax.type === "ADULT").length;
    const children = paxList
      .filter((pax) => pax.type === "CHILD")
      .map((pax) => pax.age);
  
    return [{ adults, children }];
  };
  
  const [guests, setGuests] = useState<RoomInput[]>(
    queryParameters?.paxList ? groupPaxToRooms(queryParameters.paxList) : [{ adults: 2, children: [] }]
  );

  const [paxList, setPaxList] = useState<PackagePaxList>(
    queryParameters?.paxList || []
  );

  const [airport, setAirport] = useState<Airport | null>(null);
  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );
  const [airportFocus, setAirportFocus] = useState<boolean>(false)
  const [airportList, setAirportList] = useState<AirportList>(queryParameters?.airportList ? queryParameters.airportList : []);
  const [tripDurationAsNights, settripDurationAsNights] = useState<number>(queryParameters?.tripDurationAsNights ? queryParameters!.tripDurationAsNights : 7)
  const [requestKey, setrequestKey] = useState<string>( "");
  const [departureFocus, setDepartureFocus] = useState(false);
  const [missingInputError, setMissinInputError] = useState({
    packageLocation: false,
    startDate: false,
    endDate: false,
    airportList: false,
    paxList: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  function handleSubmit(event: FormEvent) {

    event.preventDefault();

    dispatch(SetTabSelections({ currentTab: "PackageTour" }))

    setMissinInputError({
      packageLocation: !packageLocation,
      startDate: !dateRange.startDate,
      endDate: !dateRange.endDate,
      airportList: !(airportList?.length > 0 ),
      paxList: !paxList,
    });
    
if(!(airportList?.length > 0))
    {return}
else{
  const urlSearchParams = PackageTourQueryParametersService.encode(
    packageLocation,
    dateRange,
    paxList,
    airportList,
    tripDurationAsNights,
    requestKey,
  );

  if (urlSearchParams) {
    navigate("/tourpackage?" + urlSearchParams.toString());
  }
}
  }

  useEffect(() => {
    if (queryParameters?.searchType === 'PackageTour') {
      if (queryParameters.region) {
        setpackageLOcation(queryParameters.region);
      }
  
      if (airportList) {
        const uppercasedAirports = airportList.map((airport) => ({
          id: airport.id!.toUpperCase(),
          text: airport.text!.toUpperCase(),
          iataCode: airport.iataCode!.toUpperCase(),
        }));
  
        setAirportList(uppercasedAirports);
  
        const airport = {
          id: uppercasedAirports.map((airport) => airport.id).join(', '),
          text: airportList.map((airport) => airport.text!.split(' ')[0]).join(', ') ,
          iataCode: uppercasedAirports.map((airport) => airport.iataCode).join(', '),
        };
        setAirport(airport);
      }
      console.log(queryParameters.paxList)
      setPaxList(queryParameters?.paxList || [] )
    }
  }, [queryParameters]);

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="relative mt-0 w-full shadow-sm rounded-b-lg bg-white dark:bg-neutral-900 dark:shadow-2xl"
      >
        <div className="[ nc-divide-field ] flex w-full flex-col rounded-full md:flex-row md:items-center ">
          <div className="[ nc-divide-field ] relative flex flex-grow flex-col md:flex-row md:items-center">
            <AutocompleteInput
              className="flex"
              defaultValue={""}
              //category="tour"
              placeHolder={packageLocation?.title ? packageLocation.title : t("packagetour.destination.autocomplete")}
              desc={packageLocation?.subTitle ? packageLocation.subTitle : t("react.hotel.where-are-u-traveling")}
              autocompleteValue={packageLocation}
              setAutocompleteValue={setpackageLOcation}
              localStorageToken="tour-search-form-autocomplete"
              getUrl={getPackageTour_Autocomplete_url}
              setDateFocus={setAirportFocus}
              // onInputDone={() => setDateFocused("startDate")}
              error={missingInputError.packageLocation}
              searchType="PackageTour"
              //className="nc-flex-2"
            />
            <AutocompleteForFlight
              className="flex-[2_2_0%]"
              placeHolder={t("tourpackage.flightfrom.autocomplete")}
              desc={t("tourpackage.fromwhere.autocomplete")}
              getUrl={getAirportAutocompleteUrl}
              autocompleteValue={airport}
              setAutocompleteValue={setAirport}
              localStorageToken="airport-departure"
              error={missingInputError.airportList}
              focus={airportFocus}
              autoFocus={airportFocus}
              setFocus={setAirportFocus}
              airportList={airportList}
              setAirportList={setAirportList}
              searchType={"PackageTour"}
              onInputDone={() => setDateFocused("startDate")}

            />
            <DatesRangeInput
              value={dateRange}
              defaultValue={dateRange}
              defaultFocus={dateFocused}
              prRanges={true}
              onFocusChange={(focus) => setDateFocused(focus)}
              onChange={(data) => setDateRange(data)}
              checkinDateError={missingInputError.startDate}
              checkoutDateError={missingInputError.endDate}
              tripDurationAsNights={tripDurationAsNights}
              settripDurationAsNights={settripDurationAsNights}
              fieldClassName={"nc-flex-1.5"}
            />
            <GuestsInput
              guestValue={guests}
              setGuestValue={setGuests}
              roomAvailable={false}
              paxList={paxList}
              setPaxList={setPaxList}
            />

            {/* BUTTON SUBMIT OF FORM */}
            <div className="justify center flex items-center px-4 py-4 lg:py-0">
              <SubmitButton />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PackageTourSearchForm;
