import { RoomInput } from "components/common/HeroSearchForm/GuestsInput";

class RoomOccupanciesService {
  static stringifyRoomOccupancies(roomOccupancies: Array<RoomInput>) {
    return (
      roomOccupancies
        .reduce(
          //@ts-ignore
          (prev, curr) => [
            ...prev,
            `[${curr.adults}${
              curr.children.length > 0 ? "," + curr.children.join(",") : ""
            }]`,
          ],
          []
        )
        //@ts-ignore
        .join("|")
    );
  }

  static getTotalGuests(roomOccupancies: Array<RoomInput>) {
    return roomOccupancies.reduce((prev, curr) => {
      return prev + curr.adults + curr.children.length;
    }, 0);
  }

  static getRoomOccupanciesValues(roomOccupancies: string) {
    const arr = roomOccupancies.split("|");
    const rooms: Array<RoomInput> = [];
    arr.forEach((roomDef) => {
      if (!roomDef) return;
      let roomTravellers: Array<number> = JSON.parse(roomDef);
      roomTravellers = roomTravellers.map((trav) => Number(trav));

      const adults = roomTravellers.shift() || 0;
      const children = roomTravellers;

      rooms.push({ adults, children });
    });
    return {
      totalGuests: RoomOccupanciesService.getTotalGuests(rooms),
      roomOccupancies: rooms,
    };
  }
}

export default RoomOccupanciesService;
