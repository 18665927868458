import { Customer } from '../types/invoice';

const PAGE_SIZE = 100;

export const filterAndPaginateCustomers = (
  customers: Customer[],
  searchTerm: string,
  page: number
): { filteredCustomers: Customer[]; hasMore: boolean } => {
  const lowercasedSearchTerm = searchTerm.toLowerCase();
  
  const filteredCustomers = customers.filter(customer =>
    customer.text.toLowerCase().includes(lowercasedSearchTerm)
  );

  const startIndex = (page - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const paginatedCustomers = filteredCustomers.slice(startIndex, endIndex);

  return {
    filteredCustomers: paginatedCustomers,
    hasMore: endIndex < filteredCustomers.length
  };
};

