import Glide from "@glidejs/glide";
import React, { FC, useEffect, useRef, useState } from "react";
import NcImage from "components/ui/NcImage/NcImage";
import NextPrev from "components/ui/NextPrev/NextPrev";
import ncNanoId from "utils/ncNanoId";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID?: string;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID,
}) => {
  const UNIQUE_CLASS = uniqueID || "nc_glide2_gallery_" + ncNanoId();
  const elementRef = useRef<any>(null);
  const [isElementReady, setElementReady] = useState(false);
  
  useEffect(() => {
    if (elementRef.current && !isElementReady) {
      setElementReady(true);
    }
  }, [elementRef.current, isElementReady]);
  
  useEffect(() => {
    if (isElementReady) {
      setTimeout(() => {
        new Glide(elementRef.current, {
          perView: 1,
          gap: 0,
          keyboard: false,
        }).mount();
      }, 100);
    }
  }, [isElementReady]);
  



  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      <div ref={elementRef} className={`${UNIQUE_CLASS} group relative overflow-hidden`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {galleryImgs.length > 0 ? (
              galleryImgs.map((item, index) => (
                <li key={index} className="glide__slide">
                  <div className={ratioClass}>
                    <NcImage src={item} />
                  </div>
                </li>
              ))
            ) : (
              <div className={ratioClass}>
                <NcImage src={"item"} />
              </div>
            )}
          </ul>
        </div>

        {/* DOTS */}
        <div className="absolute inset-x-0 -bottom-4 h-10 bg-gradient-to-t from-neutral-900" />
        <div
          className="glide__bullets absolute bottom-2 left-1/2 flex -translate-x-1/2 transform items-center justify-center space-x-1.5"
          data-glide-el="controls[nav]"
        >
          {galleryImgs.map((_, i) => (
            <button
              className="glide__bullet h-1.5 w-1.5 rounded-full bg-neutral-300"
              key={i}
              data-glide-dir={`=${i}`}
            />
          ))}
        </div>

        {/* NAV */}
        {galleryImgs.length > 1 && (
          <div className="absolute inset-x-2 top-1/2 flex -translate-y-1/2 transform justify-between opacity-0 transition-opacity group-hover:opacity-100">
            <NextPrev
              className="w-full justify-between"
              btnClassName="w-8 h-8"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GallerySlider;
