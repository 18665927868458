import { AirportList, PackagePaxList, PackageTourAutocompleteResult, SearchPayload } from "api/types";
import { DateRange } from "components/common/HeroSearchForm/TourSearchForm";

class PackageTourQueryParametersService {

  static encode(
    packageLocation: PackageTourAutocompleteResult | null,
    dateRange: DateRange,
    paxList: PackagePaxList,
    airportList: AirportList,
    tripDurationAsNights: number,
    requestKey: string,
    offerKey?: string,
    region?: { title: string, subTitle: string },
  ): string | null {
    
    
    if (!packageLocation || !dateRange.startDate || !dateRange.endDate || !paxList ) { 
      return null;
    }
    const updatedAirportList = 
    airportList?.map(airport => ({
       ...airport,
       text: airport.text!.toUpperCase(),
       id: airport.id!.toUpperCase(),
       iataCode: airport.iataCode!.toUpperCase(),
  
     }))
    return new URLSearchParams({
      searchType: "PackageTour",
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
      autocompleteKey: packageLocation.key,
      paxList: JSON.stringify(paxList), 
      airportList: JSON.stringify(updatedAirportList), 
      tripDurationAsNights: tripDurationAsNights.toString(),
      requestKey: requestKey || "",
      offerKey: offerKey || "",
      region: JSON.stringify(packageLocation),
    }).toString();
  }

  static decode(urlParams: URLSearchParams): SearchPayload | null {

    let startDate = urlParams.get("startDate") || null;
    let endDate = urlParams.get("endDate") || null;
    let autocompleteKey = urlParams.get("autocompleteKey") || null;
    let region = urlParams.get("region") || "";
    let paxList = urlParams.get("paxList") || null;
    let airportList = urlParams.get("airportList") || null;
    let tripDurationAsNightsString = urlParams.get("tripDurationAsNights");
    let hotelAtrId = urlParams.get("hotelAtrId") || null;
    if (tripDurationAsNightsString === null) {
      return null;
    }
    let tripDurationAsNights = Number(tripDurationAsNightsString); 
    let requestKey = urlParams.get("requestKey") || null;
    let offerKey = urlParams.get("offerKey") || null;

    if (!startDate || !endDate || !autocompleteKey || !paxList || !airportList) {
      return null;
    }
    return {
      searchType: "PackageTour",
      startDate: startDate!,
      endDate: endDate!,
      autocompleteKey: autocompleteKey!,
      airportList: JSON.parse(airportList), 
      paxList: JSON.parse(paxList), 
      requestKey: requestKey!,
      offerKey: offerKey!,
      tripDurationAsNights: tripDurationAsNights,
      region: JSON.parse(region),
      hotelAtrId: hotelAtrId!,

    };
  }
  
}

export default PackageTourQueryParametersService;
