import {
    CellContext,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from "@tanstack/react-table";
  import { FC, SetStateAction, useEffect, useState } from "react";
  import { BookingListModel, BookingStatusModel } from "api/types";
  import { SpinnerDotted } from "spinners-react";
  import moment from "moment";
  import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
  import { useNavigate } from "react-router-dom";
  import { useTranslation } from "react-i18next";
  import { fetcherWithoutJSON } from "api/helpers/fetcher";
  import { getBookingListUrl } from "api/helpers/api-constants";
  import { getStaticUrl } from "utils/getStaticUrl";
  import { useReduxReducer } from "redux/store";
  import { setReservationList } from "redux/slices/generalSlice";
  import { useDispatch } from "react-redux";
  import RefreshIcon from '@mui/icons-material/Refresh';
import Button from "components/ui/Button/Button";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import { IconButton } from "@material-ui/core";
import { useLocalStorageValue } from "hooks/useLocalStorageValue";
    
  type Props = {
    filterStatus: BookingStatusModel;
    search?: string | undefined;
  };
  
  type TypeFilter = {
    car: boolean;
    flight: boolean;
    hotel: boolean;
    transfer: boolean;
  };
  
  const typeCheckedStyle = "bg-primary text-white p-2 ";
  const typeUncheckedStyle = "text-muted p-2 ";
  const renderSellerCell = (data : BookingListModel) => {
    const agencyName = data.agencyName; 
    const username = data.user?.username;
  
    return (
      <div className="grid grid-rows-2">
        <span className="font-semibold text-black">{agencyName}</span>
        <span className="text-gray-400">{username}</span>
      </div>
    );
  };
  const renderDateCol = (
    info: CellContext<BookingListModel, number | undefined>
  ) => {
    let reservationDate = moment(info.row.original.creationDate).format(
      "DD.MM.YYYY"
    );
    let optionDate = info.row.original.optionDate;
  
    return (
      <div className="flex flex-col text-center">
        <span className="font-bold text-red-500 text-sm">
          {optionDate && moment(optionDate).format("DD.MM.YYYY")}
          <p>{optionDate && moment(optionDate).format("HH:mm")}
          </p>
        </span>
      </div>
    );
  };
  const renderPassengerCol = (info: any) => {
    let passengers = info.row.original.extendedPaxList;
  
    return (
      <div className="flex flex-col">
        {passengers &&
          passengers.map((passenger: any, index: number) => {
            return (
              <div key={index} className="text-black">
                <span className="w-1/6 ">
                  {passenger.paxtype === "ADT"
                    ? passenger.gender === "MALE"
                      ? "MR "
                      : "MRS "
                    : passenger.paxtype + " "}
                </span>
                <span className="">
                  {passenger.firstname} {passenger.surname}
                </span>
              </div>
            );
          })}
      </div>
    );
  };
  const renderTypeHeader = (
    types: TypeFilter,
    setTypes: React.Dispatch<SetStateAction<TypeFilter>>,
    data: string
  ) => {
    return (
      <div className="">
        <span className=" flex justify-center">{data}</span>
        <div className="flex items-center justify-center ">
          <input
            type="checkbox"
            id="car"
            className="d-none"
            name="type"
            defaultChecked={types.car}
            onChange={(e) => setTypes({ ...types, car: e.target.checked })}
          />
          <label
            htmlFor="car"
            className={`cursor-pointer rounded-l-xl border ${
              types.car ? typeCheckedStyle : typeUncheckedStyle
            }`}
          >
            <i className="las la-car la-2x"></i>
          </label>
          <input
            type="checkbox"
            id="flight"
            className="d-none"
            name="type"
            defaultChecked={types.flight}
            onChange={(e) => setTypes({ ...types, flight: e.target.checked })}
          />
          <label
            htmlFor="flight"
            className={`cursor-pointer border ${
              types.flight ? typeCheckedStyle : typeUncheckedStyle
            }`}
          >
            <i className="las la-plane la-2x"></i>
          </label>
          <input
            type="checkbox"
            id="hotel"
            className="d-none"
            name="type"
            defaultChecked={types.hotel}
            onChange={(e) => setTypes({ ...types, hotel: e.target.checked })}
          />
          <label
            htmlFor="hotel"
            className={`cursor-pointer border ${
              types.hotel ? typeCheckedStyle : typeUncheckedStyle
            }`}
          >
            <i className="las la-building la-2x"></i>
          </label>
          <input
            type="checkbox"
            id="transfer"
            className="d-none"
            name="type"
            defaultChecked={types.transfer}
            onChange={(e) => setTypes({ ...types, transfer: e.target.checked })}
          />
          <label
            htmlFor="transfer"
            className={`cursor-pointer rounded-r-xl border ${
              types.transfer ? typeCheckedStyle : typeUncheckedStyle
            }`}
          >
            <i className="las la-shuttle-van la-2x"></i>
          </label>
        </div>
      </div>
    );
  };
  const renderTypeCell = (info: BookingListModel) => {
    return (
      <div className="divide-y">
        {info.rentalCarReservation && (
          <span className="flex flex-col py-1">
            <span className="flex flex-col items-center justify-center text-center">
              <span className="text-xs text-black">
                {info.rentalCarReservation.rentalCarBrand}{" "}
                {info.rentalCarReservation.rentalCarType}
              </span>
              <span className="text-xs text-black">
                {info.rentalCarReservation.pickupLocationStr ===
                info.rentalCarReservation.returnLocationStr ? (
                  info.rentalCarReservation.pickupLocationStr
                ) : (
                  <span className="flex flex-col">
                    <span>{info.rentalCarReservation.pickupLocationStr}</span>
                    <span>{info.rentalCarReservation.returnLocationStr}</span>
                  </span>
                )}
              </span>
              <span className="text-xs text-black">
                {moment(new Date(info.rentalCarReservation.pickupDate)).format(
                  "DD.MM.YYY HH:mm"
                )}{" "}
                -{" "}
                {moment(new Date(info.rentalCarReservation.returnDate)).format(
                  "DD.MM.YYY HH:mm"
                )}
              </span>
            </span>
          </span>
        )}
        {info.flightList && info.flightList.length > 0 && (
          <span className="flex flex-col py-1">
            {info.flightList.map((flight: any, index: number) => {
              return (
                <span key={index} className="flex items-center">
                  {flight.airlineLogo && (
                    <img
                      src={getStaticUrl('b2b')+ flight.airlineLogo}
                      alt=""
                      className="h-5 w-5 rounded-sm object-cover"
                    />
                  )}
                 
                  <span className="text-muted ml-2 font-semibold">
                    {flight.departureDateStr.split(".")[0] +
                      "." +
                      flight.departureDateStr.split(".")[1]}
                  </span>
                  <span className="ml-1 flex items-center font-semibold text-black">
                    <span className="mr-0.5">{flight.departurePort}</span>
                    <i className="las la-long-arrow-alt-right"></i>
                    <span className="ml-1">{flight.arrivalPort}</span>
                  </span>
                </span>
              );
            })}
          </span>
        )}
        {info.hotelReservation && (
          <span className="flex flex-col py-1">
            <span className="flex flex-col items-center justify-center text-center">
              <span className="text-xs text-black">
                {info.hotelReservation.hotelName}
              </span>
              <span className="text-xs text-black">
                {moment(new Date(info.hotelReservation.checkinDate)).format(
                  "DD.MM.YYYY HH:mm"
                )}{" "}
                -{" "}
                {moment(new Date(info.hotelReservation.checkoutDate)).format(
                  "DD.MM.YYYY HH:mm"
                )}
              </span>
            </span>
          </span>
        )}
        {info.transferReservation && (
          <span className="flex flex-col py-1">
            <span className="flex flex-col items-center justify-center text-center">
              <span className="text-xs text-black">
                {info.transferReservation.transferProviderName}
              </span>
              <span className="text-xs text-black">
                {info.transferReservation.transferPickupLocation}
              </span>
              <span className="text-xs text-black">
                {moment(new Date(info.transferReservation.pickupDate)).format(
                  "DD.MM.YYYY HH:mm"
                )}{" "}
                -{" "}
                {moment(new Date(info.transferReservation.returnDate)).format(
                  "DD.MM.YYYY HH:mm"
                )}
              </span>
            </span>
          </span>
        )}
      </div>
    );
  };
  const renderStatusCell = (info: BookingListModel, { t }: any) => {
    let dangerBadge =
      " bg-red-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 flex justify-center ";
    let successBadge =
      "bg-green-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400 flex justify-center";
    let yellowBadge =
      "bg-yellow-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300 flex justify-center";
      const statuses = [
      "RESERVATION" ,
      "CANCELED",
      ];
     
    return (
      <div className="flex flex-col">
        { info.statuses && info.statuses.map((item) => (
        <span
        className={
          item === "CANCELED" ||item === "EXPIRED"
            ? dangerBadge
            : item === "TICKETED" || item === "CONFIRMED"
            ? successBadge
            : yellowBadge
        }
      >
     {
    item === "RESERVATION"
      ? t("b2c-app.profile.bookings.status.reservation").toUpperCase()
      : item === "CANCELED"
      ? t("b2c-app.profile.bookings.status.canceled").toUpperCase()
      : item === "TICKETED" || item === "CONFIRMED"
      ? t("b2c-app.profile.bookings.status.ticketed").toUpperCase()
      : item === "EXPIRED"
      ? t("b2c-app.profile.bookings.status.expired").toUpperCase()
      : null
  }
  
      </span>
      ))}
      </div>
     
     
    );
  };
  
  const BookingListLight: FC<Props> = ({ filterStatus, search }) => {
    const [typeFilter, setTypeFilter] = useState<TypeFilter>({
      car: false,
      flight: false,
      hotel: false,
      transfer: false,
    });
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [lastRefresh, setLastRefresh] = useLocalStorageValue<string>({
      key: 'last-refresh',
      defaultValue: "",
    });
      const {reservationList} = useReduxReducer(state => state.general)
    const [data, setData] = useState<BookingListModel[]>(reservationList || []);

    const columnHelper = createColumnHelper<BookingListModel>();
    const navigate = useNavigate();

    const flightBooking: string = t("flightbooking.total");
    
    const columns = [
     
      columnHelper.accessor((row) => row.bookingKey, {
        id: "booking",
        cell: (info) => (
          <span className="text-center text-black">{info.getValue()}</span>
        ),
        header: () => <span>{t("bookings.booking-key")}</span>,
      }),
      columnHelper.accessor("creationDate", {
        header: () => (
          <span className=" flex-col items-center">
            <span className=" flex justify-center text-center text-sm whitespace-nowrap">
              {t("flightbooking.ticketing_deadline")}
            </span>
          </span>
        ),
        cell: (info) => renderDateCol(info),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("passenger", {
        header: () => <span className="pl-0">{t("flights.passengers")}</span>,
        cell: (info) => renderPassengerCol(info),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor("a", {
        header: () => <span className="pl-0">{t("bookings.flights")}</span>,

        cell: (info) => renderTypeCell(info.row.original),
      }),
      columnHelper.accessor("totalPrice", {
        header: flightBooking,
        cell: (info) => 
        <span className=" whitespace-nowrap"> {info.row.original.totalPrice?.toFixed(2) + " €" || "-"}
        </span>
      }),
      
    ];
  
    useEffect(() => {
      setData(reservationList)
    }, [reservationList]);
  
    useEffect(() => {
      if(reservationList.length === 0)
      getData();
        else
          return
    }, []);
  
    const reactTable = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });
  
    const getData = async () => {
      setLoading(true);
      const url = getBookingListUrl(
       "0",
       "9",
       "",
        "RESERVATION",
        "false",
        "false",
        "false",
        "false",
      );
      await fetcherWithoutJSON(url)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Something went wrong on api server!");
          }
        })
        .then((data) => {
          dispatch(setReservationList(data.aaData))
          
          setLastRefresh(moment().format("DD.MM.YYYY HH:mm"));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    return (
      <div className="flex flex-col rounded-2xl gap-2 bg-slate-50  shadow-sm dark:bg-slate-800/25">

          <div className="flex justify-between rounded-t-2xl  py-6 px-2 items-center bg-white " >
          <h1 className="  text-xl font-bold text-gray-800 dark:text-white">
      {t("react.dashboard.upcomingoptions")}
    </h1>
                <span className="text-sm text-muted-foreground">
                {t("pagination.last") + " " + (t("general.update"))} {lastRefresh}
                <IconButton
                onClick={getData}
                className="p-0"
              >
                  <RefreshIcon className="text-primary-6000" /> {/* Add the icon here */}
                </IconButton>
              </span>
                </div>
          {loading && (
            <div className="absolute h-full w-full">
              <div className="absolute top-2/4 left-1/2 -translate-x-1/2">
                <div className="flex flex-col items-center">
                  <SpinnerDotted color="#007bff" />
                  <div className="mt-2 text-lg font-semibold">
                    {t("b2c-app.generals.loading")}...
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={`min-h-[250px] ${loading ? "opacity-50" : ""}`}>
  <div className="overflow-x-auto">
    <table className="w-full table-auto border-collapse text-sm">
      {/* Table headers */}
      <thead>
        {reactTable.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className="pl-1 text-center font-medium text-gray-900 whitespace-nowrap"
                key={header.id}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {/* Table body */}
      <tbody className="bg-white dark:bg-slate-800 gap-0">
        {data.length > 0 ? (
          reactTable.getRowModel().rows.map((row) => (
            <tr
              className="cursor-pointer hover:bg-blue-100"
              key={row.id}
              onClick={() =>
                navigate(`/booking/detail/${row.original.bookingKey}`)
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  className="border-b border-slate-100 text-center text-black dark:border-slate-700 dark:text-slate-400"
                  key={cell.id}
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))
        ) : !loading && (
          <tr>
            <td colSpan={columns.length} className="p-4 text-center">
              <div className="flex flex-col items-center">
                <div className="text-2xl font-semibold">
                  There were no results that matched the chosen parameters.
                </div>
                <div className="mt-2 text-lg font-semibold">
                  Try again by changing the filters.
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>
      <div className="flex justify-end items-end p-2">
      <ButtonPrimary
        className=""
        onClick={() => navigate("/bookings")}
      >
        {t("app.generals.show-all")}
        </ButtonPrimary>
    </div>
      </div>
    );
  };
  
  export default BookingListLight;


  