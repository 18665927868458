import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useTranslation } from 'react-i18next';
import { SpinnerDotted } from 'spinners-react';
import { fetcherWithoutJSON } from 'api/helpers/fetcher';
import { confirmMealSelection, getMealSsr } from 'api/helpers/api-constants';
import ButtonClose from 'components/ui/ButtonClose/ButtonClose';
import { BookingFlight, BookingRoute } from 'api/types';
import { disableButton } from 'utils/disableButton';

export interface SsrSelectionProps {
  bookingKey: string;
  filekey: string;
  route: BookingRoute;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface SsrResponse {
  ssrList: string[];
}

const MealSelection: React.FC<SsrSelectionProps> = ({ bookingKey, filekey, route, isOpen, setIsOpen }) => {
    const [isgetData, setIsgetData] = useState(false);
    const [paxId, setPaxId] = useState<any>();
    const [flightId, setFlightId] = useState<any>();
    const [mealloading, setmealloading] = useState(false);
    const [selectedMeal, setSelectedMeal] = useState<string>("");
    const [confirmbox, setConfirmbox] = useState(false);
    const [flightData, setflightData] = useState<BookingFlight>();
    const {t} = useTranslation()
    interface SsrResponse {
      ssrList: string[];
    }

    const postSsr = async (
      bookingKey: string,
      filekey: string,
      selectedMeal: string
    ): Promise<SsrResponse> => {
      const url = confirmMealSelection();
      const params = new URLSearchParams({
        bookingKey: bookingKey,
        filekey,
        ssrKey: selectedMeal,
      });

      try {
        const response = await fetcherWithoutJSON(`${url}${params}`, {
          method: "POST",

        });

        if (!response.ok) {
          const correlationID: any = response.headers.get("correlation-id");
          Swal.fire("Error Code: " + correlationID, "", "error");
        } else {
          Swal.fire("success", "", "success");
        }

        const data = await response.json();
        return data as SsrResponse;
      } catch (error) {
        Swal.fire("Error Code: unknown", "", "error");
        console.error(error);
        throw error;
      } finally {
        setmealloading(false);
        setConfirmbox(false);
      }
    };

    //post meal api

    type mealdata1 = {
      ssrKey: string;
      name: string;
      description: string;
      currencyCode: string;
      price: number;
    };

    const [testData, settestData] = useState<any>();
    const [isloading, setisloading] = useState(true);

    const getssrData = async () => {
      setisloading(true)
      settestData(null)
      await fetcherWithoutJSON(
        getMealSsr() +
          new URLSearchParams({
            bookingKey: bookingKey.toString(),
            filekey: filekey,
            flightId: flightId,
            paxId: paxId,
          }),
        {
          method: "GET",
          credentials: "include",
          cache: "no-cache",
        }
      )
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          }
          else if (response.status === 500) {
            const errorData = await response.json();
            if(errorData.message.includes("No offer")){
              Swal.fire(t("general.offer-not-available"), "", "info");
            }
            else{
              Swal.fire(t("ssr.errorfrom-airline"), "", "error");
            }
          } else {
            throw new Error("Something went wrong on the API server!");
          }
          
        })
        .then((data) => {
          settestData(data.ssrList);
        })
       
        .finally(() => {
          setisloading(false);
        });
    };
    useEffect(() => {
      if (flightId && paxId && filekey) {
        getssrData();
      }
    }, [isgetData, paxId, flightId]);

    const mealdata1: any = testData;
    if (isOpen === true) {
      window.scrollTo(0, 0);
    }
    return (
      <div>
        {isOpen ? (
          <div>
            {isOpen && (
              <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                <div className="w-full rounded bg-white">
                <ButtonClose onClick={() =>{
            setIsOpen(false)
            setFlightId(null)
            setPaxId(null)}
              }></ButtonClose>
                  <div className=" rounded bg-white ">
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      <RestaurantIcon />{" "}
                      <h2 className="p-2">{t("flightbooking.meals")}</h2>
                    </div>
                    <div className="flex items-center justify-center rounded-t-lg bg-white px-4 py-1 font-semibold ">
                      {isloading && paxId ? (
                        <div className="text-black ">
                          <SpinnerDotted color="#007bff" />
                        </div>
                      ) : flightId && paxId ? (
                        <h2 className="bg-white text-black">
                          3- {t("flightbooking.meals")} {t("generals.select")}{" "}
                        </h2>
                      ) : flightId ? (
                        <h2 className="bg-white text-black">
                          2- {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.meals")} {t("generals.select")}{" "}
                        </h2>
                      ) : (
                        <h2 className="bg-white text-black">
                          1- {t("generals.flight")} {t("generals.select")} 2-{" "}
                          {t("flight.passenger")} {t("generals.select")} 3-{" "}
                          {t("flightbooking.meals")} {t("generals.select")}{" "}
                        </h2>
                      )}
                    </div>

                    <div className="">
                      <div className="w-full  p-2">
                        <div className=" w-min-screen z-10   flex rounded-md border border-gray-300 bg-white">
                          {
                              route.bookMealAvailable &&
                              route.filekey && (
                                <div
                                  key={route.filekey}
                                  className="  w-2/6 p-2  "
                                >
                                  <h3 className=" font-bold">
                                    1- {t("flightbooking.flights")}
                                  </h3>

                                  {route.trips?.map((trip) => (
                                    trip.flights.map((flight) => (
                                        <div key={flight.id}>
                                        <button
                                          key={flight.id}
                                          className={`w-full  border-2 p-2 text-center hover:bg-gray-100  ${
                                            flight.id === flightId
                                              ? "bg-gray-200"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setFlightId(flight.id);
                                            setflightData(flight)
                                            setPaxId("");
                                            settestData(null);
                                          }}
                                        >
                                          <div className="  text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                            {flightId === flight.id ? (
                                              <i
                                                className="fa fa-check"
                                                aria-hidden="true"
                                              ></i>
                                            ) : null}
    
                                            <span className="flex items-center space-x-2">
                                              <img className='h-5 w-5' src={flight.airlineLogo} alt='airline'></img>
                                            <span className='font-bold'>{flight.flightNumber}</span>  
                                           
                                            </span>
                                            <span>
                                              {flight.departurePort} -{" "}
                                              {flight.arrivalPort}
                                            </span>
                                          </div>
                                        </button>
                                      </div>
                                    ))
                                    ))}

                                </div>
                              )
                                            }
                              {
                                route.bookMealAvailable && route.filekey && (
                                  <div className="flex w-1/6 items-stretch justify-center p-2">
                                    {flightId && (
                                      <div>
                                        <h3 className="font-bold">
                                          2- {t("flightbooking.passengers")}
                                        </h3>
                                        <div>
                                          {route.passengers.map((p) => (
                                            <div key={p.id}>
                                              <button
                                                className={`w-full border-2 p-2 text-center hover:bg-gray-100 ${
                                                  p.id === paxId ? "bg-gray-200" : ""
                                                }`}
                                                onClick={() => setPaxId(p.id)}
                                              >
                                                <div className="text-sm flex items-center justify-between space-x-3 px-2 text-gray-900">
                                                  {paxId === p.id && (
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                  )}
                                                  <p>{p.firstname}</p>
                                                  <p className="font-bold">{p.surname}</p>
                                                </div>
                                              </button>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              }
                              
                          <div className=" w-4/6 p-2 mx-1 ">
                            { mealdata1 ?  mealdata1.length === 0 &&  <div>{t("ssr.errorfrom-airline")}</div>: null}
                            {paxId &&
                            flightId && flightData &&
                            flightData.ssrRecords.find(
                              (rcr: any) => rcr.recordType === "MEAL"
                            )?.paxId === paxId &&
                            flightData.ssrRecords.find(
                              (rcr: any) => rcr.recordType === "MEAL"
                            )?.flightId === flightId ? (
                              <span>{t("ssr.already.selected")}</span>
                            ) : (

                              mealdata1 &&
                              paxId &&
                              flightId &&
                              !isloading && (
                                <div className="relative p-3">
                                  <select
                                    className="focus:shadow-outline w-full appearance-none rounded border bg-white py-2 pl-3 pr-8 leading-tight text-gray-700 focus:outline-none"
                                    value={selectedMeal}
                                    onChange={(e) =>
                                      setSelectedMeal(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      {t("flightbooking.meals")}{" "}
                                      {t("generals.select")}{" "}
                                    </option>
                                    {!testData
                                    ? (
                                      <div>Havayolu kaynaklı hata alıyoruz</div>
                                    ) : (
                                      mealdata1.map((meal: any) => (
                                        <option
                                          key={meal.ssrKey}
                                          value={meal.ssrKey}
                                        >
                                          {meal.name} {meal.description} -{" "}
                                          {meal.price} {meal.currencyCode}
                                        </option>
                                      ))
                                    )}
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                                    <svg
                                      className="h-4 w-4 fill-current text-gray-500"
                                      viewBox="0 0 20 20"
                                    ></svg>
                                  </div>
                                  <div className="mt-2 rounded border shadow-md">
                                    {selectedMeal && (
                                      <div className="p-8">
                                        <div className="border-1 text-md overflow-hidden text-center text-gray-900 ">
                                          <p className="font-bold">
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.name
                                            }
                                          </p>
                                          <p className="">
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.description
                                            }
                                          </p>
                                          <p className="">
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.price
                                            }{" "}
                                            {
                                              mealdata1.find(
                                                (meal: any) =>
                                                  meal.ssrKey === selectedMeal
                                              )?.currencyCode
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>

                      {confirmbox && (
                        <div className="fixed top-0 left-0 flex h-full w-full items-start justify-center bg-gray-500 bg-opacity-75 p-8">
                          <div className="grid w-1/2 grid-rows-2 rounded bg-white p-2">
                            <div className="flex w-full justify-between p-2 ">
                              <ButtonClose
                                onClick={() => setConfirmbox(false)}
                              ></ButtonClose>

                              {mealloading && (
                                <SpinnerDotted className="flex items-center justify-center"></SpinnerDotted>
                              )}
                            </div>
                            <div className="border-1 text-md overflow-hidden rounded  p-2 text-center text-gray-900 shadow-md ">
                              <p className="font-bold">
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.name
                                }
                              </p>
                              <p className="">
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.description
                                }
                              </p>
                              <p className="">
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.price
                                }{" "}
                                {
                                  mealdata1.find(
                                    (meal: any) => meal.ssrKey === selectedMeal
                                  )?.currencyCode
                                }
                              </p>
                            </div>
                            <div className="p-2">
                              <p className=" text-red-600">
                                {t(
                                  "generals.attention-this-action-is-not-reversible"
                                )}
                              </p>
                              <p></p>
                              <p>
                                {" "}
                                {t("generals.are-you-sure-you-want-to-proceed")}
                              </p>
                              <div className="inline-flex  p-2 " role="group">
                                <button
                                  type="button"
                                  className="text-sm rounded-l-lg border border-gray-200 bg-white px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-red-500
                                  "
                                  onClick={() => {
                                    setConfirmbox(false);
                                  }}
                                >
                                  {t("b2c-app.generals.cancel")}
                                </button>
                                <button
                                  id="confirmMeal"
                                  type="button"
                                  className="bg-gray=200 text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium  text-white hover:text-gray-500 hover:bg-primary-400"
                                  onClick={() => {
                                    const button = document.getElementById("confirmMeal") as HTMLButtonElement | null;
                                    if (button) {
                                      disableButton([button]);
                                   }
                                    postSsr(bookingKey, filekey, selectedMeal); //post ssr func will come
                                    setmealloading(true);
                                  }}
                                  disabled={mealloading}
                                >
                                  {t("generals.yes")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="inline-flex  p-2 " role="group">
                        <button
                          type="button"
                          className="text-sm rounded-l-lg border border-gray-200 bg-white-500 px-4 py-2  font-medium text-gray-900 hover:bg-red-700 hover:text-white focus:z-10 focus:ring-2"
                          onClick={() => {
                            //setIsExpanded(false);
                            setPaxId("");
                            setFlightId("");
                            setIsOpen(false);
                            //setSelectedMeal("");
                            setSelectedMeal("");
                          }}
                        >
                          {t("b2c-app.generals.cancel")}
                        </button>
                        <button
                          type="button"
                          className="text-sm rounded-r-lg border border-gray-200 bg-primary-6000 px-4 py-2 font-medium text-white  hover:text-gray-200 hover:bg-primary-400 focus:z-10 focus:ring-2"
                          onClick={() => {
                            //  setIsExpanded(false);
                            // postSsr(key, filekey, selectedMeal); //post ssr func will come
                            setConfirmbox(true);
                          }}
                        >
                          {t("generals.add")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  export default MealSelection