import React, {FC, useState} from 'react';
import {DateRangePicker, FocusedInputShape} from "react-dates";
import useWindowSize from "../../../hooks/useWindowResize";
import {IDateRange} from "../../../api/types";
import moment, {Moment} from "moment";

interface IRangeDatePicker {
    className?: string;
    dateRange: IDateRange;
    onFocusChange: FocusedInputShape|null;
    onFocusInputHandler: (focus: FocusedInputShape|null) => void;
    onDatesChangeHandler: (date: IDateRange) => void;
}

export const RangeDatePicker: FC<IRangeDatePicker> = ({
                                                      dateRange,
                                                      onFocusInputHandler,
                                                      onFocusChange,
                                                      onDatesChangeHandler,
                                                      className = ""
    }) => {
        const windowSize = useWindowSize();

        const isOutsideRange = (day: Moment) => {
            return day.isAfter(moment()) || day.isBefore(moment().subtract(10, 'year'));
        }

        return (
            <div className={className}>
                <DateRangePicker
                    keepFocusOnInput
                    startDate={dateRange.startDate}
                    startDateId={"nc-hero-stay-startDateId"}
                    endDate={dateRange.endDate}
                    endDateId={"nc-hero-stay-endDateId"}
                    focusedInput={onFocusChange}
                    onDatesChange={onDatesChangeHandler}
                    onFocusChange={onFocusInputHandler}
                    numberOfMonths={2 || (windowSize.width <= 1024 ? 1 : undefined)}
                    daySize={windowSize.width > 500 ? 56 : undefined}
                    orientation={"horizontal"}
                    showClearDates
                    noBorder
                    keepOpenOnDateSelect
                    hideKeyboardShortcutsPanel
                    anchorDirection={"right"}
                    renderCalendarInfo={null}
                    isOutsideRange={isOutsideRange}
                />
            </div>
        )
}
