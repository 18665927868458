import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { getStaticUrl } from "utils/getStaticUrl";

export const supportedLngs = {
    en: { nativeName: "English" },
    de: { nativeName: "Deutsch" },
    tr: { nativeName: "Türkçe" },
    ar: { nativeName: "Arabic" },
    pl: { nativeName: "Polish" },
    ru: { nativeName: "Russian" },
    fr: { nativeName: "French" },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(ChainedBackend)
    .init({
        debug: false,
        fallbackLng: "de",
        supportedLngs: Object.keys(supportedLngs),
        interpolation: {
            escapeValue: false,
            prefix: "{",
            suffix: "}",
        },
        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [
                {
                    expirationTime: 60 * 60 * 1000, //every hour
                },
                {
                    loadPath: getStaticUrl('b2b')+"/public/i18n.json?language={lng}",
                },
            ],
        },
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag'],
        }
    });
// Refresh local storage every hour
setInterval(() => {
    i18n.reloadResources();
}, 60 * 60 * 1000);

export default i18n;
