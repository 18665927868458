//Format date to localeString


export function formatServerDate(dateString: string, locale: string = "de-DE", timeZone: string = "Europe/Berlin"): string {
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
  
    return new Date(dateString).toLocaleString(locale, options);
  }
  
  
  export function formatServerDateTime(dateString: string, locale: string = "de-DE", timeZone: string = "Europe/Berlin"): string {
    const options: Intl.DateTimeFormatOptions = {
        timeZone,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    return new Date(dateString).toLocaleString(locale, options);
}
