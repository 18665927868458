import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import filtersReducer from "./slices/filtersSlice";
import orderReducer from "./slices/orderSlice";
import tabsReducer from "./slices/tabsSlice";
import flightReducer from "./slices/flightSlice";
import generalSlice from "./slices/generalSlice";
import prevUrlSlice from "./slices/prevUrlSlice";
import rentalcarReducer from "./slices/rentalcarReducer";
import hotelReducer from "./slices/hotelReducer";
import configSlice from "./slices/configSlice";


export const store = configureStore({
  reducer: {
    filters: filtersReducer,
    order: orderReducer,
    tabs: tabsReducer,
    flight: flightReducer,
    rentalcar: rentalcarReducer,
    hotel: hotelReducer,

    general: generalSlice,
    prevUrl: prevUrlSlice,
    config: configSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootSearchPageState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type SearchPageDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useSearchPageDispatch = () => useDispatch<SearchPageDispatch>();
export const useReduxReducer: TypedUseSelectorHook<RootSearchPageState> = useSelector;
