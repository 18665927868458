export function formatDate(inputDate: string) {
    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${day}.${month}.${year}`;
}

// dateString get date in format yyyy-MM-dd
export function formatDateShortMonth(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${day}. ${month}`;
}
// converts dd.MM.yyyy to yyyy-MM-dd
export function formatDateReverse(dateString: string): string {
    const datePattern = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    if (!datePattern.test(dateString)) {
      throw new Error('Invalid date format. Expected dd.MM.yyyy');
    }
    const [, day, month, year] = dateString.match(datePattern)!;
    return `${year}-${month}-${day}`;
  }

// dd.MM.yyyyThh:mm.ss (2024-01-16T07:30:00.000+00:00)
export const extractTime = (dateTimeString: string): string | null => {
    try {
        const dateTime: string = dateTimeString.split('T')[1]

        const stunden: string = dateTime.split(':')[0]
        const minuten: string = dateTime.split(':')[1]

        return `${stunden}:${minuten}`;
    } catch (error) {
        console.error('Fehler beim Parsen des Datums:', error);
        return null;
    }
};

export const convertDateToDayMonth = (dateString: string, t: any): string => {
    const date = new Date(dateString);

    const monthNames = [
        'react.month.month_1',
        'react.month.month_2',
        'react.month.month_3',
        'react.month.month_4',
        'react.month.month_5',
        'react.month.month_6',
        'react.month.month_7',
        'react.month.month_8',
        'react.month.month_9',
        'react.month.month_10',
        'react.month.month_11',
        'react.month.month_12'
    ];

    return `${date.getDate()}. ${t(monthNames[date.getMonth()])}`;
}
