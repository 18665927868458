import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { isB2C } from "../../actions";
import { withStyles } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { t } from "i18next";
const useStyles = makeStyles((theme) => ({
  priceoverviewHeader: {
    backgroundColor: "#eaeaea",
    padding: "10px",
    fontWeight: "700",
  },
  priceoverviewList: {
    padding: "15px",
    display: "grid",
    gridTemplateRows: "auto auto auto",
  },
  flightContainer: {
    display: "grid",
    gridTemplateRows: "auto auto auto auto auto auto",
  },
  priceoverviewlistTotal: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    fontWeight: "700",
    marginTop: "5px",
    paddingTop: "5px",
    borderBottom: "double 3px #e4e4e4",
    borderTop: "1px solid #e4e4e4",
  },
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

export function FlightPriceOverview(props) {
  const classes = useStyles();

  let flight = props.selectedFlight;
  let vehicle = props.vehicle;
  let insurance = props.insurance;

  let adtFare = 0;
  let chdFare = 0;
  let infFare = 0;
  let totalFare = 0;

  let adtTax = 0;
  let chdTax = 0;
  let infTax = 0;
  let totalTax = 0;

  let adtFlightprice = 0;
  let chdFlightprice = 0;
  let infFlightprice = 0;
  let serviceFee = 0;

  let flightSegments = 0;

  if (props.isRoundTripFlightAvailable !== null) {
    let segments = 0;
    if (props.isMultiFlight) {
      for (let i = 0; i < flight.length; i++) {
        segments++;
      }
    }

    adtFare = props.isRoundTripFlightAvailable.adtBaseFare;
    chdFare = props.isRoundTripFlightAvailable.chdBaseFare;
    infFare = props.isRoundTripFlightAvailable.infBaseFare;
    totalFare = adtFare + chdFare + infFare;

    adtTax = props.isRoundTripFlightAvailable.adtTax;
    chdTax = props.isRoundTripFlightAvailable.chdTax;
    infTax = props.isRoundTripFlightAvailable.infTax;
    totalTax = adtTax + chdTax + infTax;

    adtFlightprice =
      props.isRoundTripFlightAvailable.adtPrice +
      segments * props.serviceFeePerPaxAndRoute;
    chdFlightprice =
      props.isRoundTripFlightAvailable.chdPrice +
      segments * props.serviceFeePerPaxAndRoute;
    infFlightprice =
      props.isRoundTripFlightAvailable.infPrice +
      segments * props.serviceFeePerPaxAndRoute;
  } else {
    for (let i = 0; i < flight.length; i++) {
      adtFare += flight[i].adtFare;
      chdFare += flight[i].chdFare;
      infFare += flight[i].infFare;

      adtTax += flight[i].adtTax;
      chdTax += flight[i].chdTax;
      infTax += flight[i].infTax;

      adtFlightprice +=
        flight[i].adtPrice +
        flight[i].flightList.length * props.serviceFeePerPaxAndRoute;
      chdFlightprice +=
        flight[i].chdPrice +
        flight[i].flightList.length * props.serviceFeePerPaxAndRoute;
      infFlightprice +=
        flight[i].infPrice +
        flight[i].flightList.length * props.serviceFeePerPaxAndRoute;
    }
    totalFare = adtFare + chdFare + infFare;
    totalTax = adtTax + chdTax + infTax;
  }

  if (!props.isMultiFlight) {
    //flightSegments = flight.flightList.length;
    flightSegments = flight.length;
  } else {
    flightSegments = flight.length;
  }

  serviceFee =
    (props.adtCount + props.chdCount + props.infCount) *
    flightSegments *
    props.serviceFeePerPaxAndRoute;

  let baggageKeys = Object.keys(props.selectedBaggage);
  let baggagePrice = 0;
  if (baggageKeys.length > 0) {
    for (let i = 0; i < baggageKeys.length; i++) {
      let key = baggageKeys[i];
      if (props.selectedBaggage[key].hasOwnProperty("price")) {
        baggagePrice += props.selectedBaggage[key].price;
      }
    }
  }

  let totalFlightPrice =
    props.adtCount * adtFlightprice +
    props.chdCount * chdFlightprice +
    props.infCount * infFlightprice +
    baggagePrice;

  let vehicleOffer = "";
  let options = "";
  let isOptionSelected = false;
  let keys = [];
  let totalpriceRentalcar = 0;
  if (vehicle !== null && typeof vehicle !== "undefined") {
    options = vehicle.options;
    isOptionSelected = false;
    keys = Object.keys(options);
    totalpriceRentalcar = vehicle.effectiveTotalPrice;
    for (let i = 0; i < keys.length; i++) {
      if (vehicle.options[keys[i]].isSelected) {
        isOptionSelected = true;
        totalpriceRentalcar += vehicleOffer.options[keys[i]].price;
      }
    }
  }

  let insurancePrice = 0;
  if (insurance !== null && typeof insurance !== "undefined") {
    insurancePrice = parseFloat(insurance.price);
  }

  let totalprice = totalFlightPrice + totalpriceRentalcar + insurancePrice;

  return (
    <div
      className={
        !props.isMobile
          ? "selectedvehicle-priceoverview"
          : "selectedvehicle-priceoverview-mobile"
      }
    >
      <div className={classes.priceoverviewHeader}>
        {t("react.general.priceoverview")}
      </div>
      <div className={classes.priceoverviewList}>
        <div className={classes.flightContainer}>
          <div style={{ fontWeight: "700" }}>Flug</div>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
            <div>
              {props.adtCount} x {t("react.flight.adults")}
            </div>
            <div style={{ textAlign: "right" }}>
              {adtFlightprice.toFixed(2).replace(".", ",")} €
            </div>
          </div>
          {props.chdCount > 0 ? (
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>
                {props.chdCount} x {t("react.flight.children")}
              </div>
              <div style={{ textAlign: "right" }}>
                {props.chdCount > 0
                  ? chdFlightprice.toFixed(2).replace(".", ",")
                  : "0,00"}{" "}
                €
              </div>
            </div>
          ) : (
            ""
          )}
          {props.infCount > 0 ? (
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>
                {props.infCount} x {t("react.flight.infants")}
              </div>
              <div style={{ textAlign: "right" }}>
                {props.infCount > 0
                  ? infFlightprice.toFixed(2).replace(".", ",")
                  : "0,00"}{" "}
                €
              </div>
            </div>
          ) : (
            ""
          )}
          {baggagePrice > 0 ? (
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>{t("react.flight.addbaggage")}</div>
              <div style={{ textAlign: "right" }}>
                {baggagePrice.toFixed(2).replace(".", ",")} €
              </div>
            </div>
          ) : (
            ""
          )}
          {/*<div style={{display: "grid", gridTemplateColumns: "auto auto"}}>
                        <div>
                            {t('react.flight.service-fee')}
                        </div>
                        <div style={{textAlign: "right"}}>
                            {serviceFee.toFixed(2).replace(".", ",")} €
                        </div>
                    </div>*/}
          {vehicle !== null && typeof vehicle !== "undefined" && false ? (
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>{t("react.flight.flight-total")}</div>
              <div style={{ textAlign: "right" }}>
                {totalFlightPrice.toFixed(2).replace(".", ",")} €
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>

        {vehicle !== null && typeof vehicle !== "undefined" ? (
          <div
            style={{
              paddingTop: "2px",
              marginTop: "5px",
              borderTop: "2px dotted #e4e4e4",
            }}
          >
            <div style={{ fontWeight: "700" }}>Mietwagen</div>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>{t("react.rentalcar.vehicle-price")}</div>
              <div style={{ textAlign: "right" }}>
                {totalpriceRentalcar.toFixed(2).replace(".", ",")} €
              </div>
            </div>
            {vehicle.dropFee > 0 ? (
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <div>{t("react.rentalcar.oneway-fee")}</div>
                <div style={{ textAlign: "right" }}>
                  {vehicle.dropFee.toFixed(2).replace(".", ",")} €
                </div>
              </div>
            ) : (
              ""
            )}
            {/*<div style={{display: "grid", gridTemplateColumns: "auto auto", paddingBottom: "5px"}}>
                                <div>
                                    {t('react.general.service-fee')}
                                </div>
                                <div style={{textAlign: "right"}}>
                                    {vehicle.systemFee.toFixed(2).replace(".", ",")} €
                                </div>
                            </div>*/}
            {keys.map(function (key) {
              if (options[key].isSelected) {
                return (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      paddingTop: "5px",
                    }}
                  >
                    <div>{options[key].description}</div>
                    <div style={{ textAlign: "right" }}>
                      {options[key].price.toFixed(2).replace(".", ",")} €
                    </div>
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div />
        )}

        {insurance !== null && typeof insurance !== "undefined" ? (
          <div
            style={{
              paddingTop: "2px",
              marginTop: "5px",
              borderTop: "2px dotted #e4e4e4",
            }}
          >
            <div style={{ fontWeight: "700" }}>{t("pdf.insurance")}</div>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>{t("react.general.insurance-fee")}</div>
              <div style={{ textAlign: "right" }}>
                {insurance.price.replace(".", ",")} €
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}

        {!isB2C() ? (
          <React.Fragment>
            <div
              style={{
                paddingTop: "2px",
                marginTop: "5px",
                borderTop: "2px dotted #e4e4e4",
              }}
            >
              <div style={{ fontWeight: "700" }}></div>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <div>
                  {t("flightbooking.tax")}
                  <a style={{ fontSize: "15px" }}>
                    <CustomTooltip title={"Steuern sind inklusive"} arrow>
                      <InfoOutlined
                        fontSize={"inherit"}
                        style={{ verticalAlign: "middle" }}
                      />
                    </CustomTooltip>
                  </a>
                </div>
                <div style={{ textAlign: "right" }}>
                  {totalTax.toFixed(2).replace(".", ",")} €
                </div>
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>
                {t("react.flight.service-fee")}
                <a style={{ fontSize: "15px" }}>
                  <CustomTooltip title={"Servicegebühr ist inklusive"} arrow>
                    <InfoOutlined
                      fontSize={"inherit"}
                      style={{ verticalAlign: "middle" }}
                    />
                  </CustomTooltip>
                </a>
              </div>
              <div style={{ textAlign: "right" }}>
                {serviceFee.toFixed(2).replace(".", ",")} €
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div
            style={{
              paddingTop: "2px",
              marginTop: "5px",
              borderTop: "2px dotted #e4e4e4",
            }}
          >
            <div style={{ fontWeight: "700" }} />
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div>
                Steuern & Gebühren{" "}
                <a style={{ fontSize: "15px" }}>
                  <CustomTooltip
                    title={"Steuern und Gebühren sind inklusive"}
                    arrow
                  >
                    <InfoOutlined
                      fontSize={"inherit"}
                      style={{ verticalAlign: "middle" }}
                    />
                  </CustomTooltip>
                </a>
              </div>
              <div style={{ textAlign: "right" }}>
                {(totalTax + serviceFee).toFixed(2).replace(".", ",")} €
              </div>
            </div>
          </div>
        )}

        <div
          className={classes.priceoverviewlistTotal}
          style={isOptionSelected ? { borderTop: "1px solid #e4e4e4" } : {}}
        >
          <div>{t("react.general.total")}</div>

          <div style={{ textAlign: "right" }}>
            {totalprice.toFixed(2).replace(".", ",")} €
          </div>
        </div>
      </div>
    </div>
  );
}
