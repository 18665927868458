import { TourSearchResults } from "api/types";
import React from "react";
import { Order } from "redux/slices/orderSlice";
import { useReduxReducer } from "redux/store";

function useResultFilter(
  data: TourSearchResults | undefined,
  totalGuests?: number
) {
  const activeFilters = useReduxReducer(
    (state) => state.filters.activeFilters
  );

  const allResults = data?.tours || [];

  const order = useReduxReducer((state) => state.order);

  return React.useMemo(() => {
    if (allResults.length === 0) return [];
    let results = [...allResults];

    if (activeFilters.name) {
      results = results.filter((result) =>
        result.name.toLowerCase().includes(activeFilters.name!.toLowerCase())
      );
    }

    return results;
  }, [allResults, activeFilters, order]);
}

export default useResultFilter;
