import React, { useEffect, useState } from 'react';
import { useReduxReducer } from 'redux/store';
import WalletAgencies from './components/WalletAgencies';
import WalletMain from './components/WalletMain';

const Wallet: React.FC = () => {
    const { isAdmin, userData } = useReduxReducer((state) => state.general);
    const [agencyNumber, setAgencyNumber] = useState<string>("");

    useEffect(() => {
        if (userData) {
          const agencyNum = userData.agencyNumber || "";
          setAgencyNumber(agencyNum);
        }
      }, [userData]);
    return (
    <>
      {isAdmin 
        ?  <WalletAgencies />
        :  <WalletMain/> }
     
    </>
  );
};

export default Wallet;
