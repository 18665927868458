import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface StringState {
 value: string
}

const initialState: StringState = {
 value: ''
}

export const prevUrlSlice = createSlice({
 name: 'prevUrl',
 initialState,
 reducers: {
    setPrevUrl: (state, action: PayloadAction<string>) => {
     state.value = action.payload
   },
 },
})

export const { setPrevUrl } = prevUrlSlice.actions

export default prevUrlSlice.reducer
