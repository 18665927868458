import { HotelSearchAutocompleteResult, SearchPayload } from "api/types";
import { RoomInput } from "components/common/HeroSearchForm/GuestsInput";
import { DateRange } from "components/common/HeroSearchForm/HotelSearchForm";
import RoomOccupanciesService from "./RoomOccupanciesService";
import ValidationService from "./ValidationService";

class HotelQueryParametersService {
  static encode(
    nationality: string,
    hotel: HotelSearchAutocompleteResult | null,
    dateRange: DateRange,
    guests: RoomInput[]
  ): string | null {
    if (!nationality || !hotel || !dateRange.startDate || !dateRange.endDate) {
      return null;
    }

    let roomOccupancies =
      RoomOccupanciesService.stringifyRoomOccupancies(guests);

    return new URLSearchParams({
      searchType: "HOTEL",
      checkinDate: dateRange.startDate.format("YYYY-MM-DD"),
      checkoutDate: dateRange.endDate.format("YYYY-MM-DD"),
      destinationId: hotel.id,
      destinationType: hotel.type,
      label: hotel.label,
      nationality,
      roomOccupancies,
    }).toString();
  }

  static decode(urlParams: URLSearchParams): SearchPayload | null {
    let checkinDate = urlParams.get("checkinDate") || null;
    let checkoutDate = urlParams.get("checkoutDate") || null;
    let destinationId = urlParams.get("destinationId") || null;
    let destinationType = urlParams.get("destinationType") || null;
    let label = urlParams.get("label") || null;
    let nationality = urlParams.get("nationality") || null;
    let roomOccupanciesParameter = urlParams.get("roomOccupancies") || null;

    checkinDate = ValidationService.date(checkinDate) ? checkinDate : null;
    checkoutDate = ValidationService.date(checkoutDate) ? checkoutDate : null;
    nationality = ValidationService.nationality(nationality)
      ? nationality
      : null;
    roomOccupanciesParameter = ValidationService.roomOccupancies(
      roomOccupanciesParameter
    )
      ? roomOccupanciesParameter
      : null;

    if (
      !checkinDate ||
      !checkoutDate ||
      !destinationId ||
      !destinationType ||
      !nationality ||
      !roomOccupanciesParameter ||
      !label
    ) {
      return null;
    }

    const { roomOccupancies, totalGuests } =
      RoomOccupanciesService.getRoomOccupanciesValues(
        roomOccupanciesParameter!
      );

    return {
      searchType: "HOTEL",
      checkinDate: checkinDate!,
      checkoutDate: checkoutDate!,
      destinationId: destinationId!,
      destinationType: destinationType!,
      nationality: nationality!,
      roomOccupancies,
      label: label!,
      totalGuests,
    };
  }
}

export default HotelQueryParametersService;
