import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {useMediaQuery} from "react-responsive";

export function JupiterSubmitButton( props )
{
    const isMobile = useMediaQuery({ query: `(max-width: 960px)` });
    const ColorButton = withStyles((theme) => ({
        root: {
            width: props.btnWidth,
            fontSize: '14px',
            fontWeight: "600 !important",
            height: props.btnHeight,
            borderColor: '#1976d1',
            color: '#ffffff',
            backgroundColor: '#1976d1',
            '&:hover': {
                backgroundColor: '#1463B0',
            },
        },
    }))(Button);
    const DisabledButton = withStyles((theme) => ({
        root: {
            width: props.btnWidth,
            fontSize: '14px',
            height: props.btnHeight,
            borderColor: '#d1e8ff',
            color: '#5a5a5a',
            backgroundColor: '#d1e8ff'
        },
    }))(Button);

    if ( props.hasOwnProperty( 'disabled' ) && props.disabled )
    {
        return(
            <DisabledButton variant="contained" disabled>
                { props.btnLabel }
            </DisabledButton>
        )
    }

    return(
        <ColorButton variant="contained" color="primary" onClick = { ( event ) => {
            props.btnOnClick( event );
        } }>
            { props.btnLabel }
        </ColorButton>
    )
}