import React, { ReactNode } from 'react';
import heroImage from "../../images/heroImage.jpg"

interface ImageSliderProps {
  children: ReactNode;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };

  return (
   
    <div className="relative flex justify-end z-10" style={{
      backgroundImage: `url(${heroImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-25"></div>
    <div className="py-16 container w-full">
  {children}
</div>

  </div>
     
  );
};

export default ImageSlider;
