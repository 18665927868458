import React, {FC, useState} from "react"
import Fetcher from "../../../api/helpers/fetcher";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
export interface ISearchAutocompleteResult {
        key?: string;
        title?: string;
        subTitle?: string;
        type?: string;
        id: string;
        text: string;
        label?: string;
}

interface IMobileAutoComplete {
    className?: string
    defaultValue: string
    selectedDestination: ISearchAutocompleteResult|null
    setDestination: (value: ISearchAutocompleteResult) => void
    getUrl: (term: string) => string;
    placeholder: string;

}

export const MobileAutoComplete: FC<IMobileAutoComplete> = (
    {
        className = "rounded-sm bg-white",
        defaultValue,
        selectedDestination,
        setDestination,
        getUrl,
        placeholder,

    }) => {

    const [ openModal, setOpenModal ] = useState<boolean>(false)
    const [ availableDestinations, setAvailableDestinations ] = useState<ISearchAutocompleteResult[]>([])

    const openModalHandler = () => {
        setOpenModal(!openModal)
    }

    const search = async (term: string) => {
        try {
            const response = await Fetcher(getUrl(term));
            const results = response.hasOwnProperty('results') ? response.results : response;
            setAvailableDestinations(results as ISearchAutocompleteResult[]);
        } catch (error) {
            console.error("Error during search:", error);
        }
    };


    const selectedDestinationHandler = (value: ISearchAutocompleteResult) => {
        setDestination(value)
        openModalHandler()
    }
console.log(availableDestinations)
    return (
        <>
            <InputText
                className={`${className} w-full focus:outline-0 focus:border-none focus:outline-offset-0 border-neutral-200 dark:border-neutral-700 dark:bg-neutral-900 cursor-pointer`}
                type={'text'}
                style={{ fontSize: '1.1rem', padding: '0.5rem 1rem' }}
                readOnly
                value={selectedDestination ? selectedDestination.text : ''}
                placeholder={defaultValue}
                onClick={() => openModalHandler()}
            />

            <Dialog
                header={defaultValue}
                visible={openModal}
                position={'bottom'}
                style={{ height: '100%', width: '100%', margin: 0 }}
                onHide={openModalHandler}
                draggable={false}
                resizable={false}
            >
                <div className={`pt-2`}>
                    <InputText
                        autoFocus
                        className={`w-full pt-2 rounded-lg focus:outline-0 focus:border-none focus:outline-offset-0 border-neutral-200 bg-white dark:border-neutral-700 dark:bg-neutral-900`}
                        type={'text'}
                        style={{ fontSize: '1.3rem' }}
                        placeholder={placeholder}
                        onChange={(e) => search(e.target.value)}
                    />
                </div>
                <div className={`mt-4`}>
                    {
                       availableDestinations.length > 0 && availableDestinations?.map(destination => (
                            <div
                                key={destination.id}
                                className={`border-b py-2.5 px-2 cursor-pointer hover:bg-gray-300`}
                                onClick={() => selectedDestinationHandler(destination)}
                            >
                                <div className={`2 text-xl py-2`}>
                                    {destination.hasOwnProperty('label') ? destination.label : destination.text}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Dialog>
        </>
    )
}
