import React, {FC, useEffect, useState} from "react"
import {IAgentSalesreport, IAgentSalesreportSummary, IDateRange, TBookingType} from "../../api/types";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {getAgentSalesreportURL} from "../../api/helpers/api-constants";
import {VerticalBar} from "./VerticalBar";
import fetcher from "../../api/helpers/fetcher";
import {InvoiceFilter} from "../InvoiceData/InvoiceFilter";
import {createFlightSummary, createSalesreportSummary, createTotal} from "utils/createSalesreportSummary";
import {CompareTable} from "./CompareTable";
import {useReduxReducer} from "../../redux/store";
import {TotalTable} from "./TotalTable";
import {AllSingleEntriesTable} from "./AllSingleEntriesTable";
import {useDispatch} from "react-redux";
import {setIsApp} from "../../redux/slices/generalSlice";

export const SalesreportPage: FC = () => {
    const dispatch = useDispatch()
    const { isApp } = useReduxReducer(state => state.general)

    const [dataLoaded, setDataLoaded] = useState<boolean>(true);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    const [data, setData] = useState<IAgentSalesreport[]>([]);
    const [dataLastYear, setDataLastYear] = useState<IAgentSalesreport[]>([]);

    const [dataSets, setDataSets] = useState<{[key: string]: number[]}>({})
    const [dataSetsLastYear, setDataSetsLastYear] = useState<{[key: string]: number[]}>({})

    const [sortedDataSets, setSortedDataSets] = useState<[string, number][]>([])
    const [sortedDataSetsLastYear, setSortedDataSetsLastYear] = useState<[string, number][]>([])

    const [flightData, setFlightData] = useState<IAgentSalesreport[]>([]);
    const [flightDataLastYear, setFlightDataLastYear] = useState<IAgentSalesreport[]>([])

    const [hotelData, setHotelData] = useState<IAgentSalesreport[]>([]);
    const [hotelDataLastYear, setHotelDataLastYear] = useState<IAgentSalesreport[]>([]);

    const [flightNHotelData, setFlightNHotelData] = useState<IAgentSalesreport[]>([]);
    const [flightNHotelDataLastYear, setFlightNHotelDataLastYear] = useState<IAgentSalesreport[]>([]);

    const [dynamicData, setDynamicData] = useState<IAgentSalesreport[]>([]);
    const [dynamicDataLastYear, setDynamicDataLastYear] = useState<IAgentSalesreport[]>([]);

    const [rentalcarData, setRentalcarData] = useState<IAgentSalesreport[]>([]);
    const [rentalcarDataLastYear, setRentalcarDataLastYear] = useState<IAgentSalesreport[]>([]);

    const [transferData, setTransferData] = useState<IAgentSalesreport[]>([]);
    const [transferDataLastYear, setTransferDataLastYear] = useState<IAgentSalesreport[]>([]);

    const [insuranceData, setInsuranceData] = useState<IAgentSalesreport[]>([]);
    const [insuranceDataLastYear, setInsuranceDataLastYear] = useState<IAgentSalesreport[]>([]);

    const [summary, setSummary] = useState<IAgentSalesreportSummary>({"0": {}, "1": {}, "2": {}, "3": {}, "4": {}, "5": {}, "6": {}, "7": {}})
    const [summaryLastYear, setSummaryLastYear] = useState<IAgentSalesreportSummary>({"0": {}, "1": {}, "2": {}, "3": {}, "4": {}, "5": {}, "6": {}, "7": {}})

    const [dateRange, setDateRange] = useState<IDateRange>({startDate: moment().subtract(3, 'weeks'), endDate: moment()});
    const [dateRangeLastYear, setDateRangeLastYear] = useState<IDateRange>({startDate: moment().subtract(3, 'weeks').subtract(1, 'year'), endDate: moment().subtract(1, 'year')});

    const [chartSelectedAirlines, setChartSelectedAirlines] = useState<string[]>([])

    const [selectedBookingType, setSelectedBookingType ] = useState<TBookingType>("0")
    const [datePickerIsOpen, setDatePickerIsOpen ] = useState<boolean>(false)

    const [total, setTotal] = useState<{[key: string]: number}>({})
    const [totalLastYear, setTotalLastYear] = useState<{[key: string]: number}>({})

    const [partnerCode, setPartnerCode] = useState<string>('')

    const { t } = useTranslation();
    const { isAdmin } = useReduxReducer(state => state.general)
    const [agencyName, setagencyName] = useState<string | null>(null);
//BookingType-Liste;
//1= Flug
//2= Hotel
//3= Flug und Hotel
//4= Dynamisch
//5= Mietwagen
//6= Transfer
//7= Versicherung
//8= Gesamt

    const bookingTypes = [
        t('react.searchmask.tab.flight'),
        t('react.searchmask.tab.hotel'),
        t('react.searchmask.tab.flightsandhotel'),
        t('homepage.products.product5.title'),
        t('react.searchmask.tab.rentacar'),
        t('react.searchmask.tab.transfer'),
        t('navbar.insurance'),
        t('react.general.total')
    ]

    useEffect(() => {
        if (!isApp && window.location.hash.includes('app'))
            dispatch(setIsApp(true))
    }, [isApp])

    useEffect(() => {
        if (dateRange.startDate !== null && dateRange.endDate !== null && (partnerCode.length === 5 || partnerCode.length === 0))
            fetchData()
    }, [dateRange, partnerCode])

    useEffect(() => {
        if (data.length > 0)
            extractData(data)
    }, [data])

    useEffect(() => {
        if (dataLastYear.length > 0)
            extractData(dataLastYear, true)
    }, [dataLastYear])

    useEffect(() => {
        const _summary: IAgentSalesreportSummary = {
            "0": createFlightSummary(flightData),
            "1": createSalesreportSummary(hotelData, "Hotel"),
            "2": createSalesreportSummary(flightNHotelData, "Flight and Hotel"),
            "3": createSalesreportSummary(dynamicData, "Dynamic"),
            "4": createSalesreportSummary(rentalcarData, "Rentalcar"),
            "5": createSalesreportSummary(transferData, "Transfer"),
            "6": createSalesreportSummary(insuranceData, "Insurance"),
            "7": createTotal(t, data)
        }
        setSummary(_summary)
    }, [flightData])

    useEffect(() => {
        const _summary: IAgentSalesreportSummary = {
            "0": createFlightSummary(flightDataLastYear),
            "1": createSalesreportSummary(hotelDataLastYear, "Hotel"),
            "2": createSalesreportSummary(flightNHotelDataLastYear, "Flight and Hotel"),
            "3": createSalesreportSummary(dynamicDataLastYear, "Dynamic"),
            "4": createSalesreportSummary(rentalcarDataLastYear, "Rentalcar"),
            "5": createSalesreportSummary(transferDataLastYear, "Transfer"),
            "6": createSalesreportSummary(insuranceDataLastYear, "Insurance"),
            "7": createTotal(t, dataLastYear)
        }
        setSummaryLastYear(_summary)
    }, [flightDataLastYear])

    useEffect(() => {
        if (Object.keys(summary).length > 0 && selectedBookingType !== "7") {
            const _dataSets: {[key: string]: number[]} = {}
            Object.keys(summary[selectedBookingType]).map(date => {
                Object.keys(summary[selectedBookingType][date]).map(key => {
                    if (key.length > 0)
                        _dataSets[key] = Array.from({length: Object.keys(data).length}, () => 0)
                })
            })

            if (Object.keys(_dataSets).length > 0) {
                Object.keys(summary[selectedBookingType]).map((date, index) => {
                    Object.keys(summary[selectedBookingType][date]).map(key => {
                        if (key.length > 0)
                            _dataSets[key][index] = summary[selectedBookingType][date][key]
                    })
                })
            }

            setDataSets(_dataSets)
            setSortedDataSets(processData(_dataSets))
        }
    }, [summary, selectedBookingType])

    useEffect(() => {
        if (Object.keys(summaryLastYear).length > 0 && selectedBookingType !== "7") {
            const _dataSets: {[key: string]: number[]} = {}
            Object.keys(summaryLastYear[selectedBookingType]).map(date => {
                Object.keys(summaryLastYear[selectedBookingType][date]).map(key => {
                    if (key.length > 0)
                        _dataSets[key] = Array.from({length: Object.keys(data).length}, () => 0)
                })
            })

            if (Object.keys(_dataSets).length > 0) {
                Object.keys(summaryLastYear[selectedBookingType]).map((date, index) => {
                    Object.keys(summaryLastYear[selectedBookingType][date]).map(key => {
                        if (key.length > 0)
                            _dataSets[key][index] = summaryLastYear[selectedBookingType][date][key]
                    })
                })
            }

            setDataSetsLastYear(_dataSets)
            setSortedDataSetsLastYear(processData(_dataSets))
        }
    }, [summaryLastYear, selectedBookingType])

    useEffect(() => {
        if (selectedBookingType === '0') {
            if (chartSelectedAirlines.length === 0) {
                const _chartSelectedAirlines: string[] = []
                sortedDataSets.slice(0,4).map((key) => _chartSelectedAirlines.push(key[0]))
                setChartSelectedAirlines(_chartSelectedAirlines)
            }
        }
    }, [chartSelectedAirlines])

    const fetchData = async () => {
        setShowErrorAlert(false);

        await makeRequest({
            partnerCode: partnerCode,
            dateBegin: dateRange.startDate?.format("DD.MM.YYYY"),
            dateEnd: dateRange.endDate?.format("DD.MM.YYYY")
        })

        await makeRequest({
            partnerCode: partnerCode,
            dateBegin: dateRangeLastYear.startDate?.format("DD.MM.YYYY"),
            dateEnd: dateRangeLastYear.endDate?.format("DD.MM.YYYY")
        }, true)
    }

    const makeRequest = async (agentInvoiceListRequest: {partnerCode: string, dateBegin: string|undefined, dateEnd: string|undefined}, lastYear: boolean = false) => {
        setDataLoaded(false)
        const response = await fetcher(getAgentSalesreportURL(agentInvoiceListRequest.partnerCode, agentInvoiceListRequest.dateBegin!, agentInvoiceListRequest.dateEnd!, agentInvoiceListRequest.partnerCode.length >= 5 ));

        const isPartnerCodeValid = agentInvoiceListRequest.partnerCode.length === 5;
        const agencyName = isPartnerCodeValid ? response.agencyName : null;
        const data = isPartnerCodeValid ? response.list : response;
        
        setagencyName(agencyName);
        
        if (lastYear) {
            setDataLastYear(data);
        } else {
            setData(data);
        }
        
        setDataLoaded(true);
        
    }


    const processData = (dataSets: {[key: string]: number[]}): [string, number][] => {
        const results: { [key: string]: number } = {}
        for (const key in dataSets) {
            if (dataSets.hasOwnProperty(key)) {
                const total = dataSets[key].reduce((acc, value) => acc + value, 0);
                results[key] = Number.parseInt(total.toFixed(0));
            }
        }
        return Object.entries(results).sort((a, b) => b[1] - a[1])
    }

    const extractData = (_data: IAgentSalesreport[], lastYear: boolean = false) => {
        const _flightData: IAgentSalesreport[] = []
        const _hotelData: IAgentSalesreport[] = []
        const _flightNHotelData: IAgentSalesreport[] = []
        const _dynamicData: IAgentSalesreport[] = []
        const _rentalcarData: IAgentSalesreport[] = []
        const _transferData: IAgentSalesreport[] = []
        const _insuranceData: IAgentSalesreport[] = []

        _data.map(value => {
            switch (value.BookingTypeID) {
                case 1:
                    _flightData.push(value)
                    break
                case 2:
                    _hotelData.push(value)
                    break
                case 3:
                    _flightNHotelData.push(value)
                    break
                case 4:
                    _dynamicData.push(value)
                    break
                case 5:
                    _rentalcarData.push(value)
                    break
                case 6:
                    _transferData.push(value)
                    break
                case 7:
                    _insuranceData.push(value)
                    break
            }
        })

        if (lastYear) {
            setFlightDataLastYear(_flightData)
            setHotelDataLastYear(_hotelData)
            setFlightNHotelDataLastYear(_flightNHotelData)
            setDynamicDataLastYear(_dynamicData)
            setRentalcarDataLastYear(_rentalcarData)
            setTransferDataLastYear(_transferData)
            setInsuranceDataLastYear(_insuranceData)
        } else {
            setFlightData(_flightData)
            setHotelData(_hotelData)
            setFlightNHotelData(_flightNHotelData)
            setDynamicData(_dynamicData)
            setRentalcarData(_rentalcarData)
            setTransferData(_transferData)
            setInsuranceData(_insuranceData)
        }
    }

    const calcTotalFlightPrice = (airlineData: IAgentSalesreport[]): number => {
        const total: number = airlineData.reduce((_total, _data) => _total + _data.SalePrice, 0)
        return Number.parseInt(total.toFixed(2))
    }

    const getData = (): IAgentSalesreport[] => {
        switch (selectedBookingType) {
            case "0": return flightData
            case "1": return hotelData
            case "2": return flightNHotelData
            case "3": return dynamicData
            case "4": return rentalcarData
            case "5": return transferData
            case "6": return insuranceData
            default: return []
        }
    }

    return (
        <main className="container pt-5 pb-5">
            <div className="flex w-full justify-between items-center">
                <h1 className="mb-3 text-lg font-semibold">{t("navbar.sales-report")}</h1>
                {isAdmin && agencyName && <h1 className="mb-3 text-lg text-red-500 font-semibold">{agencyName}</h1>}
                    <InvoiceFilter
                    dateRange={dateRange}
                    withPartnerCodeFilter={isAdmin}
                    withSearchField={false}
                    partnerCode={isAdmin ? partnerCode : undefined}
                    searchPartnerCodeLabel={isAdmin ? t('manage-agencies.agency-number') : undefined}
                    selectedBookingType={selectedBookingType}
                    bookingTypes={bookingTypes}
                    setPartnerCode={isAdmin ? setPartnerCode : undefined}
                    setDateRange={((value) => {
                        setDateRange(value)
                        setDateRangeLastYear({startDate: moment(value.startDate).subtract(1, 'year')!, endDate: moment(value.endDate).subtract(1, 'year')!})
                    })}
                    setSelectedBookingType={((type) => setSelectedBookingType(type.toString() as TBookingType))}
                    datePickerIsOpen={(isOpen) => setDatePickerIsOpen(isOpen)}
                />

            </div>

            <div className={`mb-3`}>
                <VerticalBar
                    data={Object.keys(summary[selectedBookingType]).length > 0 ? summary[selectedBookingType] : {}}
                    dataLastYear={Number.parseInt(selectedBookingType) === 7 ? summaryLastYear[selectedBookingType] : null}
                    showTotal={Number.parseInt(selectedBookingType) === 7}
                    isLoading={!dataLoaded}
                    currentYear={dateRange.endDate?.year()}
                    lastYear={dateRangeLastYear.endDate?.year()}
                    showDiagramIcons={!datePickerIsOpen && Number.parseInt(selectedBookingType) !== 7}
                    infoText={t('generals.no-entry-found')}
                    dataSets={dataSets}
                    sortedDataSets={sortedDataSets}
                    selectedAirlines={selectedBookingType === "0" ? chartSelectedAirlines : null}
                    setSelectedAirline={(value: string[]) => setChartSelectedAirlines(value)}
                />
            </div>

            {
                (selectedBookingType !== "7" && sortedDataSetsLastYear.length > 0 && sortedDataSets.length > 0) && (
                    <CompareTable
                        label={selectedBookingType === "0" ? t('flights.airline') : ''}
                        isFlight={selectedBookingType === "0"}
                        totalFlightPrice={selectedBookingType === "0" ? calcTotalFlightPrice(flightData) : null}
                        totalFlightPriceLastYear={selectedBookingType === "0" ? calcTotalFlightPrice(flightDataLastYear) : null}
                        dateRange={dateRange}
                        dateRangeLastYear={dateRangeLastYear}
                        sortedDataSets={sortedDataSets}
                        sortedDataSetsLastYear={sortedDataSetsLastYear}
                    />
                )
            }
            {
                selectedBookingType === "7" && (
                    <TotalTable
                        data={summary[selectedBookingType]}
                        dataLastYear={summaryLastYear[selectedBookingType]}
                        dateRange={dateRange}
                        dateRangeLastYear={dateRangeLastYear}
                    />
                )
            }
            {
                selectedBookingType !== "7" &&
                    <AllSingleEntriesTable
                        entries={getData()}
                        bookingType={selectedBookingType}
                    />
            }
        </main>
    )
}
