import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

interface DatePickerProps {
  date: moment.Moment;
  handleDateChange: (date: moment.Moment | null) => void;
  className?: string;
  error?: string;
}

export const DatePickermui: React.FC<DatePickerProps> = ({
  date,
  handleDateChange,
  className = '',
  error = '',
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        value={date}
        onChange={(newDate) => handleDateChange(newDate)}
        className={className}
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!error,
            helperText: error,
          },
        }}
      />
    </LocalizationProvider>
  );
};
