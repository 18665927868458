import { postRescanPnr, postRescanPnrtakeReservation } from 'api/helpers/api-constants';
import { fetcherWithoutJSON } from 'api/helpers/fetcher';
import postFetcher from 'api/helpers/postFetcher';
import { BookingRoute } from 'api/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { disableButton } from 'utils/disableButton';
import { getStaticUrl } from 'utils/getStaticUrl';

interface PnrData {
  id: string;
  status: string;
  optionDate: string;
  filekey: string;
  flightList: any[]; 
  rescanAvailable?: boolean;
  refundAvailable?: boolean;
  totalAmount: number;
  airlineSystem?: string;
  voidAvailable?: boolean;
}

interface ReservationOptionsButtonsProps {
  row: BookingRoute;
  bookingKey: string;
  rescanPnrAllowed: boolean;
  };
  


const ReservationOptionsButtons: React.FC<ReservationOptionsButtonsProps> = ({ row, bookingKey, rescanPnrAllowed }) => {
  const [loading, setLoading] = useState(false);
  const baseUrl = getStaticUrl('b2b');
  const {t} = useTranslation()
  const applyPgsDiscount = async (bookingKey: string, filekey: string): Promise<void> => {
    try {
      const response = await fetcherWithoutJSON(
        `${baseUrl}/agency/getPgsCampaigns?bookingKey=${bookingKey}&filekey=${filekey}`
      );
      const res = await response.text();
      Swal.fire(
        res,
        "Dikkat: Yukarıda indirimli fiyat görüntüleniyorsa biletin fiyatına otomatik olarak arka planda indirim uygulanmış olup, 10 dakika içerisinde sonraki aşama olan “Biletme” gerçekleştirildikten sonra indirimli fiyatı ekranda yeniden görebilirsiniz.",
        "warning"
      );
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error",
        "An error occurred while applying the PGS discount.",
        "error"
      );
    }
  };

  const cancelPnr = async (formElement: HTMLFormElement, fileKey: string): Promise<void> => {
    try {
      setLoading(true);
      const confirmation = window.confirm(t("generals.are-you-sure-you-want-to-proceed"));
      if (confirmation) {
        if (formElement.length > 0) {
          formElement.action = `${baseUrl}/agency/cancelPnr?filekey=${fileKey}`;
          formElement.addEventListener('submit', () => {
            setLoading(false);
          });
          formElement.submit();
        } else {
          setLoading(false);
          console.error("No forms found on the page.");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVoid = async (fileKey: string): Promise<void> => {
    try {
      const confirmation = window.confirm(t("flightbooking.are-you-sure-you-want-to-void"));
      if (confirmation) {
        setLoading(true);
        const url = "/agency/voidPnr";
        const data = { filekey: fileKey };
        const options = {
          method: "POST",
          body: JSON.stringify(data),
        };
        const response = await fetcherWithoutJSON(url, options);
        if (!response.ok) {
          throw new Error("Failed to void PNR");
        }
      } else {
        console.log("Void operation canceled by the user");
      }
    } catch (error) {
      console.error(error);
      Swal.fire(t("app.general-error"), "An error occurred while voiding PNR.", "error");
    } finally {
      setLoading(false);
    }
  };

  const rescanPnr = async (bookingKey: string, filekey: string): Promise<void> => {
    const payload = { bookingKey, filekey };
    try {
      setLoading(true);
      const response = await postFetcher(postRescanPnr(), payload);
      setLoading(false);

      const oldPrice = parseFloat(response.oldPrice);
      const newPrice = parseFloat(response.newPrice);
      const diff = newPrice - oldPrice;
      const newDiff = Math.abs(diff).toFixed(2);
      const depFlightRecommendation = response.depFlightRecommendation;
      const retFlightRecommendation = response.retFlightRecommendation;
      let diffColor = diff > 0 ? "red" : "green";
      let diffSign = diff > 0 ? "+" : "-";

      Swal.fire({
        title: t("flightbooking.options.query-current-price"),
        html: `
          <span style="font-weight:500">
            ${t("generals.old-price")} ${oldPrice.toFixed(2)} EUR<br>
            <b style="color:${diffColor}">${t("generals.difference")} ${diffSign}${newDiff} EUR</b><br>
            ${t("generals.new-price")} ${newPrice.toFixed(2)} EUR<br><br>
            <b style='color:red'>${t("generals.old-booking-will-be-canceled-before-creating-new-booking")}</b>
          </span>`,
        showCancelButton: true,
        confirmButtonText: `<i class="fa fa-plus" aria-hidden="true"></i> ${t("flights.reserve")}`,
        cancelButtonText: `<i class="fa fa-close" aria-hidden="true"></i> ${t("generals.abort")}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const payload1 = {
            bookingKey,
            filekey,
            depFlightRecommendation,
            retFlightRecommendation,
          };
          try {
            setLoading(true);
            const response1 = await postFetcher(postRescanPnrtakeReservation(), payload1);
            setLoading(false);
            if (!response1.hasOwnProperty("status") || response1.status === "error") {
              Swal.fire(t("app.general-error"), response1.reason, "error");
              return;
            }
            const newBookingKey = response1.newBookingKey;
            Swal.fire({
              title: t("generals.success"),
              showConfirmButton: false,
              html: `
                <span style="font-weight:500">
                  <br><br>
                  JUPITER Key: <a href="https://www.atrtouristik.com/ibe#/booking/detail/${newBookingKey}" target="_blank" class="btn btn-success ml-2">
                    <i class="mdi mdi-open-in-new"></i> ${newBookingKey}
                  </a><br>
                </span>`,
            });
          } catch (error) {
            console.error("An error occurred:", error);
            Swal.fire(t("app.general-error"), "An error occurred while rescanning PNR.", "error");
          }
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
      Swal.fire(t("app.general-error"), "An error occurred while rescanning PNR.", "error");
    }
  };

  return (
    <div className="text-center flex">
      {row.status === "RESERVATION" && (
        <div>
          <form id={`form_${row.filekey}`}>
            <input type="hidden" name="bookingKey" value={bookingKey}></input>
            <input type="hidden" name="filekey" value={row.filekey}></input>
          </form>
          <button
            id="cancelPnr"
            type="button"
            onClick={() => {
              const button = document.getElementById("cancelPnr") as HTMLButtonElement | null;
              if (button) {
                disableButton([button]);
              }
              cancelPnr(document.getElementById(`form_${row.filekey}`) as HTMLFormElement, row.filekey);
            }}
            className="text-sm rounded border bg-white px-4 py-2 font-medium text-red-600 hover:border-red-600 hover:text-red-300"
          >
            <i className="fa fa-trash" aria-hidden="true"></i> {t("app.booking.cancel-pnr")}
          </button>
        </div>
      )}
      {rescanPnrAllowed && row.status === "RESERVATION" && (
        <div className="text-center">
          <button
            id="rescanPnr"
            type="button"
            className="text-sm rounded border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700"
            onClick={() => {
              const button = document.getElementById("rescanPnr") as HTMLButtonElement | null;
              if (button) {
                disableButton([button]);
              }
              rescanPnr(bookingKey, row.filekey);
            }}
          >
            <i className="fa fa-refresh" aria-hidden="true"></i> {t("flightbooking.options.query-current-price")}
          </button>
        </div>
      )}
      {row.status === "RESERVATION" && row.airlineSystem === "PCCL" && (
        <div className="btn-group btn-group-sm" role="group" key={row.filekey}>
          <button
            type="button"
            className="text-sm rounded border border-gray-200 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700"
            onClick={() => applyPgsDiscount(bookingKey, row.filekey)}
          >
            <i className="mdi mdi-coins"></i> Pegasus İndirim Sorgula
          </button>
        </div>
      )}
      {row.status === "TICKETED" && row.voidAvailable && (
        <div key={row.filekey} className="text-center">
          <button
            id="void"
            type="button"
            className="text-sm rounded border border-gray-200 bg-red-500 px-4 py-2 font-medium text-white hover:bg-red-900 hover:text-red-700 focus:z-10 focus:text-red-700 focus:ring-2 focus:ring-red-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500"
            onClick={() => {
              const button = document.getElementById("void") as HTMLButtonElement | null;
              if (button) {
                disableButton([button]);
              }
              handleVoid(row.filekey);
            }}
          >
            <i className="fa fa-times"></i>
            <span>Void</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ReservationOptionsButtons;
