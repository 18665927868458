import React, {FC} from 'react'
import {ProgressSpinner} from "primereact/progressspinner";

export const LoadingOverlay: FC = () => {
    const overlayStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(150,150,150,0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    };

    return (
        <div style={overlayStyle}>
            <ProgressSpinner style={{width: '80px', height: '80px'}} strokeWidth="5" animationDuration="1.5s" />
        </div>
    );
}
