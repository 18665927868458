import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { disableButton } from "utils/disableButton";

interface PaymentButtonProps {
  path: string;
  params: {
    [key: string]: string;
  };
}

const PaymentButton: React.FC<PaymentButtonProps> = ({ path, params }) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", path);

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div className="p-2">
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        className="w-full"
      >
        {params.paymentType === "INVOICE" ? (
          <span>{t("flightbooking.ticketing.inv")} + 0,00% </span>
        ) : null}
        {params.paymentType === "WALLET" ? (
          <span>{t("wallet")} + 0,00% </span>
        ) : null}
        {params.paymentType === "KLARNA" ? (
          <span>Klarna Ratenkauf + 3,00% </span>
        ) : null}
         {params.paymentType === "IDEAL" ? (
          <span>IDEAL + 0,00%</span>
        ) : null}
        {params.paymentType === "STRIPE" ? (
          <span>{t("b2c-app.payment.payment-type.credit-card")} - VISA / MasterCard + 1,50% </span>
        ) : null}
      </Button>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{t("booking.payment-confirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {t("generals.are-you-sure-you-want-to-proceed")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t("b2c-app.confirmation-request.reject")}
          </Button>
          <Button 
          id="purchase"
          onClick={() => { 
            const button = document.getElementById("purchase") as HTMLButtonElement | null;
                   if (button) {
                     disableButton([button]);
                  }
            handleCloseDialog();
             handleSubmit(); }} color="primary">
            {t("b2c-app.confirmation-request.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentButton;
