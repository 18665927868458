import React, { FC, useRef, useState, useEffect } from 'react';
import moment from 'moment';
import Input from '../Input/Input';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import './DatePicker.css';
import { isDateValid } from 'utils/validators';
import { InputMask, InputMaskChangeEvent } from 'primereact/inputmask';
import ToastNotification from '../ToastNotification/ToastNotification';
import { useTranslation } from 'react-i18next';

interface IDatePicker {
  className?: string;
  paxType?: string;
  date: moment.Moment;
  handleDateChange: (date: moment.Moment) => void;
  error?: any;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
}

export const DatePicker: FC<IDatePicker> = ({
  date = moment(),
  paxType = '',
  className = '',
  error = '',
  maxDate,
  minDate,
  handleDateChange,
}) => {
  const {t} = useTranslation()
  const calendarRef = useRef<Calendar>(null);
  const [inputValue, setInputValue] = useState<string>(date.isValid() ? date.format('DD.MM.YYYY') : '');
  console.log("inputt",inputValue)
  useEffect(() => {
    setInputValue(date.isValid() ? date.format('DD.MM.YYYY') : '');
  }, [date]);
  const openCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.show();
    }
  };
  const [message, setMessage] = useState<string | null>(null);
      const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
      const resetNotification = () => {
        setMessage(null);
        setSeverity('info');
      };
  
  

  const getMinDate = () => {
    if (minDate) {
      return minDate.toDate();
    } else {
      switch (paxType) {
        case 'ADT':
          return moment().subtract(99, 'year').toDate();
        case 'CHD':
          return moment().subtract(12, 'year').toDate();
        case 'INF':
          return moment().subtract(2, 'year').toDate();
        default:
          return moment().toDate();
      }
    }
  };

  const getMaxDate = () => {
    if (maxDate) {
      return maxDate.toDate();
    } else {
      switch (paxType) {
        case 'ADT':
          return moment().subtract(12, 'year').toDate();
        case 'CHD':
          return moment().subtract(2, 'year').toDate();
        case 'INF':
          return moment().toDate();
        default:
          return moment().toDate();
      }
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | InputMaskChangeEvent) => {
    const inputDate = moment(e.target.value, 'DD.MM.YYYY', true);
    
    if (inputDate.isValid()) {
      const max = maxDate ? maxDate : getMaxDate();
      const min = minDate ? minDate : getMinDate();

      if (inputDate.isBetween(min, max, undefined, '[]')) {
        handleDateChange(inputDate);
      } else {
        console.log(date)
        handleDateChange(moment(null));
        setInputValue("");
        setMessage(t("react.hotel.dateoutofrange"));
        setSeverity("warning");
      }
    } else {
      handleDateChange(moment(null));
      setInputValue("");
      setMessage(t("react.hotel.dateoutofrange"));
      setSeverity("warning");
    }
  };

  

  return (
    <div className={` cursor-pointer ${className}`}>
      <div className="grid grid-rows-2 items-center">
        <div className='flex justify-between'> 
        { 
     
      <InputMask
          className={`${error!.length > 0 ? "border-red-500" : "" }  relative p-1 rounded-lg left-0 top-0 cursor-pointer w-full border-neutral-200 bg-white focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:border-neutral-700 dark:bg-neutral-900 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25`}
          mask="99.99.9999"
          name="birthdate"
          required
          value={inputValue}
          onBlur={(e) =>handleInputChange(e)}
        />}
        
        <Button icon="pi pi-calendar" onClick={openCalendar} className="text-primary-6000" /></div>
     
        {error!.length > 0 && <div className='text-xs text-red-400'>{error}</div>}
        
      </div>
      <Calendar
  ref={calendarRef}
  value={date.isValid() ? date.toDate() : moment().toDate()}
  minDate={getMinDate()}
  maxDate={getMaxDate()} 
  onChange={(e) => handleDateChange(moment(e.value as Date))}
  dateFormat="dd.mm.yy"
  yearNavigator
  yearRange={`1900:${moment(maxDate).year()}`}
  monthNavigator
  inputClassName="hidden-input"
  showOnFocus={false}
  appendTo={document.body}
/>
<ToastNotification message={message} severity={severity} resetNotification={resetNotification} />

    </div>
  );
};
