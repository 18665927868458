import React, {useEffect} from "react";
import $ from "jquery";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import {AtrDateFormat, AtrDateMonthFormat} from "../calendar";
import Calendar from "react-calendar";
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#1976d0'
    },
    title: {
        marginLeft: '50px',
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function JupiterDatePicker( props )
{
    const classes = useStyles();
    let passengerObject = props.passengerObject;

    const closeDatePicker = () =>
    {
        props.closeDatePicker();
    }

    return(
        <div>
            <Dialog fullScreen open={props.openDatePicker} onClose={closeDatePicker} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={closeDatePicker} aria-label="close">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            { passengerObject.birthdate !== '' ? AtrDateMonthFormat( new Date( passengerObject.birthdate ), true ) : '' }
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className = "mobile-calendar-passenger" style = {{ display: "grid", gridTemplateRows: "auto 100px", height: "100%" }}>
                    <Calendar
                        maxDate = { new Date( passengerObject.maxDate ) }
                        value = { passengerObject.birthdate  !== '' ? new Date( passengerObject.birthdate  ) : '' }
                        onClickDay = {
                            ( value, event ) =>
                            {
                                let inputElement = '#flightcheckout-card-passenger-birthdate-' + $( event.target ).closest( 'div[ class^="calendar-passenger" ]' ).attr( 'data-passengerValue' );
                                $( inputElement ).val( AtrDateFormat( value ) );

                                props.selectedDate( value );
                            }
                        }
                    />

                    <div style={{ position: "relative" }}>
                        <div className = "mobile-dialog-calendar" onClick = { closeDatePicker }>
                            <div className = "mobile-dialog-calendar-button">Auswählen</div>
                        </div>
                    </div>

                </div>
            </Dialog>
        </div>
    )
}
