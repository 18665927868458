import { useState, useEffect, SetStateAction } from "react";
import {
  LinearProgress,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Alert,
  Box,
} from "@mui/material";
import { DataGrid, gridClasses, GridPaginationModel, GridRenderCellParams, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { IDateRange, WalletData, WalletTransaction } from "api/types";
import fetcher from "api/helpers/fetcher";
import { deleteWalletEntry, getWalletList, saveWalletEntry } from "api/helpers/api-constants";
import { useReduxReducer } from "redux/store";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import currencyFormat from "utils/currencyFormat";
import moment from "moment";
import ToastNotification from "components/ui/ToastNotification/ToastNotification";
import { postParamRequest } from "api/helpers/postFetcher";
import Swal from "sweetalert2";
import SyncIcon from '@mui/icons-material/Sync';
import { useParams } from "react-router-dom";
import BackButton from "components/ui/ButtonBack/ButtonBack";
import WalletFilter from "./WalletFilter";
import { setDataFilters } from "redux/slices/filtersSlice";
import { DatePicker } from "components/ui/DatePicker/DatePicker";
import { maxHeaderSize } from "http";

const WalletMain = () => {
  const { t } = useTranslation();
  const { isAdmin, userData } = useReduxReducer((state) => state.general);
  const { agencyNumber: paramsAgencyNumber } = useParams<{ agencyNumber: string }>();
  const agencyNumber :string = paramsAgencyNumber || userData?.agencyNumber || "";


  const [walletData, setWalletData] = useState<WalletData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayStart, setDisplayStart] = useState(0);
  const [displayLength, setDisplayLength] = useState(1000);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState<string>("");
  const [searchTerm, setsearchTerm] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState<"edit" | "new">("new");
  const [selectedRow, setSelectedRow] = useState<WalletTransaction | null>(null);
  const [txAmount, setTxAmount] = useState<number>(0);
  const [txDateTime, settxDateTime] = useState<any>(moment().format("YYYY-MM-DD hh:mm"));
  const [txDescription, setTxDescription] = useState<string>("");
  const [dateRange, setdateRange] = useState<IDateRange>({startDate: moment().subtract(5,"year"), endDate: moment()})
  

  const fetchWalletData = async (agencyNum: string, search:string) => {
    if (agencyNum.length === 5) {
      try {
        setLoading(true);
        setError("");
        const response = await fetcher(
          getWalletList(
            displayStart.toString(),
             displayLength.toString(), 
             search, agencyNum, 
             "",
             "", 
             moment(dateRange.startDate).format("YYYY-MM-DD"),
             moment(dateRange.endDate).format("YYYY-MM-DD"))
        );
        setWalletData(response);
        setTotalRecords(response.iTotalRecords);
      } catch (error) {
        console.error("Failed to fetch wallet data", error);
      } finally {
        setLoading(false);
      }
    } else {
      setError(t("react.validation.requiredfield"));
    }
  };

  useEffect(() => { 
 if(agencyNumber)
      fetchWalletData(agencyNumber, "");
    
  }, []);

  useEffect(() => {
    if (agencyNumber) {
      fetchWalletData(agencyNumber,searchTerm);
    }
  }, [displayLength, displayStart]);
  

  const handleSearch = (search:string) => {
    if (agencyNumber) {
      fetchWalletData(agencyNumber, search);
    } else {
      setError(t("react.validation.requiredfield"));
    }
  };

  const handleDialogOpen = (mode: "edit" | "new", row: WalletTransaction | null = null) => {
    setDialogMode(mode);
    setSelectedRow(row);
    setTxAmount(row?.txAmount || 0);
    settxDateTime(row?.txDate ? moment(row.txDate).format("YYYY-MM-DD hh:mm") : moment().format("YYYY-MM-DD hh:mm")  )
    setTxDescription(row?.txDescription || "");
    setOpenDialog(true);
  };  

  const handleDeleteEntry = async (entryId: number) => {
    try {
      setLoading(true);
      const response = await postParamRequest(deleteWalletEntry(), { id: entryId.toString() });
  
      if (response.success) {
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'The wallet entry has been deleted successfully.',
        });
        fetchWalletData(agencyNumber,"");
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to delete the wallet entry!',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred!',
      });
      console.error("Failed to delete wallet entry", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleDialogClose = () => {
    setOpenDialog(false);
    setTxAmount(0);
    setTxDescription("");
  };

  const handleDialogSubmit = async () => {
    // if (!txAmount || !txDescription) {
    //   setError(t("react.validation.requiredfield"));
    //   return;
    // }

    try {
      setLoading(true);
      let response;
    
      if (dialogMode === "edit" && selectedRow) {
        response = await postParamRequest(saveWalletEntry(), {
          id: selectedRow.id.toString(),
          agencyNumber: agencyNumber,
          txAmount: txAmount.toString(),
          txDescription: txDescription,
          txDateTime:txDateTime,

        });
      } else {
        response = await postParamRequest(saveWalletEntry(), {
          id: "",
          agencyNumber: agencyNumber,
          txAmount: txAmount.toString(),
          txDescription: txDescription,
          txDateTime:txDateTime,
        });
      }
    
      if (response.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Wallet entry saved successfully!',
        });
        fetchWalletData(agencyNumber,"");
        handleDialogClose();
      } else {
        handleDialogClose();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save wallet entry!',
        });

      }
    } catch (error) {
      handleDialogClose();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred!',
      });
      console.error("Failed to save wallet entry", error);
    } finally {
      setLoading(false);
    }
    
  };
const handleFilter = async ( 
  ) => {
    console.log(dateRange)
    try {
      setLoading(true);
      setError("");
      const response = await fetcher(
        getWalletList(displayStart.toString(),
         displayLength.toString(), 
         searchTerm, 
         agencyNumber, 
         "desc", 
         "txDate", 
         moment(dateRange.startDate).format("YYYY-MM-DD") || "",
         moment(dateRange.endDate).format("YYYY-MM-DD") || "",         )
      );
      setWalletData(response);
      setTotalRecords(response.iTotalRecords);
    } catch (error) {
      console.error("Failed to fetch wallet data", error);
    } finally {
      setLoading(false);
    }
  
  } 
  
  if (!userData || loading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  const localeText = {
    toolbarDensity: t('react.wallet.density'),
    toolbarDensityLabel: t('react.wallet.density'),
    toolbarDensityCompact: t('0'),
    toolbarDensityStandard: t('1'),
    toolbarDensityComfortable: t('2'),
    toolbarExport: t('export'),
    toolbarExportLabel: t('exportLabel'),
    toolbarExportCSV: t('exportCSV'),
    noRowsLabel: t('generals.no-entry-found'),
    footerRowSelected: (count: number) => t('rowsSelected', { count }),
    footerTotalRows: t('generals.total'),
    paginationRowsPerPage: t(' '), 
  };


  const columnsForAgencies = [
    { field: 'id', headerName: 'ID', flex:1},
    {
      field: 'txDate',
      headerName: t("b2c-app.flights.date"),
      flex:1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.value as string).format('DD/MM/YYYY hh:mm'),
    },    
    {
      field: 'txDescription',
      headerName: t("homepage.career.description"),
      flex:2,
    },
    {
      field: 'txAmount',
      headerName: t('wallet.transaction-amount'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            color: params.value < 0 ? 'red' : 'green',
            fontWeight: 600,
          }}
        >
          {params.value?.toFixed(2)}
        </span>
      ),
    },
   
  ]

  const columns = [
    { field: 'id', headerName: 'ID', flex:1},
    {
      field: 'txDate',
      headerName: t("b2c-app.flights.date"),
      flex:1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.value as string).format('DD/MM/YYYY HH:mm'),
    },    
    {
      field: 'txDescription',
      headerName: t("homepage.career.description"),
      flex:2,
    },
    {
      field: 'txAmount',
      headerName: t('wallet.transaction-amount'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            color: params.value < 0 ? 'red' : 'green',
            fontWeight: 600,
          }}
        >
          {params.value?.toFixed(2)}
        </span>
      ),
    },
    {
      field: 'actionsArea',
      headerName: t(""),
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        
          <>
            <IconButton
              title={t("b2c-app.generals.edit")}
              onClick={() => handleDialogOpen("edit", params.row)}
              className="p-1"
            >
              <EditIcon className="text-primary-6000" />
            </IconButton>
            <IconButton
              title={t("b2c-app.generals.delete")}
              onClick={() => handleDeleteEntry(params.row.id)}
              className="p-1"
            >
              <DeleteIcon className=" text-red-500" />
            </IconButton>
          </>
      
      ),
    }
  ]

  function CustomToolbar() {
    return (
      <div className="flex justify-between p-1 pt-2 items-start">
            
            <GridToolbarContainer>

 <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change density' } }}
      />
       
      <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
          button: { variant: 'outlined' },
        }}
      />
      </GridToolbarContainer>
   <div className="flex justify-end items-start">
    
    <DatePicker
    className=""
      date={moment(dateRange.startDate) }
      handleDateChange={(date) => (setdateRange({startDate: date, endDate: dateRange.endDate}))}
      minDate={moment().subtract(5, "years")}
      maxDate={moment().add(1,"month")}
    />
    <DatePicker
    className=""
      date={moment(dateRange.endDate)}
      handleDateChange={(date) => setdateRange({startDate: dateRange.startDate, endDate: date})}
      minDate={moment().subtract(5, "years")}
      maxDate={moment().add(1,"month")}
    />
    <ButtonPrimary  className=" whitespace-nowrap" onClick={() => handleFilter()}>{t("app.apply-filter")}</ButtonPrimary>

    </div>
    {isAdmin && (
                <div className="col-span-3 text-right flex justify-between items-center gap-12  ">
                  <ButtonPrimary  onClick={() => handleDialogOpen("new")} className=" bg-green-600 hover:bg-green-900">
                    <AddIcon className="w-5 h-5" />
                    {t("generals.add-new")}
                  </ButtonPrimary>
               
                </div>
              )}
      </div>
     
    );
  }

  return (
    <>
      {isAdmin ? (
        <div className="container my-12 space-y-8 min-h-screen">
          <BackButton/>
          {!walletData && (
            <ToastNotification severity="error" message={t("app.general-error")} />
          )}
          {walletData && (
            <>
            
         
           <Paper style={{ height: 'auto', width: '100%' }}>
                 
           <div className="grid grid-cols-12   bg-white  py-4 px-2 mb-2 ">
              <div className="col-span-3 ">
            
                <span className="text-lg font-semibold">
                  <span className="items-center flex text-lg font-medium text-primary-6000">
                    {walletData?.agencyName} -  {agencyNumber}
                  </span>

                 
                  <span
                    className="font-semibold text-lg"
                    style={{
                      color: walletData.walletBalance > 0 ? "green" : "red",
                    }}
                  >
                   {t("wallet.balance")}:{" "}  {currencyFormat(walletData?.walletBalance)}
                  </span>
                </span>
             

              </div>
              <div className="col-span-9 grid gap-4">
              <div className="flex justify-end items-center"> 

            <TextField
              className="pr-4 max-h-8 "
              label={t("react.wallet.search.placeholder")}
              size="small"
              value={searchTerm}
              placeholder={t("react.wallet.search.placeholder")}
              onChange={(e) => setsearchTerm(e.target.value)}
              style={{ minWidth: '30rem', maxHeight:'8rem' } } 

            />
            <span className="px-1">
            <ButtonPrimary 
            className=""
            onClick={() => handleSearch(searchTerm)}>
              <SearchIcon className="w-3 h-3" />
              {t("b2c-app.generals.search")}
            </ButtonPrimary>
            {searchTerm.length > 0 && (
              <span>
                <ButtonPrimary
                  className="ml-2"
                  onClick={() => {
                    handleSearch("");
                    setsearchTerm("");
                  }}
                >
                <SyncIcon className="w-5 h-5" />
                  {t("react.general.reset")}
                </ButtonPrimary>
              </span>
                
            )}
            </span>
        

            </div>
           
                
               
           

              </div>
           </div>
                <DataGrid
                  localeText={localeText}
                  rows={walletData.aaData}
                  columns={isAdmin ? columns : columnsForAgencies}
                  rowCount={totalRecords}
                  sx={{
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                      outline: 'none',
                    },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                      {
                        outline: 'none',
                      },
                  }}
                   slots={{
                    toolbar: CustomToolbar,
                  }}
                  loading={loading}
                  slotProps={{
                    loadingOverlay: {
                      variant: 'linear-progress',
                      noRowsVariant: 'linear-progress',
                    },
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 100,
                      },
                    },
                  }}      
                  pageSizeOptions={[ 25, 50, 100]} 
                  autoHeight

                />
              </Paper>
              
              <Dialog open={openDialog} onClose={handleDialogClose}>
  <DialogTitle>
    {dialogMode === "edit" ? t("b2c-app.generals.edit") : t("generals.add-new")}
  </DialogTitle>
  <DialogContent>
    <TextField
      margin="dense"
      id="txAmount"
      label={t("wallet.transaction-amount")}
      type="number"
      fullWidth
      value={txAmount}
      onChange={(e) => setTxAmount(e.target.value as unknown as number)}
      error={Boolean(error)}
      helperText={error}
    />
    <TextField
      margin="dense"
      id="txDescription"
      label={t("wallet.transaction-description")}
      fullWidth
      value={txDescription}
      onChange={(e) => setTxDescription(e.target.value)}
      error={Boolean(error)}
      helperText={error}
    />
    <TextField
      margin="dense"
      id="txDateTime"
      label={t("wallet.transaction-date-time")}
      type="datetime-local"
      fullWidth
      value={txDateTime}
      onChange={(e) => settxDateTime(e.target.value as unknown as Date)}
      InputLabelProps={{
        shrink: true,  
      }}
    />
   
  </DialogContent>
  <DialogActions>
    <Button onClick={handleDialogClose}>{t("b2c-app.generals.cancel")}</Button>
    <Button onClick={handleDialogSubmit}>
      {dialogMode === "edit" ? t("react.hotel.save") : t("generals.add-new")}
    </Button>
  </DialogActions>
</Dialog>

            </>
          )}
        </div>
      ) : (
        <Alert severity="error">
          {t("app.error-unauthorized")}
        </Alert>
      )}
       
    </>
  );
};

export default WalletMain;
