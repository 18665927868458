import React, {FC} from "react"
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";

interface IInvoiceTypeFilter {
    className?: string
    selectedType: string
    types: string[]
    label: string
    setSelectedType: (type: string) => void
}

export const InvoiceTypeFilter: FC<IInvoiceTypeFilter> = ({selectedType, label,  types, setSelectedType, className = ''}) => {

    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedType(event.target.value as string)
    }

    return (
        <div className={className}>
            <FormControl>
                <InputLabel id="invoiceTypeFilter">{label}</InputLabel>
                <Select
                    labelId={`${label}`}
                    id={`${label}-id`}
                    size="small"
                    style={{ width: 230 }}
                    value={selectedType}
                    label={label}
                    onChange={handleChange}
                >
                    {
                        types.map((type, index) => (
                            <MenuItem value={index}>{type}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
    )
}
