import React, {FC, useEffect, useState} from "react"
import {Dialog} from "primereact/dialog";
import {useTranslation} from "react-i18next";
import {CustomCalendar} from "./index";
import { InputText } from "primereact/inputtext";
import {BestPricesModel} from "../../../api/types";
import "./customCalenar.css"
import moment from "moment";
import {CustomButton} from "../CustomButton";

interface IMobileCustomCalendar {
    isLoadingBestPrices?: boolean
    bestPrices?: BestPricesModel[]
    range?: boolean
    selectedDate: [moment.Moment|null, moment.Moment|null]
    setSelectedDate: (value: [moment.Moment|null, moment.Moment|null]) => void
}

export const MobileCustomCalendar: FC<IMobileCustomCalendar> = (
    {
        isLoadingBestPrices = false,
        range = true,
        bestPrices = [],
        selectedDate,
        setSelectedDate
    }) => {

    const [ _selectedDate, _setSelectedDate ] = useState<[moment.Moment|null, moment.Moment|null]>(selectedDate)
    const [ value, setValue] = useState<string>((selectedDate[0] && selectedDate[1]) ? `${selectedDate[0].format('D. MMM')} - ${selectedDate[1].format('D. MMM')}` : '');
    const [ openModal, setOpenModal ] = useState<boolean>(false)

    const { t } = useTranslation()

    useEffect(() => {
        getValue()
    }, [selectedDate, range])

    const openModalHandler = (newValue: boolean = false) => {
        setOpenModal(!openModal)
        _setSelectedDate(newValue ? _selectedDate : selectedDate)
        getValue()
    }

    const selectDateHandler = () => {
        openModalHandler(true)
        if (_selectedDate[0] && (!range || _selectedDate[1]))
            setSelectedDate(_selectedDate)
    }

    const getValue = () => {
        let _value = "";

        if (selectedDate[0])
            _value += selectedDate[0].format('D. MMM')

        if (range && selectedDate[1])
            _value += ' - ' + selectedDate[1].format('D. MMM')

        setValue(_value)
    }

    const dialogFooter = () => {
        return (
            <>
                <div className={`grid grid-cols-2 text-left mt-2 mb-2`}>
                    <div className={`col-span-1 p-2`}>
                        <span className={`text-xs`}>{t('generals.departure-flights')}</span>
                        <div className={`font-semibold`}>
                            {
                                _selectedDate[0] ?
                                    _selectedDate[0].format('ddd, D. MMM')
                                    :
                                    '--'
                            }
                        </div>
                    </div>
                    {
                        range && (
                            <div className={`col-span-1 p-2 pl-4 border-l-2`}>
                                <span className={`text-xs`}>{t('generals.return-flights')}</span>
                                <div className={`font-semibold`}>
                                    {
                                        _selectedDate[1] ?
                                            _selectedDate[1].format('ddd, D. MMM')
                                            :
                                            '--'
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <CustomButton
                    value={t('general.select.date')}
                    clickeHandler={selectDateHandler}
                />
            </>
        )
    }

    return (
        <>
            <InputText
                className={`text-sm w-full rounded-lg focus:outline-0 focus:border-none focus:outline-offset-0 border-neutral-200 bg-gray-200 dark:border-neutral-700 dark:bg-neutral-900 cursor-pointer`}
                type={'text'}
                style={{ fontSize: '1.1rem', padding: '0.5rem 1rem' }}
                readOnly
                value={value}
                placeholder={t('sales-report.date-range')}
                onChange={(e) => setValue(e.target.value)}
                onClick={() => openModalHandler(false)}
            />

            <Dialog
                className={'atr-datepicker'}
                header={t('general.choose.date')}
                footer={dialogFooter}
                visible={openModal}
                position={'bottom'}
                style={{ height: '100%', width: '100%', margin: 0 }}
                onHide={openModalHandler}
                draggable={false}
                resizable={false}
            >
                <CustomCalendar
                    isLoadingBestPrices={isLoadingBestPrices}
                    bestPrices={bestPrices}
                    range={range}
                    selectedDate={_selectedDate}
                    setSelectedDate={(value) => _setSelectedDate(value)}
                />
            </Dialog>
        </>
    );
}
