import React, { Dispatch, useEffect, useState } from "react";
import {
  AnchorDirectionShape,
  DateRangePicker,
  FocusedInputShape,
} from "react-dates";
import { DateRange } from "./HotelSearchForm";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import useWindowSize from "hooks/useWindowResize";
import moment, { Moment } from "moment";
import { CalendarIcon, ClockIcon } from "@heroicons/react/outline";
import HeroSelect from "../HeroSearchForm/HeroSelect";
import timeSelectOptions from "utils/timeSelectOptions";
import RadioGroup from "components/ui/RadioGroup";
import { useTranslation } from "react-i18next";
import { AirportSearchAutocompleteResult, BestPricesModel } from "api/types";
import { getBestPricesUrl } from "api/helpers/api-constants";

import { Button, Chip, MenuItem, Stack } from "@mui/material";
import Select from "components/ui/Select/Select";
import ButtonClose from "components/ui/ButtonClose/ButtonClose";
import ButtonPrimary from "components/ui/Button/ButtonPrimary";

type Fields = "checkIn" | "checkOut";

export interface DatesRangeInputProps {
  defaultValue: DateRange;
  defaultFocus?: FocusedInputShape | null;
  onChange: Dispatch<React.SetStateAction<DateRange>>;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
  startDateTime?: string | null;
  setStartDateTime?: React.Dispatch<React.SetStateAction<string | null>>;
  endDateTime?: string | null;
  setEndDateTime?: React.Dispatch<React.SetStateAction<string | null>>;
  minDate?: Moment | null;
  maxDate?: Moment | null;
  className?: string;
  fieldClassName?: string;
  wrapClassName?: string;
  numberOfMonths?: number;
  anchorDirection?: AnchorDirectionShape;
  checkinLabel?: string;
  checkoutLabel?: string;
  checkinDateError?: boolean;
  checkoutDateError?: boolean;
  IconCheckin?: React.ReactNode & FC<React.ComponentProps<"svg">>;
  IconCheckout?: React.ReactNode & FC<React.ComponentProps<"svg">>;
  prRanges: Boolean;
  value: DateRange;
  tripDurationAsNights?: number;
  settripDurationAsNights?: React.Dispatch<React.SetStateAction<number>>;
}

const DatesRangeInput: FC<DatesRangeInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  startDateTime,
  setStartDateTime,
  endDateTime,
  setEndDateTime,
  minDate,
  maxDate,
  className,
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapClassName = "divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 lg:border-none",
  numberOfMonths,
  anchorDirection,
  checkinLabel = "",
  checkoutLabel = "",
  checkinDateError = false,
  checkoutDateError = false,
  IconCheckin = CalendarIcon,
  IconCheckout = CalendarIcon,
  prRanges = false,
  value,
  tripDurationAsNights,
  settripDurationAsNights,
}) => {
  const { t } = useTranslation();

  // checkinLabel = t("react.hotel.check-in");
  // checkoutLabel = t("react.hotel.check-out");

  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [duration, setDuration] = useState<DateRange>({
    endDate: null,
    startDate: null,
  });

  const windowSize = useWindowSize();

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  const handleClearData = (field: Fields) => {
    switch (field) {
      case "checkIn": {
        return onChange((date: any) => ({ ...date, startDate: null }));
      }
      case "checkOut": {
        return onChange((date: any) => ({ ...date, endDate: null }));
      }

      default:
        break;
    }
  };
  const [selectedValue, setSelectedValue] = useState<number>(
    tripDurationAsNights ? tripDurationAsNights : 7
  );

  const durationValues = [
    {
      value: 0,
      label: t("general.exact"),
    },
    {
      value: 7,
      label: "1 "+ t("general.week"),
    },
    {
      value: 14,
      label: "2 "+ t("general.week"),
    },
    {
      value: 21,
      label: "3 "+ t("general.week"),
    },
   
    {
      value: 35,
      label: "5 "+ t("general.week"),
    },
    {
      value: 70,
      label: "10 "+ t("general.week"),
    },
  ];

  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };
  const [keepOpenCalender, setkeepOpenCalender] = useState<boolean>(true);

  const renderInputCheckInDate = () => {
    const focused = focusedInput === "startDate";
    const errorClassName = checkinDateError ? "text-red-400" : "";

    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} h-full flex-shrink-0 cursor-pointer items-center space-x-3 ${
          focused ? "rounded-xl dark:bg-neutral-800" : " "
        }`}
        onClick={() => handleDateFocusChange("startDate")}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <IconCheckin className="nc-icon-field" />
        </div>
        <div className="border-right flex-grow">
          <span className="xl:text-base block font-semibold">
            {value?.startDate
              ? value?.startDate.format("DD MMM")
              : checkinLabel}
          </span>
          <span
            className={
              "-mt-1 block text-sm font-medium text-neutral-400 " +
              errorClassName
            }
          >
            {value?.startDate ? checkinLabel : `Add date`}
          </span>
          {value?.startDate && focused && (
            <ClearDataButton onClick={() => handleClearData("checkIn")} />
          )}
        </div>
      </div>
    );
  };

  const renderInputCheckOutDate = () => {
    const focused = focusedInput === "endDate";
    const errorClassName = checkoutDateError ? "text-red-400" : "";

    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} h-full flex-shrink-0 cursor-pointer items-center space-x-3 ${
          focused ? "rounded-xl dark:bg-neutral-800" : " "
        }`}
        onClick={() => handleDateFocusChange("endDate")}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <IconCheckout className="nc-icon-field" />
        </div>
        <div className="border-right flex-grow">
          <span className="xl:text-lg block font-semibold">
            {value?.endDate ? value?.endDate.format("DD MMM") : checkoutLabel}
          </span>
          <span
            className={
              "-mt-1 block text-sm font-medium text-neutral-400 " +
              errorClassName
            }
          >
            {value?.endDate ? checkoutLabel : `Add date`}
          </span>
          {value?.endDate && focused && (
            <ClearDataButton onClick={() => handleClearData("checkOut")} />
          )}
        </div>
      </div>
    );
  };

  const renderInputForPackageTour = () => {
    const focused = focusedInput === "startDate";
    const errorClassName = checkoutDateError ? "text-red-400" : "";

    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} h-full flex-shrink-0 cursor-pointer items-center space-x-3 text-center ${
          focused ? "rounded-xl p-2.5 dark:bg-neutral-800" : " "
        }`}
        onClick={() => {
          handleDateFocusChange("startDate");
          setkeepOpenCalender(true);
        }}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <IconCheckout className="nc-icon-field" />
        </div>
        <div className="">
          <span className="xl:text-base block font-semibold">
            {value?.startDate
              ? value?.startDate.format("DD MMM")
              : checkinLabel}
            {" - "}
            {value?.endDate ? value?.endDate.format("DD MMM") : checkoutLabel}
          </span>

          <span className="xl:text-base block font-semibold">
            {durationValues.map((item, index) =>
              item.value === selectedValue ? (
                <span key={index}>{item.label}</span>
              ) : null
            )}
          </span>

          <span
            className={
              "-mt-1 block text-sm font-medium text-neutral-400 " +
              errorClassName
            }
          >
            {value?.endDate ? checkoutLabel : `Add date`}
          </span>
          {value?.endDate && focused && (
            <ClearDataButton onClick={() => handleClearData("checkOut")} />
          )}
        </div>
      </div>
    );
  };
  // const renderStartTime = () => {
  //   const focused = focusedInput === "startTime";
  //   const errorClassName = checkoutDateError ? "text-red-400" : "";

  //   return (
  //     <HeroSelect
  //       Icon={ClockIcon}
  //       selectOptions={timeSelectOptions}
  //       value={startDateTime}
  //       setValue={setStartDateTime}
  //     />
  //   );
  // };

  const ranges = [
    {
      id: "1_week",
      range: { endDate: moment().add(7, "days"), startDate: moment() },
      label: "1 "+ t("general.week"),
    },
    {
      id: "1_week",
      range: { endDate: moment().add(14, "days"), startDate: moment() },
      label: "2 "+ t("general.week"),
    },
    {
      id: "2_week",
      range: { endDate: moment().add(21, "days"), startDate: moment() },
      label: "3 "+ t("general.week"),
    },
    {
      id: "3_days",
      range: { endDate: moment().add(3, "days"), startDate: moment() },
      label: "3 "+ t("general.days"),
    },
    {
      id: "5_days",
      range: { endDate: moment().add(5, "days"), startDate: moment() },
      label: "5 "+ t("general.days"),
    },
    {
      id: "10_days",
      range: { endDate: moment().add(10, "days"), startDate: moment() },
      label: "10 "+ t("general.days"),
    },
  ];

  const durationInDays = value.endDate?.diff(value.startDate, "days") || 0;
  const handleDurationChange = (value: number) => {
    setSelectedValue(value);
    if (settripDurationAsNights) settripDurationAsNights(value);
    setkeepOpenCalender(false);
    setFocusedInput("endDate");
  };

  return (
    <div className="[ lg:nc-flex-2 ] relative z-10 w-full   ">
      <div className="absolute inset-x-0 bottom-0 mr-40">
        {prRanges ? (
          keepOpenCalender && (
            <DateRangePicker
              keepFocusOnInput
              startDate={value.startDate}
              endDate={value.endDate}
              focusedInput={focusedInput}
              onDatesChange={(date) => {
                onChange(date);
              }}
              onFocusChange={handleDateFocusChange}
              numberOfMonths={
                numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)
              }
              startDateId={"nc-hero-stay-startDateId"}
              endDateId={"nc-hero-stay-endDateId"}
              daySize={windowSize.width > 500 ? 56 : undefined}
              orientation={"horizontal"}
              showClearDates
              noBorder
              hideKeyboardShortcutsPanel
              anchorDirection={anchorDirection}
              firstDayOfWeek={1}
              keepOpenOnDateSelect={true}
              renderCalendarInfo={() => (
                <div className="flex justify-center p-2 ">
                  {prRanges && (
                    <Stack direction="row" spacing={1}>
                      {durationValues.map((option) => (
                        <span className="flex items-center" key={option.value}>
                          <Chip
                            key={option.value}
                            label={option.label}
                            variant={
                              selectedValue === option.value
                                ? "filled"
                                : "outlined"
                            }
                            color={
                              selectedValue === option.value
                                ? "success"
                                : "default"
                            }
                            disabled={option.value > durationInDays}
                            onClick={() => {
                              handleDurationChange(option.value);
                            }}
                          />
                        </span>
                      ))}
                     <Select
                      value={selectedValue}
                      onChange={(event) =>  handleDurationChange(Number(event?.target.value))}
                    >
                      {Array.from({ length: durationInDays }, (_, i) => i + 1).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                    </Stack>
                  )}
                  <span className="flex justify-end mx-4">
            <ButtonPrimary 
            onClick={() => { setkeepOpenCalender(false) }}>
              {t("b2c-app.generals.done")}
            </ButtonPrimary>
          </span>
                </div>
              )}
            />
          )
        ) : (
          <DateRangePicker
            keepFocusOnInput
            startDate={value.startDate}
            endDate={value.endDate}
            focusedInput={focusedInput}
            onDatesChange={(date) => {
              onChange(date);
            }}
            onFocusChange={handleDateFocusChange}
            numberOfMonths={
              numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)
            }
            startDateId={"nc-hero-stay-startDateId"}
            endDateId={"nc-hero-stay-endDateId"}
            daySize={windowSize.width > 500 ? 56 : undefined}
            orientation={"horizontal"}
            showClearDates
            noBorder
            hideKeyboardShortcutsPanel
            anchorDirection={anchorDirection}
            firstDayOfWeek={1}
          />
        )}
      </div>

      <div
        className={`relative flex h-full w-full flex-shrink-0 flex-col lg:flex-row lg:items-center  ${wrapClassName}`}
      >
        {prRanges && renderInputForPackageTour()}

        {!prRanges && renderInputCheckInDate()}

        {startDateTime && setStartDateTime && (
          <HeroSelect
            Icon={ClockIcon}
            selectOptions={timeSelectOptions}
            value={startDateTime}
            setValue={setStartDateTime}
          />
        )}

        {!prRanges && renderInputCheckOutDate()}

        {endDateTime && setEndDateTime && (
          <HeroSelect
            Icon={ClockIcon}
            selectOptions={timeSelectOptions}
            value={endDateTime}
            setValue={setEndDateTime}
          />
        )}
      </div>
    </div>
  );
};

export default DatesRangeInput;
