import React from "react";
import { t } from "i18next";
export function countDaysBtwDepAndArr(flightlist) {
  let departureDate = flightlist[0].departureDate;
  let arrivalDate = flightlist[flightlist.length - 1].arrivalDate;

  departureDate = new Date(
    new Date(departureDate).getFullYear(),
    new Date(departureDate).getMonth(),
    new Date(departureDate).getDate()
  ).getTime();
  arrivalDate = new Date(
    new Date(arrivalDate).getFullYear(),
    new Date(arrivalDate).getMonth(),
    new Date(arrivalDate).getDate()
  ).getTime();

  let diffTime = arrivalDate - departureDate;

  return parseInt(diffTime / (1000 * 3600 * 24));
}

export function countDaysBtwDepAndArrSingleFlight(departureDate, arrivalDate) {
  departureDate = new Date(
    new Date(departureDate).getFullYear(),
    new Date(departureDate).getMonth(),
    new Date(departureDate).getDate()
  ).getTime();
  arrivalDate = new Date(
    new Date(arrivalDate).getFullYear(),
    new Date(arrivalDate).getMonth(),
    new Date(arrivalDate).getDate()
  ).getTime();

  let diffTime = arrivalDate - departureDate;

  return parseInt(diffTime / (1000 * 3600 * 24));
}

export function countDaysTillNextFlight(
  departureTimeFirstFlight,
  departureTimeNextFlight
) {
  let departureDate = new Date(
    new Date(departureTimeFirstFlight).getFullYear(),
    new Date(departureTimeFirstFlight).getMonth(),
    new Date(departureTimeFirstFlight).getDate()
  ).getTime();
  let arrivalDate = new Date(
    new Date(departureTimeNextFlight).getFullYear(),
    new Date(departureTimeNextFlight).getMonth(),
    new Date(departureTimeNextFlight).getDate()
  ).getTime();

  let diffTime = arrivalDate - departureDate;

  return parseInt(diffTime / (1000 * 3600 * 24));
}

export function calcTotalTravelTimeinHoursAndMinutes(flightSegment) {
  let totalTime = 0;

  for (let i = 0; i < flightSegment.length; i++) {
    totalTime += flightSegment[i].durationInSeconds;
    totalTime += flightSegment[i].secondsUntilNextFlight;
  }

  let totalTimeHour = Math.floor(totalTime / (60 * 60));
  let totalTimeMinute = totalTime / 60 - totalTimeHour * 60;

  if (totalTimeMinute < 10) {
    totalTimeMinute = "0" + totalTimeMinute;
  }

  return totalTimeHour + "h " + totalTimeMinute + "min";
}

export function calcTotalTravelTime(flightSegment) {
  let totalTime =
    flightSegment[flightSegment.length - 1].arrivalDate -
    flightSegment[0].departureDate;

  let totalTimeHour = Math.floor(totalTime / (1000 * 60 * 60));
  let totalTimeMinute = new Date(totalTime).getMinutes();

  if (totalTimeMinute < 10) {
    totalTimeMinute = "0" + totalTimeMinute;
  }

  return totalTimeHour + "h " + totalTimeMinute + "min";
}

export function calcTraveltimeSecondsToHourAndMinutes(totalTime) {
  let totalTimeHour = Math.floor(totalTime / (60 * 60));
  let totalTimeMinute = new Date(totalTime).getMinutes();

  if (totalTimeMinute < 10) {
    totalTimeMinute = "0" + totalTimeMinute;
  }

  return totalTimeHour + " h " + totalTimeMinute + " min";
}

export function calcTransferTraveltimeSecondsToHourAndMinutes(totalTime) {
  let totalTimeMinute = Math.floor(totalTime / 60);

  if (totalTimeMinute < 10) {
    totalTimeMinute = "0" + totalTimeMinute;
  }

  return totalTimeMinute;
}

export function PlusDaysElement(props) {
  switch (props.days) {
    case 1:
      return (
        <a className="plus_day">
          <sup>+1</sup>
          <span className="plus_day-tooltiptext">
            {t("react.flight.arrivalnextday")}
          </span>
        </a>
      );
    case 2:
      return (
        <a className="plus_day">
          <sup>+2</sup>
          <span className="plus_day-tooltiptext">
            {t("react.flight.arrivalintwoday")}
          </span>
        </a>
      );
    case 3:
      return (
        <a className="plus_day">
          <sup>+3</sup>
          <span className="plus_day-tooltiptext">
            {t("react.flight.arrivalinthreeday")}
          </span>
        </a>
      );
    default:
      return (
        <a className="plus_day">
          <sup>+{props.days}</sup>
          <span className="plus_day-tooltiptext">
            {t("react.flight.arrivalincountdays").replace("{days}", props.days)}
          </span>
        </a>
      );
  }
}

export function replaceDotWithComma(amount) {
  return amount.toFixed(2).replace(".", ",");
}

export function addLeadingZero(time) {
  let counts = time.split(":");
  let newTime = "";

  if (counts[0].length === 1) {
    newTime += "0" + counts[0] + ":";
  } else {
    newTime += counts[0] + ":";
  }

  if (parseInt(counts[1]) < 10) {
    newTime += "0" + counts[1];
  } else {
    newTime += counts[1];
  }

  return newTime;
}
