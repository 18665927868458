import { HotelPackageTour } from "api/types"
import React from "react"
import { FaSun, FaThumbsUp, FaStar } from "react-icons/fa"
import { IoLocationSharp } from "react-icons/io5"


interface HotelProps {
  hotel: HotelPackageTour
}

const HotelHeader: React.FC<HotelProps> = ({ hotel }) => {
  const largeImage = hotel.images.find((image) => image.imageSize === "LARGE")

  return (
    <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm">
      <div className="grid gap-6 p-6 sm:grid-cols-[250px_1fr]">
        <div className="relative aspect-[4/3] w-full overflow-hidden rounded-md">
          <img
            src={largeImage?.url || "/placeholder.svg"}
            alt={hotel.hotelName}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="space-y-4">
          <div className="space-y-2">
            <h2 className="text-2xl font-bold tracking-tight">{hotel.hotelName}</h2>
            <div className="flex items-center space-x-1">
              {[...Array(parseInt(hotel.stars))].map((_, i) => (
                <FaStar key={i} className="h-5 w-5 text-yellow-400" />
              ))}
            </div>
          </div>
          <div className="flex flex-wrap gap-2">
            <div className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-semibold text-blue-800">
              <FaThumbsUp className="mr-1 h-3 w-3" />
              {Math.round(hotel.review.reviewRecommendationRate)}% Recommended
            </div>
            <div className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-semibold text-green-800">
              <FaSun className="mr-1 h-3 w-3" />
              {hotel.review.reviewPoint}/6 Rating
            </div>
          </div>
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <IoLocationSharp className="h-4 w-4" />
            <span>{hotel.cityName},</span>
            <span className="font-medium">{hotel.countryName}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HotelHeader