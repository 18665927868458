import React, {FC, useEffect, useState} from "react"
import {
    AirportSearchAutocompleteResult, CabinClass,
    IMissingInputError, IMultiCityMissingInput, MultiCitySearchModel,
    SearchPayload,
    TFlightType,
    TSelectedFlights
} from "../../../../api/types";
import {useReduxReducer} from "../../../../redux/store";
import {FlightSearchFormMobile} from "./FlightSearchFormMobile";
import FlightsSearchForm from "./FlightSearchForm";
import {
    clearBaseOffer,
    setIsMultiFlight,
    setIsOneWay,
    setIsRoundtrip,
    setSelectedFlights
} from "../../../../redux/slices/flightSlice";
import {SetTabSelections} from "../../../../redux/slices/tabsSlice";
import FlightQueryParametersService from "../../../../services/FlightQueryParametersService";
import {useDispatch} from "react-redux";
import moment from "moment";
import {TravelersInputValue} from "../TravelersInput";
import useRecentSearches from "../../../../hooks/useRecentSearches";
import {useNavigate} from "react-router-dom";
import {checkObjectsNotNull} from "./MultiCityForm";

interface IFlightSearch {
  queryParameters: SearchPayload | null;
}

export const FlightSearch: FC<IFlightSearch> = ({queryParameters}) => {
    const { isMobile, isApp } = useReduxReducer(state => state.general)
    const { isOneWay, isMultiFlight } = useReduxReducer(state => state.flight)
    const {audit} = useReduxReducer(state => state.config)
    const { appendRecentSearch } = useRecentSearches("recentSearches");
    const navigate = useNavigate();
    const [ flightType, setFlightType ] = useState<TFlightType>("Round-Trip")
    const [ departureAirport, setDepartureAirport] = useState<AirportSearchAutocompleteResult | null>(null);
    const [ arrivalAirport, setArrivalAirport] = useState<AirportSearchAutocompleteResult | null>(null);
    const [ selectedDate, setSelectedDate ] = useState<[moment.Moment|null, moment.Moment|null]>([moment(), moment().add(1,"weeks")])
    const [ cabinClass, setCabinClass] = useState<CabinClass>("ECONOMY");
    const [ multiCitySearchNumber, setMultiCitySearchNumber ] = useState<number>(2)
    const [ travelers, setTravelers ] = useState<TravelersInputValue>({
        adults: 1,
        children: 0,
        infants: 0,
    });
    const [withBaggageOnly, setWithBaggageOnly] = useState<boolean>(false);
    const [showDirectFlights, setshowDirectFlights] = useState<boolean>(false);
    const [excludeGdsFlights, setexcludeGds] = useState<boolean>(true);
    const [ missingInputError, setMissingInputError] = useState<IMissingInputError>({
        departureAirport: false,
        arrivalAirport: false,
        departingDate: false,
        returningDate: false,
        departureFirstTrip: false
    });
    const [multiCitySearch, setMultiCitySearch] = useState<MultiCitySearchModel[]>([
        {
            departureAirport: null,
            arrivalAirport: null,
            departureDate: null,
        },
        {
            departureAirport: null,
            arrivalAirport: null,
            departureDate: null,
        }
    ]);
    const [multiCityMissingInput, setMultiCityMissingInput] = useState<IMultiCityMissingInput[]>(
        Array.from({length: multiCitySearchNumber}).fill({departureAirport: false, arrivalAirport: false, departureDate: false}) as IMultiCityMissingInput[]
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (multiCitySearchNumber !== multiCityMissingInput.length)
            setMultiCityMissingInput(Array.from({length: multiCitySearchNumber}).fill({departureAirport: false, arrivalAirport: false, departureDate: false}) as IMultiCityMissingInput[])
    }, [multiCitySearchNumber])

    useEffect(() => {
        if(audit && audit.marketingType === "B2C"){
            setexcludeGds(false)
        }
        if (queryParameters && queryParameters.searchType === 'Flights') {
            setTravelers({
                adults: queryParameters.adtCount!,
                children: queryParameters.chdCount!,
                infants: queryParameters.infCount!
            })
            setCabinClass(queryParameters.cabinClass!)
            setFlightType(queryParameters.flightSearchType)
            setWithBaggageOnly(queryParameters.withBaggageOnly!)
            setshowDirectFlights(queryParameters.showDirectFlights!)
            setexcludeGds(queryParameters.excludeGdsFlights!)
            if (queryParameters.flightSearchType !== 'Multi-City') {
                setDepartureAirport({id: queryParameters.originDestinationInformation![0].origin!, text: queryParameters.originDestinationInformation![0].originText!})
                setArrivalAirport({id: queryParameters.originDestinationInformation![0].destination!, text: queryParameters.originDestinationInformation![0].destinationText!})
                setSelectedDate([moment(queryParameters.originDestinationInformation![0].date!), queryParameters.originDestinationInformation![1] ? moment(queryParameters.originDestinationInformation![1].date!) : null])
            } else {
                const _multiCitySearch: MultiCitySearchModel[] = []
                queryParameters.originDestinationInformation?.map((destination) => {
                    _multiCitySearch.push({
                        departureAirport: {id: destination.origin!, text: destination.originText!},
                        arrivalAirport: {id: destination.destination!, text: destination.destinationText!},
                        departureDate: moment(destination.date)
                    })
                })
                setMultiCitySearch(_multiCitySearch)
                setMultiCitySearchNumber(queryParameters.originDestinationInformation!.length)

            }
        }
    }, [queryParameters])

    function handleSubmit(e: React.FormEvent) {
        dispatch(setIsOneWay(flightType === 'Oneway'))
        dispatch(setIsRoundtrip(flightType === 'Round-Trip'))
        dispatch(setIsMultiFlight(flightType === 'Multi-City'))

      //  dispatch(clearBaseOffer())
        dispatch(setSelectedFlights(Array.from({length: isMultiFlight ? multiCitySearchNumber : isOneWay ? 1 : 2}).fill(null) as TSelectedFlights[]))

        e.preventDefault();

        if (flightType === "Multi-City") {
            let newInputErrors = [...multiCityMissingInput]
            multiCitySearch.forEach((search, index) => {
                if (!search.departureAirport) {
                    newInputErrors[index].departureAirport = true
                } else {
                    newInputErrors[index].departureAirport = false
                }
                if (!search.arrivalAirport) {
                    newInputErrors[index].arrivalAirport = true
                } else {
                    newInputErrors[index].arrivalAirport = false
                }
                if (!search.departureDate) {
                    newInputErrors[index].departureDate = true
                } else {
                    newInputErrors[index].departureDate = false
                }
            })
            setMultiCityMissingInput(newInputErrors)

            if (!checkObjectsNotNull(multiCitySearch)) {
                return
            }

        } else if (flightType === "Round-Trip") {

            let newErrors = { ...missingInputError }

            newErrors = {
                ...newErrors,
                departureAirport: !departureAirport?.id,
                arrivalAirport: !arrivalAirport?.id,
                departingDate: !selectedDate[0],
                returningDate: !selectedDate[1],
            }

            setMissingInputError(newErrors)

        } else if (flightType === "Oneway") {

            let newErrors = { ...missingInputError }

            newErrors = {
                ...newErrors,
                departureAirport: !departureAirport?.id,
                arrivalAirport: !arrivalAirport?.id,
                departureFirstTrip: !selectedDate[0]
            }

            setMissingInputError(newErrors)
        }

        if (!isMultiFlight && (!departureAirport?.id || !arrivalAirport?.id)) {
            if (flightType === "Round-Trip") {
                if (!selectedDate[0] && !selectedDate[1]) {
                    return;
                }
            } else if (flightType === "Oneway") { 
                if (!selectedDate[0]) {
                    return;
                }
            }
        } else {

            dispatch(SetTabSelections({ currentTab: "Flights" }))

            const urlSearchParams = FlightQueryParametersService.encode(
                flightType,
                travelers,
                cabinClass,
                !isMultiFlight ? departureAirport : null,
                !isMultiFlight ? arrivalAirport : null,
                multiCitySearch,
                {
                    startDate: !isMultiFlight ? selectedDate[0] : null,
                    endDate: !isMultiFlight ? selectedDate[1] : null,
                },
                !isMultiFlight ? selectedDate[0] : null,
                withBaggageOnly,
                showDirectFlights,
                excludeGdsFlights,
            );
            if (urlSearchParams) {

                const recentData = {
                    type: 'flight',
                    searchType: flightType,
                    departure: departureAirport?.id,
                    arrival: arrivalAirport?.id,
                    dateEnd: selectedDate[1],
                    dateStart: selectedDate[0],
                    travelers: travelers,
                    url: urlSearchParams,
                };
                navigate("/flight-search?" + urlSearchParams);
                appendRecentSearch(recentData);
                
            }
        }
    }
    return (
        <>
            {
                isMobile || isApp ?
                    <div className={`bg-white px-3 pt-2 pb-3`}>
                       {/* <SearchTypeTabSection /> // to work with other searchForms in mobile it is rendered in heroSearchForm/index.tsx line 201 */}
                        <FlightSearchFormMobile
                            queryParameters={queryParameters}
                            flightType={flightType}
                            missingInputError={missingInputError}
                            setFlightType={(value) => setFlightType(value)}
                            setMissingInputError={(value) => setMissingInputError(value)}
                            departureAirport={departureAirport}
                            arrivalAirport={arrivalAirport}
                            cabinClass={cabinClass}
                            multiCitySearch={multiCitySearch}
                            travelers={travelers}
                            selectedDate={selectedDate}
                            setArrivalAirport={(value) => setArrivalAirport(value)}
                            setCabinClass={(value) => setCabinClass(value)}
                            setDepartureAirport={setDepartureAirport}
                            setMultiCitySearch={(value) => setMultiCitySearch(value)}
                            setSelectedDate={(value) => setSelectedDate(value)}
                            setTravelers={(value) => setTravelers(value)}
                            multiCitySearchNumber={multiCitySearchNumber}
                            setMultiCitySearchNumber={(value) => setMultiCitySearchNumber(value)}
                            handleSubmit={(e: React.FormEvent) => handleSubmit(e)}
                            withBaggageOnly= {withBaggageOnly}
                            setWithBaggageOnly = {setWithBaggageOnly}
                        />
                    </div>
                    :
                    <FlightsSearchForm
                        queryParameters={queryParameters}
                        flightType={flightType}
                        missingInputError={missingInputError}
                        setFlightType={(value) => setFlightType(value)}
                        setMissingInputError={(value) => setMissingInputError(value)}
                        departureAirport={departureAirport}
                        arrivalAirport={arrivalAirport}
                        cabinClass={cabinClass}
                        multiCitySearch={multiCitySearch}
                        travelers={travelers}
                        selectedDate={selectedDate}
                        setArrivalAirport={(value) => setArrivalAirport(value)}
                        setCabinClass={(value) => setCabinClass(value)}
                        setDepartureAirport={setDepartureAirport}
                        setMultiCitySearch={(value) => setMultiCitySearch(value)}
                        setSelectedDate={(value) => setSelectedDate(value)}
                        setTravelers={(value) => setTravelers(value)}
                        multiCitySearchNumber={multiCitySearchNumber}
                        setMultiCitySearchNumber={setMultiCitySearchNumber}
                        handleSubmit={(e: React.FormEvent) => handleSubmit(e)}
                        withBaggageOnly= {withBaggageOnly}
                        setWithBaggageOnly = {setWithBaggageOnly}
                        setShowDirectFlights = {setshowDirectFlights}
                        showDirectFlights= {showDirectFlights}
                        setExcludeGdsFlights = {setexcludeGds}
                        excludeGdsFlights = {excludeGdsFlights}

                    />
            }
        </>
    )
}
