import React, { useState, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Invoice } from 'pages/Accounting/types/invoice';
import { useGetInvoices } from 'pages/Accounting/hooks/useGetInvoices';
import { InvoiceFilters } from './InvoiceFilters';
import { useGetDetail } from 'pages/Accounting/hooks/useGetInvoiceDetail';
import InvoiceDetailDialog from './InvoiceDetailDialog';
import { CircularProgress } from '@material-ui/core';
import { useDebounce } from 'use-debounce';

const columns: ColumnDef<Invoice>[] = [
  {
    accessorFn: (row) => row.creationDate,
    id: 'creationDate',
    header: 'Creation Date',
    cell: ({ getValue }) => format(new Date(getValue() as number), 'dd MMM yyyy HH:mm'),
  },
  {
    accessorFn: (row) => row.invoiceStatus,
    id: 'invoiceStatus',
    header: 'Status',
  },
  {
    accessorFn: (row) => row.invoiceNumber,
    id: 'invoiceNumber',
    header: 'Invoice Number',
  },
  {
    accessorFn: (row) => row.bookingReference,
    id: 'bookingReference',
    header: 'Booking Ref',
  },
  {
    accessorFn: (row) => row.productType,
    id: 'productType',
    header: 'Product',
  },
  {
    accessorFn: (row) => row.travelRegistrant,
    id: 'travelRegistrant',
    header: 'Travel Registrant',
  },
  {
    accessorFn: (row) => row.outstandingBalance,
    id: 'outstandingBalance',
    header: 'Outstanding',
    cell: ({ getValue }) => `€${(getValue() as number).toFixed(2)}`,
  },
  {
    accessorFn: (row) => row.totalSalesPrice,
    id: 'totalSalesPrice',
    header: 'Total Sales',
    cell: ({ getValue }) => `€${(getValue() as number).toFixed(2)}`,
  },
];

export default function InvoiceTable() {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'creationDate', desc: true },
  ]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    invoiceNumber: '',
    bookingReference: '',
    productType: '',
    outstandingBalance: '',
    totalSalesPrice: '',
  });
  const { loading, errorDetail, invoiceDetail, fetchInvoiceDetail } = useGetDetail();
  const [dialogOpen, setDialogOpen] = useState(false);


  const [queryParams, setQueryParams] = useState({
    sEcho: 1,
    iDisplayStart: 0,
    iDisplayLength: 10,
    iSortingCols: 1,
    iSortCol_0: 0,
    mDataProp_0: 'creationDate',
    sSortDir_0: 'desc',
    ...filters,
  });
  const [debouncedFilters] = useDebounce(filters, 3000);

  const { data, error, isValidating, mutate } = useGetInvoices(queryParams);
  
  const handleViewDetails = (invoiceNumber: string) => {
    fetchInvoiceDetail(invoiceNumber);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  useEffect(() => {
    setQueryParams((prev) => {
      const updated = {
        ...prev,
        iDisplayStart: pagination.pageIndex * pagination.pageSize,
        iDisplayLength: pagination.pageSize,
        iSortCol_0: columns.findIndex((col) => col.id === sorting[0]?.id),
        mDataProp_0: sorting[0]?.id || 'creationDate',
        sSortDir_0: sorting[0]?.desc ? 'desc' : 'asc',
        ...debouncedFilters,
      };
      return JSON.stringify(prev) === JSON.stringify(updated) ? prev : updated;
    });
  }, [pagination, sorting, debouncedFilters]);
  

  const table = useReactTable({
    data: data?.aaData || [],
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    pageCount: Math.ceil((data?.iTotalRecords || 0) / pagination.pageSize),
    manualFiltering: true,
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  };

  if (loading || isValidating) return <div className='h-100 flex justify-center items-center w-full'><CircularProgress className='text-primary-6000'/></div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="w-full bg-white p-6 max-w-6xl rounded-lg shadow-lg">
      <InvoiceFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        onFilterSubmit={handleFilterSubmit}
      />

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}
              onClick={() => handleViewDetails(row.original.invoiceNumber)}
              className=' hover:cursor-pointer hover:bg-primary-50'
          >
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="px-2 py-4 whitespace-nowrap">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div>
          <span className="text-sm text-gray-700">
            Page {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="px-4 py-2 border rounded text-sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 border rounded text-sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>
      </div>
      <InvoiceDetailDialog 
        open={dialogOpen} 
        onClose={handleCloseDialog} 
        invoiceDetail={invoiceDetail} 
      />
    </div>
  );
}

