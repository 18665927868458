import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CarRentalOfferOption, RentalCarOffer} from "../../api/types";

interface IRentalcarState {
    rentalcarData: RentalCarOffer[]
    filteredData: RentalCarOffer[]|null
    selectedOptions: CarRentalOfferOption[]
    providerList: string[]
    selectedSortValue: number
    selectedPriceCategories: string[]
    selectedDepositCategory: string|null
    selectedFuelTypeCategories: string[]|null
    selectedGearTypeCategories: string[]|null
    selectedProviderCategories: string[]|null
    selectedRentalcar: RentalCarOffer|null
}

const initialState: IRentalcarState = {
    rentalcarData: [],
    filteredData: null,
    selectedOptions: [],
    providerList: [],
    selectedSortValue: 0,
    selectedPriceCategories: [],
    selectedDepositCategory: null,
    selectedFuelTypeCategories: null,
    selectedGearTypeCategories: null,
    selectedProviderCategories: null,
    selectedRentalcar: null
}

const rentalcarSlice = createSlice({
    name: 'rentalcar',
    initialState,
    reducers: {
        setRentalcarData: (state, action: PayloadAction<RentalCarOffer[]>) => {
            state.rentalcarData = action.payload
        },
        setFilteredData: (state, action: PayloadAction<RentalCarOffer[]|null>) => {
            state.filteredData = action.payload
        },
        setSelectedOptions: (state, action: PayloadAction<CarRentalOfferOption[]>) => {
            state.selectedOptions = action.payload
        },
        setProviderList: (state, action: PayloadAction<string[]>) => {
            state.providerList = action.payload
        },
        setSelectedSortValue: (state, action: PayloadAction<number>) => {
            state.selectedSortValue = action.payload
        },
        setSelectedPriceCategories: (state, action: PayloadAction<string[]>) => {
            state.selectedPriceCategories = action.payload
        },
        setSelectedDepositCategories: (state, action: PayloadAction<string|null>) => {
            state.selectedDepositCategory = action.payload
        },
        setSelectedFuelTypeCategories: (state, action: PayloadAction<string[]|null>) => {
            state.selectedFuelTypeCategories = action.payload
        },
        setSelectedGearTypeCategories: (state, action: PayloadAction<string[]|null>) => {
            state.selectedGearTypeCategories = action.payload
        },
        setSelectedProviderCategories: (state, action: PayloadAction<string[]|null>) => {
            state.selectedProviderCategories = action.payload
        },
        setSelectedRentalcar: (state, action: PayloadAction<RentalCarOffer|null>) => {
            state.selectedRentalcar = action.payload
        },
        resetFilter: (state) => {
            state.filteredData = null
            state.selectedPriceCategories = []
            state.selectedDepositCategory = null
            state.selectedFuelTypeCategories = null
            state.selectedGearTypeCategories = null
            state.selectedProviderCategories = null
        }
    }
})

export const {
    setRentalcarData,
    setFilteredData,
    setSelectedOptions,
    setProviderList,
    setSelectedSortValue,
    setSelectedPriceCategories,
    setSelectedDepositCategories,
    setSelectedFuelTypeCategories,
    setSelectedGearTypeCategories,
    setSelectedProviderCategories,
    setSelectedRentalcar,
    resetFilter
} = rentalcarSlice.actions

export default rentalcarSlice.reducer
