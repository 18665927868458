import React, { useState } from "react";
import SubmitButton from "../SubmitButton";
import moment from "moment";
import { RentalSearchAutocompleteResult, SearchPayload } from "api/types";
import AutocompleteInput from "../AutocompleteInput";
import { getRentalLocationAutocompleteUrl } from "api/helpers/api-constants";
import Checkbox from "components/ui/Checkbox/Checkbox";
import HeroSelect from "../HeroSelect";
import { ClockIcon, UserIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import DateInput from "../DateInput";
import timeSelectOptions from "utils/timeSelectOptions";


interface CarRentalSearchFormProps{
    
  otherReturnStation: boolean;
  setOtherReturnStation: React.Dispatch<React.SetStateAction<boolean>>;
  age: string | null;
  setAge: React.Dispatch<React.SetStateAction<string | null>>;
  startDate: moment.Moment | null;
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  endDate: moment.Moment | null;
  setEndDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  rentalLocation: RentalSearchAutocompleteResult | null;
  setRentalLocation: React.Dispatch<React.SetStateAction<RentalSearchAutocompleteResult | null>>;
  returnLocation: RentalSearchAutocompleteResult | null;
  setReturnLocation: React.Dispatch<React.SetStateAction<RentalSearchAutocompleteResult | null>>;
  rentalTime: string | null;
  setRentalTime: React.Dispatch<React.SetStateAction<string | null>>;
  returnTime: string | null;
  setReturnTime: React.Dispatch<React.SetStateAction<string | null>>;
  missingInputError: any | null;
  rentalTimeFocus: boolean;
  setRentalTimeFocus: React.Dispatch<React.SetStateAction<boolean>>;
  returnTimeFocus: boolean;
  setReturnTimeFocus: React.Dispatch<React.SetStateAction<boolean>>;
  dateFocus: boolean;
  setDateFocus: React.Dispatch<React.SetStateAction<boolean>>;
  returnDateFocus: boolean;
  setReturnDateFocus: React.Dispatch<React.SetStateAction<boolean>>;
  returnLocationFocus: boolean;
  setReturnLocationFocus: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (value: React.FormEvent) => void;
}

export const CarRentalSearchForm: React.FC<CarRentalSearchFormProps> = ({
    otherReturnStation,
    setOtherReturnStation,
    age,
    setAge,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rentalLocation,
    setRentalLocation,
    returnLocation,
    setReturnLocation,
    rentalTime,
    setRentalTime,
    returnTime,
    setReturnTime,
    missingInputError,
    rentalTimeFocus,
    setRentalTimeFocus,
    returnTimeFocus,
    setReturnTimeFocus,
    dateFocus,
    setDateFocus,
    returnDateFocus,
    setReturnDateFocus,
    returnLocationFocus,
    setReturnLocationFocus,

    handleSubmit,
  }) => {

  const { t } = useTranslation();

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className=" relative mt-0 w-full rounded-br-lg bg-white shadow-sm dark:bg-neutral-900 dark:shadow-2xl"
      >
        <div className=" [ nc-divide-field ] flex w-full flex-col border-b border-neutral-100 px-3 md:flex-row md:items-center">
          <div className="[ nc-divide-field ] relative flex flex-grow flex-col md:flex-row ">
            <div className="col-md-3 ml-md-2 my-auto px-0">
              <HeroSelect
                desc={t("react.hotel.age-years-old")}
                value={age}
                setValue={setAge}
                selectOptions={ageSelectOptions}
                Icon={UserIcon}
                error={missingInputError.age}
                isHorizontal={true}
              />
            </div>
            <Checkbox
              labelClassName="!text-sm !text-neutral-400"
              inputClassName="!w-5 !h-5"
              className="ml-auto py-2"
              name="test"
              label={t("react.searchmask.otherlocation")}
              checked={otherReturnStation}
              onChange={() => setOtherReturnStation((prev) => !prev)}
            />
          </div>
        </div>
        <div className="[ nc-divide-field ] relative flex w-full flex-grow flex-col md:flex-row">
          <AutocompleteInput
            className={"col-md p-0"}
            placeHolder={t("app.rentacar.pickup-location")}
            desc={t("react.filter.from")}
            getUrl={getRentalLocationAutocompleteUrl}
            autocompleteValue={rentalLocation}
            setAutocompleteValue={setRentalLocation}
            localStorageToken="rental-location"
            error={missingInputError.rentalLocation}
            setDateFocus={
              otherReturnStation ? setReturnLocationFocus : setDateFocus
            }
          />
          {otherReturnStation && (
            <AutocompleteInput
              className={"col-md border-left p-0"}
              placeHolder={t("app.rentacar.return")}
              desc={t("flightbooking.to")}
              getUrl={getRentalLocationAutocompleteUrl}
              autocompleteValue={returnLocation}
              setAutocompleteValue={setReturnLocation}
              localStorageToken="rental-return-location"
              error={missingInputError.returnLocation}
              focus={returnLocationFocus}
              setFocus={setReturnLocationFocus}
              setDateFocus={setDateFocus}
            />
          )}
          <DateInput
            className={"col-md-3  border-left p-0"}
            label={t("app.rentacar.rentaldate")}
            date={startDate}
            setDate={setStartDate}
            error={missingInputError.departingDate}
            setTimeFocus={setRentalTimeFocus}
            numberOfMonths={1}
            minDate={moment().startOf('day')}
            maxDate={moment().add(1, "year")}
            dateFocus={dateFocus}
            setDateFocus={setDateFocus}
            time={rentalTime}
            setTime = {setRentalTime}
          />
         
          <DateInput
            className={"col-md-3 border-left  ml-3 p-0"}
            label={t("app.rentacar.returndate")}
            date={endDate}
            setDate={setEndDate}
            error={missingInputError.returningDate}
            dateFocus={returnDateFocus}
            setDateFocus={setReturnDateFocus}
            setTimeFocus={setReturnTimeFocus}
            numberOfMonths={1}
            minDate={startDate ? moment(startDate) : moment()}
            maxDate={
              startDate
                ? moment(startDate).add(10, "year")
                : moment().add(10, "year")
            }
            time={returnTime}
            setTime = {setReturnTime}
          />
       

          <div className="justify center ml-auto flex w-full items-center px-4 py-4 lg:w-min lg:py-0">
            <SubmitButton />
          </div>
        </div>
      </form>
    </div>
  );
};

const ageSelectOptions = new Array(63).fill(null).map((_, i) => ({
  value: String(i + 18),
  label: String(i + 18) + " years old",
}));

export default CarRentalSearchForm;
