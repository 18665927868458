import React, { FC } from "react";
import Logo from "components/ui/Logo/Logo";
import Navigation from "components/ui/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import MenuBar from "components/ui/MenuBar/MenuBar";
import LangDropdown from "components/common/Header/LangDropdown";
import AvatarDropdown from "../../common/Header/AvatarDropdown";
import {Notification} from "../../common/Header/Notification";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useReduxReducer } from "redux/store";
import { ReturnOldPage } from "utils/navigationLinks";
import { Link, NavLink } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const {t} = useTranslation()
  const { isAdmin,isMobile, isApp } = useReduxReducer(state => state.general)

const {header,auth,aboutUs} = useReduxReducer(state => state.config)

  return (
    <div
      className={`nc-MainNav1 relative z-[60]  border-bottom bg-white ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="px-8 relative flex items-center justify-between space-x-4 py-1 xl:space-x-8">
        <div className="flex flex-grow items-center justify-start space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          {
  !(isMobile || isApp) && header.goToOldSiteEnabled && (
<div className="xl:flex xl:justify-between xl:gap-2 md:grid md:justify-start">
{isAdmin && (
        <a
          style={{ backgroundColor: "#156da1", maxHeight: "30px", fontSize: isMobile ? "10px" : "14px" }}
          className="my-2 rounded text-white text-xs p-1 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 xl:px-3 sm:px-2 md:px-2 flex items-center justify-center"
          href={ReturnOldPage}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("react.general.return-to-old-search-form")}
        </a>
      )}
      <a
        style={{ backgroundColor: "#156da1", maxHeight: "30px", fontSize: isMobile ? "10px" : "14px" }}
        className="my-2 p-1 text-white rounded text-xs hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 xl:px-3 sm:px-2 md:px-2 flex items-center justify-center"
        href={"#/getapp"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <DownloadIcon className="mr-1" />
        {t("react.getapp")}
      </a>
    </div>
  )
}

          <Navigation />
          {aboutUs && 
             <div className="flex justify-end"><NavLink
              end
              rel="noopener noreferrer"
              className={ "inline-flex items-center rounded text-md py-2 px-4 font-semibold text-gray-900 bg-neutral-100 hover:text-neutral-700 "}
              to={{
                pathname: "/aboutus",
              }}
            >
              {t("homepage.about-us")}
            </NavLink></div>
         }
        </div>
        <div className="flex flex-shrink-0 items-center justify-end space-x-1 text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center space-x-1 xl:flex">
           {header.searchEnabled && <SearchDropdown />}
          {header.notificationListEnabled &&   <Notification />}
            {/* <ChartDropdown /> */}
            <LangDropdown />
            <div className="px-25" />
           {auth.authenticationEnabled && <AvatarDropdown />}
          </div>
          <div className="flex items-center xl:hidden">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
