import React, {FC} from 'react'
import {formatNumber} from "../../utils/formatNumber";
import {IDateRange} from "../../api/types";
import {useTranslation} from "react-i18next";

interface ICompareTable {
    label: string
    isFlight: boolean
    totalFlightPrice: number|null
    totalFlightPriceLastYear: number|null
    dateRange: IDateRange
    dateRangeLastYear: IDateRange
    sortedDataSets: [string, number][]
    sortedDataSetsLastYear: [string, number][]
}

export const CompareTable: FC<ICompareTable> = ({
                                                  label,
                                                  isFlight,
                                                  totalFlightPrice,
                                                  totalFlightPriceLastYear,
                                                  dateRange,
                                                  dateRangeLastYear,
                                                  sortedDataSets,
                                                  sortedDataSetsLastYear
}) => {
    const { t } = useTranslation()

    return (
        <div className={`card px-4 py-3 mb-3`}>
            <div className={`pt-2`}>
                <div className={`grid grid-cols-12`}>
                    <div className={`col-span-2`}>
                        <h1 className="mb-3 text-lg font-semibold">{label}</h1>
                    </div>
                    <div className={`col-span-5`}>
                        <h1 className="mb-3 text-lg font-semibold text-right pr-3">
                            {`${dateRangeLastYear.startDate?.format('DD.MM.YYYY')} - ${dateRangeLastYear.endDate?.format('DD.MM.YYYY')}`}
                        </h1>
                    </div>
                    <div className={`col-span-5`}>
                        <h1 className="mb-3 text-lg font-semibold text-right pr-3">
                            {`${dateRange.startDate?.format('DD.MM.YYYY')} - ${dateRange.endDate?.format('DD.MM.YYYY')}`}
                        </h1>
                    </div>
                </div>
                {
                    isFlight && (
                        <div className={`grid grid-cols-12`}>
                            <div className={`col-span-2`}>
                                <h1 className="mb-3 text-lg font-semibold">{t('rentalcar.total')}</h1>
                            </div>
                            <div className={`col-span-5 pr-3 text-right`}>
                                <h1 className="mb-3 text-lg font-semibold">{formatNumber(totalFlightPriceLastYear!)} EUR</h1>
                            </div>
                            <div className={`col-span-5 pr-3 font-semibold text-right ${(totalFlightPrice! < totalFlightPriceLastYear!) ? 'text-red-500' : 'text-green-500'}`}>
                                <h1 className="mb-3 text-lg font-semibold">{formatNumber(totalFlightPrice!)} EUR</h1>
                            </div>
                        </div>
                    )
                }
                {
                    sortedDataSets.map((_data, index) => {
                        const airline: string = _data[0]
                        const lastYearData: [string, number]|undefined = sortedDataSetsLastYear.find(_dataLastYear => _dataLastYear[0] === _data[0])
                        const currentValue: string = formatNumber(_data[1])

                        return (
                            <div key={`airlinetable-${index}`} className={`grid grid-cols-12 border-t ${index%2 === 0 && 'bg-gray-100'}`}>
                                <div className={`col-span-2 py-1 pl-2`}>{airline}</div>
                                <div className={`col-span-5 py-1 pr-3 text-right`}>{lastYearData ? formatNumber(lastYearData[1]) + ' EUR' : 'n.V.'}</div>
                                <div className={`col-span-5 py-1 pr-3 font-semibold text-right ${(_data[1] < (lastYearData ? lastYearData[1] : 0)) ? 'text-red-500' : 'text-green-500'}`}>{currentValue} EUR</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
