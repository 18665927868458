import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { disableButton } from "utils/disableButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";



interface Flight {
    id: number;
    flightNumber: string;
    airline: string;
    operatingAirline: string | null;
    airlineLogo: string;
    classOfService: string;
    selectedSeats: string | null;
    baggageAllowance: string;
    departureDate: number;
    arrivalDate: number;
    departurePort: string;
    arrivalPort: string;
    stopoverPort: string | null;
    operatingCarrierInformation: string | null;
    aircraft: string | null;
    cabinClass: string;
    fareBasis: string | null;
    brandName: string;
    availableSeats: number;
    tripIndex: number;
    availabilityBreak: boolean;
    depDate: number;
    arrDate: number;
    departureTime: string;
    departurePortName: string;
    arrivalPortName: string;
    arnk: boolean;
    durationInSeconds: number;
    flightNumberWithoutAirlineCode: string;
    openFlight: boolean;
    timeString: string;
    departureTimeStr: string;
    arrivalTimeStr: string;
    departureDateStr: string;
    arrivalDateStr: string;
    arrivalTime: string;
  }
  
interface RefreshFlightsDialogProps {
  filekey: string;
  bookingKey: string;
}

const UpdateFlightDialog: React.FC<RefreshFlightsDialogProps> = ({filekey, bookingKey }) => {
  const [flights, setFlights] = useState<Flight[]>([]);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [open, setOpen] = useState<boolean>(false);
  const {t} = useTranslation()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open) {
      fetch(`/intern/getPnrJson?bookingKey=${bookingKey}&filekey=${filekey}`)
        .then((res) => res.json())
        .then((pnr) => {
          const flightList = pnr.flightList;
          setFlights(flightList);

          // Initialize formData with fetched flight data
          const initialFormData: { [key: string]: string } = {};
          flightList.forEach((flight :Flight) => {
            initialFormData[`${flight.id}-flightNumber`] = flight.flightNumber;
            initialFormData[`${flight.id}-departurePort`] = flight.departurePort;
            initialFormData[`${flight.id}-arrivalPort`] = flight.arrivalPort;
            initialFormData[`${flight.id}-stopoverPort`] = flight.stopoverPort || "";
            initialFormData[`${flight.id}-classOfService`] = flight.classOfService;
            initialFormData[`${flight.id}-departureDate`] = `${flight.departureDateStr} ${flight.departureTimeStr}`;
            initialFormData[`${flight.id}-arrivalDate`] = `${flight.arrivalDateStr} ${flight.arrivalTimeStr}`;
            initialFormData[`${flight.id}-baggageAllowance`] = flight.baggageAllowance;
            initialFormData[`${flight.id}-selectedSeats`] = flight.selectedSeats || "";
            initialFormData[`${flight.id}-airline`] = flight.airline;
            initialFormData[`${flight.id}-openFlight`] = flight.openFlight ? "true" : "false";
          });
          setFormData(initialFormData);
        })
        .catch((error) => console.error("Error fetching flight data:", error));
    }
  }, [open, bookingKey, filekey]);
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement  | any >, 
    flightId: number
  ) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [`${flightId}-${name}`]: value.toUpperCase(),
    }));
  };
  
  const handleSubmit = () => {
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      form.append(key, value);
    });

    fetch(`/intern/updateFlights?bookingKey=${bookingKey}&filekey=${filekey}`, {
      method: "POST",
      body: form,
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "İşlem başarıyla gerçekleşti",
            text: "Lütfen fiyatların doğruluğunu kontrol ediniz.",
            confirmButtonText: "OK",
          });
          handleClose();
          setTimeout(() => window.location.reload(), 1500);
        } else {
            handleClose();
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "Uçuşları güncellerken bir sorun oluştu.",
            confirmButtonText: "OK",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Uçuşları güncellerken bir sorun oluştu.",
          confirmButtonText: "OK",
        });
      });
  };

  const handleRemoveFlight = (flightId: number) => {
    fetch(`/intern/deleteFlightManually?bookingKey=${bookingKey}&filekey=${filekey}&flightId=${flightId}`, {
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "İşlem başarıyla gerçekleşti",
            text: "Uçuş silindi, lütfen doğruluğunu kontrol ediniz.",
            confirmButtonText: "OK",
          });
          handleClose();
          setTimeout(() => window.location.reload(), 1500);
        } else {
            handleClose();
            Swal.fire({
                icon: "error",
                title: "Hata",
                text: "Uçuş silinirken bir sorun oluştu.",
                confirmButtonText: "OK",
              });
          throw new Error("Failed to remove flight");
        }
      })
      .catch(() => {
        handleClose();

        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Uçuş silinirken bir sorun oluştu.",
          confirmButtonText: "OK",
        });
      });
  };

  return (<>
   <button
                  id="updateFlights"
                  type="button"
                  onClick={() => {
                    const button = document.getElementById("updateFlights") as HTMLButtonElement | null;
                    if (button) {
                      disableButton([button]);
                   }
                   handleOpen()}}
                  className="text-sm border border-gray-100 bg-white px-4 py-2 font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 "
                >
                  <i className="fa fa-edit px-1"></i> Uçuş Güncelle
                </button>
   <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Uçuşları Güncelle</DialogTitle>
      <DialogContent>
       {flights && <form id="updatehFlightsForm">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Uçuş No</th>
                    <th>Kalkış</th>
                    <th>Varış</th>
                    <th>Stopover</th>
                    <th>Sınıf</th>
                    <th>Kalkış Tarih / Saat</th>
                    <th>Varış Tarih / Saat</th>
                    <th>Bagaj</th>
                    <th>Koltuk</th>
                    <th>Havayolu</th>
                    <th>Açık Tarih</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {flights.map((flight) => (
  <tr key={flight.id}>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-flightNumber`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="flightNumber"
        sx={{ width: '100px' }}

      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-departurePort`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="departurePort"
        sx={{ width: '80px' }}
      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-arrivalPort`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="arrivalPort"
        sx={{ width: '80px' }}
      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-stopoverPort`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="stopoverPort"
        sx={{ width: '80px' }}

      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-classOfService`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="classOfService"
        sx={{ width: '60px' }}
      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-departureDate`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="departureDate"
        sx={{ width: '160px' }} 
      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-arrivalDate`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="arrivalDate"
        sx={{ width: '160px' }} 
      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-baggageAllowance`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="baggageAllowance"
        sx={{ width: '70px' }}

      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-selectedSeats`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="selectedSeats"
        sx={{ width: '70px' }}

      />
    </td>
    <td>
      <TextField
        size="small"
        variant="outlined"
        value={formData[`${flight.id}-airline`]}
        onChange={(e) => handleChange(e, flight.id)}
        name="airline"
      />
    </td>
    <td>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData[`${flight.id}-openFlight`] === "true" ? true : false}
            onChange={(e) => handleChange(e, flight.id)}
            name="openFlight"
          />
        }
        label=" "
      />
    </td>
    <td>
    <IconButton onClick={() => handleRemoveFlight(flight.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
    </td>
  </tr>
))}

                </tbody>
              </table>
            </Grid>
          </Grid>
        </form>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>{t("b2c-app.generals.cancel")}</Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>{t("agency-settings.save")}</Button>
      </DialogActions>
    </Dialog>
  </>
   
  );
};

export default UpdateFlightDialog;
