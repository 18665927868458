import { PaymentInfo } from "api/types";
import moment from "moment";
import React, { FC, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface SectionPaymentProps {
    data: { payments: PaymentInfo[] } | null;}

export const SectionPayment: FC<SectionPaymentProps> = ({ data }) => {
    
    const [open, setOpen] = React.useState(false);
    const [selectedPayment, setSelectedPayment] = useState<PaymentInfo | null>(null);

    const handleClickOpen = (payment:PaymentInfo) => {
        setSelectedPayment(payment);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    if (!data?.payments || data?.payments.length === 0 ) return null;
        return (
        <div className="relative overflow-hidden rounded-lg border mt-2 border-neutral-100 bg-white transition-shadow dark:border-neutral-800 dark:bg-neutral-900 }">
            <div className="flex justify-center items-center text-gray-900 text-base font-bold space-x-4 p-4 border-b-2 border-neutral-100"> <span>Payment Transactions</span></div>
     <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{"Transactions"}</DialogTitle>
        <DialogContent> 
             <table className="w-full table-auto border-collapse text-sm">
            <thead className="bg-neutral-100">
            <tr>
                <th scope="col" className="px-16  py-3">
                   Date
                </th>
                <th scope="col" className="px-2 py-3">
                   Type 
                </th>
                <th scope="col" className="px-2 py-3">
                   Provider reference
                </th>
                <th scope="col" className="px-2 py-3">
                    Error code
                </th> 
                <th scope="col" className="px-2 py-3">
                Error message
                </th>
                <th scope="col" className="px-2 py-3">
                Auth Code
                </th>
            </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
            {
               selectedPayment?.transactions.map((transaction, index) => {
                return (
                  <tr key={index} className="text-sm  hover:bg-slate-100 cursor-pointer">
                    <td className="border-b border-slate-100 p-1 pl-1 font-bold text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {transaction.creationDate && moment(transaction.creationDate).format("DD-MM-YYYY HH:mm")}
                    </td>
                    <td className="border-b border-slate-100 px-1 pl-1 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {transaction.type}
                    </td>
                    <td className="border-b border-slate-100 p-1 pl-1 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {transaction.providerReference}
                    </td>
                    <td className="border-b border-slate-100 p-1 pl-1 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {transaction.errorCode}
                    </td>
                    <td className="border-b text-xs border-slate-100 p-1 pl-1 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {transaction.errorMessage}
                    </td>
                    <td className="border-b border-slate-100 text-xs p-1 pl-1 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                      {transaction.authCode}
                    </td>
                  </tr>
                );
              })
              
            } 
            </tbody>
        </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
 
        </DialogActions>
      </Dialog>
        <table className="w-full table-auto border-collapse text-sm">
            <thead className="bg-neutral-50">
            <tr>
                <th scope="col" className="px-6 py-3 ">
                   Date
                </th>
                <th scope="col" className="px-6 py-3">
                   Provider 
                </th>
                <th scope="col" className="px-6 py-3">
                   Status
                </th>
                <th scope="col" className="px-6 py-3">
                    Type
                </th> 
                <th scope="col" className="px-6 py-3">
                Amount
                </th>
            </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
            {
                data?.payments.map(payment => {
                    return (
                        <tr className="hover:bg-slate-100 cursor-pointer" key={payment.guid}onClick={() => handleClickOpen(payment)} >
                            <td className="border-b border-slate-100 p-4 pl-8 font-bold text-slate-500 dark:border-slate-700 dark:text-slate-400">
                            {payment.creationDate &&  moment(payment.creationDate).format("DD-MM-YYYY - HH:mm")}
                            </td>
                            <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                                {payment.provider}
                            </td>
                            <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                                {payment.status}
                            </td>
                            <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                                {payment.type}
                            </td>
                            <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-400">
                                { payment.amount.toFixed(2)} €
                            </td>
                            
                        </tr>
                    )
                }) 
            } 
            </tbody>
        </table>
    </div>    );
};
