import React, {useEffect} from "react";
import ButtonClose from "components/ui/ButtonClose/ButtonClose";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import {NAVIGATION_APP, NAVIGATION_MOBILE} from "data/navigation";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {useReduxReducer} from "../../../redux/store";
import {UserInformation} from "../../common/Header/UserInformation";
import LangDropdown from "../../common/Header/LangDropdown";

import {useTranslation} from "react-i18next";
import {Notification} from "../../common/Header/Notification";
import {GuestMessage} from "../Message/GuestMessage";
import {ChatboxPosition, Crisp} from "crisp-sdk-web";
import {Button} from "primereact/button";
import {CustomButton} from "../../common/CustomButton";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_MOBILE,
  onClickClose,
}) => {
  const { isApp, isGuest } = useReduxReducer(state => state.general)
  const { t } = useTranslation()
  const {header,auth} = useReduxReducer(state => state.config)

  useEffect(() => {
    if (isApp) {
      const crispChatbox = document.getElementsByClassName('crisp-client');

      if (crispChatbox.length > 0)
        crispChatbox[0].className = 'crisp-client';

      Crisp.setPosition('left' as ChatboxPosition)
        Crisp.load()
      // Crisp.chat.open()
      // Crisp.chat.show()

      const removeCrispChatbox = () => {
        const crispChatbox = document.getElementsByClassName('crisp-client');
        if (crispChatbox.length > 0) {
          crispChatbox[0].className = 'crisp-client hidden';
        }
      };

      return () => {
        removeCrispChatbox();
      };
    }
  }, [])

  // Default navigation
  let navigation = NAVIGATION_MOBILE;

  // Check if bookingsLinkEnabled is false
  if (!header.bookingsLinkEnabled) {
    navigation = navigation.filter(item => item.name !== "Buchungen");
  }

  // Check if moreLinksEnabled is false
  if (!header.moreLinksEnabled) {
    navigation = navigation.filter(item => item.name !== "Weitere Links");
  }
  // Check if goToOldSiteEnabled is false
  if (!header.goToOldSiteEnabled) {
    navigation = navigation.filter(item => item.name !== "Zur alten Suchmaske");
  }
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                "mt-[2px] flex rounded-lg px-4 py-2.5 text-sm font-medium text-neutral-900 hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-800" +
                (isActive ? " text-secondary" : "")
              }
            >
              <span
                className={!i.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {t(i.i18nextKey!)}
              </span>
              {i.children && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex flex-grow justify-end"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={item.id}
        as="li"
        className="text-neutral-900 dark:text-white"
      >
        <NavLink
          end
          className={({ isActive }) =>
            "flex w-full items-center rounded-lg py-2.5 px-4 text-sm font-medium uppercase tracking-wide hover:bg-neutral-100 dark:hover:bg-neutral-800" +
            (isActive ? " text-secondary" : "")
          }
          to={{
            pathname: `${item.href}${isGuest ? '?guest=1' : ''}` || undefined,
          }}
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={item.isNewUrl ? () => window.location.href = item.href : onClickClose}
          >
            {t(item.i18nextKey!)}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex flex-grow justify-end"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="h-screen w-full max-w-sm transform divide-y-2 divide-neutral-100 overflow-y-auto bg-white py-2 shadow-lg ring-1 transition dark:divide-neutral-800 dark:bg-neutral-900 dark:ring-neutral-700">
      <div className="py-6 px-5">
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <div>
        <div className="flex flex-shrink-0 items-center justify-end space-x-1 pr-4 text-neutral-700 dark:text-neutral-100">
          <LangDropdown />
         {(!isGuest && header.notificationListEnabled) && <Notification />}
        </div>
        {
          isGuest ?
              <GuestMessage msg={t('general.guest-login')} /> :
              auth.authenticationEnabled && <UserInformation />
        }
      </div>
      <ul className="flex flex-col space-y-1 py-6 px-2">
        {(isApp ? NAVIGATION_APP : navigation)
            .filter(navi => (isApp && isGuest) ? navi.name !== 'Bookings' : true)
            .map(_renderItem)
        }
      </ul>
    </div>
  );
};

export default NavMobile;
