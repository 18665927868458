import { HomeIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ClearDataButton from "./ClearDataButton";

export interface NationalityInputProps {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  className?: string;
  error?: boolean;
}

const NationalityInput: React.FC<NationalityInputProps> = ({
  value,
  setValue,
  className = "",
  error = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const {t} = useTranslation()
  const [showPopover, setShowPopover] = useState(false);

  const nationalitiesSelectInputs = useMemo(() => {
    if (!query) {
      return nationalities;
    }
    return nationalities.filter((nationality) =>
      nationality.name.toLowerCase().includes(query.toLowerCase())
    );
  }, [query]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }

    if (!showPopover) {
      if (!value) {
        setQuery("");
      } else {
        setQuery(nationalities.find((x) => x.code === value)?.name || "Select");
      }
    }
  }, [showPopover, value]);

  const handleSelectNationality = (item: string) => {
    setValue(item);
    setShowPopover(false);
  };

  const renderSearchValue = () => {
    return (
      <>
        {nationalitiesSelectInputs.length > 0 ? (
          nationalitiesSelectInputs.map((nationality) => (
            <span
              onClick={() => handleSelectNationality(nationality.code)}
              key={nationality.code}
              className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
            >
              <span className="block text-neutral-400">
                <HomeIcon className="h-6 w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {nationality.name}
              </span>
            </span>
          ))
        ) : (
          <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
{t("app.no-search-results")}          </span>
        )}
      </>
    );
  };

  const errorClassName = error ? "text-red-400" : "";

  return (
    <div className="relative flex" ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none  ${className} `}
      > <span className="border-l h-10"></span>
          <span className="text-neutral-300 dark:text-neutral-400">
          <HomeIcon className="block h-5 w-5" />
        </span>
        <div className=" pl-2 flex-grow">
       
          <input
            className={`xl:text-base block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200`}
            placeholder={t("hotel.nationality")}
            id="nationality-input"
            value={query}
            autoFocus={showPopover}
            onChange={(e) => {
              setValue("");
              setQuery(e.currentTarget.value);
            }}
            ref={inputRef}
          />
        
          <label
            htmlFor="nationality-input"
            className={
              "-mt-1 block text-sm font-medium text-neutral-400  " +
              errorClassName
            }
          >
            {t("hotel.residency")}
          </label>

          {query && showPopover && (
            <ClearDataButton
              onClick={() => {
                setQuery("");
                setValue("");
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 top-full z-40 max-h-96 w-full min-w-[220px] overflow-y-auto bg-white py-3 dark:bg-neutral-800 sm:min-w-[400px] sm:py-6">
          {renderSearchValue()}
        </div>
      )}
    </div>
  );
};

export default NationalityInput;

export const nationalities = [
  { code: "AF", name: "Afghan" },
  { code: "AX", name: "Åland Island" },
  { code: "AL", name: "Albanian" },
  { code: "DZ", name: "Algerian" },
  { code: "AS", name: "American Samoan" },
  { code: "AD", name: "Andorran" },
  { code: "AO", name: "Angolan" },
  { code: "AI", name: "Anguillan" },
  { code: "AQ", name: "Antarctic" },
  { code: "AG", name: "Antiguan or Barbudan" },
  { code: "AR", name: "Argentine" },
  { code: "AM", name: "Armenian" },
  { code: "AW", name: "Aruban" },
  { code: "AU", name: "Australian" },
  { code: "AT", name: "Austrian" },
  { code: "AZ", name: "Azerbaijani, Azeri" },
  { code: "BS", name: "Bahamian" },
  { code: "BH", name: "Bahraini" },
  { code: "BD", name: "Bangladeshi" },
  { code: "BB", name: "Barbadian" },
  { code: "BY", name: "Belarusian" },
  { code: "BE", name: "Belgian" },
  { code: "BZ", name: "Belizean" },
  { code: "BJ", name: "Beninese, Beninois" },
  { code: "BM", name: "Bermudian, Bermudan" },
  { code: "BT", name: "Bhutanese" },
  { code: "BO", name: "Bolivian" },
  { code: "BQ", name: "Bonaire" },
  { code: "BA", name: "Bosnian or Herzegovinian" },
  { code: "BW", name: "Motswana, Botswanan" },
  { code: "BV", name: "Bouvet Island" },
  { code: "BR", name: "Brazilian" },
  { code: "IO", name: "BIOT" },
  { code: "BN", name: "Bruneian" },
  { code: "BG", name: "Bulgarian" },
  { code: "BF", name: "Burkinabé" },
  { code: "BI", name: "Burundian" },
  { code: "CV", name: "Cabo Verdean" },
  { code: "KH", name: "Cambodian" },
  { code: "CM", name: "Cameroonian" },
  { code: "CA", name: "Canadian" },
  { code: "KY", name: "Caymanian" },
  { code: "CF", name: "Central African" },
  { code: "TD", name: "Chadian" },
  { code: "CL", name: "Chilean" },
  { code: "CN", name: "Chinese" },
  { code: "CX", name: "Christmas Island" },
  { code: "CC", name: "Cocos Island" },
  { code: "CO", name: "Colombian" },
  { code: "KM", name: "Comoran, Comorian" },
  { code: "CG", name: "Congolese" },
  { code: "CD", name: "Congolese" },
  { code: "CK", name: "Cook Island" },
  { code: "CR", name: "Costa Rican" },
  { code: "CI", name: "Ivorian" },
  { code: "HR", name: "Croatian" },
  { code: "CU", name: "Cuban" },
  { code: "CW", name: "Curaçaoan" },
  { code: "CY", name: "Cypriot" },
  { code: "CZ", name: "Czech" },
  { code: "DK", name: "Danish" },
  { code: "DJ", name: "Djiboutian" },
  { code: "DM", name: "Dominican" },
  { code: "DO", name: "Dominican" },
  { code: "EC", name: "Ecuadorian" },
  { code: "EG", name: "Egyptian" },
  { code: "SV", name: "Salvadoran" },
  { code: "GQ", name: "Equatorial Guinean, Equatoguinean" },
  { code: "ER", name: "Eritrean" },
  { code: "EE", name: "Estonian" },
  { code: "ET", name: "Ethiopian" },
  { code: "FK", name: "Falkland Island" },
  { code: "FO", name: "Faroese" },
  { code: "FJ", name: "Fijian" },
  { code: "FI", name: "Finnish" },
  { code: "FR", name: "French" },
  { code: "GF", name: "French Guianese" },
  { code: "PF", name: "French Polynesian" },
  { code: "TF", name: "French Southern Territories" },
  { code: "GA", name: "Gabonese" },
  { code: "GM", name: "Gambian" },
  { code: "GE", name: "Georgian" },
  { code: "DE", name: "German" },
  { code: "GH", name: "Ghanaian" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Greek, Hellenic" },
  { code: "GL", name: "Greenlandic" },
  { code: "GD", name: "Grenadian" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guamanian, Guambat" },
  { code: "GT", name: "Guatemalan" },
  { code: "GG", name: "Channel Island" },
  { code: "GN", name: "Guinean" },
  { code: "GW", name: "Bissau-Guinean" },
  { code: "GY", name: "Guyanese" },
  { code: "HT", name: "Haitian" },
  { code: "HM", name: "Heard Island or McDonald Islands" },
  { code: "VA", name: "Vatican" },
  { code: "HN", name: "Honduran" },
  { code: "HK", name: "Hong Kong, Hong Kongese" },
  { code: "HU", name: "Hungarian, Magyar" },
  { code: "IS", name: "Icelandic" },
  { code: "IN", name: "Indian" },
  { code: "ID", name: "Indonesian" },
  { code: "IR", name: "Iranian, Persian" },
  { code: "IQ", name: "Iraqi" },
  { code: "IE", name: "Irish" },
  { code: "IM", name: "Manx" },
  { code: "IL", name: "Israeli" },
  { code: "IT", name: "Italian" },
  { code: "JM", name: "Jamaican" },
  { code: "JP", name: "Japanese" },
  { code: "JE", name: "Channel Island" },
  { code: "JO", name: "Jordanian" },
  { code: "KZ", name: "Kazakhstani, Kazakh" },
  { code: "KE", name: "Kenyan" },
  { code: "KI", name: "I-Kiribati" },
  { code: "KP", name: "North Korean" },
  { code: "KR", name: "South Korean" },
  { code: "KW", name: "Kuwaiti" },
  { code: "KG", name: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz" },
  { code: "LA", name: "Lao, Laotian" },
  { code: "LV", name: "Latvian" },
  { code: "LB", name: "Lebanese" },
  { code: "LS", name: "Basotho" },
  { code: "LR", name: "Liberian" },
  { code: "LY", name: "Libyan" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuanian" },
  { code: "LU", name: "Luxembourg, Luxembourgish" },
  { code: "MO", name: "Macanese, Chinese" },
  { code: "MK", name: "Macedonian" },
  { code: "MG", name: "Malagasy" },
  { code: "MW", name: "Malawian" },
  { code: "MY", name: "Malaysian" },
  { code: "MV", name: "Maldivian" },
  { code: "ML", name: "Malian, Malinese" },
  { code: "MT", name: "Maltese" },
  { code: "MH", name: "Marshallese" },
  { code: "MQ", name: "Martiniquais, Martinican" },
  { code: "MR", name: "Mauritanian" },
  { code: "MU", name: "Mauritian" },
  { code: "YT", name: "Mahoran" },
  { code: "MX", name: "Mexican" },
  { code: "FM", name: "Micronesian" },
  { code: "MD", name: "Moldovan" },
  { code: "MC", name: "Monégasque, Monacan" },
  { code: "MN", name: "Mongolian" },
  { code: "ME", name: "Montenegrin" },
  { code: "MS", name: "Montserratian" },
  { code: "MA", name: "Moroccan" },
  { code: "MZ", name: "Mozambican" },
  { code: "MM", name: "Burmese" },
  { code: "NA", name: "Namibian" },
  { code: "NR", name: "Nauruan" },
  { code: "NP", name: "Nepali, Nepalese" },
  { code: "NL", name: "Dutch, Netherlandic" },
  { code: "NC", name: "New Caledonian" },
  { code: "NZ", name: "New Zealand, NZ" },
  { code: "NI", name: "Nicaraguan" },
  { code: "NE", name: "Nigerien" },
  { code: "NG", name: "Nigerian" },
  { code: "NU", name: "Niuean" },
  { code: "NF", name: "Norfolk Island" },
  { code: "MP", name: "Northern Marianan" },
  { code: "NO", name: "Norwegian" },
  { code: "OM", name: "Omani" },
  { code: "PK", name: "Pakistani" },
  { code: "PW", name: "Palauan" },
  { code: "PS", name: "Palestinian" },
  { code: "PA", name: "Panamanian" },
  { code: "PG", name: "Papua New Guinean, Papuan" },
  { code: "PY", name: "Paraguayan" },
  { code: "PE", name: "Peruvian" },
  { code: "PH", name: "Philippine, Filipino" },
  { code: "PN", name: "Pitcairn Island" },
  { code: "PL", name: "Polish" },
  { code: "PT", name: "Portuguese" },
  { code: "PR", name: "Puerto Rican" },
  { code: "QA", name: "Qatari" },
  { code: "RE", name: "Réunionese, Réunionnais" },
  { code: "RO", name: "Romanian" },
  { code: "RU", name: "Russian" },
  { code: "RW", name: "Rwandan" },
  { code: "BL", name: "Barthélemois" },
  { code: "SH", name: "Saint Helenian" },
  { code: "KN", name: "Kittitian or Nevisian" },
  { code: "LC", name: "Saint Lucian" },
  { code: "MF", name: "Saint-Martinoise" },
  { code: "PM", name: "Saint-Pierrais or Miquelonnais" },
  { code: "VC", name: "Saint Vincentian, Vincentian" },
  { code: "WS", name: "Samoan" },
  { code: "SM", name: "Sammarinese" },
  { code: "ST", name: "São Toméan" },
  { code: "SA", name: "Saudi, Saudi Arabian" },
  { code: "SN", name: "Senegalese" },
  { code: "RS", name: "Serbian" },
  { code: "SC", name: "Seychellois" },
  { code: "SL", name: "Sierra Leonean" },
  { code: "SG", name: "Singaporean" },
  { code: "SX", name: "Sint Maarten" },
  { code: "SK", name: "Slovak" },
  { code: "SI", name: "Slovenian, Slovene" },
  { code: "SB", name: "Solomon Island" },
  { code: "SO", name: "Somali, Somalian" },
  { code: "ZA", name: "South African" },
  { code: "GS", name: "South Georgia or South Sandwich Islands" },
  { code: "SS", name: "South Sudanese" },
  { code: "ES", name: "Spanish" },
  { code: "LK", name: "Sri Lankan" },
  { code: "SD", name: "Sudanese" },
  { code: "SR", name: "Surinamese" },
  { code: "SJ", name: "Svalbard" },
  { code: "SZ", name: "Swazi" },
  { code: "SE", name: "Swedish" },
  { code: "CH", name: "Swiss" },
  { code: "SY", name: "Syrian" },
  { code: "TW", name: "Chinese, Taiwanese" },
  { code: "TJ", name: "Tajikistani" },
  { code: "TZ", name: "Tanzanian" },
  { code: "TH", name: "Thai" },
  { code: "TL", name: "Timorese" },
  { code: "TG", name: "Togolese" },
  { code: "TK", name: "Tokelauan" },
  { code: "TO", name: "Tongan" },
  { code: "TT", name: "Trinidadian or Tobagonian" },
  { code: "TN", name: "Tunisian" },
  { code: "TR", name: "Turkish" },
  { code: "TM", name: "Turkmen" },
  { code: "TC", name: "Turks and Caicos Island" },
  { code: "TV", name: "Tuvaluan" },
  { code: "UG", name: "Ugandan" },
  { code: "UA", name: "Ukrainian" },
  { code: "AE", name: "Emirati, Emirian, Emiri" },
  { code: "GB", name: "British, UK" },
  { code: "UM", name: "American" },
  { code: "US", name: "American" },
  { code: "UY", name: "Uruguayan" },
  { code: "UZ", name: "Uzbekistani, Uzbek" },
  { code: "VU", name: "Ni-Vanuatu, Vanuatuan" },
  { code: "VE", name: "Venezuelan" },
  { code: "VN", name: "Vietnamese" },
  { code: "VG", name: "British Virgin Island" },
  { code: "VI", name: "U.S. Virgin Island" },
  { code: "WF", name: "Wallis and Futuna, Wallisian or Futunan" },
  { code: "EH", name: "Sahrawi, Sahrawian, Sahraouian" },
  { code: "YE", name: "Yemeni" },
  { code: "ZM", name: "Zambian" },
  { code: "ZW", name: "Zimbabwean" },
];
