import React, {FC, useState} from "react"
import {nationalities} from "../NationalityInput";
import Select from "../../../ui/Select/Select";

interface IMobileHotelCountrySelection {
    className?: string
    nationality: string
    setNationality: (value: string) => void
}

export const MobileHotelCountrySelection: FC<IMobileHotelCountrySelection> = (
    {
        className = '',
        nationality,
        setNationality
    }) => {

    const handleNationalityHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNationality(e.target.value);
    }

    return (
        <Select
            className={`${className} lg:w-40`}
            name="flight-class"
            id="flight-class"
            onChange={handleNationalityHandler}
            value={nationality}
        >
            {nationalities.map((option) => (
                <option key={option.code} value={option.code}>
                    {option.name}
                </option>
            ))}
        </Select>
    )
}
