import SectionHero from "components/common/SectionHero";
import React, {useEffect} from "react";
import BgGlassmorphism from "components/common/BgGlassmorphism";
import { Helmet } from "react-helmet";
import PageImages from "./PageImages";
import { useReduxReducer } from "redux/store";
import SuggestedTours from "components/common/HeroSearchForm/TourSearchForm/SuggestedTours";
import SearchGrid from "pages/TourSearchPage/SearchGrid";
import BestOffers from "./BestOffers";
import RecentSearches from "./RecentSearches";
import useQueryParameters from "../../hooks/useQueryParameters";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SetTabSelections} from "../../redux/slices/tabsSlice";
import AdvertisementSection from "./AdvertisementSection";
import CookieConsent from "./CookieConsent";
import heroImage from "../../images/tours/heroSearch.png"
import ImageSlider from "./ImageSliider";
import { Banners } from "pages/Banners/Banners";


function PageHome() {

    const dispatch = useDispatch()
    const { isApp, isMobile } = useReduxReducer(state => state.general)
    const {isAdmin} = useReduxReducer(state=> state.general)
    const {audit} = useReduxReducer(state => state.config)
  const tabsSelection  = useReduxReducer(state => state.tabs)

    let { type } = useParams();

    useEffect(() => {
        if (isApp && type)
            dispatch(SetTabSelections(type))
    }, [type])
    const {meta,header} = useReduxReducer((state)  => state.config)

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>
         {meta.title}
        </title>
        <link rel="icon" type="image/png" href={header.favicon} sizes="16x16" />
        <meta name="description" content={meta.description} />
        <link rel="apple-touch-icon" href={header.defaultLogo} />


      </Helmet>

      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {isMobile 
        ?  <SectionHero />
    
        : <ImageSlider>
          <SectionHero />
          </ImageSlider>
        }
      <div className={`${!isApp && 'container min-h-screen  relative mt-12 mb-24 space-y-12 lg:mb-32'}`}>
        {/* SECTION HERO */}
       
       
        {!isApp && (tabsSelection.activeTab === "Tour" 
        ? <SuggestedTours /> 
        : <>
        {/*audit.marketingType === "B2B" && !isMobile && <AdvertisementSection/>*/}
        
        {audit.marketingType === "B2B" && <RecentSearches />}
        <Banners/>
        </>)}
      </div>
      <CookieConsent/>
    </div>
  );
}

export default PageHome;
