import React, { Dispatch,useRef, useEffect, useState } from "react";
import {
  AnchorDirectionShape,
  DateRangePicker,
  FocusedInputShape,
} from "react-dates";
import { DateRange } from "./HotelSearchForm";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import useWindowSize from "hooks/useWindowResize";
import moment, { Moment } from "moment";
import { CalendarIcon, ClockIcon } from "@heroicons/react/outline";
import HeroSelect from "../HeroSearchForm/HeroSelect";
import timeSelectOptions from "utils/timeSelectOptions";
import RadioGroup from "components/ui/RadioGroup";
import { useTranslation } from "react-i18next";
import { AirportSearchAutocompleteResult, BestPricesModel } from "api/types";
import { b2cURL, getBestPricesUrl } from "api/helpers/api-constants";
import TextField from '@mui/material/TextField';

import { Button, Chip, MenuItem, Stack } from "@mui/material";
import Select from "components/ui/Select/Select";
import { useReduxReducer } from "redux/store";
import { fetcherWithoutJSON } from "api/helpers/fetcher";
import { BsChevronRight } from "react-icons/bs";
import Checkbox from "components/ui/Checkbox/Checkbox";
import { InputMask } from "primereact/inputmask";

type Fields = "checkIn" | "checkOut";

export interface DatesRangeInputProps {
  defaultValue: DateRange;
  defaultFocus?: FocusedInputShape | null;
  onChange: Dispatch<React.SetStateAction<DateRange>>;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
  startDateTime?: string | null;
  setStartDateTime?: React.Dispatch<React.SetStateAction<string | null>>;
  endDateTime?: string | null;
  setEndDateTime?: React.Dispatch<React.SetStateAction<string | null>>;
  minDate?: Moment | null;
  maxDate?: Moment | null;
  className?: string;
  fieldClassName?: string;
  wrapClassName?: string;
  numberOfMonths?: number;
  anchorDirection?: AnchorDirectionShape;
  checkinLabel?: string;
  checkoutLabel?: string;
  checkinDateError?: boolean;
  checkoutDateError?: boolean;
  IconCheckin?: React.ReactNode & FC<React.ComponentProps<"svg">>;
  IconCheckout?: React.ReactNode & FC<React.ComponentProps<"svg">>;
  value: DateRange;
  departureAirport?: AirportSearchAutocompleteResult | null;
  arrivalAirport?: AirportSearchAutocompleteResult | null;
  date: [moment.Moment | null, moment.Moment | null];
  setSelectedDate: (value: [moment.Moment | null, moment.Moment | null]) => void;}

const DateInputFlight: FC<DatesRangeInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = null,
  onFocusChange,
  startDateTime,
  setStartDateTime,
  endDateTime,
  setEndDateTime,
  minDate,
  maxDate,
  className,
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapClassName = "divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 md:border-l md:border-r border-neutral-200 lg:border-none",
  numberOfMonths,
  anchorDirection,
  checkinLabel = "",
  checkoutLabel = "",
  checkinDateError = false,
  checkoutDateError = false,
  IconCheckin = CalendarIcon,
  IconCheckout = CalendarIcon,
  value,
 departureAirport,
 arrivalAirport,
 date = [value.startDate,value.endDate],
 setSelectedDate,
}) => {
  const { t } = useTranslation();
  // checkinLabel = t("react.hotel.check-in");
  // checkoutLabel = t("react.hotel.check-out");
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const { isB2C } = useReduxReducer(state => state.general)
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMultiRoute, setIsMultiRoute] = useState(false);

  const [routeOptions, setRouteOptions] = useState<string[]>(departureAirport && arrivalAirport 
    ? [`${departureAirport!.id.split(",")[0]}`, `${arrivalAirport!.id.split(",")[0]}`] // Extract first airport code from comma-separated IDs
    : [""] // Set default empty array
  );
  const [selectedRoute, setSelectedRoute] = useState<any>(routeOptions[0]);

  const {serviceFee} = useReduxReducer((state) => state.flight)
  const [bestPrices, setBestPrices] = useState<BestPricesModel[]>([]);
  const [dateRange, setDateRange] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({
    startDate: value.startDate ? moment(value.startDate ).startOf("month") : moment(),
    endDate: value.endDate 
      ? moment(value.endDate ).add(1, "month").endOf("month")
      : moment().add(1, "month").endOf("month"),
  });
  const [directFlight, setDirectFlight] = useState<boolean>(false); 
  const windowSize = useWindowSize();
  useEffect(() => {
    setSelectedRoute(null);

    if (departureAirport?.id && arrivalAirport?.id) {
      const depAirports = departureAirport.id.split(",");
      const arrAirports = arrivalAirport.id.split(",");
      
      const options : any = [];
      
      depAirports.forEach(depAirport => {
        arrAirports.forEach(arrAirport => {
          options.push([depAirport, arrAirport]);
        });
      });
      
      setRouteOptions(options);
      setSelectedRoute(options[0]);
    }
    
  }, [departureAirport, arrivalAirport]);
  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);
  const handleClearData = (field: Fields) => {
    switch (field) {
      case "checkIn": {
        return onChange((date: any) => ({ ...date, startDate: null }));
      }
      case "checkOut": {
        return onChange((date: any) => ({ ...date, endDate: null }));
      }

      default:
        break;
    }
  };
  useEffect(() => {
    if (defaultFocus === "startDate") {
      inputRef.current!.focus();
    }
    else if(defaultFocus === "endDate")
    inputRef2.current!.focus();
    else
    inputRef2.current?.blur()

  }, [defaultFocus]);
  
  const getPrices = async (
    from: string,
    to: string,
    startDate: string,
    endDate: string
  ) => {
    setIsLoading(true);

   if (routeOptions.length > 1) {
    setIsMultiRoute(true);

  } else {
    setIsMultiRoute(false);
  }

    const url = `${isB2C ? b2cURL : ''}${getBestPricesUrl(from, to, startDate, endDate)}`;

    await fetcherWithoutJSON(url)
      .then((res) => res.json())
      .then((data) => {
        if (data.status !== "error") {
          let newData = data.map((item: BestPricesModel) => {
            return {
              ...item,
              isBestPrice: false,
            };
          });

          let bestPrices: { first: number[]; last: number[] } = {
            first: [],
            last: [],
          };

          if (directFlight) {
            newData = newData.filter(
              (item: BestPricesModel) => item.nonStopPrice
            );

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month").subtract(1, "day")
                )
              ) {
                if (moment(element.departureDate).isAfter(moment())) {
                  if (element.nonStopPrice) {
                    bestPrices.first.push(element.nonStopPrice);
                  }
                }
              } else if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
                  dateRange.endDate
                )
              ) {
                if (moment(element.departureDate).isAfter(moment())) {
                  if (element.nonStopPrice) {
                    bestPrices.last.push(element.nonStopPrice);
                  }
                }
              }
            });

            bestPrices.first.sort((a, b) => a - b);
            bestPrices.last.sort((a, b) => a - b);

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month")
                )
              ) {
                if (element.nonStopPrice) {
                  if (element.nonStopPrice === bestPrices.first[0]) {
                    element.isBestPrice = true;
                  }
                }
              } else if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month"),
                  dateRange.endDate
                )
              ) {
                if (element.nonStopPrice) {
                  if (element.nonStopPrice === bestPrices.last[0]) {
                    element.isBestPrice = true;
                  }
                }
              } else {
                element.isBestPrice = false;
              }
            });
          } else {
            // newData = newData.filter((item: BestPricesModel) => item.nonStopPrice)

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month").subtract(1, "day")
                )
              ) {
                bestPrices.first.push(element.bestPrice ?? 0);
              }

              if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
                  dateRange.endDate
                )
              ) {
                bestPrices.last.push(element.bestPrice ?? 0);
              }
            });

            bestPrices.first.sort((a, b) => a - b);
            bestPrices.last.sort((a, b) => a - b);

            newData.forEach((element: BestPricesModel) => {
              if (
                moment(element.departureDate).isBetween(
                  dateRange.startDate,
                  moment(dateRange.startDate).add(1, "month")
                )
              ) {
                if (element.bestPrice === bestPrices.first[0]) {
                  element.isBestPrice = true;
                }
              } else if (
                moment(element.departureDate).isBetween(
                  moment(dateRange.endDate).subtract(1, "month"),
                  dateRange.endDate
                )
              ) {
                if (
                  Math.ceil(element.bestPrice ?? 0) ===
                  Math.ceil(bestPrices.last[0])
                ) {
                  element.isBestPrice = true;
                }
              } else {
                element.isBestPrice = false;
              }
            });
          }
          setBestPrices(newData);
        }
      })
      .catch((err) => {
        console.error("err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (departureAirport?.id && arrivalAirport?.id && selectedRoute) {
      const startDate = moment().format("DD.MM.YYYY");
      const endDate = moment().add(6, "month").format("DD.MM.YYYY");
      let originId, destinationId;

        originId = focusedInput ==="startDate" && selectedRoute ? selectedRoute![0] : selectedRoute![1];
        destinationId =focusedInput ==="startDate" && selectedRoute ? selectedRoute![1] : selectedRoute![0];
  

      getPrices(originId, destinationId, startDate, endDate);
    }
  }, [selectedRoute, focusedInput]);
  
  useEffect(() => {
    let newData = bestPrices.map((item: BestPricesModel) => {
      return {
        ...item,
        isBestPrice: false,
      };
    });

    let prices: { first: number[]; last: number[] } = { first: [], last: [] };

    if (directFlight) {
      newData = newData.filter((item: BestPricesModel) => item.nonStopPrice);

      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month").subtract(1, "day")
          )
        ) {
          if (moment(element.departureDate).isAfter(moment())) {
            if (element.nonStopPrice) {
              prices.first.push(element.nonStopPrice);
            }
          }
        } else if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
            dateRange.endDate
          )
        ) {
          if (moment(element.departureDate).isAfter(moment())) {
            if (element.nonStopPrice) {
              prices.last.push(element.nonStopPrice);
            }
          }
        }
      });

      prices.first.sort((a, b) => a - b);
      prices.last.sort((a, b) => a - b);

      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month")
          )
        ) {
          if (element.nonStopPrice) {
            if (element.nonStopPrice === prices.first[0]) {
              element.isBestPrice = true;
            }
          }
        } else if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month"),
            dateRange.endDate
          )
        ) {
          if (element.nonStopPrice) {
            if (element.nonStopPrice === prices.last[0]) {
              element.isBestPrice = true;
            }
          }
        } else {
          element.isBestPrice = false;
        }
      });
    } else {
      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month").subtract(1, "day")
          )
        ) {
          if (element.bestPrice) {
            prices.first.push(element.bestPrice);
          }
        }

        if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month").add(1, "day"),
            dateRange.endDate
          )
        ) {
          if (element.bestPrice) {
            prices.last.push(element.bestPrice);
          }
        }
      });

      prices.first.sort((a, b) => a - b);
      prices.last.sort((a, b) => a - b);

      newData.forEach((element: BestPricesModel) => {
        if (
          moment(element.departureDate).isBetween(
            dateRange.startDate,
            moment(dateRange.startDate).add(1, "month")
          )
        ) {
          if (element.bestPrice === prices.first[0]) {
            element.isBestPrice = true;
          }
        } else if (
          moment(element.departureDate).isBetween(
            moment(dateRange.endDate).subtract(1, "month"),
            dateRange.endDate
          )
        ) {
          if (element.bestPrice === prices.last[0]) {
            element.isBestPrice = true;
          }
        } else {
          element.isBestPrice = false;
        }
      });
    }

    setBestPrices(newData);
  }, [directFlight]);
  const renderDayContent = (day: moment.Moment) => {
    let _ = bestPrices.find(
      (item) =>
        moment(item.departureDate).format("YYYY-MM-DD") ===
        day.format("YYYY-MM-DD")
    );

    if (_) {
      return (
        <div className="flex flex-col items-center">
          <div
            className={`${
              day.format("DD.MM.YYYY") ===  value.startDate?.format("DD.MM.YYYY") || day.format("DD.MM.YYYY") === value?.endDate?.format("DD.MM.YYYY")
                ? "!text-white"
                : moment(day).isBefore(minDate)
                ? "!text-gray-300"
                : "!text-black"
            }`}
          >
            {day.format("D")}
          </div>
          <div
    className={`text-xs ${
        day.format("DD.MM.YYYY") === value.startDate?.format("DD.MM.YYYY") ||   day.format("DD.MM.YYYY") === value?.endDate?.format("DD.MM.YYYY")
            ? "!text-gray-100 "
            : moment(day).isBefore(minDate)
                ? "!text-gray-300"
                : directFlight && _.nonStopPrice
                    ? !_.isBestPrice
                        ? "!text-primary-6000"
                        : ""
                    : "!text-gray-700"
    } ${directFlight && !_.nonStopPrice && "!hidden"} ${
        moment(day).isBefore(minDate) && "invisible"
    } ${_.isBestPrice && "font-semibold !text-[#ff690f]"}`}
>
            {directFlight
              ? _.nonStopPrice && Math.ceil(_.nonStopPrice) + serviceFee
              : Math.ceil(_.bestPrice ?? 0) + serviceFee }{" "}
            €
          </div>
        </div>
      );
    }
    return (
      <div
        className={`${
          day.format("DD.MM.YYYY") ===   value.startDate?.format("DD.MM.YYYY") || day.format("DD.MM.YYYY") ===  value?.endDate?.format("DD.MM.YYYY")
            ? "!text-white"
            : moment(day).isBefore(minDate)
            ? "!text-gray-300"
            : "!text-black"
        }`}
      >
        {day.format("D")}
      </div>
    );
  };


  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };
  useEffect(() => {
    if(date[0] && date[1]){
      setinputDate( date[0]?.format("YYYY-MM-DD"))
      setinputDateReturn( date[1]?.format("YYYY-MM-DD"))
    }
    
  }, [date]);
  const [inputDate, setinputDate] = useState<string>(date[0]? date[0]?.format("YYYY-MM-DD"): "");
  const [inputDateReturn, setinputDateReturn] = useState<string>(date[1]? date[1]?.format("YYYY-MM-DD"): "");

  const renderInputCheckInDate = () => {
    const focused = focusedInput === "startDate";   
  
    const errorClassName = checkinDateError ? "text-red-400" : "";
    const handleInputChange = (input: string) => {
      setinputDate(input); // Update inputDate immediately
  
      
        const newStartDate = input.length > 0 ? moment(input) : date[0];
        if (newStartDate && newStartDate.isValid()) {
          setSelectedDate([newStartDate, date[1]]);
        }
      
    };
    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} h-full flex-shrink-0 cursor-pointer items-center space-x-3 ${
          focused ? "rounded-xl dark:bg-neutral-800" : ""
        }`}
        onClick={() => handleDateFocusChange("startDate")}
      >
        <div className="border-right flex-grow">
          <input
            type="date" // Changed to text for keyboard input
            ref={inputRef}
            className={`xl:text-lg block font-semibold border-none ${errorClassName} hide-calendar-icon`}
            value={inputDate}
            onChange={(e) => setinputDate(e.target.value)}
            onBlur={() => handleInputChange(inputDate)}
            placeholder={value?.startDate ? "" : checkinLabel}
          />
          {focused && <ClearDataButton onClick={() => handleClearData("checkIn")} />}
        </div>
      </div>
    );
  };
  const renderInputCheckOutDate = () => {
    const focused = focusedInput === "endDate";
    const errorClassName = checkoutDateError ? "text-red-400" : "";
    const handleInputChange = (input: string) => {
      setinputDateReturn(input); 

        const newReturnDate = input.length > 0 ? moment(input) : date[1];
        if (newReturnDate && newReturnDate.isValid()) {
          setSelectedDate([date[0], newReturnDate]);
        }
      
    };
    return (
      <div
        className={`relative flex flex-1 ${fieldClassName} h-full flex-shrink-0 cursor-pointer items-center space-x-3 ${
          focused ? "rounded-xl dark:bg-neutral-800" : ""
        }`}
        onClick={() => handleDateFocusChange("endDate")}
      >
        
        <div className="border-right flex-grow">
        <input
          type="date"
          ref={inputRef2}
          className={`xl:text-lg block font-semibold border-none ${errorClassName} hide-calendar-icon`}
          value={inputDateReturn}
          onChange={(e) => setinputDateReturn(e.target.value)}
          onBlur={() => handleInputChange(inputDateReturn)}
          placeholder={value?.startDate ? "" : checkinLabel} 
        />
        {focused && <ClearDataButton onClick={() => handleClearData("checkOut")} />}
      </div>
    </div>
    );
  };
  
  return (
    <div className="[ lg:nc-flex-2 ] relative z-10 w-full   ">
      <div className="absolute inset-x-0 bottom-0">
        
          <DateRangePicker
            keepFocusOnInput
            startDate={moment(date[0])}
            endDate={moment(date[1])}
            focusedInput={focusedInput}
            onDatesChange={(date) => {
              onChange(date);
              setSelectedDate([moment(date.startDate),moment(date.endDate)])
            }}
            onFocusChange={handleDateFocusChange}
            numberOfMonths={
              numberOfMonths || (windowSize.width <= 1024 ? 1 : undefined)
            }
            startDateId={"nc-hero-stay-startDateId"}
            endDateId={"nc-hero-stay-endDateId"}
            daySize={windowSize.width > 500 ? 56 : undefined}
            orientation={"horizontal"}
            showClearDates
            noBorder
            hideKeyboardShortcutsPanel
            anchorDirection={anchorDirection}
            firstDayOfWeek={1}
            renderDayContents={(day) =>
              isLoading ? (
                <div className="flex flex-col items-center">
                  <div
                    className={`${
                      day.format("DD.MM.YYYY") === (focusedInput === "startDate" ? value.startDate?.format("DD.MM.YYYY") :value?.endDate?.format("DD.MM.YYYY"))
                        ? "!text-white"
                        : moment(day).isBefore(minDate)
                        ? "!text-gray-200"
                        : "!text-black"
                    }`}
                  >
                    {day.format("D")}
                  </div>
                  <div className="h-2 w-6 animate-pulse rounded-full bg-primary-300"></div>
                </div>
              ) : (
                renderDayContent(day)
              )
            }
            calendarInfoPosition="top"
            renderCalendarInfo={() => {
              if (departureAirport?.id && arrivalAirport?.id) {
                return (
                  <div className="grid w-full grid-cols-2 items-center justify-between pt-2 px-2">
                    
                    <div className="flex items-center justify-center space-x-2 pt-3 pl-3 font-semibold">
                      <span>{ focusedInput === "startDate" ? departureAirport.id :arrivalAirport.id} </span>
                      <BsChevronRight />
                      <span>{ focusedInput === "startDate" ? arrivalAirport.id :departureAirport.id} </span>
                    </div>
                    <div></div>
                    {isMultiRoute ? (
                      <div
                        className="row mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gridColumn: "span 2",
                        }}
                      >
                        {focusedInput === "startDate" &&
                        routeOptions.map((option, index) => (
                          <span key={index} style={{ margin: "0 4px" }}>
                            <Chip
                              key={index}
                              label={(option[0] + " > " + option[1])}
                              size={"small"}
                              variant={
                                selectedRoute === option ? "filled" : "outlined"
                              }
                              color={
                                selectedRoute === option ? "primary" : "default"
                              }
                              onClick={() => setSelectedRoute(option)}
                            />
                          </span>
                        )) 
                      
                     
                      }{focusedInput === "endDate" &&
                        routeOptions.map((option, index) => (
                          <span key={index} style={{ margin: "0 4px" }}>
                            <Chip
                              key={index}
                              label={ ( option[1] + " > " + option[0])}
                              size={"small"}
                              variant={
                                selectedRoute === option ? "filled" : "outlined"
                              }
                              color={
                                selectedRoute === option ? "primary" : "default"
                              }
                              onClick={() => setSelectedRoute(option)}
                            />
                          </span>
                        )) 
                      }
                      
                      </div>
                    ) : null}
                  </div>
                );
              } else {
                return undefined;
              }
            }}
          />
        
      </div>

      <div
        className={`relative flex h-full w-full flex-shrink-0 flex-col lg:flex-row lg:items-center  ${wrapClassName}`}
      >

        {renderInputCheckInDate()}
        {renderInputCheckOutDate()}

        
      </div>
    </div>
  );
};

export default DateInputFlight;
