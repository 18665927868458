import React, { useEffect,useState } from "react";
import moment from "moment";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import {
  AirportSearchAutocompleteResult,
  CabinClass,
  IMissingInputError,
  IMultiCityMissingInput,
  MultiCitySearchModel,
  SearchPayload,
  TFlightType,
} from "api/types";
import RadioGroup from "components/ui/RadioGroup";
import Select from "components/ui/Select/Select";
import TravelersInput, { TravelersInputValue } from "../TravelersInput";
import DateInput from "../DateInput";
import cn from "classnames";
import {  getAirportAutocompleteUrl } from "api/helpers/api-constants";
import { useTranslation } from "react-i18next";
import AutocompleteForFlight from "../AutocompleteForFlight";
import { FaExchangeAlt } from "react-icons/fa";
import { Button, IconButton, Tooltip } from "@mui/material";
import { IoAddCircle } from "react-icons/io5";
import MultiCityForm from "./MultiCityForm";
import { useReduxReducer } from "../../../../redux/store";
import Switch from "components/ui/Switch/Switch";
import DateInputFlight from "../DateInputFlight";
import { FocusedInputShape } from "react-dates";
import { setSwitchValue } from "redux/slices/generalSlice";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";

export interface DateRange {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface FlightSearchFormProps { 
  queryParameters: SearchPayload | null;
  flightType: TFlightType;
  departureAirport: AirportSearchAutocompleteResult | null;
  arrivalAirport: AirportSearchAutocompleteResult | null;
  selectedDate: [moment.Moment | null, moment.Moment | null];
  travelers: TravelersInputValue;
  cabinClass: CabinClass;
  multiCitySearch: MultiCitySearchModel[];
  multiCitySearchNumber: number;
  missingInputError: IMissingInputError;
  withBaggageOnly: boolean;
  showDirectFlights: boolean;
  excludeGdsFlights: boolean;
  setExcludeGdsFlights: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDirectFlights: React.Dispatch<React.SetStateAction<boolean>>;
  setWithBaggageOnly : React.Dispatch<React.SetStateAction<boolean>>;
  setFlightType: (value: TFlightType) => void;
  setDepartureAirport: React.Dispatch<
    React.SetStateAction<AirportSearchAutocompleteResult | null>
  >;
  setArrivalAirport: React.Dispatch<
    React.SetStateAction<AirportSearchAutocompleteResult | null>
  >;
  setSelectedDate: (
    value: [moment.Moment | null, moment.Moment | null] 
  ) => void;
  setTravelers: (value: TravelersInputValue) => void;
  setCabinClass: (value: CabinClass) => void;
  setMultiCitySearch: React.Dispatch<
    React.SetStateAction<MultiCitySearchModel[]>
  >;
  setMultiCitySearchNumber: React.Dispatch<React.SetStateAction<number>>;
  setMissingInputError: (value: IMissingInputError) => void;
  handleSubmit: (value: React.FormEvent) => void;
}

const FlightsSearchForm: FC<FlightSearchFormProps> = ({
  queryParameters,
  flightType,
  departureAirport,
  arrivalAirport,
  selectedDate,
  travelers,
  cabinClass,
  multiCitySearch,
  multiCitySearchNumber,
  missingInputError,
  withBaggageOnly,
  showDirectFlights,
  excludeGdsFlights,
  setExcludeGdsFlights,
  setShowDirectFlights,
  setWithBaggageOnly,
  setDepartureAirport,
  setArrivalAirport,
  setFlightType,
  setSelectedDate,
  setTravelers,
  setCabinClass,
  setMultiCitySearch,
  setMultiCitySearchNumber,
  setMissingInputError,
  handleSubmit,
}) => {
  const { isMobile } = useReduxReducer((state) => state.general);

  const [multiCityMissingInput, setMultiCityMissingInput] = useState<
    IMultiCityMissingInput[]
  >([
    {
      departureAirport: false,
      arrivalAirport: false,
      departureDate: false,
    },
  ]);
  const dispatch = useDispatch()
  const [departureFocus, setDepartureFocus] = useState(false);
  const [returnFocus, setReturnFocus] = useState(false);
  const [dateFocus, setDateFocus] = useState(false);
  const [returnDateFocus, setReturnDateFocus] = useState(false);
  const [errorCheckMulti, seterrorCheckMulti] = useState<boolean>(false);
  const { t } = useTranslation();
  const {audit} = useReduxReducer(state => state.config)
  const location = useLocation();
  const [airportChange, setairportChange] = useState<boolean>(false);
  const handleAirportsChange = () => {
  const temp = departureAirport;
  setDepartureAirport(arrivalAirport);
  setArrivalAirport(temp);
  setairportChange(!airportChange)

};
const [dateRange, setDateRange] = useState<DateRange>({
  startDate: moment().add("days", 1),
  endDate: moment().add("days", 2),
});
const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
  "startDate"
);
  useEffect(() => {
    if (!queryParameters ?? !queryParameters?.originDestinationInformation) {
      return;
    }

    if (queryParameters) {
      setTravelers({
        adults: queryParameters?.adtCount || 1,
        children: queryParameters?.chdCount || 0,
        infants: queryParameters?.infCount || 0,
      });

      setFlightType(queryParameters.flightSearchType ?? "Round-Trip");
      setCabinClass(queryParameters.cabinClass || "ECONOMY");

      if (queryParameters?.flightSearchType === "Multi-City") {
        setMultiCitySearchNumber(
          queryParameters?.originDestinationInformation.length ?? 1
        );

        let temp = [...multiCitySearch];

        queryParameters?.originDestinationInformation.forEach((item, index) => {
          temp[index] = {
            departureAirport: {
              id: item.origin!,
              text: item.originText!,
            },
            arrivalAirport: {
              id: item.destination!,
              text: item.destinationText!,
            },
            departureDate: moment(item.date!),
          };
        });

        setMultiCitySearch(temp);
      } else if (queryParameters.flightSearchType === "Round-Trip") {
        const origin = queryParameters.originDestinationInformation[0];
        const returnal = queryParameters.originDestinationInformation[1];

        setDepartureAirport({ id: origin.origin!, text: origin.originText! });

        setArrivalAirport({
          id: origin.destination!,
          text: origin.destinationText!,
        });

        // setRoundTripStartDate(moment(origin.date!, "YYYY-MM-DD"));
        // setRoundTripEndDate(moment(returnal.date!, "YYYY-MM-DD"));
        setSelectedDate([
          moment(origin.date!, "YYYY-MM-DD"),
          moment(returnal.date!, "YYYY-MM-DD"),
        ]);
      } else if (queryParameters.flightSearchType === "Oneway") {
        const origin = queryParameters.originDestinationInformation[0];
        setDepartureAirport({ id: origin.origin!, text: origin.originText! });
        setArrivalAirport({
          id: origin.destination!,
          text: origin.destinationText!,
        });

        // setDepartureFirstTrip(moment(origin.date!));
        setSelectedDate([moment(origin.date!), null]);
      } else if (queryParameters.flightSearchType === "Multi-City") {
        const firstTrip = queryParameters.originDestinationInformation[0];
        const secondTrip = queryParameters.originDestinationInformation[1];

        setDepartureAirport({
          id: firstTrip.origin!,
          text: firstTrip.originText!,
        });
        setArrivalAirport({
          id: firstTrip.destination!,
          text: firstTrip.destinationText!,
        });

        // setDepartureFirstTrip(moment(firstTrip.date!));
        setSelectedDate([moment(firstTrip.date!), null]);
      }
    }
  }, [queryParameters, location.pathname]);

  useEffect(() => {
    if (multiCitySearch.length > multiCityMissingInput.length) {
      const _multiCityMissingInput: IMultiCityMissingInput[] = [];
      multiCitySearch.forEach(() => {
        _multiCityMissingInput.push({
          departureAirport: false,
          arrivalAirport: false,
          departureDate: false,
        });
      });
      setMultiCityMissingInput(_multiCityMissingInput);
    }
    seterrorCheckMulti(false);
  }, [multiCitySearch]);
  function handleCabinClassChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setCabinClass(e.target.value as CabinClass);
  }

  return (
    <div className="relative mt-0 w-full rounded-b-lg bg-white shadow-sm dark:bg-neutral-900 dark:shadow-2xl">
      <div className="justify-between border-b border-neutral-100 dark:border-neutral-800 lg:flex">
        <RadioGroup
          wrapperClassName="p-2 mt-1"
          values={[
            { value: "Round-Trip", label: t("flights.roundtrip") },
            { value: "Oneway", label: t("flights.oneway") },
            {
              value: "Multi-City",
              label: t("react.searchmask.flight.multiflight"),
            },
          ]}
          selected={flightType}
          onChange={(value) => {
            setFlightType(value as TFlightType);
            dispatch(setSwitchValue(false))
          }}
        />
        <div className="flex items-center">
        {flightType === "Multi-City" &&
        <TravelersInput
            labelClassName="!text-base !font-medium"
            iconClassName="w-5 h-5"
            subtitle={false}
            travelers={travelers}
            setTravelers={setTravelers}
          />}
       {  /*  <div className="px-2 flex pt-2">
           <span className={"text-md pt-1 " + (withBaggageOnly ? 'text-primary-6000' : 'text-gray-800')}>
  {t("b2c-app.flights.results.filter.baggage.with-baggage")}
</span>
          <Switch
            id="customSwitch"
            checked={withBaggageOnly}
            onChange={() => setWithBaggageOnly((prev) => !prev)}
            labelClass=""
            className="pt-1"
            />
           </div> */}
        { audit.marketingType === "B2B" &&  <div className="px-2 flex pt-2">

          <span className={"text-md pt-1 " + (!excludeGdsFlights ? 'text-primary-6000' : 'text-gray-800')}> {t("flight.searchform.iatasearch")}</span>
          <Switch
            id="iataSwitch"
            checked={!excludeGdsFlights}
            onChange={() => setExcludeGdsFlights((prev) => !prev)}
            labelClass=""
            className="pt-1"
            />
             <Tooltip
    title={
      <span className="text-white font-semibold text-right">
        {t("flight.searchform.iata.tooltip")}
      </span>
    }
    placement="right-start"
    arrow
    disableFocusListener
    slotProps={{
     
      tooltip: {
        sx: {
          backgroundColor: "#156da1", // Blue background
          color: "white", // White text
          fontWeight: "bold",
          padding: "8px 12px",
          zIndex: 0,
        },
      },
      arrow: {
        sx: {
          color: "#156da1", // Arrow color should match the tooltip background
        },
      },
    }}
  >
    <IconButton size="small" className="mt-xl-n4">
      <InfoIcon fontSize="small" style={{ color: "#156da1" }} />
    </IconButton>
  </Tooltip>
           </div>}
           <div className="px-2 flex pt-2">
          <span className={"text-md pt-1 " + (showDirectFlights ? 'text-primary-6000' : 'text-gray-800')}> {t("b2c-app.flights.results.filter.stops.direct-flight")}</span>
          <Switch
            id="directFlightSwitch"
            checked={showDirectFlights}
            onChange={() => setShowDirectFlights((prev) => !prev)}
            labelClass=""
            className="pt-1"
            />
           </div>
          <div className="p-2">
            <Select
              className="lg:w-40"
              name="flight-class"
              id="flight-class"
              onChange={handleCabinClassChange}
              value={cabinClass} 
            >
              {flightClassSelectOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>

        </div>
      </div>

      {flightType !== "Multi-City" && (
        <div className=" [ nc-divide-field ] flex w-full flex-col rounded-full md:flex-row md:items-center ">
          <div className="[ nc-divide-field ] relative flex flex-grow flex-col md:flex-row ">
            <AutocompleteForFlight
              placeHolder={t("app.flight-search.airport")}
              desc={t("app.flight-search.from")}
              getUrl={getAirportAutocompleteUrl}
              autocompleteValue={departureAirport}
              setAutocompleteValue={setDepartureAirport}
              localStorageToken="airport-departure"
              error={missingInputError.departureAirport}
              focus={departureFocus}
              autoFocus={departureFocus}
              setFocus={setDepartureFocus}
              setDateFocus={setReturnFocus}
              airportChange={airportChange}
            />
            <Button onClick={handleAirportsChange}>
              <FaExchangeAlt />
            </Button>
            <AutocompleteForFlight
              placeHolder={t("app.flight-search.airport")}
              desc={t("app.flight-search.to")}
              getUrl={getAirportAutocompleteUrl}
              autocompleteValue={arrivalAirport}
              setAutocompleteValue={setArrivalAirport}
              localStorageToken="airport-arrival"
              error={missingInputError.arrivalAirport}
              autoFocus={returnFocus}
              focus={returnFocus}
              setFocus={setReturnFocus}
              setDateFocus={setDateFocus}
              airportChange={airportChange}
            />

            {flightType === "Round-Trip" ? (
              <div className="flex flex-wrap">
            
                    <DateInputFlight
                          defaultValue={dateRange}
                          defaultFocus={dateFocus ?  dateFocused : null }
                          checkinLabel={t("app.add-date")}
                          checkoutLabel={t("app.add-date")}
                          onFocusChange={(focus) => setDateFocused(focus)}
                          onChange={(data) => setDateRange(data)}
                          date= {selectedDate}
                          setSelectedDate={setSelectedDate}              
                          checkinDateError={missingInputError.departingDate}
                          checkoutDateError={missingInputError.returningDate}
                          value={dateRange}
                          departureAirport={departureAirport}
                          arrivalAirport={arrivalAirport}
                      />
               
              </div>
            ) : (
            
              <DateInput
                departureAirport={departureAirport}
                arrivalAirport={arrivalAirport}
                label={t("b2c-app.flights.date")}
                date={selectedDate[0]}
                setDate={(value) => setSelectedDate([value, null])}
                numberOfMonths={isMobile ? 1 : 2}
                dateFocus={dateFocus}
                setDateFocus={setDateFocus}
                error={missingInputError.departureFirstTrip}
                minDate={moment().subtract(1, "days")}
              />
            )}
            <div className="flex items-center">
              <TravelersInput
            labelClassName="!text-base !font-medium"
            iconClassName="w-5 h-5"
            subtitle={false}
            travelers={travelers}
            setTravelers={setTravelers}
          /></div>

            <div
              className={cn(
                "justify center flex items-center px-4 py-4 lg:py-0"
              )}
            >
             <button
              className="flex h-12 w-full items-center justify-center rounded-lg bg-primary-6000 text-neutral-50 hover:bg-primary-700  focus:ring-1 focus-visible:bg-primary-300 md:h-12 md:w-16"
              onClick={(e) => {
                handleSubmit(e);
                setDateFocused(null)
                e.currentTarget.blur()
              }}
              id="submit-button" // Add an id to the button
            >
              <span className="mr-3 md:hidden">{t("app.search")}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>

            </div>
          </div>
        </div>
      )}
      {flightType === "Multi-City" && (
        <>

          {[...Array(multiCitySearchNumber)].map((_, index) => {
            return (
              <MultiCityForm
                searchType={flightType}
                travelers={travelers}
                cabinClass={cabinClass}
                first={index === 0}
                index={index}
                multiCitySearchNumber={multiCitySearchNumber}
                setMultiCitySearchNumber={setMultiCitySearchNumber}
                multiCitySearch={multiCitySearch}
                setMultiCitySearch={setMultiCitySearch}
                key={index}
                multiCityMissingInput={multiCityMissingInput}
                setMultiCityMissingInput={setMultiCityMissingInput}
                isMobile={isMobile}
                handleErrorCheck={errorCheckMulti}
                withBaggageOnly={withBaggageOnly}
              />
            );
          })}
          {multiCitySearchNumber !== 6 && (
            <div className="hover:border-bottom-3 flex w-full items-center justify-between  rounded border-t p-2 ">
              <button
                className="flex items-center  justify-center rounded bg-primary-6000 p-2 px-4 text-neutral-50 disabled:bg-gray-400"
                disabled={multiCitySearchNumber === 5}
                onClick={() => {
                  let newValues = [...multiCitySearch];

                  let temp = {
                    departureAirport: null,
                    arrivalAirport: null,
                    departureDate: null,
                  };

                  newValues.push(temp);

                  setMultiCitySearch(newValues);
                  setMultiCitySearchNumber((current) => current + 1);
                  seterrorCheckMulti(false);
                }}
              >
                <IoAddCircle fontSize="large" />
                <span className="ml-3">
                  {" "}
                  {t("flight.multicity_addflight")}{" "}
                </span>
              </button>
              <button
                type="submit"
                className="flex items-center justify-between rounded bg-primary-6000 p-2 px-4 text-neutral-50 hover:bg-primary-700 focus:outline-none"
                onClick={(event) => {
                  handleSubmit(event);
                  seterrorCheckMulti(true);
                }}
              >
                <span className="mr-3">{t("app.search")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const flightClassSelectOptions: Array<{
  label: string;
  value: CabinClass;
}> = [
  { label: "Economy Class", value: "ECONOMY" },
  { label: "Business Class", value: "BUSINESS" },
  { label: "First Class", value: "FIRST" },
];

export default FlightsSearchForm;
